import { styled } from '@mui/material/styles';
import { SmallButton } from 'components/common/stylings';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { ArticleType, AttachmentType } from '../hooks/useArticlesQuery';
import RenderImage from './RenderImage';
import RenderVideo from './RenderVideo';
import RenderAudio from './RenderAudio';
import { Box } from '@mui/material';
import { getExtraFileNameAttachedByService, getFileDetails } from 'utility/helpers';
import { ArticleVariantType } from './ArticleBox';

type RenderAttachmentsProps = {
  article: ArticleType;
  attachments: AttachmentType[];
  doNotAutoplayVideos?: boolean;
  hideAttachmentsCount?: boolean;
  isDetailedView?: boolean;
  isDraftView?: boolean;
  isRelatedPostView?: boolean;
  isShortView?: boolean;
  variant: ArticleVariantType;
  setLightboxOpen?: (payload: { open: boolean; fileName: string }) => void;
};

function RenderAttachments({
  article,
  attachments,
  doNotAutoplayVideos = true,
  hideAttachmentsCount = false,
  isShortView = false,
  isDetailedView = true,
  isDraftView = false,
  isRelatedPostView = false,
  variant,
  setLightboxOpen,
}: RenderAttachmentsProps) {
  const attachment = useMemo(() => attachments[0], [attachments]);
  const { fileContentType, isImage, isVideo, isAudio } = useMemo(
    () =>
      getFileDetails({
        file: attachment,
        useDefaultStrToSliceFromFileName: !attachment.fileUploaded,
        strToSliceFromFileName: getExtraFileNameAttachedByService<ArticleType>(article),
      }),
    [attachment],
  );
  let content: ReactNode = '';

  const handleAttachmentsOpen = (event: MouseEvent) => {
    if (setLightboxOpen) {
      setLightboxOpen({ open: true, fileName: attachment?.fileName || '' });
      event.stopPropagation();
    }
  };

  if (isImage) {
    content = <RenderImage attachment={attachment} isShortView={isShortView} variant={variant} />;
  }

  if (isVideo) {
    content = (
      <RenderVideo
        isDetailedView={isDetailedView}
        isDraftView={isDraftView}
        isRelatedPostView={isRelatedPostView}
        doNotAutoplayVideos={doNotAutoplayVideos}
        attachment={attachment}
        variant={variant}
        forceStopPlaying={!!setLightboxOpen}
      />
    );
  }

  if (isAudio) {
    content = (
      <RenderAudio
        isDraftView={isDraftView}
        isRelatedPostView={isRelatedPostView}
        variant={variant}
        attachment={attachment}
      />
    );
  }

  if (content) {
    return (
      <$Container className={`${fileContentType} ${variant}`} onClick={handleAttachmentsOpen}>
        {content}
        {!hideAttachmentsCount && !isRelatedPostView && attachments.length > 1 && (
          <$PhotoButton onClick={handleAttachmentsOpen} variant="contained" className={variant}>
            1/{attachments.length}
          </$PhotoButton>
        )}
      </$Container>
    );
  }

  return null;
}

export const $Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: theme.palette.custom.background,
  height: 380,
  borderRadius: 4,
  overflow: 'hidden',
  margin: '8px auto',
  '&.video': {
    backgroundColor: `${theme.palette.custom.black} !important`,
  },
  '&.audio': {
    marginTop: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'fit-content',
  },
  '&.audio.detailed, &.audio.draft': {
    height: 100,
  },
  '&.detailed': {
    height: 400,
  },
  '&.draft': {
    width: '25%',
    height: 100,
    borderRadius: 5,
  },
  '&.related-post': {
    width: 80,
    height: 80,
    borderRadius: 10,
    margin: 0,
  },
  '&.playlist': {
    width: '100%',
    height: 200,
    margin: 0,
    borderRadius: 5,
    backgroundColor: theme.palette.custom.softPeach,
  },
}));

export const $PhotoButton = styled(SmallButton, { label: 'PhotoButton' })(({ theme }) => ({
  position: 'absolute',
  right: 4,
  top: 16,
  color: theme.palette.custom.white,
  background: 'rgba(0, 0, 0, 0.5)',
  height: 24,
  width: 'fit-content',
  minWidth: 'fit-content',
  padding: 8,
  fontSize: 13,
  fontWeight: 400,
  '&.draft': {
    right: -8,
    top: 8,
  },
}));

export default RenderAttachments;
