import { styled } from '@mui/material/styles';
import {
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Link as MuiLink,
} from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

type RenderLLMMarkdownProps = {
  llmResponse: string;
};

function RenderLLMMarkdown({ llmResponse }: RenderLLMMarkdownProps) {
  return (
    <Markdown
      className="line-break"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        table: (props) => (
          <TableContainer
            component={Paper}
            sx={(theme) => ({
              boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
            })}
          >
            <Table>{props.children}</Table>
          </TableContainer>
        ),
        th: (props) => <TableCell>{props.children}</TableCell>,
        thead: (props) => <TableHead>{props.children}</TableHead>,
        tr: (props) => <TableRow>{props.children}</TableRow>,
        tbody: (props) => <TableBody>{props.children}</TableBody>,
        td: (props) => <TableCell>{props.children}</TableCell>,
        a: (props) => (
          <Link href={props.href} target="_blank">
            {props.children}
          </Link>
        ),
      }}
    >
      {llmResponse}
    </Markdown>
  );
}

const Link = styled(MuiLink, { label: 'Link' })(({ theme }) => ({
  color: theme.palette.custom.link,
  fontWeight: 600,
  textDecoration: 'none',
  cursor: 'pointer',
  '&: hover': {
    textDecoration: 'underline',
  },
}));

export default RenderLLMMarkdown;
