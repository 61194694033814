import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';
import { ArticleType } from '../hooks/useArticlesQuery';
import { UseArticleReducerActions, UseArticleReducerState } from '../hooks/useArticle';
import { ProjectRecommendationsType } from 'pages/projects/hooks/useProjectsQuery';
import { Dispatch, SetStateAction, Suspense, lazy } from 'react';
import AddPostToPlaylistsModal from './AddPostToPlaylistsModal';
import StarSmileLineIcon from 'remixicon-react/StarSmileLineIcon';
import { Box } from '@mui/material';
import Loader from 'components/common/loader/Loader';
import NotProjectRelevantArticle from 'pages/project/components/NotProjectRelevantArticleModal';
import AddPostModal from 'components/modals/addPostModal/AddPostModal';
import { FeedFilter } from 'api/KnowledgeAPI';
import SelectWorkspacesToForwardModal from 'components/modals/addPostModal/SelectWorkspacesToForwardModal';

const PostRevisionModal = lazy(() => import('./PostRevisionModal'));
const AboutInsightsModal = lazy(
  () => import('components/modals/aboutInsightsModal/AboutInsightsModal'),
);
const EndoresementInteractionModal = lazy(
  () => import('components/modals/endorsementIneractionModal/EndorsementInteractionModal'),
);
const InteractionModal = lazy(() => import('components/modals/interactionModal/InteractionModal'));
const AlertModal = lazy(() => import('components/utils/AlertModal'));
const AddPostsToPlaylistModal = lazy(() => import('./AddPostsToPlaylistModal'));
const AssignDraftModal = lazy(() => import('./AssignDraftModal'));
const DraftCollaborators = lazy(() => import('./DraftsCollaborators'));
const InviteDraftCollabModal = lazy(() => import('./InviteDraftCollabModal'));
const RejectDraftModal = lazy(() => import('./RejectDraftModal'));

type ArticleModalsProps = {
  article: ArticleType;
  articleState: UseArticleReducerState;
  hasEditAccess: boolean;
  isDetailedView?: boolean;
  project?: ProjectRecommendationsType;
  stakeholderId?: string;
  userMap?: ArticleUserMapType;
  selectedCompanyIds?: string[];
  selectedFeedFilters?: FeedFilter[];
  deleteArticle: (article?: ArticleType) => void;
  dispatch: (value: UseArticleReducerActions) => void;
  endorseHandler: (article?: ArticleType) => void;
  movePost: (article?: ArticleType) => void;
  moveToDrafts: (article?: ArticleType) => void;
  publishArticle: (article?: ArticleType) => void;
  setSelectedCompanyIds?: Dispatch<SetStateAction<string[]>>;
  updateArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
};

function ArticleModals({
  article,
  articleState,
  hasEditAccess,
  isDetailedView = false,
  project,
  stakeholderId,
  userMap,
  selectedCompanyIds = [],
  selectedFeedFilters,
  deleteArticle,
  dispatch,
  endorseHandler,
  movePost,
  moveToDrafts,
  publishArticle,
  setSelectedCompanyIds = () => {},
  updateArticleCallback,
}: ArticleModalsProps) {
  return (
    <>
      {article && articleState.openCopyPost && (
        <AddPostModal
          open={articleState.openCopyPost}
          setOpen={() => {
            dispatch({ type: 'TOGGLE_COPY_POST', payload: false, key: 'openCopyPost' });
          }}
          article={article}
          project={project}
          stakeholderId={stakeholderId}
          selectedFeedFilters={selectedFeedFilters}
          isCopyPost
          createPlaylist={article.originIndicator === 'PLAYLIST'}
        />
      )}

      {article && hasEditAccess && articleState.openEditPost && (
        <AddPostModal
          open={articleState.openEditPost}
          setOpen={() => {
            dispatch({ type: 'TOGGLE_EDIT_MODAL', payload: false, key: 'openEditPost' });
          }}
          article={article}
          isEditing
          project={project}
          stakeholderId={stakeholderId}
          selectedFeedFilters={selectedFeedFilters}
          updateArticleCallback={updateArticleCallback}
        />
      )}

      {article && articleState.openForwardPostModal && (
        <AddPostModal
          forward
          open={articleState.openForwardPostModal}
          setOpen={() => {
            dispatch({
              type: 'TOGGLE_FORWARD_POST_MODAL',
              payload: false,
              key: 'openForwardPostModal',
            });
            setSelectedCompanyIds([]);
          }}
          article={article}
          project={project}
          stakeholderId={stakeholderId}
          selectedFeedFilters={selectedFeedFilters}
          publishArticleToCompanyIds={selectedCompanyIds}
        />
      )}

      {articleState.openSelectWorkspacesModal && (
        <SelectWorkspacesToForwardModal
          article={article}
          open={articleState.openSelectWorkspacesModal}
          setOpen={() => {
            dispatch({
              type: 'TOGGLE_SELECT_WORKSPACES_MODAL',
              payload: false,
              key: 'openSelectWorkspacesModal',
            });
          }}
          onNext={() => {
            if (selectedCompanyIds.length) {
              dispatch({
                type: 'TOGGLE_FORWARD_POST_MODAL',
                payload: true,
                key: 'openForwardPostModal',
              });
            }

            dispatch({
              type: 'TOGGLE_SELECT_WORKSPACES_MODAL',
              payload: false,
              key: 'openSelectWorkspacesModal',
            });
          }}
          selectedCompanyIds={selectedCompanyIds}
          setSelectedCompanyIds={setSelectedCompanyIds}
        />
      )}

      {articleState.openPlaylistModal && (
        <AddPostToPlaylistsModal
          open={articleState.openPlaylistModal}
          setOpen={() => {
            dispatch({ type: 'TOGGLE_PLAYLIST_MODAL', payload: false, key: 'openPlaylistModal' });
          }}
          article={article}
          selectedFeedFilters={selectedFeedFilters}
          projectId={project?.id}
          stakeholderId={stakeholderId}
        />
      )}

      <Suspense fallback={<Loader variant="fixed" />}>
        {articleState.openInsightsModal && (
          <AboutInsightsModal
            open={articleState.openInsightsModal}
            setOpen={() =>
              dispatch({ type: 'TOGGLE_INSIGHTS_MODAL', payload: false, key: 'openInsightsModal' })
            }
          />
        )}

        {articleState.openInteractionsModal && (
          <InteractionModal
            articleId={article.id}
            open={articleState.openInteractionsModal}
            setOpen={() =>
              dispatch({
                type: 'TOGGLE_INTERACTIONS_MODAL',
                payload: false,
                key: 'openInteractionsModal',
              })
            }
          />
        )}

        {articleState.openAddPostsToPlaylistModal && (
          <AddPostsToPlaylistModal
            open={articleState.openAddPostsToPlaylistModal}
            setOpen={() =>
              dispatch({
                type: 'TOGGLE_ADD_POSTS_TO_PLAYLIST_MODAL',
                payload: false,
                key: 'openAddPostsToPlaylistModal',
              })
            }
            playListArticle={article}
            selectedFeedFilters={selectedFeedFilters}
          />
        )}

        {articleState.openPostRevisionModal && (
          <PostRevisionModal
            isDetailedView={isDetailedView}
            open={articleState.openPostRevisionModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_POST_REVISION_MODAL',
                payload: false,
                key: 'openPostRevisionModal',
              });
            }}
            article={article}
            selectedFeedFilters={selectedFeedFilters}
          />
        )}

        {articleState.openMovePostConfirmationModal && (
          <AlertModal
            open={articleState.openMovePostConfirmationModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_MOVE_POST_MODAL',
                payload: false,
                key: 'openMovePostConfirmationModal',
              });
            }}
            onSubmit={movePost}
            loading={articleState.loading}
            title="Move Post to Top?"
            submitButtonName="Confirm"
            submitButtonColor="primary"
            description="Move this post to the top of the feed for everyone."
          />
        )}

        {articleState.openMoveToDraftsConfirmationModal && (
          <AlertModal
            open={articleState.openMoveToDraftsConfirmationModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_MOVE_TO_DRAFTS_MODAL',
                payload: false,
                key: 'openMoveToDraftsConfirmationModal',
              });
            }}
            onSubmit={moveToDrafts}
            loading={articleState.loading}
            title="Move Post to Drafts?"
            submitButtonName="Confirm"
            submitButtonColor="primary"
            description="Move this post to your drafts."
          />
        )}

        {articleState.openEndorseConfirmationModal && (
          <AlertModal
            open={articleState.openEndorseConfirmationModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_ENDORSE_CONFIRMATION_MODAL',
                payload: false,
                key: 'openEndorseConfirmationModal',
              });
            }}
            onSubmit={endorseHandler}
            loading={articleState.loading}
            title={
              <Box display="flex" alignItems="center">
                <StarSmileLineIcon />
                &nbsp;Endorse
              </Box>
            }
            submitButtonName="Endorse"
            submitButtonColor="primary"
            description={
              <>
                Are you sure you want to endorse this post?
                <br />
                You cannot undo the endorsement.
              </>
            }
          />
        )}

        {articleState.openDeleteModal && (
          <AlertModal
            open={articleState.openDeleteModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_DELETE_POST_MODAL',
                payload: false,
                key: 'openDeleteModal',
              });
            }}
            onSubmit={deleteArticle}
            loading={articleState.loading}
            description={
              article.isDraft ? (
                <>
                  Are you sure you want to delete this draft post?
                  <br />
                  This draft post will be deleted permanently from your drafts.
                </>
              ) : (
                ''
              )
            }
          />
        )}

        {articleState.openPostConfirmationModal && (
          <AlertModal
            title="Confirm"
            open={articleState.openPostConfirmationModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_POST_CONFIRMATION_MODAL',
                payload: false,
                key: 'openPostConfirmationModal',
              });
            }}
            onSubmit={publishArticle}
            loading={articleState.loading}
            submitButtonName="Post"
            submitButtonColor="primary"
            description="You are about to make this draft public by posting it to the feed."
          />
        )}

        {articleState.openTransferDraftModal && (
          <AssignDraftModal
            open={articleState.openTransferDraftModal}
            setOpen={() =>
              dispatch({
                type: 'TOGGLE_TRANSFER_DRAFT_MODAL',
                payload: false,
                key: 'openTransferDraftModal',
              })
            }
            article={article}
            shouldRedirect={isDetailedView}
          />
        )}

        {articleState.openInviteModal && (
          <InviteDraftCollabModal
            open={articleState.openInviteModal}
            setOpen={() =>
              dispatch({ type: 'TOGGLE_INVITE_MODAL', payload: false, key: 'openInviteModal' })
            }
            article={article}
            userMap={userMap}
          />
        )}

        {articleState.openEndorseInteractionsModal && (
          <EndoresementInteractionModal
            open={articleState.openEndorseInteractionsModal}
            setOpen={() => {
              dispatch({
                type: 'TOGGLE_ENDORSE_INTERACTIONS_MODAL',
                payload: false,
                key: 'openEndorseInteractionsModal',
              });
            }}
            endorsements={article.endorsements}
          />
        )}

        {articleState.openRejectModal && (
          <RejectDraftModal
            open={articleState.openRejectModal}
            setOpen={() =>
              dispatch({ type: 'TOGGLE_REJECT_MODAL', payload: false, key: 'openRejectModal' })
            }
            article={article}
            shouldRedirect={isDetailedView}
          />
        )}

        {articleState.openCollaborators && (
          <DraftCollaborators
            open={articleState.openCollaborators}
            setOpen={() =>
              dispatch({
                type: 'TOGGLE_COLLABORATORS_MODAL',
                payload: false,
                key: 'openCollaborators',
              })
            }
            userMap={userMap}
            collaborators={article?.collaborators || []}
            article={article}
          />
        )}

        {articleState.openNotRelevantArticleModal && project && (
          <NotProjectRelevantArticle
            open={articleState.openNotRelevantArticleModal}
            setOpen={() =>
              dispatch({
                type: 'TOGGLE_NOT_RELEVANT_ARTICLE_MODAL',
                payload: false,
                key: 'openNotRelevantArticleModal',
              })
            }
            articleId={article.id}
            project={project}
          />
        )}
      </Suspense>
    </>
  );
}

export default ArticleModals;
