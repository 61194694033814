import { JavaAPI, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';

class StakeholdersAPI extends JavaAPI {
  public searchStakeholders(companyId: string, keyword: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SEARCH, {
      companyId,
      novaSearchKeyword: keyword,
      novaSearchType: 'stakeholders',
    });
  }

  public getStakeholder(companyId: string, stakeholderId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER.replace(':companyId', companyId).replace(
        ':stakeholderId',
        stakeholderId,
      )}`,
    );
  }

  public getStakeholderArticles(stakeholderId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER_ARTICLES.replace(':stakeholderId', stakeholderId)}`,
    );
  }

  public getStakeholders(companyId: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDERS.replace(':companyId', companyId)}`);
  }

  public getStakeholdersPagination(companyId: string, pageNo: string, pageSize: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDERS_PAGINATION.replace(':companyId', companyId)
        .replace(':pageNo', pageNo)
        .replace(':pageSize', pageSize)}`,
    );
  }

  public addStakeholder(companyId: string, payload: any) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_STAKEHOLDER.replace(':companyId', companyId),
      payload,
    );
  }

  public addScorecard(companyId: string, projectId: string, payload: any) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_SCORECARD.replace(':companyId', companyId).replace(
        ':projectId',
        projectId,
      ),
      payload,
    );
  }

  public editScorecard(payload: any) {
    return this.put(JAVA_SERVICE_ENDPOINTS.EDIT_SCORECARD, payload);
  }
}

export default new StakeholdersAPI();
