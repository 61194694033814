import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { FileActivityType } from '../apis/HomeAPI';
import ViewDocumentsModal from './ViewDocumentsModal';

type FormatDocumentsActivityProps = {
  documents: FileActivityType[];
};

function FormatDocumentsActivity({ documents }: FormatDocumentsActivityProps) {
  const [openDocumentsModal, setOpenDocumentsModal] = useState(false);

  return (
    <>
      <Box component="span">
        <TotalDocuments as="strong" onClick={() => setOpenDocumentsModal(true)}>
          {documents.length} {documents.length > 1 ? 'files' : 'file'}
        </TotalDocuments>{' '}
        {documents.length > 1 ? 'are' : 'is'} now searchable in BuildWise
      </Box>

      {openDocumentsModal && (
        <ViewDocumentsModal
          open={openDocumentsModal}
          setOpen={setOpenDocumentsModal}
          documents={documents}
        />
      )}
    </>
  );
}

const TotalDocuments = styled(Typography, { label: 'TotalDocuments' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.tuna,
  fontSize: 14,
  fontWeight: 700,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FormatDocumentsActivity;
