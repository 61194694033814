import { RenderTemplate, TemplateType } from '@buildwise/libs/company-admin';
import { Dialog, Typography, Button, DialogContent, DialogActions, Box } from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import AlertModal from 'components/utils/AlertModal';
import HandleOperation from 'components/utils/HandleOperation';
import { debounce } from 'lodash';
import Search from 'pages/knowledge/components/SearchComponent';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

type BrowseTemplatesModalProps = {
  hasNewChanges: boolean;
  open: boolean;
  template: TemplateType | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSelect: (template: TemplateType) => void;
  templates: TemplateType[];
};

function BrowseTemplatesModal({
  hasNewChanges,
  open,
  template: lastSelectedTemplate,
  templates,
  setOpen,
  onSelect,
}: BrowseTemplatesModalProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType | undefined>(
    lastSelectedTemplate,
  );
  const [confirmNewTemplate, setConfirmNewTemplate] = useState(false);

  const searchedTemplates = useMemo(() => {
    if (searchTerm) {
      return templates.filter((template) =>
        template.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return templates;
  }, [templates, searchTerm]);

  const closeModal = () => {
    setOpen(false);
  };

  const handleClick = (template: TemplateType) => {
    setSelectedTemplate(template);
  };

  const handleDoubleClick = (template: TemplateType) => {
    if (template.id !== lastSelectedTemplate?.id) {
      setSelectedTemplate(template);
      if (hasNewChanges) {
        setConfirmNewTemplate(true);
      } else {
        onSelect(template);
      }
    } else {
      closeModal();
    }
  };

  const handleConfirm = () => {
    onSelect(selectedTemplate!);
    setConfirmNewTemplate(false);
  };

  const handleNext = () => {
    if (hasNewChanges) {
      setConfirmNewTemplate(true);
    } else {
      if (selectedTemplate?.id !== lastSelectedTemplate?.id) {
        onSelect(selectedTemplate!);
      } else {
        closeModal();
      }
    }
  };

  const handleCancel = () => {
    setSelectedTemplate(lastSelectedTemplate);
  };

  const updateSearch = (value: string) => {
    setSearchTerm(value.trim());
  };
  const debounceFn = useCallback(debounce(updateSearch, 300), []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="templates-dialog-title"
      aria-describedby="templates-dialog-description"
      onClose={closeModal}
      sx={{ '& .MuiPaper-root': { height: '80%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="templates-dialog-title">
        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: 'black' }}>
          Select Template
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers id="templates-dialog-description" sx={{ pt: 0 }}>
        <Box
          sx={{
            position: 'sticky',
            top: '0',
            backgroundColor: 'white',
            zIndex: 1,
            padding: '16px 0 24px',
          }}
        >
          <Search
            value={searchTerm}
            placeholder="Search for Templates"
            setValue={debounceFn}
            filled
            triggerOnEnter
          />
        </Box>
        <HandleOperation<TemplateType>
          noDataComponent={
            searchTerm && searchedTemplates.length === 0 ? (
              <>
                No templates found with <b>"{searchTerm}"</b>
              </>
            ) : (
              <>No templates found</>
            )
          }
          data={searchTerm ? searchedTemplates : templates}
        >
          {searchedTemplates.length > 0 &&
            searchedTemplates.map((template) => (
              <RenderTemplate
                key={template.id}
                template={template}
                variant="browse"
                hideMenu
                onDoubleClick={handleDoubleClick}
                onClick={handleClick}
                highlightTemplateId={selectedTemplate?.id || ''}
              />
            ))}
        </HandleOperation>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!selectedTemplate} onClick={handleNext}>
          Next
        </Button>
      </DialogActions>
      {confirmNewTemplate && (
        <AlertModal
          open={confirmNewTemplate}
          setOpen={setConfirmNewTemplate}
          onSubmit={handleConfirm}
          handleCancel={handleCancel}
          handleClose={handleCancel}
          title="Use Template"
          submitButtonName="Use Template"
          description={<>All changes made to your draft will be overridden.</>}
        />
      )}
    </Dialog>
  );
}

export default BrowseTemplatesModal;
