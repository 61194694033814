import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Avatar,
  IconButton,
  Divider,
  InputBase,
} from '@mui/material';
import SelectMenu from 'components/common/menu/SelectMenu';
import { VIEW_DRAFT } from 'constants/routes';
import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import SendPlane2FillIcon from 'remixicon-react/SendPlane2FillIcon';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import { highlightSearchkeyWord, formatDate, getAvatarBgColor } from 'utility/helpers';
import RFIComments from './RFIComments';
import RenderFilteredInsightStatus from './RenderFilteredInsightStatus';
import {
  BusinessObjectActivityLog,
  MeetingMinutes,
} from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';
import { MenuItemType } from 'components/drawer/RenderMenu';
import { ArticleResponse } from 'pages/article/hooks/useArticleDetailsQuery';
import { styled } from '@mui/material/styles';
import useUserData from 'hooks/useUserData';
import MeetingMinutesCategories from './MeetingMinutesCategories';
import PdfViewer from 'pages/search/PdfViewer';
import FileIcon from 'pages/search/components/FileIcon';

type RenderMeetingMinutesProps = {
  comment: string;
  draftDetails?: ArticleResponse;
  filteredRFIComments: BusinessObjectActivityLog[];
  insightMenu: MenuItemType[];
  isLoading: boolean;
  meetingMinutes: MeetingMinutes;
  savingComment: boolean;
  searchKeyword?: string;
  addComment: () => Promise<void>;
  handleKeyDown: (event: KeyboardEvent) => void;
  setComment: Dispatch<SetStateAction<string>>;
};

function RenderMeetingMinutes({
  comment,
  draftDetails,
  filteredRFIComments,
  insightMenu,
  isLoading,
  meetingMinutes,
  savingComment,
  searchKeyword,
  addComment,
  handleKeyDown,
  setComment,
}: RenderMeetingMinutesProps) {
  const { state } = useLocation();
  const { user } = useUserData();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    name: string;
    url: string;
  } | null>(null);

  return (
    <>
      <Box>
        <$Subject
          variant="h5"
          dangerouslySetInnerHTML={{
            __html: highlightSearchkeyWord(meetingMinutes.title, searchKeyword),
          }}
        />
        <Typography>{meetingMinutes?.description || ''}</Typography>
        {/* <$InsightQuestionBody
          dangerouslySetInnerHTML={{
            __html: highlightSearchkeyWord(
              meetingMinutes?.insightSnippets?.join(' ') || '',
              searchKeyword,
              true,
            ),
          }}
        /> */}
      </Box>
      <Box>
        <$BoldText>Project Name</$BoldText>
        <Box display="flex" alignItems="center">
          {meetingMinutes?.procore_project_name ? (
            <>
              <BuildingLineIcon size={20} style={{ marginRight: 4 }} />
              <Typography>{meetingMinutes?.procore_project_name}</Typography>
            </>
          ) : (
            <>&mdash;&mdash;</>
          )}
        </Box>
        {/* <$BoldText>Project Name</$BoldText>
              <Link
                to={`${PROJECT.replace(':projectId', project?.id || meetingMinutes.projectId || '')}${
                  searchKeyword ? `?q=${searchKeyword}` : ''
                }#rfis`}
                state={searchKeyword && { ...state, project }}
                target="_blank"
              >
                {(meetingMinutes as InsightRfiType).projectName || project?.name}
              </Link> */}
        <$BoldText>Created On</$BoldText>
        <Typography>{formatDate(meetingMinutes.starts_at)}</Typography>
        {/* <$BoldText>Created By</$BoldText>
        <Typography>
          {meetingMinutes.created_by.name || meetingMinutes.created_by?.login ? (
            <>
              {meetingMinutes.created_by.name} ({meetingMinutes.created_by?.login})
            </>
          ) : (
            <>&mdash;&mdash;</>
          )}
        </Typography> */}
        {/* <$BoldText>Responsible Contractor</$BoldText>
        <Typography>
          {meetingMinutes.responsible_contractor?.name || <>&mdash;&mdash;</>}
        </Typography> */}
        <$BoldText>Attendees</$BoldText>
        {meetingMinutes?.attendees?.length > 0 ? (
          meetingMinutes.attendees.map((attendee) => (
            <Typography key={attendee.id}>
              {attendee?.login_information?.name} ({attendee?.login_information?.login})
            </Typography>
          ))
        ) : (
          <>&mdash;&mdash;</>
        )}
        <$BoldText>Attachments</$BoldText>
        {meetingMinutes?.attachments?.length > 0 ? (
          meetingMinutes.attachments.map((attachment) => (
            <Box
              key={attachment.id}
              onClick={() => {
                if ((attachment?.filename || attachment?.name)?.endsWith('.pdf')) {
                  setOpenPdfViewer(true);
                  setSelectedFile({
                    name: attachment?.filename || attachment?.name,
                    url: attachment?.url || '',
                  });
                } else {
                  window.open(attachment.url);
                }
              }}
              display="flex"
              alignItems="center"
            >
              <FileIcon
                fileType={(attachment?.filename || attachment?.name).substring(
                  (attachment?.filename || attachment?.name).lastIndexOf('.') + 1,
                )}
              />
              <Attachment>{attachment?.filename || attachment?.name}</Attachment>
            </Box>
          ))
        ) : (
          <>&mdash;&mdash;</>
        )}
        {/* <$BoldText>Reference</$BoldText>
        <Typography>{meetingMinutes.reference || <>&mdash;&mdash;</>}</Typography> */}
        {/* <$BoldText>Question(s)</$BoldText>
        {filteredRFIQuestions ? (
          <$InsightQuestionBody
            dangerouslySetInnerHTML={{
              __html: highlightSearchkeyWord(filteredRFIQuestions, searchKeyword),
            }}
          />
        ) : (
          <>&mdash;&mdash;</>
        )} */}
        <$BoldText>Categories</$BoldText>
        {meetingMinutes?.meeting_categories?.length > 0 ? (
          <MeetingMinutesCategories categories={meetingMinutes.meeting_categories} />
        ) : (
          <>&mdash;&mdash;</>
        )}
        <$BoldText>Set AI Draft Status</$BoldText>
        <Box mt={0.5} display="flex" alignItems="center">
          <RenderFilteredInsightStatus
            status={meetingMinutes.businessObjectStatus}
            showDefaultValue
          />
          <SelectMenu menu={insightMenu}>
            <Box ml={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ borderRadius: 5, minWidth: 'fit-content', px: 1.5, py: 0 }}
              >
                <MoreFillIcon />
              </Button>
            </Box>
          </SelectMenu>
        </Box>
        {meetingMinutes.referenceArticleId && (
          <>
            <$BoldText display="flex" alignItems="center">
              Linked AI Draft &nbsp;
              {isLoading && (
                <CircularProgress
                  size={16}
                  sx={(theme) => ({ color: theme.palette.custom.black })}
                />
              )}
            </$BoldText>
            {!isLoading && (
              <Link
                to={`${VIEW_DRAFT.replace(':articleId', meetingMinutes.referenceArticleId)}${
                  searchKeyword ? `?q=${searchKeyword}` : ''
                }`}
                state={{
                  ...state,
                  isDraft: true,
                }}
                target="_blank"
              >
                {draftDetails?.companyArticle?.article?.title || ''}
              </Link>
            )}
          </>
        )}
        {/* {meetingMinutes.businessObjectStatus === BusinessObjectStatus.REJECTED && (
                <>
                  <$BoldText>Reason for Rejection</$BoldText>
                  <Typography>{meetingMinutes.businessObjectNotes || <>&mdash;&mdash;</>}</Typography>
                </>
              )}
              {meetingMinutes.businessObjectStatus === BusinessObjectStatus.PENDING_APPROVAL && (
                <>
                  <$BoldText>Reason for Park</$BoldText>
                  <Typography>{meetingMinutes.businessObjectNotes || <>&mdash;&mdash;</>}</Typography>
                </>
              )} */}
        <$BoldText mb={1}>Comment(s)</$BoldText>
        <CommentInput
          autoFocus
          multiline
          fullWidth
          placeholder="Add a Comment"
          value={comment}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
          startAdornment={
            <Avatar
              variant="circular"
              src={user?.profileImageURL}
              sx={{
                backgroundColor: getAvatarBgColor(user?.userNameToDisplay || '', 30, 50),
                width: 30,
                height: 30,
                fontSize: 16,
                mr: 1,
              }}
            >
              {user?.userNameToDisplay?.slice(0, 1)?.toUpperCase() || <UserLineIcon size={16} />}
            </Avatar>
          }
          endAdornment={
            <IconButton onClick={addComment}>
              <SendPlane2FillIcon size={22} />
            </IconButton>
          }
          onKeyDown={handleKeyDown}
        />
        {comment && (
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
            <Button onClick={() => setComment('')} sx={{ mr: 0.5 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addComment} disabled={savingComment}>
              {savingComment ? (
                <CircularProgress
                  sx={(theme) => ({ color: theme.palette.custom.black })}
                  size={20}
                />
              ) : (
                'Comment'
              )}
            </Button>
          </Box>
        )}
        <Divider sx={{ my: 2 }} />
        {filteredRFIComments.length === 0 ? (
          <Typography>There are no new comments.</Typography>
        ) : (
          <RFIComments comments={filteredRFIComments} />
        )}

        {openPdfViewer && selectedFile && (
          <PdfViewer open={openPdfViewer} setOpen={setOpenPdfViewer} file={selectedFile} />
        )}
      </Box>
    </>
  );
}

// const $InsightQuestionBody = styled(Box, { label: 'InsightQuestionBody' })(({ theme }) => ({
//   margin: '0',
//   lineHeight: '21px',
//   whiteSpace: 'pre-wrap',
//   '& .highlight': {
//     backgroundColor: 'yellow !important',
//   },
// }));

const $Subject = styled(Typography, { label: 'Subject' })(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  fontWeight: 700,
  color: '#504D64',
  lineHeight: '29px',
  fontSize: '1.2em',
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const Attachment = styled(Typography, { label: 'Attachment' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.link,
}));

const $BoldText = styled(Typography, { label: 'BoldText' })(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: '10px',
}));

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  fontSize: 14,
  lineHeight: '15.6px',
  color: theme.palette.custom.link,
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const CommentInput = styled(InputBase, { label: 'CommentInput' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  borderRadius: 8,
  padding: '6px !important',
  maxHeight: '150px !important',
  overflow: 'hidden !important',
  border: `1px solid ${theme.palette.custom.line}`,
  '&:focus-within': {
    borderColor: `${theme.palette.custom.black} !important`,
  },
  '&:has(.MuiInputBase-input:placeholder-shown)': {
    borderColor: `${theme.palette.custom.black} !important`,
    // borderColor: 'transparent',
  },
  '& .MuiInputBase-input': {
    maxHeight: '150px !important',
    overflow: 'auto !important',
  },
}));

export default RenderMeetingMinutes;
