export const homeQueryKeys = {
  recentActivity: (companyId: string) => ['recent-activty', companyId],
  userMetrics: (companyId: string, username: string) => ['user-metrics', companyId, username],
  recommendedPosts: (companyId: string, username: string) => [
    'recommended-posts',
    companyId,
    username,
  ],
  endorsedPosts: (companyId: string) => ['endorsed-posts', companyId],
  popularPosts: (companyId: string, duration: string) => ['popular-posts', companyId, duration],
};
