import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { CommentType } from '@buildwise/pages/article/hooks/useArticleDetailsQuery';
import ViewCommentsModal from './ViewCommentsModal';

type FormatCommentsActivityProps = {
  comments: CommentType[];
};

function FormatCommentsActivity({ comments }: FormatCommentsActivityProps) {
  const [openCommentsModal, setOpenCommentsModal] = useState(false);

  return (
    <>
      <Box component="span">
        <TotalComments as="strong" onClick={() => setOpenCommentsModal(true)}>
          {comments.length} {comments.length > 1 ? 'comments' : 'comment'}
        </TotalComments>{' '}
        {comments.length > 1 ? 'are' : 'is'} added to posts recently
      </Box>

      {openCommentsModal && (
        <ViewCommentsModal
          open={openCommentsModal}
          setOpen={setOpenCommentsModal}
          comments={comments}
        />
      )}
    </>
  );
}

const TotalComments = styled(Typography, { label: 'TotalComments' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.tuna,
  fontSize: 14,
  fontWeight: 700,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FormatCommentsActivity;
