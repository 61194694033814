import useUserData from 'hooks/useUserData';
import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { AxiosResponse } from 'axios';
import { updateTemplatesCache } from '../queries/useTemplatesQuery';
import TemplatesAPI from '../apis/TemplatesAPI';
import { TemplateType } from '../types';

export type TemplateFormValuesType = {
  title: string;
  tag?: string;
  labels: string[];
  description: string;
};

type UseTemplatesFormArgs = {
  closeModal: () => void;
  template?: TemplateType;
};

function useTemplatesForm(args?: UseTemplatesFormArgs) {
  const { template, closeModal } = args!;
  const { companyId } = useUserData();
  const [loading, setLoading] = useState(false);
  const templateForm = useForm<TemplateFormValuesType>({
    mode: 'onTouched',
    defaultValues: {
      title: template?.title || '',
      tag: '',
      labels: template?.labels || [],
      description: template?.description || '',
    },
  });

  const handleLabelKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      const { tag, labels } = templateForm.getValues();
      const tagValue = tag?.replaceAll(',', '').trim();
      const noDuplicate = !labels?.find((label) => label.toLowerCase() === tagValue?.toLowerCase());
      if (tagValue && noDuplicate) {
        templateForm.setValue('labels', [...(labels || []), tagValue]);
      }

      templateForm.setValue('tag', '');
    }
  };
  const handleRemoveLabel = (label: string) => {
    const { labels } = templateForm.getValues();
    templateForm.setValue(
      'labels',
      labels?.filter((labelVal) => labelVal.toLowerCase() !== label.toLowerCase()),
    );
  };

  const onSubmit = async (values: TemplateFormValuesType) => {
    const payload = {
      title: values.title.trim(),
      labels: values?.labels || [],
      description: values.description.trim(),
    };

    try {
      setLoading(true);
      let templateRes: AxiosResponse<TemplateType> | undefined = undefined;

      if (template) {
        templateRes = await TemplatesAPI.updateTemplate(companyId, template.id, payload);
      } else {
        templateRes = await TemplatesAPI.addTemplate(companyId, payload);
      }

      updateTemplatesCache(
        companyId,
        templateRes.data,
        template ? 'update-template' : 'add-template',
      );
      toast.success(`Template ${template ? 'updated' : 'added'} successfully`);
      closeModal();
    } catch (error) {
      console.error('SAVE_TEMPLATE_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSave = templateForm.handleSubmit(onSubmit);

  return {
    templateForm,
    loading,
    handleSave,
    handleLabelKeyDown,
    handleRemoveLabel,
  };
}

export default useTemplatesForm;
