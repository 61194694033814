import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import RenderPlayistArticles from './RenderPlayistArticles';
import AddPostsToPlaylistModal from './AddPostsToPlaylistModal';
import { ArticleType } from '../hooks/useArticlesQuery';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import _ from 'lodash';

const RenderPostsInEditPlayListModal: React.FC<{
  playListArticles: ArticleType[];
  article: ArticleType;
  getValues: UseFormGetValues<PostFormData>;
  setValue: UseFormSetValue<PostFormData>;
}> = ({ playListArticles, article, getValues, setValue }) => {
  const [openAddPostsToPlaylistModal, setAddPostsToPlaylistModalOpen] = useState(false);
  const [duplicatePlayListArticles, setDuplicatePlayListArticles] =
    useState<ArticleType[]>(playListArticles);

  useEffect(() => {
    setDuplicatePlayListArticles(playListArticles);
  }, [playListArticles]);

  const addToPlayListArticles = (playListArticle: ArticleType[]) => {
    const copyPlayListArticles = getValues()?.playListArticles || [];
    const concatenatedResult = _.uniqBy(copyPlayListArticles.concat(playListArticle), 'id');
    setDuplicatePlayListArticles([...concatenatedResult]);
    const playListArticleIds = getValues()?.playListArticleIds || [];
    const newPlayListArticleIds = playListArticle.map((playlist) => playlist.id);
    setValue?.('playListArticleIds', _.uniq([...playListArticleIds, ...newPlayListArticleIds]), {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue?.('playListArticles', concatenatedResult, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Box sx={{ margin: '1rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 700, color: 'black' }}>Posts in this Collection</Typography>
        <Button
          startIcon={<AddLineIcon />}
          variant="outlined"
          onClick={() => setAddPostsToPlaylistModalOpen(true)}
        >
          Add Posts
        </Button>
      </Box>
      <Box>
        <RenderPlayistArticles
          posts={duplicatePlayListArticles}
          getValues={getValues}
          setValue={setValue}
          draggable
        />
      </Box>
      {openAddPostsToPlaylistModal && (
        <AddPostsToPlaylistModal
          open={openAddPostsToPlaylistModal}
          setOpen={setAddPostsToPlaylistModalOpen}
          playListArticle={article}
          isEdit={true}
          addToPlayListArticles={addToPlayListArticles}
          getValues={getValues}
        />
      )}
    </Box>
  );
};

export default RenderPostsInEditPlayListModal;
