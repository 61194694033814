import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '../../../libs/services/endpoints';
import {
  ProjectType,
  ProjectContactType,
  ProjectRecommendationsType,
} from '../../projects/hooks/useProjectsQuery';
import useUserData from '../../../hooks/useUserData';
import ProjectsAPI from '../../../api/ProjectsAPI';
import { queryClient } from '../../../config/queryClient';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ConfigEntryType, ConfigType, FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';
import * as _ from 'lodash';

export type UserFormType = ConfigType & {
  questions: ConfigEntryType[];
  configId: string;
  projectId: string;
  formName: string;
  formCategoryDescription: string;
  formType: string;
  formCategory: string;
  assignedTo: string;
  assignedToUserDisplayName: string;
  status: string;
  dueDate: Date | null;
  updatedBy: string;
  updatedAt: string | Date;
  createdAt: string | Date;
  createdBy: string;
  contact?: any;
  project?: any;
};

export type ProjectProgressCardFormType = UserFormType;
export type ScoreCardFormType = UserFormType & {
  partnerId: string;
  rating: string;
  contactId: string;
};

export const useProjectDetailsQuery = ({
  projectId,
  shouldFetch = true,
}: {
  projectId: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const projectDetailsQueryKey = [JAVA_SERVICE_ENDPOINTS.GET_PROJECT, companyId, projectId];

  const { data: projectData, ...rest } = useQuery<AxiosResponse<ProjectType>, Error>(
    projectDetailsQueryKey,
    () => ProjectsAPI.getProject(companyId, projectId),
    {
      refetchOnMount: 'always',
      staleTime: 2 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!projectId && !!companyId && shouldFetch,
    },
  );
  const project = useMemo(() => (projectData?.data || {}) as ProjectType, [projectData]);

  const updatePostInProjectPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<ProjectType, Error> | undefined>(
      projectDetailsQueryKey,
      (old) => {
        if (old) {
          let newPlaylists = [...(old?.data?.articles || [])];
          let addToPlaylistIdsUpdatedCount = 0;
          let removeFromPlaylistIdsUpdatedCount = 0;

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            for (let i = 0; i < newPlaylists.length; i++) {
              const playlist = newPlaylists[i];
              const newPlaylistArticleIds = [...playlist?.playListArticleIds];
              const newPlaylistArticles = [...playlist?.playListArticles];

              // add article to playlist
              if (addToPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.push(article.id);
                newPlaylistArticles.push(article);
              }

              // remove article from playlist
              if (removeFromPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }
              }

              newPlaylists[i] = {
                ...playlist,
                playListArticleIds: newPlaylistArticleIds,
                playListArticles: newPlaylistArticles,
              };

              // stop updating cache when all collections updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            data: {
              ...old.data,
              articles: newPlaylists,
            },
          };
        }

        return old;
      },
    );
  };

  return {
    project,
    ...rest,
    updatePostInProjectPlaylistsCache,
  };
};

export const useProjectScorecardsQuery = ({
  projectId,
  shouldFetch = true,
}: {
  projectId: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const { data, isLoading } = useQuery<AxiosResponse<any>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS, companyId, projectId],
    () => ProjectsAPI.getProjectForms(companyId, projectId),
    {
      staleTime: 60 * 1000,
      enabled: !!projectId && !!companyId && shouldFetch,
      refetchOnMount: 'always',
    },
  );
  const scorecards: ScoreCardFormType[] = useMemo(
    () =>
      data?.data?.forms
        ?.filter((item) => item?.formCategory === FORMS_CONFIG_CATEGORY_TYPES.SCORECARD)
        ?.map((item: ScoreCardFormType) => ({
          id: item.id,
          configName: item.formName,
          configType: item.formType,
          configCategory: item.formCategory,
          description: item.formCategoryDescription,
          entries: item.questions,
          sortFlag: item.sortFlag,
          title: item.title,
          companyId,
          projectId: item.projectId,
          partnerId: item.contactId,
          contact: item.contact,
          rating: item.rating,
          configId: item.configId,
          updatedAt: item.updatedAt,
          updatedBy: item.updatedBy,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          updatedByDisplayName: data?.data?.userMap[item.updatedBy]?.[0]?.['displayName'],
          createdByDisplayName: data?.data?.userMap[item.createdBy]?.[0]?.['displayName'],
          createdByTitle: data?.data?.userMap[item.createdBy]?.[0]?.['title'],
          AssignedToTitle: data?.data?.userMap[item.assignedTo]?.[0]?.['title'],
          status: item.status,
          assignedTo: item.assignedTo,
          assignedToUserDisplayName: data?.data?.userMap[item.assignedTo]?.[0]?.['displayName'],
          profileImageURL: data?.data?.userMap[item.assignedTo]?.[0]?.['profileImageURL'],
        })) || [],
    [data],
  );

  const sortedScoreCards = _.orderBy(scorecards, ['updatedAt'], ['desc']);

  const updateProjectScoreCardsCache = ({
    action,
    removeScoreCardIds = [],
    scorecard = '',
  }: {
    action: 'add' | 'remove' | 'update';
    removeScoreCardIds?: string[];
    scorecard?: any;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS,
      companyId,
      projectId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueriesData<AxiosResponse<any> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS, companyId, projectId],
        (old) => {
          if (old) {
            let updatedScoreCards = [...(old?.data?.forms || [])];
            let updatedUserMap = { ...(old?.data?.userMap || {}) };

            if (action === 'add' && scorecard) {
              updatedScoreCards.unshift({ ...scorecard.form });
            }

            if (action === 'remove') {
              updatedScoreCards = updatedScoreCards.filter(
                (scorecard) => !removeScoreCardIds.includes(scorecard.id),
              );
            }

            if (action === 'update' && scorecard) {
              const index = updatedScoreCards.findIndex((item) => item.id === scorecard.form.id);
              if (index > -1) {
                updatedScoreCards[index] = { ...scorecard.form };
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                forms: updatedScoreCards,
                userMap: { ...updatedUserMap, ...scorecard?.userMap },
              },
            };
          }
          return old;
        },
      );
    }
  };

  return {
    scorecards: sortedScoreCards,
    isLoading,
    updateProjectScoreCardsCache,
  };
};

export const useProjectProgresscardsQuery = ({
  projectId,
  shouldFetch = true,
}: {
  projectId: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const { data, isLoading } = useQuery<AxiosResponse<any>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS, companyId, projectId],
    () => ProjectsAPI.getProjectForms(companyId, projectId),
    {
      staleTime: 60 * 1000,
      enabled: !!projectId && !!companyId && shouldFetch,
    },
  );
  const progresscards: ProjectProgressCardFormType[] = useMemo(
    () =>
      data?.data?.forms
        ?.filter((item) => item?.formCategory === FORMS_CONFIG_CATEGORY_TYPES.PROJECT_PROGRESS_CARD)
        ?.map((item: ProjectProgressCardFormType) => ({
          id: item.id,
          configName: item.formName,
          configType: item.formType,
          configCategory: item.formCategory,
          description: item.formCategoryDescription,
          entries: item.questions,
          sortFlag: item.sortFlag,
          title: item.title,
          companyId,
          projectId: item.projectId,
          configId: item.configId,
          updatedAt: item.updatedAt,
          updatedBy: item.updatedBy,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          updatedByDisplayName: data?.data?.userMap[item.updatedBy]?.[0]?.['displayName'],
          status: item.status,
          assignedTo: item.assignedTo,
          assignedToUserDisplayName: data?.data?.userMap[item.assignedTo]?.[0]['displayName'],
        })) || [],
    [data],
  );

  const updateProjectProgressCardsCache = ({
    action,
    removeProgressCardIds = [],
    progressCard = '',
  }: {
    action: 'add' | 'remove' | 'update';
    removeProgressCardIds?: string[];
    progressCard?: any;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS,
      companyId,
      projectId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueriesData<AxiosResponse<any> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS, companyId, projectId],
        (old) => {
          if (old) {
            let updatedProgressCards = [...(old?.data?.forms || [])];
            let updatedUserMap = { ...(old?.data?.userMap || {}) };

            if (action === 'add' && progressCard) {
              updatedProgressCards.push({ ...progressCard.form });
            }

            if (action === 'remove') {
              updatedProgressCards = updatedProgressCards.filter(
                (progressCard) => !removeProgressCardIds.includes(progressCard.id),
              );
            }

            if (action === 'update' && progressCard) {
              const index = updatedProgressCards.findIndex(
                (item) => item.id === progressCard.form.id,
              );
              if (index > -1) {
                updatedProgressCards[index] = { ...progressCard.form };
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                forms: updatedProgressCards,
                userMap: { ...updatedUserMap, ...progressCard?.userMap },
              },
            };
          }
          return old;
        },
      );
    }
  };

  return {
    progresscards,
    isLoading,
    updateProjectProgressCardsCache,
  };
};

export const invalidateProjectQuery = (companyId, projectId) => {
  queryClient.refetchQueries([JAVA_SERVICE_ENDPOINTS.GET_PROJECT, companyId, projectId]);
};

export const invalidateProjectScorecardsQuery = (companyId, projectId) => {
  queryClient.refetchQueries([
    JAVA_SERVICE_ENDPOINTS.GET_SCORECARDS.replace(':companyId', companyId),
    projectId,
  ]);
};

export const updateProjectDetailsCache = (companyId: string, updatedProject: ProjectType) => {
  queryClient.setQueriesData<AxiosResponse<ProjectType> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECT, companyId, updatedProject.id],
    (oldData: AxiosResponse<ProjectType> | undefined) => {
      if (oldData) {
        return {
          ...oldData,
          data: updatedProject,
        };
      }

      return oldData;
    },
  );
};

export const useProjectRecommendationsQuery = ({
  projectId,
  shouldFetch = true,
}: {
  projectId: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const projectRecommendationsQueryKey = [
    JAVA_SERVICE_ENDPOINTS.GET_PROJECT_RECOMMENDATIONS,
    companyId,
    projectId,
  ];

  const { data: projectData, ...rest } = useQuery<AxiosResponse<ProjectRecommendationsType>, Error>(
    projectRecommendationsQueryKey,
    () => ProjectsAPI.getProjectRecommendations(companyId, projectId),
    {
      refetchOnMount: 'always',
      staleTime: 2 * 60 * 1000,
      enabled: !!projectId && !!companyId && shouldFetch,
    },
  );
  const project = useMemo(
    () => (projectData?.data || {}) as ProjectRecommendationsType,
    [projectData],
  );

  const updatePostInProjectRecommendedPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<ProjectRecommendationsType, Error> | undefined>(
      projectRecommendationsQueryKey,
      (old) => {
        if (old) {
          const newPlaylistsMap = { ...old?.data?.recommendations.articleMap };
          const playlistIds = Object.keys(newPlaylistsMap);
          let addToPlaylistIdsUpdatedCount = 0;
          let removeFromPlaylistIdsUpdatedCount = 0;

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            for (let i = 0; i < playlistIds.length; i++) {
              const playlist = newPlaylistsMap[playlistIds[i]];

              // add article to playlist
              if (addToPlaylistIds.includes(playlist.id)) {
                newPlaylistsMap[playlist.id] = {
                  ...playlist,
                  playListArticleIds: [...playlist?.playListArticleIds, article.id],
                  playListArticles: [...playlist?.playListArticles, article],
                };
                addToPlaylistIdsUpdatedCount++;
              }

              // remove article from playlist
              if (removeFromPlaylistIds.includes(playlist.id)) {
                const newPlaylistArticleIds = [...playlist?.playListArticleIds];
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const newPlaylistArticles = [...playlist?.playListArticles];
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }

                newPlaylistsMap[playlist.id] = {
                  ...playlist,
                  playListArticleIds: newPlaylistArticleIds,
                  playListArticles: newPlaylistArticles,
                };
                removeFromPlaylistIdsUpdatedCount++;
              }

              // stop updating cache when all collections updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            data: {
              ...old.data,
              recommendations: {
                ...old.data.recommendations,
                articleMap: newPlaylistsMap,
              },
            },
          };
        }

        return old;
      },
    );
  };

  return {
    project,
    ...rest,
    updatePostInProjectRecommendedPlaylistsCache,
  };
};

export const updateProjectScorecardsCache = (
  companyId: string,
  projectId: string,
  partners: ProjectContactType[],
) => {
  queryClient.setQueriesData(
    [JAVA_SERVICE_ENDPOINTS.GET_SCORECARDS.replace(':companyId', companyId), projectId],
    (old: any) => {
      if (old) {
        const scoreCards = old.data.scoreCards;
        for (const scoreCard of scoreCards) {
          const partnerIndex = partners.findIndex((p) => p.id === scoreCard.contractorId);
          if (partnerIndex > -1) scoreCard.contractorRole = partners[partnerIndex].contactTypes[0];
        }
      }
      return old;
    },
  );
};

export const updateProjectRecommendationsQueryCache = ({
  companyId,
  projectId,
  action,
  project,
  articles,
}: {
  companyId: string;
  projectId: string;
  action: 'add-articles' | 'update-article' | 'delete-article' | 'update-project';
  project?: ProjectRecommendationsType;
  articles?: ArticleType[];
}) => {
  queryClient.setQueriesData<AxiosResponse<ProjectRecommendationsType> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECT_RECOMMENDATIONS, companyId, projectId],
    (old: AxiosResponse<ProjectRecommendationsType> | undefined) => {
      if (old) {
        let updatedArticleMap = { ...old.data.recommendations.articleMap };
        let updatedSpecSectionDetailsMap = { ...old.data.recommendations.specSectionDetailsMap };

        if (action === 'add-articles' && articles) {
          articles?.forEach((article) => {
            updatedArticleMap = {
              ...updatedArticleMap,
              [article.id]: article,
            };

            const addArticleSpecSectionKeys = article.specSections?.map((specSection) =>
              specSection.split('-')[0].trim(),
            ) || [' '];
            addArticleSpecSectionKeys?.forEach((key, index) => {
              if (updatedSpecSectionDetailsMap[key]) {
                const updatedArticleIds = [
                  ...(updatedSpecSectionDetailsMap[key]?.articleIds || []),
                ];
                updatedArticleIds.push(article.id);
                updatedSpecSectionDetailsMap[key] = {
                  ...updatedSpecSectionDetailsMap[key],
                  articleIds: updatedArticleIds,
                };
              } else {
                let division = '';
                let divisionName = '';
                if (article.divisions?.length) {
                  const articleDivision = article.divisions[0].split('-');
                  division = articleDivision?.[0]?.trim() || '';
                  divisionName = articleDivision?.[1]?.trim() || '';
                }

                updatedSpecSectionDetailsMap[key] = {
                  articleIds: [article.id],
                  division,
                  divisionName,
                  specSection: key,
                  specSectionName: article?.specSections?.[index]?.split('-')?.[1]?.trim() || '',
                };
              }
            });
          });
        }

        if (action === 'update-article' && articles) {
          const article = articles?.[0];
          if (updatedArticleMap[article.id]) {
            updatedArticleMap[article.id] = {
              ...updatedArticleMap[article.id],
              ...article,
            };
          }
        }

        if (action === 'delete-article' && articles) {
          const article = articles?.[0];
          delete updatedArticleMap[article.id];
        }

        return {
          ...old,
          data: {
            ...old.data,
            recommendations: {
              ...old.data.recommendations,
              articleMap: updatedArticleMap,
              specSectionDetailsMap: updatedSpecSectionDetailsMap,
            },
            ...project,
          },
        };
      }

      return old;
    },
  );
};

export default useProjectDetailsQuery;
