import { FormControl, Autocomplete, TextField, CircularProgress, Chip } from '@mui/material';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { usePlaylistsQuery } from 'pages/knowledge/hooks/useArticlesQuery';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

type PlaylistOptionType = { label: string; value: string };

type PostPlaylistsProps = {
  isEditing: boolean;
  isExpanded: boolean;
};

function PostPlaylists({ isEditing, isExpanded }: PostPlaylistsProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const selectedPlaylists = watch('playlists');
  const { playlists, isLoading: playlistsLoading } = usePlaylistsQuery({
    shouldFetch: !isEditing && isExpanded,
  });
  const playlistsOptions = useMemo(
    () => playlists.map((playlist) => ({ label: playlist.title, value: playlist.id })),
    [playlists],
  );

  const handleSelectPlaylists = (_: unknown, playlistIds: PlaylistOptionType[]) => {
    setValue('playlists', playlistIds, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <FormControl fullWidth sx={{ marginBottom: 3 }}>
      <Autocomplete
        multiple
        limitTags={3}
        disableCloseOnSelect
        onChange={handleSelectPlaylists}
        options={playlistsOptions}
        fullWidth
        autoHighlight
        value={selectedPlaylists || []}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        noOptionsText={playlistsLoading ? 'Loading...' : 'No Collections Found'}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Add to Collections"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {playlistsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.label}
              deleteIcon={<CloseLineIcon size={20} />}
            />
          ))
        }
        disabled={isSubmitting}
      />
    </FormControl>
  );
}

export default PostPlaylists;
