import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FC, ChangeEvent, ReactNode, useRef, MouseEvent } from 'react';

const $FileLabel = styled('label')(() => ({
  cursor: 'pointer',
  width: 'fit-content',
  display: 'block',
}));

type UploadProps = {
  acceptedFiles?: string[];
  children: ReactNode;
  doNotUpload?: boolean;
  name?: string;
  multiple?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Upload: FC<UploadProps> = ({
  acceptedFiles,
  children,
  doNotUpload = false,
  name = 'avatar',
  onChange,
  multiple = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenFile = (event: MouseEvent) => {
    if (!doNotUpload) {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <$FileLabel htmlFor="file">
      <input
        type="file"
        multiple={multiple}
        id="file"
        name={name}
        value=""
        ref={inputRef}
        onChange={onChange}
        accept={acceptedFiles?.join(',')}
        hidden
      />
      <Box onClick={handleOpenFile} width="fit-content">
        {children}
      </Box>
    </$FileLabel>
  );
};

export default Upload;
