import { API, GEN_AI_SERVICE_URL } from '@buildwise/libs/services';

class GenAIAPI extends API {
  constructor() {
    super({
      baseURL: GEN_AI_SERVICE_URL,
    });
  }
}

export default GenAIAPI;
