import { InfiniteData, useInfiniteQuery } from 'react-query';
import { homeQueryKeys } from './query-keys';
import { HomeAPI } from '..';
import useUserData from 'hooks/useUserData';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { ArticlesResponse } from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';

function useRecommendedPostsQuery({ username }: { username: string }) {
  const { companyId, isNovaAdmin } = useUserData();
  const {
    data: recommendedArticlesData,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    refetch,
    ...rest
  } = useInfiniteQuery({
    enabled: !!companyId && !!username,
    keepPreviousData: true,
    queryKey: homeQueryKeys.recommendedPosts(companyId, username),
    staleTime: Infinity,
    getNextPageParam: (lastPage: any) =>
      lastPage?.data?.[0]?.totalPages > lastPage?.data?.[0]?.pageNumber
        ? lastPage.data[0].pageNumber + 1
        : undefined,
    queryFn: ({ pageParam = 0 }) =>
      HomeAPI.getRecommendedPosts(companyId, isNovaAdmin ? username : '', pageParam, 10),
  });

  const fetchAllArticles = (pages) => {
    const allArticles: ArticleType[] = [];
    pages?.forEach((page) => {
      if (page.data && Array.isArray(page.data)) {
        allArticles.push(...(page?.data?.[0]?.articles || []));
      }
    });

    return allArticles;
  };

  const updateCache = ({
    action = 'update',
    updatedArticle,
  }: {
    action?: 'update' | 'delete';
    updatedArticle: ArticleType;
  }) => {
    const updateArticle = (
      old: InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined,
    ) => {
      if (old) {
        const pages = [...(old?.pages || [])];
        let pageIndex = -1;
        let itemIndex = -1;

        for (let i = 0; i < pages.length; i++) {
          const articles = pages[i].data[0].articles;
          for (let j = 0; j < articles.length; j++) {
            if (articles[j].id === updatedArticle.id) {
              itemIndex = j;
              break;
            }
          }

          if (itemIndex > -1) {
            pageIndex = i;
            break;
          }
        }

        if (pageIndex === -1) {
          pageIndex = 0;
        }
        const updatedAricles = [...pages[pageIndex].data[0].articles];

        if (action === 'update') {
          if (itemIndex > -1) {
            updatedAricles[itemIndex] = {
              ...updatedAricles[itemIndex],
              ...updatedArticle,
            };
          } else {
            updatedAricles.unshift(updatedArticle);
          }
        }

        if (action === 'delete') {
          updatedAricles.splice(itemIndex, 1);
        }

        pages[pageIndex] = {
          ...pages[pageIndex],
          data: [
            {
              ...pages[pageIndex].data[0],
              articles: updatedAricles,
            },
          ],
        };

        return {
          ...old,
          pages,
        };
      }

      return old;
    };

    const queryStatus = queryClient.getQueryState(
      homeQueryKeys.recommendedPosts(companyId, username),
    );

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined>(
        homeQueryKeys.recommendedPosts(companyId, username),
        (old) => updateArticle(old),
      );
    }
  };

  return {
    recommendedPosts: fetchAllArticles(recommendedArticlesData?.pages),
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    refetch,
    updateCache,
    ...rest,
  };
}

export default useRecommendedPostsQuery;
