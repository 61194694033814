import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { PartnerType } from '@buildwise/pages/stakeholders/hooks/useStakeholdersQuery';
import ViewPartnersModal from './ViewPartnersModal';
import { useBuildWiseModulesQuery } from '@buildwise/pages/knowledge/hooks/usePersonalizeQueries';
import { BUILDWISE_MODULES } from 'components/drawer/constants';
import pluralize, { singular } from 'pluralize';

type FormatUsersActivityProps = {
  partners: PartnerType[];
};

function FormatPartnersActivity({ partners }: FormatUsersActivityProps) {
  const [openPartnersModal, setOpenPartnersModal] = useState(false);
  const { buildwiseModules } = useBuildWiseModulesQuery();
  const { partnersModuleName } = useMemo(
    () => ({
      partnersModuleName:
        buildwiseModules.find((module) => module.entryCode === BUILDWISE_MODULES.PARTNERS)
          ?.entryDescription || BUILDWISE_MODULES.PARTNERS,
    }),
    [buildwiseModules],
  );

  return (
    <>
      <Box component="span">
        <TotalPartners as="strong" onClick={() => setOpenPartnersModal(true)}>
          {partners.length}{' '}
          {partners.length > 1
            ? pluralize(partnersModuleName).toLowerCase()
            : singular(partnersModuleName).toLowerCase()}
        </TotalPartners>{' '}
        {partners.length > 1 ? 'are' : 'is'} assigned to projects
      </Box>

      {openPartnersModal && (
        <ViewPartnersModal
          open={openPartnersModal}
          setOpen={setOpenPartnersModal}
          partners={partners}
        />
      )}
    </>
  );
}

const TotalPartners = styled(Typography, { label: 'TotalPartners' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.tuna,
  fontSize: 14,
  fontWeight: 700,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FormatPartnersActivity;
