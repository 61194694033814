import { FormControl, Autocomplete, TextField, CircularProgress, Chip } from '@mui/material';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { useTopicsQuery } from 'pages/knowledge/hooks/usePersonalizeQueries';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

type PostTopicsProps = {
  isExpanded: boolean;
};

function PostTopics({ isExpanded }: PostTopicsProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const { topics: topicsOptions, isLoading: topicsLoading } = useTopicsQuery({
    shouldFetch: isExpanded,
  });
  const selectedTopics = watch('topics');

  const handleSelectTopics = (_: unknown, topics: string[]) => {
    setValue('topics', topics, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <FormControl fullWidth sx={{ marginBottom: 3 }}>
      <Autocomplete
        multiple
        limitTags={3}
        disableCloseOnSelect
        onChange={handleSelectTopics}
        options={topicsOptions}
        autoHighlight
        value={selectedTopics || []}
        noOptionsText={topicsLoading ? 'Loading...' : 'No Topics Found'}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Topics"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {topicsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option}
              deleteIcon={<CloseLineIcon size={20} />}
            />
          ))
        }
        disabled={isSubmitting}
      />
    </FormControl>
  );
}

export default PostTopics;
