import { Stack, Chip, InputBase } from '@mui/material';
import { SEARCH } from 'constants/routes';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { preventComma } from 'utility/helpers';
import { styled } from '@mui/material/styles';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { KeyboardEvent } from 'react';

type PostLabelsProps = {
  isExpanded: boolean;
  handleLabelKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  removeLabel: (label: string) => void;
};

function PostLabels({ isExpanded, handleLabelKeyDown, removeLabel }: PostLabelsProps) {
  const {
    formState: { isSubmitting },
    watch,
  } = useFormContext<PostFormData>();
  const labels = watch('labels');

  return (
    <>
      {isExpanded && (
        <>
          <TagsInput
            fullWidth
            placeholder="Add tags (optional)"
            onKeyUp={handleLabelKeyDown}
            onKeyDown={preventComma}
            disabled={isSubmitting}
            sx={{ mb: labels.length > 0 ? 1 : 2 }}
          />
          {labels?.length > 0 && (
            <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
              {labels.map((label) => (
                <Chip
                  key={label}
                  onDelete={(event: MouseEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                    removeLabel(label);
                  }}
                  label={label}
                  variant="outlined"
                  size="small"
                  deleteIcon={<CloseLineIcon size={20} />}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'none' },
                  }}
                  component={Link}
                  to={`${SEARCH}?q=${label}`}
                  target="_blank"
                  disabled={isSubmitting}
                />
              ))}
            </Stack>
          )}
        </>
      )}
    </>
  );
}

const TagsInput = styled(InputBase, { label: 'TopicsInput' })({
  fontSize: 16,
});

export default PostLabels;
