import ProjectsAPI from 'api/ProjectsAPI';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export default function useInsightsTypeDraftsQuery(options: { shouldFetch?: boolean } = {}) {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: insightsData, ...rest } = useQuery<AxiosResponse<ArticleType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS_TYPE_DRAFTS, companyId],
    () => ProjectsAPI.getInsightsTypeDrafts(companyId),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
      refetchOnMount: 'always',
    },
  );
  const drafts = useMemo(
    () => (Array.isArray(insightsData?.data) ? insightsData?.data || [] : []),
    [insightsData],
  );

  return {
    drafts,
    ...rest,
  };
}
