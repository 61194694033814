import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';
import { ConfigsByTypeResponse } from 'api/CompanyAPI';
import { ConfigResponse, ConfigType } from 'config/config';

class AdminModuleAPI extends JavaAPI {
  public getAdminConfigsByType(configType: string) {
    return this.get<ConfigsByTypeResponse>(
      JAVA_SERVICE_ENDPOINTS.GET_ADMIN_CONFIGS_BY_TYPE.replace(':configType', configType),
    );
  }

  public updateConfig(config: ConfigType) {
    return this.put<ConfigResponse>(JAVA_SERVICE_ENDPOINTS.UPDATE_CONFIG, config);
  }
}

const AdminAPI = new AdminModuleAPI();
export default AdminAPI;
