import { Button, IconButton } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DOCUMENT_TYPES } from 'components/utils/constants';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { formatFileSize, getExactFileName } from 'utility/helpers';
import ThumbnailIcon from './ThumbnailIcon';
import EditLineIcon from 'remixicon-react/EditLineIcon';

type ThumbnailsProps = {
  disabled?: boolean;
  files: AttachmentType[];
  showEdit?: boolean;
  showEditLogoOnly?: boolean;
  isImage?: (file: AttachmentType) => boolean;
  removeFile?: (attachment: AttachmentType, index: number) => void;
  setLightboxOpen?: (state: { open: boolean; fileName: string }) => void;
};

const RenderThumbnails = ({
  disabled,
  files,
  showEdit = false,
  showEditLogoOnly = false,
  isImage,
  removeFile,
  setLightboxOpen,
}: ThumbnailsProps) => {
  const thumbnails = files.filter((attachment) => !attachment?.imageHashValue);
  const handleClick = (file: AttachmentType) => {
    if (!disabled) {
      let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
      if (file.fileUploaded) {
        fileType = file.fileType.toLowerCase();
      }
      if (
        DOCUMENT_TYPES.includes(`application/${fileType}`) ||
        DOCUMENT_TYPES.includes(`text/${fileType === 'txt' ? 'plain' : fileType}`)
      ) {
        window.open(file.thumbnailOpenUrl as string);
      } else {
        setLightboxOpen?.({ open: true, fileName: file.fileName });
      }
    }
  };

  if (thumbnails.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={2} my={1}>
      {thumbnails.map((file, index) => {
        return (
          <Grid item sm={12} md={6} key={file.fileName}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                backgroundColor: theme.palette.custom.aquaHaze,
                borderRadius: 1,
                p: 2,
                overflow: 'hidden',
                position: 'relative',
              })}
            >
              {showEditLogoOnly && showEdit && isImage?.(file) && (
                <Button
                  title="Edit"
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                  sx={(theme) => ({
                    position: 'absolute',
                    bottom: 8,
                    right: 22,
                    backgroundColor: theme.palette.custom.white,
                    padding: '2px',
                    minWidth: 'fit-content',
                    borderRadius: '50%',
                  })}
                  onClick={() => handleClick(file)}
                >
                  <EditLineIcon size={14} />
                </Button>
              )}
              <Box display="flex">
                <Box
                  onClick={() => handleClick(file)}
                  sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    width: '80px !important',
                    height: '50px !important',
                    position: 'relative',
                    '& img, & video': {
                      width: '80px !important',
                      height: '50px !important',
                      borderRadius: 1,
                    },
                  }}
                >
                  <ThumbnailIcon attachment={file} />
                </Box>
                <Box ml={1.5} width="100%" flex={1}>
                  <Title title={file.fileName} onClick={() => handleClick(file)}>
                    {getExactFileName(file)}
                  </Title>
                  <FileSize>{formatFileSize(file.fileSize)}</FileSize>
                </Box>
              </Box>
              <Box ml={1} flex={1} display="flex" alignItems="flex-start" justifyContent="flex-end">
                {!showEditLogoOnly && showEdit && isImage?.(file) && (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={disabled}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.custom.white,
                      padding: '2px 12px',
                      minWidth: 'fit-content',
                      mr: 0.5,
                    })}
                    onClick={() => handleClick(file)}
                  >
                    <EditLineIcon size={18} />
                    &nbsp;Edit
                  </Button>
                )}
                <IconButton
                  size="small"
                  onClick={() => removeFile?.(file, index)}
                  sx={{ mt: -0.25 }}
                  disabled={disabled}
                >
                  <CloseLineIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
  width: 'fit-content',
  cursor: 'pointer',
  wordBreak: 'break-word',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

const FileSize = styled(Typography, { label: 'FileSize' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default RenderThumbnails;
