import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';

type NoPostsProps = {
  message?: string;
};

function NoPosts({ message }: NoPostsProps) {
  const theme = useTheme();
  return (
    <NoDataContainer>
      <FileListLineIcon size={120} color={theme.palette.custom.steel} />
      <NoDataDescription>{message || 'No posts found'}</NoDataDescription>
    </NoDataContainer>
  );
}

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '80px 24px 0',
});

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default NoPosts;
