import FileListFillIcon from 'remixicon-react/FileListFillIcon';
import FolderMusicFillIcon from 'remixicon-react/FolderMusicFillIcon';
import ImageFillIcon from 'remixicon-react/ImageFillIcon';
import VideoFillIcon from 'remixicon-react/VideoFillIcon';
import { ReactComponent as WordDocumentIcon } from 'assets/icons/doc.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as PptDocumentIcon } from 'assets/icons/ppt.svg';
import { ReactComponent as ExcelDocumentIcon } from 'assets/icons/xls.svg';
import { ReactComponent as TextDocumentIcon } from 'assets/icons/txt.svg';

type FileIconProps = {
  fileType: string;
};

function FileIcon({ fileType }: FileIconProps) {
  switch (fileType.toLowerCase()) {
    case 'doc':
    case 'docx':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'msword': {
      return <WordDocumentIcon width={24} height={24} />;
    }

    case 'pdf': {
      return <PdfIcon width={24} height={24} />;
    }

    case 'txt': {
      return <TextDocumentIcon width={24} height={24} />;
    }

    case 'ppt':
    case 'pptx':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'vnd.ms-powerpoint': {
      return <PptDocumentIcon width={24} height={24} />;
    }

    case 'xls':
    case 'csv':
    case 'xlsx':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'vnd.ms-excel': {
      return <ExcelDocumentIcon width={24} height={24} />;
    }

    case 'apng':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'pjpeg':
    case 'pjp':
    case 'png':
    case 'jfif': {
      return <ImageFillIcon />;
    }

    case 'mp4':
    case 'webm':
    case '3gp':
    case 'mpg':
    case 'mpeg':
    case 'm4v':
    case 'ogv':
    case 'ogg':
    case 'avi':
    case 'quicktime':
    case 'mov': {
      return <VideoFillIcon />;
    }

    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'oga': {
      return <FolderMusicFillIcon />;
    }

    default:
      return <FileListFillIcon />;
  }
}

export default FileIcon;
