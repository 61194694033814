import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as CertifyCheckFillIcon } from 'assets/icons/CertifyCheck.svg';

function PersonalPostsNote() {
  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12}>
        <Container>
          <CertifyCheckFillIcon />
          <Note>Recommendations tailored based on the posts you like and your search history.</Note>
        </Container>
      </Grid>
    </Grid>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  padding: '16px',
  borderRadius: 5,
  display: 'flex',
}));

const Note = styled(Typography, { label: 'Note' })(({ theme }) => ({
  marginLeft: 12,
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
}));

export default PersonalPostsNote;
