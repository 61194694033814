import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ArticleType } from '../hooks/useArticlesQuery';

type ArticleCustomFieldsProps = {
  article: ArticleType;
};

function ArticleCustomFields({ article }: ArticleCustomFieldsProps) {
  return (
    <Box>
      {Object.keys(article?.customFields || []).map((key) => {
        return (
          <Container key={key}>
            <Title>{key}</Title>
            {Array.isArray(article?.customFields?.[key].fieldValue)
              ? (article.customFields?.[key].fieldValue as string[]).join(', ')
              : article.customFields?.[key].fieldValue}
          </Container>
        );
      })}
    </Box>
  );
}

const Container = styled(Box, { label: 'Container' })({
  marginTop: 12,
});

const Title = styled(Typography, { label: 'Title' })({
  fontSize: 14,
  fontWeight: 700,
});

export default ArticleCustomFields;
