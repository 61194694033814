import { CompanyType } from 'hooks/useCompanyQueries';
import { ConfigResponse, ConfigType } from 'config/config';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { FileType } from 'pages/companyFiles/hooks/useFilesQuery';
import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

type AddCompanyResponseType = {
  company: CompanyType;
  totalElements: number;
  totalNumber: number;
  totalPages: number;
};

type UploadFilesPayload = {
  files: AttachmentType[];
  fileReferenceObject: {
    objectId: string;
    objectName: string;
    objectType: string;
  };
};

type RemoveFilesPayload = {
  ids: {
    ids: string[];
  };
  fileReferenceObject: {
    objectId: string;
    objectName: string;
    objectType: string;
  };
};

export type ConfigsByTypeResponse = {
  configs: ConfigType[];
};

class CompanyAPI extends JavaAPI {
  public getCompany(companyId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId));
  }

  public getCompanies({
    isAssociatedWithOtherCompanies,
  }: {
    isAssociatedWithOtherCompanies: boolean;
  }) {
    return this.get(
      isAssociatedWithOtherCompanies
        ? JAVA_SERVICE_ENDPOINTS.GET_USER_COMPANIES
        : JAVA_SERVICE_ENDPOINTS.GET_COMPANIES,
    );
  }

  public starWorkspace(companyId: string, payload: { workSpaceIds: string[]; isStarred: boolean }) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.STAR_WORKSPACE.replace(':companyId', companyId),
      payload,
    );
  }

  public getUserGroups(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_USER_GROUPS.replace(':companyId', companyId),
    );
  }

  public getPartnerRoles(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_PARTNER_ROLES.replace(':companyId', companyId),
    );
  }

  public getTopics(companyId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_TOPICS.replace(':companyId', companyId));
  }

  public getDivisions(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_DIVISIONS.replace(':companyId', companyId),
    );
  }

  public getDepartments(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_DEPARTMENTS.replace(':companyId', companyId),
    );
  }

  public getProjectPhases(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_PROJECT_PHASES.replace(':companyId', companyId),
    );
  }

  public getScorecardCategories(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_SCORECARD_CATEGORIES.replace(':companyId', companyId),
    );
  }

  public predictDivisions(companyId: string, query: string) {
    return this.post(
      `${JAVA_SERVICE_ENDPOINTS.PREDICT_COMPANY_DIVISIONS.replace(':companyId', companyId)}`,
      {
        query,
      },
    );
  }

  public getSuggestedExpertSkills(companyId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_SUGGESTED_EXPERT_SKILLS.replace(':companyId', companyId)}`,
    );
  }

  public getSuggestedHobbies(companyId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_SUGGESTED_HOBBIES.replace(':companyId', companyId)}`,
    );
  }

  public getBuildWiseModules(companyId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_MODULES.replace(':companyId', companyId)}`,
    );
  }

  public getDivisionSpecSections(companyId: string, divisionCode: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_DIVISION_SEPC_SECTIONS.replace(':companyId', companyId).replace(
        ':divisionCode',
        divisionCode,
      ),
    );
  }

  public updateCompanySettings(companyId: string, data) {
    return this.put(JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId), data);
  }

  public getCompanyConfig(companyId: string, configName: string) {
    return this.get<ConfigResponse>(
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', companyId).replace(
        ':configName',
        configName,
      ),
    );
  }

  public getCompanyConfigsByType(companyId: string, configType: string) {
    return this.get<ConfigsByTypeResponse>(
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY_CONFIGS_BY_TYPE.replace(':companyId', companyId).replace(
        ':configType',
        configType,
      ),
    );
  }

  public getBuildWiseConfig(configName: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG.replace(':configName', configName));
  }

  public updateConfig(
    companyId: string,
    configName: string,
    description: string,
    entries: {
      id?: string;
      entryCode: string;
      entryValues?: string[];
      entryDescription: string;
      isActive: boolean;
      deleteFlag?: boolean;
      otherAttributes?: Record<string, any>;
      configEntryRefMap?: Record<string, any>;
    }[],
  ) {
    return this.put<ConfigResponse>(JAVA_SERVICE_ENDPOINTS.UPDATE_CONFIG, {
      companyId: companyId,
      configName: configName,
      description: description,
      entries: entries,
    });
  }

  public updateConfigNEW(config: ConfigType) {
    return this.put<ConfigResponse>(JAVA_SERVICE_ENDPOINTS.UPDATE_CONFIG, config);
  }

  public updateBuildWiseConfig(
    configName: string,
    description: string,
    entries: {
      id?: string;
      entryCode: string;
      entryValues: string[];
      entryDescription: string;
      isActive: boolean;
      deleteFlag?: boolean;
      otherAttributes?: Record<string, any>;
      configEntryRefMap?: Record<string, any>;
    }[],
  ) {
    return this.put<ConfigResponse>(JAVA_SERVICE_ENDPOINTS.UPDATE_CONFIG, {
      configName: configName,
      description: description,
      entries: entries,
    });
  }

  public uploadLogo(
    companyId: string,
    objectId: string,
    objectName: string,
    objectType: string,
    file: File,
  ) {
    const formBody = new FormData();
    if (file) {
      formBody.append('file', file);
    }
    formBody.append(
      'fileReferenceObject',
      JSON.stringify({ objectId: objectId, objectName: objectName, objectType: objectType }),
    );
    return this.post(
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId) + '/fileUpload',
      formBody,
    );
  }

  public deleteLogo(
    fileReferenceObject: { objectId: string; objectName: string; objectType: string },
    companyId: string,
    file: File | null,
  ) {
    const formBody = new FormData();
    if (file) {
      formBody.append('file', file);
    }
    formBody.append('fileReferenceObject', JSON.stringify(fileReferenceObject));
    return this.delete(
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId) + '/file',
      {
        data: formBody,
      },
    );
  }

  public addCompany(payload: { name: string; emailDomain?: string }) {
    return this.post<AddCompanyResponseType>(JAVA_SERVICE_ENDPOINTS.ADD_COMPANY, payload);
  }

  public uploadFiles(companyId: string, payload: UploadFilesPayload) {
    const formData = new FormData();

    payload.files.forEach((attachment) => {
      formData.append('file', attachment.file);
    });

    formData.append('fileReferenceObject', JSON.stringify(payload.fileReferenceObject));
    formData.append('path', '/');

    return this.post<{ documents: FileType[] }>(
      JAVA_SERVICE_ENDPOINTS.UPLOAD_FILES.replace(':companyId', companyId),
      formData,
    );
  }

  public removeFiles(companyId: string, payload: RemoveFilesPayload) {
    const formData = new FormData();

    formData.append('ids', JSON.stringify(payload.ids));
    formData.append('fileReferenceObject', JSON.stringify(payload.fileReferenceObject));

    return this.delete(JAVA_SERVICE_ENDPOINTS.REMOVE_FILES.replace(':companyId', companyId), {
      data: formData,
    });
  }
}

const companyAPI = new CompanyAPI();
export default companyAPI;
