import { createTheme, Theme } from '@mui/material/styles';
import moment from 'moment/moment';

moment.updateLocale('en', {
  relativeTime: {
    past: (diff) => (diff === 'Just now' ? diff : `${diff} ago`),
    s: 'Just now',
    ss: 'Just now',
  },
});

export const globalStyles = (theme: Theme) => ({
  a: {
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
  },
  p: {
    margin: 0,
  },
  input: {
    '&:-webkit-autofill': {
      border: '1px solid inherit',
      WebkitTextFillColor: 'inherit',
      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
      '&:hover, &:focus': {
        border: '1px solid inherit',
        WebkitTextFillColor: 'inherit',
        WebkitBoxShadow: '0 0 0px 1000px transparent inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
  body: {
    fontFamily: 'Lato, sans-serif',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.custom.background,
    WebkitOverflowScrolling: 'touch',
  },
  // Fix for Safari bug with scrolling issue on Dialogs
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      '&[style*="overflow:hidden"],&[style*="overflow: hidden"]': {
        touchAction: 'none',
        MsTouchAction: 'none',
      },
    },
  },
  '*': {
    '&::-webkit-scrollbar': {
      width: '10px',
      marginRight: '5px',
      height: '10px',
      zIndex: 99,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.custom.silverChalice,
      borderRadius: '10px',
      backgroundClip: 'padding-box',
      border: '2px solid transparent',
      zIndex: 99,
    },
    '&::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-track:hover': {
      display: 'block',
      zIndex: 99,
      background: 'rgb(224, 224, 224)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgb(119, 119, 119)',
      border: '1px solid rgb(224, 224, 224)',
    },
  },
  '@keyframes thumbnail-sequence': {
    '0%': {
      transform: 'translateY(-50%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
});

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 0,
          '&:focus': {
            outline: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          borderRadius: 4,
          backgroundColor: theme.palette.custom.tuna,
          color: '#FFFFFF',
          fontSize: 10,
          lineHeight: '12px',
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 39,
          height: 26,
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '18px',
          '&.Mui-disabled': {
            opacity: '0.6 !important',
          },
          '&:hover': {
            backgroundColor: theme.palette.custom.background,
          },
        }),
        outlined: {
          border: '1px solid #D9DBE9',
        },
        filled: {
          background: '#EFF0F7',
        },
        deleteIcon: {
          marginRight: 8,
          color: '#4E4B66',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 7,
            paddingLeft: 10,
          },
        },
        paper: ({ theme }) => ({
          boxShadow: 'none',
          border: `1px solid ${theme.palette.custom.line}`,
          // DevNote: Explicitly hide options container styles when there are no options available for freeSolo input. See https://github.com/mui/material-ui/issues/40843
          '&.hide-options-container': {
            border: 'none',
          },
        }),
        option: ({ theme }) => ({
          '&:hover': {
            backgroundColor: `${theme.palette.custom.whiteSmoke} !important`,
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${theme.palette.custom.mariner01} !important`,
          },
          '&[aria-selected="true"]:hover': {
            backgroundColor: `${theme.palette.custom.mariner02} !important`,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          outline: 0,
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
        },
        input: {
          '&::placeholder': {
            color: '#A0A3BD',
            opacity: 1,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: '#D9DBE9',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9DBE9',
          },
        },
        input: {
          padding: '14px 16px',
        },
        notchedOutline: {
          border: '1px solid #D9DBE9',
          '& > legend > span': {
            display: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          fontSize: 16,
          lineHeight: '28px',
          fontWeight: 700,
          letterSpacing: '0.25px',
          color: '#4E4B66',
          transform: 'none',
          position: 'static',
          marginBottom: 4,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 40,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          height: 40,
          fontSize: 16,
        },
        root: {
          borderRadius: 40,
          fontWeight: 600,
          minWidth: 100,
          paddingLeft: 16,
          paddingRight: 16,
          textTransform: 'none',
          boxShadow: 'none',
        },
        outlined: {
          border: '1px solid #504D64',
        },
        containedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.custom.superNova,
          color: '#664D00',
          '&:hover': {
            backgroundColor: '#F4B740',
          },
        }),
        containedError: {
          backgroundColor: '#A40E0E',
          color: 'white',
          '&:hover': {
            backgroundColor: '#A40E0E',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          margin: '0 !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontSize: 16,
            fontWeight: 600,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 38,
          border: '1px solid #EDEDF1',
          borderTop: 0,
          borderRight: 0,
          borderLeft: 0,
          position: 'relative',
          '&.large-tabs .MuiTab-root': {
            padding: '16px 18px',
          },
        },
        indicator: ({ theme }) => ({
          background: theme.palette.custom.superNova,
        }),
        scrollButtons: ({ theme }) => ({
          position: 'absolute',
          backgroundColor: theme.palette.custom.background,
          zIndex: 1,
          height: '100%',
          opacity: 1,
          transition: '0.3s',
          '&:first-of-child': {
            left: 0,
          },
          '&:last-child': {
            right: 0,
          },
          '&.Mui-disabled': {
            opacity: 0,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.custom.slate,
          minHeight: 38,
          lineHeight: '14px',
          padding: '0 18px',
          fontSize: 16,
          textTransform: 'none',
          marginRight: '8px',
          '&.Mui-selected': {
            color: theme.palette.custom.pirateGold,
            fontWeight: 700,
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '&.full-height .MuiPaper-root': {
            height: '100%',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '12px 24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px 24px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          border: 0,
          width: theme.drawerWidth,
          [theme.breakpoints.up('lg')]: {},
          '&.MuiDrawer-paperAnchorDockedLeft': {
            zIndex: 1090,
            backgroundColor: 'transparent',
          },
          overflow: 'hidden',
          '&:hover': {
            overflow: 'auto',
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.sidebar-menu': {
            padding: '8px 24px',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          '&.children': {
            padding: '8px 12px',
          },
          '& .header .MuiListItemText-primary': {
            fontWeight: '700',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          fontSize: 14,
          paddingLeft: 13,
          paddingRight: 13,
          marginTop: 4,
          color: theme.palette.secondary.dark,
          transition: 'background 0.25s',
          '&.Mui-selected:not(.multi)': {
            backgroundColor: 'rgba(80, 77, 100, 0.12)',
            '& .MuiTypography-body1': {
              fontWeight: 700,
              fontSize: 15,
            },
          },
          '&.Mui-selected.multi': {
            backgroundColor: 'transparent',
            '& .MuiTypography-body1': {
              fontWeight: 700,
            },
          },
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          lineHeight: '16px',
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 38,
          color: theme.palette.secondary.dark,
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '&.light-warning .MuiBadge-colorWarning': {
            backgroundColor: '#fff9e6',
          },
        },
        colorWarning: {
          fontSize: 14,
          padding: '10px 6px',
        },
        colorSecondary: {
          fontSize: 14,
          padding: '10px 6px',
          backgroundColor: '#ebedf0',
          color: '#504d64',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.cursor-default': {
            cursor: 'default',
          },
          '&.no-hover:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottomColor: '#f1f1f1',
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#f1f3f5',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#e9e9e9',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputLabel-asterisk': {
            color: theme.palette.custom.lavaRed,
          },
        }),
      },
    },
  },
  palette: {
    mode: 'light',
    text: {
      primary: '#4E4B66',
    },
    background: {
      default: '#F7F7FC',
    },
    primary: {
      main: '#504D64',
      light: '#84819A',
      // dark: '#4E4B66',
    },
    secondary: {
      main: '#84819A',
      light: '#BEBCC9',
      dark: '#504D64',
    },
    warning: {
      main: '#F4B740',
      light: '##fff9e6',
      dark: '#946200',
    },
    error: {
      main: '#A40E0E',
      light: '#FF84B7',
      dark: '#C30052',
    },
    success: {
      main: '#00BA88',
      light: '#34EAB9',
      dark: '#00966D',
    },
    custom: {
      activeTitle: '#14142B',
      lightBlack: '#09121F',
      body: '#4E4B66',
      label: '#6E7191',
      placeholder: '#A0A3BD',
      line: '#C2C7D0',
      background: '#F7F7FC',
      inputBackground: '#EFF0F7',
      offWhite: '#FCFCFC',
      white: '#FFFFFF',
      black: '#000',
      earlyDawn: '#FFF9E6',
      lightOrange: '#71453D',
      boxShadow: 'rgba(0, 0, 0, 0.04)',
      superNova: '#FFC107',
      mediumBlack: '#504d64',
      jetGrey: '#5D6B82',
      divider: '#0000001f',
      link: '#1976D2',
      aliceBlue: '#F1F7FC',
      blueChalk: '#EBEDF0',
      yellowBorder: '#FFE699',
      brown: '#6D5303',
      tuna: '#35374F',
      mistBlue: '#6A6C89',
      smoky: '#1111111f',
      softPeach: '#EDEDF1',
      redWine: '#A40E0E',
      greenBlue: '#00BA88',
      lightBlue: '#B6E8FC',
      ghostWhite: '#f8f9ff',
      deepSea: '#00825F',
      greenishTeal: '#2BC69C',
      coralPink: '#FF8B66',
      pattensBlue: '#e6f3ff',
      linkWater: '#d6e7f7',
      whiteSmoke: '#F5F6F7',
      mariner01: 'rgba(25, 118, 210, 0.1)',
      mariner02: 'rgba(25, 118, 210, 0.2)',
      slate: '#505F79',
      pirateGold: '#B38705',
      steel: '#7A8699',
      platinum: '#DFE2E6',
      dark: '#202223',
      coolGrey: '#9798AD',
      lilyWhite: '#E9F9FF',
      mikadoYellow: '#FAC914',
      mulledWine: '#504D64',
      oldLace: '#fff4e5',
      mangoTango: '#ed6c02',
      nutmegWood: '#663c00',
      alabaster: '#fafbfb',
      pinkLemonade: '#ED2E7E',
      lavaRed: '#DF1717',
      tropicalBlue: '#E8F1FA',
      blueZodiac: '#15294B',
      skelton1: 'rgb(0 0 0 / 20%)',
      silver: '#ccc9c3',
      offGreen: '#E6F8F3',
      dawnPink: '#F6E7E7',
      tealGreen: '#007153',
      porcelain: '#EFF0F6',
      rangoonGreen: '#1a1a1a',
      aquaHaze: '#F0F3F5',
      charCoal: '#323639',
      iceberg: '#D9F4FF',
      smokeGrey: '#737373',
      davyGrey: '#525659',
      paleCarmine: '#B33737',
      navy: '#091E42',
      pinkSwan: '#B5B6C5',
      riverBed: '#475467',
      mirage: '#101828',
      geyser: '#D9DBE9',
      yellowTan: '#FEE175',
      silverChalice: '#aaaaaa',
      yellowishBrown: '#9c7604',
      ashGrey: '#B3B9C4',
      purpleMimosa: '#A679EE',
      blazeOrange: '#FF6F07',
      pictonBlue: '#4AB9E8',
      paleGold: '#FFDB6F',
      dusk: '#4B4E71',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    caption: {
      color: '#84819A',
      fontSize: 15,
    },
  },
  vh100: 'calc(var(--vh, 1vh) * 100)',
  vh: (n: number) => `calc(var(--vh, 1vh) * ${n})`,
  headerHeight: 80,
  drawerWidth: 300,
  maxWidth: '1440px',
});

declare module '@mui/material' {
  interface Theme {
    vh100: string;
    vh: (n: number) => string;
    headerHeight: number;
    drawerWidth: number;
    maxWidth: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    vh100?: string;
    vh?: (n: number) => string;
    headerHeight?: number;
    drawerWidth?: number;
    maxWidth: string;
  }
  interface Palette {
    custom: {
      activeTitle: string;
      lightBlack: string;
      body: string;
      label: string;
      placeholder: string;
      line: string;
      background: string;
      inputBackground: string;
      offWhite: string;
      white: string;
      black: string;
      earlyDawn: string;
      lightOrange: string;
      boxShadow: string;
      superNova: string;
      mediumBlack: string;
      jetGrey: string;
      divider: string;
      link: string;
      aliceBlue: string;
      blueChalk: string;
      yellowBorder: string;
      brown: string;
      tuna: string;
      mistBlue: string;
      smoky: string;
      softPeach: string;
      redWine: string;
      greenBlue: string;
      lightBlue: string;
      ghostWhite: string;
      deepSea: string;
      greenishTeal: string;
      coralPink: string;
      pattensBlue: string;
      linkWater: string;
      whiteSmoke: string;
      mariner01: string;
      mariner02: string;
      slate: string;
      pirateGold: string;
      steel: string;
      platinum: string;
      dark: string;
      coolGrey: string;
      lilyWhite: string;
      mikadoYellow: string;
      mulledWine: string;
      oldLace: string;
      mangoTango: string;
      nutmegWood: string;
      alabaster: string;
      pinkLemonade: string;
      lavaRed: string;
      tropicalBlue: string;
      blueZodiac: string;
      skelton1: string;
      silver: string;
      offGreen: string;
      dawnPink: string;
      tealGreen: string;
      porcelain: string;
      rangoonGreen: string;
      aquaHaze: string;
      charCoal: string;
      iceberg: string;
      smokeGrey: string;
      davyGrey: string;
      paleCarmine: string;
      navy: string;
      pinkSwan: string;
      riverBed: string;
      mirage: string;
      geyser: string;
      yellowTan: string;
      silverChalice: string;
      yellowishBrown: string;
      ashGrey: string;
      purpleMimosa: string;
      blazeOrange: string;
      pictonBlue: string;
      paleGold: string;
      dusk: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      activeTitle?: string;
      lightBlack?: string;
      body?: string;
      label?: string;
      placeholder?: string;
      line?: string;
      background?: string;
      inputBackground?: string;
      offWhite?: string;
      white?: string;
      black: string;
      earlyDawn: string;
      lightOrange: string;
      boxShadow: string;
      superNova: string;
      mediumBlack: string;
      jetGrey: string;
      divider: string;
      link: string;
      aliceBlue: string;
      blueChalk: string;
      yellowBorder: string;
      brown: string;
      tuna: string;
      mistBlue: string;
      smoky: string;
      softPeach: string;
      redWine: string;
      greenBlue: string;
      lightBlue: string;
      ghostWhite: string;
      deepSea: string;
      greenishTeal: string;
      coralPink: string;
      pattensBlue: string;
      linkWater: string;
      whiteSmoke: string;
      mariner01: string;
      mariner02: string;
      slate: string;
      pirateGold: string;
      steel: string;
      platinum: string;
      dark: string;
      coolGrey: string;
      lilyWhite: string;
      mikadoYellow: string;
      mulledWine: string;
      oldLace: string;
      mangoTango: string;
      nutmegWood: string;
      alabaster: string;
      pinkLemonade: string;
      lavaRed: string;
      tropicalBlue: string;
      blueZodiac: string;
      skelton1: string;
      silver: string;
      offGreen: string;
      dawnPink: string;
      tealGreen: string;
      porcelain: string;
      rangoonGreen: string;
      aquaHaze: string;
      charCoal: string;
      iceberg: string;
      smokeGrey: string;
      davyGrey: string;
      paleCarmine: string;
      navy: string;
      pinkSwan: string;
      riverBed: string;
      mirage: string;
      geyser: string;
      yellowTan: string;
      silverChalice: string;
      yellowishBrown: string;
      ashGrey: string;
      purpleMimosa: string;
      blazeOrange: string;
      pictonBlue: string;
      paleGold: string;
      dusk: string;
    };
  }
}
