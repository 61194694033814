import React, { MouseEvent, Suspense, useEffect, useMemo, useRef, Dispatch } from 'react';
import { Box, Button, Stack, Typography, Chip, Divider, Checkbox, BoxProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { highlightSearchkeyWord, linkToUser, replaceMentions } from 'utility/helpers';
import { Link } from 'react-router-dom';
import { SEARCH, VIEW_ARTICLE, VIEW_DRAFT } from 'constants/routes';
import Attachment2Icon from 'remixicon-react/Attachment2Icon';
import LightboxModal from 'components/common/modal/LightboxModal';
import Loader from 'components/common/loader/Loader';
import QuestionLineIcon from 'remixicon-react/QuestionLineIcon';
import Avatar from 'components/common/avatar/Avatar';
import LightbulbFlashFillIcon from 'remixicon-react/LightbulbFlashFillIcon';
import { ArticleUserMapType, CommentType } from 'pages/article/hooks/useArticleDetailsQuery';
import { ProjectRecommendationsType, ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import linkifyHtml from 'linkify-html';
import { ArticleType } from '../hooks/useArticlesQuery';
import RenderAttachments from './RenderAttachments';
import { decode } from 'html-entities';
import CommentsBox from 'pages/article/components/CommentsBox';
import useArticle from '../hooks/useArticle';
import AutoGeneratedDraftMessage from './AutoGeneratedDraftMessage';
import ArticleInteractions from './ArticleInteractions';
import ArticleModals from './ArticleModals';
import RenderPlaylists from './RenderPlaylists';
import PlaylistBadge from './PlaylistBadge';
import ArticleProjectDetails from './ArticleProjectDetails';
import ArticleMenu from './ArticleMenu';
import RenderArticleUser from './RenderArticleUser';
import ArticleHeader from './ArticleHeader';
import DraftActions from './DraftActions';
import DraftFooterActions from 'pages/drafts/DraftFooterActions';
import Attachments from './Attachments';
import { ArticleAttachmentsSummary } from 'pages/article/hooks/useArticleAttachmentsSummary';
import useUserData from 'hooks/useUserData';
import ArticleAttachmentSummaryIndicator from './ArticleAttachmentSummaryIndicator';
import { FeedFilter } from 'api/KnowledgeAPI';
import ArticleCustomFields from './ArticleCustomFields';

export type ArticleVariantType =
  | 'detailed'
  | 'short'
  | 'draft'
  | 'list'
  | 'related-post'
  | 'playlist'
  | 'playlist-article';

type ArticlePropTypes = {
  article: ArticleType;
  articleAttachmentsSummary?: ArticleAttachmentsSummary;
  articleIndex?: number;
  comments?: CommentType[];
  doNotAutoplayVideos?: boolean;
  isRecommendedType?: boolean;
  project?: ProjectType | ProjectRecommendationsType;
  scrollInfoKey?: string;
  showAttachmentSummaryIndicatorSkeleton?: boolean;
  stakeholderId?: string;
  selectedFeedFilters?: FeedFilter[];
  userMap?: ArticleUserMapType;
  variant?: ArticleVariantType;
  bookmarkUpdateCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  deleteArticleCallback?: (options: { article: ArticleType }) => void;
  endorseArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  likeUpdateCallback?: (options: { oldArticle: ArticleType; updatedArticle: ArticleType }) => void;
  resetFeed?: (options?: {
    resetQueries?: boolean | undefined;
    smoothScrollBehavior?: boolean | undefined;
  }) => void;
  updateArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  showCheckbox?: boolean;
  articlePreviewInfo?: ArticleType[];
  setArticlePreviewInfo?: Dispatch<React.SetStateAction<ArticleType[]>>;
  isPreviewLoading?: boolean;
};

const ArticleBox: React.FC<ArticlePropTypes> = ({
  article,
  articleAttachmentsSummary,
  articleIndex,
  comments = [],
  doNotAutoplayVideos = false,
  isRecommendedType = false,
  project,
  scrollInfoKey,
  showAttachmentSummaryIndicatorSkeleton,
  stakeholderId,
  selectedFeedFilters,
  userMap = {},
  variant = 'detailed',
  bookmarkUpdateCallback,
  deleteArticleCallback,
  endorseArticleCallback,
  likeUpdateCallback,
  resetFeed,
  updateArticleCallback,
  showCheckbox,
  articlePreviewInfo,
  setArticlePreviewInfo,
  isPreviewLoading,
}) => {
  const theme = useTheme();
  const { company } = useUserData();
  const articleDescriptionRef = useRef<HTMLParagraphElement | null>(null);
  const {
    articleState,
    autoGeneratedDraftMenu,
    documents,
    hasEditAccess,
    isAutoGeneratedDraft,
    isDetailedView,
    isDraftView,
    isListView,
    isPublishedByBuildWiseInsights,
    isRelatedPostView,
    isShortView,
    mediaFiles,
    menu,
    pathname,
    routeState,
    searchKeyword,
    selectedCompanyIds,
    bookmarkHandler,
    deleteArticle,
    dispatch,
    endorseHandler,
    likeHandler,
    movePost,
    moveToDrafts,
    openInsightsBanner,
    publishArticle,
    setSelectedCompanyIds,
    viewInteractionsHandler,
  } = useArticle({
    article,
    articleIndex,
    project,
    selectedFeedFilters,
    stakeholderId,
    variant,
    bookmarkUpdateCallback,
    deleteArticleCallback,
    endorseArticleCallback,
    likeUpdateCallback,
    resetFeed,
    updateArticleCallback,
  });

  useEffect(() => {
    if (articleDescriptionRef.current) {
      const embeddedImages = articleDescriptionRef.current.querySelectorAll('img');

      embeddedImages.forEach((embeddedImage) => {
        embeddedImage.style.cursor = 'pointer';
        embeddedImage.addEventListener('click', handleEmbeddedImageClick);
      });
    }

    return () => {
      if (articleDescriptionRef.current) {
        const embeddedImages = articleDescriptionRef.current.querySelectorAll('img');

        embeddedImages.forEach((embeddedImage) =>
          embeddedImage.removeEventListener('click', handleEmbeddedImageClick),
        );
      }
    };
  }, [articleDescriptionRef, article.description]);

  const handleEmbeddedImageClick = (event: unknown) => {
    const attachmentId =
      (event as MouseEvent)?.currentTarget?.getAttribute('data-attachment-id') ||
      (event as MouseEvent)?.currentTarget?.getAttribute('attachmentid');
    const fileName = mediaFiles.find((file) => file.id === attachmentId)?.fileName;

    if (fileName) {
      dispatch({
        type: 'TOGGLE_LIGHTBOX',
        payload: { open: true, fileName },
      });
    }
  };

  const RenderedTitle = useMemo(() => {
    if (!searchKeyword) {
      return (
        <ArticleTitle variant="h5" className={variant}>
          {article.title}
        </ArticleTitle>
      );
    }
    return (
      <ArticleTitle
        variant="h5"
        className={variant}
        dangerouslySetInnerHTML={{
          __html: highlightSearchkeyWord(article?.title, searchKeyword),
        }}
      />
    );
  }, [article, isDetailedView]);

  const RenderedDescription = useMemo(() => {
    if (!article.description) return null;
    let _text = isDetailedView ? article.description : article.description.slice(0, 1000);
    if (searchKeyword) {
      _text = highlightSearchkeyWord(_text, searchKeyword);
    }
    return (
      <ArticleDescription
        ref={isRelatedPostView ? null : articleDescriptionRef}
        className={variant}
        dangerouslySetInnerHTML={{ __html: linkifyHtml(decode(_text), { target: '_blank' }) }}
        {...(!isDetailedView && {
          sx: {
            '& img, table': {
              display: 'none',
            },
          },
        })}
      />
    );
  }, [article, isDetailedView]);

  return (
    <>
      {isDetailedView && article.isDraft && (
        <DraftActions
          articleState={articleState}
          autoGeneratedDraftMenu={autoGeneratedDraftMenu}
          dispatch={dispatch}
        />
      )}

      <ArticleContainer className={`article-container ${variant}`}>
        {article.isDraft && (
          <AutoGeneratedDraftMessage
            dispatch={dispatch}
            variant={variant}
            draft={article}
            userMap={userMap}
            isAutoGeneratedDraft={!!isAutoGeneratedDraft}
          />
        )}

        {isRecommendedType && (
          <ArticleHeader
            variant={variant}
            article={article}
            project={project as ProjectRecommendationsType}
            dispatch={dispatch}
          />
        )}

        <ArticleContentContainer className={`article-box ${variant}`}>
          <Box display="flex">
            {!isListView && !isRelatedPostView && (
              <AvatarContainer>
                {showCheckbox && (
                  <Checkbox
                    onChange={() => {
                      setArticlePreviewInfo?.((prevState) =>
                        prevState?.find((item) => item?.id === article?.id)
                          ? prevState.filter((item) => item?.id !== article?.id)
                          : [...prevState, article],
                      );
                    }}
                    checked={!!articlePreviewInfo?.find((item) => item?.id === article?.id)}
                    disabled={isPreviewLoading}
                  />
                )}
                <Avatar
                  src={article?.profileImageURL}
                  size={38}
                  name={isPublishedByBuildWiseInsights ? '' : article.userNameToDisplay}
                  icon={<LightbulbFlashFillIcon color="#FFC107" />}
                  bgcolor={isPublishedByBuildWiseInsights ? '#FFF8E5' : ''}
                  onClick={openInsightsBanner}
                  to={isPublishedByBuildWiseInsights ? '' : linkToUser(article.createdBy)}
                />
              </AvatarContainer>
            )}

            {!isRelatedPostView && (
              <ArticleContent className={variant} isDetailedView={isDetailedView}>
                <ArticleUserContainer className={variant}>
                  {!isListView && (
                    <RenderArticleUser
                      article={article}
                      dispatch={dispatch}
                      isPublishedByBuildWiseInsights={isPublishedByBuildWiseInsights}
                    />
                  )}
                  <ArticleMenu menu={menu} bookmarkHandler={bookmarkHandler} article={article} />
                </ArticleUserContainer>

                {!isDraftView && (
                  <Box
                    sx={{
                      cursor: isShortView || isListView || isDraftView ? 'pointer' : 'auto',
                    }}
                    {...((isShortView || isDraftView || isListView) && {
                      component: Link,
                      to: `${VIEW_ARTICLE.replace(':articleId', article.id)}${
                        searchKeyword ? `?q=${encodeURIComponent(searchKeyword)}` : ''
                      }`,
                      state: {
                        isPlayListFeature: article.originIndicator === 'PLAYLIST',
                        scrollInfoKey,
                        activeSidebarTab: pathname.startsWith('/admin/bookmarks')
                          ? 'Bookmarks'
                          : '',
                        article,
                      },
                    })}
                  >
                    <Box mt={1}>
                      {!isListView && article.originIndicator === 'QUESTION_AND_ANSWER' && (
                        <$AnsweredTitle display="flex">
                          <QuestionLineIcon size={16} />
                          Answered this question:
                        </$AnsweredTitle>
                      )}
                      {RenderedTitle}

                      {isDetailedView && article?.labels?.length > 0 && (
                        <Stack direction="row" flexWrap="wrap" my={1}>
                          {article.labels.map((label) => (
                            <Chip
                              component={Link}
                              to={`${SEARCH}?q=${label}`}
                              state={routeState}
                              key={`${article.id}-${label}`}
                              label={label}
                              variant="outlined"
                              sx={{ mr: 0.5, mb: 0.5, cursor: 'pointer' }}
                            />
                          ))}
                        </Stack>
                      )}
                      {isDetailedView && mediaFiles.length > 0 && (
                        <Attachments
                          attachments={mediaFiles}
                          article={article}
                          doNotAutoplayVideos
                          variant={variant}
                          setLightboxOpen={(payload: { open: boolean; fileName: string }) =>
                            dispatch({ type: 'TOGGLE_LIGHTBOX', payload })
                          }
                        />
                      )}
                      {RenderedDescription}
                      {(isShortView || isListView) && article.originIndicator === 'PLAYLIST' && (
                        <PlaylistBadge article={article} />
                      )}

                      {(isShortView || isListView) && mediaFiles.length > 0 && (
                        <Attachments
                          attachments={mediaFiles}
                          article={article}
                          doNotAutoplayVideos
                          variant={variant}
                        />
                      )}

                      {!isDetailedView && documents.length > 0 && (
                        <>
                          <IconButton
                            size="small"
                            className="no-hover"
                            sx={(theme) => ({ color: theme.palette.custom.mediumBlack })}
                          >
                            <Attachment2Icon size={20} color={theme.palette.custom.link} />
                            &nbsp;
                            <Typography sx={(theme) => ({ color: theme.palette.custom.link })}>
                              {documents.length}{' '}
                              {documents.length > 1 ? 'Attachments' : 'Attachment'}
                            </Typography>
                          </IconButton>
                          {/* {!!company?.settings?.articlesLLMSearch &&
                            !!article?.attachments?.find(
                              (attachment) => attachment?.pageNo && attachment.pageNo?.length > 0,
                            ) && (
                              <ArticleAttachmentSummaryIndicator
                                showAttachmentSummaryIndicatorSkeleton={
                                  showAttachmentSummaryIndicatorSkeleton
                                }
                              />
                            )} */}
                        </>
                      )}
                    </Box>
                  </Box>
                )}

                {isDetailedView && (
                  <ArticleProjectDetails
                    article={article}
                    documents={documents}
                    searchKeyword={searchKeyword}
                    articleAttachmentsSummary={articleAttachmentsSummary}
                  />
                )}
                {isDetailedView && article.customFields && (
                  <ArticleCustomFields article={article} />
                )}
              </ArticleContent>
            )}
          </Box>

          {(isDraftView || isRelatedPostView) && (
            <Link
              to={`${(isRelatedPostView ? VIEW_ARTICLE : VIEW_DRAFT).replace(
                ':articleId',
                article.id,
              )}${searchKeyword ? `?q=${encodeURIComponent(searchKeyword)}` : ''}`}
              state={{
                isPlayListFeature: article.originIndicator === 'PLAYLIST',
                scrollInfoKey,
                isDraft: article?.isDraft,
                article,
              }}
            >
              <DraftContentContainer className={variant}>
                <Box flex={1} marginRight={mediaFiles.length > 0 ? 1 : 0}>
                  {RenderedTitle}
                  {RenderedDescription}
                </Box>
                {mediaFiles.length > 0 && (
                  <RenderAttachments
                    {...{
                      attachments: mediaFiles,
                      doNotAutoplayVideos,
                      variant,
                      isShortView,
                      isDetailedView,
                      isDraftView,
                      isRelatedPostView,
                      article,
                    }}
                  />
                )}
              </DraftContentContainer>
            </Link>
          )}

          {article.isDraft && !isDetailedView && (
            <DraftFooterActions userMap={userMap} article={article} dispatch={dispatch} />
          )}

          {!(article.isDraft && !isDetailedView) &&
            !(isDetailedView && article.originIndicator === 'PLAYLIST') && (
              <ArticleInteractions
                article={article}
                dispatch={dispatch}
                isDetailedView={isDetailedView}
                isDraftView={isDraftView}
                isRelatedPostView={isRelatedPostView}
                likeHandler={likeHandler}
                scrollInfoKey={scrollInfoKey}
                searchKeyword={searchKeyword}
                variant={variant}
                viewInteractionsHandler={viewInteractionsHandler}
                userMap={userMap}
              />
            )}
        </ArticleContentContainer>

        {isDetailedView && article.originIndicator === 'PLAYLIST' && (
          <>
            <Box px={3}>
              <PlaylistBadge
                article={article}
                hasEditAccess={hasEditAccess}
                isDetailedView={isDetailedView}
                dispatch={dispatch}
              />
            </Box>
            <RenderPlaylists article={article} dispatch={dispatch} />
            <Box px={3}>
              <ArticleInteractions
                article={article}
                dispatch={dispatch}
                isDetailedView={isDetailedView}
                isDraftView={isDraftView}
                isRelatedPostView={isRelatedPostView}
                likeHandler={likeHandler}
                scrollInfoKey={scrollInfoKey}
                searchKeyword={searchKeyword}
                variant={variant}
                viewInteractionsHandler={viewInteractionsHandler}
                userMap={userMap}
              />
            </Box>
          </>
        )}

        {isDetailedView && (
          <>
            <Divider sx={{ mt: 2, mx: 3 }} />
            <Box pb={2}>
              <CommentsBox articleId={article.id} comments={comments || []} article={article} />
            </Box>
          </>
        )}

        <Suspense fallback={<Loader variant="fixed" />}>
          <ArticleModals
            articleState={articleState}
            userMap={userMap}
            article={article}
            deleteArticle={deleteArticle}
            publishArticle={publishArticle}
            isDetailedView={isDetailedView}
            dispatch={dispatch}
            hasEditAccess={hasEditAccess}
            project={project as ProjectRecommendationsType}
            movePost={movePost}
            moveToDrafts={moveToDrafts}
            stakeholderId={stakeholderId}
            endorseHandler={endorseHandler}
            selectedFeedFilters={selectedFeedFilters}
            selectedCompanyIds={selectedCompanyIds}
            setSelectedCompanyIds={setSelectedCompanyIds}
            updateArticleCallback={updateArticleCallback}
          />
        </Suspense>

        {mediaFiles.length > 0 && articleState.lightbox.open && (
          <LightboxModal<ArticleType>
            onClose={() =>
              dispatch({ type: 'TOGGLE_LIGHTBOX', payload: { open: false, fileName: '' } })
            }
            open={articleState.lightbox.open}
            defaultOpenFileName={articleState.lightbox.fileName}
            mediaFiles={mediaFiles}
            item={article}
          />
        )}
      </ArticleContainer>
    </>
  );
};

export const ArticleContainer = styled(Box, { label: 'ArticleContainer' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  '&.detailed': {
    borderRadius: '16px',
  },
  '&.short, &.draft': {
    borderRadius: '4px',
    marginBottom: 8,
  },
  '&.list': {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.custom.divider}`,
    marginBottom: 8,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  '&.related-post': {
    boxShadow: 'none',
  },
}));

const $AnsweredTitle = styled(Box, { label: 'AnsweredTitle' })(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.custom.label,
  marginBottom: 8,
}));

export const ArticleContentContainer = styled(Box, { label: 'ArticleContentContainer' })({
  padding: '0 16px',
  '&.detailed': {
    padding: '0 24px',
  },
  '&.list': {
    padding: '0',
  },
});
interface ArticleContentProps extends BoxProps {
  isDetailedView?: boolean;
}

export const ArticleContent = styled(Box, { label: 'ArticleContent' })<ArticleContentProps>(({
  isDetailedView,
}) => {
  return {
    flex: 1,
    overflow: 'hidden',
    padding: '16px 0 8px 12px',
    '&.list': {
      paddingLeft: 0,
      paddingTop: 24,
    },
    '& img': {
      ...(!isDetailedView && { width: '100% !important', height: '100% !important' }),
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
    },
  };
});

export const ArticleUserContainer = styled(Box, { label: 'ArticleUserContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.list': {
    justifyContent: 'flex-end',
  },
});

export const ArticleTitle = styled(Typography, { label: 'ArticleTitle' })(({ theme }) => ({
  wordWrap: 'break-word',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '29px',
  color: theme.palette.secondary.dark,
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
  '& a': {
    color: theme.palette.custom.link,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&.related-post': {
    fontSize: 14,
    lineHeight: '20px',
  },
}));

export const ArticleDescription = styled(Typography, { label: 'ArticleDescription' })(
  ({ theme }) => ({
    position: 'relative',
    paddingTop: 8,
    wordBreak: 'break-word',
    fontFamily: 'Lato, sans-serif',
    fontSize: 16,
    color: theme.palette.custom.black,
    '& *': {
      fontFamily: 'Lato, sans-serif !important',
    },
    '&.short, &.list, &.draft, &.related-post': {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      maxHeight: 150,
    },
    '&.short *, &.list *, &.draft *, &.related-post *': {
      maxHeight: 100,
    },
    '& .highlight': {
      backgroundColor: 'yellow !important',
    },
    '& a': {
      whiteSpace: 'pre-wrap',
      color: `${theme.palette.custom.link} !important`,
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    '&.related-post': {
      lineHeight: '20px',
      paddingTop: 4,
      fontSize: 14,
    },
  }),
);

export const DraftContentContainer = styled(Box, { label: 'DraftContentContainer' })({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
  cursor: 'pointer',
  '&.related-post': {
    marginTop: 16,
  },
  '& img': {
    width: '100% !important',
    maxWidth: '100% !important',
    height: '100% !important',
    maxHeight: '100% !important',
    overflow: 'hidden',
  },
});

export const $ActionButtons = styled(Button, { label: 'ActionButtons' })(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 15,
  fontWeight: 400,
  minWidth: 40,
  height: 29,
  padding: '16px 12px 8px',
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: 5,
  },
}));

export const AvatarContainer = styled(Box, { label: 'AvatarContainer' })({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  marginTop: '16px',
});

export const ExportButtonContainer = styled(Box, { label: 'ExportButtonContainer' })({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  paddingTop: '8px',
});

export default ArticleBox;
