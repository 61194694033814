import KnowledgeAPI from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { FileSearchType } from 'hooks/useSearchQuery';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export enum PromptSource {
  BuildWiseGPT = 'BuildWiseGPT',
  VoicePost = 'VoicePost',
  ArticleSummarization = 'ArticleSummarization',
  CompanyFileSearch = 'CompanyFileSearch',
}

export type BuildWiseGPTHistoryItem = {
  _id: string;
  createdAt: Date | string;
  createdBy: string;
  genAIResponse: string;
  isBookMarked?: boolean;
  prompt: string;
  promptSource?: PromptSource;
  referenceFiles?: FileSearchType[];
  isStreaming?: boolean;
};

export type BuildWiseGPTHistory = {
  promptHistoryList: BuildWiseGPTHistoryItem[];
};

function useBuildWiseGPTHistoryQuery() {
  const { user } = useUserData();
  const queryClient = useQueryClient();
  const { data: buildwiseGPTHistoryData, ...rest } = useQuery<
    AxiosResponse<BuildWiseGPTHistory>,
    Error
  >(
    [JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_GPT_HISTORY, user?.username],
    () => KnowledgeAPI.getBuildWiseGPTHistory(user?.username || ''),
    {
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!user?.username,
    },
  );

  const buildwiseGPTHistory = useMemo(
    () =>
      Array.isArray(buildwiseGPTHistoryData?.data?.promptHistoryList)
        ? buildwiseGPTHistoryData?.data?.promptHistoryList || []
        : [],
    [buildwiseGPTHistoryData?.data?.promptHistoryList],
  );

  const updatePromptHistoryCache = ({
    action,
    chat,
  }: {
    action: 'add' | 'update';
    chat: BuildWiseGPTHistoryItem;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_GPT_HISTORY,
      user?.username,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<BuildWiseGPTHistory> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_GPT_HISTORY, user?.username],
        (old) => {
          if (old) {
            const updatedHistory = [...(old?.data?.promptHistoryList || [])];

            if (action === 'add') {
              updatedHistory.unshift(chat);
            }

            if (action === 'update') {
              const index = updatedHistory.findIndex((item) => item._id === chat._id);

              if (index > -1) {
                updatedHistory[index] = {
                  ...updatedHistory[index],
                  ...chat,
                };
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                promptHistoryList: updatedHistory,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    buildwiseGPTHistory,
    ...rest,
    updatePromptHistoryCache,
  };
}

export default useBuildWiseGPTHistoryQuery;
