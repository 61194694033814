import { Box, Grid, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { ListRange, Virtuoso } from 'react-virtuoso';
import { getPageScrollInfo, handlePageScrollPosition } from 'utility/helpers';
import HandleOperation from 'components/utils/HandleOperation';
import Filter from 'components/common/filter/Filter';
import { styled } from '@mui/material/styles';
import useAIDraftsWorkbench from './hooks/useAIDraftsWorkbench';
import NoInsights from 'pages/projects/NoInsights';
import InsightBox, { InsightContainer } from 'pages/projects/components/InsightBox';
import { useLocation, useNavigate } from 'react-router-dom';

const AIDraftsWorkbench = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { TABS, filters, insights, isLoading, tab, clearFilters, setTab } = useAIDraftsWorkbench();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Title>AI-Drafts Workbench</Title>
        <StickyTabsContainer>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_: unknown, newValue: string) => {
              setTab(newValue);
              navigate(`${pathname}#${newValue}`, { state, replace: true });
            }}
          >
            {TABS.map(({ hide, ...tabProps }) => (
              <Tab key={tabProps.value} {...tabProps} sx={{ px: 1.5 }} />
            ))}
          </Tabs>
        </StickyTabsContainer>
        <Box mb={10}>
          <HandleOperation
            isLoading={isLoading}
            data={insights}
            skelton={
              <InsightContainer>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Skeleton animation="wave" variant="text" width={150} height={30} />
                  <Skeleton animation="wave" variant="text" width={100} height={25} />
                </Box>
                <Skeleton animation="wave" variant="text" width="70%" height={30} sx={{ my: 1 }} />
                <Skeleton animation="wave" variant="text" height={20} />
                <Skeleton animation="wave" variant="text" width="60%" height={20} />
              </InsightContainer>
            }
            noDataComponent={<NoInsights filters={filters} clearFilters={clearFilters} />}
          >
            <Virtuoso
              initialTopMostItemIndex={getPageScrollInfo<(typeof insights)[0]>(
                tab === 'rfis' ? 'rfis' : 'meeting-minutes',
                insights,
                '_id',
              )}
              rangeChanged={(range: ListRange) =>
                handlePageScrollPosition(
                  tab === 'rfis' ? 'rfis' : 'meeting-minutes',
                  0,
                  range.startIndex,
                )
              }
              useWindowScroll
              data={insights}
              itemContent={(_, item) => (
                <InsightBox
                  key={item._id}
                  insight={item}
                  insightType={tab === 'rfis' ? 'FilteredRFI' : 'Meeting Minutes'}
                  isDefaultView
                />
              )}
            />
          </HandleOperation>
        </Box>
      </Grid>
      <Grid item md={4} display={{ xs: 'none', sm: 'none', md: 'block' }} mt={14}>
        <Filter
          filters={filters}
          isLoading={isLoading}
          skeltonCount={10}
          clearFilters={clearFilters}
        />
      </Grid>
    </Grid>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  color: theme.palette.custom.blueZodiac,
  fontWeight: 700,
  fontSize: 25,
}));

const StickyTabsContainer = styled(Box, { label: 'StickyTabsContainer' })(({ theme }) => ({
  position: 'sticky',
  top: 79,
  padding: '16px 0',
  backgroundColor: theme.palette.custom.background,
  zIndex: 1,
  width: '100%',
}));

export default AIDraftsWorkbench;
