import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ClickAwayListener, Grow, Paper, Popper as MuiPopper } from '@mui/material';
import { styled } from '@mui/material/styles';

const $Popper = styled(MuiPopper, { label: 'Popper' })<{ bg?: string }>(
  ({ theme, bg = 'white' }) => ({
    zIndex: 1500,
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.custom[bg]} transparent`,
      },
    },
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.custom[bg]} transparent transparent transparent`,
      },
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.custom[bg]} transparent transparent`,
      },
    },
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.custom[bg]}`,
      },
    },
    '& .MuiPaper-root': {
      background: theme.palette.custom[bg],
      boxShadow: '0px 8px 16px rgba(17, 17, 17, 0.12)',
      borderRadius: 8,
    },
  }),
);

const $Arrow = styled(Box, { label: 'Arrow' })({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

const Popper: React.FC<{
  anchor: any;
  onClose?: () => void;
  open: boolean;
  children: React.ReactNode;
  color?: string;
  modifiers?: any;
}> = ({ anchor, onClose = () => {}, open, color = 'white', modifiers = [], children }) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <$Popper
      placement="bottom-end"
      disablePortal
      open={open}
      anchorEl={anchor}
      bg={color}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        ...modifiers,
      ]}
      transition
    >
      {({ TransitionProps }: any) => (
        <ClickAwayListener onClickAway={onClose}>
          <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <Paper>
              <$Arrow className="arrow" component="span" ref={setArrowRef} />
              {children}
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </$Popper>
  );
};

export default Popper;
