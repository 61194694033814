import { Button, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { FilterType } from 'components/common/filter/Filter';
import LightbulbLineIcon from 'remixicon-react/LightbulbLineIcon';
import Filter2LineIcon from 'remixicon-react/Filter2LineIcon';
import { useLocation, useNavigate } from 'react-router-dom';

type NoProjectsProps = {
  filters: FilterType[];
  clearFilters: () => void;
};

function NoInsights({ filters, clearFilters }: NoProjectsProps) {
  const theme = useTheme();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  if (filters?.[0]?.selectedOptions && filters[0].selectedOptions?.length > 0) {
    return (
      <NoDataContainer>
        <Filter2LineIcon size={120} color={theme.palette.custom.steel} />
        <NoDataTitle mt={1}>Looks like your search is too specific.</NoDataTitle>
        <NoDataDescription my={1}>
          Try to expand your filters to see more content.
        </NoDataDescription>
        <Button
          variant="outlined"
          sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mt: 1 })}
          onClick={() => {
            clearFilters();
            if (hash) {
              navigate(pathname);
            }
          }}
        >
          Clear Filters
        </Button>
      </NoDataContainer>
    );
  }

  return (
    <NoDataContainer>
      <LightbulbLineIcon size={120} color={theme.palette.custom.steel} />
      <NoDataDescription mb={1}>No Insights Found</NoDataDescription>
    </NoDataContainer>
  );
}

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '40px 24px 0',
});

const NoDataTitle = styled(Box, { label: 'NoDataTitle' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
}));

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default NoInsights;
