import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { catchErrorMessage, getVideoCover, isValidImage, isValidVideo } from 'utility/helpers';
import { toast } from 'react-toastify';
import useUserData from 'hooks/useUserData';
import InsightAPI from 'api/InsightAPI';

export type FeedbackFormData = {
  feedback: string;
  attachments?: File[];
};

const useFeedbackForm = (afterSubmit: () => void) => {
  const { company, user } = useUserData();
  const [loading, setLoading] = useState(false);
  const [attachments] = useState<File[]>([]);
  const [progress, setProgress] = useState(0);

  const { handleSubmit, control, getValues, setValue, register, watch, reset, trigger } =
    useForm<FeedbackFormData>({ defaultValues: { feedback: '', attachments: [] } });

  const onProgress = (progressEvent: ProgressEvent) => {
    const percent = Math.round((100 * progressEvent.loaded) / progressEvent.total);
    setProgress(percent);
  };

  const onSubmit = async (values: FeedbackFormData) => {
    try {
      trigger();
      if (!values.feedback) return null;
      setLoading(true);
      setProgress(0);
      const payload = {
        title: `${company?.name}; ${user?.userNameToDisplay}; ${user?.title}`,
        description: values.feedback,
      };

      if (values.attachments?.length) {
        for (let i = 0; i < values.attachments!.length; i++) {
          const _fileValue = values?.attachments?.[i];
          let thumbnail;
          if (isValidImage(_fileValue?.type)) {
            thumbnail = _fileValue;
          } else if (isValidVideo(_fileValue?.type)) {
            try {
              const videoCover: any = await getVideoCover(_fileValue);
              const file = new File([videoCover], _fileValue?.name + '.jpg', {
                type: 'image/jpeg',
                lastModified: new Date().getTime(),
              });
              thumbnail = file;
            } catch (error) {
              console.log(error);
            }
          }

          await InsightAPI.feedback(payload, values.attachments![i], thumbnail, onProgress);
        }
      } else {
        await InsightAPI.feedback(payload, '', '', onProgress);
      }

      toast.success('Feeback saved successfully');
      reset();
      afterSubmit();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  return {
    attachments,
    control,
    loading,
    progress,
    getValues,
    handleSubmit,
    onSubmit,
    register,
    reset,
    setValue,
    triggerSubmit,
    watch,
  };
};

export default useFeedbackForm;
