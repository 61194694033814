import { API, PYTHON_SERVICE_URL } from '@buildwise/libs/services';

class PythonAPI extends API {
  constructor() {
    super({
      baseURL: PYTHON_SERVICE_URL,
    });
  }
}

export default PythonAPI;
