import { CompanyType } from 'hooks/useCompanyQueries';
import CompanyAPI from 'api/CompanyAPI';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { updateCompaniesQueryCache } from 'hooks/useCompanyQueries';
import useAuthStore from 'store/useAuthStore';
import { shallow } from 'zustand/shallow';

export function useStarredWorkspaceState() {
  const [starringWorkspaceId, setStarringWorkspaceId] = useState('');
  const [userAuthDetails] = useAuthStore((state) => [state.user], shallow);

  const starWorkspace = useCallback(
    async (company: CompanyType) => {
      try {
        setStarringWorkspaceId(company.id);
        const isStarred = !company?.starred;

        await CompanyAPI.starWorkspace(userAuthDetails?.companyId, {
          workSpaceIds: [company.id],
          isStarred,
        });

        updateCompaniesQueryCache(
          { ...company, starred: isStarred },
          'update',
          userAuthDetails?.isAssociatedWithOtherCompanies,
        );
      } catch (error) {
        console.warn('STAR_WORKSPACE_ERROR', error);
        toast.error(catchErrorMessage(error));
      } finally {
        setStarringWorkspaceId('');
      }
    },
    [userAuthDetails],
  );

  return {
    starringWorkspaceId,
    starWorkspace,
  };
}
