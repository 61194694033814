import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import { Dispatch, SetStateAction, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import GeneratePostWithAiForm, {
  _defaultValues,
  schemas,
  FormValues,
} from './GeneratePostWithAiForm';
import { FormProvider } from 'react-hook-form';
import genAIKnowledgeAPI from 'api/GenAIKnowledgeAPI';
import useUserData from 'hooks/useUserData';
import { toast } from 'react-toastify';
import { catchErrorMessage, extractImagesFromHtml } from 'utility/helpers';
import _ from 'lodash';
import { checkURLAccessibility } from 'utility/helpers';
import { AttachmentType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';

type GeneratePostWithAiModalProps = {
  open: boolean;
  onGeneratePostCallback?: (options: {
    article: { title: string; description: string };
    attachments?: AttachmentType[];
  }) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
};

export type TabNameType = 'content' | 'file' | 'url';

function GeneratePostWithAiModal({
  open,
  setOpen,
  onGeneratePostCallback,
  isExpanded,
}: GeneratePostWithAiModalProps) {
  const { companyId } = useUserData();
  const [tab, setTab] = useState<TabNameType>('content');
  const [showmodal, setShowModal] = useState(false);
  const formState = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schemas[tab]),
    defaultValues: {
      description: '',
      attachments: [],
      coverPhotoFileName: '',
      url: '',
    },
  });
  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
  } = formState;

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const handleGenerateArticle = async (values) => {
    try {
      const { description, url, attachments } = values;
      let result;
      let updatedAttachments: AttachmentType[] = [];

      if (tab === 'url') {
        const isAccessible = await checkURLAccessibility(url);
        if (!isAccessible) {
          throw new Error('Please enter a valid URL');
        }
      }

      if (tab === 'content') {
        updatedAttachments = extractImagesFromHtml(description);
      }

      if (['content', 'url'].includes(tab)) {
        let payload = {
          companyId,
          ...(tab === 'content' && { summary_text: description }),
          ...(tab === 'url' && { web_url: url }),
        };
        result = await genAIKnowledgeAPI.generateArticleSummary(payload);
      } else {
        result = await genAIKnowledgeAPI.generateArticleSummaryFromFile(
          companyId,
          attachments[0].file,
        );
      }
      if (result?.status === 200 && !_.isEmpty(result?.data)) {
        onGeneratePostCallback?.({
          article: result?.data,
          attachments: updatedAttachments,
        });
        toast.success('Post generated successfully');
      } else {
        toast.error('Failed to generate the post');
      }
    } catch (error) {
      console.warn('ARTCILE_GENERATE_ERROR', error);
      toast.error(catchErrorMessage(error));
    }
  };

  const onSubmit = handleSubmit(handleGenerateArticle);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      TransitionComponent={GrowDialogTransition}
      sx={{ '& .MuiPaper-root': { height: '68%' } }}
    >
      <CustomDialogTitle
        onClose={closeModal}
        id="dialog-title"
        sx={{ textAlign: 'center', borderBottom: 'none' }}
      >
        <Title>Convert Your Content to Post</Title>
      </CustomDialogTitle>
      <DialogContent id="dialog-description">
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Tabs
              value={tab}
              onChange={(_: unknown, newValue: TabNameType) => {
                setTab(newValue);
                reset();
              }}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
              sx={{
                border: 'none',
                '& .MuiTab-root': {
                  margin: 0,
                  border: 'none',
                  backgroundColor: '#F5F6F7',
                  color: '#35374F',
                  textTransform: 'none',
                  padding: '8px, 12px, 8px, 12px',
                  fontWeight: 700,
                  '&:hover': {
                    backgroundColor: '#b3b3b3',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#35374F',
                    color: '#FFFFFF',
                  },
                },
              }}
            >
              <Tab label="Add Text" value="content" />
              <Tab label="Upload File" value="file" />
              {/* <Tab label="Add URL" value="url" /> */}
            </Tabs>
          </Box>

          <Box sx={{ padding: 2 }}>
            <FormProvider {...formState}>
              <GeneratePostWithAiForm
                tab={tab}
                isExpanded={isExpanded}
                showModal={showmodal}
                setShowModal={setShowModal}
              />
            </FormProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={onSubmit}
          variant="contained"
        >
          {isSubmitting ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Generate Post'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

export default GeneratePostWithAiModal;
