import { styled } from '@mui/material/styles';
import { AttachmentType } from '../hooks/useArticlesQuery';
import VolumeUpFillIcon from 'remixicon-react/VolumeUpFillIcon';
import { ArticleVariantType } from './ArticleBox';

type RenderAudioProps = {
  isDraftView?: boolean;
  attachment: AttachmentType;
  isRelatedPostView?: boolean;
  variant?: ArticleVariantType;
};

function RenderAudio({ attachment, isDraftView, isRelatedPostView, variant }: RenderAudioProps) {
  return (
    <>
      {isDraftView ||
      isRelatedPostView ||
      variant === 'list' ||
      variant === 'detailed' ||
      variant === 'short' ||
      variant === 'playlist-article' ||
      variant === 'playlist' ? (
        <VolumeUpFillIcon size={isRelatedPostView || variant === 'playlist-article' ? 40 : 80} />
      ) : (
        <$Audio src={attachment?.articleOpenUrl} controls className={variant} />
      )}
    </>
  );
}

export const $Audio = styled('audio', { label: 'Audio' })(({ theme }) => ({
  width: '100%',
}));

export default RenderAudio;
