import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { catchErrorMessage } from 'utility/helpers';
import { toast } from 'react-toastify';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { styled } from '@mui/material/styles';
import ProjectsAPI from 'api/ProjectsAPI';
import useUserData from 'hooks/useUserData';
import useFilteredInsightsQuery, {
  BusinessObjectStatus,
  FilteredRFI,
  MeetingMinutes,
} from './hooks/useFilteredInsightsQuery';

type ParkInsightModalProps = {
  filteredRFI: FilteredRFI | MeetingMinutes;
  objectType: 'RFI' | 'MEETING_MINUTES';
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function ParkInsightModal({ filteredRFI, objectType, open, setOpen }: ParkInsightModalProps) {
  const { companyId } = useUserData();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const { updateFilteredInsightsCache } = useFilteredInsightsQuery({
    objectType,
    shouldFetch: false,
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      await ProjectsAPI.updateInsight(companyId, {
        businessObjects: [
          {
            objectId: filteredRFI.id,
            objectType,
            reviewStatus: {
              status: BusinessObjectStatus.PENDING_APPROVAL,
              notes: reason.trim(),
            },
          },
        ],
      });
      toast.success('Status updated');
      updateFilteredInsightsCache({
        action: 'update',
        insight: {
          ...filteredRFI,
          businessObjectStatus: BusinessObjectStatus.PENDING_APPROVAL,
          updated_at: new Date().toISOString(),
          businessObjectNotes: reason.trim(),
        },
      });
      closeModal();
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const closeModal = () => {
    setReason('');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Save for Review</Title>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ borderBottom: 0 }}>
        <Typography mb={2}>Save this item for review with a comment.</Typography>
        <TextField
          multiline
          minRows={5}
          maxRows={10}
          autoFocus
          fullWidth
          value={reason}
          onChange={handleChange}
          placeholder="Provide feedback to improve recommendations..."
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSubmit} disabled={loading || !reason.trim()}>
          {loading ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Save'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
}));

export default ParkInsightModal;
