import {
  Box,
  Grid,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Chip,
  Button,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConfigEntryType, ConfigType } from 'config/config';
import useUserData from 'hooks/useUserData';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { catchErrorMessage } from 'utility/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import useAdminConfigsByTypeQuery from '../queries/useAdminConfigsByTypeQuery';
import AdminAPI from '../apis/AdminAPI';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import RenderEntryItem from './RenderEntryItem';
import { DndContext } from '@dnd-kit/core';

type RenderConfigProps = {
  config: ConfigType;
  configType: string;
};

function RenderConfig({ config, configType }: RenderConfigProps) {
  const { isNovaAdmin } = useUserData();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [customFields, setCustomFields] = useState<Omit<ConfigEntryType, 'id'>[]>([]);
  const { updateConfigsCache } = useAdminConfigsByTypeQuery({
    configType,
  });

  const addMultipleConfig = async () => {
    try {
      const { data } = await AdminAPI.updateConfig({
        ...config,
        entries: [...config.entries, ...customFields] as ConfigEntryType[],
      });
      toast.success(`Config added successfully`);
      updateConfigsCache({ updatedConfig: data.config });
      if (hash) {
        navigate(pathname);
      }
      setCustomFields([]);
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const updateConfigEntriesOrder = async (entries: ConfigEntryType[]) => {
    const oldEntries = [...config.entries];
    try {
      updateConfigsCache({ updatedConfig: { ...config, sortFlag: false, entries } });
      await AdminAPI.updateConfig({
        ...config,
        sortFlag: false,
        entries,
      });
      if (hash) {
        navigate(pathname);
      }
    } catch (e) {
      updateConfigsCache({ updatedConfig: { ...config, sortFlag: false, entries: oldEntries } });
      toast.error(catchErrorMessage(e));
    }
  };

  // const deleteConfig = async ({
  //   config,
  //   deleteConfigEntry,
  // }: {
  //   config: ConfigType;
  //   deleteConfigEntry: ConfigEntryType;
  // }) => {
  //   try {
  //     const updateConfigEntries = [...(config.entries || [])];
  //     const index = updateConfigEntries.findIndex(
  //       (entry) => entry.entryCode === deleteConfigEntry.entryCode,
  //     );
  //     updateConfigEntries[index].deleteFlag = true;
  //     const { data } = await AdminAPI.updateConfig({
  //       ...config,
  //       entries: updateConfigEntries,
  //     });
  //     toast.success(`${updateConfigEntries[index].entryCode} deleted successfully`);
  //     updateConfigsCache({ updatedConfig: data.config });
  //     if (hash) {
  //       navigate(pathname);
  //     }
  //   } catch (e) {
  //     toast.error(catchErrorMessage(e));
  //   }
  // };

  return (
    <Container id={config.configName}>
      <Box sx={{ padding: 4 }}>
        <Grid container>
          <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
            <Typography variant="h5">{config.description}</Typography>
          </Grid>
          <Grid container item md={9} xs={12}>
            {isNovaAdmin && (
              <Grid container item md={12} sx={{ marginBottom: 2 }}>
                <Grid item md={10}>
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      limitTags={3}
                      disableCloseOnSelect
                      onChange={(_: unknown, values: Omit<ConfigEntryType, 'id'>[]) => {
                        setCustomFields(values);
                      }}
                      getOptionLabel={(option) => option.entryDescription}
                      options={customFields}
                      autoHighlight
                      value={customFields || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Fields"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                        />
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            label={option.entryDescription}
                            deleteIcon={<CloseLineIcon size={20} />}
                          />
                        ))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <Button
                    sx={{ ml: 2 }}
                    variant="outlined"
                    disabled={!customFields.length}
                    onClick={addMultipleConfig}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container item md={12}>
              <DndContext
                onDragEnd={({ active, over }) => {
                  if (over && active.id !== over?.id) {
                    const activeIndex = config.entries.findIndex(({ id }) => id === active.id);
                    const overIndex = config.entries.findIndex(({ id }) => id === over.id);

                    updateConfigEntriesOrder(arrayMove(config.entries, activeIndex, overIndex));
                  }
                }}
              >
                <SortableContext items={config.entries.map((entry) => ({ id: entry?.id || '' }))}>
                  {config.entries.map((entry, index) => (
                    <RenderEntryItem key={entry.id} config={config} entry={entry} index={index} />
                  ))}
                </SortableContext>
              </DndContext>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const Container = styled(Paper, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 16,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  marginBottom: 24,
  overflow: 'hidden',
}));

export default RenderConfig;
