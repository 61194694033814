import { useState, useMemo, useEffect, ChangeEvent, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FilterType } from 'components/common/filter/Filter';
import { TabProps } from '@mui/material';
import { theme } from 'config/theme';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import DraftLineIcon from 'remixicon-react/DraftLineIcon';
import {
  InsightStatusChip,
  InsightStatusText,
} from 'pages/projects/components/RenderFilteredInsightStatus';
import useFilteredInsightsQuery, { BusinessObjectStatus } from './useFilteredInsightsQuery';
import _ from 'lodash';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

const INSIGHT_STATUS = [
  {
    type: 'New',
    backgroundColor: theme.palette.custom.earlyDawn,
    status: BusinessObjectStatus.NEW,
  },
  {
    type: 'Pending Approval',
    icon: <TimeLineIcon size={18} color={theme.palette.custom.tuna} />,
    backgroundColor: theme.palette.custom.softPeach,
    status: BusinessObjectStatus.PENDING_APPROVAL,
  },
  {
    type: 'Rejected',
    icon: <CloseCircleLineIcon size={18} color={theme.palette.custom.redWine} />,
    backgroundColor: theme.palette.custom.dawnPink,
    status: BusinessObjectStatus.REJECTED,
  },
  {
    type: 'Approved',
    icon: <CheckLineIcon size={18} color={theme.palette.custom.tuna} />,
    backgroundColor: theme.palette.custom.iceberg,
    status: BusinessObjectStatus.APPROVED,
  },
  {
    type: 'AI Draft Created',
    icon: <DraftLineIcon size={18} color={theme.palette.custom.tealGreen} />,
    backgroundColor: theme.palette.custom.offGreen,
    status: BusinessObjectStatus.AI_DRAFT_CREATED,
  },
];

export default function useAIDraftsWorkbench() {
  const { hash } = useLocation();
  const [tab, setTab] = useState(decodeURIComponent(hash).replace('#', '') || 'rfis');
  const { insights: insightsData, isLoading } = useFilteredInsightsQuery({
    objectType: tab === 'rfis' ? 'RFI' : 'MEETING_MINUTES',
  });

  const TABS = useMemo(() => {
    const allTabs: (TabProps & { hide: boolean })[] = [
      {
        value: 'rfis',
        label: `RFIs`,
        hide: false,
      },
      {
        value: 'meetingMinutes',
        label: `Meeting Minutes`,
        hide: false,
      },
    ];

    return allTabs.filter((_tab) => !_tab.hide);
  }, []);

  const handleFilterChange = useCallback(
    (categoryIndex: number, event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFilters((prevState) => {
        const updatedState = [...prevState];

        if (event.target.value === 'Select All') {
          if (checked) {
            updatedState[categoryIndex].selectedOptions = updatedState[categoryIndex].allOptions;
          } else {
            updatedState[categoryIndex].selectedOptions = [];
          }

          return updatedState;
        }

        if (checked) {
          updatedState[categoryIndex].selectedOptions = [
            ...(updatedState[categoryIndex]?.selectedOptions || []),
            event.target.value,
          ];
        } else {
          updatedState[categoryIndex].selectedOptions = updatedState[
            categoryIndex
          ]?.selectedOptions?.filter((option) => option !== event.target.value);
        }

        return updatedState;
      });
    },
    [],
  );

  const [filters, setFilters] = useState<FilterType[]>([
    {
      allOptions: [],
      category: 'Status',
      defaultOpen: true,
      options: [],
      selectedOptions: [],
      showSelectAll: false,
      onChange: handleFilterChange.bind(null, 0),
    },
    {
      allOptions: [],
      category: 'Projects',
      defaultOpen: true,
      options: [],
      selectedOptions: [],
      showSelectAll: true,
      onChange: handleFilterChange.bind(null, 1),
    },
  ]);

  const insights = useMemo(() => {
    let _data = [...insightsData];
    _data = _data.filter((insight) => {
      const isStatusMatched =
        filters?.[0]?.selectedOptions?.includes(insight.businessObjectStatus) ||
        (insight.businessObjectStatus !== BusinessObjectStatus.NEW &&
          insight.businessObjectStatus !== BusinessObjectStatus.PENDING_APPROVAL &&
          insight.businessObjectStatus !== BusinessObjectStatus.APPROVED &&
          insight.businessObjectStatus !== BusinessObjectStatus.REJECTED &&
          insight.businessObjectStatus !== BusinessObjectStatus.AI_DRAFT_CREATED &&
          filters?.[0]?.selectedOptions?.includes(BusinessObjectStatus.NEW));
      const isProjectMatched = filters?.[1]?.selectedOptions?.includes(insight.procore_project_id);

      if (filters?.[0]?.selectedOptions?.length && filters?.[1]?.selectedOptions?.length) {
        return isStatusMatched && isProjectMatched;
      } else if (filters?.[0]?.selectedOptions?.length) {
        return isStatusMatched;
      } else if (filters?.[1]?.selectedOptions?.length) {
        return isProjectMatched;
      }

      return insight;
    });

    return _data;
  }, [insightsData, filters]);

  const { insightsCountByStatus } = useMemo(() => {
    let insightsCount: Record<string, number> = {};
    insightsData.forEach((insight) => {
      if (
        insight.businessObjectStatus === BusinessObjectStatus.AI_DRAFT_CREATED ||
        insight.businessObjectStatus === BusinessObjectStatus.PENDING_APPROVAL ||
        insight.businessObjectStatus === BusinessObjectStatus.APPROVED ||
        insight.businessObjectStatus === BusinessObjectStatus.REJECTED
      ) {
        insightsCount[insight.businessObjectStatus] = insightsCount[insight.businessObjectStatus]
          ? insightsCount[insight.businessObjectStatus] + 1
          : 1;
      } else {
        insightsCount[BusinessObjectStatus.NEW] = insightsCount[BusinessObjectStatus.NEW]
          ? insightsCount[BusinessObjectStatus.NEW] + 1
          : 1;
      }
    });
    return {
      insightsCountByStatus: insightsCount,
    };
  }, [insightsData]);

  const { projectInsightsCount, projectsOptions } = useMemo(() => {
    const projectsOptions = _.sortBy(
      _.uniqBy(
        insightsData.map((insight) => ({
          label: insight.procore_project_name,
          value: insight.procore_project_id,
        })),
        'value',
      ),
      'label',
    );
    let projectInsightsCount: Record<string, number> = {};
    insightsData.forEach((insight) => {
      projectInsightsCount[insight.procore_project_id] = projectInsightsCount[
        insight.procore_project_id
      ]
        ? projectInsightsCount[insight.procore_project_id] + 1
        : 1;
    });
    return {
      projectsOptions,
      projectInsightsCount,
    };
  }, [insightsData]);

  useEffect(() => {
    setFilters((prevState) => [
      {
        ...prevState[0],
        options: INSIGHT_STATUS.map((option) => ({
          label: (
            <InsightStatusChip sx={{ backgroundColor: option.backgroundColor }}>
              {option.icon}
              <InsightStatusText sx={{ fontSize: '14px !important', ml: option.icon ? 1 : 0 }}>
                {option.type}&nbsp;
                {insightsCountByStatus[option.status]
                  ? `(${insightsCountByStatus[option.status]})`
                  : ''}
              </InsightStatusText>
            </InsightStatusChip>
          ),
          value: option.status,
        })),
        allOptions: INSIGHT_STATUS.map((insight) => insight.type),
      },
      {
        ...prevState[1],
        options: projectsOptions.map((project) => ({
          ...project,
          badgeValue: projectInsightsCount[project.value],
        })),
        allOptions: projectsOptions.map((project) => project.value),
      },
    ]);
  }, [insightsCountByStatus, projectInsightsCount, projectsOptions]);

  const clearFilters = useCallback(() => {
    setFilters((prevState) => {
      return prevState.map((filter) => ({ ...filter, selectedOptions: [] }));
    });
  }, []);

  useEffect(() => clearFilters(), [tab]);

  return {
    TABS,
    filters,
    insights,
    isLoading,
    tab,
    clearFilters,
    setTab,
  };
}
