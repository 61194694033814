import { ReactNode, Ref } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Avatar as MuiAvatar, Badge, ButtonProps, SxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { getAvatarBgColor } from 'utility/helpers';

const $Avatar = styled(MuiAvatar)<{ size?: number; fontSize?: number; bgcolor?: string }>(
  ({ theme, size = 40, fontSize = 20, bgcolor }) => ({
    backgroundColor: bgcolor || theme.palette.secondary.dark,
    width: size,
    height: size,
    fontSize,
  }),
);

interface AvatarPropTypes<T> extends Omit<ButtonProps, 'size'> {
  name?: string;
  icon?: ReactNode;
  size?: number;
  to?: string;
  src?: string;
  fontSize?: number;
  buttonRef?: Ref<T>;
  onClick?: () => void;
  sx?: SxProps;
  bgcolor?: string;
  badgeContent?: ReactNode;
}

const Avatar = <T extends HTMLElement>({
  name,
  icon,
  size = 40,
  fontSize = 20,
  to,
  onClick,
  buttonRef,
  bgcolor,
  sx,
  src,
  badgeContent,
  ...buttonProps
}: AvatarPropTypes<T>) => (
  <>
    {!!to ? (
      <Link to={to} ref={buttonRef as Ref<HTMLAnchorElement>}>
        <$Avatar
          src={src}
          size={size}
          fontSize={fontSize}
          bgcolor={bgcolor || getAvatarBgColor(name || '', 30, 50)}
        >
          {name ? name.slice(0, 1).toUpperCase() : icon ? icon : ''}
        </$Avatar>
      </Link>
    ) : (
      <IconButton
        onClick={onClick}
        sx={{ p: 0, ...sx }}
        ref={buttonRef as Ref<HTMLButtonElement>}
        {...buttonProps}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={badgeContent || null}
          sx={{ ml: 0 }}
        >
          <$Avatar
            src={src}
            size={size}
            fontSize={fontSize}
            bgcolor={bgcolor || (name && getAvatarBgColor(name, 30, 50))}
          >
            {name ? name.slice(0, 1).toUpperCase() : icon ? icon : ''}
          </$Avatar>
        </Badge>
      </IconButton>
    )}
  </>
);

export default Avatar;
