import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from './GeneratePostWithAiForm';
import { toast } from 'react-toastify';
import PostAttachments from '../PostAttachments';
import { AttachmentType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { catchErrorMessage } from 'utility/helpers';

const FileUpload = () => {
  const { setValue, watch } = useFormContext<FormValues>();
  const attachments = watch('attachments');
  const [loadingFiles, setLoadingFiles] = useState(false);

  const DOCUMENT_TYPES = [
    'docx',
    'csv',
    'pdf',
    'xlsx',
    'pptx',
    'xls',
    'msword',

    'application/docx',
    'text/csv',
    'application/pdf',
    'application/xlsx',
    'application/xls',
    'text/plain',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
  ];

  const onDrop = (acceptedFiles: File[]) => {
    try {
      setLoadingFiles(true);
      const invalidFiles: string[] = [];
      let files = acceptedFiles.filter((file) => {
        if (DOCUMENT_TYPES.includes(file.type)) {
          return true;
        } else {
          invalidFiles.push(file.type);
          return false;
        }
      });
      if (invalidFiles.length > 0) {
        return toast.error(`File types ${invalidFiles.join(', ')} are not allowed`);
      }
      let updatedAttachments: AttachmentType[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const attachment = {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            articleOpenUrl: '',
            thumbnailOpenUrl: '',
            thumbnail: file,
            file,
          } as AttachmentType;

          if (DOCUMENT_TYPES.includes(file.type)) {
            attachment.articleOpenUrl = URL.createObjectURL(file);
            attachment.thumbnailOpenUrl = URL.createObjectURL(file);
          }

          updatedAttachments.push(attachment);
        } catch (error) {
          console.log('LOCAL_FILE_NOT_LOADED_ERROR', error);
        }
      }
      setValue('attachments', [...updatedAttachments], {
        shouldDirty: true,
        shouldValidate: true,
      });
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setLoadingFiles(false);
    }
  };

  return (
    <>
      <PostAttachments
        article={undefined}
        loadingFiles={loadingFiles}
        onDrop={onDrop}
        isExpanded={true}
        hideDropZone={!!attachments.length}
        multiple={false}
      />
    </>
  );
};

export default FileUpload;
