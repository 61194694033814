import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import RequestPostForm from './RequestPostForm';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import useUserData, { UserType } from 'hooks/useUserData';
import _ from 'lodash';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import moment from 'moment';
import FormsAPI from 'api/FormsAPI';

// Define the minimum date as today
const today = new Date();
today.setHours(0, 0, 0, 0);

export type UsersSearchResponseType = {
  totalElements?: number;
  totalNumber?: number;
  totalPages?: number;
  userResponse: UserType[];
};

export type RequestPostFormSchema = {
  assignedTo: string;
  requestMessage: string;
  dueDate?: string | Date | null;
};

const RequestPostFormSchemaValidations = Yup.object().shape({
  assignedTo: Yup.string().email('Please enter valid email').required('This is required field'),
  requestMessage: Yup.string().required('Please enter message'),
  dueDate: Yup.date()
    .nullable()
    .notRequired()
    .min(today, 'Please select a date in the future or today')
    .typeError('Please enter valid date'),
});

type RequestPostModalProps = {
  open: boolean;
  onRequestPostCallback?: (options: { form: any }) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function RequestPostModal({ open, onRequestPostCallback, setOpen }: RequestPostModalProps) {
  const { company, companyId } = useUserData();
  const formState = useForm<RequestPostFormSchema>({
    mode: 'all',
    resolver: yupResolver(RequestPostFormSchemaValidations),
    defaultValues: {
      assignedTo: '',
      requestMessage: '',
      dueDate: null,
    },
  });
  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
    setError,
  } = formState;
  const validEmailDomains = useMemo(
    () => _.filter([company?.emailDomain || '', ...(company?.otherEmailDomains || [])], _.length),
    [company?.emailDomain, company?.otherEmailDomains],
  );
  const { data: peopleData } = useSearchItemQuery<UsersSearchResponseType>(
    {
      searchType: 'users',
      operator: 'or',
      filterBy: {
        filters: [
          {
            filterType: 'name',
            filterValue: 'ALL',
          },
        ],
      },
    },
    true,
  );

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const handleSave = async (values: RequestPostFormSchema) => {
    /* DevNote:
        If invitePermittedDomainsFlag:true
          then posts can be requested from existing company users + new users with matching domain [company settings: emailDomain:"", otherEmailDomains:[]]
        If invitePermittedDomainsFlag:flase
          then posts can be requested from existing company users
    */

    try {
      // check if entered email is acceptable
      const emailDomain = values.assignedTo.split('@').pop() || '';
      if (
        (company?.settings?.invitePermittedDomainsFlag &&
          validEmailDomains.includes(emailDomain)) ||
        !!peopleData?.userResponse?.find((user) => user.username === values.assignedTo)
      ) {
        const { data } = await FormsAPI.createForm({
          ...values,
          dueDate: values.dueDate
            ? moment(values.dueDate).endOf('day').format('YYYY-MM-DDTHH:mmZ')
            : '',
          companyId,
          status: 'NEW',
          formCategory: FORMS_CONFIG_CATEGORY_TYPES.REQUEST_A_POST_CARD,
        });
        if (!_.isEmpty(data)) {
          toast.success('Post requested successfully!');
          onRequestPostCallback?.({
            form: data.form,
          });
          closeModal();
        } else {
          toast.error('Failed to request a post');
        }
      } else {
        setError(
          'assignedTo',
          {
            message: company?.settings.invitePermittedDomainsFlag
              ? `Sorry, ${emailDomain} is not permitted.`
              : 'Please choose an existing member.',
            type: 'INVALID_EMAIL_DOMAIN',
          },
          { shouldFocus: true },
        );
      }
    } catch (error) {
      console.warn('REQUEST_POST_ERROR', error);
      toast.error(catchErrorMessage(error));
    }
  };

  const onRequest = handleSubmit(handleSave);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      TransitionComponent={GrowDialogTransition}
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Request a Post</Title>
      </CustomDialogTitle>
      <DialogContent id="dialog-description" dividers sx={{ borderBottom: 0 }}>
        <FormProvider {...formState}>
          <RequestPostForm />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={onRequest}
          variant="contained"
        >
          {isSubmitting ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Send Request'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

export default RequestPostModal;
