import { FormControl, FormHelperText, Stack, Chip, Box, InputBase } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { preventComma } from 'utility/helpers';
import { TemplateFormValuesType } from '../../../hooks/useTemplatesForm';
import { Dispatch, KeyboardEvent, MouseEvent, SetStateAction, useEffect, useRef } from 'react';
import Editor, { initConfig } from 'components/common/editor/Editor';
import TinyMceEditorSkelton from 'components/utils/TinyMceEditorSkelton';
import { Link } from 'react-router-dom';
import { SEARCH } from 'constants/routes';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { styled } from '@mui/material/styles';

type TemplateFormProps = {
  loading: boolean;
  showModal: boolean;
  handleLabelKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleRemoveLabel: (label: string) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

function TemplateForm({
  loading,
  showModal,
  handleLabelKeyDown,
  handleRemoveLabel,
  setShowModal,
}: TemplateFormProps) {
  const { control, watch } = useFormContext<TemplateFormValuesType>();
  const labels = watch('labels');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(-1, -1);
      inputRef.current.focus();
    }
  }, [inputRef, showModal]);

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: 'Please enter template title',
          minLength: { value: 3, message: 'Title name should be atleast 3 characters' },
        }}
        render={({ field, fieldState }) => (
          <FormControl sx={{ minHeight: 62 }} fullWidth>
            <TitleInput
              inputRef={inputRef}
              inputProps={{ minLength: 3, maxLength: 250 }}
              error={!!fieldState.error}
              fullWidth
              placeholder="Add a title"
              {...field}
              value={!!field.value.trim() ? field.value : ''}
              disabled={loading}
            />
            <FormHelperText sx={{ marginLeft: 0, bottom: 0, position: 'absolute' }} error>
              {fieldState.error?.message}
            </FormHelperText>
          </FormControl>
        )}
        name="title"
      />

      <Controller
        control={control}
        render={({ field }) => (
          <TagsInput
            fullWidth
            placeholder="Add tags (optional)"
            {...field}
            onKeyUp={handleLabelKeyDown}
            onKeyDown={preventComma}
            value={!!field?.value?.trim() ? field.value : ''}
            disabled={loading}
          />
        )}
        name="tag"
      />
      <Stack direction="row" flexWrap="wrap" minHeight={30} my={labels.length > 0 ? 1 : 0}>
        {labels.map((label) => (
          <Chip
            key={label}
            onDelete={(event: MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();
              handleRemoveLabel(label);
            }}
            label={label}
            variant="outlined"
            size="small"
            sx={{
              mr: 0.5,
              textTransform: 'capitalize',
              cursor: 'pointer',
              '&:hover': { textDecoration: 'none' },
            }}
            component={Link}
            to={`${SEARCH}?q=${label}`}
            target="_blank"
            disabled={loading}
            deleteIcon={<CloseLineIcon size={20} />}
          />
        ))}
      </Stack>

      <Box
        sx={{
          mb: 2,
          position: 'relative',
          height: 420,
        }}
      >
        <Box sx={{ visibility: showModal ? 'visible' : 'hidden' }}>
          <Controller
            control={control}
            rules={{ required: 'Please enter description' }}
            render={({ field: { ref, onChange, ...field }, fieldState }) => (
              <FormControl sx={{ height: 420 }} fullWidth>
                <Editor
                  {...field}
                  onEditorChange={onChange}
                  onInit={(_, editor) => {
                    ref(editor);
                    setShowModal(true);
                  }}
                  value={!!field.value.trim() ? field.value : ''}
                  disabled={loading}
                  init={{
                    ...initConfig,
                    content_style: `
                      ${initConfig.content_style}

                      body.mce-content-readonly {
                        color: #00000061;
                      }
                      `,
                  }}
                />
                <FormHelperText sx={{ marginLeft: 0, bottom: -30, position: 'absolute' }} error>
                  {fieldState.error?.message}
                </FormHelperText>
              </FormControl>
            )}
            name="description"
          />
        </Box>

        {!showModal && <TinyMceEditorSkelton />}
      </Box>
    </>
  );
}

const TitleInput = styled(InputBase, { label: 'TitleInput' })({
  fontSize: 20,
  fontWeight: 700,
});

const TagsInput = styled(InputBase, { label: 'TopicsInput' })({
  fontSize: 16,
});

export default TemplateForm;
