import { styled } from '@mui/material/styles';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Checkbox,
  Avatar,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Dispatch, Fragment, SetStateAction, useMemo } from 'react';
import { useCompaniesQuery } from 'hooks/useCompanyQueries';
import HandleOperation from 'components/utils/HandleOperation';
import { getAvatarBgColor } from 'utility/helpers';
import _ from 'lodash';
import useUserData from 'hooks/useUserData';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';

type SelectWorkspacesToForwardModalProps = {
  article: ArticleType;
  open: boolean;
  selectedCompanyIds: string[];
  onNext: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedCompanyIds: Dispatch<SetStateAction<string[]>>;
};

function SelectWorkspacesToForwardModal({
  article,
  open,
  selectedCompanyIds,
  onNext,
  setOpen,
  setSelectedCompanyIds,
}: SelectWorkspacesToForwardModalProps) {
  const { companyId, isNovaAdmin } = useUserData();
  const { companies: associatedCompanies, isLoading } = useCompaniesQuery({
    isAssociatedWithOtherCompanies: !isNovaAdmin,
  });
  const companies = useMemo(
    () => associatedCompanies.filter((company) => company.id !== companyId),
    [associatedCompanies, companyId],
  );

  const closeModal = () => {
    setSelectedCompanyIds([]);
    setOpen(false);
  };

  const handleChange = (companyId: string) => {
    if (!article?.publishArticleToCompanyIds?.includes(companyId)) {
      if (selectedCompanyIds.includes(companyId)) {
        setSelectedCompanyIds((prevState) =>
          prevState.filter((_companyId) => _companyId !== companyId),
        );
      } else {
        setSelectedCompanyIds((prevState) => [...prevState, companyId]);
      }
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ '& .MuiPaper-root': { minHeight: '50%', maxHeight: '80%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Select Workspace(s)</Title>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description" sx={{ p: 0, borderBottom: 0 }}>
        <Description>
          Select the workspace(s) that you would like to forward the post to.
        </Description>
        <HandleOperation isLoading={isLoading} data={companies}>
          {companies.map((company) => (
            <Fragment key={company.id}>
              <CompanyHoverContainer onClick={() => handleChange(company.id)}>
                <CompanyContainer>
                  <Checkbox
                    checked={selectedCompanyIds.includes(company.id)}
                    disabled={article?.publishArticleToCompanyIds?.includes(company.id)}
                  />
                  <Box display="flex" alignItems="center" ml={0.5}>
                    <Avatar
                      variant="rounded"
                      src={company.companyLogoURL}
                      sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: company.companyLogoURL
                          ? 'transparent'
                          : getAvatarBgColor(company.name || '', 50, 50),
                        fontSize: 18,
                        fontWeight: 700,
                        '& .MuiAvatar-img': {
                          objectFit: 'contain',
                        },
                      }}
                    >
                      {company.name.slice(0, 1).toUpperCase()}
                    </Avatar>
                    <Box ml={1.5}>
                      <CompanyName>{company.name}</CompanyName>
                      {article?.publishArticleToCompanyIds?.includes(company.id) && (
                        <Note>Already forwarded to this workspace</Note>
                      )}
                    </Box>
                  </Box>
                </CompanyContainer>
              </CompanyHoverContainer>
            </Fragment>
          ))}
        </HandleOperation>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={selectedCompanyIds.length === 0} onClick={onNext}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'StickyContainer' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const Description = styled(Typography, { label: 'Description' })({
  fontSize: 16,
  padding: '16px 24px',
});

const CompanyHoverContainer = styled(Box, { label: 'CompanyHoverContainer' })(({ theme }) => ({
  padding: '0 24px',
  '&:hover': {
    backgroundColor: theme.palette.custom.whiteSmoke,
    transition: '0.5s',
  },
}));

const CompanyContainer = styled(Box, { label: 'CompanyContainer' })({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 0',
  cursor: 'pointer',
});

const CompanyName = styled(Typography, { label: 'CompanyName' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.black,
  textTransform: 'capitalize',
}));

const Note = styled(Typography, { label: 'Note' })(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.custom.redWine,
}));

export default SelectWorkspacesToForwardModal;
