import { Avatar, Box, Grid, Skeleton } from '@mui/material';
import {
  ArticleContainer,
  ArticleContent,
  ArticleContentContainer,
  ArticleUserContainer,
  ArticleVariantType,
} from 'pages/knowledge/components/ArticleBox';

type ArticleSkeltonProps = {
  variant: ArticleVariantType;
};

function ArticleSkelton({ variant }: ArticleSkeltonProps) {
  return (
    <ArticleContainer className={variant}>
      <ArticleContentContainer className={variant}>
        <Box display="flex">
          <Skeleton animation="wave" variant="circular" width={48} height={48} sx={{ mt: 2 }}>
            <Avatar />
          </Skeleton>
          <ArticleContent className={variant}>
            <ArticleUserContainer>
              <Box>
                <Skeleton animation="wave" variant="text" width={250} height={20} />
                <Skeleton animation="wave" variant="text" width={180} height={20} />
              </Box>
            </ArticleUserContainer>
            <Box mt={2}>
              <Skeleton animation="wave" width="80%" variant="text" height={20} />
              <Skeleton animation="wave" width="100%" variant="text" height={15} sx={{ mt: 1 }} />
              <Skeleton animation="wave" width="90%" variant="text" height={15} />
              <Grid container spacing={0.5} sx={{ height: 380, borderRadius: 0.5, mt: 1 }}>
                <Grid item xs={6}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height="100%">
                    <Avatar />
                  </Skeleton>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height="100%">
                    <Avatar />
                  </Skeleton>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height="100%">
                    <Avatar />
                  </Skeleton>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height="100%">
                    <Avatar />
                  </Skeleton>
                </Grid>
              </Grid>
            </Box>
          </ArticleContent>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" pb={1}>
          <Box>
            <Skeleton animation="wave" width={100} height={50} />
          </Box>
          <Box display="flex">
            <Skeleton animation="wave" width={40} height={50} sx={{ mr: 1 }} />
            <Skeleton animation="wave" width={40} height={50} sx={{ mr: 1 }} />
            <Skeleton animation="wave" width={40} height={50} />
          </Box>
        </Box>
      </ArticleContentContainer>
    </ArticleContainer>
  );
}

export default ArticleSkelton;
