import { Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArticleType, AttachmentType } from '../hooks/useArticlesQuery';
import Image2FillIcon from 'remixicon-react/Image2FillIcon';
import RenderAttachment from './RenderAttachment';
import { ArticleVariantType } from './ArticleBox';
import RenderArticlePreviewAttachments from './RenderArticlePreviewAttachments';

type AttachmentsProps = {
  article: ArticleType;
  attachments: AttachmentType[];
  doNotAutoplayVideos?: boolean;
  variant: ArticleVariantType;
  setLightboxOpen?: (payload: { open: boolean; fileName: string }) => void;
  source?: string;
};

function Attachments({
  article,
  attachments,
  doNotAutoplayVideos = true,
  variant,
  setLightboxOpen,
  source,
}: AttachmentsProps) {
  const thumbnails = attachments.filter(
    (attachment) => variant === 'short' || !attachment?.imageHashValue,
  );
  const layout = attachments.length === 1 ? 'single' : 'multiple';

  if (thumbnails.length == 0) {
    return null;
  }

  if (source === 'preview') {
    return (
      <PreviewContainer>
        <Grid container columnSpacing={0.5}>
          {thumbnails.map((attachment, index) => (
            <Grid
              item
              xs={layout === 'single' ? 12 : 6}
              height={layout === 'single' ? '380px' : '190px'}
              width={'100%'}
              key={index}
            >
              <RenderArticlePreviewAttachments attachment={attachment} />
            </Grid>
          ))}
        </Grid>
      </PreviewContainer>
    );
  }

  if (thumbnails.length >= 4) {
    return (
      <Container>
        <Grid container columnSpacing={0.5} height="100%">
          <Grid item xs={6} height="50%" pb={0.5}>
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="multiple"
              article={article}
              variant={variant}
              attachment={thumbnails[0]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
          <Grid item xs={6} height="50%" pb={0.5}>
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="multiple"
              article={article}
              variant={variant}
              attachment={thumbnails[1]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
          <Grid item xs={6} height="50%">
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="multiple"
              article={article}
              variant={variant}
              attachment={thumbnails[2]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
          <Grid item xs={6} height="50%">
            <Box sx={{ position: 'relative', height: '100%', width: '100%', display: 'flex' }}>
              <RenderAttachment
                doNotAutoplayVideos={doNotAutoplayVideos}
                layout="multiple"
                article={article}
                variant={variant}
                attachment={thumbnails[3]}
                setLightboxOpen={setLightboxOpen}
              />
              {thumbnails.length > 4 && (
                <AttachmentsCountContainer
                  onClick={() =>
                    setLightboxOpen?.({ open: true, fileName: thumbnails[3]?.fileName || '' })
                  }
                >
                  <IconButton
                    sx={(theme) => ({
                      backgroundColor: theme.palette.custom.tuna,
                      color: theme.palette.custom.white,
                      '&:hover': {
                        backgroundColor: theme.palette.custom.tuna,
                      },
                    })}
                  >
                    <Image2FillIcon size={30} />
                  </IconButton>
                  <AttachmentsCount>+{thumbnails.length - 4}</AttachmentsCount>
                </AttachmentsCountContainer>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (thumbnails.length === 3) {
    return (
      <Container>
        <Grid container columnSpacing={0.5} height="100%">
          <Grid item xs={6} height="100%">
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="triple"
              article={article}
              variant={variant}
              attachment={thumbnails[0]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
          <Grid item xs={6} height="100%">
            <Grid container height="100%">
              <Grid item xs={12} height="50%" pb={0.5}>
                <RenderAttachment
                  doNotAutoplayVideos={doNotAutoplayVideos}
                  layout="triple"
                  article={article}
                  variant={variant}
                  attachment={thumbnails[1]}
                  setLightboxOpen={setLightboxOpen}
                />
              </Grid>
              <Grid item xs={12} height="50%">
                <RenderAttachment
                  doNotAutoplayVideos={doNotAutoplayVideos}
                  layout="triple"
                  article={article}
                  variant={variant}
                  attachment={thumbnails[2]}
                  setLightboxOpen={setLightboxOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (thumbnails.length === 2) {
    return (
      <Container>
        <Grid container columnSpacing={0.5} height="100%">
          <Grid item xs={6} height="100%">
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="double"
              article={article}
              variant={variant}
              attachment={thumbnails[0]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
          <Grid item xs={6} height="100%">
            <RenderAttachment
              doNotAutoplayVideos={doNotAutoplayVideos}
              layout="double"
              article={article}
              variant={variant}
              attachment={thumbnails[1]}
              setLightboxOpen={setLightboxOpen}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <RenderAttachment
        doNotAutoplayVideos={doNotAutoplayVideos}
        layout="single"
        article={article}
        variant={variant}
        attachment={thumbnails[0]}
        setLightboxOpen={setLightboxOpen}
      />
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })({
  height: 380,
  borderRadius: 4,
  overflow: 'hidden',
  margin: '8px auto',
});

const PreviewContainer = styled(Box, { label: 'Container' })({
  borderRadius: 4,
  margin: '8px auto',
});

const AttachmentsCountContainer = styled(Box, { label: 'AttachmentsCountContainer' })(
  ({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    opacity: 1,
    transition: '0.4s',
    color: theme.palette.custom.white,
    background: 'rgba(0, 0, 0, 0.35)',
  }),
);

const AttachmentsCount = styled(Typography, { label: 'AttachmentsCount' })({
  fontSize: 20,
  fontWeight: 700,
  marginTop: 8,
});

export default Attachments;
