import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { AttachmentType } from '../hooks/useArticlesQuery';
import { Box } from '@mui/material';
import { splitFilesByType } from 'utility/helpers';
import { ReactComponent as PlaceholderImage } from 'assets/icons/placeholder_image.svg';
import RenderPlaylistArticleThumbnail from './RenderPlaylistArticleThumbnail';
import Image2FillIcon from 'remixicon-react/Image2FillIcon';

type RenderAttachmentsProps = {
  attachments: AttachmentType[];
  coverPhotoFileName?: string;
};

function PlaylistArticleThumbnail({
  attachments,
  coverPhotoFileName = '',
}: RenderAttachmentsProps) {
  const { mediaFiles } = useMemo(
    () =>
      splitFilesByType({
        files: attachments,
        coverPhotoFileName,
      }),

    [attachments, coverPhotoFileName],
  );

  return (
    <PlaylistArticleThumbnailContainer>
      {mediaFiles.length > 0 ? (
        <RenderPlaylistArticleThumbnail attachment={mediaFiles[0]} />
      ) : (
        <PlaceholderImageContainer>
          <PlaceholderImage style={{ width: '70px', height: '70px' }} />
        </PlaceholderImageContainer>
      )}
      {mediaFiles.length > 1 && (
        <AttachmentsCount>
          <Image2FillIcon size={20} />
          {mediaFiles.length}
        </AttachmentsCount>
      )}
    </PlaylistArticleThumbnailContainer>
  );
}

const PlaylistArticleThumbnailContainer = styled(Box, {
  label: 'PlaylistArticleThumbnailContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.softPeach,
  cursor: 'pointer',
  display: 'flex',
  height: 80,
  width: 125,
  borderRadius: 4,
  overflow: 'hidden',
  position: 'relative',
  '&.audio': {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PlaceholderImageContainer = styled(Box, { label: 'PlaceholderImageContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const AttachmentsCount = styled(Box, { label: 'AttachmentsCount' })(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  fontSize: 16,
  fontWeight: 700,
  opacity: 0,
  transition: '0.4s',
  color: theme.palette.custom.white,
  background: 'rgba(0, 0, 0, 0.35)',
  '&:hover': {
    opacity: 1,
  },
}));

export default PlaylistArticleThumbnail;
