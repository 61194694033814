import { CommentType } from '@buildwise/pages/article/hooks/useArticleDetailsQuery';
import { Badge, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from 'components/common/avatar/Avatar';
import { USER_PROFILE, VIEW_ARTICLE } from 'constants/routes';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Chat3FillIcon from 'remixicon-react/Chat3FillIcon';
import { replaceMentions } from 'utility/helpers';

type RenderCommentActivityProps = {
  comment: CommentType;
};

function RenderCommentActivity({ comment }: RenderCommentActivityProps) {
  const commentText = useMemo(() => {
    if (!comment) return null;
    const _commentText = replaceMentions(comment.commentText, comment.mentions);
    return <CommentText dangerouslySetInnerHTML={{ __html: _commentText }} />;
  }, [comment]);

  return (
    <Container key={comment.id}>
      <Box mr={2}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ zIndex: 0 }}
          badgeContent={
            <BadgeContent>
              <Chat3FillIcon />
            </BadgeContent>
          }
        >
          <Avatar
            src={comment?.profileImageURL}
            size={44}
            name={comment?.commentAuthorDisplayName}
            to={USER_PROFILE.replace(':userName', encodeURIComponent(comment?.commentAuthor))}
          />
        </Badge>
      </Box>
      <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
        <CommentLink to={`${VIEW_ARTICLE.replace(':articleId', comment?.articleId)}#${comment.id}`}>
          {commentText}
        </CommentLink>
        <CommentInfo>
          <UserLink
            to={USER_PROFILE.replace(':userName', encodeURIComponent(comment?.commentAuthor))}
          >
            {comment.commentAuthorDisplayName}
          </UserLink>{' '}
          <Typography fontSize={14} lineHeight={1.2}>
            {comment.commentAuthorDisplayTitle}
          </Typography>
        </CommentInfo>
      </Box>
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  padding: 12,
  marginBottom: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.custom.alabaster,
  overflow: 'hidden',
  '&:last-child': {
    border: 'none',
  },
}));

const CommentText = styled(Typography, { label: 'CommentText' })(({ theme }) => ({
  fontSize: 16,
  fontStyle: 'italic',
  fontWeight: 400,
  marginBottom: 6,
  lineHeight: '17px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  color: theme.palette.custom.blueZodiac,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
  '& > span': {
    backgroundColor: '#E8F5FA',
  },
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const BadgeContent = styled(Box, { label: 'BadgeContent' })(({ theme }) => ({
  svg: {
    backgroundColor: theme.palette.custom.link,
    borderRadius: '50%',
    width: 20,
    height: 20,
    padding: 4,
    path: {
      color: theme.palette.custom.white,
    },
  },
}));

const CommentLink = styled(Link, { label: 'CommentLink' })(({ theme }) => ({
  width: '100%',
  fontWeight: 700,
  color: theme.palette.custom.activeTitle,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.custom.link,
  },
}));

const CommentInfo = styled(Typography, { label: 'CommentInfo' })(({ theme }) => ({
  color: theme.palette.custom.slate,
  fontSize: 14,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const UserLink = styled(Link, { label: 'UserLink' })(({ theme }) => ({
  color: theme.palette.custom.slate,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.2,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default RenderCommentActivity;
