import { JavaAPI, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';
import { UserType } from 'hooks/useUserData';

export interface LoginPayload {
  username: string;
  password: string;
}
export interface UpdateProfilePicturePayload {
  file: File;
  fileReferenceObject: {
    objectId: string;
    objectName: string;
    objectType: string;
  };
}

export interface PutUserPersonalizedProjectsPayload {
  projectIDs: string[];
  interested: boolean;
}

type SharePostPayload = {
  users: string[];
  projectIds: string[];
  message?: string;
};

export type SSOSignInQueryParams = {
  access_token: string;
  id_token: string;
};

class UserAPI extends JavaAPI {
  public getUser(username: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_USER_DETAILS}/${username}`);
  }

  public getUsers() {
    return this.get<UserType[]>(JAVA_SERVICE_ENDPOINTS.GET_USERS);
  }

  public getCompanyDetails(companyId: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_COMPANY_DETAILS}/${companyId}`);
  }

  public getUserArticles(username: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_USER_ARTICLES.replace(':userName', username));
  }

  public editUser(payload: any) {
    return this.put<UserType>(JAVA_SERVICE_ENDPOINTS.EDIT_USER_DETAILS, payload);
  }

  public editUserPublic(payload: any, params) {
    return this.put(JAVA_SERVICE_ENDPOINTS.EDIT_USER_DETAILS, payload, params);
  }

  public inviteUsers(payload: string[]) {
    return this.post(JAVA_SERVICE_ENDPOINTS.INVITE_USER, payload);
  }

  public inviteExternalUsers(payload: {
    invitations: { inviteeEmail: string; inviteeRole: string }[];
  }) {
    return this.post(JAVA_SERVICE_ENDPOINTS.INVITE_EXTERNAL_USER, payload);
  }

  public shareArticle(companyId: string, articleId: string, payload: SharePostPayload) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.SHARE_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      payload,
    );
  }

  public followUser(userId) {
    return this.put(JAVA_SERVICE_ENDPOINTS.FOLLOW_USER.replace(':userId', userId));
  }

  public unfollowUser(userId) {
    return this.put(JAVA_SERVICE_ENDPOINTS.UNFOLLOW_USER.replace(':userId', userId));
  }

  public login(payload: LoginPayload) {
    return this.post(JAVA_SERVICE_ENDPOINTS.LOGIN, payload);
  }

  public ssoSignIn(queryParams: SSOSignInQueryParams) {
    const query = new URLSearchParams({ ...queryParams });

    return this.post(`${JAVA_SERVICE_ENDPOINTS.SSO_SIGN_IN}?${query.toString()}`);
  }

  public verifyDomain(email: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.VERIFY_DOMAIN}?email=${encodeURIComponent(email)}`);
  }

  public signUp(payload) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SIGNUP, payload);
  }

  public forgotPassword(email) {
    return this.post(JAVA_SERVICE_ENDPOINTS.FORGOT_PASSWORD, { email });
  }

  public validateOTP(email, otp) {
    return this.post(JAVA_SERVICE_ENDPOINTS.VALIDATE_OTP, { email, otp });
  }

  public resetPassword(email, otpUid, password) {
    return this.post(JAVA_SERVICE_ENDPOINTS.RESET_PASSWORD, { email, otpUid, password });
  }

  public verifyCode(email, otp) {
    return this.post(JAVA_SERVICE_ENDPOINTS.VERIFY_CODE, { email, otp });
  }

  public endorseSkills(userName: string, skills: string[]) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ENDORSE_USER_SKILLS, { userName, skills });
  }

  public refreshToken(refreshToken: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.REFRESH_TOKEN, { refresh_token: refreshToken });
  }

  public getPeople(companyId: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_COMPANY_USERS.replace(':companyId', companyId)}`);
  }

  public searchPeople(companyId: string, keyword: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SEARCH, {
      companyId,
      novaSearchKeyword: keyword,
      novaSearchType: 'users',
    });
  }

  public updateProfilePicture(companyId: string, payload: UpdateProfilePicturePayload) {
    const formBody = new FormData();
    formBody.append('file', payload.file);
    formBody.append('fileReferenceObject', JSON.stringify(payload.fileReferenceObject));

    return this.post(
      JAVA_SERVICE_ENDPOINTS.UPDATE_PROFILE_PICTURE.replace(':companyId', companyId),
      formBody,
    );
  }

  public bulkEditUser(companyId: string, payload: { users: UserType[] }) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.BULK_UPDATE_USERS.replace(':companyId', companyId),
      payload,
    );
  }

  public starProject(companyId: string, payload: { projectIDs: string[]; isStarred: boolean }) {
    return this.put(JAVA_SERVICE_ENDPOINTS.STAR_PROJECT.replace(':companyId', companyId), payload);
  }
}

export default new UserAPI();
