import { styled } from '@mui/material/styles';
import Avatar from 'components/common/avatar/Avatar';
import AddPostModal from 'components/modals/addPostModal/AddPostModal';
import { USER_PROFILE } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import { useMemo, useState } from 'react';
import { Box, IconButton, Button as MuiButton } from '@mui/material';
import SelectMenu from 'components/common/menu/SelectMenu';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import QuestionLineIcon from 'remixicon-react/QuestionLineIcon';
import AddQuestionModal from 'components/modals/addQuestionModal/AddQuestionModal';
import RequestPostModal from './request-post/RequestPostModal';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { useAssignedFormsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';

type LongAddPostButtonProps = {
  disabled?: boolean;
  onAddArticleCallback?: (options: { article: ArticleType }) => void;
  formId?: string;
};

function LongAddPostButton({
  disabled = false,
  onAddArticleCallback,
  formId,
}: LongAddPostButtonProps) {
  const { user, company, companyId } = useUserData();
  const [openAddPostModal, setOpenAddPostModal] = useState(false);
  const [openRequestPostModal, setOpenRequestPostModal] = useState(false);
  const [openAskQuestionModal, setOpenAskQuestionModal] = useState(false);
  const menu = useMemo(
    () =>
      company?.settings?.askQuestionFlag
        ? [
            {
              title: 'Ask a Question',
              icon: <QuestionLineIcon size={17} />,
              onClick: () => setOpenAskQuestionModal(true),
            },
          ]
        : [],
    [company?.settings?.askQuestionFlag],
  );
  const { updateAssignedFormsCache } = useAssignedFormsQuery({
    companyId: companyId || '',
    assignedTo: user?.username || '',
    createdBy: user?.username || '',
    status: 'NEW-INPROGRESS',
    formCategory: FORMS_CONFIG_CATEGORY_TYPES.REQUEST_A_POST_CARD,
  });

  return (
    <>
      <ButtonsContainer>
        <Avatar
          src={user?.profileImageURL}
          size={38}
          name={user?.userNameToDisplay}
          to={USER_PROFILE.replace(':userName', user?.username || '')}
        />
        <Button disableRipple onClick={() => setOpenAddPostModal(true)} disabled={disabled}>
          Share knowledge that can help others...
        </Button>
        <MuiButton
          variant="contained"
          sx={{ ml: 1 }}
          onClick={() => {
            setOpenAddPostModal(true);
          }}
          disabled={disabled}
        >
          Add Post
        </MuiButton>
        <MuiButton
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={() => setOpenRequestPostModal(true)}
          disabled={disabled}
        >
          Request a Post
        </MuiButton>
        {menu.length > 0 && (
          <SelectMenu menu={menu}>
            <IconButton size="small" sx={{ ml: 1 }}>
              <MoreFillIcon />
            </IconButton>
          </SelectMenu>
        )}
      </ButtonsContainer>

      {openAddPostModal && (
        <AddPostModal
          open={openAddPostModal}
          setOpen={setOpenAddPostModal}
          selectedFeedFilters={[]}
          {...(onAddArticleCallback && { onAddArticleCallback })}
          {...(formId && { formIds: [formId] })}
        />
      )}

      {openAskQuestionModal && (
        <AddQuestionModal open={openAskQuestionModal} setOpen={setOpenAskQuestionModal} />
      )}

      {openRequestPostModal && (
        <RequestPostModal
          open={openRequestPostModal}
          setOpen={setOpenRequestPostModal}
          onRequestPostCallback={({ form }) => updateAssignedFormsCache({ form, action: 'add' })}
        />
      )}
    </>
  );
}

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.custom.white,
  padding: 16,
  borderRadius: 8,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
}));

const Button = styled(MuiButton, { label: 'Button' })(({ theme }) => ({
  borderRadius: 33,
  color: theme.palette.custom.mistBlue,
  fontSize: 14,
  flex: 1,
  justifyContent: 'flex-start',
  marginLeft: 12,
  backgroundColor: theme.palette.custom.whiteSmoke,
}));

export default LongAddPostButton;
