import { useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import Edit2LineIcon from 'remixicon-react/Edit2LineIcon';
import IconButton from '@mui/material/IconButton';
import DataTable from 'pages/admin/userAdministration/components/DataTable';
import { CompanyType, useCompaniesQuery } from 'hooks/useCompanyQueries';
import HandleOperation from 'components/utils/HandleOperation';
import AddCompanyModal from './AddCompanyModal';
import ModifyCompanyModal from './ModifyCompanyModal';

const $SettingsPaper = styled(Paper, { label: 'SettingsPaper' })(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 16,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
  marginBottom: 24,
  overflow: 'hidden',
  minHeight: '400px',
}));

const $Header = styled(Box, { label: 'Header' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  marginBottom: '16px',
}));

type BuildWiseCompanyViewType = {
  id: number;
  companyId: string;
  name: string;
  primaryDomain: string;
  otherDomains: string;
};

const BuildWiseCompanies = () => {
  const { companies, isLoading } = useCompaniesQuery({ isAssociatedWithOtherCompanies: false });
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  const [openModifyCompanyModal, setOpenModifyCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | undefined>();

  const handleEditClick = (companyData: BuildWiseCompanyViewType) => {
    setSelectedCompany(companies?.find((company) => company.id === companyData.companyId));
    setOpenModifyCompanyModal(true);
  };

  const companiesData = useMemo(() => {
    const data: BuildWiseCompanyViewType[] = [];
    let i = 0;
    companies?.forEach((company) => {
      const companyData = {
        id: ++i,
        companyId: company.id,
        name: company.name,
        primaryDomain: company.emailDomain || '—',
        otherDomains: company.otherEmailDomains?.join(',') || '—',
      };
      data.push(companyData);
    });

    return data;
  }, [companies]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'actions',
        headerName: '',
        renderCell: (params) => (
          <IconButton sx={{ margin: '0 auto' }} onClick={handleEditClick.bind(null, params.row)}>
            <Edit2LineIcon />
          </IconButton>
        ),
      },
      { field: 'id', headerName: 'ID', width: 30, hide: true, disableColumnMenu: true },
      {
        field: 'companyId',
        headerName: 'Company Id',
        width: 150,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 250,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'primaryDomain',
        headerName: 'Email Domain',
        width: 180,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'otherDomains',
        headerName: 'Other Email Domains',
        width: 200,
        sortable: true,
        disableColumnMenu: true,
      },
    ],
    [companiesData],
  );

  return (
    <$SettingsPaper>
      <Box sx={{ padding: 4 }}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <$Header>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Companies</Typography>
              <Button
                startIcon={<AddLineIcon />}
                variant="outlined"
                onClick={() => setOpenAddCompanyModal(true)}
              >
                Add a Company
              </Button>
            </$Header>
            <HandleOperation isLoading={isLoading}>
              <DataTable<BuildWiseCompanyViewType>
                columns={columns}
                rows={companiesData}
                pageSize={10}
                height={630}
                checkboxSelection={false}
              />
            </HandleOperation>
          </Grid>
        </Grid>
      </Box>

      {openAddCompanyModal && (
        <AddCompanyModal open={openAddCompanyModal} setOpen={setOpenAddCompanyModal} />
      )}

      {openModifyCompanyModal && selectedCompany && (
        <ModifyCompanyModal
          open={openModifyCompanyModal}
          setOpen={setOpenModifyCompanyModal}
          company={selectedCompany}
          onCloseCallback={() => setSelectedCompany(undefined)}
        />
      )}
    </$SettingsPaper>
  );
};

export default BuildWiseCompanies;
