import { styled, useTheme } from '@mui/material/styles';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Box,
  Chip as MuiChip,
} from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { POPULAR_POSTS_FILTERS } from '../constants';
import usePopularPostsQuery from '../queries/usePopularPostsQuery';
import Filter3LineIcon from 'remixicon-react/Filter3LineIcon';
import { handlePageScrollPosition } from 'utility/helpers';

type PopularPostsFiltersProps = {
  selectedFilter: string;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
};

function PopularPostsFilters({ selectedFilter, setSelectedFilter }: PopularPostsFiltersProps) {
  const theme = useTheme();
  const { isLoading } = usePopularPostsQuery({ duration: selectedFilter });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;
  const currentLabel = useMemo(() => {
    return POPULAR_POSTS_FILTERS.find((item) => item.value === selectedFilter)?.label;
  }, [selectedFilter]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    handlePageScrollPosition('popular-posts', 0, 0, 'reset');
    setSelectedFilter(event.target.value);
    handleClose();
  };

  return (
    <>
      <StickyContainer>
        <Chip
          label={
            <Box display="flex" alignItems="center">
              {isLoading ? (
                <CircularProgress
                  sx={(theme) => ({ color: theme.palette.custom.black, mr: 1 })}
                  size={18}
                />
              ) : (
                <Filter3LineIcon color={theme.palette.custom.mistBlue} style={{ marginRight: 8 }} />
              )}
              {currentLabel}
            </Box>
          }
          variant="outlined"
          size="small"
          aria-describedby={id}
          onClick={handleClick}
        />
      </StickyContainer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={() => ({
          '& .MuiPaper-root': {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0px 0px 24px 0px #0000001F',
          },
        })}
      >
        <FormControl sx={{ padding: 1 }}>
          <RadioGroup
            value={selectedFilter}
            onChange={handleFilterChange}
            defaultValue={selectedFilter}
            name="sortBy"
          >
            {POPULAR_POSTS_FILTERS.map((item) => (
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  '& .MuiFormControlLabel-label': {
                    fontSize: 15,
                  },
                }}
                key={item.label}
                value={item.value}
                control={<Radio size="small" />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Popover>
    </>
  );
}

const StickyContainer = styled(Box, { label: 'StickyContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 135,
  padding: '8px 0 16px',
  backgroundColor: theme.palette.custom.background,
  zIndex: 2,
  width: '100%',
}));

const Chip = styled(MuiChip, { label: 'Chip' })(({ theme }) => ({
  cursor: 'pointer',
  padding: '16px 8px',
  marginRight: 8,
  textTransform: 'capitalize',
  backgroundColor: theme.palette.custom.white,
}));

export default PopularPostsFilters;
