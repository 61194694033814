import { useQuery } from 'react-query';
import { homeQueryKeys } from './query-keys';
import { HomeAPI } from '..';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';

function useRecentActivityQuery() {
  const { companyId } = useUserData();
  const { data: recentActivityData, ...rest } = useQuery({
    queryKey: homeQueryKeys.recentActivity(companyId),
    queryFn: () => HomeAPI.getRecentActivity(companyId),
    staleTime: 60 * 1000,
    refetchOnMount: 'always',
    enabled: !!companyId,
  });

  const recentActivity = useMemo(() => recentActivityData?.data, [recentActivityData]);

  return {
    recentActivity,
    ...rest,
  };
}

export default useRecentActivityQuery;
