import { useForm } from 'react-hook-form';
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  attachFiles,
  catchErrorMessage,
  getExactFileName,
  getFileDetails,
  handlePageScrollPosition,
} from 'utility/helpers';
import KnowledgeAPI, { FeedFilter } from 'api/KnowledgeAPI';
import { toast } from 'react-toastify';
import { DRAFTS, HOME, VIEW_DRAFT } from 'constants/routes';
import useUserData, { useUserArticlesQuery } from 'hooks/useUserData';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArticleType,
  AttachmentType,
  useArticlesAndPlaylistsPaginationQuery,
} from 'pages/knowledge/hooks/useArticlesQuery';
import useArticleDetailsQuery, {
  updateArticleDetailsCache,
} from 'pages/article/hooks/useArticleDetailsQuery';
import {
  ProjectsPaginationResponseType,
  ProjectRecommendationsType,
} from 'pages/projects/hooks/useProjectsQuery';
import { updateDraftsCache } from 'pages/drafts/hooks/useDraftsQuery';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { AUDIO_TYPES, DOCUMENT_TYPES, IMAGE_TYPES, VIDEO_TYPES } from 'components/utils/constants';
import { updateProjectRecommendationsQueryCache } from 'pages/project/hooks/useProjectDetailsQuery';
import { useFormDetailsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import { updateStakeholderArticlesCache } from 'pages/stakeholder/hooks/useStakeholderDetailsQuery';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import useBookmarksQuery from 'pages/bookmarks/hooks/useBookmarksQuery';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import { ConfigEntryFieldType, ConfigEntryType } from 'config/config';
import { TemplateType, useTemplatesQuery } from '@buildwise/libs/company-admin';


const APPLICATION_BASED_ARTICLE_FIELDS: ConfigEntryType[] = [
  {
    entryCode: 'topics',
    entryDescription: 'Topics',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      configRefName: 'TOPICS',
      label: 'Topics',
      placeHolder: 'Select Topics',
    },
    targetMapperType: 'CONFIG',
  },
  {
    entryCode: 'projectId',
    entryDescription: 'Project',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.SELECT,
      label: 'Project',
      placeHolder: 'Select Project',
    },
    targetMapperType: 'API',
  },
  {
    entryCode: 'projectPhases',
    entryDescription: 'Project Phases',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      configRefName: 'PROJECT_PHASES',
      label: 'Project Phases',
      placeHolder: 'Select Project Phases',
    },
    targetMapperType: 'CONFIG',
  },
  {
    entryCode: 'partnerIds',
    entryDescription: 'Partners',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      label: 'Partners',
      placeHolder: 'Select Partners',
    },
    targetMapperType: 'API',
  },
  {
    entryCode: 'playListArticleIds',
    entryDescription: 'Playlists',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      label: 'Collections',
      placeHolder: 'Select Collections',
    },
    targetMapperType: 'API',
  },
  {
    entryCode: 'divisions',
    entryDescription: 'Divisions',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      label: 'Divisions',
      placeHolder: 'Select Divisions',
    },
    targetMapperType: 'API',
  },
  {
    entryCode: 'specSections',
    entryDescription: 'Spec Sections',
    entryType: 'APPLICATION',
    entryValues: [],
    isActive: true,
    otherAttributes: {},
    referenceObject: {
      type: ConfigEntryFieldType.MULTI_SELECT,
      label: 'Spec Sections',
      placeHolder: 'Select Spec Sections',
    },
    targetMapperType: 'API',
  },
];

export type PostFormData = {
  attachments: AttachmentType[];
  coverPhotoFileName: string;
  description: string;
  divisionChips: string[];
  divisions: string[];
  isDraft: boolean;
  labels: string[];
  partnerIds: string[];
  playListArticleIds: string[];
  playListArticles: any[];
  playlists: { label: string; value: string }[];
  projectId: string;
  projectPhases: string[];
  specSectionChips: string[];
  specSections: string[];
  title: string;
  topics: string[];
  [key: string]: any;
};

type UseAddPostFormOptions = {
  articleId?: string;
  createPlaylist?: boolean;
  data?: ArticleType;
  defaultPlaylists?: { label: string; value: string }[];
  defaultProjectId?: string;
  formIds?: string[];
  forward: boolean;
  selectedFeedFilters?: FeedFilter[];
  isCopyPost: boolean;
  isEditing?: boolean;
  project?: ProjectRecommendationsType;
  publishArticleToCompanyIds: string[];
  stakeholderId?: string;
  defaultExpanded: boolean;
  onAddArticleCallback?: (options: { article: ArticleType }) => void;
  onSaveCallback?: (article: ArticleType) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  updateArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  state?: any;
};

type ProjectsSearchResponseType = ProjectsPaginationResponseType;

const filterValidAttachments = (attachments: AttachmentType[]) => {
  return attachments.filter((attachment) => {
    let fileType = `${attachment.fileType?.toLowerCase().split('/')?.[1]}`;

    if (attachment.fileUploaded) {
      fileType = attachment.fileType?.toLowerCase();
    }

    return (
      DOCUMENT_TYPES.includes(`application/${fileType}`) ||
      DOCUMENT_TYPES.includes(`text/${fileType === 'txt' ? 'plain' : fileType}`) ||
      IMAGE_TYPES.includes(`image/${fileType}`) ||
      AUDIO_TYPES.includes(`audio/${fileType}`) ||
      VIDEO_TYPES.includes(`video/${fileType}`)
    );
  });
};

const orderAttachmentsByCoverPhoto = (
  attachments: AttachmentType[],
  coverPhotoFileName: string,
) => {
  const updatedAttachments = [...(filterValidAttachments(attachments) || [])];
  if (coverPhotoFileName) {
    const fileIndex = updatedAttachments.findIndex((attachment) => {
      const exactFileName = getExactFileName(attachment);
      return exactFileName === coverPhotoFileName;
    });

    if (fileIndex > -1) {
      const file = updatedAttachments.splice(fileIndex, 1)[0];
      updatedAttachments.unshift(file);
    }
  }

  return updatedAttachments;
};

const PostFormSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  labels: Yup.array(Yup.string()),
  description: Yup.string(),
  attachments: Yup.array(Yup.object()),
  coverPhotoFileName: Yup.string(),
  topics: Yup.array(Yup.string()),
  projectId: Yup.string().nullable(),
  projectPhases: Yup.array(Yup.string()),
  partnerIds: Yup.array(Yup.string()),
  playlists: Yup.array(Yup.object()),
  playListArticleIds: Yup.array(Yup.string()),
  playListArticles: Yup.array(Yup.object()),
  divisions: Yup.array(Yup.string()),
  divisionChips: Yup.array(Yup.string()),
  specSections: Yup.array(Yup.string()),
  specSectionChips: Yup.array(Yup.string()),
  isDraft: Yup.boolean(),
});

const useAddPostForm = ({
  articleId,
  createPlaylist = false,
  data: articleData,
  defaultPlaylists = [],
  defaultProjectId = '',
  formIds = [],
  forward,
  selectedFeedFilters,
  isCopyPost,
  isEditing = false,
  project,
  publishArticleToCompanyIds,
  stakeholderId,
  defaultExpanded,
  onAddArticleCallback,
  onSaveCallback,
  setOpen,
  updateArticleCallback,
}: UseAddPostFormOptions) => {
  const { companyId, isNovaAdmin, company, isBuildWiseInsightsUser } = useUserData();
  const { updateFeedCache, resetFeedQueries } = useArticlesAndPlaylistsPaginationQuery({
    shouldFetch: false,
    filters: selectedFeedFilters,
  });
  const { updateUserArticlesCache } = useUserArticlesQuery({ shouldFetch: false });
  const { updateBookmarksCache } = useBookmarksQuery({ shouldFetch: false });
  const { entries: articleFields } = useConfigQuery(companyId, 'ARTICLE_FIELDS');
  const uniqueArticleFields = useMemo(
    () =>
      _.uniqBy([...articleFields, ...APPLICATION_BASED_ARTICLE_FIELDS], 'entryCode').filter(
        (field) =>
          field.isActive &&
          (field.referenceObject?.type === 'SELECT' ||
            field.referenceObject?.type === 'MULTI_SELECT'),
      ),
    [articleFields],
  );
  const { applicationBasedFields, standardAndCustomFields, customFields } = useMemo(
    () => ({
      applicationBasedFields: uniqueArticleFields
        .filter((field) => field.entryType === 'APPLICATION')
        .map((field) => field.entryCode),
      standardAndCustomFields: uniqueArticleFields.filter(
        (field) =>
          field.entryType === 'STANDARD' ||
          field.entryType === 'Standard' ||
          field.entryType === 'CUSTOM',
      ),
      customFields: uniqueArticleFields.filter((field) => field.entryType === 'CUSTOM'),
    }),
    [uniqueArticleFields],
  );
  const { data: projectsData } = useSearchItemQuery<ProjectsSearchResponseType>(
    {
      searchType: 'projects',
      operator: 'or',
      filterBy: {
        filters: [
          {
            filterType: 'name',
            filterValue: 'ALL',
          },
        ],
      },
    },
    false,
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isSavingDraft, setSavingDraft] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { templates } = useTemplatesQuery(companyId);
  const [isExpanded, setExpanded] = useState(defaultExpanded);
  const [isFullScreen, setFullScreen] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const canPublishPost = useMemo(
    () => !((isNovaAdmin || isBuildWiseInsightsUser) && company?.settings?.externalFlag),
    [company?.settings.externalFlag, isNovaAdmin, isBuildWiseInsightsUser],
  );
  const hasAccessToBrowseTemplates = useMemo(
    () =>
      templates.length > 0 &&
      !createPlaylist &&
      (isNovaAdmin || company?.settings?.articleTemplatesFlag),
    [createPlaylist, company?.settings?.articleTemplatesFlag, isNovaAdmin, templates],
  );
  let data = articleData;
  const { articleResponse: fetchedArticle } = useArticleDetailsQuery({
    articleId: articleId || data?.id || '',
    updateViewCount: false,
  });

  data = (data || fetchedArticle?.companyArticle?.article) as ArticleType

  const postForm = useForm<PostFormData>({
    mode: 'onChange',
    resolver: yupResolver(PostFormSchema),
    defaultValues: getDefaultValues(data),
  });
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType | undefined>(undefined);
  const [openTemplates, setOpenTemplates] = useState(false);
  const { formState, getValues, handleSubmit, reset, setValue, watch } = postForm;
  const { isDirty, isValid } = formState;
  const attachments = watch('attachments');
  const coverPhotoFileName = watch('coverPhotoFileName');

  useEffect(() => {
    const { title } = getValues();
    if (title && isCopyPost) {
      setValue('title', `Copy of - ${title}`, { shouldDirty: true, shouldValidate: true });
    }
  }, [isCopyPost, getValues, setValue]);

  useEffect(() => {
    if (forward) {
      downloadArticleAttachments();
    }
  }, [forward]);

  useEffect(() => {
    if (fetchedArticle?.companyArticle?.article) {
      reset(getDefaultValues(fetchedArticle?.companyArticle?.article, getValues('attachments')), {
        keepDirty: true,
        keepIsValid: true,
        keepDirtyValues: true,
        keepDefaultValues: true,
      });
    }
  }, [fetchedArticle?.companyArticle?.article]);

  function getDefaultValues(article: ArticleType | undefined, _attachments?: AttachmentType[]) {
    const customFields: Record<string, string | string[]> = {};
    Object.keys(data?.customFields || []).forEach((key) => {
      if (
        data?.customFields &&
        (Array.isArray(data?.customFields?.[key].fieldValue)
          ? data?.customFields?.[key].fieldValue.length > 0
          : data?.customFields?.[key].fieldValue)
      )
        customFields[key] = data?.customFields?.[key].fieldValue;
    });

    return {
      attachments: forward
        ? _attachments || []
        : orderAttachmentsByCoverPhoto(
            article?.attachments || [],
            article?.coverPhotoFileName || '',
          ),
      coverPhotoFileName: article?.coverPhotoFileName || '',
      description: article?.description || '',
      divisionChips: [],
      divisions: article?.divisions || [],
      isDraft: !!article?.isDraft,
      labels: article?.labels || [],
      partnerIds: article?.partnerIds || [],
      playListArticleIds:
        defaultPlaylists?.map((item) => item.value) || article?.playListArticleIds || [],
      playListArticles: article?.playListArticles || [],
      playlists: defaultPlaylists || [],
      projectId: defaultProjectId || article?.projectId || '',
      projectPhases: article?.projectPhases || [],
      specSectionChips: [],
      specSections: article?.specSections || [],
      title: article?.title || '',
      topics: article?.topics || [],
      ...customFields,
    };
  }

  const downloadArticleAttachments = async () => {
    const downloadAttachmentsPromises: Promise<boolean>[] = [];
    const allFiles: File[] = [];

    if (data?.attachments) {
      setLoadingFiles(true);
      const attachmentsToClone = data?.attachments.filter(
        (attachment) => !attachment.imageHashValue,
      );

      for (const attachment of attachmentsToClone) {
        downloadAttachmentsPromises.push(
          new Promise(async (resolve, reject) => {
            try {
              const { data: fileBlob } = await axios(attachment.articleOpenUrl || '', {
                responseType: 'blob',
              });

              let fileType = '';

              if (IMAGE_TYPES.includes(attachment.fileType?.toLowerCase())) {
                fileType = `image/${attachment.fileType?.toLowerCase()}`;
              }

              if (VIDEO_TYPES.includes(attachment.fileType?.toLowerCase())) {
                fileType = `video/${attachment.fileType?.toLowerCase()}`;
              }

              if (AUDIO_TYPES.includes(attachment.fileType?.toLowerCase())) {
                fileType = `audio/${attachment.fileType?.toLowerCase()}`;
              }

              if (DOCUMENT_TYPES.includes(attachment.fileType?.toLowerCase())) {
                if (attachment.fileType?.toLowerCase() === 'txt') {
                  fileType = `text/${attachment.fileType?.toLowerCase()}`;
                } else {
                  fileType = `application/${attachment.fileType?.toLowerCase()}`;
                }
              }

              const newFile = new File([fileBlob], getExactFileName(attachment), {
                lastModified: new Date().getTime(),
                type: fileType,
              });
              allFiles.push(newFile);
              resolve(true);
            } catch (error) {
              reject(error);
            }
          }),
        );
      }

      try {
        await Promise.all(downloadAttachmentsPromises);
        attachFiles(allFiles, getValues('attachments'), (attachments: AttachmentType[]) => {
          setValue('attachments', attachments, { shouldDirty: true, shouldValidate: true });
        });
      } catch (error) {
        console.error('DOWNLOAD_ARTICLE_ATTACHMENTS_ERROR', error);
      } finally {
        setLoadingFiles(false);
      }
    }
  };

  const handleLabelKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      const { labels } = getValues();
      const tagValue = event.currentTarget.value.trim();
      const noDuplicate = !labels?.find((label) => label.toLowerCase() === tagValue?.toLowerCase());
      if (tagValue && noDuplicate) {
        setValue('labels', [...(labels || []), tagValue], {
          shouldDirty: true,
          shouldValidate: true,
        });
        event.currentTarget.value = '';
      }
    }
  };

  const removeLabel = (label: string) => {
    const { labels } = getValues();
    setValue(
      'labels',
      labels?.filter((labelVal) => labelVal.toLowerCase() !== label.toLowerCase()),
      { shouldDirty: true, shouldValidate: true },
    );
  };

  const onSubmit = async (values: PostFormData) => {
    try {
      const payload: any = {
        title: values.title.trim(),
        labels: values.labels,
        description: values.description,
        costImpact: null,
        timeImpact: null,
        isDraft: values.isDraft,
        status: values.isDraft ? data?.status : 'PUBLISHED',
        coverPhotoFileName: values?.coverPhotoFileName || '',
      };

      if (formIds.length > 0) {
        payload['formIds'] = formIds;
      }

      // format post description mentions
      const parser = new DOMParser();
      const doc = parser.parseFromString(payload?.description, 'text/html');
      const mentionElements = doc.querySelectorAll('.mention');
      const mentions = mentionElements?.length
        ? Array.from(mentionElements).map((el) => {
            const name = el?.textContent || '';
            return {
              userName: el?.getAttribute('data-mention-id'),
              displayName: name,
            };
          })
        : [];
      payload['mentions'] = mentions

      // format custom fields
      const customFieldsValues: Record<
        string,
        { fieldName: string; fieldValue: string | string[]; type: string; configRefName: string }
      > = {};
      customFields.forEach((field) => {
        if (
          Array.isArray(values[field.entryCode])
            ? values[field.entryCode].length > 0
            : values[field.entryCode]
        ) {
          customFieldsValues[field.entryCode] = {
            fieldName: field.entryCode,
            fieldValue: values[field.entryCode],
            type: field.referenceObject?.type || '',
            configRefName: field.referenceObject?.configRefName || '',
          };
        }
      });
      payload.customFields = customFieldsValues;

      if (values.topics) {
        payload.topics = values.topics;
      }
      payload.projectId = values.projectId || null;
      if (values.projectPhases) {
        payload.projectPhases = values.projectPhases;
      }
      if (values?.partnerIds) {
        payload.partnerIds = values?.partnerIds;
      }
      if (values.playlists || values.playListArticleIds) {
        payload.playListArticleIds = applicationBasedFields.includes('playListArticleIds')
          ? values.playListArticleIds
          : values.playlists.map((playlist) => playlist.value);
      }

      if (values.divisions || values.divisionChips) {
        payload.divisions = _.uniq([...(values.divisionChips || []), ...(values.divisions || [])]);
      }
      if (values.specSections || values.specSectionChips) {
        payload.specSections = _.uniq([
          ...(values.specSectionChips || []),
          ...(values.specSections || []),
        ]);
      }

      let coverPhoto: AttachmentType | undefined = undefined;
      let remainingAttachments: AttachmentType[] = [...values.attachments];

      if (!isEditing) {
        const coverPhotoIndex = remainingAttachments.findIndex((attachment) =>
          values.coverPhotoFileName
            ? attachment.fileName === values.coverPhotoFileName
            : getFileDetails({ file: attachment }).isMediaFile,
        );

        if (coverPhotoIndex > -1) {
          coverPhoto = remainingAttachments[coverPhotoIndex];
          remainingAttachments.splice(coverPhotoIndex, 1);
        }
      }

      let articleResponse;
      if (isEditing && data?.id) {
        payload.playListArticleIds =
          values?.playListArticleIds?.length > 0 ? values.playListArticleIds : [];

        const updatedPayload = {
          ...data,
          ...payload,
        };
        delete updatedPayload?.remainingAttachments;
        delete updatedPayload?.project;
        delete updatedPayload?.primaryCompanyName;
        articleResponse = await KnowledgeAPI.editArticle(companyId, data.id, updatedPayload);
      } else {
        delete payload.attachments;
        if (createPlaylist) {
          payload.originIndicator = 'PLAYLIST';
        }

        if (isCopyPost) {
          payload.attachments = remainingAttachments.filter(
            (file) => file.fileUploaded && !file.isRemoved && !file.isUpdated,
          );
        }

        if (coverPhoto) {
          payload.attachment = coverPhoto;
        }

        articleResponse = await KnowledgeAPI.addArticle(companyId, payload);
      }

      let removableFiles =
        remainingAttachments
          ?.filter((file) => file.fileUploaded && file.isRemoved)
          .map((file) => file.id) || [];
      let newFiles = remainingAttachments?.filter((file) => !file.fileUploaded) || [];
      let updatedFiles =
        remainingAttachments?.filter((file) => file.fileUploaded && file.isUpdated) || [];

      if (isCopyPost) {
        removableFiles = [];
        updatedFiles = [];
        newFiles =
          remainingAttachments?.filter((file) => !file.fileUploaded || file.isUpdated) || [];
      }

      if (newFiles.length || updatedFiles.length || removableFiles.length) {
        const updateArticleMediaPromises: Promise<AxiosResponse<any, Error> | any>[] = [];

        if (newFiles.length) {
          const formData = new FormData();

          newFiles.forEach((attachment) => {
            formData.append('file', attachment.file);
            if (attachment.thumbnail) {
              formData.append('thumbnail', attachment.thumbnail);
            }
          });

          updateArticleMediaPromises.push(
            KnowledgeAPI.addArticleMedia(companyId, articleResponse?.data?.id, formData),
          );
        }

        if (updatedFiles.length) {
          const formData = new FormData();

          updatedFiles.forEach((attachment) => {
            formData.append('file', attachment.file);
            if (attachment.thumbnail) {
              formData.append('thumbnail', attachment.thumbnail);
            }
          });

          updateArticleMediaPromises.push(
            KnowledgeAPI.updateArticleMedia(companyId, articleResponse?.data?.id, formData),
          );
        }

        if (removableFiles.length) {
          const removeAttachmentsPromises = removableFiles.map((id) =>
            KnowledgeAPI.removeAttachment(companyId, articleResponse?.data?.id, id),
          );

          updateArticleMediaPromises.push(Promise.all(removeAttachmentsPromises));
        }

        const [res1, res2] = await Promise.all(updateArticleMediaPromises);

        let updatedArticle: ArticleType | undefined = articleResponse?.data;

        if ((newFiles.length || updatedFiles.length) && res1) {
          updatedArticle = res1.data;
        }

        if (newFiles.length && updatedFiles.length && res2) {
          updatedArticle = res2.data;
        }

        if (!_.isEmpty(updatedArticle)) {
          if (removableFiles.length) {
            updatedArticle = {
              ...updatedArticle,
              attachments:
                updatedArticle?.attachments?.filter(
                  (attachment) => !removableFiles.includes(attachment.id),
                ) || [],
            };
          }

          if ((data || articleId) && !updatedArticle?.isDraft) {
            updateArticleCallback?.({
              oldArticle: (data || fetchedArticle?.companyArticle?.article) as ArticleType,
              updatedArticle: {
                ...updatedArticle,
                project:
                  (updatedArticle.projectId || data?.projectId) && projectsData?.projects
                    ? projectsData?.projects?.find(
                        (project) => project.id === updatedArticle?.projectId,
                      )
                    : undefined,
              },
            });
          }

          if (onSaveCallback) {
            onSaveCallback(updatedArticle);
          } else {
            if (updatedArticle && !updatedArticle.isDraft) {
              updateFeedCache({
                action: 'update',
                article: updatedArticle,
              });
              if (updatedArticle.isArticleBookMarked) {
                updateBookmarksCache({ action: 'update', article: updatedArticle });
              }
              if (project?.id) {
                updateProjectRecommendationsQueryCache({
                  companyId,
                  projectId: project?.id,
                  articles: [updatedArticle],
                  action: 'update-article',
                });
              }
              // TODO: update cache of GET /playlists API
            }

            if (updatedArticle && updatedArticle.isDraft) {
              updateDraftsCache(companyId, updatedArticle, 'update');
            }

            if (isEditing && updatedArticle) {
              updateArticleDetailsCache(companyId, {
                ...updatedArticle,
                project:
                  (updatedArticle.projectId || data?.projectId) && projectsData?.projects
                    ? projectsData?.projects?.find(
                        (project) => project.id === updatedArticle?.projectId,
                      )
                    : undefined,
              });
            }
          }
        }
      } else {
        if ((data || articleId) && !articleResponse?.data?.isDraft) {
          updateArticleCallback?.({
            oldArticle: (data || fetchedArticle?.companyArticle?.article) as ArticleType,
            updatedArticle: {
              ...articleResponse?.data,
              project:
                (articleResponse?.data.projectId || data?.projectId) && projectsData?.projects
                  ? projectsData?.projects?.find(
                      (project) => project.id === articleResponse?.data?.projectId,
                    )
                  : undefined,
            },
          });
        }

        onAddArticleCallback?.({
          article: articleResponse?.data,
        });
        if (onSaveCallback && !articleResponse?.data?.isDraft) {
          onSaveCallback({
            ...articleResponse?.data,
            playListArticleIds: payload?.playListArticleIds || [],
          });
        } else {
          if (!articleResponse?.data?.isDraft) {
            updateFeedCache({
              action: 'update',
              article: articleResponse.data,
            });
            updateUserArticlesCache({
              action: 'update',
              article: articleResponse?.data,
              _username: articleResponse?.data?.createdBy,
            });
            if (articleResponse?.data?.isArticleBookMarked) {
              updateBookmarksCache({ action: 'update', article: articleResponse?.data });
            }
            if (project?.id) {
              updateProjectRecommendationsQueryCache({
                companyId,
                projectId: project?.id,
                articles: [articleResponse.data],
                action: 'update-article',
              });
            }
          }

          if (isEditing) {
            updateArticleDetailsCache(companyId, {
              ...articleResponse.data,
              attachments: data?.attachments?.filter((file) => !removableFiles?.includes(file.id)),
              project:
                (articleResponse.data.projectId || data?.projectId) && projectsData?.projects
                  ? projectsData?.projects?.find(
                      (project) => project.id === articleResponse?.data.projectId,
                    )
                  : undefined,
            });
          }
        }
      }

      if (payload.originIndicator === 'PLAYLIST' || data?.originIndicator === 'PLAYLIST') {
        toast.success(
          values.isDraft
            ? 'Collection saved to drafts'
            : isEditing && !data?.isDraft
              ? 'Collection has been updated'
              : 'Collection created',
        );
      } else {
        toast.success(
          values.isDraft
            ? 'Draft saved successfully'
            : isEditing && !data?.isDraft
              ? 'Post updated successfully'
              : 'Post Published',
        );

        if (!isEditing && !articleResponse?.data?.isDraft) {
          handlePageScrollPosition('posts', 0, 0, 'reset');
          handlePageScrollPosition('collections', 0, 0, 'reset');
          resetFeedQueries();
        }
      }
      if (data?.isDraft && !articleResponse.data.isDraft && !isCopyPost) {
        updateDraftsCache(companyId, articleResponse.data, 'delete');
        navigate(HOME);
      }

      if (
        stakeholderId &&
        values.partnerIds &&
        data?.partnerIds?.length !== values.partnerIds.length
      ) {
        updateStakeholderArticlesCache('delete', stakeholderId, articleResponse.data);
      }

      closeModal();

      if (values.isDraft) {
        if (!(newFiles.length || updatedFiles.length || removableFiles.length)) {
          updateDraftsCache(companyId, articleResponse.data, 'update');
        }

        if (pathname !== VIEW_DRAFT && !onSaveCallback) {
          navigate(DRAFTS);
        }
      }
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setSavingDraft(false);
    }
  };

  const triggerSaveDraft = handleSubmit((values) => onSubmit({ ...values, isDraft: true }));
  const onSaveDraft = () => {
    setSavingDraft(true);
    triggerSaveDraft();
  };

  const triggerSubmit = handleSubmit((values) => onSubmit({ ...values, isDraft: false }));

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = [...((event?.target?.files || []) as File[])];
      attachFiles(files, attachments, (attachments: AttachmentType[]) => {
        if (
          !coverPhotoFileName &&
          attachments.length > 0 &&
          getFileDetails({ file: attachments[0] }).isMediaFile
        ) {
          setValue('coverPhotoFileName', attachments[0]?.fileName, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }

        setValue('attachments', attachments, { shouldDirty: true, shouldValidate: true });
      });
    },
    [attachments, coverPhotoFileName, setValue],
  );

  const handleTemplateSelection = (template: TemplateType) => {
    if (template.id !== selectedTemplate?.id) {
      setSelectedTemplate(template);
      setValue('title', template.title, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('labels', template.labels, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('description', template.description, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }

    setOpenTemplates(false);
  };

  const closeModal = () => {
    setSelectedTemplate(undefined);
    setOpen(false);
  };

  const handleClose = () => {
    if (isDirty && isValid && !forward) {
      setOpenConfirmation(true);
    } else {
      closeModal();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoadingFiles(true);
      attachFiles(acceptedFiles, attachments, (attachments: AttachmentType[]) => {
        if (
          !coverPhotoFileName &&
          attachments.length > 0 &&
          getFileDetails({ file: attachments[0] }).isMediaFile
        ) {
          setValue('coverPhotoFileName', attachments[0]?.fileName, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }

        setValue('attachments', attachments, { shouldDirty: true, shouldValidate: true });
        setLoadingFiles(false);
      });
    },
    [attachments, coverPhotoFileName, setValue],
  );

  const onForward = useCallback(
    async (values: PostFormData) => {
      try {
        const payload: Record<string, string | number | string[]> = {
          title: values.title.trim(),
          labels: values.labels,
          description: values.description,
          publishArticleToCompanyIds,
          forwardedFromArticleId: data?.id || '',
        };

        if (values.divisions || values.divisionChips) {
          payload.divisions = _.uniq([
            ...(values.divisionChips || []),
            ...(values.divisions || []),
          ]);
        }
        if (values.specSections || values.specSectionChips) {
          payload.specSections = _.uniq([
            ...(values.specSectionChips || []),
            ...(values.specSections || []),
          ]);
        }

        const formData = new FormData();
        formData.append('article', JSON.stringify(payload));

        values.attachments.forEach((attachment) => {
          formData.append('file', attachment.file);
          if (attachment.thumbnail) {
            formData.append('thumbnail', attachment.thumbnail);
          }
        });

        await KnowledgeAPI.forwardArticle(companyId, formData);
        toast.success('Post forwarded successfully!');
        closeModal();
      } catch (error) {
        toast.error(catchErrorMessage(error));
      }
    },
    [data, publishArticleToCompanyIds, companyId],
  );

  const triggerOnForward = handleSubmit((values) => onForward({ ...values, isDraft: false }));

  return {
    applicationBasedFields,
    canPublishPost,
    companyId,
    fetchedArticle,
    hasAccessToBrowseTemplates,
    isExpanded,
    isFullScreen,
    isSavingDraft,
    loadingFiles,
    openConfirmation,
    openTemplates,
    postForm,
    selectedTemplate,
    showModal,
    standardAndCustomFields,
    templates,
    closeModal,
    handleClose,
    handleLabelKeyDown,
    handleTemplateSelection,
    onDrop,
    onFileInputChange,
    onSaveDraft,
    removeLabel,
    setExpanded,
    setFullScreen,
    setOpenConfirmation,
    setOpenTemplates,
    setShowModal,
    triggerOnForward,
    triggerSubmit,
    isNovaAdmin
  };
};

export default useAddPostForm;
