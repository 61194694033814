import { ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import { BusinessObjectStatus } from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';
import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

class ProjectsAPI extends JavaAPI {
  public addProject(companyId: string, payload: Partial<ProjectType>) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_PROJECT.replace(':companyId', companyId), payload);
  }

  public editProject(companyId: string, projectId: string, payload: Partial<ProjectType>) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.EDIT_PROJECT.replace(':companyId', companyId).replace(
        ':projectId',
        projectId,
      ),
      payload,
    );
  }

  public getProjects(companyId: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_PROJECTS.replace(':companyId', companyId)}`);
  }

  public getProjectsPagination(companyId: string, pageNo: number, pageSize: number) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_PROJECTS_PAGINATION.replace(':companyId', companyId)
        .replace(':pageNo', pageNo.toString())
        .replace(':pageSize', pageSize.toString())}`,
    );
  }

  public getStarredProjects(companyId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_STARRED_PROJECTS.replace(':companyId', companyId));
  }

  public getProjectsInsights(companyId: string) {
    return this.get(`${JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS.replace(':companyId', companyId)}`);
  }

  public getFilteredInsights(companyId: string, objectType: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_FILTERED_INSIGHTS.replace(':companyId', companyId).replace(
        ':objectType',
        objectType,
      )}`,
    );
  }

  public getInsightsTypeDrafts(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS_TYPE_DRAFTS.replace(':companyId', companyId),
    );
  }

  public getProject(companyId: string, projectId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_PROJECT.replace(':companyId', companyId).replace(':projectId', projectId)}`,
    );
  }

  public getProjectRecommendations(companyId: string, projectId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_PROJECT_RECOMMENDATIONS.replace(
        ':companyId',
        companyId,
      ).replace(':projectId', projectId)}`,
    );
  }

  public getProjectScorecards(companyId: string, projectId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_SCORECARDS.replace(':companyId', companyId).replace(
        ':projectId',
        projectId,
      )}`,
    );
  }

  public getProjectForms(companyId: string, projectId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_PROJECT_FORMS.replace(':companyId', companyId).replace(
        ':projectId',
        projectId,
      )}`,
    );
  }

  public searchProjects(companyId: string, keyword: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SEARCH, {
      companyId,
      novaSearchKeyword: keyword,
      novaSearchType: 'projects',
    });
  }

  public bulkEditProject(companyId: string, payload: { projects: ProjectType[] }) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.BULK_UPDATE_PROJECTS.replace(':companyId', companyId),
      payload,
    );
  }

  public updateInsight(
    companyId: string,
    payload: {
      businessObjects: {
        objectId: number | string;
        objectType: string;
        referenceArticleId?: string;
        reviewStatus: {
          status: BusinessObjectStatus | string;
          notes?: string;
        };
      }[];
    },
  ) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.UPDATE_INSIGHT.replace(':companyId', companyId),
      payload,
    );
  }
}

export default new ProjectsAPI();
