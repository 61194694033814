import ArticleBox from '@buildwise/pages/knowledge/components/ArticleBox';
import ArticleSkelton from '@buildwise/pages/project/components/ArticleSkelton';
import HandleOperation from 'components/utils/HandleOperation';
import { ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import useRecommendedPostsQuery from '../queries/useRecommendedPostsQuery';
import useUserData from 'hooks/useUserData';
import { useLocation } from 'react-router-dom';
import PaginationLoader from 'components/utils/PaginationLoader';
import { useCallback, useEffect, useRef } from 'react';
import NoPosts from './NoPosts';
import { Grid } from '@mui/material';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { getPageScrollInfo, handlePageScrollPosition } from 'utility/helpers';

function RecommendedPosts() {
  const { user } = useUserData();
  const { state } = useLocation();
  const {
    recommendedPosts = [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isIdle,
    fetchNextPage,
    updateCache,
  } = useRecommendedPostsQuery({
    username: state?.user?.username || user?.username,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  useEffect(() => {
    const scrollToIndex = getPageScrollInfo<ArticleType>(
      'recommended-posts',
      recommendedPosts,
      'id',
    );

    if (containerRef.current) {
      containerRef.current.style.visibility = 'hidden';
    }

    if (virtuosoRef.current && scrollToIndex) {
      setTimeout(() => {
        virtuosoRef.current?.scrollToIndex({
          index: scrollToIndex,
          offset: -140,
          align: scrollToIndex > 2 ? 'start' : 'end',
        });

        if (containerRef.current) {
          containerRef.current.style.visibility = 'visible';
        }
      }, 300);
    } else {
      window.scrollTo({
        top: 0,
      });

      if (containerRef.current) {
        containerRef.current.style.visibility = 'visible';
      }
    }
  }, [containerRef, virtuosoRef]);

  const fetchPosts = useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} ref={containerRef} visibility="hidden">
        <HandleOperation
          isLoading={isLoading || (isFetching && !isFetchingNextPage) || isIdle}
          data={recommendedPosts}
          skelton={<ArticleSkelton variant="short" />}
          skeltonCount={1}
          noDataComponent={<NoPosts message="No Recommended Posts" />}
        >
          <Virtuoso
            rangeChanged={(range: ListRange) => {
              if (virtuosoRef.current && containerRef.current?.style.visibility !== 'hidden') {
                handlePageScrollPosition(
                  'recommended-posts',
                  0,
                  Math.round((range.startIndex + range.endIndex) / 2),
                );
              }
            }}
            ref={virtuosoRef}
            useWindowScroll
            data={recommendedPosts}
            endReached={fetchPosts}
            itemContent={(index, item) => (
              <ArticleBox
                key={item.id}
                article={item}
                articleIndex={index}
                variant="short"
                doNotAutoplayVideos
                scrollInfoKey="recommended-posts"
                bookmarkUpdateCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                deleteArticleCallback={({ article }) =>
                  updateCache({ action: 'delete', updatedArticle: article })
                }
                endorseArticleCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                likeUpdateCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                updateArticleCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
              />
            )}
            components={{
              Footer: () => <PaginationLoader isLoading={isFetchingNextPage} />,
            }}
          />
        </HandleOperation>
      </Grid>
    </Grid>
  );
}

export default RecommendedPosts;
