import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { UserType } from 'hooks/useUserData';
import { useState } from 'react';
import ViewUsersModal from './ViewUsersModal';

type FormatUsersActivityProps = {
  users: UserType[];
};

function FormatUsersActivity({ users }: FormatUsersActivityProps) {
  const [openUsersModal, setOpenUsersModal] = useState(false);

  return (
    <>
      <Box component="span">
        <TotalUsers as="strong" onClick={() => setOpenUsersModal(true)}>
          {users.length} {users.length > 1 ? 'colleagues' : 'colleague'}
        </TotalUsers>{' '}
        joined BuildWise
      </Box>

      {openUsersModal && (
        <ViewUsersModal open={openUsersModal} setOpen={setOpenUsersModal} users={users} />
      )}
    </>
  );
}

const TotalUsers = styled(Typography, { label: 'TotalUsers' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.tuna,
  fontSize: 14,
  fontWeight: 700,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FormatUsersActivity;
