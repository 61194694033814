import { UsersSearchResponseType } from '@buildwise/libs/home/components/request-post/RequestPostModal';
import { Editor as TinyEditor, IAllProps } from '@tinymce/tinymce-react';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import { FC, useMemo } from 'react';

export const initConfig = {
  height: '100%',
  menubar: false,
  branding: false,
  statusbar: true,
  resize: true,
  paste_data_images: true,
  paste_webkit_styles: 'all',
  browser_spellcheck: true,
  elementpath: false,
  contextmenu: 'false',
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'charmap',
    'visualblocks',
    'link',
    'powerpaste',
    'mentions',
  ],
  toolbar:
    'fontsize | ' +
    'bold italic underline strikethrough link | forecolor backcolor | alignment | indent bullist numlist | ' +
    'removeformat',
  setup: function (editor) {
    editor.ui.registry.addGroupToolbarButton('alignment', {
      icon: 'align-justify',
      tooltip: 'Alignment',
      items: 'alignleft aligncenter alignright alignjustify',
    });
    editor.on('PastePostProcess', function (e) {
      let content = e?.node?.innerHTML;

      let tempContainer = document.createElement('div');
      tempContainer.innerHTML = content;

      // removing the img's which have unsupported type
      const unsupportedImages = tempContainer?.querySelectorAll(
        'img[alt="Unsupported image type."]',
      );
      unsupportedImages?.forEach((img) => {
        const parentElement = img.parentNode as Element;

        img.remove();

        // If the parent element has no other children, remove it as well
        if (parentElement && parentElement?.childNodes?.length === 0) {
          parentElement?.remove();
        }
      });

      // Get all elements containing img tags
      let elementsWithImg = tempContainer?.querySelectorAll('*:has(img)');

      elementsWithImg?.forEach((element) => {
        let div = document.createElement('div');
        div.style.display = 'flex';
        div.style.alignItems = 'flex-end';

        // Move all child nodes of element to the new <div>
        while (element?.firstChild) {
          div.appendChild(element.firstChild);
        }

        if (element?.parentNode) {
          element.parentNode.replaceChild(div, element);
        }
      });

      e.node.innerHTML = tempContainer?.innerHTML;

      // Suppressing the error notification
      const notifications = editor.notificationManager.getNotifications();
      notifications?.forEach((notification) => {
        if (notification?.settings?.text?.includes('Some images failed to import')) {
          notification.close();
        }
      });
    });
  },
  content_style: `
    @import url('https://use.typekit.net/tan5xag.css');

    body {
      font-family: Lato, sans-serif, Helvetica, Arial;
      font-size: 16px;
    }

    p {
      margin: 0;
    }

    a {
      color: #1976D2;
    } 
    
    a:hover {
      text-decoration: underline;
    }
  `,
  mentions_min_chars: 0,
  mentions_selector: 'span.mention',
  mentions_item_type: 'profile',
  link_default_target: '_blank',
  powerpaste_word_import: 'merge',
  powerpaste_googledocs_import: 'merge',
  powerpaste_html_import: 'merge',
  mentions_menu_complete: (editor, userInfo) => {
    const span = editor.getDoc().createElement('span');
    span.className = 'mention';
    span.setAttribute('data-mention-id', userInfo.id);
    span.setAttribute('style', 'background-color: #E8F5FA;');
    span.appendChild(editor.getDoc().createTextNode(userInfo.name));
    return span;
  },
};

const Editor: FC<IAllProps> = ({ init, ...props }) => {
  const { data: people, isLoading } = useSearchItemQuery<UsersSearchResponseType>(
    {
      searchType: 'users',
      operator: 'or',
      filterBy: {
        filters: [
          {
            filterType: 'name',
            filterValue: 'ALL',
          },
        ],
      },
    },
    true,
  );

  const userData = useMemo(() => {
    return (
      people?.userResponse.map((user, index) => ({
        ...user,
        name: user.userNameToDisplay,
        id: user.username,
        description: user.title,
        image: user?.profileImageURL || 'https://images.buildwise.ai/user_profile_avatar.png',
      })) || []
    );
  }, [people]);

  const mentions_fetch = async (query, success) => {
    const filteredUsers = [...userData].filter((user) => {
      return [user.name, user.username].some((field) =>
        field.toLowerCase().includes(query.term.toLowerCase()),
      );
    });

    // only the top 10 mentions will be shown by default
    success(filteredUsers);
  };

  return !isLoading ? (
    <TinyEditor
      apiKey="gq1vzxo8fcb4symry1w5rjy7hlczwgj4k8r4z5xrwnpqp1uf"
      init={{
        ...initConfig,
        ...init,
        mentions_fetch: mentions_fetch,
      }}
      {...props}
    />
  ) : null;
};

export default Editor;
