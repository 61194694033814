import React, { ChangeEvent, useCallback, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { $FormPaper } from 'components/common/stylings';
import Thumbnails from 'components/common/dropzone/Thumbnails';
import { Controller } from 'react-hook-form';
import Upload from 'components/common/upload/Upload';
import UploadCloud2LineIcon from 'remixicon-react/UploadCloud2LineIcon';
import Attachment2Icon from 'remixicon-react/Attachment2Icon';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import {} from 'pages/article/components/CommentsBox';
import { attachFiles } from 'utility/helpers';

const $FeebackDetailsWrapper = styled(Box, { label: 'FeebackDetailsWrapper' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& .MuiTypography-h5': {
    fontWeight: 700,
    letterSpacing: '0.25px',
    lineHeight: '28px',
    width: '100%',
    color: '#000000',
    paddingBottom: 14,
  },
}));

type FeedbackFormProps = {
  control: any;
  register: any;
  setValue: any;
  watch: any;
  getValues: any;
  removeFile: any;
};

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  control,
  setValue,
  getValues,
  removeFile,
}) => {
  const [attachments, setAttachments] = useState<AttachmentType[]>(getValues()?.attachments || []);

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = [...((event?.target?.files || []) as File[])];
      attachFiles(files, attachments, setAttachments, true);
      setValue('attachments', files);
    },
    [attachments],
  );

  const onRemoveFile = async (file: any, index: number) => {
    if (file.fileUploaded) {
      removeFile(file?.fileName);
    }
    attachments.splice(index, 1);
    setAttachments(attachments.map((file) => Object.assign({}, file)));
    if (!file.fileUploaded) {
      const newFiles = attachments.filter((file) => !file.fileUploaded);
      setValue(
        'attachments',
        newFiles.map((newFile) => newFile.file),
      );
    }
  };

  return (
    <$FeebackDetailsWrapper>
      <$FormPaper>
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              multiline
              minRows={10}
              maxRows={10}
              {...field}
              autoFocus
              fullWidth
              label="Feedback"
              placeholder="Provide your feedback here..."
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          )}
          name="feedback"
        />
        <Box sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex' }}>
            <Upload name="attachments" onChange={onFileInputChange}>
              <Button variant="outlined">
                <UploadCloud2LineIcon />
                &nbsp;&nbsp;Add File
              </Button>
            </Upload>
            {attachments.length > 0 && (
              <Typography
                onClick={() => {
                  document.getElementById('feedback-attachment')?.scrollIntoView();
                }}
                sx={{ marginTop: '10px', paddingLeft: '12px', display: 'flex', cursor: 'pointer' }}
              >
                <Attachment2Icon /> 1 Attachment(s)
              </Typography>
            )}
          </Box>
          <Thumbnails
            files={attachments}
            removeFile={onRemoveFile}
            smallView
            disableEdit
            showCoverPhotoIndicator={false}
          />
        </Box>
      </$FormPaper>
    </$FeebackDetailsWrapper>
  );
};

export default FeedbackForm;
