import { create, Mutate, StoreApi, UseBoundStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { AxiosResponse } from 'axios';
import useLayoutStore from './useLayoutStore';
import { queryClient } from '../config/queryClient';
import apiClient from '../libs/services/config/apiClient';
import { JAVA_SERVICE_ENDPOINTS } from '../libs/services/endpoints';

interface AuthStore {
  accessToken: string;
  refreshToken: string;
  rememberMe: boolean;
  user: any;
  setUser: (user: any) => void;
  setAccessToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  setRememberMe: (rememberMe: boolean) => void;
  resetState: () => void;
  getRefreshToken: () => Promise<AxiosResponse>;
}

type UseAuthStore = UseBoundStore<Mutate<StoreApi<AuthStore>, [['zustand/persist', AuthStore]]>>;

const useAuthStore: UseAuthStore = create(
  persist(
    (set, get) => ({
      accessToken: '',
      refreshToken: '',
      rememberMe: false,
      user: null,
      setUser: (user: any) => set({ user }),
      setAccessToken: (accessToken: string) => set({ accessToken }),
      setRefreshToken: (refreshToken: string) => set({ refreshToken }),
      setRememberMe: (rememberMe: boolean) => set({ rememberMe }),
      resetState: () => {
        queryClient.removeQueries();
        useLayoutStore.getState().resetState();
        set({ accessToken: '', refreshToken: '', rememberMe: false, user: null });
      },
      getRefreshToken: () => {
        const token = get().refreshToken;
        if (!token) throw new Error('Unauthorized');

        return apiClient().post(JAVA_SERVICE_ENDPOINTS.REFRESH_TOKEN, {
          refreshToken: token,
        });
      },
    }),
    {
      name: 'bwAuthStorage',
    },
  ),
);

export default useAuthStore;
