import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import DraftLineIcon from 'remixicon-react/DraftLineIcon';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import { BusinessObjectStatus } from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';

type RenderFilteredInsightStatusProps = {
  status: BusinessObjectStatus | string;
  showDefaultValue?: boolean;
};

function RenderFilteredInsightStatus({
  status,
  showDefaultValue = false,
}: RenderFilteredInsightStatusProps) {
  const theme = useTheme();

  if (status === BusinessObjectStatus.AI_DRAFT_CREATED) {
    return (
      <InsightStatusChip className="ai-draft-created">
        <DraftLineIcon
          size={20}
          color={theme.palette.custom.tealGreen}
          style={{ marginRight: 8 }}
        />
        <InsightStatusText>AI Draft Created</InsightStatusText>
      </InsightStatusChip>
    );
  }

  if (status === BusinessObjectStatus.APPROVED) {
    return (
      <InsightStatusChip className="approved">
        <CheckLineIcon size={20} color={theme.palette.custom.tuna} style={{ marginRight: 8 }} />
        <InsightStatusText>Approved</InsightStatusText>
      </InsightStatusChip>
    );
  }

  if (status === BusinessObjectStatus.PENDING_APPROVAL) {
    return (
      <InsightStatusChip className="pending-approval">
        <TimeLineIcon size={20} color={theme.palette.custom.tuna} style={{ marginRight: 8 }} />
        <InsightStatusText>Pending Approval</InsightStatusText>
      </InsightStatusChip>
    );
  }

  if (status === BusinessObjectStatus.REJECTED) {
    return (
      <InsightStatusChip className="rejected">
        <CloseCircleLineIcon
          size={20}
          color={theme.palette.custom.redWine}
          style={{ marginRight: 8 }}
        />
        <InsightStatusText>Rejected</InsightStatusText>
      </InsightStatusChip>
    );
  }

  if (showDefaultValue) {
    return (
      <InsightStatusChip className="new">
        <InsightStatusText>New</InsightStatusText>
      </InsightStatusChip>
    );
  }

  return null;
}

export const InsightStatusChip = styled(Box, { label: 'InsightStatusChip' })(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  padding: '4px 16px',
  borderRadius: 20,
  '&.new': {
    backgroundColor: theme.palette.custom.earlyDawn,
  },
  '&.ai-draft-created': {
    backgroundColor: theme.palette.custom.offGreen,
  },
  '&.approved': {
    backgroundColor: theme.palette.custom.iceberg,
  },
  '&.pending-approval': {
    backgroundColor: theme.palette.custom.softPeach,
  },
  '&.rejected': {
    backgroundColor: theme.palette.custom.dawnPink,
  },
}));

export const InsightStatusText = styled(Typography, { label: 'InsightStatusText' })(
  ({ theme }) => ({
    color: theme.palette.custom.dark,
    fontSize: 14,
  }),
);

export default RenderFilteredInsightStatus;
