import ActionModal from 'components/common/modal/ActionModal';
import React, { Dispatch, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress } from '@mui/material';
import Search from './SearchComponent';
import { Virtuoso } from 'react-virtuoso';
import { catchErrorMessage, formatDate } from 'utility/helpers';
import { $LineBreak } from 'components/drawer/MainDrawer';
import useUserData from 'hooks/useUserData';
import KnowledgeAPI, { FeedFilter } from 'api/KnowledgeAPI';
import { toast } from 'react-toastify';
import { updateArticleDetailsCache } from 'pages/article/hooks/useArticleDetailsQuery';
import HandleOperation from 'components/utils/HandleOperation';
import { ArticleType, useArticlesAndPlaylistsPaginationQuery } from '../hooks/useArticlesQuery';
import Dot from 'components/common/dot/Dot';
import { UseFormGetValues } from 'react-hook-form';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';

type AddPostsToPlaylistModalPropTypes = {
  open: boolean;
  playListArticle: ArticleType;
  isEdit?: boolean;
  selectedFeedFilters?: FeedFilter[];
  addToPlayListArticles?: (val) => void;
  getValues?: UseFormGetValues<PostFormData>;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const AddPostsToPlaylistModal: React.FC<AddPostsToPlaylistModalPropTypes> = ({
  open,
  playListArticle,
  isEdit,
  selectedFeedFilters,
  addToPlayListArticles,
  getValues,
  setOpen,
}) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    articles,
    isLoading,
    searchInfo: { isLoading: searchLoading },
    updateFeedCache,
  } = useArticlesAndPlaylistsPaginationQuery({
    searchKeyword,
    pageNo: 0,
    pageSize: 10,
    searchOnly: !!searchKeyword,
    filters: selectedFeedFilters,
  });
  const { companyId } = useUserData();
  const [loading, setLoading] = useState(false);
  const [searchArticles, setSearchArticles] = useState<ArticleType[]>([]);
  const [articleIds, setArticleIds] = useState({});
  const [selectedArticles, setSelectedArticles] = useState<any>([]);

  useEffect(() => {
    if (articles && articles.length > 0) {
      const copyArticles = [...articles];
      const removePlayListArticles = copyArticles.filter(
        (article) => article.id !== playListArticle.id,
      );
      setSearchArticles([...removePlayListArticles]);
    }
  }, [articles]);

  const addPostsToPlaylist = async () => {
    try {
      if (!isEdit) {
        setLoading(true);
        const articleResponse = await KnowledgeAPI.addPostsToPlayList(
          companyId,
          playListArticle?.id,
          Object.keys(articleIds),
        );
        toast.success('Posts added to playlist successfully');
        updateArticleDetailsCache(companyId, {
          ...articleResponse.data.article,
        });
        updateFeedCache({
          action: 'update',
          article: articleResponse.data.article,
          _feedType: ['collections'],
        });
        // TODO: update article response in all respective articles cache, like user articles, project articles etc.
      } else {
        addToPlayListArticles?.(selectedArticles);
      }
    } catch (err) {
      toast.error(catchErrorMessage(err));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleDisable = (item) => {
    if (!isEdit) {
      return playListArticle?.playListArticleIds?.indexOf(item.id) > -1;
    }
    return (
      playListArticle?.playListArticleIds?.indexOf(item.id) > -1 ||
      (getValues?.()?.playListArticleIds &&
        getValues?.()?.playListArticleIds?.indexOf(item.id) > -1)
    );
  };

  const handleChange = (item, checked) => {
    if (checked) {
      setArticleIds((prev) => {
        prev[item.id] = true;
        return { ...prev };
      });
      setSelectedArticles((prev: any) => {
        prev.push(item);
        return [...prev];
      });
    } else {
      setArticleIds((prev) => {
        delete prev[item.id];
        return { ...prev };
      });
      setSelectedArticles((prev: any) => {
        const removeItem = prev.filter((article) => article.id !== item.id);
        return [...removeItem];
      });
    }
  };

  return (
    <ActionModal
      width={620}
      maxHeight="496px"
      onClose={() => {
        setOpen(false);
        setSearchKeyword('');
      }}
      title="Add Posts To Collection"
      open={open}
      footer={
        <>
          {
            <Button
              onClick={addPostsToPlaylist}
              variant="contained"
              disabled={Object.keys(articleIds).length === 0 || loading}
            >
              {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Add'}
            </Button>
          }
        </>
      }
      contentSx={{ pt: 0 }}
    >
      <Box
        sx={{
          position: 'fixed',
          width: '572px',
          zIndex: '1',
          pt: 2,
          backgroundColor: '#fff',
        }}
      >
        <Search
          value={searchKeyword}
          placeholder="Search Posts"
          setValue={setSearchKeyword}
          filled
          triggerOnEnter
        />
      </Box>

      <HandleOperation isLoading={isLoading || searchLoading}>
        {searchArticles && searchArticles.length > 0 && (
          <Box
            sx={{
              '& div[data-viewport-type="element"]': {
                position: 'unset !important',
              },
              marginTop: '4rem',
            }}
          >
            <Virtuoso
              increaseViewportBy={250}
              style={{
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
              data={searchArticles}
              itemContent={(_, item) =>
                item && item.title ? (
                  <>
                    <Box
                      key={item.id}
                      className="root-div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: '1rem 0',
                        '& .MuiButton-root': {
                          minWidth: '80px',
                        },
                      }}
                    >
                      <Checkbox
                        onClick={() =>
                          handleChange(item, !(handleDisable(item) ? true : !!articleIds[item.id]))
                        }
                        checked={handleDisable(item) ? true : !!articleIds[item.id]}
                        disabled={handleDisable(item)}
                      />
                      <Box
                        sx={{
                          ml: 1,
                          '& span': {
                            fontWeight: 400,
                            fontSize: '13px',
                            color: '#6A6C89',
                          },
                          '& .MuiBox-root': {
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#35374F',
                          },
                        }}
                      >
                        <Box>{item.title}</Box>
                        <span>
                          {item.userNameToDisplay}
                          <Dot />
                          {item.userNameTitleToDisplay}
                          <Dot />
                          {formatDate(item.publishedAt)}
                        </span>
                      </Box>
                    </Box>
                    <$LineBreak sx={{ margin: 'unset' }} />
                  </>
                ) : null
              }
            />
          </Box>
        )}
      </HandleOperation>
      {!!searchKeyword && !loading && searchArticles.length === 0 && (
        <Box>No results found for "{searchKeyword}"</Box>
      )}
    </ActionModal>
  );
};

export default AddPostsToPlaylistModal;
