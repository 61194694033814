import { Box, Typography } from '@mui/material';
import { ArticleType } from '../hooks/useArticlesQuery';
import { UseArticleReducerActions } from '../hooks/useArticle';
import { UserLink } from './DraftStatusMessage';
import Dot from 'components/common/dot/Dot';
import useCompanyQueryData from 'hooks/useCompanyQueryData';

type RenderArticleUserProps = {
  article: ArticleType;
  isPublishedByBuildWiseInsights: boolean;
  dispatch: (value: UseArticleReducerActions) => void;
  source?: string;
};

function RenderArticleUser({
  article,
  isPublishedByBuildWiseInsights,
  dispatch,
  source,
}: RenderArticleUserProps) {
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();

  return (
    <Box>
      <UserLink
        disableUserLink={!!source}
        onInsightsUserClick={() =>
          dispatch({ type: 'TOGGLE_INSIGHTS_MODAL', payload: true, key: 'openInsightsModal' })
        }
        mail={article.createdBy}
        name={article.userNameToDisplay}
        sx={{ lineHeight: 1.2, display: 'block' }}
      />
      <Box>
        <Typography variant="caption" title="Job Title">
          {isPublishedByBuildWiseInsights ? 'From your data' : article.userNameTitleToDisplay || ''}
        </Typography>
        {shouldDisplayCompanyOnUserCards &&
          (article?.primaryCompanyName || article?.userPrimaryCompanyName) && (
            <>
              <Dot />
              <Typography variant="caption" title="Company">
                {article?.primaryCompanyName || article?.userPrimaryCompanyName}
              </Typography>
            </>
          )}
      </Box>
    </Box>
  );
}

export default RenderArticleUser;
