import { ConfigAPI, useConfigQuery } from '@buildwise/libs/services';
import { Box, Typography, IconButton, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectMenu from 'components/common/menu/SelectMenu';
import AlertModal from 'components/utils/AlertModal';
import { ConfigType, FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';
import {
  USER_PROFILE,
  EDIT_COMPANY_SCORECARD,
  EDIT_ADMIN_SCORECARD,
  EDIT_COMPANY_BUILDWISE_SCORECARD,
  EDIT_ADMIN_BUILDWISE_SCORECARD,
} from 'constants/routes';
import useUserData from 'hooks/useUserData';
import { ChangeEvent, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import More2FillIcon from 'remixicon-react/More2FillIcon';
import { catchErrorMessage, formatDate } from 'utility/helpers';

type RenderScoreCardProps = {
  isCompanyConfig: boolean;
  config: ConfigType;
  configCategory:
    | FORMS_CONFIG_CATEGORY_TYPES.SCORECARD
    | FORMS_CONFIG_CATEGORY_TYPES.BUILDWISE_SCORECARD;
};

function RenderScoreCard({ isCompanyConfig, config, configCategory }: RenderScoreCardProps) {
  const { companyId } = useUserData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { configs, updateConfigCache } = useConfigQuery({
    ...(isCompanyConfig && {
      companyId,
    }),
    queryParams: {
      configCategory,
    },
  });
  const isBuildWiseScoreCard = pathname?.toLowerCase().includes('buildwise-scorecards');
  const navigateUrl = isCompanyConfig
    ? isBuildWiseScoreCard
      ? EDIT_COMPANY_BUILDWISE_SCORECARD
      : EDIT_COMPANY_SCORECARD
    : isBuildWiseScoreCard
      ? EDIT_ADMIN_BUILDWISE_SCORECARD
      : EDIT_ADMIN_SCORECARD;
  const menu = useMemo(
    () => [
      {
        title: 'Edit',
        icon: <EditLineIcon size={17} />,
        onClick: () =>
          navigate(navigateUrl, {
            state: { isCompanyConfig, config },
          }),
      },
      {
        title: 'Delete',
        icon: <DeleteBinLineIcon size={17} />,
        onClick: () => setOpenDeleteModal(true),
      },
    ],
    [isCompanyConfig, config],
  );

  const onDeleteProgresscard = async () => {
    try {
      setIsDeleting(true);
      await ConfigAPI.deleteConfigById({
        id: config.id,
      });
      updateConfigCache({
        ...(isCompanyConfig && {
          companyId,
        }),
        queryParams: {
          configCategory,
        },
        updatedConfig: {
          configs: configs.filter((_config) => _config.id !== config.id),
        },
      });
      toast.success('Template deleted successfully!');
    } catch (error) {
      console.warn('DELETE_CONFIG_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setOpenDeleteModal(false);
      setIsDeleting(false);
    }
  };

  const updateConfig = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUpdating(true);
      const { data } = await ConfigAPI.updateConfig({
        ...(isCompanyConfig && {
          companyId,
        }),
        ...config,
        isActive: event.target.checked,
      });

      updateConfigCache({
        ...(isCompanyConfig && {
          companyId,
        }),
        queryParams: {
          configCategory,
        },
        updatedConfig: {
          configs: configs.map((_config) =>
            _config.id === config.id
              ? {
                  ...config,
                  ...data?.config,
                }
              : _config,
          ),
        },
      });
      toast.success('Template updated successfully!');
    } catch (error) {
      console.warn('UPDATE_CONFIG_STATUS_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Link to={navigateUrl} state={{ isCompanyConfig, config }}>
          <Typography variant="h6">{config.title}</Typography>
        </Link>
        <Box display="flex" alignItems="center">
          <Typography variant="caption" sx={{ fontSize: 14 }} title="Last Updated On">
            {formatDate(config.updatedAt || config.createdAt)}
          </Typography>
          <SelectMenu menu={menu}>
            <IconButton size="small" sx={{ ml: 1 }} onClick={(event) => event.preventDefault()}>
              <More2FillIcon size={18} />
            </IconButton>
          </SelectMenu>
        </Box>
      </Box>
      <Link to={navigateUrl} state={{ isCompanyConfig, config }}>
        <Typography variant="body1" mt={0.5}>
          {config.description}
        </Typography>
      </Link>

      <br />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          component="strong"
          title="Updated By"
          sx={(theme) => ({ color: theme.palette.custom.mistBlue, fontSize: 14 })}
        >
          by{' '}
          <UserLink
            to={USER_PROFILE.replace(':userName', config?.updatedBy || '')}
            onClick={(event) => event.stopPropagation()}
          >
            {config?.updatedByDisplayName || config.updatedBy}
          </UserLink>
        </Typography>

        <FormControlLabel
          control={<Switch disableRipple checked={config?.isActive} onChange={updateConfig} />}
          label={config.isActive ? 'Active' : 'Inactive'}
          disabled={isUpdating}
          sx={{
            flexDirection: 'row-reverse',
            mr: 0,
            '& .MuiTypography-root': { fontSize: 14, fontWeight: 400 },
          }}
        />
      </Box>

      {openDeleteModal && (
        <AlertModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onSubmit={onDeleteProgresscard}
          loading={isDeleting}
          description={
            <>
              Are you sure you want to delete this template?
              <br />
              This template will be deleted permanently.
            </>
          }
        />
      )}
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  marginBottom: '16px',
  padding: '16px',
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  cursor: 'pointer',
  borderRadius: '4px',
}));

const UserLink = styled(Link, { label: 'UserLink' })(({ theme }) => ({
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default RenderScoreCard;
