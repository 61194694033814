import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { ReactComponent as WordDocumentIcon } from 'assets/icons/doc.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as PptDocumentIcon } from 'assets/icons/ppt.svg';
import { ReactComponent as ExcelDocumentIcon } from 'assets/icons/xls.svg';
import { ReactComponent as TextDocumentIcon } from 'assets/icons/txt.svg';
import FileTextLineIcon from 'remixicon-react/FileTextLineIcon';
import VolumeUpFillIcon from 'remixicon-react/VolumeUpFillIcon';
import {
  ExtraFileNameAttachedByServiceExtendsType,
  getExtraFileNameAttachedByService,
  getFileDetails,
} from 'utility/helpers';
import { $PlayIcon } from 'pages/knowledge/components/RenderVideo';
import { useMemo } from 'react';

type RenderAttachmentProps<T> = {
  attachment: AttachmentType;
  smallView?: boolean;
  mode?: 'view' | 'edit';
  item?: T;
};

const RenderThumbnailAttachment = <T extends ExtraFileNameAttachedByServiceExtendsType>({
  attachment,
  smallView,
  mode = 'edit',
  item,
}: RenderAttachmentProps<T>) => {
  const { fileType, isImage, isVideo, isAudio, isDocument, fileName } = useMemo(
    () =>
      getFileDetails({
        file: attachment,
        useDefaultStrToSliceFromFileName: !attachment.fileUploaded,
        strToSliceFromFileName: getExtraFileNameAttachedByService<T>(item),
      }),
    [attachment],
  );

  if (isImage) {
    return (
      <img
        alt={fileName}
        crossOrigin="anonymous"
        src={
          (mode === 'edit'
            ? attachment?.thumbnailOpenUrl || ''
            : attachment?.articleOpenUrl) as string
        }
      />
    );
  }

  if (isVideo) {
    return (
      <>
        <$PlayIcon>
          <PlayIcon width={50} height={50} />
        </$PlayIcon>
        <video poster={(attachment?.thumbnailOpenUrl || '') as string} />
      </>
    );
  }

  if (isAudio) {
    return (
      <$DocumentContainer>
        <VolumeUpFillIcon size={smallView ? 80 : 120} />
      </$DocumentContainer>
    );
  }

  if (isDocument) {
    switch (fileType) {
      case 'doc':
      case 'docx':
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'msword': {
        return (
          <$DocumentContainer>
            <WordDocumentIcon width={80} height={80} />
          </$DocumentContainer>
        );
      }

      case 'pdf': {
        return (
          <$DocumentContainer>
            <PdfIcon width={80} height={80} />
          </$DocumentContainer>
        );
      }

      case 'plain':
      case 'txt': {
        return (
          <$DocumentContainer>
            <TextDocumentIcon width={80} height={80} />
          </$DocumentContainer>
        );
      }

      case 'ppt':
      case 'pptx':
      case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'vnd.ms-powerpoint': {
        return (
          <$DocumentContainer>
            <PptDocumentIcon width={80} height={80} />
          </$DocumentContainer>
        );
      }

      case 'xls':
      case 'csv':
      case 'xlsx':
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'vnd.ms-excel': {
        return (
          <$DocumentContainer>
            <ExcelDocumentIcon width={80} height={80} />
          </$DocumentContainer>
        );
      }

      default: {
        return (
          <$DocumentContainer>
            <FileTextLineIcon size={smallView ? 80 : 160} />
          </$DocumentContainer>
        );
      }
    }
  }

  return null;
};

const $DocumentContainer = styled(Box)({
  fontSize: '80px',
  textAlign: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default RenderThumbnailAttachment;
