import { styled } from '@mui/material/styles';
import {
  List,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from '@mui/material';
import { ReactNode } from 'react';

export type RenderListProps = {
  list: {
    icon: ReactNode;
    message: ReactNode;
    hide?: boolean;
  }[];
};

function RenderList({ list }: RenderListProps) {
  const listToRender = list.filter((listItem) => !listItem.hide);

  return (
    <List>
      {listToRender.map((listItem, index) => (
        <ListItem key={index}>
          <ListItemIcon>{listItem.icon}</ListItemIcon>
          <ListItemText>{listItem.message}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const ListItem = styled(MuiListItem, { label: 'ListItem' })({
  padding: 0,
  marginBottom: 16,
  alignItems: 'flex-start',
});

const ListItemIcon = styled(MuiListItemIcon, { label: 'ListItemIcon' })({
  minWidth: 'fit-content',
  transform: 'translateY(-2px)',
});

const ListItemText = styled(MuiListItemText, { label: 'ListItemText' })({
  paddingLeft: 8,
  margin: 0,
  '& .MuiTypography-root': {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
});

export default RenderList;
