import KnowledgeAPI from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { BuildWiseGPTHistoryItem } from './useBuildWiseGPTHistoryQuery';

type PromptBookmarksResponse = {
  promptHistoryList: BuildWiseGPTHistoryItem[];
};

function usePromptBookmarksQuery() {
  const { user } = useUserData();
  const queryClient = useQueryClient();
  const { data: bookmarksData, ...rest } = useQuery<AxiosResponse<PromptBookmarksResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.PROMPT_BOOKAMRKS, user?.username],
    () => KnowledgeAPI.getPromptBookmarks(user?.username || ''),
    {
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!user?.username,
    },
  );

  const bookmarks = useMemo(
    () =>
      Array.isArray(bookmarksData?.data.promptHistoryList)
        ? bookmarksData?.data.promptHistoryList || []
        : [],
    [bookmarksData?.data],
  );

  const updatePromptBookmarksCache = ({
    action,
    historyItem,
  }: {
    action: 'add' | 'update' | 'delete';
    historyItem: BuildWiseGPTHistoryItem;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.PROMPT_BOOKAMRKS,
      user?.username,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<PromptBookmarksResponse> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.PROMPT_BOOKAMRKS, user?.username],
        (old) => {
          if (old) {
            const updatedTemplates = [...(old?.data.promptHistoryList || [])];

            if (action === 'add') {
              updatedTemplates.unshift(historyItem);
            }

            if (action === 'update') {
              const index = updatedTemplates.findIndex((item) => item._id === historyItem._id);

              if (index > -1) {
                updatedTemplates[index] = {
                  ...updatedTemplates[index],
                  ...historyItem,
                };
              }
            }

            if (action === 'delete') {
              const index = updatedTemplates.findIndex((item) => item._id === historyItem._id);

              if (index > -1) {
                updatedTemplates.splice(index, 1);
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                promptHistoryList: updatedTemplates,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    bookmarks,
    ...rest,
    updatePromptBookmarksCache,
  };
}

export default usePromptBookmarksQuery;
