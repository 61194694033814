import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useReportModalStore from 'store/useReportModalStore';
import KnowledgeAPI from 'api/KnowledgeAPI';
import QuestionsAPI from 'api/QuestionsAPI';
import { toast } from 'react-toastify';
import { attachFiles, catchErrorMessage, getExactFileName } from 'utility/helpers';
import Edit2LineIcon from 'remixicon-react/Edit2LineIcon';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
import useUserData from 'hooks/useUserData';
import { updateQuestionCommentsCache } from 'pages/questions/hooks/useQuestionDetailsQuery';
import { AUDIO_TYPES, DOCUMENT_TYPES, IMAGE_TYPES, VIDEO_TYPES } from 'components/utils/constants';
import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import {
  CommentReplyType,
  CommentType,
  updateArticleCommentsCache,
  updateArticleDetailsCache,
} from './useArticleDetailsQuery';
import { MentionType } from '../components/CommentsBox';
import { useLocation, useNavigate } from 'react-router-dom';
import LinkIcon from 'remixicon-react/LinkIcon';
import { MenuItemType } from 'components/drawer/RenderMenu';

type UseCommentArgs = {
  article?: ArticleType;
  articleId: string;
  comment: CommentType | CommentReplyType;
  commentPath: string;
  type: string | undefined;
  isParentExpanded: boolean;
  updateParentCommentReplies?: () => void;
};

function useComment({
  article,
  comment,
  commentPath,
  type,
  articleId,
  isParentExpanded,
  updateParentCommentReplies,
}: UseCommentArgs) {
  const { user, companyId } = useUserData();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openReport = useReportModalStore((state) => state.openReport);
  const [editValue, setEditValue] = useState('');
  const [editLoading, setEditLoading] = useState(false);
  const [editCommentId, setEditCommentId] = useState('');
  const [editMentions, setEditMentions] = useState<MentionType[]>([]);
  const [editAttachments, setEditAttachments] = useState<AttachmentType[]>([]);
  const [replyId, setReplyId] = useState('');
  const [replyMentions, setReplyMentions] = useState<MentionType[]>([]);
  const [replyValue, setReplyValue] = useState('');
  const [replyCommentLoading, setReplyCommentLoading] = useState(false);
  const [replyAttachments, setReplyAttachments] = useState<AttachmentType[]>([]);
  const commentRef = useRef<HTMLDivElement>(null);
  const commentTextContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollToCommentId = useMemo(() => {
    const commentId = decodeURIComponent(hash).replace('#', '');

    return commentId !== 'comments' ? commentId : null;
  }, [hash]);
  const isScrollToCommentIdExistsInReplies = useMemo(() => {
    const checkScrollToCommentIdInReplies = (replies: CommentReplyType[]): boolean => {
      if (replies.length === 0) {
        return false;
      }

      let isExistsReplies = false;

      for (let i = 0; i < replies.length; i++) {
        if (replies[i].id === scrollToCommentId) {
          isExistsReplies = true;
          break;
        }

        isExistsReplies = checkScrollToCommentIdInReplies(replies[i].replies || []);
        if (isExistsReplies) {
          break;
        }
      }

      return isExistsReplies;
    };

    return (
      !!scrollToCommentId &&
      (comment.id === scrollToCommentId || checkScrollToCommentIdInReplies(comment?.replies || []))
    );
  }, [scrollToCommentId, comment]);
  const [openCommentReplies, setOpenCommentReplies] = useState(
    isParentExpanded || isScrollToCommentIdExistsInReplies,
  );
  const [lightboxOpen, setLightboxOpen] = useState<{
    open: boolean;
    fileName: string;
    action?: string;
  }>({
    open: false,
    fileName: '',
    action: '',
  });

  const countReplies = () => {
    let count = 0;

    const countCommentReplies = (commentObj: CommentType | CommentReplyType) => {
      count += commentObj?.replies?.length || 0;

      commentObj?.replies?.forEach((reply) => countCommentReplies(reply));
    };

    countCommentReplies(comment);

    return count;
  };

  const [allCommentReplies, setAllCommentReplies] = useState(countReplies());

  const isUserLiked = useMemo(
    () => comment.likedUsers.find((likedUser) => likedUser.id === user?.id),
    [comment, comment.likedUsers.length, user?.id],
  );

  const menuOptions = useMemo(() => {
    const MENU: MenuItemType[] = [
      {
        title: 'Edit',
        icon: <Edit2LineIcon size={17} />,
        onClick: () => {
          setEditValue(comment.commentText);
          setEditCommentId(comment.id);
          setEditAttachments(comment?.attachments || []);
        },
        isHidden: user?.username !== comment.commentAuthor,
      },
      {
        title: 'Copy Link',
        icon: <LinkIcon size={17} />,
        onClick: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}${window.location.pathname}#${comment.id}`,
          );
        },
        isHidden: false,
      },
      {
        title: 'Report',
        icon: <FlagLineIcon size={17} />,
        onClick: () =>
          openReport({
            id: comment.id,
            text: comment.commentText,
            reportObject: 'Comment',
            article,
            comment,
            commentPath,
          }),
        isHidden: user?.username === comment.commentAuthor,
      },
    ];

    return MENU.filter((option) => !option.isHidden);
  }, [comment, user]);

  useEffect(() => {
    if (scrollToCommentId && commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
      commentTextContainerRef.current = commentRef.current.querySelector(
        '#comment-text-container',
      ) as HTMLDivElement | null;
    }
  }, [commentRef.current, scrollToCommentId]);

  useEffect(() => {
    if (commentTextContainerRef.current) {
      commentTextContainerRef.current.className = `${commentTextContainerRef.current.className} highlight`;
    }

    setTimeout(() => {
      if (commentTextContainerRef.current) {
        commentTextContainerRef.current.className =
          commentTextContainerRef.current.className.replace('highlight', '');
      }
    }, 4000);
  }, [commentTextContainerRef.current]);

  const replyComment = async (__: CommentType, commentPath: string) => {
    if (articleId && user) {
      try {
        const mentions = replyMentions.map((m) => ({
          displayName: m.display,
          userName: m.id,
          pmuserId: m?.pmuserId,
          title: m.title,
          id: m.userId,
        }));
        const payload = {
          articleId,
          comment: {
            parentId: replyId,
            commentText: replyValue.trim(),
            commentAuthor: user.username,
            mentions,
          },
        };

        setReplyCommentLoading(true);

        if (type === 'question') {
          const { data } = await QuestionsAPI.replyComment(payload);

          updateQuestionCommentsCache(companyId, articleId, data?.comment, 'reply', commentPath);
        } else {
          if (payload.comment.commentText || replyAttachments.length) {
            const { data: replyCommentResData } = await KnowledgeAPI.replyComment(payload);

            if (replyAttachments.length > 0 && replyCommentResData?.comment?.id) {
              const formData = new FormData();

              replyAttachments.forEach((attachment) => {
                formData.append('file', attachment.file);
                if (attachment.thumbnail) {
                  formData.append('thumbnail', attachment.thumbnail);
                }
              });

              const { data: replyCommentMediaResData } = await KnowledgeAPI.addCommentMedia(
                companyId,
                replyCommentResData?.comment?.id,
                formData,
              );
              updateArticleCommentsCache(
                companyId,
                articleId,
                replyCommentMediaResData,
                'reply',
                commentPath,
              );
            } else {
              updateArticleCommentsCache(
                companyId,
                articleId,
                replyCommentResData?.comment,
                'reply',
                commentPath,
              );
            }

            if (article) {
              updateArticleDetailsCache(companyId, {
                ...article,
                totalComments: article.totalComments + 1,
              });
            }

            setOpenCommentReplies(true);
            setAllCommentReplies((prevState) => prevState + 1);
            updateParentCommentReplies?.();
            resetReplyCommentState();
          }
        }
      } catch (error) {
        toast.error(catchErrorMessage(error));
      } finally {
        setReplyCommentLoading(false);
      }
    }
  };

  const updateComment = async (commentObj: CommentType | CommentReplyType, commentPath: string) => {
    if (articleId && user) {
      try {
        const mentions = editMentions.map((m) => ({
          displayName: m.display,
          userName: m.id,
          pmuserId: m?.pmuserId,
          title: m.title,
          id: m.userId,
        }));

        const payload = {
          id: commentObj.id,
          companyId: commentObj.companyId,
          articleId: commentObj.articleId,
          comment: {
            commentText: editValue.trim(),
            commentAuthor: commentObj.commentAuthor,
            mentions,
          },
        };
        setEditLoading(true);

        if (type === 'question') {
          const { data } = await QuestionsAPI.updateComment(payload);

          updateQuestionCommentsCache(companyId, articleId, data?.comment, 'update', commentPath);
        } else {
          const newAttachments = editAttachments.filter((attachment) => !attachment?.fileUploaded);
          const updatedAttachments = editAttachments.filter(
            (attachment) => attachment?.fileUploaded && attachment.isUpdated,
          );
          const removedAttachments = editAttachments
            .filter((file) => file.fileUploaded && file.isRemoved)
            .map((file) => file.fileName);

          if (
            payload.comment.commentText !== comment.commentText ||
            newAttachments.length ||
            updatedAttachments.length ||
            removedAttachments.length
          ) {
            const updateCommentPromises: Promise<AxiosResponse<any, Error> | any>[] = [];

            // check if comment text can be updated even when it is empty with attachments
            const shouldUpdateCommentText = payload.comment.commentText
              ? payload.comment.commentText !== comment.commentText
              : comment.attachments?.length &&
                comment.attachments?.length !== removedAttachments.length;
            if (shouldUpdateCommentText) {
              updateCommentPromises.push(KnowledgeAPI.updateComment(payload));
            }

            if (newAttachments.length > 0) {
              const formData = new FormData();

              newAttachments.forEach((attachment) => {
                formData.append('file', attachment.file);
                if (attachment.thumbnail) {
                  formData.append('thumbnail', attachment.thumbnail);
                }
              });

              updateCommentPromises.push(
                KnowledgeAPI.addCommentMedia(companyId, comment?.id, formData),
              );
            }

            if (updatedAttachments.length > 0) {
              const formData = new FormData();

              updatedAttachments.forEach((attachment) => {
                formData.append('file', attachment.file);
                if (attachment.thumbnail) {
                  formData.append('thumbnail', attachment.thumbnail);
                }
              });

              updateCommentPromises.push(
                KnowledgeAPI.updateCommentMedia(companyId, comment?.id, formData),
              );
            }

            if (removedAttachments.length) {
              const removeAttachmentsPromises = removedAttachments.map((fileName) =>
                KnowledgeAPI.removeCommentMedia(companyId, comment.id, fileName),
              );
              updateCommentPromises.push(Promise.all(removeAttachmentsPromises));
            }

            const [res1, res2, res3] = await Promise.all(updateCommentPromises);

            let updatedComment: CommentType | CommentReplyType | undefined = shouldUpdateCommentText
              ? res1?.data?.comment || {}
              : res1?.data || res2?.data || comment;
            let attachments: AttachmentType[] = [];

            // collect new attachments
            if (shouldUpdateCommentText && newAttachments.length) {
              attachments = [...(res2?.data?.attachments || []), ...attachments];
            }
            if (!shouldUpdateCommentText && newAttachments.length) {
              attachments = [...(res1?.data?.attachments || []), ...attachments];
            }

            // collect updated attachments
            if (shouldUpdateCommentText && newAttachments.length && updatedAttachments.length) {
              attachments = [...(res3?.data?.attachments || []), ...attachments];
            }
            if (
              (shouldUpdateCommentText &&
                newAttachments.length === 0 &&
                updatedAttachments.length) ||
              (!shouldUpdateCommentText && newAttachments.length && updatedAttachments.length)
            ) {
              attachments = [...(res2?.data?.attachments || []), ...attachments];
            }
            if (
              !shouldUpdateCommentText &&
              newAttachments.length === 0 &&
              updatedAttachments.length
            ) {
              attachments = [...(res1?.data?.attachments || []), ...attachments];
            }

            if (!_.isEmpty(updatedComment)) {
              // order attachments
              let finalAttachments = _.uniqBy(attachments, 'fileName');
              finalAttachments = editAttachments
                .filter((attachment) => !(attachment.fileUploaded && attachment.isRemoved))
                .map((attachment) => {
                  return (
                    attachments.find(
                      (file) =>
                        getExactFileName(file).toLowerCase() ===
                        getExactFileName(attachment).toLowerCase(),
                    ) || attachment
                  );
                });
              updatedComment = {
                ...updatedComment,
                attachments: finalAttachments,
              };

              updateArticleCommentsCache(
                companyId,
                articleId,
                updatedComment,
                'update',
                commentPath,
              );
            }
          }
          resetEditCommentState();
        }
      } catch (error) {
        toast.error(catchErrorMessage(error));
      } finally {
        setEditLoading(false);
      }
    }
  };

  const likeComment = async (commentObj: CommentType, commentPath: string) => {
    try {
      if (articleId && user) {
        const isUserLiked = commentObj.likedUsers.find((likedUser) => likedUser.id === user?.id);

        let updatedComment = { ...commentObj };

        if (isUserLiked) {
          const likedUsers = [...commentObj.likedUsers];
          const index = likedUsers.findIndex((likedUser) => likedUser.id === user.id);
          if (index > -1) {
            likedUsers.splice(index, 1);
            updatedComment = {
              ...updatedComment,
              likedUsers,
            };
          }
        } else {
          updatedComment = {
            ...updatedComment,
            likedUsers: [
              ...updatedComment.likedUsers,
              {
                ...user,
                displayName: user?.displayName || '',
                pmuserId: user?.pmuserId || null,
                profileImageURL: user?.profileImageURL || '',
                userName: user.username,
              },
            ],
          };
        }

        if (type === 'question') {
          updateQuestionCommentsCache(companyId, articleId, updatedComment, 'like', commentPath);
        } else {
          updateArticleCommentsCache(companyId, articleId, updatedComment, 'like', commentPath);
        }

        if (isUserLiked) {
          await KnowledgeAPI.removeCommentLike(user?.companyId, commentObj.id);
        } else {
          await KnowledgeAPI.likeComment(user?.companyId, commentObj.id);
        }
      }
    } catch (error) {
      toast.error(catchErrorMessage(error));
    }
  };

  const resetEditCommentState = () => {
    setEditValue('');
    setEditCommentId('');
    setEditMentions([]);
    setEditAttachments([]);
  };

  const resetReplyCommentState = () => {
    setReplyId('');
    setReplyValue('');
    setReplyMentions([]);
    setReplyAttachments([]);
  };

  const [documents, files, mediaFiles] = useMemo(() => {
    const docs: AttachmentType[] = [];
    const mediaAttachments: AttachmentType[] = [];
    const mediaFiles: AttachmentType[] = [];

    comment?.attachments?.forEach((attachment) => {
      let fileType = attachment?.fileType.toLowerCase() || '';

      if (attachment.fileUploaded) {
        if (
          attachment.fileType.toLowerCase() === 'txt' ||
          attachment.fileType.toLowerCase() === 'csv'
        ) {
          fileType = `text/${
            attachment.fileType.toLowerCase() === 'txt'
              ? 'plain'
              : attachment.fileType.toLowerCase()
          }`;
        } else {
          fileType = `application/${attachment.fileType.toLowerCase()}`;
        }
      }

      if (DOCUMENT_TYPES.includes(fileType)) {
        docs.push(attachment);
      }

      if (!DOCUMENT_TYPES.includes(fileType)) {
        mediaAttachments.push(attachment);
      }

      const isMediaFile =
        IMAGE_TYPES.includes(`image/${attachment.fileType.toLowerCase()}`) ||
        VIDEO_TYPES.includes(`video/${attachment.fileType.toLowerCase()}`) ||
        AUDIO_TYPES.includes(`audio/${attachment.fileType.toLowerCase()}`);

      if (isMediaFile) {
        mediaFiles.push(attachment);
      }
    });

    return [docs, mediaAttachments, mediaFiles];
  }, [comment.attachments]);

  const editMediaFiles = useMemo(() => {
    const mediaFiles: AttachmentType[] = [];

    editAttachments?.forEach((attachment) => {
      let fileType = `${attachment.fileType.toLowerCase().split('/')?.[1]}`;
      if (attachment.fileUploaded) {
        fileType = attachment.fileType.toLowerCase();
      }

      const isMediaFile =
        IMAGE_TYPES.includes(`image/${fileType}`) ||
        VIDEO_TYPES.includes(`video/${fileType}`) ||
        AUDIO_TYPES.includes(`audio/${fileType}`);

      if (isMediaFile) {
        mediaFiles.push(attachment);
      }
    });

    return mediaFiles;
  }, [editAttachments]);

  const replyMediaFiles = useMemo(() => {
    const mediaFiles: AttachmentType[] = [];

    replyAttachments?.forEach((attachment) => {
      let fileType = `${attachment.fileType.toLowerCase().split('/')?.[1]}`;
      if (attachment.fileUploaded) {
        fileType = attachment.fileType.toLowerCase();
      }

      const isMediaFile =
        IMAGE_TYPES.includes(`image/${fileType}`) ||
        VIDEO_TYPES.includes(`video/${fileType}`) ||
        AUDIO_TYPES.includes(`audio/${fileType}`);

      if (isMediaFile) {
        mediaFiles.push(attachment);
      }
    });

    return mediaFiles;
  }, [replyAttachments]);

  const onReplyFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = [...((event?.target?.files || []) as File[])];
      attachFiles(files, replyAttachments, setReplyAttachments);
    },
    [replyAttachments],
  );

  const onEditFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = [...((event?.target?.files || []) as File[])];
      attachFiles(files, editAttachments, setEditAttachments);
    },
    [editAttachments],
  );

  const replyRemoveAttachment = useCallback(
    (file: AttachmentType) => {
      if (!replyCommentLoading) {
        setReplyAttachments((prevState) => {
          const newAttachments = [...prevState];
          const attachmentIndex = newAttachments.findIndex(
            (attachment) => attachment.fileName === file.fileName,
          );

          if (attachmentIndex > -1) {
            newAttachments.splice(attachmentIndex, 1);
          }

          return [...newAttachments];
        });
      }
    },
    [replyAttachments, replyCommentLoading],
  );

  const editRemoveAttachment = useCallback(
    (file: AttachmentType) => {
      if (!editLoading) {
        setEditAttachments((prevState) => {
          const newAttachments = [...prevState];
          const attachmentIndex = newAttachments.findIndex(
            (attachment) => attachment.fileName === file.fileName,
          );

          if (attachmentIndex > -1) {
            if (file.fileUploaded) {
              newAttachments[attachmentIndex] = {
                ...newAttachments[attachmentIndex],
                isRemoved: true,
              };
            } else {
              newAttachments.splice(attachmentIndex, 1);
            }
          }

          return [...newAttachments];
        });
      }
    },
    [editAttachments, editLoading],
  );

  const updateAttachment = (
    setAttachments: Dispatch<SetStateAction<AttachmentType[]>>,
    fileName: string,
    updatedAttachment: AttachmentType,
  ) => {
    setAttachments((prevState) => {
      const updatedAttachments = [...prevState];
      const index = updatedAttachments.findIndex((file) => file.fileName === fileName);

      if (updatedAttachment.fileUploaded) {
        updatedAttachments[index] = {
          ...updatedAttachment,
          isUpdated: true,
        };
      } else {
        updatedAttachments[index] = updatedAttachment;
      }

      return updatedAttachments;
    });
  };

  return {
    allCommentReplies,
    commentRef,
    documents,
    editAttachments,
    editCommentId,
    editLoading,
    editMediaFiles,
    editValue,
    files,
    isScrollToCommentIdExistsInReplies,
    isUserLiked,
    lightboxOpen,
    mediaFiles,
    menuOptions,
    openCommentReplies,
    pathname,
    replyAttachments,
    replyCommentLoading,
    replyId,
    replyMediaFiles,
    replyValue,
    scrollToCommentId,
    editRemoveAttachment,
    likeComment,
    navigate,
    onEditFileInputChange,
    onReplyFileInputChange,
    replyComment,
    replyRemoveAttachment,
    resetEditCommentState,
    resetReplyCommentState,
    setAllCommentReplies,
    setEditAttachments,
    setEditMentions,
    setEditValue,
    setLightboxOpen,
    setOpenCommentReplies,
    setReplyAttachments,
    setReplyId,
    setReplyMentions,
    setReplyValue,
    updateAttachment,
    updateComment,
  };
}

export default useComment;
