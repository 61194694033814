import useEndorsedPostsQuery from '../queries/useEndorsedPostsQuery';
import ArticleBox from '@buildwise/pages/knowledge/components/ArticleBox';
import ArticleSkelton from '@buildwise/pages/project/components/ArticleSkelton';
import HandleOperation from 'components/utils/HandleOperation';
import { ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import NoPosts from './NoPosts';
import { Grid } from '@mui/material';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { getPageScrollInfo, handlePageScrollPosition } from 'utility/helpers';
import { useRef, useEffect } from 'react';

function EndorsedPosts() {
  const { endorsedPosts, isLoading, updateCache } = useEndorsedPostsQuery();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  useEffect(() => {
    const homeTabsEle = document.querySelector('#home-page-tabs');
    const isHomeTabsStickyPositioned =
      homeTabsEle?.getBoundingClientRect().top && homeTabsEle?.getBoundingClientRect().top === 79;

    const scrollToIndex = getPageScrollInfo<ArticleType>('endorsed-posts', endorsedPosts, 'id');

    if (containerRef.current) {
      containerRef.current.style.visibility = 'hidden';
    }

    if (isHomeTabsStickyPositioned || scrollToIndex > 2) {
      if (containerRef.current && isLoading) {
        window.scrollTo({
          top:
            containerRef.current.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            130,
        });
      }

      setTimeout(() => {
        virtuosoRef.current?.scrollToIndex({
          index: scrollToIndex,
          offset: -140,
        });

        if (containerRef.current) {
          containerRef.current.style.visibility = 'visible';
        }
      }, 300);
    } else {
      if (containerRef.current) {
        containerRef.current.style.visibility = 'visible';
      }
    }
  }, [containerRef, virtuosoRef]);

  return (
    <Grid container spacing={2} mb={3} minHeight="100vh">
      <Grid item xs={12} ref={containerRef} visibility="hidden">
        <HandleOperation
          isLoading={isLoading}
          data={endorsedPosts}
          skelton={<ArticleSkelton variant="short" />}
          skeltonCount={5}
          noDataComponent={<NoPosts message="No Endorsed Posts" />}
        >
          <Virtuoso
            rangeChanged={(range: ListRange) => {
              if (virtuosoRef.current && containerRef.current?.style.visibility !== 'hidden') {
                handlePageScrollPosition(
                  'endorsed-posts',
                  0,
                  window.scrollY === 0 ? 0 : Math.round((range.startIndex + range.endIndex) / 2),
                );
              }
            }}
            ref={virtuosoRef}
            useWindowScroll
            data={endorsedPosts}
            itemContent={(index, item) => (
              <ArticleBox
                key={item.id}
                article={item}
                articleIndex={index}
                variant="short"
                doNotAutoplayVideos
                scrollInfoKey="endorsed-posts"
                bookmarkUpdateCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                deleteArticleCallback={({ article }) =>
                  updateCache({ action: 'delete', updatedArticle: article })
                }
                endorseArticleCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                likeUpdateCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
                updateArticleCallback={({ updatedArticle }) =>
                  updateCache({ action: 'update', updatedArticle })
                }
              />
            )}
          />
        </HandleOperation>
      </Grid>
    </Grid>
  );
}

export default EndorsedPosts;
