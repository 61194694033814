/* ----------- Auth ------------- */
export const BASE = '/';
export const AUTH = BASE;
export const LOGIN = `${AUTH}login`;
export const SSO_SIGN_IN = `${AUTH}/auth/sso`;
export const PROCORE_SIGN_IN = `${AUTH}procore`;
export const SIGNUP = `${AUTH}signup`;
export const FORGOT_PASSWORD = `${AUTH}forgot-password`;
export const RESET_PASSWORD = `${AUTH}reset-password`;
export const TERMS_CONDITIONS = `${AUTH}terms-conditions`;
export const PRIVACY = `${AUTH}privacy`;
export const GET_STARTED = `${AUTH}get-started`;

/* ----------- Dashboard -------------- */
export const ADMIN = `${BASE}admin`;
export const HOME = `${ADMIN}/home`;
export const DASHBOARD = `${ADMIN}/dashboard`;
export const FEED = `${ADMIN}/knowledge`;
export const COLLECTIONS = `${ADMIN}/collections`;
export const SEARCH = `${ADMIN}/search`;
export const PEOPLE = `${ADMIN}/people`;
export const PEOPLE_BULK_UPDATE = `${ADMIN}/users/bulkUpdate`;
export const INSIGHTS = `${ADMIN}/insights`;
export const FILES = `${ADMIN}/files`;
export const INSIGHT = `${ADMIN}/insight/:insightId`;
// Partners
export const STAKEHOLDERS = `${ADMIN}/stakeholders`;
export const ADD_PARTNER = `${ADMIN}/partners/add`;
export const EDIT_PARTNER = `${ADMIN}/partner/:stakeholderId/edit`;
export const STAKEHOLDER = `${ADMIN}/stakeholder/:stakeholderId`;
export const STAKEHOLDER_FORM = `${ADMIN}/stakeholder/:stakeholderId/scorecard/:formId`;
export const CREATE_STAKEHOLDER_FORM = `${ADMIN}/stakeholder/:stakeholderId/scorecard/create`;
export const STAKEHOLDER_BULK_UPDATE = `${ADMIN}/stakeholders/bulkUpdate`;
// Projects
export const PROJECTS = `${ADMIN}/projects`;
export const ADD_PROJECT = `${ADMIN}/projects/add`;
export const EDIT_PROJECT = `${ADMIN}/project/:projectId/edit`;
export const PROJECT_BULK_UPDATE = `${ADMIN}/projects/bulkUpdate`;
export const PROJECT = `${ADMIN}/project/:projectId`;
export const PROJECT_PROGRESS_CARD = `${ADMIN}/project/:projectId/progresscard/:formId`;
export const PROJECT_SCORECARD = `${ADMIN}/project/:projectId/scorecard/:formId`;
export const CREATE_PROJECT_PROGRESS_CARD = `${ADMIN}/project/:projectId/progresscard/create`;
export const CREATE_PROJECT_SCORECARD = `${ADMIN}/project/:projectId/scorecard/create`;
export const BOOKMARKS = `${ADMIN}/bookmarks`;
export const DRAFTS = `${ADMIN}/drafts`;
export const REQUESTS = `${ADMIN}/requests`;
export const VIEW_ARTICLE = `${ADMIN}/article/:articleId`;
export const VIEW_DRAFT = `${ADMIN}/draft/:articleId`;
export const MY_PROFILE = `${ADMIN}/profile`;
export const RECENTLY_VIEWED = `${ADMIN}/recently-viewed`;
export const VIEW_QUESTION = `${ADMIN}/question/:questionId`;

export const QUESTIONS_BY_ME = `${ADMIN}/questions-by-me`;
export const QUESTIONS_FOR_ME = `${ADMIN}/questions-for-me`;
export const USER_POSTS = `${ADMIN}/posts/:userName`;
export const USER_PROFILE = `${ADMIN}/profile/:userName`;
export const NOTIFICATION = `${ADMIN}/notifications`;

// Chat
export const CHAT = `${ADMIN}/chat`;

/* ----------- Company Admin -------------- */
export const COMPANY_ADMIN = `${BASE}company-admin`;
export const ACCOUNT_SETTINGS = `${COMPANY_ADMIN}/account-settings`;
export const SUBSCRIPTION_SETTINGS = `${COMPANY_ADMIN}/subscription-settings`;
export const INTEGRATIONS = `${COMPANY_ADMIN}/integrations`;
export const COMPANY_CONFIGURATIONS = `${COMPANY_ADMIN}/company-configurations`;
export const COMPANY_FIELDS = `${COMPANY_ADMIN}/company-custom-fields`;
export const COMPANY_FILTERS = `${COMPANY_ADMIN}/company-custom-filters`;
export const USER_ACTIVITY_DASHBOARDS = `${COMPANY_ADMIN}/dashboards`;
export const TEMPLATES = `${COMPANY_ADMIN}/templates`;
export const COMPANY_PROGRESS_CARDS = `${COMPANY_ADMIN}/company-progress-cards`;
export const CREATE_COMPANY_PROGRESS_CARD = `${COMPANY_ADMIN}/company-progress-cards/create`;
export const EDIT_COMPANY_PROGRESS_CARD = `${COMPANY_ADMIN}/company-progress-cards/edit`;
export const COMPANY_SCORECARDS = `${COMPANY_ADMIN}/company-scorecards`;
export const CREATE_COMPANY_SCORECARD = `${COMPANY_ADMIN}/company-scorecards/create`;
export const EDIT_COMPANY_SCORECARD = `${COMPANY_ADMIN}/company-scorecards/edit`;
export const COMPANY_BUILDWISE_SCORECARDS = `${COMPANY_ADMIN}/company-buildwise-scorecards`;
export const CREATE_COMPANY_BUILDWISE_SCORECARD = `${COMPANY_ADMIN}/company-buildwise-scorecards/create`;
export const EDIT_COMPANY_BUILDWISE_SCORECARD = `${COMPANY_ADMIN}/company-buildwise-scorecards/edit`;
export const TEMPLATE_DETAILS = `${COMPANY_ADMIN}/templates/:templateId`;
export const TOPIC_EXPERTS = `${COMPANY_ADMIN}/topic-experts`;
export const USERS = `${COMPANY_ADMIN}/users`;
export const USER_GROUPS = `${COMPANY_ADMIN}/user-groups`;
export const COMAPNY_DASHBOARDS = `${COMPANY_ADMIN}/dashboards`;

/* ----------- BuildWise Admin -------------- */
export const BUILDWISE_ADMIN = `${BASE}buildwise-admin`;
export const BUILDWISE_COMPANIES = `${BUILDWISE_ADMIN}/companies`;
export const BUILDWISE_USERS = `${BUILDWISE_ADMIN}/all-users`;
export const BUILDWISE_CONFIGURATIONS = `${BUILDWISE_ADMIN}/configurations`;
export const ADMIN_FIELDS = `${BUILDWISE_ADMIN}/custom-fields`;
export const ADMIN_FILTERS = `${BUILDWISE_ADMIN}/custom-filters`;
export const AI_DRAFT_WORKBENCH = `${BUILDWISE_ADMIN}/drafts-workbench`;
export const ADMIN_PROGRESS_CARDS = `${BUILDWISE_ADMIN}/progress-cards`;
export const CREATE_ADMIN_PROGRESS_CARD = `${BUILDWISE_ADMIN}/progress-cards/create`;
export const EDIT_ADMIN_PROGRESS_CARD = `${BUILDWISE_ADMIN}/progress-cards/edit`;
export const ADMIN_SCORECARDS = `${BUILDWISE_ADMIN}/scorecards`;
export const CREATE_ADMIN_SCORECARD = `${BUILDWISE_ADMIN}/scorecards/create`;
export const EDIT_ADMIN_SCORECARD = `${BUILDWISE_ADMIN}/scorecards/edit`;
export const ADMIN_BUILDWISE_SCORECARDS = `${BUILDWISE_ADMIN}/buildwise-scorecards`;
export const CREATE_ADMIN_BUILDWISE_SCORECARD = `${BUILDWISE_ADMIN}/buildwise-scorecards/create`;
export const EDIT_ADMIN_BUILDWISE_SCORECARD = `${BUILDWISE_ADMIN}/buildwise-scorecards/edit`;

/* ----------- BuildWise GPT -------------- */
export const BUILDWISE_GPT_BASE = `${BASE}buildwise-gpt`;
export const BUILDWISE_GPT_CHAT = `${BUILDWISE_GPT_BASE}/chat`;
export const BUILDWISE_GPT_BOOKMARKS = `${BUILDWISE_GPT_BASE}/bookmarks`;
export const BUILDWISE_GPT_TEMPLATES = `${BUILDWISE_GPT_BASE}/prompt-templates`;
