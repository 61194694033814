import CompanyAPI, { ConfigsByTypeResponse } from 'api/CompanyAPI';
import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { companyAdminKeys } from './query-keys';
import { ConfigType } from 'config/config';

function useCompanyConfigsByTypeQuery({
  companyId,
  configType,
}: {
  companyId: string;
  configType: string;
}) {
  const queryClient = useQueryClient();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigsByTypeResponse>, Error>({
    queryKey: companyAdminKeys.getConfigsByType(companyId, configType),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
    enabled: !!companyId && !!configType,
    queryFn: () => CompanyAPI.getCompanyConfigsByType(companyId, configType),
  });

  const updateConfigsCache = ({ updatedConfig }: { updatedConfig: ConfigType }) => {
    const queryStatus = queryClient.getQueryState(
      companyAdminKeys.getConfigsByType(companyId, configType),
    );
    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigsByTypeResponse, Error> | undefined>(
        companyAdminKeys.getConfigsByType(companyId, configType),
        (old) => {
          if (old) {
            const updatedConfigs = [...(old?.data?.configs || [])];
            const index = updatedConfigs.findIndex(
              (config) => config.configName === updatedConfig.configName,
            );

            if (index > -1) {
              updatedConfigs[index] = updatedConfig;
            }

            return {
              ...old,
              data: {
                configs: updatedConfigs,
              },
            };
          }
          return old;
        },
      );
    }
  };

  return {
    configs: entriesData?.data?.configs || [],
    updateConfigsCache,
    ...rest,
  };
}

export default useCompanyConfigsByTypeQuery;
