import React, { useMemo, useRef } from 'react';
import ImageFillIcon from 'remixicon-react/ImageFillIcon';
import { SmallButton } from '../stylings';
import Typography from '@mui/material/Typography';

type AddPostModalTypes = {
  inputProps?: any;
  onClick?: any;
  label?: string;
  fileValue?: FileList;
};

const FileInput: React.FC<AddPostModalTypes> = ({
  inputProps,
  onClick,
  label = 'Add File',
  fileValue,
}) => {
  const localRef = useRef<any>(null);
  const file = useMemo(() => (fileValue ? fileValue[0] : null), [fileValue]);

  return (
    <SmallButton
      onClick={() => (onClick ? onClick() : localRef.current?.click())}
      variant="outlined"
      startIcon={<ImageFillIcon size={18} />}
    >
      {file ? (
        <Typography
          sx={{ maxWidth: 150, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {file.name}
        </Typography>
      ) : (
        label
      )}
      {inputProps && (
        <input
          {...inputProps}
          type="file"
          ref={(e) => {
            inputProps.ref(e);
            localRef.current = e;
          }}
          hidden
        />
      )}
    </SmallButton>
  );
};

export default FileInput;
