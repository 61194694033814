import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  RadioProps,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useUserData from 'hooks/useUserData';
import useCompanyQuery, { SubscriptionSettingsType } from 'hooks/useCompanyQueries';
import { TextField } from '@mui/material';
import CompanyAPI from 'api/CompanyAPI';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { ReactComponent as RadioChecked } from 'assets/icons/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from 'assets/icons/radio-unchecked.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';
import HandleOperation from 'components/utils/HandleOperation';

const $SettingsPaper = styled(Paper, { label: 'SettingsPaper' })(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 16,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
  marginBottom: 24,
  overflow: 'hidden',
  '& .company-name': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
}));

export const $ImageBox = styled(Box, { label: 'ImageBox' })(({ theme }) => ({
  width: 100,
  height: 'auto',
  display: 'flex',
  flexShrink: 0,
  marginLeft: 0,
  padding: 10,
  border: '1px solid #EBEDF0',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
  },
}));

const SubscriptionSettings = () => {
  const [loading, setLoading] = useState(false);
  const { companyId, isNovaAdmin } = useUserData();
  const { company, isLoading, refetchCompany } = useCompanyQuery({ companyId });
  const [subscriptionSettings, setSubscriptionSettings] = useState<
    Partial<SubscriptionSettingsType>
  >({});

  useEffect(() => {
    if (company?.settings && !loading) {
      setSubscriptionSettings(company.settings?.subscriptionSettings);
    }
  }, [company?.settings]);

  const handleUpdate = async <T,>(key: string, value: T | string) => {
    try {
      setLoading(true);
      const newSubscriptionSettings = {
        ...subscriptionSettings,
        [key]: value,
        subscriptionsRemaining:
          key === 'totalSubscriptions'
            ? Number(value) - Number(subscriptionSettings?.subscriptionsUsed)
            : subscriptionSettings.subscriptionsRemaining,
      };
      setSubscriptionSettings(newSubscriptionSettings);

      const payload = {
        id: companyId,
        settings: {
          ...company?.settings,
          subscriptionSettings: newSubscriptionSettings,
        },
      };
      await CompanyAPI.updateCompanySettings(companyId, payload);
      refetchCompany();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h4" mb={2}>
        Subscription Settings
      </Typography>
      <HandleOperation isLoading={isLoading}>
        <$SettingsPaper>
          <Box sx={{ padding: 4 }}>
            <Grid
              container
              sx={{
                marginRight: 4,
              }}
            >
              <Grid item md={3} xs={12} sx={{ paddingRight: 4 }}>
                <Typography variant="h5">Subscription Plan</Typography>
              </Grid>
              <Grid item md={9} xs={12}>
                <Box sx={{ borderBottom: '1px solid #D9DBE9', paddingBottom: '24px' }}>
                  <Typography variant="h6">Subscription Type</Typography>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-customized-radios"
                      name="customized-radios"
                      value={subscriptionSettings.subscriptionPlan || ''}
                    >
                      {subscriptionSettings?.subscriptionPlanList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          disabled={!isNovaAdmin || loading}
                          value={item}
                          control={<CustomRadio />}
                          label={item}
                          checked={item === subscriptionSettings?.subscriptionPlan}
                          onChange={() => handleUpdate<string>('subscriptionPlan', item)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ borderBottom: '1px solid #D9DBE9', padding: '24px 0' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      InputAdornmentProps={{ position: 'start' }}
                      components={{
                        OpenPickerIcon: () => <CalendarLineIcon />,
                      }}
                      label="Start Date"
                      inputFormat="MMM D, YYYY"
                      disableMaskedInput
                      value={subscriptionSettings?.subscriptionStartDate || null}
                      onChange={handleUpdate.bind(null, 'subscriptionStartDate')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '180px', mr: 3, '& .MuiInputBase-input': { pl: 0 } }}
                        />
                      )}
                      disabled={!isNovaAdmin}
                    />
                    <DatePicker
                      disableMaskedInput
                      InputAdornmentProps={{ position: 'start' }}
                      components={{
                        OpenPickerIcon: () => <CalendarLineIcon />,
                      }}
                      label="End Date"
                      inputFormat="MMM D, YYYY"
                      value={subscriptionSettings.subscriptionEndDate || null}
                      minDate={subscriptionSettings.subscriptionStartDate}
                      onChange={handleUpdate.bind(null, 'subscriptionEndDate')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '180px', mr: 3, '& .MuiInputBase-input': { pl: 0 } }}
                        />
                      )}
                      disabled={!isNovaAdmin}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    type="number"
                    value={subscriptionSettings.totalSubscriptions || ''}
                    onBlur={(event) =>
                      handleUpdate<number>('totalSubscriptions', Number(event.target.value))
                    }
                    onChange={(event) =>
                      setSubscriptionSettings((prevState) => ({
                        ...prevState,
                        totalSubscriptions: Number(event.target.value),
                        subscriptionsRemaining:
                          Number(event.target.value) -
                          Number(subscriptionSettings?.subscriptionsUsed),
                      }))
                    }
                    variant="outlined"
                    label="Number of Subscriptions"
                    sx={{ width: '100px', '& .MuiFormLabel-root': { overflow: 'visible' } }}
                    fullWidth
                    disabled={!isNovaAdmin}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </$SettingsPaper>
        <$SettingsPaper>
          <Box sx={{ padding: 3 }}>
            <Grid container>
              <Grid item md={3} xs={12} sx={{ paddingRight: 4 }}>
                <Typography variant="h5">Subscription Usage and Controls</Typography>
              </Grid>
              <Grid container item md={9} xs={12}>
                <Box sx={{ borderBottom: '1px solid #D9DBE9', width: '100%' }}>
                  <Grid container item md={6}>
                    <Grid item md={12}>
                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #D9DBE9', pb: 2, mb: 2 }}
                      >
                        <Typography sx={{ fontSize: '16px' }}>Purchased</Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                          {subscriptionSettings.totalSubscriptions}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #D9DBE9', pb: 2, mb: 2 }}
                      >
                        <Typography sx={{ fontSize: '16px' }}>Used</Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                          {subscriptionSettings.subscriptionsUsed}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        sx={{ pb: 2, mb: 2 }}
                      >
                        <Typography sx={{ fontSize: '16px' }}>Remaining</Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color:
                              (subscriptionSettings?.subscriptionsRemaining || 0) < 0
                                ? '#A50E0E'
                                : 'inherit',
                          }}
                        >
                          {subscriptionSettings.subscriptionsRemaining}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  pt="24px"
                >
                  <Box>
                    <Typography variant="h6">Allow new users to sign up?</Typography>
                    <Typography>
                      This controls whether more users should be allowed to join this company.
                    </Typography>
                  </Box>
                  <Box>
                    <Switch
                      disableRipple
                      onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleUpdate<boolean>('allowUserSignUps', checked)
                      }
                      name="allowUserSignUps"
                      checked={subscriptionSettings.allowUserSignUps || false}
                      disabled={!isNovaAdmin}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  pt="24px"
                >
                  <Box>
                    <Typography variant="h6">
                      Allow users to invite others to BuildWise from within your organization
                    </Typography>
                  </Box>
                  <Box>
                    <Switch
                      disableRipple
                      onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleUpdate<boolean>('allowInviteUsersFlag', checked)
                      }
                      name="allowInviteUsersFlag"
                      checked={subscriptionSettings.allowInviteUsersFlag || false}
                      disabled={!isNovaAdmin}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </$SettingsPaper>
      </HandleOperation>
    </>
  );
};

function CustomRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioChecked />}
      icon={<RadioUnchecked />}
      {...props}
    />
  );
}

export default SubscriptionSettings;
