import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBase,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import useAddQuestionForm, { QuestionFormData } from 'pages/addQuestion/hooks/useAddQuestionForm';
import {
  catchErrorMessage,
  getVideoCover,
  isValidImage,
  isValidVideo,
  preventEnterPressed,
} from 'utility/helpers';
import QuestionsAPI from 'api/QuestionsAPI';
import { toast } from 'react-toastify';
import { $Dialog, $DialogContent, $DialogTitle } from '../addPostModal/AddPostModal';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import SpyFillIcon from 'remixicon-react/SpyFillIcon';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import Suggestions from './Suggestions';
import FileInput from '../../common/fileInput/FileInput';
import { $FormLabel, $LightTooltip } from '../../common/stylings';
import { styled } from '@mui/material/styles';

const TitleInput = styled(InputBase, { label: 'TitleInput' })({
  fontSize: 20,
  fontWeight: 700,
});

type AddQuestionModalTypes = {
  open: boolean;
  setOpen: any;
};

const ROLES = ['Superintendents', 'Project Engineers (PE)', 'Project Managers (PM)'];

const AddQuestionModal: React.FC<AddQuestionModalTypes> = ({ open, setOpen }) => {
  const fileRef = useRef<any>(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [videoCover, setVideoCover] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, register, fileValue, reset, getValues } = useAddQuestionForm();
  const { ref: registerFileRef, ...fileInputProps } = useMemo(() => register('file'), [register]);

  const closeHandle = () => {
    setOpen(false);
    reset();
  };

  const backdropClick = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const updateSearch = (value) => {
    setSearchKeyword(value.trim());
  };
  const debounceFn = useCallback(debounce(updateSearch, 300), []);

  useEffect(() => {
    if (fileValue?.[0] && isValidVideo(fileValue?.[0].type)) {
      getVideoCover(fileValue[0])
        .then((value) => setVideoCover(value))
        .catch(() => setVideoCover(null));
    } else {
      setVideoCover(null);
    }
  }, [fileValue]);

  const onSubmit = async (values: QuestionFormData) => {
    try {
      setLoading(true);
      const payload: any = {
        questionTitle: values.title,
        questionDescription: values.description,
        isAnonymous: !!values.anonymous,
      };

      const _fileValue = values?.file?.[0];
      let thumbnail;
      if (isValidImage(_fileValue?.type)) {
        thumbnail = _fileValue;
      } else if (isValidVideo(_fileValue?.type) && videoCover) {
        const file = new File([videoCover], _fileValue?.name + '.jpg', {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        });
        thumbnail = file;
      }

      if (values.mentions) {
        payload.mentions = values.mentions?.map((m) => ({
          displayName: m.display,
          userName: m.id,
        }));
      }

      await QuestionsAPI.addQuestion(payload, _fileValue, thumbnail);
      toast.success(
        'Your question will now be routed to the experts and you will be notified with an answer soon!',
      );

      closeHandle();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  const onSaveAnonymously = () => {
    const values = getValues();
    if (!!values.title) {
      onSubmit({ ...values, anonymous: true });
    }
  };

  const handleFileSelect = () => {
    fileRef.current?.click();
  };

  const Actions = useMemo(
    () => (
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="outlined"
          startIcon={<SpyFillIcon size={18} />}
          disabled={loading}
          onClick={onSaveAnonymously}
          sx={{ paddingLeft: 3, paddingRight: 3, marginRight: 1 }}
        >
          Ask Anonymously
        </Button>
        <Button disabled={loading} onClick={triggerSubmit} variant="contained">
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Ask'}
        </Button>
      </DialogActions>
    ),
    [triggerSubmit, onSaveAnonymously, loading],
  );

  return (
    <$Dialog onClose={backdropClick} open={open} fullWidth>
      <$DialogTitle sx={{ fontSize: 24, display: 'flex', alignItems: 'center' }}>
        Submit a Question
        <$LightTooltip
          componentsProps={{ tooltip: { sx: { padding: 2, minWidth: 630 } } }}
          title={
            <Box>
              <Typography sx={{ fontWeight: 700 }}>
                Experts in your company will answer questions.
              </Typography>
              <Typography>
                Let BuildWise contact the most suitable and available subject mattter experts in
                your company and get you the answers quickly.
              </Typography>
            </Box>
          }
        >
          <IconButton
            className="resize"
            sx={{ padding: 0, marginLeft: 1, marginTop: 0.5, boxShadow: 'none' }}
          >
            <InformationLineIcon />
          </IconButton>
        </$LightTooltip>
        <IconButton
          aria-label="close"
          onClick={closeHandle}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.custom.lightBlack,
          }}
        >
          <CloseLineIcon size={32} />
        </IconButton>
      </$DialogTitle>
      <$DialogContent sx={{ minHeight: 300 }} dividers>
        <Controller
          control={control}
          rules={{ required: 'Please enter question title' }}
          render={({ field, fieldState }) => (
            <FormControl sx={{ minHeight: 100, paddingBottom: 5 }} fullWidth>
              <TitleInput
                multiline
                autoFocus
                maxRows={10}
                inputProps={{ maxLength: 250 }}
                error={!!fieldState.error}
                onKeyDown={preventEnterPressed}
                fullWidth
                placeholder="Type your question here"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  debounceFn(e.target.value);
                }}
                sx={{ fontSize: 20, fontWeight: 400 }}
                value={!!field.value.trim() ? field.value : ''}
              />
              <FormHelperText
                sx={{ marginLeft: 0, bottom: 0, right: 0, fontSize: 12, position: 'absolute' }}
              >
                {field.value.length}/250
              </FormHelperText>
              <FormHelperText sx={{ marginLeft: 0, bottom: 0, position: 'absolute' }} error>
                {fieldState.error?.message}
              </FormHelperText>
            </FormControl>
          )}
          name="title"
          defaultValue=""
        />
        <Suggestions searchKeyword={searchKeyword} onClick={closeHandle} />

        <FileInput label="Add File" fileValue={fileValue} onClick={handleFileSelect} />

        {/*HIDDEN FILE INPUT*/}
        <input
          {...fileInputProps}
          key={open ? 'open-file-key' : 'hide-file-key'}
          type="file"
          ref={(e) => {
            registerFileRef(e);
            fileRef.current = e;
          }}
          hidden
        />

        <Box>
          <$FormLabel sx={{ marginTop: 3 }}>This question is for: </$FormLabel>
          {ROLES?.map((role) => (
            <Box key={role} sx={{ borderBottom: `1px solid #D9DBE9` }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: '8px 4px', marginLeft: 1 }}
                    value={role}
                    {...register('suitableRoles')}
                  />
                }
                label={role}
              />
            </Box>
          ))}
        </Box>
      </$DialogContent>
      {Actions}
    </$Dialog>
  );
};

export default AddQuestionModal;
