import { useMemo, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import Edit2LineIcon from 'remixicon-react/Edit2LineIcon';
import IconButton from '@mui/material/IconButton';
import DataTable from 'pages/admin/userAdministration/components/DataTable';
import EditUserModal from 'pages/admin/userAdministration/EditUserModal';
import HandleOperation from 'components/utils/HandleOperation';
import { useUsersQuery } from 'pages/people/hooks/usePeopleQuery';

const $SettingsPaper = styled(Paper, { label: 'SettingsPaper' })(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 16,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
  marginBottom: 24,
  overflow: 'hidden',
  minHeight: '400px',
}));

export type UserData = {
  canCreatePlaylistFlag: boolean;
  canEndorseFlag: boolean;
  canInviteOthersFlag: boolean;
  canMovePostsToTopFlag: boolean;
  canSuggestRevisiontToPostsFlag: boolean;
  companyId: string;
  department?: string;
  firstName: string;
  id: number;
  jobTitle: string;
  primaryCompanyId: string;
  primaryCompanyName: string;
  lastActiveOn?: string;
  lastName: string;
  office?: string;
  status: string;
  userName: string;
  userRole: string;
};

const BuildWiseUsers = () => {
  const { users, isLoading } = useUsersQuery();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<UserData | undefined>();

  const handleEditClick = (user: UserData) => {
    setShowEditModal(true);
    setSelectedRow(user);
  };

  const usersData: UserData[] = useMemo(() => {
    const data: UserData[] = [];
    let i = 0;
    users.forEach((user) => {
      const userData: UserData = {
        id: ++i,
        companyId: user.companyId,
        firstName: user.firstname,
        lastName: user.lastname,
        userName: user.username,
        jobTitle: user.title,
        primaryCompanyId: user?.primaryCompanyId || '',
        primaryCompanyName: user?.primaryCompanyName || '',
        userRole: user.roles.length > 0 ? user.roles[0].roleName : '',
        status: user.active ? 'Active' : 'Inactive',
        canEndorseFlag: user.canEndorseFlag || false,
        canSuggestRevisiontToPostsFlag: user.canSuggestRevisiontToPostsFlag || false,
        canMovePostsToTopFlag: user.canMovePostsToTopFlag || false,
        canCreatePlaylistFlag: user.canCreatePlaylistFlag || false,
        canInviteOthersFlag: user.canInviteOthersFlag || false,
      };
      data.push(userData);
    });

    return data;
  }, [users]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'actions',
        headerName: '',
        renderCell: (params) => (
          <IconButton sx={{ margin: '0 auto' }} onClick={() => handleEditClick(params.row)}>
            <Edit2LineIcon />
          </IconButton>
        ),
      },
      { field: 'id', headerName: 'ID', width: 30, hide: true, disableColumnMenu: true },
      {
        field: 'companyId',
        headerName: 'Company ID',
        width: 150,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 150,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 150,
        sortable: true,
        disableColumnMenu: true,
      },
      { field: 'userName', headerName: 'Email', width: 150, disableColumnMenu: true },
      { field: 'jobTitle', headerName: 'Title', width: 150, disableColumnMenu: true },
      {
        field: 'userRole',
        headerName: 'Role',
        width: 200,
        type: 'singleSelect',
        valueOptions: ['ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_USER'],
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        type: 'singleSelect',
        valueOptions: ['Active', 'Inactive'],
        disableColumnMenu: true,
      },
    ],
    [usersData],
  );

  return (
    <$SettingsPaper>
      <Box sx={{ padding: 4 }}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Typography sx={{ fontSize: 19, fontWeight: 600, mb: 2 }}>Users</Typography>
            <HandleOperation isLoading={isLoading}>
              <DataTable<UserData>
                columns={columns}
                rows={usersData}
                pageSize={10}
                height={630}
                checkboxSelection={false}
              />
            </HandleOperation>
          </Grid>
        </Grid>
      </Box>

      {showEditModal && selectedRow && (
        <EditUserModal
          open={showEditModal}
          setOpen={setShowEditModal}
          user={selectedRow}
          people={users}
        />
      )}
    </$SettingsPaper>
  );
};

export default BuildWiseUsers;
