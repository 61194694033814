import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { Link } from 'react-router-dom';
import { VIEW_ARTICLE } from '../../../constants/routes';
import { styled } from '@mui/material/styles';
import { useArticlesQuery } from 'pages/knowledge/hooks/useArticlesQuery';

type SuggestionsPropTypes = {
  searchKeyword: string;
  onClick: any;
};

const $SuggestionsWrapper = styled(Box, { label: 'SuggestionsWrapper' })(({ theme }) => ({
  width: '100%',
  background: theme.palette.custom.background,
  padding: 10,
  borderRadius: 8,
  paddingBottom: 0,
  marginTop: -16,
  marginBottom: 16,
}));

const $SuggestionsHeader = styled(Box, { label: 'SuggestionsHeader' })(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 8,
}));

const $SuggestionsList = styled(Box, { label: 'SuggestionsList' })(({ theme }) => ({
  width: '100%',
  maxHeight: 300,
  overflow: 'auto',
  '& > a': {
    display: 'block',
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: `1px solid ${theme.palette.custom.line}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
}));

const Suggestions: React.FC<SuggestionsPropTypes> = ({ searchKeyword, onClick }) => {
  const [closeSuggestions, setCloseSuggestions] = useState(false);
  const { searchedData } = useArticlesQuery({ searchKeyword, searchOnly: true });

  const articles = useMemo(() => {
    return searchedData?.length ? searchedData.slice(0, 4) : [];
  }, [searchedData]);

  if (closeSuggestions || !articles?.length) return null;

  return (
    <$SuggestionsWrapper>
      <$SuggestionsHeader>
        <Typography sx={{ fontWeight: 700, fontSize: 16 }}>Suggested Posts</Typography>
        <IconButton
          onClick={() => setCloseSuggestions(true)}
          sx={{
            padding: 0,
            color: (theme) => theme.palette.custom.lightBlack,
          }}
        >
          <CloseLineIcon size={24} />
        </IconButton>
      </$SuggestionsHeader>

      <$SuggestionsList>
        {articles?.map((article) => (
          <Box
            key={article.id}
            component={Link}
            onClick={onClick}
            to={VIEW_ARTICLE.replace(':articleId', article.id)}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: '16.8px' }}>
              {article.title}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: 13, lineHeight: '16.8px', color: '#84819A' }}
            >
              {article.userNameTitleToDisplay} • {article.userNameExpYearsToDisplay}
            </Typography>
          </Box>
        ))}
      </$SuggestionsList>
    </$SuggestionsWrapper>
  );
};

export default Suggestions;
