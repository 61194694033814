import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HOME } from 'constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_TABS } from '../constants';
import { useMemo, useRef } from 'react';
import useUserData from 'hooks/useUserData';
import { handlePageScrollPosition } from 'utility/helpers';

function PostsTabs() {
  const navigate = useNavigate();
  const { state, hash } = useLocation();
  const { isNovaAdmin } = useUserData();
  const staticPostsTabsPositionRef = useRef<HTMLDivElement | null>(null);
  const postsTabsRef = useRef<HTMLDivElement | null>(null);
  const tab = decodeURIComponent(hash).replace('#', '') || HOME_TABS[0].value;
  const tabs = useMemo(
    () => HOME_TABS.filter((_tab) => isNovaAdmin || _tab.value !== 'for-you'),
    [isNovaAdmin],
  );

  const handleTabClick = (_tab: string) => {
    const isHomeTabsStickyPositioned =
      postsTabsRef.current?.getBoundingClientRect().top &&
      postsTabsRef.current?.getBoundingClientRect().top === 79;

    if (tab === _tab && staticPostsTabsPositionRef.current && isHomeTabsStickyPositioned) {
      handlePageScrollPosition(_tab, 0, 0, 'reset');
      window.scrollTo({
        behavior: 'smooth',
        top:
          staticPostsTabsPositionRef.current.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          70,
      });
    }
  };

  return (
    <>
      <Box ref={staticPostsTabsPositionRef} />
      <StickyContainer id="home-page-tabs" ref={postsTabsRef}>
        <Tabs
          value={tab}
          onChange={(_: unknown, value: string) => {
            navigate(`${HOME}${value !== 'all-posts' ? `#${value}` : ''}`, {
              state,
              replace: true,
            });
          }}
          sx={{ width: '100%' }}
        >
          {tabs.map((_tab) => (
            <Tab key={_tab.value} {..._tab} onClick={() => handleTabClick(_tab.value)} />
          ))}
        </Tabs>
      </StickyContainer>
    </>
  );
}

const StickyContainer = styled(Box, { label: 'StickyContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 79,
  padding: '16px 0 12px',
  backgroundColor: theme.palette.custom.background,
  zIndex: 2,
  width: '100%',
}));

export default PostsTabs;
