import { FC } from 'react';
import { Typography, Dialog, DialogContent, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Link } from 'react-router-dom';
import { USER_PROFILE } from 'constants/routes';
import Avatar from 'components/common/avatar/Avatar';
import { UserType } from 'hooks/useUserData';
import Dot from 'components/common/dot/Dot';
import useCompanyQueryData from 'hooks/useCompanyQueryData';

type ViewUsersModalProps = {
  open: boolean;
  users: UserType[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewUsersModal: FC<ViewUsersModalProps> = ({ open, users, setOpen }) => {
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="xs"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>New Users</Title>
      </CustomDialogTitle>
      <DialogContent dividers>
        {users?.map((user, i) => (
          <UserBox key={user?.id || i}>
            <Box marginRight={1.5}>
              <Avatar
                src={user?.profileImageURL}
                size={44}
                name={user?.userNameToDisplay}
                to={USER_PROFILE.replace(':userName', user?.username)}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography>
                <UserLink to={USER_PROFILE.replace(':userName', user?.username)}>
                  {user?.userNameToDisplay}
                </UserLink>
              </Typography>
              <Box>
                <Typography variant="caption">{user?.title}</Typography>
                {shouldDisplayCompanyOnUserCards && user?.primaryCompanyName && (
                  <>
                    <Dot />
                    <Typography variant="caption">{user?.primaryCompanyName}</Typography>
                  </>
                )}
              </Box>
            </Box>
          </UserBox>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

const UserBox = styled(Box, { label: 'UserBox' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.custom.platinum}`,
  padding: '12px 0',
  '&:last-child': {
    border: 'none',
  },
}));

const UserLink = styled(Link, { label: 'UserLink' })(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.custom.activeTitle,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default ViewUsersModal;
