import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container/Container';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import { Button, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import useUserData from 'hooks/useUserData';
import Search from 'pages/knowledge/components/SearchComponent';
import { CHAT, HOME, SEARCH } from 'constants/routes';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import useLayoutStore from 'store/useLayoutStore';
import UserMenu from './UserMenu';
import NotificationBox from './NotificationBox';
import { handlePageScrollPosition } from 'utility/helpers';
import ArrowDownSFillIcon from 'remixicon-react/ArrowDownSFillIcon';
import SelectCompanyModal from 'components/main/SelectCompanyModal';
import { shallow } from 'zustand/shallow';
import useAuthStore from 'store/useAuthStore';
import { useQueryClient } from 'react-query';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

const $AppBar = styled(AppBar, { label: 'AppBar' })(() => ({
  background: 'white',
  zIndex: 999,
  boxShadow: 'none',
  position: 'sticky',
  top: 0,
}));

const $ImageBox = styled(Box, { label: 'ImageBox' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: theme.drawerWidth,
  padding: '0 24px',
  marginLeft: '-32px',
}));

const CompanyName = styled(Typography, { label: 'CompanyName' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  textTransform: 'capitalize',
  color: theme.palette.custom.body,
  lineHeight: '27px',
  maxWidth: 300,
  textAlign: 'center',
}));

const Header = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hash, pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const [invalidLogo, setInvalidLogo] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState(
    decodeURIComponent(searchParams?.get('q') || ''),
  );
  const setActiveSearch = useLayoutStore((state) => state.setActiveSearch);
  const [openSwitchWorkspaceModal, setOpenSwitchWorkspaceModal] = useState(false);
  const [fetchUserDetails, setFetchUserDetails] = useState(false);
  const [fetchCompaniesDetails, setFetchCompanyDetails] = useState(false);
  const [userAuthDetails, setUser] = useAuthStore((state) => [state.user, state.setUser], shallow);
  const isNovaAdminOrAssociatedUser = useMemo(
    () => !!(userAuthDetails?.isNovaAdmin || userAuthDetails?.isAssociatedWithOtherCompanies),
    [userAuthDetails?.isNovaAdmin, userAuthDetails?.isAssociatedWithOtherCompanies],
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const {
    companyId: loggedInCompanyId,
    company,
    companyQueryInfo,
  } = useUserData({
    shouldFetchUserDetails: !isNovaAdminOrAssociatedUser || fetchUserDetails,
    shouldFetchCompanyDetails: !isNovaAdminOrAssociatedUser || fetchCompaniesDetails,
    selectedCompanyId: selectedCompanyId || userAuthDetails?.companyId,
  });

  useEffect(() => {
    setSearchKeyword(decodeURIComponent(searchParams?.get('q') || ''));
  }, [searchParams, pathname]);

  const tab = useMemo(() => {
    const getValidTab = (tab: string) =>
      ['knowledge', 'people', 'users', 'projects', 'stakeholders', 'insights'].includes(tab)
        ? tab
        : 'knowledge';

    if (hash) {
      return getValidTab(decodeURIComponent(hash).replace('#', ''));
    }

    const splitPath = pathname.split('/');
    if (splitPath.length > 0 && splitPath[2] && !pathname.includes('search')) {
      return getValidTab(splitPath[2]);
    }
  }, [pathname, hash]);

  useEffect(() => {
    if (searchKeyword && searchKeyword !== searchParams.get('q')) {
      handlePageScrollPosition('search-tabs', 0, 0, 'reset');

      let query = `?q=${encodeURIComponent(searchKeyword)}`;

      if (searchParams.get('details')) {
        query += '&details=true';
      }

      if (tab && tab !== 'knowledge') {
        if (tab.toLowerCase() === 'people') {
          query += '#users';
        } else if (tab.toLowerCase() === 'insights') {
          query += '#projects';
        } else {
          query += `#${tab}`;
        }
      }
      navigate(SEARCH + query, {
        replace: true,
        state: { defaultTab: pathname !== SEARCH ? pathname : state?.defaultTab },
      });
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (searchKeyword) {
      setActiveSearch(true);
    } else {
      setActiveSearch(false);
    }
  }, [searchKeyword, setActiveSearch]);

  const onClearSearch = () => {
    if (searchKeyword && pathname === SEARCH) {
      navigate(state?.defaultTab || -1, { state: { fromSearch: true } });
    }
  };

  const handleSelectCompany = useCallback(
    async (companyId: string) => {
      if (companyId !== loggedInCompanyId) {
        queryClient.clear();
        if (userAuthDetails?.isNovaAdmin) {
          setUser({ ...userAuthDetails, companyId, selectedCompanyId: companyId });
          setFetchCompanyDetails(true);
          setFetchUserDetails(true);
          navigate(HOME);
        } else {
          setSelectedCompanyId(companyId);
          setFetchCompanyDetails(true);
          setTimeout(() => {
            companyQueryInfo.refetch().then(() => {
              setUser({ ...userAuthDetails, companyId, selectedCompanyId: companyId });
              setFetchUserDetails(true);
              navigate(HOME);
            });
          }, 1);
        }
      }
      setOpenSwitchWorkspaceModal(false);
    },
    [userAuthDetails],
  );

  return (
    <$AppBar>
      <Container sx={{ paddingX: { sm: 6 }, py: 1, maxWidth: '1440px' }} maxWidth={false}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }} disableGutters>
          <Box display="flex" alignItems="center">
            <$ImageBox>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  ...(isNovaAdminOrAssociatedUser && {
                    p: 1,
                    borderRadius: '8px',
                    cursor: 'pointer',
                    transition: 'all 0.5s',
                    width: '100%',
                  }),
                }}
              >
                <Link to={HOME} title={company?.name}>
                  {company?.companyLogoDisplay &&
                  company?.companyLogoDisplay !== 'NAME' &&
                  company?.companyLogoURL &&
                  !invalidLogo ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {(!company?.companyLogoDisplay ||
                        ['BOTH', 'LOGO'].includes(company?.companyLogoDisplay)) && (
                        <Box
                          component="img"
                          src={company?.companyLogoURL}
                          onError={() => setInvalidLogo(true)}
                          maxWidth={200}
                          maxHeight={40}
                          onLoad={() => setLogoLoaded(true)}
                        />
                      )}

                      {company?.companyLogoDisplay === 'BOTH' && logoLoaded && (
                        <Box sx={{ marginLeft: '5px' }}>
                          <CompanyName>{company?.name}</CompanyName>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <CompanyName>{company?.name}</CompanyName>
                  )}
                </Link>
                {isNovaAdminOrAssociatedUser && (
                  <Tooltip
                    placement="bottom"
                    title={<Typography fontSize={12}>Switch Workspace</Typography>}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (isNovaAdminOrAssociatedUser) {
                          setOpenSwitchWorkspaceModal(true);
                        }
                      }}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.custom.platinum,
                        ml: 1,
                        mt: -0.5,
                      })}
                    >
                      <ArrowDownSFillIcon color={theme.palette.custom.navy} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </$ImageBox>
          </Box>

          {(!pathname.startsWith('/company-admin') || pathname === '/company-admin/dashboards') &&
            !pathname.startsWith('/buildwise-admin') &&
            !pathname.startsWith('/buildwise-gpt') && (
              <Box width="100%" display="flex" justifyContent="flex-end">
                {!pathname.startsWith('/admin/chat') && (
                  <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box width="80%" mr={1}>
                      <Search
                        filled
                        value={searchKeyword}
                        placeholder="Search..."
                        setValue={setSearchKeyword}
                        fromSearch={state?.fromSearch}
                        onClearSearch={onClearSearch}
                        triggerOnEnter
                      />
                    </Box>
                  </Box>
                )}
                <Box display="flex" alignItems="center">
                  {pathname === CHAT && (
                    <Button
                      startIcon={<CloseLineIcon />}
                      variant="contained"
                      onClick={() => navigate(-1)}
                      sx={(theme) => ({
                        mr: 2,
                        backgroundColor: theme.palette.custom.silver,
                        color: theme.palette.custom.black,
                        '&:hover': {
                          backgroundColor: theme.palette.custom.silver,
                        },
                      })}
                    >
                      Close
                    </Button>
                  )}
                  <Collapse in={pathname !== CHAT} orientation="horizontal">
                    <Box display="flex" alignItems="center">
                      <Box sx={{ mr: 2 }}>
                        <NotificationBox
                          shouldFetchNotifications={
                            !selectedCompanyId || selectedCompanyId === userAuthDetails?.companyId
                          }
                        />
                      </Box>
                      <UserMenu
                        setOpenSwitchWorkspaceModal={setOpenSwitchWorkspaceModal}
                        selectedCompanyId={selectedCompanyId}
                      />
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            )}
          {/* {(!pathname.startsWith('/company-admin') || pathname === '/company-admin/dashboards') &&
            !pathname.startsWith('/buildwise-admin') &&
            !pathname.startsWith('/buildwise-gpt') && (
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                  <Box width="80%" mr={1}>
                    <Search
                      filled
                      value={searchKeyword}
                      placeholder="Search..."
                      setValue={setSearchKeyword}
                      fromSearch={state?.fromSearch}
                      onClearSearch={onClearSearch}
                      triggerOnEnter
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box sx={{ mr: 2 }}>
                    <NotificationBox
                      shouldFetchNotifications={
                        !selectedCompanyId || selectedCompanyId === userAuthDetails?.companyId
                      }
                    />
                  </Box>
                  <UserMenu
                    setOpenSwitchWorkspaceModal={setOpenSwitchWorkspaceModal}
                    selectedCompanyId={selectedCompanyId}
                  />
                </Box>
              </Box>
            )} */}
        </Toolbar>
      </Container>

      {openSwitchWorkspaceModal && (
        <SelectCompanyModal
          selectCompanyModal={openSwitchWorkspaceModal}
          handleSelectCompany={handleSelectCompany}
          onClose={() => setOpenSwitchWorkspaceModal(false)}
        />
      )}
    </$AppBar>
  );
};

export default Header;
