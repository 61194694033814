import { Skeleton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type TinyMceEditorSkeltonProps = {
  source?: string | undefined;
};

function TinyMceEditorSkelton({ source }: TinyMceEditorSkeltonProps) {
  return (
    <$SkeltonContainer>
      {!source && (
        <$EditorToolBarSkelton>
          <Skeleton variant="rectangular" width={150} height={25} sx={{ mr: 2, borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={300} height={25} sx={{ mr: 2, borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={300} height={25} sx={{ mr: 2, borderRadius: 1 }} />
        </$EditorToolBarSkelton>
      )}
    </$SkeltonContainer>
  );
}

export const $SkeltonContainer = styled(Box, { label: 'SkeltonContainer' })(({ theme }) => ({
  border: `2px solid ${theme.palette.custom.softPeach}`,
  width: '100%',
  height: '100%',
  borderRadius: 16,
  position: 'absolute',
  top: '0',
  left: 0,
  zIndex: 9,
}));

export const $EditorToolBarSkelton = styled(Box, { label: 'EditorToolBarSkelton' })({
  height: 50,
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07)',
});

export default TinyMceEditorSkelton;
