import { ConfigsByTypeResponse } from 'api/CompanyAPI';
import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { adminKeys } from './query-keys';
import { ConfigType } from 'config/config';
import AdminAPI from '../apis/AdminAPI';
import useUserData from 'hooks/useUserData';

function useAdminConfigsByTypeQuery({ configType }: { configType: string }) {
  const queryClient = useQueryClient();
  const { isNovaAdmin } = useUserData();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigsByTypeResponse>, Error>({
    queryKey: adminKeys.getAdminConfigsByType(configType),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
    enabled: !!configType && isNovaAdmin,
    queryFn: () => AdminAPI.getAdminConfigsByType(configType),
  });

  const updateConfigsCache = ({ updatedConfig }: { updatedConfig: ConfigType }) => {
    const queryStatus = queryClient.getQueryState(adminKeys.getAdminConfigsByType(configType));
    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigsByTypeResponse, Error> | undefined>(
        adminKeys.getAdminConfigsByType(configType),
        (old) => {
          if (old) {
            const updatedConfigs = [...(old?.data?.configs || [])];
            const index = updatedConfigs.findIndex(
              (config) => config.configName === updatedConfig.configName,
            );

            if (index > -1) {
              updatedConfigs[index] = updatedConfig;
            }

            return {
              ...old,
              data: {
                configs: updatedConfigs,
              },
            };
          }
          return old;
        },
      );
    }
  };

  return {
    configs: entriesData?.data?.configs || [],
    updateConfigsCache,
    ...rest,
  };
}

export default useAdminConfigsByTypeQuery;
