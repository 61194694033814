import { Box, Button as MuiButton } from '@mui/material';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import { styled, useTheme } from '@mui/material/styles';
import { ArticleType } from '../hooks/useArticlesQuery';
import RenderPlayistArticles from './RenderPlayistArticles';
import { UseArticleReducerActions } from '../hooks/useArticle';
import { USER_PROFILE } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import Avatar from 'components/common/avatar/Avatar';
import AddPostModal from 'components/modals/addPostModal/AddPostModal';
import { useState } from 'react';
import { updateArticleDetailsCache } from 'pages/article/hooks/useArticleDetailsQuery';

type RenderPlaylistsProps = {
  article: ArticleType;
  dispatch: (value: UseArticleReducerActions) => void;
};

function RenderPlaylists({ article, dispatch }: RenderPlaylistsProps) {
  const { user, companyId } = useUserData();
  const theme = useTheme();
  const [openAddPostModal, setOpenAddPostModal] = useState(false);

  return (
    <>
      {article?.playListArticles?.length === 0 && (
        <NoPostsContainer>
          <FileListLineIcon size={120} color={theme.palette.custom.jetGrey} />
          <NoPostsDescription>
            Start creating your collection by adding a few posts.
          </NoPostsDescription>
        </NoPostsContainer>
      )}

      {article?.playListArticles?.length > 0 && (
        <RenderPlayistArticles posts={article?.playListArticles} />
      )}

      <Box px={3}>
        <ButtonsContainer>
          <Avatar
            src={user?.profileImageURL}
            size={38}
            name={user?.userNameToDisplay}
            to={USER_PROFILE.replace(':userName', user?.username || '')}
          />
          <Button disableRipple onClick={() => setOpenAddPostModal(true)}>
            Add a new post to this collection
          </Button>
        </ButtonsContainer>
      </Box>

      {openAddPostModal && (
        <AddPostModal
          open={openAddPostModal}
          setOpen={setOpenAddPostModal}
          selectedFeedFilters={[]}
          defaultExpanded
          defaultPlaylists={[{ label: article.title, value: article.id }]}
          onSaveCallback={(newArticle: ArticleType) => {
            if (newArticle.playListArticleIds.includes(article.id)) {
              updateArticleDetailsCache(companyId, {
                ...article,
                playListArticleIds: [...(article?.playListArticleIds || []), newArticle.id],
                playListArticles: [...(article?.playListArticles || []), newArticle],
              });
            }
          }}
        />
      )}
    </>
  );
}

const NoPostsContainer = styled(Box, { label: 'NoPostsContainer' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 16,
  '&:hover': {
    transition: '0.5s',
    backgroundColor: theme.palette.custom.whiteSmoke,
  },
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.custom.white,
  padding: '16px 0',
  borderRadius: 8,
  marginTop: 16,
  marginBottom: 16,
}));

const Button = styled(MuiButton, { label: 'Button' })(({ theme }) => ({
  borderRadius: 33,
  color: theme.palette.custom.mistBlue,
  fontSize: 14,
  flex: 1,
  justifyContent: 'flex-start',
  marginLeft: 8,
  transition: 'background-color 0.8s',
  border: `1px solid ${theme.palette.custom.line}`,
  '&:hover': {
    backgroundColor: theme.palette.custom.blueChalk,
  },
}));

const NoPostsDescription = styled(Box, { label: 'NoPostsDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default RenderPlaylists;
