import { Dialog, Box, Typography, DialogContent, IconButton, DialogTitle } from '@mui/material';
import { RotateDirection, SpecialZoomLevel, Viewer, ZoomEvent } from '@react-pdf-viewer/core';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import { styled, useTheme } from '@mui/material/styles';
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  WheelEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FileListFillIcon from 'remixicon-react/FileListFillIcon';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon';
import DownloadFillIcon from 'remixicon-react/DownloadFillIcon';
import PrinterFillIcon from 'remixicon-react/PrinterFillIcon';
import {
  zoomPlugin,
  RenderZoomOutProps,
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomProps,
} from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin, RenderNumberOfPagesProps } from '@react-pdf-viewer/page-navigation';
import { printPlugin, RenderPrintProps } from '@react-pdf-viewer/print';
import { thumbnailPlugin, RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';
import { rotatePlugin, RenderRotateProps } from '@react-pdf-viewer/rotate';
import ClockwiseLineIcon from 'remixicon-react/ClockwiseLineIcon';
import { ReactComponent as FitToPageIcon } from 'assets/icons/FitToPage.svg';
import { ReactComponent as FitToWidthIcon } from 'assets/icons/FitToWidth.svg';
import { RenderHighlightsProps, searchPlugin } from '@react-pdf-viewer/search';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import PdfSearch from './PdfSearch';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import axios from 'axios';
import { formatFileSize } from 'utility/helpers';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

const DEFAULT_PDF_ZOOM_SCALE = 1.5;

type PdfViewerProps = {
  file: {
    name: string;
    size?: number;
    url: string;
    initialPage?: number;
    pageContent?: string[];
  };
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function PdfViewer({ file, open, setOpen }: PdfViewerProps) {
  const theme = useTheme();
  // DevNote: Reason why we are using ref instead of state is that, Search state in @react-pdf-viewer is getting reset when mutate state in Parent component i.e this component. See https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1705
  const zoomStateRef = useRef<{
    isCtrlPressed: boolean;
    specificZoomValue: SpecialZoomLevel;
    currentZoomValue: number;
  }>({
    isCtrlPressed: false,
    specificZoomValue: SpecialZoomLevel.PageFit,
    currentZoomValue: DEFAULT_PDF_ZOOM_SCALE,
  });
  const [searchParams] = useSearchParams();
  const searchKeyword = useMemo(
    () => decodeURIComponent(searchParams.get('q') || ''),
    [searchParams],
  );
  const [showSearch, setShowSearch] = useState(!!searchKeyword);
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut, Zoom, zoomTo } = zoomPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  const printPluginInstance = printPlugin({
    renderProgressBar: () => <></>,
  });
  const { Print } = printPluginInstance;
  const thumbnailPluginInstance = thumbnailPlugin({
    thumbnailWidth: 150,
  });
  const { Thumbnails } = thumbnailPluginInstance;
  const rotatePluginInstance = rotatePlugin();
  const { Rotate } = rotatePluginInstance;
  const pdfSearchKeywords = useMemo(
    () =>
      _.filter(
        _.uniq([
          searchKeyword,
          ...searchKeyword.split(' '),
          // ...(file?.pageContent || []).map((chunk) => popFirstThreeWords(chunk)),
        ]),
        _.length,
      ),
    [searchKeyword],
  );
  const searchPluginInstance = searchPlugin({
    enableShortcuts: true,
    ...(searchKeyword && {
      keyword: pdfSearchKeywords,
    }),
    renderHighlights: (renderProps: RenderHighlightsProps) => (
      <>
        {renderProps.highlightAreas.map((area, index) => (
          <Box
            key={`${area.pageIndex}-${index}`}
            style={{
              ...renderProps.getCssProperties(area),
              position: 'absolute',
              left: 0,
              right: 0,
              backgroundColor: 'rgb(255 193 7 / 30%)',
              width: '100%',
              height: '40px',
              opacity: '0.5',
            }}
            className="rpv-search__highlight"
          ></Box>
        ))}
      </>
    ),
  });
  const { clearHighlights } = searchPluginInstance;

  useEffect(() => {
    const tawkWidget = document.querySelector('.widget-visible') as HTMLDivElement;

    if (tawkWidget) {
      tawkWidget.style.setProperty('z-index', '1', 'important');
    }

    return () => {
      if (tawkWidget) {
        tawkWidget.removeAttribute('style');
      }
    };
  }, []);

  const handleDocumentLoad = () => {
    setDocumentLoaded(true);
  };

  const downloadFile = async () => {
    try {
      const { data: fileBlob } = await axios(file.url, { responseType: 'blob' });
      const fileURL = URL.createObjectURL(fileBlob);
      let anchorEle = document.createElement('a');
      anchorEle.href = fileURL;
      anchorEle.download = file.name;
      anchorEle.click();
    } catch (error) {
      console.warn('PDF_DOWNLOAD_ERROR', error);
    }
  };

  const handleZoom = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      zoomStateRef.current.isCtrlPressed = true;
      if (event.key === '+' || event.key === '=') {
        zoomTo(zoomStateRef.current.currentZoomValue + 0.2);
        event.stopPropagation();
        event.preventDefault();
      }

      if (event.key === '-' || event.key === '_') {
        zoomTo(zoomStateRef.current.currentZoomValue - 0.2);
        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  const handleOnZoom = (event: ZoomEvent) => {
    zoomStateRef.current.currentZoomValue = event.scale;
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (zoomStateRef.current.isCtrlPressed && !(event.metaKey || event.ctrlKey)) {
      zoomStateRef.current.isCtrlPressed = false;
    }
  };

  const handleScroll = (event: WheelEvent) => {
    if (zoomStateRef.current.isCtrlPressed) {
      if (event.deltaY > 0) {
        zoomTo(zoomStateRef.current.currentZoomValue - 0.1);
        event.stopPropagation();
        event.preventDefault();
      } else if (event.deltaY < 0) {
        zoomTo(zoomStateRef.current.currentZoomValue + 0.1);
        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  if (!file.url) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root': {
          height: '100%',
        },
      }}
      // DevNote: Zooming shortcuts are not working in @react-pdf-viewer. See https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1704
      onKeyDown={handleZoom}
      onKeyUp={handleKeyUp}
      onWheel={handleScroll}
    >
      <$DialogTitle id="dialog-title">
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" flex={1}>
            <FileListFillIcon color="#f1c40f" size={30} />
            <Box marginLeft={1.5}>
              <Typography component="h3" sx={{ fontSize: '18px', fontWeight: 700 }}>
                {file.name}
              </Typography>
              {file.size && (
                <Typography component="p" fontSize="14px">
                  {file?.size ? formatFileSize(file?.size) : ''}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <CurrentPageInput />
              <Box mx={0.5}>/</Box>
              <NumberOfPages>
                {(props: RenderNumberOfPagesProps) => (
                  <Typography fontSize={14}>{props.numberOfPages}</Typography>
                )}
              </NumberOfPages>
            </Box>
            <Box mr={1} ml={3} color={theme.palette.custom.white} sx={{ opacity: 0.5 }}>
              |
            </Box>
            <Box>
              <ZoomOut>
                {(props: RenderZoomOutProps) => (
                  <IconButton
                    title="Zoom Out"
                    onClick={props.onClick}
                    size="small"
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.custom.jetGrey,
                      },
                    })}
                  >
                    <SubtractLineIcon size={20} color={theme.palette.custom.white} />
                  </IconButton>
                )}
              </ZoomOut>
              <CurrentScale>
                {(props: RenderCurrentScaleProps) => (
                  <Typography display="inline" fontSize={14} mx={1}>{`${Math.round(
                    props.scale * 100,
                  )}%`}</Typography>
                )}
              </CurrentScale>
              <ZoomIn>
                {(props: RenderZoomInProps) => (
                  <IconButton
                    title="Zoom In"
                    size="small"
                    onClick={props.onClick}
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.custom.jetGrey,
                      },
                    })}
                  >
                    <AddLineIcon size={20} color={theme.palette.custom.white} />
                  </IconButton>
                )}
              </ZoomIn>
            </Box>
            <Box mr={1} ml={2} color={theme.palette.custom.white} sx={{ opacity: 0.5 }}>
              |
            </Box>
            <Box display="flex" alignItems="center">
              <Zoom>
                {(props: RenderZoomProps) => (
                  <IconButton
                    title={
                      zoomStateRef.current.specificZoomValue === SpecialZoomLevel.PageFit
                        ? 'Fit to Width'
                        : 'Fit to Page'
                    }
                    size="small"
                    onClick={() => {
                      const zoomTo =
                        zoomStateRef.current.specificZoomValue === SpecialZoomLevel.PageFit
                          ? SpecialZoomLevel.PageWidth
                          : SpecialZoomLevel.PageFit;
                      props.onZoom(zoomTo);
                      zoomStateRef.current.specificZoomValue = zoomTo;
                    }}
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.custom.jetGrey,
                      },
                    })}
                  >
                    {zoomStateRef.current.specificZoomValue === SpecialZoomLevel.PageFit ? (
                      <FitToWidthIcon
                        style={{ width: 22, height: 22 }}
                        color={theme.palette.custom.white}
                      />
                    ) : (
                      <FitToPageIcon
                        style={{ width: 22, height: 22 }}
                        color={theme.palette.custom.white}
                      />
                    )}
                  </IconButton>
                )}
              </Zoom>
              <Rotate direction={RotateDirection.Forward}>
                {(props: RenderRotateProps) => (
                  <IconButton
                    title="Rotate Clockwise"
                    size="small"
                    onClick={props.onClick}
                    sx={(theme) => ({
                      ml: 1,
                      '&:hover': {
                        backgroundColor: theme.palette.custom.jetGrey,
                      },
                    })}
                  >
                    <ClockwiseLineIcon size={20} color={theme.palette.custom.white} />
                  </IconButton>
                )}
              </Rotate>
            </Box>
          </Box>
          <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton
              title="Search"
              size="small"
              sx={(theme) => ({
                mr: 1.5,
                '&:hover': {
                  backgroundColor: theme.palette.custom.jetGrey,
                },
              })}
              onClick={() => setShowSearch(true)}
            >
              <SearchLineIcon size={20} color={theme.palette.custom.white} />
            </IconButton>
            <IconButton
              title="Download"
              size="small"
              sx={(theme) => ({
                '&:hover': {
                  backgroundColor: theme.palette.custom.jetGrey,
                },
              })}
              onClick={downloadFile}
            >
              <DownloadFillIcon size={20} color={theme.palette.custom.white} />
            </IconButton>
            <Print>
              {(props: RenderPrintProps) => (
                <IconButton
                  onClick={props.onClick}
                  title="Print"
                  size="small"
                  sx={(theme) => ({
                    mx: 1.5,
                    '&:hover': {
                      backgroundColor: theme.palette.custom.jetGrey,
                    },
                  })}
                >
                  <PrinterFillIcon size={20} color={theme.palette.custom.white} />
                </IconButton>
              )}
            </Print>
            <IconButton
              title="Close"
              size="small"
              sx={(theme) => ({
                '&:hover': {
                  backgroundColor: theme.palette.custom.jetGrey,
                },
              })}
              onClick={() => setOpen(false)}
            >
              <CloseFillIcon color={theme.palette.custom.white} />
            </IconButton>
          </Box>
        </Box>
      </$DialogTitle>
      <$DialogContent dividers>
        <Box display="flex" height="100%">
          <Box
            width={350}
            sx={(theme) => ({ backgroundColor: theme.palette.custom.charCoal })}
            overflow="auto"
          >
            {showSearch ? (
              <Box px={2} py={1}>
                <Box textAlign="right" mb={0.5}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowSearch(false);
                      clearHighlights();
                    }}
                    sx={(theme) => ({
                      '&:hover': {
                        backgroundColor: theme.palette.custom.jetGrey,
                      },
                    })}
                  >
                    <CloseLineIcon color={theme.palette.custom.white} />
                  </IconButton>
                </Box>
                <PdfSearch
                  searchPluginInstance={searchPluginInstance}
                  isDocumentLoaded={isDocumentLoaded}
                />
              </Box>
            ) : (
              <Thumbnails
                renderThumbnailItem={(props: RenderThumbnailItemProps) => (
                  <Box key={props.key} my={1}>
                    <Box
                      onClick={props.onJumpToPage}
                      sx={(theme) => ({
                        cursor: 'pointer',
                        borderWidth: 6,
                        borderStyle: 'solid',
                        borderRadius: 1,
                        maxWidth: 160,
                        maxHeight: 180,
                        overflow: 'hidden',
                        borderColor:
                          props.currentPage === props.pageIndex
                            ? theme.palette.custom.superNova
                            : 'transparent',
                      })}
                    >
                      {props.renderPageThumbnail}
                    </Box>
                    <Typography textAlign="center" color={theme.palette.custom.white}>
                      {props.renderPageLabel}
                    </Typography>
                  </Box>
                )}
              />
            )}
          </Box>
          <Box flex={1} height="100%">
            <Viewer
              // initialPage={file.initialPage}
              theme="dark"
              defaultScale={DEFAULT_PDF_ZOOM_SCALE}
              fileUrl={file.url}
              onZoom={handleOnZoom}
              plugins={[
                zoomPluginInstance,
                pageNavigationPluginInstance,
                printPluginInstance,
                thumbnailPluginInstance,
                rotatePluginInstance,
                searchPluginInstance,
              ]}
              onDocumentLoad={handleDocumentLoad}
            />
          </Box>
        </Box>
      </$DialogContent>
    </Dialog>
  );
}

const $DialogTitle = styled(DialogTitle, { label: 'DialogTitle' })(({ theme }) => ({
  padding: '12px 24px',
  top: '0',
  width: '100%',
  transition: 'opacity 0.7s',
  color: 'white',
  zIndex: 1,
  backgroundColor: theme.palette.custom.charCoal,
  boxShadow:
    '0 -2px 8px rgba(0,0,0,.09), 0 4px 8px rgba(0,0,0,.06), 0 1px 2px rgba(0,0,0,.3), 0 2px 6px rgba(0,0,0,.15)',
}));

const $DialogContent = styled(DialogContent, { label: 'DialogContent' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.davyGrey,
  overflow: 'hidden',
  padding: '0px !important',
  width: '100%',
  height: '100%',
}));

export default PdfViewer;
