import { GEN_AI_SERVICE_ENDPOINTS, GenAIAPI } from '@buildwise/libs/services';

class GenAIKnowledgeAPI extends GenAIAPI {
  public generateArticleSummary(payload: any) {
    return this.post(GEN_AI_SERVICE_ENDPOINTS.GENERATE_ARTICLE_SUMMARY, payload);
  }

  public generateArticleSummaryFromFile(companyId: string, file: File) {
    const formBody = new FormData();
    if (file) {
      formBody.append('file', file);
    }
    return this.post(
      GEN_AI_SERVICE_ENDPOINTS.GENERATE_ARTICLE_SUMMARY_FROM_FILE.replace(':company_id', companyId),
      formBody,
    );
  }
}
const genAIKnowledgeAPI = new GenAIKnowledgeAPI();
export default genAIKnowledgeAPI;
