import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '../../../libs/services/endpoints';
import useUserData from '../../../hooks/useUserData';
import { queryClient } from '../../../config/queryClient';
import StakeholdersAPI from '../../../api/StakeholdersAPI';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';
import { PartnerType } from 'pages/stakeholders/hooks/useStakeholdersQuery';
import { ConfigEntryType, ConfigType } from 'config/config';

type PartnerDetailsResponse = {
  contact: PartnerType;
};

export type StakeHolderScoreCardFormType = ConfigType & {
  questions: ConfigEntryType[];
  configId: string;
  projectId: string;
  formName: string;
  formCategoryDescription: string;
  formType: string;
  formCategory: string;
  assignedTo: string;
  assignedToUserDisplayName?: string;
  status: string;
  updatedBy: string;
  updatedAt: string | Date;
  createdAt: string | Date;
  createdBy: string;
  partnerId: string;
  contact: any;
  project: any;
  rating: string;
  contactId: string;
};

export const useStakeholderDetailsQuery = ({
  stakeholderId,
  shouldFetch = true,
}: {
  stakeholderId: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const {
    data: stakeholderData,
    refetch: refetchStakeholderData,
    ...rest
  } = useQuery<AxiosResponse<PartnerDetailsResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER, companyId, stakeholderId],
    () => StakeholdersAPI.getStakeholder(companyId, stakeholderId),
    {
      enabled: !!stakeholderId && !!companyId && shouldFetch,
      staleTime: 2 * 60 * 1000,
      keepPreviousData: true,
      refetchOnMount: 'always',
    },
  );
  const stakeholder: PartnerType | undefined = useMemo(
    () =>
      ({
        ...stakeholderData?.data?.contact,
        scoreCardForms:
          stakeholderData?.data?.contact?.scoreCardForms?.map(
            (form: StakeHolderScoreCardFormType) => ({
              id: form.id,
              configName: form.formName,
              configType: form.formType,
              configCategory: form.formCategory,
              description: form.formCategoryDescription,
              entries: form.questions,
              sortFlag: form.sortFlag,
              title: form.title,
              companyId,
              projectId: form.projectId,
              partnerId: form.contactId,
              contact: stakeholderData?.data?.contact,
              rating: form.rating,
              configId: form.configId,
              updatedAt: form.updatedAt,
              updatedBy: form.updatedBy,
              createdAt: form.createdAt,
              createdBy: form.createdBy,
              project: form.project,
              updatedByDisplayName:
                stakeholderData?.data?.contact?.userMap[form.updatedBy]?.[0]?.['displayName'],
              createdByDisplayName:
                stakeholderData?.data?.contact?.userMap[form.createdBy]?.[0]?.['displayName'],
              createdByTitle:
                stakeholderData?.data?.contact?.userMap[form.createdBy]?.[0]?.['title'],
              AssignedToTitle:
                stakeholderData?.data?.contact?.userMap[form.assignedTo]?.[0]?.['title'],
              status: form.status,
              assignedTo: form.assignedTo,
              assignedToUserDisplayName:
                stakeholderData?.data?.contact?.userMap[form.assignedTo]?.[0]?.['displayName'],
              profileImageURL:
                stakeholderData?.data?.contact?.userMap[form.assignedTo]?.[0]?.['profileImageURL'],
            }),
          ) || [],
      }) as PartnerType,
    [stakeholderData],
  );

  const updateStakeholderDetailsCache = ({
    updatedStakeholder,
  }: {
    updatedStakeholder: PartnerType;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER,
      companyId,
      stakeholderId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<PartnerDetailsResponse, Error> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER, companyId, stakeholderId],
        (old) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                contact: {
                  ...old?.data?.contact,
                  ...updatedStakeholder,
                },
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    stakeholder,
    ...rest,
    updateStakeholderDetailsCache,
  };
};

type StakeholderArticlesResponse = {
  articles: ArticleType[];
  totalElements: number;
  totalNumber: number;
  totalPages: number;
  userMap: ArticleUserMapType;
};

export const useStakeholderArticles = ({
  stakeholderId,
  shouldFetch = true,
}: {
  stakeholderId: string;
  shouldFetch?: boolean;
}) => {
  const stakeholderArticlesQueryKey = [
    JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER_ARTICLES,
    stakeholderId,
  ];

  const { data: stakeholderArticles, ...rest } = useQuery<
    AxiosResponse<StakeholderArticlesResponse>,
    Error
  >(stakeholderArticlesQueryKey, () => StakeholdersAPI.getStakeholderArticles(stakeholderId), {
    staleTime: 2 * 60 * 1000,
    keepPreviousData: true,
    refetchOnMount: 'always',
    enabled: !!stakeholderId && shouldFetch,
  });

  const articles = useMemo(() => stakeholderArticles?.data?.articles || [], [stakeholderArticles]);

  const updatePostInStakeholderPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<StakeholderArticlesResponse, Error> | undefined>(
      stakeholderArticlesQueryKey,
      (old) => {
        if (old) {
          let newPlaylists = [...(old?.data?.articles || [])];
          let addToPlaylistIdsUpdatedCount = 0;
          let removeFromPlaylistIdsUpdatedCount = 0;

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            for (let i = 0; i < newPlaylists.length; i++) {
              const playlist = newPlaylists[i];
              const newPlaylistArticleIds = [...playlist?.playListArticleIds];
              const newPlaylistArticles = [...playlist?.playListArticles];

              // add article to collection
              if (addToPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.push(article.id);
                newPlaylistArticles.push(article);
              }

              // remove article from collection
              if (removeFromPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }
              }

              newPlaylists[i] = {
                ...playlist,
                playListArticleIds: newPlaylistArticleIds,
                playListArticles: newPlaylistArticles,
              };

              // stop updating cache when all collections updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            data: {
              ...old.data,
              articles: newPlaylists,
            },
          };
        }

        return old;
      },
    );
  };

  return {
    articles,
    ...rest,
    updatePostInStakeholderPlaylistsCache,
  };
};

export const invalidateStakeholderQuery = (companyId, stakeholderId) => {
  queryClient.refetchQueries([JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER, companyId, stakeholderId]);
};

export const updateStakeholderArticlesCache = (
  action: 'update' | 'delete',
  stakeholderId: string,
  article: ArticleType,
) => {
  queryClient.setQueryData<AxiosResponse<StakeholderArticlesResponse, Error> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_STAKEHOLDER_ARTICLES, stakeholderId],
    (oldData: AxiosResponse<StakeholderArticlesResponse, Error> | undefined) => {
      if (oldData) {
        const newStakeholderArticles = [...(oldData.data?.articles || [])];

        const index = newStakeholderArticles.findIndex(
          (stakeholderArticle) => stakeholderArticle.id === article.id,
        );
        if (action === 'update') {
          if (index > -1) {
            newStakeholderArticles[index] = { ...newStakeholderArticles[index], ...article };
          }
        }

        if (action === 'delete') {
          newStakeholderArticles.splice(index, 1);
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            articles: newStakeholderArticles,
          },
        };
      }

      return oldData;
    },
  );
};
