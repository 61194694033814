import { API, JAVA_SERVICE_URL } from '@buildwise/libs/services';

class JavaAPI extends API {
  constructor() {
    super({
      baseURL: JAVA_SERVICE_URL,
    });
  }
}

export default JavaAPI;
