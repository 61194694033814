import { GEN_AI_SERVICE_ENDPOINTS, GenAIAPI } from '@buildwise/libs/services';

class BuildWiseGPTAPI extends GenAIAPI {
  public postPrompt(payload: { promptText: string; regenerateFlag?: boolean }) {
    return this.post(GEN_AI_SERVICE_ENDPOINTS.POST_PROMPT, {
      ...payload,
      promptType: 'Generate',
    });
  }
}
const buildWiseGPTAPI = new BuildWiseGPTAPI();
export default buildWiseGPTAPI;
