import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';
import { useMemo } from 'react';
import useUserData, { UserType } from 'hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import KnowledgeAPI, { ArticlesResponse, FeedFilter } from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import orderBy from 'lodash/orderBy';
import { queryClient } from 'config/queryClient';
import { ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';

export type AttachmentType = {
  // Uploaded properties
  id: string;
  articleOpenUrl?: string;
  createdAt?: Date | string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileURL?: string;
  fileUploaded?: boolean;
  generatePreSignedURLFlag?: boolean;
  isFileUploaded?: boolean;
  mimeType?: string;
  thumbnailOpenUrl: string | ArrayBuffer | null | undefined;
  // embedded imge properties
  imageHashValue?: string;
  // Local properties
  file: File;
  isRemoved?: boolean;
  isUpdated?: boolean;
  thumbnail: File | null;
  // Only available on searched articles
  documentPDF?: string;
  pageNo?: string[];
  chunkContent?: string[];
  pageInfo?: {
    pageNo: string;
    pageContent: string;
  }[];
  pageContent?: string[];
};

export type Endorsement = {
  userName: string;
  userNameToDisplay: string;
  title: string;
  expYears: string;
  date: string;
  skills: string[];
  comments: string[];
};

export type ArticleType = {
  activityLog?: {
    type: string;
    date: string | Date;
    userName: string;
    nextUserName: string;
    desciption: string;
  }[];
  articleCreatedBy: string;
  attachments?: AttachmentType[];
  assignee?: string;
  autoDraftFlag?: boolean;
  collaborators?: string[];
  comments: string[];
  companyId: string;
  costImpact: number;
  createdAt: Date | string;
  createdBy: string;
  customFields?: Record<
    string,
    {
      fieldName: string;
      fieldValue: string | string[];
      type: string;
      configRefName: string;
    }
  >;
  description: string;
  division: string;
  divisions?: string[];
  eventSummary: string;
  eventType: string;
  formIds?: string[];
  hasAttachments: boolean;
  id: string;
  isArticleBookMarked: boolean;
  isArticleEndorsed: boolean;
  isDraft: boolean;
  knowledgeLevel: string;
  labels: string[];
  lastUpdatedAt: Date | string;
  mentions: any[];
  noteRelativePathToShare: string;
  originIndicator: string;
  previousOwners?: string[];
  primaryCompanyName?: string;
  project?: ProjectType;
  projectId: string;
  projectPhases?: string[];
  partnerIds?: string[];
  project_phase: string;
  publishArticleToCompanyIds?: string[];
  reason?: string;
  sourcepmobjectname?: string;
  questionCreatedBy: string;
  questionId: string;
  questions: unknown[];
  questionCreatedByDisplayName: string;
  questionCreatedByExpYearsToDisplay: string;
  questionCreatedByTitleToDisplay: string;
  specCategory: string;
  specSection: string;
  specSections?: string[];
  status: string;
  timeImpact: number;
  title: string;
  topics?: string[];
  topicsAutoGenerated: unknown;
  totalComments: number;
  totalDislikes: number;
  totalLikes: number;
  totalViews: number;
  userLiked: boolean;
  userNameExpYearsToDisplay: string;
  userNameTitleToDisplay: string;
  userNameToDisplay: string;
  userPrimaryCompanyName?: string;
  userVerified: boolean;
  userViewed?: boolean;
  versionId: number;
  publishedAt?: Date | string;
  profileImageURL?: string;
  questionCreatedByProfileImageURL?: string;
  likedUsers?: UserType[];
  viewedUsers?: UserType[];
  playListArticles: ArticleType[];
  playListArticleIds: string[];
  coverPhotoFileName?: string;
  endorsements: Endorsement[];
  userMap?: ArticleUserMapType;
};

export type LikedUsersResponse = {
  article: ArticleType;
  totalElements: number;
  totalPages: number;
  totalNumber: number;
};

type ArticlesQueryPropsType = {
  searchKeyword?: string;
  sortBy?: string;
  searchOnly?: boolean;
};

export type FeedType = 'posts' | 'collections';

export const useArticlesQuery = ({
  searchKeyword = '',
  sortBy = '',
  searchOnly = false,
}: ArticlesQueryPropsType) => {
  const { companyId } = useUserData();

  const { data: articlesData, ...info } = useQuery(
    [
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_ARTICLES.replace(':companyId', companyId)}noPagination`,
    ],
    () => KnowledgeAPI.getArticles(companyId),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !searchOnly && !!companyId,
    },
  );

  const { data: searchedData, ...searchInfo } = useQuery<AxiosResponse<ArticleType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH, companyId, 'all', searchKeyword],
    () => KnowledgeAPI.searchArticles(companyId, searchKeyword),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!searchKeyword,
    },
  );

  const articles = useMemo(() => {
    if (!!searchKeyword) {
      return searchedData?.data;
    }
    return articlesData?.data?.[0]?.articles || [];
  }, [articlesData, searchKeyword, searchedData]);

  const sortedArticles = useMemo(() => {
    if (articles && sortBy) {
      return orderBy(articles, sortBy, 'desc');
    }
    return articles;
  }, [articles, sortBy]);

  return {
    articles: sortedArticles,
    info,
    searchInfo,
    searchedData: searchedData?.data,
  };
};

const pickQueryURL = (
  feedType: FeedType,
  companyId: string,
  pageNo: number,
  pageSize: number,
  filters: FeedFilter[],
) => {
  switch (feedType) {
    case 'collections':
      return {
        url: KnowledgeAPI.getPlaylistArticlesPagination(companyId, pageNo, pageSize, filters),
      };
    default:
      return {
        url: KnowledgeAPI.getPostArticlesPagination(companyId, pageNo, pageSize, filters),
      };
  }
};

const pickQueryKey = (feedType: FeedType) => {
  switch (feedType) {
    case 'collections':
      return JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_PLAYLIST_ARTICLES;
    default:
      return JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_POST_ARTICLES;
  }
};

type PaginationArticlesQueryPropsType = {
  feedType?: FeedType;
  filters?: FeedFilter[];
  pageNo?: number;
  pageSize?: number;
  searchKeyword?: string;
  searchOnly?: boolean;
  shouldFetch?: boolean;
};

export const useArticlesAndPlaylistsPaginationQuery = (
  options: PaginationArticlesQueryPropsType = {},
) => {
  const {
    feedType = 'posts',
    filters = [],
    pageNo = 0,
    pageSize = 10,
    searchKeyword = '',
    searchOnly = false,
    shouldFetch = true,
  } = options;
  const { companyId } = useUserData();

  const fetchArticles = ({ pageParam = pageNo }) => {
    return pickQueryURL(feedType, companyId, pageParam, pageSize, filters).url;
  };

  const {
    data: articlesData,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    refetch,
    ...rest
  } = useInfiniteQuery([pickQueryKey(feedType), companyId, filters], fetchArticles, {
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !searchOnly && !!companyId && shouldFetch,
    getNextPageParam: (lastPage) =>
      lastPage?.data?.[0]?.totalPages > lastPage?.data?.[0]?.pageNumber
        ? lastPage.data[0].pageNumber + 1
        : undefined,
  });

  const { data: searchedData, ...searchInfo } = useQuery<AxiosResponse<ArticleType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH, companyId, 'all', searchKeyword],
    () => KnowledgeAPI.searchArticles(companyId, searchKeyword),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!searchKeyword,
    },
  );

  const fetchAllArticles = (pages) => {
    const allArticles: ArticleType[] = [];
    pages?.forEach((page) => {
      if (page.data && Array.isArray(page.data)) {
        allArticles.push(...(page?.data?.[0]?.articles || []));
      }
    });

    return allArticles;
  };

  const articles = useMemo(() => {
    const allArticles = fetchAllArticles(articlesData?.pages);
    if (!!searchKeyword) {
      return Array.isArray(searchedData?.data) ? searchedData?.data : [];
    }
    return allArticles;
  }, [articlesData?.pages, searchKeyword, searchedData]);

  const resetFeedQueries = () => {
    queryClient.resetQueries([pickQueryKey('posts'), companyId, filters], {
      refetchPage: (_: unknown, index: number) => index === 0,
    });
    queryClient.resetQueries([pickQueryKey('collections'), companyId, filters], {
      refetchPage: (_: unknown, index: number) => index === 0,
    });
  };

  const refetchQuery = ({ type }: { type: 'posts' | 'collections' }) => {
    queryClient.invalidateQueries({
      queryKey: [pickQueryKey(type), companyId, filters],
      refetchPage: (_, index) => index === 0,
    });
    // // queryClient.refetchQueries({
    // //   queryKey: [pickQueryKey(type), companyId, filters],
    // //   refetchPage: (_, index) => index === 0,
    // // });
    // queryClient.resetQueries([pickQueryKey('posts'), companyId, filters], {
    //   refetchPage: (_: unknown, index: number) => index === 0,
    // });
  };

  const updatePostInPaginatedPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    _companyId = companyId,
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    _companyId?: string;
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined>(
      [JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_PLAYLIST_ARTICLES, _companyId, filters],
      (old) => {
        if (old) {
          const pages = [...old.pages];

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            let addToPlaylistIdsUpdatedCount = 0;
            let removeFromPlaylistIdsUpdatedCount = 0;

            for (let i = 0; i < pages.length; i++) {
              const playlists = [...pages[i].data?.[0]?.articles];
              for (let j = 0; j < playlists.length; j++) {
                // add article to collection
                if (addToPlaylistIds.includes(playlists[j].id)) {
                  playlists[j] = {
                    ...playlists[j],
                    playListArticleIds: [...playlists[j]?.playListArticleIds, article.id],
                    playListArticles: [...playlists[j]?.playListArticles, article],
                  };
                  addToPlaylistIdsUpdatedCount++;
                }

                // remove article from collection
                if (removeFromPlaylistIds.includes(playlists[j].id)) {
                  playlists[j]?.playListArticleIds?.splice(
                    playlists[j]?.playListArticleIds?.indexOf(article.id),
                    1,
                  );
                  const index = playlists[j]?.playListArticles?.findIndex(
                    (playListArticle) => playListArticle.id === article.id,
                  );
                  playlists[j]?.playListArticles?.splice(index, 1);
                  removeFromPlaylistIdsUpdatedCount++;
                }
              }

              pages[i] = {
                ...pages[i],
                data: [
                  {
                    ...pages[i].data[0],
                    articles: playlists,
                  },
                ],
              };

              // stop updating cache when all playlists updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            pages,
          };
        }

        return old;
      },
    );
  };

  const updateFeedCache = ({
    action = 'update',
    article,
    _companyId = companyId,
    _feedType = ['posts', 'collections'],
  }: {
    action?: 'add' | 'update' | 'delete';
    article: ArticleType;
    _companyId?: string;
    _feedType?: FeedType[];
  }) => {
    const updateArticle = (
      old: InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined,
    ) => {
      if (old) {
        const pages = [...(old?.pages || [])];
        let pageIndex = -1;
        let itemIndex = -1;

        for (let i = 0; i < pages.length; i++) {
          const articles = pages[i]?.data[0]?.articles || [];
          for (let j = 0; j < articles.length; j++) {
            if (articles[j].id === article.id) {
              itemIndex = j;
              break;
            }
          }

          if (itemIndex > -1) {
            pageIndex = i;
            break;
          }
        }

        if (pageIndex === -1) {
          pageIndex = 0;
        }
        const updatedAricles = [...(pages[pageIndex]?.data[0]?.articles || [])];

        if (action === 'add') {
          updatedAricles.unshift(article);
        }

        if (action === 'update') {
          if (itemIndex > -1) {
            updatedAricles[itemIndex] = {
              ...updatedAricles[itemIndex],
              ...article,
            };
          } else {
            updatedAricles.unshift(article);
          }
        }

        if (action === 'delete') {
          updatedAricles.splice(itemIndex, 1);
        }

        pages[pageIndex] = {
          ...pages[pageIndex],
          data: [
            {
              ...pages[pageIndex].data[0],
              articles: updatedAricles,
            },
          ],
        };

        return {
          ...old,
          pages,
        };
      }

      return old;
    };

    if (_feedType.includes('posts')) {
      const queryStatus = queryClient.getQueryState([
        JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_POST_ARTICLES,
        _companyId,
        filters,
      ]);

      if (queryStatus?.status && queryStatus.status !== 'idle') {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined
        >([JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_POST_ARTICLES, _companyId, filters], (old) => {
          if (article?.originIndicator !== 'PLAYLIST') {
            return updateArticle(old);
          }

          return old;
        });
      }
    }

    if (_feedType.includes('collections')) {
      const queryStatus = queryClient.getQueryState([
        JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_PLAYLIST_ARTICLES,
        companyId,
        filters,
      ]);

      if (queryStatus?.status && queryStatus.status !== 'idle') {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ArticlesResponse[], Error>> | undefined
        >([JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_PLAYLIST_ARTICLES, companyId, filters], (old) => {
          if (article?.originIndicator === 'PLAYLIST') {
            return updateArticle(old);
          }

          return old;
        });
      }
    }
  };

  return {
    articles,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    searchInfo,
    ...rest,
    fetchNextPage,
    refetch,
    refetchQuery,
    resetFeedQueries,
    updateFeedCache,
    updatePostInPaginatedPlaylistsCache,
  };
};

export const usePlaylistsQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();
  const playlistsQueryKey = [JAVA_SERVICE_ENDPOINTS.GET_PLAYLISTS, companyId];

  const { data: playistsData, ...rest } = useQuery<AxiosResponse<ArticleType[], Error>>(
    playlistsQueryKey,
    () => KnowledgeAPI.getAllPlayLists(companyId),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      staleTime: 2 * 60 * 1000,
      enabled: !!companyId && shouldFetch,
    },
  );

  const playlists = useMemo(
    () => (Array.isArray(playistsData?.data) ? playistsData?.data || [] : []),
    [playistsData],
  );

  const updatePlaylistsCache = ({ playlist }: { playlist: ArticleType }) => {
    queryClient.setQueryData<AxiosResponse<ArticleType[], Error> | undefined>(
      playlistsQueryKey,
      (old) => {
        if (old) {
          const playlists = [...(old?.data || [])];

          return {
            ...old,
            data: [playlist, ...playlists],
          };
        }

        return old;
      },
    );
  };

  const updatePostInPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<ArticleType[], Error> | undefined>(
      playlistsQueryKey,
      (old) => {
        if (old) {
          let newPlaylists = [...(old?.data || [])];
          let addToPlaylistIdsUpdatedCount = 0;
          let removeFromPlaylistIdsUpdatedCount = 0;

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            for (let i = 0; i < newPlaylists.length; i++) {
              const playlist = newPlaylists[i];

              // add article to playlist
              if (addToPlaylistIds.includes(playlist.id)) {
                newPlaylists[i] = {
                  ...playlist,
                  playListArticleIds: [...playlist?.playListArticleIds, article.id],
                  playListArticles: [...playlist?.playListArticles, article],
                };
              }

              // remove article from playlist
              if (removeFromPlaylistIds.includes(playlist.id)) {
                const newPlaylistArticleIds = [...playlist?.playListArticleIds];
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const newPlaylistArticles = [...playlist?.playListArticles];
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }

                newPlaylists[i] = {
                  ...playlist,
                  playListArticleIds: newPlaylistArticleIds,
                  playListArticles: newPlaylistArticles,
                };
              }

              // stop updating cache when all playlists updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            data: newPlaylists,
          };
        }

        return old;
      },
    );
  };

  return {
    playlists,
    ...rest,
    updatePlaylistsCache,
    updatePostInPlaylistsCache,
  };
};
