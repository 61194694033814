import { Typography, Grid, Box, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import { formatFullNumber } from 'utility/helpers';
import { ReactComponent as RFIIcon } from 'assets/icons/RFIIcon.svg';
import { ReactComponent as ProcoreLogo } from 'assets/icons/FullProcoreLogo.svg';
import useUserData from 'hooks/useUserData';
import moment from 'moment';

function ProcoreSyncStats() {
  const theme = useTheme();
  const { company } = useUserData();

  return (
    <Container>
      <DataImportedLabel>
        <Box>
          {company?.pmmetrics?.pmsystemName?.toLowerCase() === 'procore' && (
            <>
              <ImportedFromText>Imported from</ImportedFromText>
              <ProcoreLogo width={100} />
            </>
          )}
        </Box>
        <LastSyncText>
          Last Data Sync: {moment().subtract(1, 'days').format('MMM DD, YYYY')}
        </LastSyncText>
      </DataImportedLabel>
      <Divider />
      <Grid container spacing={1.5} mt={1}>
        <Grid item sm={12} md={6}>
          <Item>
            <BuildingLineIcon size={24} color={theme.palette.custom.link} />
            <ItemText>{formatFullNumber(company?.pmmetrics?.totalProjects || 0)} Projects</ItemText>
          </Item>
        </Grid>
        <Grid item sm={12} md={6}>
          <Item>
            <RFIIcon width={20} height={22} />
            <ItemText>{formatFullNumber(company?.pmmetrics?.totalRFIs || 0)} RFIs</ItemText>
          </Item>
        </Grid>
        <Grid item sm={12} md={6}>
          <Item>
            <GroupLineIcon size={24} color={theme.palette.custom.purpleMimosa} />
            <ItemText>{formatFullNumber(company?.pmmetrics?.totalUsers || 0)} People</ItemText>
          </Item>
        </Grid>
        <Grid item sm={12} md={6}>
          <Item>
            <FileList3LineIcon size={24} color={theme.palette.custom.blazeOrange} />
            <ItemText>
              {formatFullNumber(company?.pmmetrics?.totalSubmittals || 0)} Submittals
            </ItemText>
          </Item>
        </Grid>
        <Grid item sm={12} md={6}>
          <Item>
            <HandCoinLineIcon size={24} color={theme.palette.custom.pictonBlue} />
            <ItemText>{formatFullNumber(company?.pmmetrics?.totalVendors || 0)} Partners</ItemText>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  width: 350,
  padding: 16,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
}));

const DataImportedLabel = styled(Box, { label: 'DataImportedLabel' })(({}) => ({
  marginBottom: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ImportedFromText = styled(Typography, { label: 'ImportedFromText' })(({ theme }) => ({
  textAlign: 'center',
  fontSize: 14,
  color: theme.palette.custom.mistBlue,
}));

const LastSyncText = styled(Typography, { label: 'LastSyncText' })(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.custom.coolGrey,
}));

const Item = styled(Box, { label: 'Item' })(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ItemText = styled(Box, { label: 'ItemText' })(({ theme }) => ({
  marginLeft: 4,
  fontSize: 14,
  color: theme.palette.custom.tuna,
}));

export default ProcoreSyncStats;
