import { Box, IconButton, InputBase } from '@mui/material';
import { Match, RenderSearchProps, SearchPlugin } from '@react-pdf-viewer/search';
import { KeyboardEvent, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CloseCircleFillIcon from 'remixicon-react/CloseCircleFillIcon';
import RenderSearchMatches from './RenderSearchMatches';

type PdfSearchProps = {
  isDocumentLoaded: boolean;
  searchPluginInstance: SearchPlugin;
};

function PdfSearch({ isDocumentLoaded, searchPluginInstance }: PdfSearchProps) {
  const { Search } = searchPluginInstance;
  const theme = useTheme();
  const [matches, setMatches] = useState<Match[]>([]);

  return (
    <Search>
      {(searchProps: RenderSearchProps) => {
        const { keyword, setKeyword, clearKeyword, search } = searchProps;
        const handleSearchKeyDown = (e: KeyboardEvent) => {
          if (e.key === 'Enter' && keyword) {
            search().then((allMatches) => {
              setMatches(allMatches);
            });
          }
        };

        return (
          <Box height="100%" overflow="auto">
            <SearchInput
              autoFocus
              placeholder="Search..."
              value={keyword}
              onKeyDown={handleSearchKeyDown}
              onChange={(event) => setKeyword(event?.target.value)}
              type="text"
              fullWidth
              endAdornment={
                keyword && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      clearKeyword();
                      setMatches([]);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <CloseCircleFillIcon size={20} color={theme.palette.custom.white} />
                  </IconButton>
                )
              }
            />
            <RenderSearchMatches
              {...searchProps}
              matches={matches}
              setMatches={setMatches}
              documentLoaded={isDocumentLoaded}
            />
          </Box>
        );
      }}
    </Search>
  );
}

const SearchInput = styled(InputBase, { label: 'SearchInput' })(({ theme }) => ({
  color: theme.palette.custom.white,
  marginBottom: 16,
  paddingBottom: 4,
  borderBottom: `1px solid ${theme.palette.custom.silver}`,
  '&:focus-within': {
    borderBottom: `1px solid ${theme.palette.custom.white}`,
  },
}));

export default PdfSearch;
