import ProjectsAPI from 'api/ProjectsAPI';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export enum BusinessObjectStatus {
  NEW = 'ACTIVE',
  AI_DRAFT_CREATED = 'AI_DRAFT_CREATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
}

export type BusinessObjectActivityLog = {
  date: string | Date;
  userName: string;
  description: string;
  status: BusinessObjectStatus | string;
};

export type MeetingMinutesCategory = {
  id: number;
  title: string;
  meeting_topic: {
    description: string;
    id: number;
    minutes: string;
    title: string;
  }[];
};

export type FilteredRFI = {
  _id: string;
  id: number;
  created_at: string | Date;
  initiated_at: string | Date;
  link: string;
  subject: string;
  updated_at: string | Date;
  reference: string;
  questions: {
    id: number;
    body: string;
  }[];
  responsible_contractor: {
    id: number;
    name: string;
  };
  created_by: {
    id: number;
    login: string;
    name: string;
  };
  procore_project_id: string;
  bw_company_id: string;
  procore_project_name: string;
  bw_company_name: string;
  bw_modified_date: string | Date;
  insightSnippets: string[];
  referenceArticleId: string;
  businessObjectStatus: BusinessObjectStatus | string;
  businessObjectNotes: string;
  reviewStatus: {
    status: BusinessObjectStatus | string;
    notes: string;
    activityLog: BusinessObjectActivityLog[];
  };
};

export type MeetingMinutes = {
  starts_at: string | Date;
  title: string;
  description: string;
  conclusion: string;
  attachments: {
    id: number;
    filename: string;
    name: string;
    url: string;
  }[];
  attendees: {
    id: number;
    login_information: {
      id: number;
      login: string;
      name: string;
    };
  }[];
  meeting_categories: MeetingMinutesCategory[];
  _id: string;
  id: number;
  procore_project_id: string;
  bw_company_id: string;
  procore_project_name: string;
  bw_company_name: string;
  bw_modified_date: string | Date;
  insightSnippets: string[];
  referenceArticleId: string;
  businessObjectStatus: BusinessObjectStatus | string;
  businessObjectNotes: string;
  reviewStatus: {
    status: BusinessObjectStatus | string;
    notes: string;
    activityLog: BusinessObjectActivityLog[];
  };
};

export default function useFilteredInsightsQuery({
  objectType,
  shouldFetch = true,
}: {
  objectType: 'RFI' | 'MEETING_MINUTES';
  shouldFetch?: boolean;
}) {
  const { companyId } = useUserData();

  const { data: insightsData, ...rest } = useQuery<
    AxiosResponse<{ objectList: (FilteredRFI | MeetingMinutes)[] }>,
    Error
  >(
    [JAVA_SERVICE_ENDPOINTS.GET_FILTERED_INSIGHTS, companyId, objectType],
    () => ProjectsAPI.getFilteredInsights(companyId, objectType),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && !!objectType && shouldFetch,
      refetchOnMount: 'always',
    },
  );
  const insights = useMemo(
    () =>
      Array.isArray(insightsData?.data?.objectList) ? insightsData?.data?.objectList || [] : [],
    [insightsData],
  );

  const updateFilteredInsightsCache = ({
    action,
    insight,
  }: {
    action: 'update';
    insight: FilteredRFI | MeetingMinutes;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_FILTERED_INSIGHTS,
      companyId,
      objectType,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<{ objectList: FilteredRFI[] }> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_FILTERED_INSIGHTS, companyId, objectType],
        (old) => {
          if (old) {
            const updatedInsights = [...(old?.data.objectList || [])];

            if (action === 'update') {
              const index = updatedInsights.findIndex((item) => item._id === insight._id);

              if (index > -1) {
                updatedInsights[index] = {
                  ...updatedInsights[index],
                  ...insight,
                };
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                objectList: updatedInsights,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    insights,
    ...rest,
    updateFilteredInsightsCache,
  };
}
