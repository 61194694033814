import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Dispatch, SetStateAction } from 'react';
import RenderPendingFormCard from './RenderPendingFormCard';
import { Link } from 'react-router-dom';
import { PROJECT_PROGRESS_CARD, PROJECT_SCORECARD, STAKEHOLDER_FORM } from 'constants/routes';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';

type PendingFormsModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pendingForms: any;
  title: string;
};

function PendingFormsModal({ open, setOpen, pendingForms, title }: PendingFormsModalProps) {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="progressCards-dialog-title"
      aria-describedby="progressCards-dialog-description"
      onClose={closeModal}
      sx={{ '& .MuiPaper-root': { height: '55%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="progressCards-dialog-title">
        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: 'black' }}>{title}</Typography>
      </CustomDialogTitle>
      <DialogContent id="progressCards-dialog-description" sx={{ pt: 0 }}>
        <Box mt={2}>
          {pendingForms.length > 0 &&
            pendingForms.map((form, index) => (
              <Link
                to={
                  form?.formCategory === FORMS_CONFIG_CATEGORY_TYPES.BUILDWISE_SCORECARD
                    ? STAKEHOLDER_FORM?.replace(':stakeholderId', form.contactId).replace(
                        ':formId',
                        form.id,
                      )
                    : `${(form?.formCategory === FORMS_CONFIG_CATEGORY_TYPES.PROJECT_PROGRESS_CARD ? PROJECT_PROGRESS_CARD : PROJECT_SCORECARD)?.replace(':projectId', form.projectId).replace(':formId', form.id)}`
                }
                state={{ ...form, mode: 'ANSWER' }}
                onClick={closeModal}
                key={index}
              >
                <RenderPendingFormCard form={form} key={form.id} context={title} />
              </Link>
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PendingFormsModal;
