import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectMenu from 'components/common/menu/SelectMenu';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import { formatDate } from 'utility/helpers';
import { ReactComponent as BookmarkFillIcon } from 'assets/icons/bookmark-fill.svg';
import { ReactComponent as BookmarkLineIcon } from 'assets/icons/bookmark.svg';
import { MenuItemType } from 'components/drawer/RenderMenu';
import { ArticleType } from '../hooks/useArticlesQuery';

type ArticleMenuProps = {
  article: ArticleType;
  menu: MenuItemType[];
  bookmarkHandler: () => void;
};

function ArticleMenu({ article, menu, bookmarkHandler }: ArticleMenuProps) {
  return (
    <Box display="flex" alignItems="center" mt={article.userNameTitleToDisplay ? -2.5 : 0}>
      {article.isArticleBookMarked ? (
        <IconButton onClick={bookmarkHandler} sx={{ mr: 0.5 }} size="small" title="Remove Bookmark">
          <BookmarkFillIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      ) : (
        <IconButton onClick={bookmarkHandler} sx={{ mr: 0.5 }} size="small" title="Bookmark">
          <BookmarkLineIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      )}
      <$ArticleDate variant="caption" mr={0.5}>
        {formatDate(article.isDraft ? article.createdAt : article.publishedAt)}
      </$ArticleDate>
      <SelectMenu menu={menu}>
        <Box>
          <IconButton size="small">
            <MoreFillIcon />
          </IconButton>
        </Box>
      </SelectMenu>
    </Box>
  );
}

export const $ArticleDate = styled(Typography, { label: 'ArticleDate' })({
  fontSize: 12,
  fontWeight: 400,
});

export default ArticleMenu;
