export const JAVA_SERVICE_URL = process.env.REACT_APP_JAVA_SERVICE_URL;
export const JAVA_SERVICE_ENDPOINTS = {
  // Meta
  API_VERSION_PREFIX: '/v1/api/',
  // Home page
  GET_RECENT_ACTIVITY: '/company/:companyId/recentActivity',
  GET_USER_METRICS: '/companyVault/:companyId/userMetrics/:username',
  GET_RECOMMENDED_POSTS: '/companyVault/:companyId/recommendedPosts/:username',
  GET_ENDORSED_POSTS: '/companyVault/:companyId/endorsedPosts',
  GET_POPULAR_POSTS: '/companyVault/:companyId/popularPosts',

  LOGIN: '/user/login',
  SSO_SIGN_IN: '/company/user/ssologin',
  VERIFY_DOMAIN: '/company/domain/verifyDomain',
  SIGNUP: '/user/signUp',
  VERIFY_CODE: '/user/verifyCode', // POST email, otp
  GET_USER_DETAILS: '/user',
  GET_COMPANY_DETAILS: '/company',
  EDIT_USER_DETAILS: '/user',
  UPDATE_PROFILE_PICTURE: 'company/:companyId/fileUpload',
  INVITE_USER: '/inviteUser',
  INVITE_EXTERNAL_USER: '/inviteExternalUser',
  FOLLOW_USER: '/user/:userId/follow',
  UNFOLLOW_USER: '/user/:userId/unfollow',
  ENDORSE_USER_SKILLS: '/user/endorseSkills',
  GET_USER_ARTICLES: '/articles/:userName',
  REFRESH_TOKEN: '/refreshToken',
  STAR_PROJECT: '/user/:companyId/starredProjects',
  STAR_WORKSPACE: '/user/:companyId/starredWorkSpaces',

  FEEDBACK: '/feedbackToNova',

  FORGOT_PASSWORD: '/forgotPassword', // POST email
  VALIDATE_OTP: '/validateOTP', // POST email, otp
  RESET_PASSWORD: '/resetPassword', // POST email, otpUid, password

  REPORT: '/complaint', // POST complaintUserName, complaintType, objectType, description, companyId

  KNOWLEDGE_GET_ARTICLES: '/companyVault/:companyId',
  KNOWLEDGE_GET_POST_ARTICLES: '/companyVault/:companyId/posts',
  KNOWLEDGE_GET_INSIGHT_ARTICLES: '/companyVault/:companyId/insightArticles',
  KNOWLEDGE_GET_PLAYLIST_ARTICLES: '/companyVault/:companyId/playlists',
  GET_ARTICLES_BOOKMARKED: '/company/:companyId/articles/bookmarked',
  GET_ARTICLES_DRAFTS: '/company/:companyId/articles/draft',
  GET_ARTICLE: '/company/:companyId/article/:articleId',
  GET_RELATED_ARTICLES: '/company/:companyId/article/:articleId/recommendations',
  GET_ARTICLE_COMMENTS: '/comment/company/:companyId/article/:articleId',
  ADD_ARTICLE: '/article/:companyId',
  FORWARD_ARTICLE: '/article/multi/:companyId',
  REVISE_ARTICLE: '/company/:companyId/article/:articleId/revise',
  MOVE_ARTICLE: '/company/:companyId/article/:articleId/repost',
  ADD_ARTICLE_MEDIA: '/company/:companyId/article/:articleId/addMedia',
  UPDATE_ARTICLE_MEDIA: '/company/:companyId/article/:articleId/updateMedia',
  ADD_COMMENT_MEDIA: '/company/:companyId/comment/:commentId/addMedia',
  UPDATE_COMMENT_MEDIA: '/company/:companyId/comment/:commentId/updateMedia',
  REMOVE_COMMENT_ATTCHMENT: '/company/:companyId/comment/:commentId/removeMedia/fileName/:fileName',
  EDIT_ARTICLE: '/company/:companyId/article/:articleId', // PUT
  SHARE_ARTICLE: '/company/:companyId/article/:articleId/userShare',
  REMOVE_ARTICLE_ATTACHMENT: '/company/:companyId/article/:articleId/removeMedia/attachment/:id', // POST
  ADD_COMMENT: '/comment',
  REPLY_COMMENT: '/comment',
  UPDATE_COMMENT: '/comment',
  LIKE_COMMENT: '/company/:companyId/comment/:commentId/like',
  REMOVE_COMMENT_LIKE: '/company/:companyId/comment/:commentId/removelike',
  ADD_BOOKMARK: '/article/addBookmark',
  REMOVE_BOOKMARK: '/article/removeBookmark',
  ADD_LIKE: '/like',
  REMOVE_LIKE: '/removelike',

  ADD_STAKEHOLDER: '/company/:companyId/stakeholders',
  GET_STAKEHOLDER: '/company/:companyId/stakeholder/:stakeholderId',
  GET_STAKEHOLDER_ARTICLES: '/articles/stakeHolder/:stakeholderId',
  GET_STAKEHOLDERS: '/company/:companyId/stakeholders',
  GET_STAKEHOLDERS_PAGINATION: '/company/:companyId/stakeholders?pageNo=:pageNo&pageSize=:pageSize',
  ADD_SCORECARD: '/company/:companyId/project/:projectId/scorecards',
  GET_SCORECARDS: '/company/:companyId/project/:projectId/scorecards',
  EDIT_SCORECARD: '/scorecard',
  GET_PROJECT_FORMS: '/company/:companyId/forms?projectId=:projectId',
  GET_ALL_FORMS_BY_CATEGORY:
    '/company/:companyId/config?configCategory=:configCategory',
  CREATE_FORM: '/form',
  UPDATE_FORM: '/form',
  DELETE_FORM: 'form/:formId',
  GET_FORM_DETAILS: 'form/:formId',
  GET_ASSIGNED_FORMS: '/company/:companyId/forms',

  PROCORE_CREATE_URL: '/procore/loginurl',
  PROCORE_LOGIN_URL: '/procore/user',
  GET_PROCORE_COMPANIES: '/procore/companies',
  GET_PROCORE_COMPANY_PROJECTS: '/procore/company/:companyId/projects',
  IMPORT_PROCORE_PROJECTS: '/procore/company/:companyId/projects/import',
  PROCORE_RFI_PDF: (pmProjectID: string, pmRFIId: number) =>
    `procore/project/${pmProjectID}/rfis/${pmRFIId}.pdf`,
  PROCORE_SUBMITTAL_PDF: (pmProjectID: string, pmSubmittalID: number) =>
    `procore/project/${pmProjectID}/submittals/${pmSubmittalID}.pdf`,

  ADD_QUESTION: '/question',
  GET_QUESTION: '/question/company/:companyId/question/:questionId',
  GET_QUESTION_COMMENTS: '/comment/company/:companyId/question/:questionId',
  PASS_QUESTION: '/question',
  GET_COMPANY_QUESTIONS: '/questions/company/:companyId',
  ANSWER_QUESTION: '/question/answer',

  GET_USER_ACTIVITY: '/userAudienceDetails',
  GET_AUDIENCE_SUMMARY: '/userAudienceSummary',
  GET_DASHBOARD_SUMMARY: '/companyDashboardSummary',

  GET_NOTIFICATIONS: '/notificationHistory',
  READ_NOTIFICATION: '/notification/:notificationId',
  READ_ALL_NOTIFICATION: '/notification/user', // PUT

  ADD_PROJECT: '/company/:companyId/project', // POST
  GET_INSIGHTS: '/company/:companyId/insights',
  GET_FILTERED_INSIGHTS: '/company/:companyId/businessObject/:objectType',
  GET_INSIGHTS_TYPE_DRAFTS: '/company/:companyId/articles/allDrafts',
  EDIT_PROJECT: '/company/:companyId/project/:projectId', // PUT
  GET_PROJECTS: '/company/:companyId/projects',
  GET_PROJECT: '/company/:companyId/project/:projectId',
  GET_PROJECT_RECOMMENDATIONS: '/company/:companyId/project/:projectId/recommendations',
  GET_PROJECTS_PAGINATION: '/company/:companyId/projects?pageNo=:pageNo&pageSize=:pageSize',
  GET_STARRED_PROJECTS: '/user/:companyId/starredProjects',
  ADD_POSTS_TO_PROJECT: '/company/:companyId/project/:projectId/recommendations',
  UPDATE_INSIGHT: '/company/:companyId/businessObject',

  // Configurations
  GET_COMPANY_CONFIGS_BY_TYPE: '/company/:companyId/config?configType=:configType',
  GET_ADMIN_CONFIGS_BY_TYPE: '/config?configType=:configType',
  GET_SUGGESTED_EXPERT_SKILLS: '/company/:companyId/config/userProfileSuggestedExpertSkills',
  GET_SUGGESTED_HOBBIES: '/company/:companyId/config/HOBBIES',
  GET_BUILDWISE_MODULES: '/company/:companyId/config/BUILDWISE_MODULES',
  GET_COMPANY: '/company/:companyId',
  GET_COMPANY_USERS: '/users/:companyId',
  PREDICT_COMPANY_DIVISIONS: '/company/:companyId/predict/divisions',
  PREDICT_COMPANY_DIVISION_SPEC_SECTIONS:
    '/company/:companyId/predict/division/:divisionCode/specsections',
  GET_DIVISION_SEPC_SECTIONS: '/company/:companyId/division/:divisionCode/specsections',
  COMPANY_CONFIG_DIVISIONS: '/company/:companyId/config/DIVISIONS',
  COMPANY_CONFIG_TOPICS: '/company/:companyId/config/TOPICS',
  COMPANY_CONFIG_USER_GROUPS: '/company/:companyId/config/USERGROUPS',
  COMPANY_CONFIG_PARTNER_ROLES: '/company/:companyId/config/PARTNER_ROLES',
  COMPANY_CONFIG_PROJECT_PHASES: '/company/:companyId/config/PROJECT_PHASES',
  COMPANY_CONFIG_SCORECARD_CATEGORIES: '/company/:companyId/config/SCORECARD_CATEGORIES',
  COMPANY_CONFIG_DEPARTMENTS: '/company/:companyId/config/DEPARTMENTS',
  UPLOAD_FILES: '/company/:companyId/fileUpload',
  REMOVE_FILES: '/company/:companyId/file',
  UPDATE_TOPIC_USER_GROUPS: '/config/:topicConfigId',

  COMPANY_CONFIG: '/company/:companyId/config/:configName',
  BUILDWISE_CONFIG: '/config/:configName',
  DELETE_CONFIG_BY_ID: '/config/:id',
  UPDATE_CONFIG: '/config',

  SEARCH: '/novaSearch',
  SEARCH_ITEM: '/search',
  CAPTURE_SEARCH_RESULT_CLICK: '/captureUserSearchInfo',

  RFID_ADD_LIKE: (companyId: string, projectId: string, rfiId: string) =>
    `/company/${companyId}/project/${projectId}/rfi/${rfiId}/like`,
  RFID_REMOVE_LIKE: (companyId: string, projectId: string, rfiId: string) =>
    `/company/${companyId}/project/${projectId}/rfi/${rfiId}/removelike`,
  LIKED_USERS: '/company/:companyId/article/:articleId/likedUsers',

  ADD_POSTS_TO_PLAYLIST: '/company/:companyId/playlist/:articleId/addPosts',
  GET_PLAYLISTS: '/company/:companyId/playlists',
  ADD_POST_TO_MANY_PLAYLISTS: '/company/:companyId/article/:articleId/addToPlaylists',
  REMOVE_POST_FROM_MANY_PLAYLISTS: '/company/:companyId/article/:articleId/removeFromPlaylists',
  COMPANY_FILES: '/company/:companyId/documents',
  GET_USER_COMPANIES: '/userCompanies',

  // Company Admin - Templates
  GET_TEMPLATES: '/company/:companyId/templates',
  GET_USER_TEMPLATES: '/templates/:userName',
  GET_TEMPLATE_DETAILS: '/company/:companyId/template/:templateId',
  ADD_TEMPLATE: '/template/:companyId',
  UPDATE_TEMPLATE: '/company/:companyId/template/:templateId',
  DELETE_TEMPLATE: '/company/:companyId/template/:templateId',

  // Bulk Update
  BULK_UPDATE_USERS: 'company/:companyId/users/bulkUpdate',
  BULK_UPDATE_PROJECTS: 'company/:companyId/projects/bulkUpdate',

  // BuildWise Admin - Companies
  GET_COMPANIES: '/companies',
  ADD_COMPANY: '/company',
  GET_USERS: '/users',
  POST_PROMPT: '/genAI',
  GET_BUILDWISE_GPT_HISTORY: '/user/:username/gptPromptHistory',
  PROMPT_BOOKAMRKS: '/user/:username/gptPromptHistory/bookMarked',
  PROMPT_TEMPLATES: '/gptPrompts/:companyId',
  ADD_PROMPT_TEMPLATE: '/gptPrompt',
  DELETE_PROMPT_TEMPLATE: '/gptPrompt/:promptId',
  BOOKMARK_PROMPT: '/gptPrompt/:promptId',
  UPDATE_LLM_SEARCH_PROMPT: '/gptPromptHistory',
};

export const PYTHON_SERVICE_URL = process.env.REACT_APP_PYTHON_SERVICE_URL;
export const PYTHON_SERVICE_ENDPOINTS = {
  ARTICLES_SEARCH: '/search/articleSearch/',
  ARTICLE_ATTACHMENT_SUMMARY: '/search/articleFileSummary/',
  ARTICLES_SUMMARY: '/search/ArticleSummary/',
  RFI_SUMMARY: '/search/rfiSummary/',
  COMPANY_FILES_SEARCH: '/search/filesSearch/',
  PROJECT_FILES_SEARCH: '/search/projectFileSearch/',
  GET_CURRENT_WORKSPACE: '/user/userExistingCompany/?userName=:userName',
  CHAT_WITH_ARTICLE: '/chat/chatWithArticles/',
  CHAT_WITH_BEDROCK: '/chat/chatWithBedrock/',
};

export const GEN_AI_SERVICE_URL = process.env.REACT_APP_GEN_AI_SERVICE_URL;
export const GEN_AI_SERVICE_ENDPOINTS = {
  POST_PROMPT: '/genAI',
  GENERATE_ARTICLE_SUMMARY : 'p1/genai/generateSummary/',
  GENERATE_ARTICLE_SUMMARY_FROM_FILE : 'p1/genai/generateArticleFromFile/?company_id=:company_id'
};
