import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';
import { useMemo } from 'react';
import useUserData from 'hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import KnowledgeAPI from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';

export type ArticleUserMapDetails = {
  profileImageURL?: string;
  displayName: string;
  id: string;
  title: string;
  userName: string;
  years: string;
};
export type ArticleUserMapType = {
  [email: string]: ArticleUserMapDetails[];
};

export type ArticleResponse = {
  articleCreatedBy: string;
  articleTitle: string;
  companyArticle: {
    article: ArticleType;
  };
  totalElements: number;
  totalNumber: number;
  totalPages: number;
  userExpYearsToDisplay: string;
  userMap: ArticleUserMapType;
  userNameToDisplay: string;
  userProfileImageURL: string;
  userTitleToDisplay: string;
};

type ArticleQueryPropTypes = {
  articleId: string;
  updateViewCount?: boolean;
  shouldFetch?: boolean;
};

export const useArticleDetailsQuery = ({
  articleId,
  updateViewCount,
  shouldFetch = true,
}: ArticleQueryPropTypes) => {
  const { companyId, user, isNovaOrCompanyAdmin } = useUserData();

  const { data: articleData, ...rest } = useQuery<AxiosResponse<ArticleResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_ARTICLE.replace(':companyId', companyId), articleId],
    () => KnowledgeAPI.getArticle(companyId, articleId, updateViewCount ? 'no' : 'yes'),
    {
      refetchOnMount: 'always',
      enabled: !!companyId && !!articleId && shouldFetch,
    },
  );

  const articleResponse = useMemo(() => articleData?.data, [articleData]);

  const isMyArticle = useMemo(() => {
    if (!articleResponse || !user) return false;

    return articleResponse.articleCreatedBy === user.username || isNovaOrCompanyAdmin;
  }, [articleResponse, user, isNovaOrCompanyAdmin]);

  return {
    articleResponse,
    isMyArticle,
    ...rest,
  };
};

type PaginatedRelatedArticlesResponse = {
  articles: ArticleType[];
  pageNumber: number;
  totalNumber: number;
  totalPages: number;
};

export const usePaginatedRelatedArticlesQuery = (options: {
  articleId: string;
  pageNo?: number;
  pageSize?: number;
  shouldFetch?: boolean;
}) => {
  const { articleId, pageNo = 0, pageSize = 10, shouldFetch = true } = options;
  const { companyId } = useUserData();
  const queryKey = [JAVA_SERVICE_ENDPOINTS.GET_RELATED_ARTICLES, companyId, articleId];

  const { data: relatedArticlesData, ...rest } = useInfiniteQuery<
    AxiosResponse<PaginatedRelatedArticlesResponse, Error>
  >(
    queryKey,
    ({ pageParam = pageNo }) =>
      KnowledgeAPI.getPaginatedRelatedArticles(companyId, articleId, pageParam, pageSize),
    {
      enabled: shouldFetch && !!companyId && !!articleId,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage?.data?.totalPages > lastPage?.data?.pageNumber
          ? lastPage.data.pageNumber + 1
          : undefined,
    },
  );

  const articles = useMemo(() => {
    const allArticles: ArticleType[] = [];

    relatedArticlesData?.pages?.forEach((page) => {
      if (page?.data) {
        allArticles.push(...(page?.data?.articles || []));
      }
    });

    return allArticles;
  }, [relatedArticlesData?.pages]);

  const resetPaginatedRelatedArticlesQuery = () => {
    queryClient.resetQueries(queryKey, {
      refetchPage: (_: unknown, index: number) => index === 0,
    });
  };

  const updatePaginatedRelatedArticlesCache = ({
    updateArticleId,
    updatedArticle,
  }: {
    updateArticleId: string;
    updatedArticle: ArticleType;
  }) => {
    queryClient.setQueryData<
      InfiniteData<AxiosResponse<PaginatedRelatedArticlesResponse, Error>> | undefined
    >(queryKey, (old) => {
      if (old) {
        const pages = [...old.pages];
        let pageIndex = -1;
        let articleIndex = -1;

        // find pageIndex and articleIndex in all pages
        for (let i = 0; i < pages.length; i++) {
          const articles = [...(pages[i]?.data?.articles || [])];
          for (let j = 0; j < articles.length; j++) {
            if (articles[j].id === updateArticleId) {
              articleIndex = j;
              break;
            }
          }

          if (articleIndex > -1) {
            pageIndex = i;
            break;
          }
        }

        // if article not exists, set pageIndex=0 to create a new article in first page
        if (pageIndex === -1) {
          pageIndex = 0;
        }

        const updatedAricles = [...(pages[pageIndex]?.data?.articles || [])];

        if (articleIndex > -1) {
          updatedAricles[articleIndex] = {
            ...updatedAricles[articleIndex],
            ...updatedArticle,
          };
        }

        pages[pageIndex] = {
          ...pages[pageIndex],
          data: {
            ...pages[pageIndex].data,
            articles: updatedAricles,
          },
        };

        return {
          ...old,
          pages,
        };
      }

      return old;
    });
  };

  return {
    articles,
    ...rest,
    resetPaginatedRelatedArticlesQuery,
    updatePaginatedRelatedArticlesCache,
  };
};

export const invalidateArticleDetailsQueries = (companyId, articleId) => {
  queryClient.refetchQueries(
    JAVA_SERVICE_ENDPOINTS.GET_ARTICLE.replace(':companyId', companyId).replace(
      ':articleId',
      articleId,
    ),
  );
};

type CommentsResponse = {
  articleId: string;
  companyId: string;
  comments: CommentType[];
};

type MentionType = {
  id: string | null;
  userName: string;
  displayName: string;
  title: string | null;
  years: string | null;
  profileImageUrl: string | null;
};

export type LikedUserType = {
  displayName: string;
  id: string;
  pmuserId: string | null;
  profileImageURL: string;
  title: string;
  userName: string;
};

export type CommentReplyType = CommentType & { parentId: string };

export type CommentType = {
  articleCreatedBy: string;
  articleId: string;
  articleTitle: string;
  attachments?: AttachmentType[];
  commentAuthor: string;
  commentAuthorDisplayExpYears: string;
  commentAuthorDisplayName: string;
  commentAuthorDisplayTitle: string;
  commentText: string;
  commentedAt: Date | string;
  companyId: string;
  id: string;
  likedUsers: LikedUserType[];
  mentions: MentionType[];
  primaryCompanyName?: string;
  profileImageURL?: string;
  replies?: CommentReplyType[];
  taggedTo: string[];
  topicsAutoGenerated: any;
  updatedBy?: string;
  userPrimaryCompanyName?: string;
};

export const useArticleCommentsQuery = ({ articleId }: ArticleQueryPropTypes) => {
  const { companyId } = useUserData();

  const { data: commentsData, ...rest } = useQuery<AxiosResponse<CommentsResponse>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_ARTICLE_COMMENTS.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
    ],
    () => KnowledgeAPI.getArticleComments(companyId, articleId),
    {
      staleTime: 60 * 1000,
      refetchOnMount: 'always',
      enabled: !!companyId && !!articleId,
    },
  );

  const comments = useMemo(() => {
    return commentsData?.data?.comments || [];
  }, [commentsData]);

  return {
    comments,
    ...rest,
  };
};

export const updateArticleCommentsCache = (
  companyId: string,
  articleId: string,
  comment: CommentType | CommentReplyType | undefined,
  action: 'add' | 'update' | 'like' | 'reply' | 'delete',
  commentPath: string = '',
) => {
  queryClient.setQueriesData<AxiosResponse<CommentsResponse> | undefined>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_ARTICLE_COMMENTS.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
    ],
    (oldData: AxiosResponse<CommentsResponse> | undefined) => {
      if (oldData && comment) {
        const oldComments = [...(oldData?.data?.comments || [])];

        if (action === 'add') {
          oldComments.unshift(comment);
        }

        if (commentPath) {
          let comments = oldComments;
          const commentIds = commentPath.split('/');

          for (let i = 0; i < commentIds.length; i++) {
            const commentIndex = comments.findIndex((comment) => comment.id === commentIds[i]);

            if (commentIndex === -1) {
              break;
            }

            if (commentIndex > -1) {
              if (i !== commentIds.length - 1) {
                comments = comments[commentIndex]?.replies || [];
              } else {
                if (action === 'delete') {
                  comments.splice(commentIndex, 1);
                }

                if (action === 'update' || action === 'like') {
                  comments[commentIndex] = {
                    ...comment,
                    replies: comments[commentIndex].replies,
                  };
                }

                if (action === 'reply') {
                  if (comments[commentIndex].replies) {
                    comments[commentIndex].replies?.unshift(comment as CommentReplyType);
                  } else {
                    comments[commentIndex] = {
                      ...comments[commentIndex],
                      replies: [comment as CommentReplyType],
                    };
                  }
                }
              }
            }
          }
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            comments: [...oldComments],
          },
        };
      }

      return oldData;
    },
  );
};

export const updateArticleDetailsCache = (companyId: string, updatedArticle: ArticleType) => {
  queryClient.setQueriesData<AxiosResponse<ArticleResponse> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_ARTICLE.replace(':companyId', companyId), updatedArticle?.id],
    (oldData: AxiosResponse<ArticleResponse> | undefined) => {
      if (oldData) {
        oldData = {
          ...oldData,
          data: {
            ...oldData.data,
            companyArticle: {
              article: {
                ...oldData?.data.companyArticle.article,
                ...updatedArticle,
              },
            },
            userMap: updatedArticle?.userMap || {},
          },
        };
        return oldData;
      }

      return oldData;
    },
  );
};

export default useArticleDetailsQuery;
