import { Typography } from '@mui/material';
import Loader from 'components/common/loader/Loader';
import { Fragment, FunctionComponent, PropsWithChildren, ReactNode } from 'react';

type HandleOperationProps<T = any> = {
  SkeltonWrapper?: FunctionComponent<{ children: ReactNode }>;
  data?: T[];
  errorPlaceholder?: ReactNode;
  isError?: boolean;
  isLoading?: boolean;
  noDataComponent?: ReactNode;
  skelton?: ReactNode;
  skeltonCount?: number;
};

function HandleOperation<T>({
  SkeltonWrapper,
  children,
  data,
  errorPlaceholder,
  isError,
  isLoading,
  noDataComponent,
  skelton,
  skeltonCount = 10,
}: PropsWithChildren<HandleOperationProps<T>>) {
  if (isLoading) {
    if (skelton) {
      const skeltons = (
        <>
          {Array.from({ length: skeltonCount }).map((_, index) => (
            <Fragment key={index}>{skelton}</Fragment>
          ))}
        </>
      );
      if (SkeltonWrapper) {
        return <SkeltonWrapper>{skeltons}</SkeltonWrapper>;
      }
      return skeltons;
    }

    return <Loader />;
  }

  if (isError) {
    if (errorPlaceholder) {
      return <>{errorPlaceholder}</>;
    }

    return <Typography>Something went wrong!</Typography>;
  }

  if (data?.length === 0) {
    return <>{noDataComponent}</>;
  }

  return <>{children}</>;
}

export default HandleOperation;
