import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';
import { CommentType } from '@buildwise/pages/article/hooks/useArticleDetailsQuery';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { ProjectType } from '@buildwise/pages/projects/hooks/useProjectsQuery';
import {
  PartnerType,
  ScoreCardType,
} from '@buildwise/pages/stakeholders/hooks/useStakeholdersQuery';
import { ArticlesResponse } from 'api/KnowledgeAPI';
import { UserType } from 'hooks/useUserData';

export type FileActivityType = {
  articleId?: string;
  companyId?: string;
  createdAt: Date | string;
  createdBy?: string;
  documentDirectory?: string;
  documentName: string;
  documentPDF?: string;
  documentSize: number;
  documentType: string;
  documentURL: string;
  documentUploaded: boolean;
  generatePreSignedURLFlag: boolean;
  id?: string;
  objectId: string;
  objectType: 'COMPANY' | 'PROJECT' | 'ARTICLE' | 'COMMENT';
  objectName: string;
  projectId?: string;
  s3Key?: string;
  uploadedToOSS: boolean;
};

class HomeAPI extends JavaAPI {
  public getRecentActivity(companyId: string) {
    return this.get<{
      articles: ArticleType[];
      comments: CommentType[];
      documents: FileActivityType[];
      partners: PartnerType[];
      projects: ProjectType[];
      scoreCards: ScoreCardType[];
      users: UserType[];
      searchKeywords: string[];
    }>(JAVA_SERVICE_ENDPOINTS.GET_RECENT_ACTIVITY.replace(':companyId', companyId));
  }

  public getUserMetrics(companyId: string, username: string) {
    return this.get<{
      totalArticlesCreated: number;
      totalArticleViews: number;
      totalArticleLikes: number;
      totalArticleComments: number;
    }>(
      JAVA_SERVICE_ENDPOINTS.GET_USER_METRICS.replace(':companyId', companyId).replace(
        ':username',
        encodeURIComponent(username),
      ),
    );
  }

  public getRecommendedPosts(
    companyId: string,
    username: string,
    pageNo: number,
    pageSize: number,
  ) {
    const params = new URLSearchParams({
      isPersonalised: 'false',
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.GET_RECOMMENDED_POSTS.replace(':companyId', companyId).replace(
        ':username',
        encodeURIComponent(username),
      )}?${params.toString()}`,
    );
  }

  public getEndorsedPosts(companyId: string) {
    return this.get<ArticlesResponse[]>(
      JAVA_SERVICE_ENDPOINTS.GET_ENDORSED_POSTS.replace(':companyId', companyId),
    );
  }

  public getPopularPosts(companyId: string, duration: string) {
    return this.get<ArticlesResponse[]>(
      `${JAVA_SERVICE_ENDPOINTS.GET_POPULAR_POSTS.replace(':companyId', companyId)}?duration=${duration}`,
    );
  }
}

const homeAPI = new HomeAPI();
export default homeAPI;
