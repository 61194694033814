import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArticleType } from '../hooks/useArticlesQuery';
import BookletLineIcon from 'remixicon-react/BookletLineIcon';
import { Dispatch } from 'react';
import { UseArticleReducerActions } from '../hooks/useArticle';

type PlayListBadgeProps = {
  article: ArticleType;
  hasEditAccess?: boolean;
  isDetailedView?: boolean;
  dispatch?: Dispatch<UseArticleReducerActions>;
};

function PlaylistBadge({ article, hasEditAccess, isDetailedView, dispatch }: PlayListBadgeProps) {
  return (
    <$Container>
      <$ArticlesCount>
        <BookletLineIcon size={22} />
        <$PostsCount>
          {article?.playListArticles?.length || article?.playListArticleIds?.length}{' '}
          {article?.playListArticles?.length === 1 || article?.playListArticleIds?.length === 1
            ? 'Post'
            : 'Posts'}
        </$PostsCount>
      </$ArticlesCount>
      {isDetailedView ? (
        hasEditAccess ? (
          <$ViewPlaylist
            onClick={() =>
              dispatch?.({ type: 'TOGGLE_EDIT_MODAL', payload: true, key: 'openEditPost' })
            }
          >
            Edit
          </$ViewPlaylist>
        ) : null
      ) : (
        <$ViewPlaylist>View Full Collection</$ViewPlaylist>
      )}
    </$Container>
  );
}

const $Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  margin: '10px 0',
  backgroundColor: theme.palette.custom.earlyDawn,
  color: theme.palette.custom.lightOrange,
  fontWeight: '700',
  borderRadius: 4,
}));

const $ArticlesCount = styled(Box, { label: 'ArticlesCount' })({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
});

const $PostsCount = styled(Typography, { label: 'PostsCount' })({
  fontWeight: 700,
});

const $ViewPlaylist = styled(Typography, { label: 'ViewPlaylist' })({
  fontWeight: 700,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export default PlaylistBadge;
