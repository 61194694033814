import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

class InsightAPI extends JavaAPI {
  public getInsight(companyId: string, insightId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS.replace(':companyId', companyId).replace(
        ':insightId',
        insightId,
      )}`,
    );
  }

  public like(companyId: string, projectId: string, rfid: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.RFID_ADD_LIKE(companyId, projectId, rfid));
  }

  public removeLike(companyId: string, projectId: string, rfid: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.RFID_REMOVE_LIKE(companyId, projectId, rfid));
  }

  public feedback(payload: any, file: any, thumbnail: any, onUploadProgress?: any) {
    const formBody = new FormData();
    if (file) {
      formBody.append('file', file);
    }
    if (thumbnail) {
      formBody.append('thumbnail', thumbnail);
    }
    formBody.append('article', JSON.stringify(payload));

    return this.post(
      JAVA_SERVICE_ENDPOINTS.FEEDBACK,
      formBody,
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }
}

export default new InsightAPI();
