/* 
  References: 
    https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
    https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Containers
*/
export const IMAGE_TYPES = [
  'apng',
  // 'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  // 'webp',

  'image/apng',
  // 'image/avif',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/png',
  // 'image/webp',
];

export const AUDIO_TYPES = [
  'mp3',
  'wav',
  '3gp',
  'mpg',
  'mpeg',
  'm4a',
  'oga',
  'ogg',
  'webm',

  'audio/mp3',
  'audio/wav',
  'audio/3gp',
  'audio/mpg',
  'audio/mpeg',
  'audio/m4a',
  'audio/oga',
  'audio/ogg',
  'audio/webm',
];

export const VIDEO_TYPES = [
  'mp4',
  'webm',
  '3gp',
  'mpg',
  'mpeg',
  'm4v',
  'm4p',
  'ogv',
  'ogg',
  'mov',
  'avi',
  'quicktime',

  'video/mp4',
  'video/webm',
  'video/3gp',
  'video/mpg',
  'video/mpeg',
  'video/m4v',
  'video/m4p',
  'video/ogv',
  'video/ogg',
  'video/mov',
  'video/avi',
  'video/quicktime',
  'video/x-matroska',
];

export const DOCUMENT_TYPES = [
  'doc',
  'docx',
  'csv',
  'pdf',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'vnd.ms-excel',
  'vnd.ms-powerpoint',
  'msword',

  'application/doc',
  'application/docx',
  'text/csv',
  'application/pdf',
  'application/xlsx',
  'application/xls',
  'application/ppt',
  'application/pptx',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/msword',
];

export const ATTACHMENT_TYPES = [...IMAGE_TYPES, ...AUDIO_TYPES, ...VIDEO_TYPES, ...DOCUMENT_TYPES];

export const MEDIA_ATTACHMENT_TYPES = [...IMAGE_TYPES, ...AUDIO_TYPES, ...VIDEO_TYPES];
