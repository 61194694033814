import { useQuery, useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import useAuthStore from 'store/useAuthStore';
import { shallow } from 'zustand/shallow';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import UserAPI from 'api/UserAPI';
import { AxiosResponse } from 'axios';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import { CompanyType } from './useCompanyQueries';
import {
  deleteChatFromSessionStorage,
  isNovaAdminRole,
  isNovaOrCompanyAdminRole,
} from 'utility/helpers';

export type RoleType = {
  roleDescription: string;
  roleName: string;
};

export type UserType = {
  active: boolean;
  associatedCompanies?: UserType[];
  canCreatePlaylistFlag: boolean;
  canEndorseFlag: boolean;
  canInviteOthersFlag: boolean;
  canMovePostsToTopFlag: boolean;
  canSuggestRevisiontToPostsFlag: boolean;
  city: string;
  companyId: string;
  country_code: string;
  department_or_region: string;
  departmentsForNotifications: string[];
  displayName?: string;
  email: string;
  endorsements?: {
    skills: string[];
    title: string | null | undefined;
    userName: string | undefined;
    userNameToDisplay: string | undefined;
  }[];
  expYears: string;
  firstname: string;
  followers: string[];
  following: string[];
  hobbies?: string[];
  id: string;
  starredProjects?: string[];
  gptPromptIds?: string[];
  gptPromptHistoryIds?: string[];
  lastname: string;
  learningSkills?: string[];
  mySkills?: string[];
  notificationSettings: any;
  phoneNumber: string;
  pmuserId?: string;
  preferences: {
    projects: any[];
    topics: string[];
    projectPhases: string[];
    projectTypes: any[];
    createdBy: any[];
    divisions: string[];
  };
  primaryCompanyId?: string;
  primaryCompanyName?: string;
  profileImageURL?: string;
  projectIds: any[];
  projects: ProjectType[];
  projectsInterested?: string[];
  topics?: string[];
  roles: RoleType[];
  state_code: string;
  title: string;
  topicsAutoGenerated: any;
  topicsAutoGeneratedFromArticles: unknown;
  topicsAutoGeneratedFromComments: unknown;
  topicsForNotifications?: string[];
  userName?: string;
  userNameToDisplay: string;
  userPrimaryCompanyName?: string;
  userTotalArticles: number;
  userTotalProjects: number;
  username: string;
  years?: string;
};

export type UserArticlesResponse = {
  articles: ArticleType[];
};

export const useUserData = (
  options: {
    shouldFetchUserDetails?: boolean;
    shouldFetchCompanyDetails?: boolean;
    selectedCompanyId?: string;
  } = {},
) => {
  const {
    shouldFetchUserDetails = true,
    shouldFetchCompanyDetails = true,
    selectedCompanyId = '',
  } = options;
  const queryClient = useQueryClient();
  const [accessToken, resetState, userDetails] = useAuthStore(
    (state) => [state.accessToken, state.resetState, state.user],
    shallow,
  );

  const { data: companyData, ...companyQueryInfo } = useQuery<AxiosResponse<CompanyType>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_COMPANY_DETAILS, selectedCompanyId || userDetails?.companyId],
    () => UserAPI.getCompanyDetails(selectedCompanyId || userDetails?.companyId),
    {
      onError: () => {
        resetState();
      },
      staleTime: 5 * 60 * 1000,
      enabled: !!(selectedCompanyId || userDetails?.companyId) && shouldFetchCompanyDetails,
    },
  );

  const {
    data: userData,
    refetch: refetchUserData,
    ...userInfo
  } = useQuery<AxiosResponse<UserType>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_USER_DETAILS, userDetails?.email],
    () => UserAPI.getUser(userDetails?.email),
    {
      onError: () => {
        resetState();
      },
      staleTime: 5 * 60 * 1000,
      enabled: !!accessToken && !!userDetails?.email && shouldFetchUserDetails,
    },
  );
  const user = useMemo(() => (accessToken ? userData?.data : undefined), [userData, accessToken]);
  const companyId = useMemo(
    () =>
      selectedCompanyId || userDetails?.companyId
        ? selectedCompanyId || userDetails?.companyId
        : user?.companyId || '',
    [user, userDetails, selectedCompanyId],
  );
  const {
    isNovaAdmin,
    isNovaOrCompanyAdmin,
    isBuildWiseInsightsUser,
    isAssociatedWithOtherCompanies,
  } = useMemo(() => {
    const isNovaAdmin = isNovaAdminRole(user?.roles);

    return {
      isNovaAdmin,
      isNovaOrCompanyAdmin: isNovaOrCompanyAdminRole(user?.roles),
      isBuildWiseInsightsUser: user?.username === 'buildwise.insights@buildwise.ai',
      isAssociatedWithOtherCompanies:
        !isNovaAdmin &&
        !!user?.associatedCompanies &&
        !!user?.associatedCompanies.find((company) => company.companyId !== user?.companyId),
    };
  }, [user?.roles, user?.username, user?.associatedCompanies]);

  const company = useMemo(() => (companyData ? companyData?.data : null), [companyData]);

  const logout = useCallback(() => {
    resetState();
    queryClient.removeQueries();
    deleteChatFromSessionStorage({ companyId });
  }, [queryClient, resetState]);

  const updateCompanyDetailsCache = (updatedCompany: CompanyType) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY_DETAILS,
      companyId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueriesData<AxiosResponse<CompanyType> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_COMPANY_DETAILS, companyId],
        (old: AxiosResponse<CompanyType> | undefined) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                ...updatedCompany,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    accessToken,
    company,
    companyId,
    companyQueryInfo,
    isAssociatedWithOtherCompanies,
    isBuildWiseInsightsUser,
    isNovaAdmin,
    isNovaOrCompanyAdmin,
    user,
    userInfo,
    logout,
    refetchUserData,
    updateCompanyDetailsCache,
  };
};

export const useUserArticlesQuery = (
  options: { username?: string; shouldFetch?: boolean } = {},
) => {
  const { user } = useUserData();
  const { username = user?.username, shouldFetch = true } = options;
  const queryClient = useQueryClient();

  const userArticlesQueryKey = [JAVA_SERVICE_ENDPOINTS.GET_USER_ARTICLES, username];

  const { data: articlesData, ...rest } = useQuery<AxiosResponse<UserArticlesResponse>, Error>(
    userArticlesQueryKey,
    () => UserAPI.getUserArticles(username || ''),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
      enabled: !!username && shouldFetch,
    },
  );
  const articles = useMemo(() => articlesData?.data?.articles || [], [articlesData]);

  const updateUserArticlesCache = ({
    action = 'update',
    article,
    _username = user?.username,
  }: {
    action?: 'add' | 'update' | 'delete';
    article: ArticleType;
    _username?: string;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_USER_ARTICLES,
      _username,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<UserArticlesResponse, Error> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_USER_ARTICLES, _username],
        (old) => {
          if (old) {
            const updatedArticles = [...(old?.data?.articles || [])];

            if (action === 'add') {
              updatedArticles.unshift(article);
            }

            if (action === 'update') {
              const index = updatedArticles.findIndex(
                (articleData) => articleData.id === article.id,
              );

              if (index > -1) {
                updatedArticles[index] = { ...updatedArticles[index], ...article };
              } else {
                updatedArticles.unshift(article);
              }
            }

            if (action === 'delete') {
              const index = updatedArticles.findIndex(
                (articleData) => articleData.id === article.id,
              );

              updatedArticles.splice(index, 1);
            }

            return {
              ...old,
              data: {
                articles: updatedArticles,
              },
            };
          }

          return old;
        },
      );
    }
  };

  const updatePostInUserPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<UserArticlesResponse, Error> | undefined>(
      userArticlesQueryKey,
      (old) => {
        if (old) {
          let newPlaylists = [...(old?.data?.articles || [])];

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            newPlaylists = newPlaylists.map((playlist) => {
              if (addToPlaylistIds.includes(playlist.id)) {
                return {
                  ...playlist,
                  playListArticleIds: [...playlist?.playListArticleIds, article.id],
                  playListArticles: [...playlist?.playListArticles, article],
                };
              }

              if (removeFromPlaylistIds.includes(playlist.id)) {
                const newPlaylistArticleIds = [...playlist?.playListArticleIds];
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const newPlaylistArticles = [...playlist?.playListArticles];
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }

                return {
                  ...playlist,
                  playListArticleIds: newPlaylistArticleIds,
                  playListArticles: newPlaylistArticles,
                };
              }

              return playlist;
            });
          }

          return {
            ...old,
            data: {
              ...old.data,
              articles: newPlaylists,
            },
          };
        }

        return old;
      },
    );
  };

  return {
    articles,
    ...rest,
    updatePostInUserPlaylistsCache,
    updateUserArticlesCache,
  };
};

export default useUserData;
