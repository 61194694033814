import { FC, lazy, ReactNode, Suspense, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Header from 'components/header/Header';
import Loader from 'components/common/loader/Loader';
import useShareModalStore from 'store/useShareModalStore';
import { shallow } from 'zustand/shallow';

const InviteUserModal = lazy(() => import('components/modals/inviteUserModal/InviteUserModal'));
const ReportModal = lazy(() => import('components/modals/reportModal/ReportModal'));
const ShareModal = lazy(() => import('components/modals/shareModal/ShareModal'));

export const $Root = styled(Box, { label: 'Root' })(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  minHeight: theme.vh100,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const $Container = styled(Box, { label: 'ContentBox' })(({ theme }) => ({
  maxWidth: '1440px',
  margin: '0 auto',
  padding: '16px 48px 0',
  width: '100%',
  display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    padding: '16px 24px 16px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 8px 0',
  },
}));

export const $ContentBox = styled('main', { label: 'ContentBox' })(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
}));

type MenuWrapperProps = {
  sidebar: FC;
  children: ReactNode;
};

const MainWrapper: FC<MenuWrapperProps> = ({ sidebar: Sidebar, children }) => {
  const { shareData } = useShareModalStore((state) => ({ ...state }), shallow);
  const openShareModal = useMemo(() => !!shareData, [shareData]);

  return (
    <$Root>
      <Header />
      <$Container>
        <Sidebar />
        <$ContentBox>{children}</$ContentBox>
      </$Container>

      <Suspense fallback={<Loader variant="fixed" />}>
        {openShareModal && <ShareModal />}
        <ReportModal />
        <InviteUserModal />
      </Suspense>
    </$Root>
  );
};

export default MainWrapper;
