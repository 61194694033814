import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Loader from '../components/common/loader/Loader';
import useUserData from '../hooks/useUserData';
import {
  BOOKMARKS,
  HOME,
  DASHBOARD,
  DRAFTS,
  FEED,
  LOGIN,
  MY_PROFILE,
  PEOPLE,
  PROJECT,
  PROJECTS,
  QUESTIONS_BY_ME,
  QUESTIONS_FOR_ME,
  SEARCH,
  STAKEHOLDER,
  STAKEHOLDERS,
  USER_POSTS,
  USER_PROFILE,
  VIEW_ARTICLE,
  VIEW_QUESTION,
  GET_STARTED,
  INSIGHTS,
  INSIGHT,
  NOTIFICATION,
  STAKEHOLDER_BULK_UPDATE,
  PEOPLE_BULK_UPDATE,
  PROJECT_BULK_UPDATE,
  VIEW_DRAFT,
  FILES,
  COLLECTIONS,
  STAKEHOLDER_FORM,
  CREATE_PROJECT_PROGRESS_CARD,
  CREATE_STAKEHOLDER_FORM,
  ADD_PROJECT,
  EDIT_PROJECT,
  PROJECT_SCORECARD,
  PROJECT_PROGRESS_CARD,
  CHAT,
  CREATE_PROJECT_SCORECARD,
  REQUESTS,
  ADD_PARTNER,
  EDIT_PARTNER,
} from '../constants/routes';
import MainWrapper from '../components/main/MainWrapper';
import { getAdminRoutePath } from '../utility/helpers';
import MainDrawer from 'components/drawer/MainDrawer';
import PageNotFound from './PageNotFound';
import BuildWiseLoader from './BuildWiseLoader';

const Home = lazy(() => import('@buildwise/pages/Home'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Feed = lazy(() => import('@buildwise/pages/Feed'));
const UserPosts = lazy(() => import('../pages/userPosts/UserPosts'));
const Bookmarks = lazy(() => import('../pages/bookmarks/Bookmarks'));
const Article = lazy(() => import('../pages/article/Article'));
const Chat = lazy(() => import('@buildwise/pages/Chat'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const Projects = lazy(() => import('../pages/projects/Projects'));
const Project = lazy(() => import('../pages/project/Project'));
const UserForm = lazy(() => import('../pages/forms/UserForm'));
const ProjectForm = lazy(() => import('@buildwise/pages/ProjectForm'));
const QuestionView = lazy(() => import('../pages/questions/QuestionView'));
const People = lazy(() => import('../pages/people/People'));
const Drafts = lazy(() => import('../pages/drafts/Drafts'));
const Requests = lazy(() => import('@buildwise/pages/Requests'));
const QuestionsByMe = lazy(() => import('../pages/questions/QuestionsByMe'));
const QuestionsForMe = lazy(() => import('../pages/questions/QuestionsForMe'));
const Stakeholders = lazy(() => import('../pages/stakeholders/Stakeholders'));
const AddPartner = lazy(() => import('@buildwise/pages/AddPartner'));
const StakeholdersBulkUpdate = lazy(() => import('../pages/stakeholders/BulkUpdateStakeholders'));
const Stakeholder = lazy(() => import('../pages/stakeholder/Stakeholder'));
const Search = lazy(() => import('../pages/search/Search'));
const Insights = lazy(() => import('../pages/projects/Insights'));
const CompanyFiles = lazy(() => import('pages/companyFiles/CompanyFiles'));
const Insight = lazy(() => import('../pages/insight/Insight'));
const Notifications = lazy(() => import('../pages/notifications/Notifications'));
const PeopleBulkUpdate = lazy(() => import('../pages/people/BulkUpdateUsers'));
const ProjectBulkUpdate = lazy(() => import('../pages/project/BulkUpdateProjects'));
const Playlists = lazy(() => import('@buildwise/pages/Playlists'));

const DashboardRoutes = () => {
  const navigate = useNavigate();
  const { user, userInfo, companyQueryInfo } = useUserData();

  useEffect(() => {
    if (!user && !userInfo.isLoading) {
      navigate(LOGIN);
    } else if (user) {
      if (!user.firstname || !user.lastname) {
        navigate(GET_STARTED);
      }
    }
  }, [user, userInfo.isLoading]);

  if (userInfo.isLoading || companyQueryInfo.isLoading) {
    return <BuildWiseLoader />;
  }

  return (
    <MainWrapper sidebar={MainDrawer}>
      <Suspense fallback={<Loader variant="fixed" />}>
        <Routes>
          <Route path={getAdminRoutePath(HOME)} element={<Home />} />
          <Route path={getAdminRoutePath(DASHBOARD)} element={<Dashboard />} />
          <Route path={getAdminRoutePath(FEED)} element={<Feed />} />
          <Route path={getAdminRoutePath(COLLECTIONS)} element={<Playlists />} />
          <Route path={getAdminRoutePath(SEARCH)} element={<Search />} />
          <Route path={getAdminRoutePath(CHAT)} element={<Chat />} />
          <Route path={getAdminRoutePath(VIEW_ARTICLE)} element={<Article />} />
          <Route path={getAdminRoutePath(VIEW_DRAFT)} element={<Article />} />
          <Route path={getAdminRoutePath(MY_PROFILE)} element={<Profile />} />
          <Route path={getAdminRoutePath(STAKEHOLDERS)} element={<Stakeholders />} />
          <Route path={getAdminRoutePath(ADD_PARTNER)} element={<AddPartner />} />
          <Route path={getAdminRoutePath(EDIT_PARTNER)} element={<AddPartner />} />
          <Route path={getAdminRoutePath(STAKEHOLDER)} element={<Stakeholder />} />
          <Route path={getAdminRoutePath(INSIGHTS)} element={<Insights />} />
          <Route path={getAdminRoutePath(FILES)} element={<CompanyFiles />} />
          <Route path={getAdminRoutePath(INSIGHT)} element={<Insight />} />
          <Route path={getAdminRoutePath(PROJECTS)} element={<Projects />} />
          <Route path={getAdminRoutePath(PROJECT)} element={<Project />} />
          <Route path={getAdminRoutePath(PROJECT_SCORECARD)} element={<UserForm />} />
          <Route path={getAdminRoutePath(PROJECT_PROGRESS_CARD)} element={<UserForm />} />
          <Route path={getAdminRoutePath(STAKEHOLDER_FORM)} element={<UserForm />} />
          <Route path={getAdminRoutePath(CREATE_PROJECT_PROGRESS_CARD)} element={<UserForm />} />
          <Route path={getAdminRoutePath(CREATE_PROJECT_SCORECARD)} element={<UserForm />} />
          <Route path={getAdminRoutePath(CREATE_STAKEHOLDER_FORM)} element={<UserForm />} />
          <Route path={getAdminRoutePath(ADD_PROJECT)} element={<ProjectForm />} />
          <Route path={getAdminRoutePath(EDIT_PROJECT)} element={<ProjectForm />} />
          <Route path={getAdminRoutePath(PEOPLE)} element={<People />} />
          <Route path={getAdminRoutePath(BOOKMARKS)} element={<Bookmarks />} />
          <Route path={getAdminRoutePath(DRAFTS)} element={<Drafts />} />
          <Route path={getAdminRoutePath(REQUESTS)} element={<Requests />} />
          <Route path={getAdminRoutePath(VIEW_QUESTION)} element={<QuestionView />} />
          <Route path={getAdminRoutePath(QUESTIONS_BY_ME)} element={<QuestionsByMe />} />
          <Route path={getAdminRoutePath(QUESTIONS_FOR_ME)} element={<QuestionsForMe />} />
          <Route path={getAdminRoutePath(USER_POSTS)} element={<UserPosts />} />
          <Route path={getAdminRoutePath(USER_PROFILE)} element={<Profile />} />
          <Route path={getAdminRoutePath(NOTIFICATION)} element={<Notifications />} />
          <Route
            path={getAdminRoutePath(STAKEHOLDER_BULK_UPDATE)}
            element={<StakeholdersBulkUpdate />}
          />
          <Route path={getAdminRoutePath(PEOPLE_BULK_UPDATE)} element={<PeopleBulkUpdate />} />
          <Route path={getAdminRoutePath(PROJECT_BULK_UPDATE)} element={<ProjectBulkUpdate />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </MainWrapper>
  );
};

export default DashboardRoutes;
