import { styled, useTheme } from '@mui/material/styles';
import useDraftsQuery from '@buildwise/pages/drafts/hooks/useDraftsQuery';
import DraftFillIcon from 'remixicon-react/DraftFillIcon';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import RenderList, { RenderListProps } from './RenderList';
import { useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { DRAFTS, REQUESTS } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import SurveyFillIcon from 'remixicon-react/SurveyFillIcon';
import { useAssignedFormsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import { ReactComponent as ProgressCardActiveIcon } from 'assets/icons/progress_card_active.svg';
import PendingFormsModal from 'components/modals/assignedToModal/PendingFormsModal';
import FileListFillIcon from 'remixicon-react/FileListFillIcon';
import RequestedPostsModal from './request-post/RequestedPostsModal';

function YourTasks() {
  const { company, user, companyId } = useUserData();
  const { assignedProgresscards, assignedScorecards, requestedPosts } = useAssignedFormsQuery({
    companyId: companyId || '',
    assignedTo: user?.username || '',
    status: 'NEW-INPROGRESS',
    shouldFetch: false,
  });

  const theme = useTheme();
  const [queryParams] = useSearchParams();
  const { drafts } = useDraftsQuery({
    shouldFetch: false,
  });
  const [openScorecardModal, setOpenScorecardModal] = useState(false);
  const [openProgressCardModal, setOpenProgressCardModal] = useState(false);
  const [openPostRequestsModal, setOpenPostRequestsModal] = useState(
    decodeURIComponent(queryParams?.get('openPostRequests') || '') === 'true',
  );
  const listData: RenderListProps['list'] = useMemo(
    () => [
      {
        icon: (
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.custom.yellowBorder,
              mt: -0.5,
              '&:hover': { backgroundColor: theme.palette.custom.yellowBorder },
            }}
          >
            <DraftFillIcon size={18} color={theme.palette.custom.brown} />
          </IconButton>
        ),
        message: (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box component="span">
              <Link to={DRAFTS} sx={{ color: theme.palette.custom.tuna, display: 'inline' }}>
                {drafts.length} {drafts.length > 1 ? 'drafts' : 'draft'}
              </Link>{' '}
              waiting for you to post
            </Box>
            <Link to={DRAFTS}>
              Go to Drafts <ArrowRightLineIcon size={20} />
            </Link>
          </Box>
        ),
        hide: !drafts || drafts.length === 0,
      },
      {
        icon: (
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.custom.yellowBorder,
              mt: -0.5,
              '&:hover': { backgroundColor: theme.palette.custom.yellowBorder },
            }}
          >
            <SurveyFillIcon size={18} color={theme.palette.custom.brown} />
          </IconButton>
        ),
        message: (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box component="span">
              <ItemsLink
                onClick={() => setOpenScorecardModal(true)}
                sx={{ color: theme.palette.custom.tuna, display: 'inline' }}
              >
                {assignedScorecards?.length > 1
                  ? `${assignedScorecards?.length} scorecards`
                  : `${assignedScorecards?.length} scorecard`}
              </ItemsLink>{' '}
              {assignedScorecards?.length > 1 ? 'are' : 'is'} waiting for you to submit
            </Box>
            <ItemsLink onClick={() => setOpenScorecardModal(true)}>
              Go to Scorecards <ArrowRightLineIcon size={20} />
            </ItemsLink>
          </Box>
        ),
        hide: !company?.settings.scoreCardFlag || !assignedScorecards.length,
      },
      {
        icon: (
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.custom.yellowBorder,
              mt: -0.5,
              '&:hover': { backgroundColor: theme.palette.custom.yellowBorder },
            }}
          >
            <ProgressCardActiveIcon width={'18px'} height={'18px'} style={{ marginLeft: '1px' }} />
          </IconButton>
        ),
        message: (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box component="span">
              <ItemsLink
                onClick={() => setOpenProgressCardModal(true)}
                sx={{ color: theme.palette.custom.tuna, display: 'inline' }}
              >
                {assignedProgresscards?.length > 1
                  ? `${assignedProgresscards?.length} progress cards`
                  : `${assignedProgresscards?.length} progress card`}
              </ItemsLink>{' '}
              {assignedProgresscards?.length > 1 ? 'are' : 'is'} waiting for you to submit
            </Box>
            <ItemsLink onClick={() => setOpenProgressCardModal(true)}>
              Go to Progress Cards <ArrowRightLineIcon size={20} />
            </ItemsLink>
          </Box>
        ),
        hide: !company?.settings.progressCardFlag || !assignedProgresscards.length,
      },
      {
        icon: (
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.custom.yellowBorder,
              mt: -0.5,
              '&:hover': { backgroundColor: theme.palette.custom.yellowBorder },
            }}
          >
            <FileListFillIcon size={18} color={theme.palette.custom.brown} />
          </IconButton>
        ),
        message: (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box component="span">
              <Link to={REQUESTS} sx={{ color: theme.palette.custom.tuna, display: 'inline' }}>
                {requestedPosts.length} {requestedPosts?.length > 1 ? 'requests' : 'request'}
              </Link>{' '}
              for posts waiting for you to submit
            </Box>

            <Link to={REQUESTS}>
              View Requests <ArrowRightLineIcon size={20} />
            </Link>
          </Box>
        ),
        hide: !requestedPosts.length,
      },
    ],
    [
      drafts,
      company?.settings.scoreCardFlag,
      company?.settings.progressCardFlag,
      assignedProgresscards?.length,
      assignedScorecards?.length,
      requestedPosts,
    ],
  );

  return (
    <>
      <RenderList list={listData} />
      {openProgressCardModal && (
        <PendingFormsModal
          open={openProgressCardModal}
          setOpen={setOpenProgressCardModal}
          pendingForms={assignedProgresscards}
          title="Pending Progress Cards"
        />
      )}
      {openScorecardModal && (
        <PendingFormsModal
          open={openScorecardModal}
          setOpen={setOpenScorecardModal}
          pendingForms={assignedScorecards}
          title="Pending Scorecards"
        />
      )}
      {openPostRequestsModal && (
        <RequestedPostsModal open={openPostRequestsModal} setOpen={setOpenPostRequestsModal} />
      )}
    </>
  );
}

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.custom.link,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

const ItemsLink = styled(Typography, { label: 'ItemsLink' })(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.custom.link,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default YourTasks;
