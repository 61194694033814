import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import {
  CREATE_ADMIN_BUILDWISE_SCORECARD,
  CREATE_ADMIN_SCORECARD,
  CREATE_COMPANY_SCORECARD,
  CREATE_COMPANY_BUILDWISE_SCORECARD,
} from 'constants/routes';
import { useConfigQuery } from '@buildwise/libs/services';
import useUserData from 'hooks/useUserData';
import HandleOperation from 'components/utils/HandleOperation';
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon';
import RenderScoreCard from './RenderScoreCard';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';

type ScoreCardsProps = {
  isCompanyConfig?: boolean;
  configCategory?:
    | FORMS_CONFIG_CATEGORY_TYPES.SCORECARD
    | FORMS_CONFIG_CATEGORY_TYPES.BUILDWISE_SCORECARD;
};

function ScoreCards({
  isCompanyConfig = false,
  configCategory = FORMS_CONFIG_CATEGORY_TYPES.SCORECARD,
}: ScoreCardsProps) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { companyId } = useUserData();
  const { configs, isLoading, isError } = useConfigQuery({
    ...(isCompanyConfig && {
      companyId,
    }),
    queryParams: {
      configCategory,
    },
  });
  const isBuildWiseScoreCard = pathname?.toLowerCase().includes('buildwise-scorecards');

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item sm={12} md={8}>
        <StickyHeader>
          <Typography variant="h4">
            {isBuildWiseScoreCard ? 'BuildWise Scorecards' : 'Scorecards'}
          </Typography>
          <Link
            to={
              isCompanyConfig
                ? isBuildWiseScoreCard
                  ? CREATE_COMPANY_BUILDWISE_SCORECARD
                  : CREATE_COMPANY_SCORECARD
                : isBuildWiseScoreCard
                  ? CREATE_ADMIN_BUILDWISE_SCORECARD
                  : CREATE_ADMIN_SCORECARD
            }
            state={{ isCompanyConfig }}
          >
            <Button
              startIcon={<AddLineIcon />}
              variant="outlined"
              sx={(theme) => ({ bgcolor: theme.palette.custom.white })}
            >
              Create New Template
            </Button>
          </Link>
        </StickyHeader>

        <HandleOperation
          isLoading={isLoading}
          data={configs}
          isError={isError}
          skelton={
            <Container>
              <Skeleton animation="wave" width="60%" variant="text" height={20} />
              <Skeleton animation="wave" width="100%" variant="text" height={15} sx={{ mt: 1 }} />
              <Skeleton animation="wave" width="90%" variant="text" height={15} />
              <br />
              <Skeleton animation="wave" width="30%" variant="text" height={20} />
            </Container>
          }
          skeltonCount={5}
          noDataComponent={
            <NoDataContainer>
              <SurveyLineIcon size={120} color={theme.palette.custom.steel} />
              <NoDataDescription mb={1}>No scorecards to display</NoDataDescription>
            </NoDataContainer>
          }
        >
          {configs.map((config) => (
            <RenderScoreCard
              key={config.id}
              config={config}
              isCompanyConfig={isCompanyConfig}
              configCategory={configCategory}
            />
          ))}
        </HandleOperation>
      </Grid>
    </Grid>
  );
}

const StickyHeader = styled(Box, { label: 'StickyHeader' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 79,
  padding: '16px 0',
  backgroundColor: theme.palette.custom.background,
  zIndex: 1,
}));

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  marginBottom: '16px',
  padding: '16px',
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  cursor: 'pointer',
  borderRadius: '4px',
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '80px 24px 0',
});

const NoDataTitle = styled(Box, { label: 'NoDataTitle' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
}));

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default ScoreCards;
