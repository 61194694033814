import { FeedFilter } from 'api/KnowledgeAPI';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PostsFiltersStore = {
  filters: Record<string, FeedFilter[]>;
  resetFilters: (companyId?: string) => void;
  setFilters: (companyId: string, filters: FeedFilter[]) => void;
};

const usePostsFiltersStore = create<PostsFiltersStore>()(
  persist(
    (set) => ({
      filters: {},
      resetFilters: (companyId) =>
        set((prevState) => ({
          filters: companyId ? { ...prevState.filters, [companyId]: [] } : {},
        })),
      setFilters: (companyId, filters) =>
        set((prevState) => ({
          filters: {
            ...prevState.filters,
            [companyId]: filters,
          },
        })),
    }),
    {
      name: 'posts-filters',
    },
  ),
);

export default usePostsFiltersStore;
