import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import UserAPI from 'api/UserAPI';
import { UserType } from 'hooks/useUserData';
import { queryClient } from 'config/queryClient';

export const useUserProfileData = ({
  userName,
  shouldFetch = true,
}: {
  userName: string;
  shouldFetch?: boolean;
}) => {
  const {
    data: userData,
    refetch: refetchUserData,
    ...rest
  } = useQuery<AxiosResponse<UserType>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_USER_DETAILS, userName],
    () => UserAPI.getUser(userName),
    {
      staleTime: 60 * 1000,
      enabled: !!userName && shouldFetch,
    },
  );
  const user = useMemo(() => userData?.data, [userData]);

  return {
    user,
    ...rest,
    refetchUserData,
  };
};

export const updateUserDetailsCache = async (userDetails: UserType) => {
  queryClient.setQueriesData<AxiosResponse<UserType> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_USER_DETAILS.replace(':userName', userDetails.username || ''),
    (oldData) => {
      if (oldData) {
        return {
          ...oldData,
          data: oldData.data.username === userDetails.username ? userDetails : oldData.data,
        };
      }

      return oldData;
    },
  );
};

export default useUserProfileData;
