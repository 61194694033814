import {
  useCallback,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  MouseEvent,
  FC,
  ReactNode,
} from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, InputBase, Popper } from '@mui/material';
import { debounce } from 'lodash';
import { styled } from '@mui/material/styles';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import { useLocation, useSearchParams } from 'react-router-dom';
import SoundModuleFillIcon from 'remixicon-react/SoundModuleFillIcon';

const $SearchBox = styled(Box, { label: 'SearchBox' })(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F5F6F7',
  borderRadius: 8,
  minHeight: 50,
  // maxHeight: 200,
  overflow: 'hidden',
  paddingLeft: 18,
  color: theme.palette.secondary.main,
  transition: 'box-shadow 0.3s',
  '&.gpt-style': {
    backgroundColor: theme.palette.custom.white,
    boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  },
  '&.filled': {
    background: theme.palette.custom.inputBackground,
    boxShadow: 'none !important',
  },
  '&.filled:hover': {
    backgroundColor: '#EBEDF0',
    transition: '0.6s',
  },
  '&.filled.focused, &.gpt-style.focused': {
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.custom.mistBlue}`,
    transition: 'none',
  },
  '& > svg': {
    marginRight: 13,
  },
}));

const $SearchInput = styled(InputBase, { label: 'SearchInput' })(({ theme }) => ({
  width: '100%',
  maxHeight: '150px !important',
  overflow: 'auto',
  padding: '8px 0',
  '& .MuiInputBase-input': {
    maxHeight: '150px !important',
    overflow: 'auto !important',
  },
}));

const $CheckboxIcon = styled(Box, { label: 'CheckboxIcon' })({
  width: 20,
  height: 20,
  borderRadius: 4,
  border: '1px solid #8993A4',
});

const $CheckboxIconChecked = styled(Box, { label: 'CheckboxIconChecked' })({
  width: 20,
  height: 20,
  borderRadius: 4,
  background: '#FFC107',
  color: '#6D5303',
});

type SearchPropTypes = {
  autoFocus?: boolean;
  clearOnEnter?: boolean;
  filled?: boolean;
  fromSearch?: boolean;
  inputProps?: any;
  leftIcon?: ReactNode;
  multiline?: boolean;
  noDebounce?: boolean;
  placeholder?: string;
  rightIcon?: ReactNode;
  setValue: any;
  triggerOnEnter?: boolean;
  value?: string;
  variant?: string;
  onClearSearch?: () => void;
};

const Search: FC<SearchPropTypes> = ({
  autoFocus = false,
  clearOnEnter = false,
  filled = false,
  fromSearch = false,
  inputProps,
  leftIcon,
  multiline = false,
  noDebounce,
  placeholder = 'Search posts...',
  rightIcon,
  setValue,
  triggerOnEnter = false,
  value,
  variant = '',
  onClearSearch,
}) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [localValue, setLocalValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [exactMatch, setExactMatch] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const updateSearch = (value: string) => {
    setValue(value.trim());
    setExactMatch(/^"/i.test(value) && /"$/i.test(value));
  };

  const handleEnter = (event: KeyboardEvent) => {
    if (!event.shiftKey && event.key === 'Enter' && localValue) {
      updateSearch(localValue);
      if (clearOnEnter) {
        setTimeout(() => {
          setLocalValue('');
        }, 0);
      }
    }
  };

  const clearSearchText = () => {
    setValue('');
    updateSearch('');
    setLocalValue('');
    if (inputRef && inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!searchParams.get('q')) {
      clearSearchText();
    }
  }, [pathname]);

  const handleExactMatch = (event: ChangeEvent<HTMLInputElement>) => {
    setExactMatch(event.target.checked);
    let val: string;
    if (event.target.checked) {
      val = '"' + value + '"';
    } else {
      val = value?.replaceAll('"', '') || '';
    }
    updateSearch(val);
    if (inputRef && inputRef.current?.value) {
      inputRef.current.value = val;
    }
  };

  useEffect(() => {
    if (searchParams.get('q')) {
      const value = decodeURIComponent(searchParams?.get('q') || '');
      setLocalValue(value);
      if (inputRef && inputRef.current?.value) {
        inputRef.current.value = value;
      }
    }
  }, [searchParams, pathname]);

  const debounceFn = useCallback(debounce(updateSearch, 300), []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <$SearchBox
        className={`${focus || value ? 'focused' : ''} ${filled ? 'filled' : ''} ${variant}`}
      >
        {leftIcon || <SearchLineIcon />}
        <$SearchInput
          multiline={multiline}
          autoFocus={autoFocus || fromSearch}
          inputRef={inputRef}
          value={localValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          autoComplete="on"
          placeholder={placeholder}
          onChange={(e) => {
            if (triggerOnEnter) {
              setLocalValue(e.target.value);
            } else {
              setLocalValue(e.target.value);
              if (noDebounce) {
                setValue(e.target.value);
              } else {
                debounceFn(e.target.value);
              }
            }
          }}
          {...inputProps}
          {...(triggerOnEnter ? { onKeyDown: handleEnter } : {})}
        />
        <IconButton
          size="small"
          sx={{ mr: 0.5 }}
          onClick={() => {
            onClearSearch?.();
            clearSearchText();
          }}
        >
          <CloseLineIcon size={25} display={localValue || value ? 'block' : 'none'} />
        </IconButton>
        {rightIcon}
        {!!value && pathname.includes('/search') && (
          <IconButton
            color="primary"
            component="label"
            onClick={handleClick}
            style={{ marginRight: 8 }}
          >
            <SoundModuleFillIcon size={20} aria-describedby={id} type="button" />
          </IconButton>
        )}
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end" sx={{ zIndex: 10 }}>
          <Box
            sx={{
              border: '1px solid #D9DBE9',
              borderRadius: '5px',
              marginTop: '5px',
              padding: '8px 16px',
              bgcolor: 'background.paper',
            }}
          >
            <FormControlLabel
              label="Search Exact Match"
              sx={{ marginRight: '0px' }}
              control={
                <Checkbox
                  icon={<$CheckboxIcon />}
                  checkedIcon={
                    <$CheckboxIconChecked>
                      <CheckLineIcon style={{ width: '20px', height: '20px' }} />
                    </$CheckboxIconChecked>
                  }
                  checked={exactMatch}
                  onChange={(e) => {
                    handleExactMatch(e);
                  }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
              }
            />
          </Box>
        </Popper>
      </$SearchBox>
    </>
  );
};

export default Search;
