import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseCircleFillIcon from 'remixicon-react/CloseCircleFillIcon';
import Image2FillIcon from 'remixicon-react/Image2FillIcon';
import { DOCUMENT_TYPES, IMAGE_TYPES } from 'components/utils/constants';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { MouseEvent } from 'react';
import RenderThumbnailAttachment from './RenderThumbnailAttachment';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import {
  ExtraFileNameAttachedByServiceExtendsType,
  getExactFileName,
  shortenString,
} from 'utility/helpers';

type ThumbnailsProps<T> = {
  disabled?: boolean;
  disableEdit?: boolean;
  files: AttachmentType[];
  item?: T;
  mode?: 'view' | 'edit';
  showCoverPhotoIndicator?: boolean;
  smallView?: boolean;
  removeFile?: (attachment: AttachmentType, index: number) => void;
  setLightboxOpen?: (state: { open: boolean; fileName: string }) => void;
};

const Thumbnails = <T extends ExtraFileNameAttachedByServiceExtendsType>({
  disabled,
  disableEdit,
  files,
  item,
  mode = 'edit',
  showCoverPhotoIndicator = true,
  smallView = false,
  removeFile,
  setLightboxOpen,
}: ThumbnailsProps<T>) => {
  const handleClick = (file: AttachmentType) => {
    if (!disabled) {
      let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
      if (file.fileUploaded) {
        fileType = file.fileType.toLowerCase();
      }
      if (
        DOCUMENT_TYPES.includes(`application/${fileType}`) ||
        DOCUMENT_TYPES.includes(`text/${fileType === 'txt' ? 'plain' : fileType}`)
      ) {
        window.open(file.thumbnailOpenUrl as string);
      } else {
        setLightboxOpen?.({ open: true, fileName: file.fileName });
      }
    }
  };

  if (files.length === 0) {
    return null;
  }

  return (
    <$Thumbnails className={smallView ? 'small-view' : ''} id="feedback-attachment">
      <ul className="thumbnails">
        {files.map((file, index) => {
          let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
          if (file.fileUploaded) {
            fileType = file.fileType.toLowerCase();
          }

          if (
            (mode === 'view' &&
              (DOCUMENT_TYPES.includes(`application/${fileType}`) ||
                DOCUMENT_TYPES.includes(`text/${fileType === 'txt' ? 'plain' : fileType}`))) ||
            (file.fileUploaded && file.isRemoved)
          ) {
            return null;
          }

          return (
            <li
              key={index}
              style={{
                animationDelay: `${index / 50}s`,
                animationName: 'thumbnail-sequence',
                animationDuration: '0.3s',
                animationFillMode: 'both',
              }}
            >
              <Box
                title={getExactFileName(file)}
                className="attachment-box"
                onClick={handleClick.bind(null, file)}
                sx={disabled ? { opacity: 0.5 } : {}}
              >
                {mode === 'edit' && (
                  <IconButton
                    title="remove"
                    size="small"
                    onClick={(event: MouseEvent) => {
                      removeFile?.(file, index);
                      event.stopPropagation();
                    }}
                    className="delete-file"
                    disabled={disabled}
                  >
                    <CloseCircleFillIcon
                      style={{
                        border: '0px',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                      }}
                      size={smallView ? 24 : 32}
                    />
                  </IconButton>
                )}
                <RenderThumbnailAttachment<T>
                  attachment={file}
                  smallView={smallView}
                  mode={mode}
                  item={item}
                />
                {mode === 'edit' && !smallView && (
                  <$FileName>{shortenString(file?.fileName || '')}</$FileName>
                )}

                {showCoverPhotoIndicator &&
                  index === 0 &&
                  files.length > 1 &&
                  IMAGE_TYPES.includes(`image/${fileType}`) && (
                    <$CoverPhotoIndicator>
                      <Image2FillIcon size={20} color="white" /> &nbsp; Cover Photo
                    </$CoverPhotoIndicator>
                  )}
              </Box>

              {mode === 'edit' &&
                !disableEdit &&
                IMAGE_TYPES.includes(
                  file.fileUploaded ? `image/${fileType}` : file.fileType.toLowerCase(),
                ) && (
                  <Box
                    sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={handleClick.bind(null, file)}
                  >
                    <Button variant="outlined" disabled={disabled}>
                      <EditLineIcon />
                      &nbsp;Edit
                    </Button>
                  </Box>
                )}
            </li>
          );
        })}
      </ul>
    </$Thumbnails>
  );
};

export const $Thumbnails = styled(Box)(({ theme }) => ({
  '& ul': {
    listStyle: 'none',
    display: 'flex',
    padding: '0px',
    overflowX: 'auto',
    overflowY: 'hidden',
    '& li': {
      width: '180px',
      marginRight: '5px',
      '& .attachment-box': {
        width: '180px',
        height: '180px',
        cursor: 'pointer',
        position: 'relative',
        padding: '0',
        borderRadius: '5px',
        boxShadow: '0 0 1px 0 rgba(9, 30, 66, 0.31)',
        backgroundColor: '#f2f2f2',
        '& img': {
          width: '100%',
          height: '100%',
          borderRadius: '5px',
        },
        '& video': {
          width: '100%',
          height: '100%',
          borderRadius: '5px',
        },
        '& .delete-file': {
          position: 'absolute',
          right: 5,
          top: 0,
          zIndex: 10,
        },
      },
    },
  },
  '&.small-view ul': {
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 'fit-content',
    '& li': {
      width: '120px',
      marginRight: '16px',
      '& .attachment-box': {
        cursor: 'pointer',
        width: '120px',
        height: '120px',
        border: 'none',
        backgroundColor: '#f2f2f2',
        boxShadow: 'none',
        marginTop: '16px',
        '& img': {
          minWidth: '100px',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
        '& video': {
          minWidth: '100px',
          width: '100%',
          height: '100%',
        },
        '& .delete-file': {
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 10,
          transform: 'translate(50%, -50%)',
        },
      },
    },
  },
}));

const $FileName = styled(Typography)(({ theme }) => ({
  wordBreak: 'break-word',
  position: 'absolute',
  bottom: '0px',
  border: '1px solid #9798AD',
  borderRadius: '0px 0px 5px 5px',
  padding: '5px',
  background: 'rgba(255,255,255, 0.8)',
  width: '100%',
  textAlign: 'center',
}));

const $CoverPhotoIndicator = styled(Box, { label: 'CoverPhotoIndicator' })(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: '0',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '8px 0',
  background: 'linear-gradient(to top, rgb(16, 18, 20), rgba(14, 22, 36, 0.2))',
  borderRadius: '5px',
}));

export default Thumbnails;
