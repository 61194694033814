import { FormControl, TextField } from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { Controller, useFormContext } from 'react-hook-form';

type TextInputFieldProps = {
  field: ConfigEntryType;
};

function TextInputField({ field }: TextInputFieldProps) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<Record<string, any>>();

  return (
    <Controller
      control={control}
      render={({ field: _field, fieldState }) => (
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            {..._field}
            size="small"
            value={!!_field.value.trim() ? _field.value : ''}
            inputProps={{ maxLength: 50 }}
            fullWidth
            placeholder={field.referenceObject?.placeHolder || 'Enter value'}
            label="Zip"
            variant="standard"
            error={!!fieldState.error}
            helperText={!!fieldState.error}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting}
          />
        </FormControl>
      )}
      name={field.entryCode}
    />
  );
}

export default TextInputField;
