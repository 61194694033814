import React, { lazy, MouseEvent, Suspense, useMemo, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import { catchErrorMessage, linkToUser, formatDate } from 'utility/helpers';
import SelectMenu from 'components/common/menu/SelectMenu';
import useUserData from 'hooks/useUserData';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { SEARCH, TEMPLATE_DETAILS, TEMPLATES } from 'constants/routes';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import Loader from 'components/common/loader/Loader';
import Avatar from 'components/common/avatar/Avatar';
import linkifyHtml from 'linkify-html';
import { decode } from 'html-entities';
import { TemplatesAPI, TemplateType, updateTemplatesCache } from '@buildwise/libs/company-admin';

const AlertModal = lazy(() => import('components/utils/AlertModal'));
const TemplateModal = lazy(() => import('./TemplateModal'));

type RenderTemplateProps = {
  template: TemplateType;
  variant?: 'detailed' | 'browse' | 'short';
  hideMenu?: boolean;
  highlightTemplateId?: string;
  onClick?: (template: TemplateType) => void;
  onDoubleClick?: (template: TemplateType) => void;
};

const RenderTemplate: React.FC<RenderTemplateProps> = ({
  template,
  variant = 'detailed',
  hideMenu = false,
  highlightTemplateId,
  onClick,
  onDoubleClick,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  const { companyId } = useUserData();
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [isShortView, isDetailedView, isBrowseView] = useMemo(
    () => [variant === 'short', variant === 'detailed', variant === 'browse'],
    [variant],
  );

  const onDelete = async () => {
    try {
      setDeletingTemplate(true);
      await TemplatesAPI.removeTemplate(companyId, template.id);
      updateTemplatesCache(companyId, template, 'delete-template');
      toast.success('Template deleted successfully');
      navigate(TEMPLATES);
      setOpenDeleteModal(false);
    } catch (error) {
      console.error('DELETE_TEMPLATE_ERROR;', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setDeletingTemplate(false);
    }
  };

  const menu = useMemo(
    () => [
      {
        title: 'Edit',
        icon: <EditLineIcon size={17} />,
        onClick: () => setEditOpen(true),
      },
      {
        title: 'Delete',
        icon: <DeleteBinLineIcon size={17} />,
        onClick: () => setOpenDeleteModal(true),
      },
    ],
    [],
  );

  return (
    <$Container
      className={`${variant} ${highlightTemplateId === template.id ? 'highlight' : ''}`}
      {...(isBrowseView && {
        onDoubleClick: onDoubleClick?.bind(null, template),
        onClick: onClick?.bind(null, template),
      })}
    >
      <$Content>
        {!isBrowseView && (
          <Box sx={{ height: 'fit-content' }} pt={2}>
            <Avatar
              size={40}
              name={template.userNameToDisplay}
              to={linkToUser(template.createdBy)}
            />
          </Box>
        )}

        <$TemplateContent className={variant}>
          {!isBrowseView && (
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Typography sx={{ lineHeight: '18px' }}>
                  <Link to={linkToUser(template.createdBy)}>
                    <strong>{template.userNameToDisplay}</strong>
                  </Link>
                </Typography>
                {template.userNameTitleToDisplay && (
                  <Typography variant="caption">{template.userNameTitleToDisplay}</Typography>
                )}
              </Box>

              <Box display="flex" alignItems="center" mt={-2.5}>
                <$TemplatePublishedAt variant="caption">
                  {formatDate(template.publishedAt)}
                </$TemplatePublishedAt>
                {!hideMenu && (
                  <SelectMenu menu={menu}>
                    <IconButton size="small">
                      <MoreFillIcon />
                    </IconButton>
                  </SelectMenu>
                )}
              </Box>
            </Box>
          )}

          <Box
            sx={{
              cursor: isShortView || isBrowseView ? 'pointer' : 'auto',
            }}
            {...(isShortView && {
              component: Link,
              to: TEMPLATE_DETAILS.replace(':templateId', template.id),
            })}
          >
            <$TemplateTitle variant="h5" className={variant}>
              {template.title}
            </$TemplateTitle>

            {isDetailedView && template?.labels?.length > 0 && (
              <Stack direction="row" flexWrap="wrap" sx={{ my: 1 }}>
                {template.labels.map((label) => (
                  <Chip
                    component={Link}
                    to={`${SEARCH}?q=${label}`}
                    onClick={(event: MouseEvent) => event.stopPropagation()}
                    key={`${template.id}-${label}`}
                    label={label}
                    variant="outlined"
                    sx={{ mr: 0.5, mb: 0.5 }}
                  />
                ))}
              </Stack>
            )}

            <$TemplateDescription
              className={variant}
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(decode(template.description), { target: '_blank' }),
              }}
            />
          </Box>

          {isBrowseView && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: '1px solid #EBEDF0',
                mt: 3,
                pt: 1,
              }}
            >
              <$TemplatePublishedAt variant="caption" className={variant}>
                by {template.userNameToDisplay}
              </$TemplatePublishedAt>
              <$TemplatePublishedAt variant="caption">
                {formatDate(template.publishedAt)}
              </$TemplatePublishedAt>
            </Box>
          )}
        </$TemplateContent>
      </$Content>

      <Suspense fallback={<Loader variant="fixed" />}>
        {openDeleteModal && (
          <AlertModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onSubmit={onDelete}
            loading={deletingTemplate}
            description={
              <>
                Are you sure you want to delete this template?
                <br />
                This template will be deleted permanently.
              </>
            }
          />
        )}

        {editOpen && <TemplateModal open={editOpen} setOpen={setEditOpen} template={template} />}
      </Suspense>
    </$Container>
  );
};

const $Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  marginBottom: '16px',
  padding: '0 16px',
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  borderRadius: '4px',
  '&.detailed': {
    borderRadius: '16px',
    padding: '0 24px',
  },
  '&.browse': {
    boxShadow: 'none',
    border: '1px solid #EBEDF0',
    cursor: 'pointer',
  },
  '&.highlight': {
    border: '1.5px solid #FFC107',
  },
}));

const $Content = styled(Box, { label: 'Content' })(({ theme }) => ({
  display: 'flex',
  '&.browse': {
    display: 'block',
  },
}));

const $TemplateContent = styled(Box, { label: 'TemplateContent' })(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  padding: '16px 0 16px 12px',
  '&.browse': {
    paddingLeft: '0',
  },
}));

const $TemplatePublishedAt = styled(Typography, { label: 'TemplatePublishedAt' })(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  marginRight: '8px',
  '&.browse': {
    marginRight: 0,
    fontWeight: 700,
  },
}));

const $TemplateTitle = styled(Typography, { label: 'TemplateTitle' })(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 700,
  color: '#504D64',
  lineHeight: '29px',
  marginTop: 8,
  '&.browse': {
    marginTop: 0,
  },
}));

const $TemplateDescription = styled(Box, { label: 'TemplateDescription' })(({ theme }) => ({
  paddingTop: '8px',
  wordBreak: 'break-word',
  '& a': {
    color: '#1976D2',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&.short, &.browse': {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: '250px',
  },
}));

export default RenderTemplate;
