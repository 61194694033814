import {
  TextField as MuiTextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Autocomplete,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { ModifyUserFormData } from './EditUserModal';
import { SyntheticEvent, useEffect, useRef } from 'react';
import useUserData from 'hooks/useUserData';
import { UserData } from './CompanyUsers';

const STATUS = ['Active', 'Inactive'];
const USER_ROLES = ['ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_USER'];

type EditUserFormProps = {
  jobTitlesOptions: string[];
  user: UserData;
};

function EditUserForm({ jobTitlesOptions, user }: EditUserFormProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<ModifyUserFormData>();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  }, [inputRef]);

  return (
    <>
      <$Header as="h3" sx={{ margin: 0 }}>
        User Details
      </$Header>
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              inputRef={inputRef}
              disabled={isSubmitting}
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...field}
              variant="outlined"
              label="First Name"
              placeholder="First Name"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )}
        name="firstname"
      />

      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...field}
              variant="outlined"
              label="Last Name"
              placeholder="Last Name"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="lastname"
      />

      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              InputProps={{
                readOnly: true,
              }}
              tabIndex={-1}
              {...field}
              variant="outlined"
              placeholder="Email"
              label="Email"
              disabled
            />
          </FormControl>
        )}
        name="email"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <Autocomplete
              {...field}
              freeSolo
              autoHighlight
              value={field.value || null}
              onChange={(_: SyntheticEvent<Element>, value: string | null) =>
                setValue('title', value || '', { shouldDirty: true, shouldValidate: true })
              }
              onInputChange={(_: SyntheticEvent<Element>, value: string) =>
                setValue('title', value || '', { shouldDirty: true, shouldValidate: true })
              }
              options={jobTitlesOptions}
              noOptionsText="No Job Titles Found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!fieldState.error}
                  helperText={fieldState?.error?.message || ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Job Title"
                  label="Job Title"
                />
              )}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="title"
      />

      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...field}
              variant="outlined"
              label="Company Name"
              placeholder="Company Name"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting || !!(user?.primaryCompanyId && user.primaryCompanyName)}
            />
          </FormControl>
        )}
        name="primaryCompanyName"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <Autocomplete
              {...field}
              value={field.value || null}
              onChange={(_: SyntheticEvent<Element>, value: string | null) =>
                setValue('role', value || '', { shouldDirty: true, shouldValidate: true })
              }
              options={USER_ROLES}
              noOptionsText="No Roles Found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!fieldState.error}
                  helperText={fieldState?.error?.message || ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Role"
                  label="Role"
                />
              )}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="role"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <Autocomplete
              {...field}
              value={field.value || null}
              onChange={(_: SyntheticEvent<Element>, value: string | null) =>
                setValue('active', value || '', { shouldDirty: true, shouldValidate: true })
              }
              options={STATUS}
              noOptionsText="No Status Found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!fieldState.error}
                  helperText={fieldState?.error?.message || ''}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Status"
                  label="Status"
                />
              )}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="active"
      />

      <br />
      <br />
      <$Header as="h3">User Permissions</$Header>
      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ display: 'block', mt: -1 }}>
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Can endorse content"
              checked={field.value}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="canEndorseFlag"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ display: 'block', mt: -1 }}>
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Can suggest revisions to posts"
              checked={field.value}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="canSuggestRevisiontToPostsFlag"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ display: 'block', mt: -1 }}>
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Can move posts to the top of the feed"
              checked={field.value}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="canMovePostsToTopFlag"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ display: 'block', mt: -1 }}>
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Can create new collections"
              checked={field.value}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="canCreatePlaylistFlag"
      />

      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ display: 'block', mt: -1 }}>
            <FormControlLabel
              {...field}
              control={<Checkbox />}
              label="Can invite new users"
              checked={field.value}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
        name="canInviteOthersFlag"
      />
    </>
  );
}

const $Header = styled(Typography, { label: 'Header' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 20,
  color: theme.palette.custom.black,
  margin: '0 0 12px',
}));

const TextField = styled(MuiTextField, { label: 'TextField' })(({ theme }) => ({
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.custom.inputBackground,
  },
}));

export default EditUserForm;
