import { AxiosResponse } from 'axios';
import { PYTHON_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { useMemo } from 'react';
import { UseQueryResult, useQueries } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import PythonKnowledgeAPI, { ArticleAttachmentSummary } from 'api/PythonKnowledgeAPI';

export type ArticleAttachmentsSummary = {
  [attachmentFileName: string]: UseQueryResult<AxiosResponse<ArticleAttachmentSummary>>;
};

type UseArticleAttachmentsSummaryProps = {
  attachments: AttachmentType[];
};

function useArticleAttachmentsSummary({ attachments }: UseArticleAttachmentsSummaryProps) {
  const { state } = useLocation();
  const { articleId = '' } = useParams();
  const { companyId, company } = useUserData();
  const [searchParams] = useSearchParams();
  const searchKeyword = useMemo(
    () => decodeURIComponent(searchParams.get('q') || ''),
    [searchParams],
  );
  const attachmentsToSummary = useMemo(() => {
    if (!company?.settings?.articlesLLMSearch) {
      return [];
    }

    return attachments.map((attachment) => attachment.fileName);
  }, [state?.article]);

  const queries = useQueries(
    attachmentsToSummary.map((attachmentFileName) => ({
      enabled: !!(articleId && attachmentFileName && companyId && searchKeyword),
      queryKey: [
        PYTHON_SERVICE_ENDPOINTS.ARTICLE_ATTACHMENT_SUMMARY,
        articleId,
        attachmentFileName,
        companyId,
        searchKeyword,
      ],
      staleTime: 60 * 1000,
      keepPreviousData: true,
      queryFn: () =>
        PythonKnowledgeAPI.articleAttachmentSummary({
          articleId,
          attachmentFileName,
          companyId,
          novaSearchKeyword: searchKeyword,
          pageContent: false,
        }),
    })),
  );

  const articleAttachmentsSummary = useMemo(() => {
    let attachmentsSummary: ArticleAttachmentsSummary = {};

    attachmentsToSummary.forEach((attachmentFileName, index) => {
      attachmentsSummary[attachmentFileName] = queries[index];
    });

    return attachmentsSummary;
  }, [attachmentsToSummary, queries]);

  return {
    articleAttachmentsSummary,
  };
}

export default useArticleAttachmentsSummary;
