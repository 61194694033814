import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { styled } from '@mui/material/styles';
import { MouseEvent, SyntheticEvent, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { AttachmentType } from '../hooks/useArticlesQuery';
import { ArticleVariantType } from './ArticleBox';

type RenderVideoProps = {
  attachment: AttachmentType;
  doNotAutoplayVideos?: boolean;
  forceStopPlaying?: boolean;
  isDraftView?: boolean;
  isDetailedView?: boolean;
  isRelatedPostView?: boolean;
  variant?: ArticleVariantType | 'playlist-article' | 'company-files';
};

function RenderVideo({
  attachment,
  doNotAutoplayVideos = false,
  forceStopPlaying = false,
  isDraftView,
  isDetailedView,
  variant,
  isRelatedPostView,
}: RenderVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [paused, setPaused] = useState(doNotAutoplayVideos);

  const onPlay = async (event: MouseEvent | SyntheticEvent<HTMLVideoElement, Event>) => {
    if (videoRef.current && !forceStopPlaying) {
      setPaused(false);
      try {
        await videoRef.current.play();
        if (isDetailedView) {
          event.stopPropagation();
        }
      } catch (error) {
        console.warn('VIDEO_SOURCE_ERROR', error);
      }
    }
  };

  return (
    <Box onClick={onPlay} margin="0 auto">
      {paused && (
        <$PlayIcon>
          <PlayIcon
            onClick={onPlay}
            width={
              isDraftView ||
              isRelatedPostView ||
              variant === 'playlist-article' ||
              variant === 'company-files'
                ? 40
                : 60
            }
            height={
              isDraftView ||
              isRelatedPostView ||
              variant === 'playlist-article' ||
              variant === 'company-files'
                ? 40
                : 60
            }
          />
        </$PlayIcon>
      )}
      <$Video
        ref={videoRef}
        crossOrigin="anonymous"
        onPlay={() => setPaused(false)}
        preload="auto"
        onLoadedData={onPlay}
        src={paused ? '' : attachment.articleOpenUrl}
        controls={!paused}
        onPause={() => setPaused(true)}
        autoPlay={!doNotAutoplayVideos}
        poster={(attachment?.thumbnailOpenUrl || '') as string}
        className={variant}
      />
    </Box>
  );
}

const $Video = styled('video', { label: 'Video' })({
  maxWidth: '100%',
  margin: 'auto',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 5,
  '&.related-post, &.playlist-article': {
    margin: 'initial',
    width: '100%',
    height: '100%',
  },
});

export const $PlayIcon = styled(Box, { label: 'PlayIcon' })({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  '& svg': {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    color: '#F4B740',
  },
});

export default RenderVideo;
