import CompanyAPI from 'api/CompanyAPI';
import { AxiosResponse } from 'axios';
import { ConfigResponse } from 'config/config';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { useMemo, useRef } from 'react';
import { UseQueryResult, useQueries } from 'react-query';
import { useDivisionSpecSectionsQuery, usePredictDivisionsQuery } from 'hooks/useCompanyQueries';
import usePostsFiltersStore from 'store/usePostsFiltersStore';
import _ from 'lodash';

type ConfigsQueryResponse = {
  [configName: string]: UseQueryResult<AxiosResponse<ConfigResponse>>;
};

function useMultiCompanyConfigQuery({ configs }: { configs: string[] }) {
  const { companyId } = useUserData();
  const prevDivisionCodeRef = useRef<string>('');
  const {
    filters: { [companyId]: allFilters = [] },
  } = usePostsFiltersStore();
  const selectedDivisions = useMemo(
    () =>
      (allFilters.find((filter) => filter.filterName.toLowerCase() === 'divisions')
        ?.filterValues as string[]) || [],
    [allFilters],
  );

  const queries = useQueries(
    configs.map((config) => ({
      enabled: !!(companyId && config),
      queryKey: [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG, companyId, config],
      staleTime: 60 * 1000,
      keepPreviousData: true,
      queryFn: () => CompanyAPI.getCompanyConfig(companyId, config),
    })),
  );
  const { divisions: divisionsOptions, query: divisionsQuery } = usePredictDivisionsQuery({
    query: '',
    shouldFetch: configs.includes('divisions') || configs.includes('DIVISIONS'),
  });
  const divisionCode = useMemo(() => {
    const prevDivCodes = prevDivisionCodeRef.current ? prevDivisionCodeRef.current.split('-') : [];
    const divisionCodes =
      selectedDivisions?.map((division) => division?.split?.('-')?.[0]?.trim() || '') || [];

    return _.uniq([...prevDivCodes, ...divisionCodes]).join('-');
  }, [selectedDivisions]);
  const isSpecSectionsQueryHashChanged = useMemo(
    () =>
      divisionCode?.length >= prevDivisionCodeRef.current?.length &&
      divisionCode !== prevDivisionCodeRef.current,
    [divisionCode],
  );
  const { specSections: specSectionsOptions, query: specSectionsQuery } =
    useDivisionSpecSectionsQuery(
      divisionCode,
      isSpecSectionsQueryHashChanged &&
        (configs.includes('specSections') || configs.includes('SPECSECTIONS')),
    );

  const { configsQueryResponse, configsValues } = useMemo(() => {
    let configsQueryResponse: ConfigsQueryResponse = {};
    let configsValues: Record<string, string[]> = {};

    configs.forEach((config, index) => {
      if (config.toLowerCase() === 'divisions') {
        configsQueryResponse[config] = divisionsQuery as UseQueryResult<
          AxiosResponse<ConfigResponse>
        >;
        configsValues[config] = _.orderBy(divisionsOptions);
      } else if (config.toLowerCase() === 'specsections') {
        configsQueryResponse[config] = specSectionsQuery as UseQueryResult<
          AxiosResponse<ConfigResponse>
        >;
        configsValues[config] = _.orderBy(specSectionsOptions);
      } else {
        configsQueryResponse[config] = queries[index];
        if (queries[index].data?.data?.config?.entries) {
          configsValues[config] =
            queries[index].data?.data?.config?.entries.map((entry) => entry.entryCode) || [];
        }
      }
    });

    return {
      configsQueryResponse,
      configsValues,
    };
  }, [configs, queries, divisionsQuery]);

  return {
    configsQueryResponse,
    configsValues,
  };
}

export default useMultiCompanyConfigQuery;
