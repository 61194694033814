import { FormControl, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { PartnerType } from 'pages/stakeholders/hooks/useStakeholdersQuery';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

type PartnerOptionType = { label: string; value: string };
type PartnersSearchResponseType = { contacts: PartnerType[] };

type PostPartnerProps = {
  isExpanded: boolean;
};

function PostPartner({ isExpanded }: PostPartnerProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const partnerIds = watch('partnerIds');
  const { data: partnersData, isLoading: partnersLoading } =
    useSearchItemQuery<PartnersSearchResponseType>(
      {
        searchType: 'stakeholders',
        operator: 'or',
        filterBy: {
          filters: [
            {
              filterType: 'name',
              filterValue: 'ALL',
            },
          ],
        },
      },
      isExpanded,
    );
  const [partnersOptions, selectedPartner] = useMemo(() => {
    let partner: { label: string; value: string } | undefined = undefined;

    if (partnerIds?.length) {
      partner = {
        label: partnerIds[0] as string,
        value: partnerIds[0] as string,
      };
    }

    const mappedPartners = (partnersData?.contacts || []).map((partnerData) => {
      if (partnerIds?.length && partnerData.id === partnerIds[0]) {
        partner = {
          label: partnerData.name,
          value: partnerData.id,
        };

        return partner;
      }

      return {
        label: partnerData.name,
        value: partnerData.id,
      };
    });

    return [mappedPartners, partner];
  }, [partnersData?.contacts, partnerIds]);
  const handleSelectPartner = (_: unknown, partner: PartnerOptionType | null) => {
    if (partner) {
      setValue('partnerIds', [partner.value], { shouldDirty: true, shouldValidate: true });
    } else {
      setValue('partnerIds', [], { shouldDirty: true, shouldValidate: true });
    }
  };

  return (
    <FormControl fullWidth sx={{ marginBottom: 3 }}>
      <Autocomplete
        onChange={handleSelectPartner}
        options={partnersOptions}
        autoHighlight
        value={selectedPartner}
        noOptionsText={partnersLoading ? 'Loading...' : 'No Partners Found'}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Partner"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {partnersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        )}
        disabled={isSubmitting}
      />
    </FormControl>
  );
}

export default PostPartner;
