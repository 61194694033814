import { JavaAPI, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';
import { TemplateType } from '../types';

export type GetTemplateDetailsResponseType = {
  template: TemplateType;
  totalElements: Number;
  totalNumber: Number;
  totalPages: Number;
  userExpYearsToDisplay: string;
  userNameToDisplay: string;
  userTitleToDisplay: string;
};

export type GetUserTemplatesResponseType = {
  templates: TemplateType[];
  totalElements: Number;
  totalNumber: Number;
  totalPages: Number;
};

type TemplatePayloadType = {
  title: string;
  labels: string[];
  description: string;
};

class TemplatesAPI extends JavaAPI {
  public getTemplates(companyId: string) {
    return this.get<TemplateType[]>(
      JAVA_SERVICE_ENDPOINTS.GET_TEMPLATES.replace(':companyId', companyId),
    );
  }

  public getUserTemplates(userName: string) {
    return this.get<GetUserTemplatesResponseType>(
      JAVA_SERVICE_ENDPOINTS.GET_USER_TEMPLATES.replace(':userName', userName),
    );
  }

  public getTemplateDetails(companyId: string, templateId: string) {
    return this.get<GetTemplateDetailsResponseType>(
      JAVA_SERVICE_ENDPOINTS.GET_TEMPLATE_DETAILS.replace(':companyId', companyId).replace(
        ':templateId',
        templateId,
      ),
    );
  }

  public addTemplate(companyId: string, payload: TemplatePayloadType) {
    const formBody = new FormData();
    formBody.append('template', JSON.stringify(payload));
    return this.post<TemplateType>(
      JAVA_SERVICE_ENDPOINTS.ADD_TEMPLATE.replace(':companyId', companyId),
      formBody,
    );
  }

  public updateTemplate(companyId: string, templateId: string, payload: TemplatePayloadType) {
    const formBody = new FormData();
    formBody.append('template', JSON.stringify(payload));
    return this.put<TemplateType>(
      JAVA_SERVICE_ENDPOINTS.UPDATE_TEMPLATE.replace(':companyId', companyId).replace(
        ':templateId',
        templateId,
      ),
      formBody,
    );
  }

  public removeTemplate(companyId: string, templateId: string) {
    return this.delete<string>(
      JAVA_SERVICE_ENDPOINTS.DELETE_TEMPLATE.replace(':companyId', companyId).replace(
        ':templateId',
        templateId,
      ),
    );
  }
}

export default new TemplatesAPI();
