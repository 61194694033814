import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { MeetingMinutesCategory } from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';
import { Fragment } from 'react';

type MeetingMinutesCategoriesProps = {
  categories: MeetingMinutesCategory[];
};

function MeetingMinutesCategories({ categories }: MeetingMinutesCategoriesProps) {
  return (
    <>
      {categories.map((category) => (
        <Fragment key={category.id}>
          <CategoryTitle>{category.title}</CategoryTitle>
          <Box ml={2}>
            {category?.meeting_topic?.map((meetingTopic) => (
              <>
                <TopicTitle>
                  <Typography component="b">Title:</Typography>
                  {meetingTopic?.title}
                </TopicTitle>
                <TopicDescription>
                  <Typography component="b">Description:</Typography>
                  {meetingTopic?.description}
                </TopicDescription>
                <TopicMinutes>
                  <Typography component="b" display="block">
                    Minutes:
                  </Typography>
                  <Minutes
                    dangerouslySetInnerHTML={{
                      __html: meetingTopic?.minutes || '',
                    }}
                  />
                </TopicMinutes>
              </>
            ))}
          </Box>
        </Fragment>
      ))}
    </>
  );
}

const CategoryTitle = styled(Typography, { label: 'CategoryTitle' })({
  fontWeight: 500,
});

const TopicTitle = styled(Typography, { label: 'TopicTitle' })({
  fontWeight: 500,
});

const TopicDescription = styled(Typography, { label: 'TopicDescription' })({
  fontWeight: 500,
});

const TopicMinutes = styled(Typography, { label: 'TopicMinutes' })({
  fontWeight: 500,
});

const Minutes = styled(Box, { label: 'Minutes' })({
  margin: '0',
  lineHeight: '21px',
  whiteSpace: 'pre-wrap',
});

export default MeetingMinutesCategories;
