import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Controller, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ArticleLineIcon from 'remixicon-react/ArticleLineIcon';
import { theme } from 'config/theme';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import { catchErrorMessage, preventEnterPressed } from 'utility/helpers';
import { toast } from 'react-toastify';
import KnowledgeAPI from 'api/KnowledgeAPI';
import useUserData from 'hooks/useUserData';
import usePromptTemplatesQuery, { PromptTemplate } from './hooks/usePromptTemplatesQuery';

export type PromptTemplateForm = {
  promptName: string;
  prompt: string;
};

type AddPromptTemplateModalProps = {
  isEdit?: boolean;
  open: boolean;
  template?: PromptTemplate;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function AddPromptTemplateModal({
  isEdit = false,
  open,
  template,
  setOpen,
}: AddPromptTemplateModalProps) {
  const { companyId } = useUserData();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { updatePromptTemplatesCache } = usePromptTemplatesQuery({ shouldFetch: false });

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm<PromptTemplateForm>({
    mode: 'all',
    defaultValues: {
      promptName: template?.promptName || '',
      prompt: template?.prompt || '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        promptName: Yup.string().required('Title is required'),
        prompt: Yup.string().required('Description is required'),
      }),
    ),
  });

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  }, [inputRef, open]);

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (values: PromptTemplateForm) => {
    try {
      const { data } = await KnowledgeAPI.savePromptTemplate({
        companyId,
        ...template,
        ...values,
      });
      toast.success('Templates saved successfully');
      updatePromptTemplatesCache({
        action: isEdit ? 'update' : 'add',
        template: {
          ...data.gptPrompt,
          ...values,
        },
      });
      closeModal();
    } catch (error) {
      toast.error(catchErrorMessage(error));
    }
  };

  const handleSave = handleSubmit(onSubmit);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="template-dialog-title"
      aria-describedby="template-dialog-description"
      sx={{ '& .MuiPaper-root': { height: '70%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="template-dialog-title">
        <Box display="flex" alignItems="center">
          <ArticleLineIcon />
          <Title>{isEdit ? 'Edit Template' : 'New Template'}</Title>
        </Box>
      </CustomDialogTitle>
      <DialogContent id="template-dialog-description" sx={{ pt: '16px !important' }}>
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TitleInput
                {...field}
                inputRef={inputRef}
                autoFocus
                multiline
                maxRows={10}
                inputProps={{ minLength: 3 }}
                error={!!fieldState.error}
                onKeyDown={preventEnterPressed}
                fullWidth
                placeholder="Let's give this prompt a title..."
                value={!!field.value.trim() ? field.value : ''}
                disabled={isSubmitting}
                onBlur={() => {
                  field.onBlur();
                  setValue('promptName', field.value.trim(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                endAdornment={
                  !!fieldState.error && (
                    <IconButton>
                      <ErrorWarningLineIcon color={theme.palette.custom.redWine} />
                    </IconButton>
                  )
                }
                sx={
                  !!fieldState.error
                    ? (theme) => ({
                        border: `1px solid ${theme.palette.custom.redWine}`,
                        paddingLeft: '8px !important',
                        borderRadius: '5px',
                      })
                    : {}
                }
              />
              <FormHelperText sx={{ ml: 0 }} error>
                {fieldState.error?.message}
              </FormHelperText>
            </FormControl>
          )}
          name="promptName"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <DescriptionInput
                {...field}
                multiline
                inputProps={{ minLength: 3 }}
                error={!!fieldState.error}
                fullWidth
                placeholder="Save the actual prompt here..."
                value={!!field.value.trim() ? field.value : ''}
                disabled={isSubmitting}
                onBlur={() => {
                  field.onBlur();
                  setValue('prompt', field.value.trim(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                endAdornment={
                  !!fieldState.error && (
                    <IconButton>
                      <ErrorWarningLineIcon color={theme.palette.custom.redWine} />
                    </IconButton>
                  )
                }
                sx={
                  !!fieldState.error
                    ? (theme) => ({
                        border: `1px solid ${theme.palette.custom.redWine}`,
                        paddingLeft: '8px !important',
                        borderRadius: '5px',
                      })
                    : {}
                }
              />
              <FormHelperText sx={{ ml: 0 }} error>
                {fieldState.error?.message}
              </FormHelperText>
            </FormControl>
          )}
          name="prompt"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={handleSave}
          variant="contained"
        >
          {isSubmitting ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : isEdit ? (
            'Update'
          ) : (
            'Save'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.black,
  marginLeft: 8,
}));

const TitleInput = styled(InputBase, { label: 'TitleInput' })({
  fontSize: 20,
  fontWeight: 700,
});

const DescriptionInput = styled(InputBase, { label: 'DescriptionInput' })({
  fontSize: 16,
});

export default AddPromptTemplateModal;
