import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Editor, { initConfig } from 'components/common/editor/Editor';
import TinyMceEditorSkelton from 'components/utils/TinyMceEditorSkelton';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type PostDescriptionProps = {
  isEditing: boolean;
  isExpanded: boolean;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  customStyles?: CSSProperties;
  customPlaceHolder?: string;
  source?: string;
};

function PostDescription({
  isEditing,
  isExpanded,
  showModal,
  setShowModal,
  customStyles = {},
  customPlaceHolder,
  source,
}: PostDescriptionProps) {
  const theme = useTheme();
  const {
    control,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const description = watch('description');

  const [showEditor, setShowEditor] = useState(isEditing || !!description);

  useEffect(() => {
    if (!showEditor) {
      setShowEditor(isEditing || !!description);
    }
  }, [description]);

  return (
    <>
      {!isExpanded && (
        <Box minHeight={200}>
          <Controller
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Editor
                {...field}
                onEditorChange={onChange}
                onInit={(_, editor) => {
                  ref(editor);
                  setShowModal(true);
                }}
                value={!!field.value.trim() ? field.value : ''}
                disabled={isSubmitting}
                init={{
                  inline: true,
                  placeholder: 'Add a description',
                  plugins: 'quickbars link',
                  quickbars_insert_toolbar: false,
                  quickbars_selection_toolbar:
                    'bold italic underline strikethrough quicklink | forecolor backcolor',
                  setup: (editor) => {
                    editor.ui.registry.addContextToolbar('paragraphlink', {
                      predicate: (node) => {
                        return (
                          node.nodeName.toLowerCase() === 'p' && node.textContent?.length === 0
                        );
                      },
                      items: 'quickimage quicktable',
                      scope: 'node',
                    });
                  },
                  content_style: `
                ${initConfig.content_style}

                .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
                  color: ${theme.palette.custom.coolGrey};
                  font-size: 16px;
                  font-weight: normal;
                  cursor: text;
                }
                
                .mce-content-body {
                  outline: none;
                  font-family: Lato, sans-serif, Helvetica, Arial;
                  font-size: 16px;
                  font-weight: 500;
                }
                `,
                }}
              />
            )}
            name="description"
          />
        </Box>
      )}
      {!showEditor && (
        <Box
          display="flex"
          alignItems="flex-start"
          height={230}
          mb={2}
          sx={{ cursor: 'text', ...customStyles }}
          onClick={() => setShowEditor(true)}
          onFocus={() => setShowEditor(true)}
          tabIndex={0}
        >
          <DescriptionPlaceholder>
            {customPlaceHolder || 'Add a description'}
          </DescriptionPlaceholder>
        </Box>
      )}
      {isExpanded && showEditor && (
        <Box sx={{ mb: 2, position: 'relative' }}>
          <Box sx={{ visibility: showModal ? 'visible' : 'hidden', minHeight: 450 }}>
            <Controller
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Editor
                  {...field}
                  onEditorChange={onChange}
                  onInit={(_, editor) => {
                    ref(editor);
                    setShowModal(true);
                  }}
                  value={!!field.value.trim() ? field.value : ''}
                  disabled={isSubmitting}
                  init={{
                    min_height: 450,
                    height: 450,
                    auto_focus: isEditing ? undefined : true,
                    ...(source === 'convert_to_post' && { toolbar: false }),
                  }}
                  onBlur={() => {
                    field.onBlur();
                    setValue('description', field.value.trim(), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              )}
              name="description"
            />
          </Box>
          {!showModal && <TinyMceEditorSkelton source={source} />}
        </Box>
      )}
    </>
  );
}

const DescriptionPlaceholder = styled(Typography, { label: 'DescriptionPlaceholder' })(
  ({ theme }) => ({
    fontSize: 16,
    color: theme.palette.custom.coolGrey,
  }),
);

export default PostDescription;
