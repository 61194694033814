import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon';
import { attachFiles, catchErrorMessage, splitFilesByType } from 'utility/helpers';
import KnowledgeAPI from 'api/KnowledgeAPI';
import useUserData, { UserType } from 'hooks/useUserData';
import QuestionsAPI from 'api/QuestionsAPI';
import { updateQuestionCommentsCache } from 'pages/questions/hooks/useQuestionDetailsQuery';
import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import Thumbnails from 'components/common/dropzone/Thumbnails';
import {
  CommentType,
  updateArticleCommentsCache,
  updateArticleDetailsCache,
} from '../hooks/useArticleDetailsQuery';
import Comment from './Comment';
import CommentInput from './CommentInput';
import LightboxModal from 'components/common/modal/LightboxModal';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import RenderThumbnails from '@buildwise/pages/companyFiles/components/RenderThumbnails';
import { IMAGE_TYPES } from 'components/utils/constants';

export type MentionType = UserType & { name: string; id: string; display: string; userId: string };

type UsersSearchResponseType = {
  totalElements?: number;
  totalNumber?: number;
  totalPages?: number;
  userResponse: UserType[];
};

const CommentsBox: React.FC<{
  comments: CommentType[];
  articleId: string;
  type?: string;
  article?: ArticleType;
}> = ({ comments, articleId, type, article }) => {
  const { user, companyId, isNovaAdmin } = useUserData();
  const { data: people } = useSearchItemQuery<UsersSearchResponseType>(
    {
      searchType: 'users',
      operator: 'or',
      filterBy: {
        filters: [
          {
            filterType: 'name',
            filterValue: 'ALL',
          },
        ],
      },
    },
    true,
  );
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [mentions, setMentions] = useState<MentionType[]>([]);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);
  const [lightboxOpen, setLightboxOpen] = useState<{
    open: boolean;
    fileName: string;
  }>({
    open: false,
    fileName: '',
  });

  const userData = useMemo(() => {
    return (
      people?.userResponse.map((user) => ({
        ...user,
        name: user.userNameToDisplay,
        id: user.username,
        display: user.userNameToDisplay,
        userId: user.id,
      })) || []
    );
  }, [people]);

  const addComment = async () => {
    if (articleId && user) {
      try {
        const _mentions = mentions.map((m) => ({
          displayName: m.display,
          userName: m.id,
          pmuserId: m?.pmuserId,
          title: m.title,
          id: m.userId,
        }));
        setLoading(true);
        if (type === 'question') {
          const { data } = await QuestionsAPI.addComment(companyId, articleId, {
            commentAuthor: user.userNameToDisplay,
            commentText: value.trim(),
            mentions: _mentions,
          });

          updateQuestionCommentsCache(companyId, articleId, data?.comment, 'add');
        } else {
          if (value.trim() || attachments.length) {
            const { data: commentResData } = await KnowledgeAPI.addComment(companyId, articleId, {
              commentAuthor: user.username,
              commentText: value.trim(),
              mentions: _mentions,
            });

            if (attachments.length > 0 && commentResData?.comment?.id) {
              const formData = new FormData();

              attachments.forEach((attachment) => {
                formData.append('file', attachment.file);
                if (attachment.thumbnail) {
                  formData.append('thumbnail', attachment.thumbnail);
                }
              });

              const { data: commentMediaResData } = await KnowledgeAPI.addCommentMedia(
                companyId,
                commentResData?.comment?.id,
                formData,
              );
              updateArticleCommentsCache(companyId, articleId, commentMediaResData, 'add');
            } else {
              updateArticleCommentsCache(companyId, articleId, commentResData?.comment, 'add');
            }

            if (article) {
              updateArticleDetailsCache(companyId, {
                ...article,
                totalComments: article.totalComments + 1,
              });
            }

            resetForm();
          }
        }
      } catch (e) {
        toast.error(catchErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = [...((event?.target?.files || []) as File[])];
      attachFiles(files, attachments, setAttachments);
    },
    [attachments],
  );

  const removeAttachment = useCallback(
    (file: AttachmentType) => {
      if (!loading) {
        const attachmentIndex = attachments.findIndex((attachment) => attachment.id === file.id);

        if (attachmentIndex > -1) {
          setAttachments((prevState) => {
            const newAttachments = [...prevState];
            newAttachments.splice(attachmentIndex, 1);
            return [...newAttachments];
          });
        }
      }
    },
    [attachments, loading],
  );

  const resetForm = () => {
    setValue('');
    setMentions([]);
    setAttachments([]);
  };

  const { mediaFiles } = useMemo(() => splitFilesByType({ files: attachments }), [attachments]);

  const updateAttachment = (fileName: string, updatedAttachment: AttachmentType) => {
    const updatedAttachments = [...attachments];
    const index = updatedAttachments.findIndex((file) => file.fileName === fileName);

    if (index > -1) {
      updatedAttachments[index] = updatedAttachment;
      setAttachments(updatedAttachments);
    }
  };

  return (
    <$CommentsBoxWrapper>
      <Box paddingX={3}>
        <$CommentsHeader>
          <$CommentsTitle>
            <Chat3LineIcon size={16} />
            Comments
          </$CommentsTitle>
        </$CommentsHeader>

        <CommentInput
          attachments={attachments}
          type={type}
          onFileInputChange={onFileInputChange}
          value={value}
          setValue={setValue}
          setMentions={setMentions}
          user={user}
          userData={userData}
          onSubmit={addComment}
          loading={loading}
          escapeCallback={resetForm}
        />
        {(value || attachments.length > 0) && (
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
            <Button onClick={resetForm} sx={{ mr: 0.5 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addComment} disabled={loading}>
              {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Comment'}
            </Button>
          </Box>
        )}
        <RenderThumbnails
          files={attachments}
          removeFile={removeAttachment}
          setLightboxOpen={setLightboxOpen}
          showEdit
          showEditLogoOnly
          isImage={(file: AttachmentType) => {
            let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
            if (file.fileUploaded) {
              fileType = file.fileType.toLowerCase();
            }
            return IMAGE_TYPES.includes(
              file.fileUploaded ? `image/${fileType}` : file.fileType.toLowerCase(),
            );
          }}
        />
        {attachments.length > 0 && <Divider />}
      </Box>

      <$CommentsList>
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            articleId={articleId}
            commentPath={comment.id}
            type={type}
            comment={comment}
            user={user}
            userData={userData}
            article={article}
            isNovaAdmin={isNovaAdmin}
            isParentExpanded
          />
        ))}
      </$CommentsList>

      {lightboxOpen.open && (
        <LightboxModal
          onClose={() => setLightboxOpen({ open: false, fileName: '' })}
          open={lightboxOpen.open}
          mode="edit"
          mediaFiles={mediaFiles}
          defaultOpenFileName={lightboxOpen.fileName}
          updateFile={updateAttachment}
          hideCoverPhotoOption
        />
      )}
    </$CommentsBoxWrapper>
  );
};

const $CommentsBoxWrapper = styled(Box, { label: 'CommentsBoxWrapper' })({
  width: '100%',
  paddingTop: 24,
});

const $CommentsHeader = styled(Box, { label: 'CommentsHeader' })({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
});

const $CommentsTitle = styled(Box, { label: 'CommentsHeader' })(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: 15,
  fontWeight: 500,
  lineHeight: '18px',
  marginRight: 24,
  color: theme.palette.secondary.dark,
  '& svg': {
    marginRight: 6,
  },
}));

const $CommentsList = styled(Box, { label: 'CommentsList' })({
  '&.reply': {
    paddingLeft: 46,
  },
});

export default CommentsBox;
