import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AttachmentType } from '../hooks/useArticlesQuery';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { getFileDetails } from 'utility/helpers';
import { ReactNode, useMemo } from 'react';
import { $Audio } from './RenderAudio';
import { $PlayIcon } from './RenderVideo';
import VolumeUpFillIcon from 'remixicon-react/VolumeUpFillIcon';
import { AudioFile } from '@mui/icons-material';

type RenderArticlePreviewAttachmentsProps = {
  attachment: AttachmentType;
};

function RenderArticlePreviewAttachments({ attachment }: RenderArticlePreviewAttachmentsProps) {
  const { isImage, isVideo, isAudio } = useMemo(
    () =>
      getFileDetails({
        file: attachment,
      }),
    [attachment],
  );

  return (
    <Container>
      {isVideo && (
        <$PlayIcon>
          <PlayIcon width={40} height={40} />
        </$PlayIcon>
      )}
      <Box
        component="a"
        href={attachment.articleOpenUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ width: '100%' }}
      >
        {(isVideo || isImage) && (
          <$Image crossOrigin="anonymous" src={attachment?.thumbnailOpenUrl as string} />
        )}
        {isAudio && (
          <AudioContainer>
            <VolumeUpFillIcon style={{ width: 100, height: 100, fill: 'blue' }} />
          </AudioContainer>
        )}
      </Box>
    </Container>
  );
}

const $Image = styled('img', { label: 'Image' })({
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 'auto',
  objectFit: 'cover',
});

export const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
}));

export const AudioContainer = styled(Box, { label: 'AudioContainer' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '190px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f7f7fc',
  title: 'audio',
}));

export default RenderArticlePreviewAttachments;
