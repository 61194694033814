import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { useCompaniesQuery } from 'hooks/useCompanyQueries';
import Search from 'pages/knowledge/components/SearchComponent';
import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';
import Building2LineIcon from 'remixicon-react/Building2LineIcon';
import HandleOperation from 'components/utils/HandleOperation';
import { styled, useTheme } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { getAvatarBgColor } from 'utility/helpers';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import { shallow } from 'zustand/shallow';
import useAuthStore from 'store/useAuthStore';
import StarLineIcon from 'remixicon-react/StarLineIcon';
import StarFillIcon from 'remixicon-react/StarFillIcon';
import { useStarredWorkspaceState } from './hooks/useStarredWorkspaceState';

type SelectCompanyModalProps = {
  selectCompanyModal: boolean;
  handleSelectCompany: (companyId: string) => void;
  onClose: () => void;
};

const SelectCompanyModal = ({
  selectCompanyModal,
  handleSelectCompany,
  onClose,
}: SelectCompanyModalProps) => {
  const theme = useTheme();
  const [userAuthDetails] = useAuthStore((state) => [state.user], shallow);
  const { companies, isLoading, isFetching } = useCompaniesQuery({
    isAssociatedWithOtherCompanies: !!userAuthDetails?.isAssociatedWithOtherCompanies,
  });

  const [searchKeyword, setSearchKeyword] = useState('');
  const [showStarredWorkspaces, setShowStarredWorkspaces] = useState(false);
  const { starWorkspace, starringWorkspaceId } = useStarredWorkspaceState();
  const companiesData = useMemo(
    () =>
      companies?.filter?.(
        (company) =>
          company.name.toLowerCase().includes(searchKeyword.toLowerCase()) &&
          (showStarredWorkspaces ? company.starred : !showStarredWorkspaces),
      ) || [],
    [searchKeyword, companies, showStarredWorkspaces],
  );

  const updateSearch = (value: string) => {
    setSearchKeyword(value.trim());
  };

  return (
    <Dialog
      open={selectCompanyModal}
      maxWidth="lg"
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ '& .MuiPaper-root': { height: '85%', borderRadius: '16px' } }}
      TransitionComponent={GrowDialogTransition}
    >
      <CustomDialogTitle id="dialog-title" onClose={onClose} sx={{ height: 70 }} />
      <DialogContent>
        <StickySearchContainer>
          <Box position="relative">
            <Title>Select the workspace</Title>
            <Box position="absolute" top="0" right="0">
              <FormControlLabel
                control={
                  <Switch
                    disableRipple
                    sx={{ m: 1 }}
                    checked={showStarredWorkspaces}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setShowStarredWorkspaces(e.target.checked)
                    }
                  />
                }
                label="Show Starred Only"
              />
            </Box>
          </Box>
          <Box sx={{ maxWidth: '500px', margin: '12px auto 0' }}>
            <Search
              value={searchKeyword}
              placeholder="Search for workspace"
              setValue={updateSearch}
              filled
              noDebounce
            />
          </Box>
        </StickySearchContainer>

        <Box maxWidth="75%" margin="16px auto">
          <Grid container spacing={4}>
            <HandleOperation
              isLoading={isLoading || isFetching}
              data={companiesData}
              noDataComponent={
                <NoDataContainer>
                  {searchKeyword && (
                    <>
                      <SearchLineIcon size={120} color={theme.palette.custom.steel} />
                      <Typography component="p" mt={1.5}>
                        No workspaces found with <b>"{searchKeyword}"</b>
                      </Typography>
                    </>
                  )}
                  {!searchKeyword && (
                    <>
                      <Building2LineIcon size={120} color={theme.palette.custom.steel} />
                      <Typography component="p" mt={1.5}>
                        {showStarredWorkspaces
                          ? 'No starred workspaces found'
                          : 'No workspaces found'}
                      </Typography>
                    </>
                  )}
                </NoDataContainer>
              }
              skeltonCount={6}
              skelton={
                <Grid item md={4} height={220}>
                  <CompanyContainer>
                    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
                      <Skeleton animation="wave" variant="text" width="100%" height={25} />
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={90}
                        height={90}
                        sx={{ my: 2 }}
                      >
                        <Avatar />
                      </Skeleton>
                      {/* <Skeleton
                        animation="wave"
                        variant="text"
                        width="80%"
                        height={15}
                        sx={{ mb: 1 }}
                      /> */}
                      {/* <Skeleton animation="wave" variant="text" width="50%" height={15} /> */}
                    </Box>
                  </CompanyContainer>
                </Grid>
              }
            >
              {companiesData.map((company) => (
                <Grid item md={4} key={company.id} position="relative">
                  <Box sx={{ position: 'absolute', top: 40, right: 8 }}>
                    {starringWorkspaceId === company.id ? (
                      <IconButton size="small" disableRipple>
                        <CircularProgress
                          sx={(theme) => ({ color: theme.palette.custom.black })}
                          size={20}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => {
                          starWorkspace(company);
                        }}
                      >
                        {company.starred ? (
                          <StarFillIcon color={theme.palette.custom.tuna} size={20} />
                        ) : (
                          <StarLineIcon color={theme.palette.custom.tuna} size={20} />
                        )}
                      </IconButton>
                    )}
                  </Box>

                  <CompanyContainer onClick={() => handleSelectCompany(company.id)}>
                    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
                      <CompanyName title={company.name}>{company.name}</CompanyName>
                      <Avatar
                        variant="rounded"
                        src={company.companyLogoURL}
                        sx={{
                          width: 90,
                          height: 90,
                          backgroundColor: company.companyLogoURL
                            ? 'transparent'
                            : getAvatarBgColor(company.name || '', 50, 50),
                          fontSize: 40,
                          fontWeight: 700,
                          my: 2,
                          '& .MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      >
                        {company.name.slice(0, 1).toUpperCase()}
                      </Avatar>
                      {/* <CompanyDomains>
                        {company.emailDomain}
                        {company.name && company?.otherEmailDomains?.length > 0 && ', '}
                        {company?.otherEmailDomains?.join(',')}
                      </CompanyDomains> */}
                      {/* <CompanyInfo title="Projects">
                        {company.projects && <BuildingLineIcon size="20px" />}&nbsp;
                        {company.projects.length}
                      </CompanyInfo> */}
                    </Box>
                  </CompanyContainer>
                </Grid>
              ))}
            </HandleOperation>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.black,
  textAlign: 'center',
}));

const StickySearchContainer = styled(Box, { label: 'StickySearchContainer' })(({ theme }) => ({
  position: 'sticky',
  top: '0',
  backgroundColor: theme.palette.custom.white,
  zIndex: 1,
  padding: '0 24px 12px',
}));

const CompanyContainer = styled(Box, { label: 'CompanyContainer' })(({ theme }) => ({
  height: '100%',
  padding: 16,
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.custom.softPeach}`,
  transition: '0.4s',
  '&:hover': {
    boxShadow: '0px 4.14px 24.89px 0px #00000014',
    borderColor: 'transparent',
  },
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '60px 24px 0',
});

const CompanyName = styled(Typography, { label: 'CompanyName' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.activeTitle,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '90%',
  textAlign: 'center',
  textTransform: 'capitalize',
}));

// const CompanyDomains = styled(Typography, { label: 'CompanyDomains' })(({ theme }) => ({
//   fontSize: 14,
//   color: theme.palette.custom.mistBlue,
//   textAlign: 'center',
// }));

// const CompanyInfo = styled(Typography, { label: 'CompanyInfo' })(({ theme }) => ({
//   fontSize: 14,
//   color: theme.palette.custom.label,
//   marginTop: '10px',
//   display: 'flex',
//   alignContent: 'center',
// }));

export default SelectCompanyModal;
