import { useForm } from 'react-hook-form';

export type QuestionFormData = {
  title: string;
  description: string;
  anonymous?: boolean;
  file?: FileList;
  mentions?: any;
  suitableRoles?: string[];
};

const useAddQuestionForm = () => {
  const { handleSubmit, control, getValues, setValue, register, watch, reset } =
    useForm<QuestionFormData>();
  const fileValue = watch('file');

  return {
    control,
    register,
    fileValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  };
};

export default useAddQuestionForm;
