import { styled } from '@mui/material/styles';
import { Box, Typography, Button, IconButton, Divider, CircularProgress } from '@mui/material';
import SelectMenu from 'components/common/menu/SelectMenu';
import { UserType } from 'hooks/useUserData';
import { useMemo } from 'react';
import ArrowDownSFillIcon from 'remixicon-react/ArrowDownSFillIcon';
import ArrowUpSFillIcon from 'remixicon-react/ArrowUpSFillIcon';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import ThumbUpFillIcon from 'remixicon-react/ThumbUpFillIcon';
import ArrowGoBackLineIcon from 'remixicon-react/ArrowGoBackLineIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import { formatDate, linkToUser, replaceMentions } from 'utility/helpers';
import LightbulbFlashFillIcon from 'remixicon-react/LightbulbFlashFillIcon';
import Avatar from 'components/common/avatar/Avatar';
import LightboxModal from 'components/common/modal/LightboxModal';
import Thumbnails from 'components/common/dropzone/Thumbnails';
import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { CommentReplyType, CommentType } from '../hooks/useArticleDetailsQuery';
import CommentInput from './CommentInput';
import { MentionType } from './CommentsBox';
import useComment from '../hooks/useComment';
import RenderDocuments from './RenderDocuments';
import { UserLink } from 'pages/knowledge/components/DraftStatusMessage';
import useCompanyQueryData from 'hooks/useCompanyQueryData';
import Dot from 'components/common/dot/Dot';
import RenderThumbnails from '@buildwise/pages/companyFiles/components/RenderThumbnails';
import { IMAGE_TYPES } from 'components/utils/constants';
import useArticleAttachmentsSummary from '../hooks/useArticleAttachmentsSummary';
import { useLocation } from 'react-router-dom';

type CommentProps = {
  article?: ArticleType;
  articleId: string;
  comment: CommentType | CommentReplyType;
  commentPath?: string;
  isNestedReplyView?: boolean;
  isNovaAdmin: boolean;
  isParentExpanded?: boolean;
  type: string | undefined;
  user: UserType | null | undefined;
  userData: MentionType[];
  updateParentCommentReplies?: () => void;
};

const Comment: React.FC<CommentProps> = ({
  article,
  articleId,
  comment,
  commentPath = '',
  isNestedReplyView = false,
  isNovaAdmin,
  isParentExpanded = false,
  type,
  user,
  userData,
  updateParentCommentReplies,
}) => {
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();
  const {
    allCommentReplies,
    commentRef,
    scrollToCommentId,
    documents,
    editAttachments,
    editCommentId,
    editLoading,
    editMediaFiles,
    editValue,
    files,
    isScrollToCommentIdExistsInReplies,
    isUserLiked,
    lightboxOpen,
    mediaFiles,
    menuOptions,
    openCommentReplies,
    pathname,
    replyAttachments,
    replyCommentLoading,
    replyId,
    replyMediaFiles,
    replyValue,
    editRemoveAttachment,
    likeComment,
    navigate,
    onEditFileInputChange,
    onReplyFileInputChange,
    replyComment,
    replyRemoveAttachment,
    resetEditCommentState,
    resetReplyCommentState,
    setAllCommentReplies,
    setEditAttachments,
    setEditMentions,
    setEditValue,
    setLightboxOpen,
    setOpenCommentReplies,
    setReplyAttachments,
    setReplyId,
    setReplyMentions,
    setReplyValue,
    updateAttachment,
    updateComment,
  } = useComment({
    article,
    articleId,
    comment,
    commentPath,
    isParentExpanded,
    type,
    updateParentCommentReplies,
  });
  const { state } = useLocation();

  const attachments: AttachmentType[] = useMemo(() => {
    const matchingFileNames: AttachmentType[] = [];
    const commentAttachmentIds = state?.article?.commentAttachmentIds;

    commentAttachmentIds?.forEach((comment) => {
      comment[0].attachmentIds.forEach((attachmentId: string) => {
        const matchingFile = documents.find(
          (file) => file.id === attachmentId && file.fileName.includes(comment[0].commentId),
        );

        if (matchingFile) {
          matchingFileNames.push(matchingFile);
        }
      });
    });

    return matchingFileNames;
  }, [state?.article?.commentAttachmentIds, documents]);

  const { articleAttachmentsSummary } = useArticleAttachmentsSummary({ attachments });

  const formattedDate = useMemo(() => {
    if (!comment) return null;
    return formatDate(comment.commentedAt);
  }, [comment]);

  const RenderedComment = useMemo(() => {
    if (!comment) return null;
    const _commentText = replaceMentions(comment.commentText, comment.mentions);
    return <$CommentText dangerouslySetInnerHTML={{ __html: _commentText }} />;
  }, [comment]);

  return (
    <$CommentBoxWrapper
      className={commentPath === comment.id ? 'parent-comment' : ''}
      ref={comment.id === scrollToCommentId ? commentRef : null}
    >
      <Box display="flex" width="100%">
        <Box>
          <Avatar
            src={comment.profileImageURL}
            size={40}
            name={comment.commentAuthorDisplayName}
            icon={<LightbulbFlashFillIcon color="#FFC107" />}
            to={linkToUser(comment.commentAuthor)}
          />
        </Box>
        <Box paddingLeft="12px" flex={1} overflow="hidden">
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <UserLink
                mail={comment.commentAuthor}
                name={comment.commentAuthorDisplayName}
                sx={{ display: 'block', lineHeight: 1.2 }}
              />
              <Typography variant="caption" sx={{ fontSize: '14px' }} title="Job Title">
                {comment.commentAuthorDisplayTitle}
              </Typography>
              {shouldDisplayCompanyOnUserCards &&
                (comment?.primaryCompanyName || comment?.userPrimaryCompanyName) && (
                  <>
                    <Dot />
                    <Typography variant="caption" sx={{ fontSize: '14px' }} title="Company">
                      {comment?.primaryCompanyName || comment?.userPrimaryCompanyName}
                    </Typography>
                  </>
                )}
            </Box>
            <$RightMenu className="right-menu" display="flex" alignItems="center">
              <Typography variant="caption">{formattedDate}</Typography>
              <SelectMenu menu={menuOptions}>
                <Box>
                  <IconButton size="small">
                    <MoreFillIcon size={20} style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                </Box>
              </SelectMenu>
            </$RightMenu>
          </Box>
          {editCommentId === comment.id ? (
            <>
              <CommentInput
                attachments={editAttachments}
                type={type}
                onFileInputChange={onEditFileInputChange}
                placeholder="Edit Comment"
                value={editValue}
                setValue={setEditValue}
                setMentions={setEditMentions}
                user={user}
                userData={userData}
                onSubmit={updateComment.bind(null, comment, commentPath)}
                loading={editLoading}
                hideAvatar
                escapeCallback={resetEditCommentState}
              />
              <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
                <Button onClick={resetEditCommentState} sx={{ mr: 0.5 }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={updateComment.bind(null, comment, commentPath)}
                  disabled={editLoading}
                >
                  {editLoading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save'}
                </Button>
              </Box>
              <RenderThumbnails
                files={editAttachments.filter((attachment) => !attachment.isRemoved)}
                removeFile={editRemoveAttachment}
                setLightboxOpen={(state: { open: boolean; fileName: string }) => {
                  setLightboxOpen({ ...state, action: 'editing-comment' });
                }}
                showEdit
                showEditLogoOnly
                isImage={(file: AttachmentType) => {
                  let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
                  if (file.fileUploaded) {
                    fileType = file.fileType.toLowerCase();
                  }
                  return IMAGE_TYPES.includes(
                    file.fileUploaded ? `image/${fileType}` : file.fileType.toLowerCase(),
                  );
                }}
              />
              {editAttachments.length > 0 && <Divider />}
            </>
          ) : (
            <Box width="100%">
              <$CommentTextContainer id="comment-text-container">
                {RenderedComment}
              </$CommentTextContainer>
              {files.length > 0 && (
                <Thumbnails<CommentType>
                  files={files}
                  mode="view"
                  setLightboxOpen={setLightboxOpen}
                  showCoverPhotoIndicator={false}
                  item={comment}
                />
              )}
              <RenderDocuments
                documents={documents}
                showTitle={false}
                articleAttachmentsSummary={articleAttachmentsSummary}
              />
            </Box>
          )}

          <$CommentFooter>
            <Box display="flex" alignItems="center">
              <$ActionButton
                title="like/unlike"
                onClick={likeComment.bind(null, comment, commentPath)}
                startIcon={
                  isUserLiked ? <ThumbUpFillIcon size={16} /> : <ThumbUpLineIcon size={16} />
                }
                disabled={!!isNovaAdmin || comment.commentAuthor === user?.username}
              >
                {comment.likedUsers?.length || ''}
              </$ActionButton>
              {type !== 'question' && (
                <$ActionButton
                  title="reply"
                  onClick={() => {
                    setReplyId(comment.id);
                    setReplyValue(
                      `@[${comment.commentAuthorDisplayName}](${comment.commentAuthor}) `,
                    );
                    setReplyMentions([
                      {
                        display: comment.commentAuthorDisplayName,
                        id: comment.commentAuthor,
                        pmuserId: '',
                        title: comment.commentAuthorDisplayTitle,
                        userId: '',
                      } as MentionType,
                    ]);
                  }}
                >
                  <ArrowGoBackLineIcon size={16} />
                  &nbsp;&nbsp;Reply
                </$ActionButton>
              )}
            </Box>
            {type !== 'question' && comment.replies && comment.replies?.length > 0 && (
              <Box marginTop={0.5}>
                <$ViewReplies
                  onClick={() => {
                    setOpenCommentReplies((prevState) => !prevState);
                    if (isScrollToCommentIdExistsInReplies) {
                      navigate(pathname, { replace: true });
                    }
                  }}
                  endIcon={openCommentReplies ? <ArrowUpSFillIcon /> : <ArrowDownSFillIcon />}
                >
                  {allCommentReplies} {allCommentReplies > 1 ? 'replies' : 'reply'}
                </$ViewReplies>
              </Box>
            )}
          </$CommentFooter>

          {replyId === comment.id && (
            <>
              <CommentInput
                type={type}
                attachments={replyAttachments}
                onFileInputChange={onReplyFileInputChange}
                placeholder={`Reply to ${comment.commentAuthorDisplayName}`}
                doNotSelectInputValue
                value={replyValue}
                setValue={setReplyValue}
                setMentions={setReplyMentions}
                user={user}
                userData={userData}
                onSubmit={replyComment.bind(null, comment, commentPath)}
                loading={replyCommentLoading}
                hideAvatar
                escapeCallback={resetReplyCommentState}
              />
              <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
                <Button onClick={resetReplyCommentState} sx={{ mr: 0.5 }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={replyComment.bind(null, comment, commentPath)}
                  disabled={replyCommentLoading}
                >
                  {replyCommentLoading ? (
                    <CircularProgress sx={{ color: '#000' }} size={20} />
                  ) : (
                    'Reply'
                  )}
                </Button>
              </Box>
              <RenderThumbnails
                files={replyAttachments.filter((attchment) => !attchment.isRemoved)}
                removeFile={replyRemoveAttachment}
                setLightboxOpen={(state: { open: boolean; fileName: string }) => {
                  setLightboxOpen({ ...state, action: 'replying-comment' });
                }}
                showEdit
                showEditLogoOnly
                isImage={(file: AttachmentType) => {
                  let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
                  if (file.fileUploaded) {
                    fileType = file.fileType.toLowerCase();
                  }
                  return IMAGE_TYPES.includes(
                    file.fileUploaded ? `image/${fileType}` : file.fileType.toLowerCase(),
                  );
                }}
              />
              {replyAttachments.length > 0 && <Divider />}
            </>
          )}
          {!isNestedReplyView && openCommentReplies && (
            <Box>
              {comment.replies &&
                comment.replies.map((reply) => (
                  <Comment
                    article={article}
                    isParentExpanded={openCommentReplies}
                    articleId={articleId}
                    type={type}
                    key={reply.id}
                    isNestedReplyView
                    commentPath={`${commentPath}/${reply.id}`}
                    comment={reply}
                    user={user}
                    userData={userData}
                    updateParentCommentReplies={() => {
                      setAllCommentReplies((prevState) => prevState + 1);
                    }}
                    isNovaAdmin={isNovaAdmin}
                  />
                ))}
            </Box>
          )}
        </Box>
      </Box>

      {isNestedReplyView && openCommentReplies && (
        <Box>
          {comment.replies &&
            comment.replies.map((reply) => (
              <Comment
                article={article}
                key={reply.id}
                updateParentCommentReplies={() => {
                  setAllCommentReplies((prevState) => prevState + 1);
                  updateParentCommentReplies?.();
                }}
                isParentExpanded={isParentExpanded}
                articleId={articleId}
                type={type}
                isNestedReplyView
                commentPath={`${commentPath}/${reply.id}`}
                comment={reply}
                user={user}
                userData={userData}
                isNovaAdmin={isNovaAdmin}
              />
            ))}
        </Box>
      )}

      {lightboxOpen.open && (
        <LightboxModal<CommentType>
          item={comment}
          onClose={() => setLightboxOpen({ open: false, fileName: '' })}
          open={lightboxOpen.open}
          mediaFiles={
            lightboxOpen.action === 'editing-comment'
              ? editMediaFiles
              : lightboxOpen.action === 'replying-comment'
                ? replyMediaFiles
                : mediaFiles
          }
          defaultOpenFileName={lightboxOpen.fileName}
          hideCoverPhotoOption
          mode={editCommentId || replyId ? 'edit' : 'view'}
          updateFile={updateAttachment.bind(
            null,
            lightboxOpen.action === 'editing-comment' ? setEditAttachments : setReplyAttachments,
          )}
        />
      )}
    </$CommentBoxWrapper>
  );
};

const $CommentBoxWrapper = styled(Box, { label: 'CommentBoxWrapper' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.custom.white,
  marginTop: 24,
  width: '100%',
  '&.parent-comment': {
    padding: '0 24px',
  },
}));

const $RightMenu = styled(Box, { label: 'RightMenu' })(({ theme }) => ({
  '&.right-menu': {
    '& .MuiTypography-caption': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  '& .MuiTypography-caption': {
    marginRight: 8,
    fontWeight: 600,
  },
  '& > .MuiIconButton-root': {
    marginTop: 2,
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

const $CommentTextContainer = styled(Box, { label: 'CommentTextContainer' })(({ theme }) => ({
  '&.highlight': {
    borderRadius: 4,
    animation: 'highlight-comment 4s',
  },
  '@keyframes highlight-comment': {
    '0%': {
      backgroundColor: 'rgba(255, 193, 7, 0)',
    },
    '30%': {
      backgroundColor: 'rgba(255, 193, 7, 0.1)',
    },
    '50%': {
      backgroundColor: 'rgba(255, 193, 7, 0)',
    },
    '70%': {
      backgroundColor: 'rgba(255, 193, 7, 0.1)',
    },
    '100%': {
      backgroundColor: 'rgba(255, 193, 7, 0)',
    },
  },
}));

const $CommentText = styled(Typography, { label: 'CommentText' })(({ theme }) => ({
  display: 'inline',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '17px',
  marginTop: 6,
  whiteSpace: 'pre-line',
  '& > span': {
    backgroundColor: '#E8F5FA',
  },
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const $CommentFooter = styled(Box, { label: 'CommentFooter' })(({ theme }) => ({
  margin: '8px -6px 4px',
}));

const $ActionButton = styled(Button, { label: 'ActionButton' })(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 14,
  fontWeight: 400,
  minWidth: 20,
  height: 29,
  paddingLeft: 6,
  paddingRight: 6,
  marginRight: 4,
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: 4,
  },
  '& .MuiButton-endIcon': {
    marginLeft: 2,
  },
}));

const $ViewReplies = styled(Button, { label: 'ViewReplies' })(() => ({
  color: '#1976D2',
  padding: '8px',
  height: '32px',
  fontSize: '14px',
}));

export default Comment;
