import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserLink } from 'pages/knowledge/components/DraftStatusMessage';
import { formatDate, getAvatarBgColor } from 'utility/helpers';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import { BusinessObjectActivityLog } from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';

type RFICommentsProps = {
  comments: BusinessObjectActivityLog[];
};

function RFIComments({ comments }: RFICommentsProps) {
  return (
    <Box mt={1}>
      {comments.map((comment) => (
        <Box display="flex" width="100%" my={2}>
          <Box>
            <Avatar
              variant="circular"
              sx={{
                backgroundColor: getAvatarBgColor(comment.userName || '', 30, 50),
                width: 30,
                height: 30,
                fontSize: 16,
              }}
            >
              {comment?.userName?.slice(0, 1)?.toUpperCase() || <UserLineIcon size={16} />}
            </Avatar>
          </Box>
          <Box paddingLeft="12px" flex={1} overflow="hidden">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <UserLink
                  mail={comment.userName}
                  name={comment.userName?.split('@')?.[0] || ''}
                  sx={{ display: 'block', lineHeight: 1.2 }}
                />
              </Box>
              <Typography variant="caption">{formatDate(comment.date)}</Typography>
            </Box>
            <CommentDescription>{comment.description}</CommentDescription>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

const CommentDescription = styled(Typography, { label: 'CommentDescription' })({
  display: 'inline',
  fontSize: 14,
  fontWeight: 400,
  whiteSpace: 'pre-line',
});

export default RFIComments;
