import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

class NotificationsAPI extends JavaAPI {
  public getNotifications() {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_NOTIFICATIONS);
  }

  public readNotification(notificationId: string) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.READ_NOTIFICATION.replace(':notificationId', notificationId),
    );
  }

  public readAll() {
    return this.put(JAVA_SERVICE_ENDPOINTS.READ_ALL_NOTIFICATION);
  }
}

export default new NotificationsAPI();
