import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  CircularProgress,
  Divider,
  InputBase,
  IconButton,
  Avatar,
} from '@mui/material';
import { $ActionButtons } from 'pages/knowledge/components/ArticleBox';
import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import ThumbUpFillIcon from 'remixicon-react/ThumbUpFillIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import {
  catchErrorMessage,
  formatDate,
  getAvatarBgColor,
  getInsightColor,
  highlightSearchkeyWord,
} from 'utility/helpers';
import { styled } from '@mui/material/styles';
import { ReactComponent as PopOut } from 'assets/icons/pop-out.svg';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import {
  InsightRfiType,
  PmRfiType,
  PmSpecType,
  PmSubmittalType,
  ProjectType,
} from '../hooks/useProjectsQuery';
import { PROJECT, VIEW_DRAFT } from 'constants/routes';
import { useLocation } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import { Link as RouterLink } from 'react-router-dom';
import RenderFilteredInsightStatus from './RenderFilteredInsightStatus';
import useArticleDetailsQuery from 'pages/article/hooks/useArticleDetailsQuery';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import SelectMenu from 'components/common/menu/SelectMenu';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import { MenuItemType } from 'components/drawer/RenderMenu';
import RFIComments from './RFIComments';
import SendPlane2FillIcon from 'remixicon-react/SendPlane2FillIcon';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import ProjectsAPI from 'api/ProjectsAPI';
import useFilteredInsightsQuery, {
  BusinessObjectStatus,
  FilteredRFI,
  MeetingMinutes,
} from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';
import { toast } from 'react-toastify';
import _ from 'lodash';
import RenderMeetingMinutes from './RenderMeetingMinutes';

type InsightModalProps = {
  insight:
    | Partial<InsightRfiType>
    | Partial<PmSubmittalType>
    | PmSpecType
    | FilteredRFI
    | MeetingMinutes
    | null;
  insightMenu?: MenuItemType[];
  insightType: 'RFI' | 'Submittal' | 'Spec' | 'FilteredRFI' | 'Meeting Minutes';
  isLikedByMe?: boolean;
  objectType: 'RFI' | 'MEETING_MINUTES';
  open: boolean;
  project?: ProjectType;
  loading: boolean;
  searchKeyword?: string;
  likeHandler?: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleShowMoreDetails: () => void;
};

const InsightModal: FC<InsightModalProps> = ({
  insight,
  insightMenu = [],
  insightType,
  isLikedByMe,
  objectType,
  open,
  searchKeyword,
  project,
  loading,
  likeHandler,
  setOpen,
  handleShowMoreDetails,
}) => {
  const { state } = useLocation();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [comment, setComment] = useState('');
  const [savingComment, setSavingComment] = useState(false);
  const { updateFilteredInsightsCache } = useFilteredInsightsQuery({
    objectType,
    shouldFetch: false,
  });
  const { user, isNovaAdmin, companyId } = useUserData();
  const [businessObject, rfi, submittal, spec] = useMemo(
    () => [
      insight as FilteredRFI | MeetingMinutes,
      insight as InsightRfiType | PmRfiType,
      insight as PmSubmittalType,
      insight as PmSpecType,
    ],
    [insight],
  );
  const { articleResponse: draftDetails, isLoading } = useArticleDetailsQuery({
    articleId: businessObject.referenceArticleId,
    updateViewCount: false,
    shouldFetch: insightType === 'FilteredRFI' || insightType === 'Meeting Minutes',
  });
  const { filteredRFIQuestions, filteredRFIComments } = useMemo(
    () => ({
      filteredRFIQuestions:
        insightType === 'Meeting Minutes'
          ? ''
          : ((businessObject as FilteredRFI).questions || [])
              .map((question) => question.body)
              .join(' '),
      filteredRFIComments: _.orderBy(
        businessObject?.reviewStatus?.activityLog || [],
        'date',
        'desc',
      ),
    }),
    [businessObject],
  );

  const handleStopPropagation = (event: MouseEvent) => {
    // stop this click bubbling to stop duplicate search result capture click in Search component
    if (insightType !== 'FilteredRFI' && insightType !== 'Meeting Minutes') {
      event?.stopPropagation();
    }
  };

  const addComment = async () => {
    try {
      setSavingComment(true);
      await ProjectsAPI.updateInsight(companyId, {
        businessObjects: [
          {
            objectId: businessObject.id,
            objectType,
            reviewStatus: {
              status: BusinessObjectStatus.APPROVED,
              notes: comment.trim(),
            },
          },
        ],
      });
      updateFilteredInsightsCache({
        action: 'update',
        insight: {
          ...businessObject,
          businessObjectNotes: comment.trim(),
          reviewStatus: {
            ...businessObject.reviewStatus,
            activityLog: [
              {
                date: new Date().toISOString(),
                description: comment.trim(),
                userName: user?.username || '',
                status: businessObject.businessObjectStatus,
              },
              ...(businessObject?.reviewStatus?.activityLog || []),
            ],
          },
          updated_at: new Date().toISOString(),
        },
      });
      setComment('');
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setSavingComment(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      inputRef.current?.blur();
    }

    if (!event.shiftKey && event.key === 'Enter') {
      if (event.altKey) {
        setComment((prevState) => `${prevState}\r\n`);
      } else {
        event.preventDefault();
        addComment();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event: MouseEvent) => {
        handleStopPropagation(event);
        setOpen(false);
      }}
      onClick={handleStopPropagation}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={() => setOpen(false)} id="dialog-title">
        <$InsightType sx={{ backgroundColor: getInsightColor(insightType) }}>
          {insightType === 'FilteredRFI' ? 'RFI' : insightType}
        </$InsightType>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description">
        {insightType === 'FilteredRFI' && (
          <>
            <Box>
              <$Subject
                variant="h5"
                dangerouslySetInnerHTML={{
                  __html: highlightSearchkeyWord(
                    (businessObject as FilteredRFI).subject,
                    searchKeyword,
                  ),
                }}
              />
              <$InsightQuestionBody
                dangerouslySetInnerHTML={{
                  __html: highlightSearchkeyWord(
                    businessObject?.insightSnippets?.join(' ') || '',
                    searchKeyword,
                    true,
                  ),
                }}
              />
            </Box>
            <Box>
              <$BoldText>Project Name</$BoldText>
              <Box display="flex" alignItems="center">
                {businessObject?.procore_project_name ? (
                  <>
                    <BuildingLineIcon size={20} style={{ marginRight: 4 }} />
                    <Typography>{businessObject?.procore_project_name}</Typography>
                  </>
                ) : (
                  <>&mdash;&mdash;</>
                )}
              </Box>
              {/* <$BoldText>Project Name</$BoldText>
              <Link
                to={`${PROJECT.replace(':projectId', project?.id || businessObject.projectId || '')}${
                  searchKeyword ? `?q=${searchKeyword}` : ''
                }#rfis`}
                state={searchKeyword && { ...state, project }}
                target="_blank"
              >
                {(businessObject as InsightRfiType).projectName || project?.name}
              </Link> */}
              <$BoldText>Created On</$BoldText>
              <Typography>{formatDate((businessObject as FilteredRFI).created_at)}</Typography>
              <$BoldText>Created By</$BoldText>
              <Typography>
                {(businessObject as FilteredRFI).created_by.name ||
                (businessObject as FilteredRFI).created_by?.login ? (
                  <>
                    {(businessObject as FilteredRFI).created_by.name} (
                    {(businessObject as FilteredRFI).created_by?.login})
                  </>
                ) : (
                  <>&mdash;&mdash;</>
                )}
              </Typography>
              <$BoldText>Responsible Contractor</$BoldText>
              <Typography>
                {(businessObject as FilteredRFI).responsible_contractor?.name || (
                  <>&mdash;&mdash;</>
                )}
              </Typography>
              <$BoldText>Reference</$BoldText>
              <Typography>
                {(businessObject as FilteredRFI).reference || <>&mdash;&mdash;</>}
              </Typography>
              <$BoldText>Question(s)</$BoldText>
              {filteredRFIQuestions ? (
                <$InsightQuestionBody
                  dangerouslySetInnerHTML={{
                    __html: highlightSearchkeyWord(filteredRFIQuestions, searchKeyword),
                  }}
                />
              ) : (
                <>&mdash;&mdash;</>
              )}
              <$BoldText>Set AI Draft Status</$BoldText>
              <Box mt={0.5} display="flex" alignItems="center">
                <RenderFilteredInsightStatus
                  status={businessObject.businessObjectStatus}
                  showDefaultValue
                />
                <SelectMenu menu={insightMenu}>
                  <Box ml={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 5, minWidth: 'fit-content', px: 1.5, py: 0 }}
                    >
                      <MoreFillIcon />
                    </Button>
                  </Box>
                </SelectMenu>
              </Box>
              {businessObject.referenceArticleId && (
                <>
                  <$BoldText display="flex" alignItems="center">
                    Linked AI Draft &nbsp;
                    {isLoading && (
                      <CircularProgress
                        size={16}
                        sx={(theme) => ({ color: theme.palette.custom.black })}
                      />
                    )}
                  </$BoldText>
                  {!isLoading && (
                    <Link
                      to={`${VIEW_DRAFT.replace(':articleId', businessObject.referenceArticleId)}${
                        searchKeyword ? `?q=${searchKeyword}` : ''
                      }`}
                      state={{
                        ...state,
                        isDraft: true,
                      }}
                      target="_blank"
                    >
                      {draftDetails?.companyArticle?.article?.title || ''}
                    </Link>
                  )}
                </>
              )}
              {/* {businessObject.businessObjectStatus === BusinessObjectStatus.REJECTED && (
                <>
                  <$BoldText>Reason for Rejection</$BoldText>
                  <Typography>{businessObject.businessObjectNotes || <>&mdash;&mdash;</>}</Typography>
                </>
              )}
              {businessObject.businessObjectStatus === BusinessObjectStatus.PENDING_APPROVAL && (
                <>
                  <$BoldText>Reason for Park</$BoldText>
                  <Typography>{businessObject.businessObjectNotes || <>&mdash;&mdash;</>}</Typography>
                </>
              )} */}
              <$BoldText mb={1}>Comment(s)</$BoldText>
              <CommentInput
                autoFocus
                multiline
                fullWidth
                placeholder="Add a Comment"
                value={comment}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
                startAdornment={
                  <Avatar
                    variant="circular"
                    src={user?.profileImageURL}
                    sx={{
                      backgroundColor: getAvatarBgColor(user?.userNameToDisplay || '', 30, 50),
                      width: 30,
                      height: 30,
                      fontSize: 16,
                      mr: 1,
                    }}
                  >
                    {user?.userNameToDisplay?.slice(0, 1)?.toUpperCase() || (
                      <UserLineIcon size={16} />
                    )}
                  </Avatar>
                }
                endAdornment={
                  <IconButton onClick={addComment}>
                    <SendPlane2FillIcon size={22} />
                  </IconButton>
                }
                onKeyDown={handleKeyDown}
              />
              {comment && (
                <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1}>
                  <Button onClick={() => setComment('')} sx={{ mr: 0.5 }}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={addComment} disabled={savingComment}>
                    {savingComment ? (
                      <CircularProgress
                        sx={(theme) => ({ color: theme.palette.custom.black })}
                        size={20}
                      />
                    ) : (
                      'Comment'
                    )}
                  </Button>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              {filteredRFIComments.length === 0 ? (
                <Typography>There are no new comments.</Typography>
              ) : (
                <RFIComments comments={filteredRFIComments} />
              )}
            </Box>
          </>
        )}

        {insightType === 'Meeting Minutes' && (
          <RenderMeetingMinutes
            meetingMinutes={businessObject as MeetingMinutes}
            searchKeyword={searchKeyword}
            insightMenu={insightMenu}
            isLoading={isLoading}
            draftDetails={draftDetails}
            comment={comment}
            setComment={setComment}
            handleKeyDown={handleKeyDown}
            addComment={addComment}
            filteredRFIComments={filteredRFIComments}
            savingComment={savingComment}
          />
        )}

        {insightType === 'RFI' && (
          <>
            <Box>
              <$Subject
                variant="h5"
                dangerouslySetInnerHTML={{
                  __html: highlightSearchkeyWord(rfi.subject, searchKeyword),
                }}
              />
              <$InsightQuestionBody
                dangerouslySetInnerHTML={{
                  __html: highlightSearchkeyWord(rfi?.insight_snippet, searchKeyword, true),
                }}
              />
            </Box>
            <Box>
              <$BoldText>Project Name</$BoldText>
              <Link
                to={`${PROJECT.replace(':projectId', project?.id || rfi.projectId || '')}${
                  searchKeyword ? `?q=${searchKeyword}` : ''
                }#rfis`}
                state={searchKeyword && { ...state, project }}
                target="_blank"
              >
                {(rfi as InsightRfiType).projectName || project?.name}
              </Link>
              <$BoldText>Created On</$BoldText>
              <Typography>{formatDate(rfi.created_at)}</Typography>
              <$BoldText>Created By</$BoldText>
              <Typography>
                {rfi.createdByDisplayName} ({rfi.created_by})
              </Typography>
              <$BoldText>Responsible Contractor</$BoldText>
              <Typography>
                {(rfi as InsightRfiType).responsible_contractor || <>&mdash;&mdash;</>}
              </Typography>
              <$BoldText>Reference</$BoldText>
              <Typography>{(rfi as InsightRfiType).reference || <>&mdash;&mdash;</>}</Typography>
              <$BoldText>Question(s)</$BoldText>
              <$InsightQuestionBody
                dangerouslySetInnerHTML={{
                  __html: highlightSearchkeyWord(rfi?.question_body || '', searchKeyword),
                }}
              />
            </Box>
          </>
        )}

        {insightType === 'Submittal' && (
          <>
            <Box>
              <$Subject variant="h5">{submittal.title}</$Subject>
            </Box>
            <Box>
              <$BoldText>Project Name</$BoldText>
              <Link
                to={`${PROJECT.replace(':projectId', project?.id || '')}${
                  searchKeyword ? `?q=${searchKeyword}` : ''
                }#submittals`}
                state={searchKeyword && { ...state, project }}
                target="_blank"
              >
                {project?.name}
              </Link>
              <$BoldText>Created On</$BoldText>
              <Typography>{formatDate(submittal.created_at)}</Typography>
              <$BoldText>Created By</$BoldText>
              <Typography>
                {submittal.createdByDisplayName} ({submittal.created_by})
              </Typography>
              <$BoldText>Spec Section</$BoldText>
              <Typography>
                {submittal.specSectionCode} - {submittal.specSectionDescription}
              </Typography>
              <$BoldText>Submittal Type</$BoldText>
              <Typography>{submittal.submittalType || <>&mdash;&mdash;</>}</Typography>
              <$BoldText>Submittal Manager</$BoldText>
              <Typography>{submittal.submittalManager || <>&mdash;&mdash;</>}</Typography>
              <$BoldText>Status</$BoldText>
              <Typography>{submittal.status || <>&mdash;&mdash;</>}</Typography>
              <$BoldText>Responsible Contractor</$BoldText>
              <Typography>{submittal.responsible_contractor || <>&mdash;&mdash;</>}</Typography>
            </Box>
          </>
        )}

        {insightType === 'Spec' && (
          <>
            <$Subject variant="h5">{spec.label}</$Subject>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Project Name</Typography>
            <Link
              to={`${PROJECT.replace(':projectId', project?.id || '')}${
                searchKeyword ? `?q=${searchKeyword}` : ''
              }#specs`}
              state={searchKeyword && { ...state, project }}
              target="_blank"
            >
              {project?.name}
            </Link>
          </>
        )}
      </DialogContent>
      {insightType !== 'Spec' && (
        <DialogActions
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Button
            onClick={handleShowMoreDetails}
            variant="outlined"
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.white,
              fontSize: '16px',
              fontWeight: 700,
              px: 3,
            })}
            endIcon={
              loading ? <CircularProgress color="inherit" size={16} /> : <PopOut height={16} />
            }
          >
            Show More Details
          </Button>
          {insightType === 'RFI' && !searchKeyword && (
            <$ActionButtons
              onClick={likeHandler}
              startIcon={
                isLikedByMe ? <ThumbUpFillIcon size={18} /> : <ThumbUpLineIcon size={18} />
              }
              disabled={!!isNovaAdmin}
            >
              {(rfi?.engagementDetails?.likedUsers || []).length}
            </$ActionButtons>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const $InsightQuestionBody = styled(Box, { label: 'InsightQuestionBody' })(({ theme }) => ({
  margin: '0',
  lineHeight: '21px',
  whiteSpace: 'pre-wrap',
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const $InsightType = styled(Box, { label: 'InsightType' })(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.custom.tuna,
  padding: '4px 16px',
  borderRadius: '20px',
  width: 'fit-content',
}));

const $Subject = styled(Typography, { label: 'Subject' })(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  fontWeight: 700,
  color: '#504D64',
  lineHeight: '29px',
  fontSize: '1.2em',
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const $BoldText = styled(Typography, { label: 'BoldText' })(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: '10px',
}));

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  fontSize: 14,
  lineHeight: '15.6px',
  color: theme.palette.custom.link,
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const CommentInput = styled(InputBase, { label: 'CommentInput' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  borderRadius: 8,
  padding: '6px !important',
  maxHeight: '150px !important',
  overflow: 'hidden !important',
  border: `1px solid ${theme.palette.custom.line}`,
  '&:focus-within': {
    borderColor: `${theme.palette.custom.black} !important`,
  },
  '&:has(.MuiInputBase-input:placeholder-shown)': {
    borderColor: `${theme.palette.custom.black} !important`,
    // borderColor: 'transparent',
  },
  '& .MuiInputBase-input': {
    maxHeight: '150px !important',
    overflow: 'auto !important',
  },
}));

export default InsightModal;
