import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ProjectType } from '@buildwise/pages/projects/hooks/useProjectsQuery';
import ViewProjectsModal from './ViewProjectsModal';

type FormatProjectsActivityProps = {
  projects: ProjectType[];
};

function FormatProjectsActivity({ projects }: FormatProjectsActivityProps) {
  const [openProjectsModal, setOpenProjectsModal] = useState(false);

  return (
    <>
      <Box component="span">
        <TotalProjects as="strong" onClick={() => setOpenProjectsModal(true)}>
          {projects.length} {projects.length > 1 ? 'projects' : 'project'}
        </TotalProjects>{' '}
        {projects.length > 1 ? 'are' : 'is'} added recently
      </Box>
      {openProjectsModal && (
        <ViewProjectsModal
          open={openProjectsModal}
          setOpen={setOpenProjectsModal}
          projects={projects}
        />
      )}
    </>
  );
}

const TotalProjects = styled(Typography, { label: 'TotalProjects' })(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.custom.tuna,
  fontSize: 14,
  fontWeight: 700,
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FormatProjectsActivity;
