import { Theme } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';

const $Dot = styled('span', { label: 'Dot' })(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  color: theme.palette.custom.label,
  marginLeft: 8,
  marginRight: 8,
}));

type DotProps = {
  showLarge?: boolean;
  sx?: SxProps<Theme>;
};

const Dot = ({ showLarge = false, sx }: DotProps) => {
  return showLarge ? <$Dot sx={sx}>•</$Dot> : <$Dot sx={sx}>·</$Dot>;
};

export default Dot;
