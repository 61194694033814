import { Box, IconButton as MuiIconButton, Typography } from '@mui/material';
import { VIEW_DRAFT, VIEW_ARTICLE } from 'constants/routes';
import { Link } from 'react-router-dom';
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import ThumbUpFillIcon from 'remixicon-react/ThumbUpFillIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import { styled } from '@mui/material/styles';
import useUserData from 'hooks/useUserData';
import { ArticleType } from '../hooks/useArticlesQuery';
import { ArticleVariantType } from './ArticleBox';
import { useMemo } from 'react';

type ArticleInteractionActionsProps = {
  article: ArticleType;
  isDetailedView?: boolean;
  isDraftView?: boolean;
  isRelatedPostView?: boolean;
  scrollInfoKey?: string;
  searchKeyword?: string;
  variant: ArticleVariantType;
  likeHandler: (article?: ArticleType) => void;
  viewInteractionsHandler: () => void;
};

function ArticleInteractionActions({
  article,
  isDetailedView,
  isDraftView,
  isRelatedPostView,
  scrollInfoKey,
  searchKeyword,
  variant,
  likeHandler,
  viewInteractionsHandler,
}: ArticleInteractionActionsProps) {
  const { isNovaAdmin, user } = useUserData();
  const canViewInteractions = useMemo(
    () => isNovaAdmin || user?.username === article.createdBy,
    [isNovaAdmin, user?.username, article.createdBy],
  );

  return (
    <Box display="flex">
      <IconButton
        onClick={viewInteractionsHandler}
        disableRipple={!canViewInteractions}
        sx={{ mr: isRelatedPostView ? 0 : 0.5 }}
        size="small"
        className={`${canViewInteractions ? '' : 'no-hover cursor-default'} ${variant}`}
      >
        <EyeLineIcon size={18} />
        &nbsp;<$Count className={variant}>{article.totalViews}</$Count>
      </IconButton>

      <IconButton
        onClick={() => {
          if (article.createdBy === user?.username) {
            viewInteractionsHandler();
          } else {
            likeHandler(article);
          }
        }}
        disabled={isNovaAdmin}
        size="small"
        sx={{ mr: isRelatedPostView ? 0 : 0.5 }}
      >
        {article.userLiked ? <ThumbUpFillIcon size={18} /> : <ThumbUpLineIcon size={18} />}&nbsp;
        <$Count className={variant}>{article.totalLikes}</$Count>
      </IconButton>

      <Box
        {...(!isDetailedView && {
          component: Link,
          to: `${(isDraftView ? VIEW_DRAFT : VIEW_ARTICLE).replace(':articleId', article.id)}${
            searchKeyword ? `?q=${encodeURIComponent(searchKeyword)}` : ''
          }#comments`,
          state: {
            isPlayListFeature: article.originIndicator === 'PLAYLIST',
            scrollInfoKey,
            isDraft: isDraftView,
          },
        })}
      >
        <IconButton
          size="small"
          className={`${isDetailedView ? 'no-hover cursor-default' : ''} ${variant}`}
          disableRipple={isDetailedView}
        >
          <Chat3LineIcon size={18} />
          &nbsp;
          <$Count className={variant}>{article.totalComments}</$Count>
        </IconButton>
      </Box>
    </Box>
  );
}

const IconButton = styled(MuiIconButton, { label: 'IconButton' })(({ theme }) => ({
  color: theme.palette.custom.mediumBlack,
  borderRadius: 20,
  padding: '2px 12px',
}));

const $Count = styled(Typography, { label: 'Count' })({
  '&.related-post': {
    fontSize: 14,
  },
});

export default ArticleInteractionActions;
