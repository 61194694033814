import { AxiosResponse } from 'axios';
import { ReplyCommentPayloadType } from './KnowledgeAPI';
import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

class QuestionsAPI extends JavaAPI {
  public addQuestion(payload: any, file: any, thumbnail: any) {
    const formBody = new FormData();
    if (file) {
      formBody.append('file', file);
    }
    if (thumbnail) {
      formBody.append('thumbnail', thumbnail);
    }
    formBody.append('companyquestion', JSON.stringify(payload));
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_QUESTION, formBody);
  }

  public answerQuestion(questionId: string, payload: any): Promise<AxiosResponse<any, Error>> {
    const formBody = new FormData();
    formBody.append('companyquestion', questionId);
    formBody.append('article', JSON.stringify(payload));

    return this.post(JAVA_SERVICE_ENDPOINTS.ANSWER_QUESTION, formBody);
  }

  public passQuestion(payload: any) {
    return this.put(JAVA_SERVICE_ENDPOINTS.PASS_QUESTION, payload);
  }

  public getQuestion(companyId: string, questionId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_QUESTION.replace(':companyId', companyId).replace(
        ':questionId',
        questionId,
      ),
    );
  }

  public getQuestionComments(companyId: string, questionId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_QUESTION_COMMENTS.replace(':companyId', companyId).replace(
        ':questionId',
        questionId,
      ),
    );
  }

  public getMyQuestions(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY_QUESTIONS.replace(':companyId', companyId) +
        '?createdByMe=true',
    );
  }

  public getAssignedToMe(companyId: string, status: string, answeredBy: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_COMPANY_QUESTIONS.replace(':companyId', companyId) +
        `?assignedToMe=TRUE&questionStatus=${status}${answeredBy}`,
    );
  }

  public addComment(
    companyId: string,
    questionId: string,
    comment: { commentAuthor: string; commentText: string; mentions?: any[] },
  ) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_COMMENT, {
      companyId,
      questionId,
      comment,
    });
  }

  public replyComment(payload: ReplyCommentPayloadType) {
    return this.post(JAVA_SERVICE_ENDPOINTS.REPLY_COMMENT, payload);
  }

  public updateComment(payload: any) {
    return this.put(JAVA_SERVICE_ENDPOINTS.UPDATE_COMMENT, payload);
  }
}

export default new QuestionsAPI();
