import { FormControl, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { useMemo } from 'react';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import { ProjectsPaginationResponseType } from 'pages/projects/hooks/useProjectsQuery';

type ProjectOptionType = { label: string; value: string };
type ProjectsSearchResponseType = ProjectsPaginationResponseType;

type PostProjectProps = {
  isExpanded: boolean;
};

function PostProject({ isExpanded }: PostProjectProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const projectId = watch('projectId');
  const { data: projectsData, isLoading: projectsLoading } =
    useSearchItemQuery<ProjectsSearchResponseType>(
      {
        searchType: 'projects',
        operator: 'or',
        filterBy: {
          filters: [
            {
              filterType: 'name',
              filterValue: 'ALL',
            },
          ],
        },
      },
      isExpanded,
    );
  const [projectsOptions, selectedProject] = useMemo(() => {
    let project: { label: string; value: string } | undefined = undefined;

    if (projectId) {
      project = {
        label: projectId as string,
        value: projectId as string,
      };
    }

    const mappedProjects = (projectsData?.projects || []).map((projectData) => {
      if (projectId && projectData.id === projectId) {
        project = {
          label: projectData.name,
          value: projectData.id,
        };

        return project;
      }

      return {
        label: projectData.name,
        value: projectData.id,
      };
    });

    return [mappedProjects, project];
  }, [projectsData?.projects, projectId]);

  const handleSelectProject = (_: unknown, project: ProjectOptionType | null) => {
    if (project) {
      setValue('projectId', project.value, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue('projectId', '', { shouldDirty: true, shouldValidate: true });
    }
  };

  return (
    <FormControl fullWidth sx={{ marginBottom: 3 }}>
      <Autocomplete
        onChange={handleSelectProject}
        options={projectsOptions}
        autoHighlight
        value={selectedProject}
        noOptionsText={projectsLoading ? 'Loading...' : 'No Projects Found'}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Project"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {projectsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        )}
        disabled={isSubmitting}
      />
    </FormControl>
  );
}

export default PostProject;
