import { Box, Button, Grid, Typography } from '@mui/material';
import { $PageList } from 'components/common/stylings';
import { Virtuoso } from 'react-virtuoso';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import useUserData from 'hooks/useUserData';
import { useState } from 'react';
import HandleOperation from 'components/utils/HandleOperation';
import { styled } from '@mui/material/styles';
import RenderTemplate from '../libs/company-admin/components/templates/articles/RenderTemplate';
import TemplateModal from '../libs/company-admin/components/templates/articles/TemplateModal';
import { useTemplatesQuery } from '@buildwise/libs/company-admin';

function Templates() {
  const { companyId } = useUserData();
  const { templates, isLoading } = useTemplatesQuery(companyId);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item sm={12} md={8}>
        <$Header>
          <Typography variant="h4">Templates</Typography>
          <Button
            startIcon={<AddLineIcon />}
            variant="outlined"
            sx={{ marginRight: 1, bgcolor: 'white' }}
            onClick={() => setOpenEditModal(true)}
          >
            Create New Template
          </Button>
        </$Header>

        <Box>
          <$PageList>
            <HandleOperation isLoading={isLoading}>
              {templates.length > 0 && (
                <Virtuoso
                  useWindowScroll
                  data={templates}
                  itemContent={(_, template) => (
                    <RenderTemplate key={template.id} template={template} variant="short" />
                  )}
                />
              )}
            </HandleOperation>
          </$PageList>
        </Box>

        {openEditModal && <TemplateModal open={openEditModal} setOpen={setOpenEditModal} />}
      </Grid>
    </Grid>
  );
}

export const $Header = styled(Box, { label: 'Header' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 79,
  padding: '16px 0',
  backgroundColor: theme.palette.custom.background,
  zIndex: 1,
  width: '100%',
}));

export default Templates;
