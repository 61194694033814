import { Box, LinearProgress } from '@mui/material';
import { ReactComponent as BuildWiseLogo } from 'assets/icons/logo.svg';

function BuildWiseLoader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={(theme) => ({
        backgroundColor: theme.palette.custom.background,
        width: '100%',
        height: '100%',
      })}
    >
      <Box display="flex" alignItems="center" flexDirection="column" mt={20}>
        <BuildWiseLogo width={100} height={100} />
        <Box width={150} mt={2} sx={(theme) => ({ color: theme.palette.custom.superNova })}>
          <LinearProgress
            color="inherit"
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.tuna,
              borderRadius: 10,
              height: 4,
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BuildWiseLoader;
