import PostMultiSelectCustomField from './PostMultiSelectCustomField';
import PostSelectCustomField from './PostSelectCustomField';
import { ConfigEntryType } from 'config/config';

type PostCustomFieldsProps = {
  fields: ConfigEntryType[];
};

function PostCustomFields({ fields }: PostCustomFieldsProps) {
  return (
    <>
      {fields.map((field) => {
        switch (field.referenceObject?.type) {
          case 'SELECT':
            return <PostSelectCustomField key={field.entryCode} field={field} />;

          case 'MULTI_SELECT':
            return <PostMultiSelectCustomField key={field.entryCode} field={field} />;

          default:
            null;
        }
      })}
    </>
  );
}

export default PostCustomFields;
