import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useRef, useState } from 'react';
import { ReactComponent as PlaceholderImage } from 'assets/icons/placeholder_image.svg';
import { AttachmentType } from '../hooks/useArticlesQuery';
import { ArticleVariantType } from './ArticleBox';
import { getFileDetails } from 'utility/helpers';

type RenderImageProps = {
  attachment: AttachmentType;
  isShortView?: boolean;
  variant?: ArticleVariantType | 'playlist-article';
};

function RenderImage({ attachment, isShortView = false, variant }: RenderImageProps) {
  const [isInvalidThumbnail, setInvalidThumbnail] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const { fileName } = useMemo(
    () => getFileDetails({ file: attachment, useDefaultStrToSliceFromFileName: true }),
    [attachment],
  );

  return (
    <>
      {!isInvalidThumbnail && (
        <$Image
          crossOrigin="anonymous"
          src={
            (isShortView
              ? attachment?.thumbnailOpenUrl
              : attachment?.articleOpenUrl || attachment?.thumbnailOpenUrl) as string
          }
          ref={imageRef}
          onError={() => setInvalidThumbnail(true)}
          className={variant}
          title={fileName}
        />
      )}
      {isInvalidThumbnail && (
        <$PlaceholderContainer>
          <PlaceholderImage style={{ width: '80px', height: '80px' }} />
        </$PlaceholderContainer>
      )}
    </>
  );
}

const $Image = styled('img', { label: 'Image' })({
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 'auto',
  objectFit: 'cover',
  '&.related-post, &.playlist-article': {
    margin: 'initial',
    width: '100%',
    height: '100%',
  },
  '&.playlist': {
    margin: 'initial',
    width: '100%',
    height: '100%',
  },
  '&.draft, &.playlist, &.related-post, &.playlist-article': {
    objectFit: 'cover',
  },
  '&.list:hover, &.detailed:hover, &.short:hover': {
    transformOrigin: 'center',
    transform: 'scale(1.03)',
    transition: '0.8s',
  },
});

const $PlaceholderContainer = styled(Box, { label: 'PlaceholderContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export default RenderImage;
