import { useEffect, useMemo, useState } from 'react';
import useUserData from 'hooks/useUserData';
import { FilterType } from 'components/common/filter/Filter';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import useMultiCompanyConfigQuery from '@buildwise/pages/knowledge/hooks/useMultiCompanyConfigQuery';
import { FeedFilter } from 'api/KnowledgeAPI';
import _ from 'lodash';
import { ConfigEntryFieldType } from 'config/config';
import usePostsFiltersStore from 'store/usePostsFiltersStore';

function useFilters() {
  const { companyId } = useUserData();
  const { entries: companyFilters } = useConfigQuery(companyId, 'COMPANY_FILTERS');
  const activeCompanyFilters = useMemo(
    () => companyFilters.filter((filter) => filter.isActive),
    [companyFilters],
  );
  const {
    filters: { [companyId]: allFilters = [] },
    resetFilters: resetAllFilters,
    setFilters: setAllFilters,
  } = usePostsFiltersStore();
  const quickFilters = useMemo(
    () =>
      activeCompanyFilters
        .filter(
          (filter) =>
            filter.referenceObject?.type === ConfigEntryFieldType.MULTI_SELECT ||
            !filter.referenceObject?.type,
        )
        .slice(0, 2),
    [activeCompanyFilters],
  );
  const { configsValues, configsQueryResponse } = useMultiCompanyConfigQuery({
    configs: quickFilters.map(
      (filter) => filter.referenceObject?.configRefName || filter.entryCode,
    ),
  });
  const configsOptions = useMemo(
    () => (configsValues ? JSON.stringify(configsValues) : ''),
    [configsValues],
  );
  const [filters, setFilters] = useState<FilterType[]>(
    quickFilters
      .map((filter, index) => {
        return {
          category: filter.entryCode,
          displayName: filter.referenceObject?.label || filter.entryDescription,
          options:
            configsValues?.[filter.referenceObject?.configRefName || filter.entryCode]?.map(
              (option) => ({
                label: option,
                value: option,
              }),
            ) || [],
          allOptions:
            configsValues?.[filter.referenceObject?.configRefName || filter.entryCode] || [],
          selectedOptions: (allFilters.find((_filter) => _filter.filterName === filter.entryCode)
            ?.filterValues || []) as string[],
          showSelectAll: true,
          defaultOpen: index === quickFilters.length - 1,
          inputType:
            filter.referenceObject?.type === ConfigEntryFieldType.MULTI_SELECT ||
            filter.entryCode === 'divisions' ||
            filter.entryCode === 'specSections'
              ? 'checkbox'
              : 'radio',
        };
      })
      .filter((filter) => filter.options.length > 0),
  );
  const selectedFilters: FeedFilter[] = useMemo(
    () =>
      allFilters.filter((filter) =>
        filter.filterValues && Array.isArray(filter.filterValues)
          ? filter.filterValues.length > 0
          : filter.filterValues || filter.startValue || filter.endValue,
      ),
    [allFilters],
  );

  useEffect(() => {
    setFilters((prevState) =>
      quickFilters
        .map((filter, index) => {
          const options: string[] =
            JSON.parse(configsOptions)?.[
              filter.referenceObject?.configRefName || filter.entryCode
            ] || [];
          return {
            ...prevState?.[index],
            category: filter.entryCode,
            displayName: filter.referenceObject?.label || filter.entryDescription,
            options:
              options?.map((option) => ({
                label: option,
                value: option,
              })) || [],
            allOptions: options || [],
            showSelectAll: true,
            defaultOpen: index === quickFilters.length - 1,
            selectedOptions:
              (allFilters.find((_filter) => _filter.filterName === filter.entryCode)
                ?.filterValues as string[]) ||
              prevState?.[index]?.selectedOptions ||
              [],
          };
        })
        .filter((filter) => filter.options.length > 0),
    );
  }, [quickFilters, configsOptions]);

  const applyMoreFilters = (_allFilters: FeedFilter[]) => {
    setAllFilters(companyId, _allFilters);
    setFilters((prevState) =>
      [...prevState].map((filter) => ({
        ...filter,
        selectedOptions: (_allFilters.find((_filter) => _filter.filterName === filter.category)
          ?.filterValues || []) as string[],
      })),
    );
  };

  const onQuickFiltersChanged = (_filters: FilterType[]) => {
    setAllFilters(
      companyId,
      _.uniqBy(
        [
          ..._filters.map((filter) => ({
            filterName: filter?.category || '',
            filterValues: filter?.selectedOptions || [],
          })),
          ...allFilters,
        ],
        'filterName',
      ),
    );
  };

  const clearAllFilters = () => {
    setFilters((prevState) => {
      return (
        prevState?.map((filter) => ({
          ...filter,
          selectedOptions: [],
        })) || []
      );
    });
    resetAllFilters(companyId);
  };

  return {
    activeCompanyFilters,
    filters,
    filtersLoading: !!quickFilters.find(
      (filter) => configsQueryResponse[filter.entryCode]?.isLoading,
    ),
    selectedFilters,
    selectedMoreFiltersCount: selectedFilters.length,
    applyMoreFilters,
    clearAllFilters,
    onQuickFiltersChanged,
    setFilters,
  };
}

export default useFilters;
