import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import { MenuItemType } from 'components/drawer/RenderMenu';
import { UseArticleReducerActions, UseArticleReducerState } from '../hooks/useArticle';
import useUserData from 'hooks/useUserData';
import { $Header } from '@buildwise/pages/Templates';

type DraftActionsProps = {
  articleState: UseArticleReducerState;
  autoGeneratedDraftMenu: MenuItemType[];
  dispatch: (value: UseArticleReducerActions) => void;
};

function DraftActions({ articleState, autoGeneratedDraftMenu, dispatch }: DraftActionsProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { company, isNovaAdmin, isBuildWiseInsightsUser } = useUserData();
  const canPublishPost = useMemo(
    () => !((isNovaAdmin || isBuildWiseInsightsUser) && company?.settings?.externalFlag),
    [company?.settings.externalFlag, isNovaAdmin, isBuildWiseInsightsUser],
  );

  return (
    <$Header sx={{ justifyContent: 'flex-end' }}>
      {canPublishPost && (
        <Button
          variant="contained"
          startIcon={<FileListLineIcon />}
          onClick={() =>
            dispatch({
              type: 'TOGGLE_POST_CONFIRMATION_MODAL',
              payload: true,
              key: 'openPostConfirmationModal',
            })
          }
          disabled={articleState.loading}
          sx={{ mr: 1 }}
        >
          Post
        </Button>
      )}
      <Button
        variant="outlined"
        startIcon={<EditLineIcon />}
        onClick={() => dispatch({ type: 'TOGGLE_EDIT_MODAL', payload: true, key: 'openEditPost' })}
        disabled={articleState.loading}
        sx={{ mr: 1 }}
      >
        Edit
      </Button>
      <Button
        ref={anchorRef}
        variant="outlined"
        startIcon={<MoreFillIcon />}
        disabled={articleState.loading}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        More Options
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              sx={{
                minWidth: '150px',
                borderRadius: '8px',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                '& .Mui-selected': {
                  backgroundColor: 'unset !important',
                },
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  autoFocusItem
                  sx={{ maxWidth: '250px', width: 'max-content', overflow: 'hidden' }}
                >
                  {autoGeneratedDraftMenu.map((option) => (
                    <MenuItem
                      key={option.title}
                      onClick={() => option.onClick?.()}
                      sx={{ py: 1.5 }}
                    >
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          color: option.titleColor,
                          whiteSpace: 'pre-wrap',
                          '& .MuiListItemText-secondary': {
                            color: '#6A6C89',
                            fontSize: '12px',
                          },
                        }}
                        primary={option.title}
                        secondary={option.subTitle}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </$Header>
  );
}

export default DraftActions;
