import React, { useMemo, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  catchErrorMessage,
  formatDate,
  getInsightColor,
  highlightSearchkeyWord,
} from 'utility/helpers';
import { useSearchParams } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import InsightAPI from 'api/InsightAPI';
import { toast } from 'react-toastify';
import { ReactComponent as PopOut } from 'assets/icons/pop-out.svg';
import ProcoreAPI from 'api/ProcoreAPI';
import {
  InsightRfiType,
  PmRfiType,
  PmSpecType,
  PmSubmittalType,
  ProjectType,
  updateInsightsCache,
} from '../hooks/useProjectsQuery';
import RenderInsightContent from './RenderInsightContent';
import SelectMenu from 'components/common/menu/SelectMenu';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import DraftLineIcon from 'remixicon-react/DraftLineIcon';
import ParkInsightModal from 'pages/buildwiseAdmin/AIDraftsWorkbench/ParkInsightModal';
import RejectInsightModal from 'pages/buildwiseAdmin/AIDraftsWorkbench/RejectInsightModal';
import InsightModal from './InsightModal';
import LinkInsightModal from 'pages/buildwiseAdmin/AIDraftsWorkbench/LinkInsightModal';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import AlertModal from 'components/utils/AlertModal';
import ProjectsAPI from 'api/ProjectsAPI';
import useFilteredInsightsQuery, {
  BusinessObjectStatus,
  FilteredRFI,
  MeetingMinutes,
} from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';

export const InsightContainer = styled(Box, { label: 'InsightContainer' })(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  padding: 16,
  marginBottom: 16,
  background: theme.palette.custom.white,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
  '&.default-variant': {
    borderRadius: '4px',
  },
  '&.list': {
    padding: '16px 0',
    boxShadow: 'none',
    borderBottom: '1px solid #0000001f',
    borderRadius: 0,
  },
  '&.spec.list': {
    borderBottom: 'none',
  },
}));

const $InsightQuestionBody = styled(Box, { label: 'InsightQuestionBody' })(({ theme }) => ({
  margin: '16px 0',
  width: '100%',
  lineHeight: '21px',
  whiteSpace: 'pre-wrap',
  '&.ellipsis': {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: '250px',
  },
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const $SpecBox = styled(Box, { label: 'SpecBox' })(({ theme }) => ({
  width: '100%',
  marginBottom: 8,
  fontSize: 13,
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
}));

const InsightDate = styled(Typography, { label: 'InsightDate' })(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
}));

type InsightBoxPropTypes = {
  insight?:
    | InsightRfiType
    | PmRfiType
    | PmSubmittalType
    | PmSpecType
    | FilteredRFI
    | MeetingMinutes;
  insightType: 'RFI' | 'Spec' | 'Submittal' | 'FilteredRFI' | 'Meeting Minutes';
  isDefaultView?: boolean;
  pmSpecs?: PmSpecType[];
  project?: ProjectType;
  variant?: 'list';
};

const InsightBox: React.FC<InsightBoxPropTypes> = ({
  insight,
  insightType,
  isDefaultView,
  pmSpecs,
  project,
  variant = '',
}) => {
  const theme = useTheme();
  const rfi = insight as InsightRfiType | PmRfiType;
  const submittal = insight as PmSubmittalType;
  const [loading, setLoading] = useState(false);
  const { user, companyId } = useUserData();
  const [openInsightModal, setInsightModalOpen] = useState(false);
  const [openParkInsightModal, setOpenParkInsightModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectInsightModal, setOpenRejectInsightModal] = useState(false);
  const [openLinkInsightModal, setOpenLinkInsightModal] = useState(false);
  const [approvingRFI, setApprovingRFI] = useState(false);
  const [searchParams] = useSearchParams();
  const { updateFilteredInsightsCache } = useFilteredInsightsQuery({
    objectType: insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES',
    shouldFetch: false,
  });
  const searchKeyword = useMemo(
    () => decodeURIComponent(searchParams?.get('q') || ''),
    [searchParams],
  );
  const insightMenu = useMemo(
    () => [
      {
        title: 'Link or Modify AI Draft',
        subTitle: 'Create or link to an existing AI draft.',
        icon: <DraftLineIcon size={17} />,
        onClick: () => setOpenLinkInsightModal(true),
      },
      {
        title: 'Save for Review',
        subTitle: 'Status will be changed to “Pending Approval”',
        icon: <TimeLineIcon size={17} />,
        onClick: () => setOpenParkInsightModal(true),
      },
      {
        title: 'Approve',
        subTitle: 'Ready to create AI draft.',
        icon: <CheckLineIcon size={17} />,
        onClick: () => setOpenApproveModal(true),
      },
      {
        title: 'Reject',
        subTitle: 'Add a comment to improve recommendations.',
        icon: <CloseCircleLineIcon size={17} color={theme.palette.custom.redWine} />,
        onClick: () => setOpenRejectInsightModal(true),
      },
    ],
    [],
  );

  const approveRFI = async () => {
    try {
      const businessObject = insight as FilteredRFI | MeetingMinutes;
      setApprovingRFI(true);
      await ProjectsAPI.updateInsight(companyId, {
        businessObjects: [
          {
            objectId: businessObject.id,
            objectType: insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES',
            reviewStatus: {
              status: BusinessObjectStatus.APPROVED,
            },
          },
        ],
      });
      toast.success('Status updated');
      updateFilteredInsightsCache({
        action: 'update',
        insight: {
          ...businessObject,
          businessObjectStatus: BusinessObjectStatus.APPROVED,
          updated_at: new Date().toISOString(),
        },
      });
      setOpenApproveModal(false);
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setApprovingRFI(false);
    }
  };

  const likeHandler = async () => {
    const insightData = insight as InsightRfiType | PmRfiType;
    if (!user) return;
    try {
      let likedUsers = [...(insightData?.engagementDetails?.likedUsers || [])];
      const userInfo = {
        displayName: user.displayName,
        id: user.id,
        pmuserId: user.pmuserId,
        title: user.title,
        userName: user.userName,
      };

      if (isLikedByMe) {
        likedUsers.splice(
          likedUsers.findIndex((likedUser) => likedUser.id === user?.id),
          1,
        );
      } else {
        likedUsers = [...likedUsers, userInfo];
      }

      const updatedInsight = {
        ...insightData,
        engagementDetails: {
          ...insightData?.engagementDetails,
          likedUsers,
          comments: insightData?.engagementDetails?.comments || [],
        },
      };
      updateInsightsCache(companyId, updatedInsight);

      if (isLikedByMe) {
        await InsightAPI.removeLike(companyId, insightData?.projectId!, insightData?.id!);
      } else {
        await InsightAPI.like(companyId, insightData?.projectId!, insightData?.id!);
      }
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const isLikedByMe = useMemo(
    () =>
      ((insight as InsightRfiType | PmRfiType)?.engagementDetails?.likedUsers || []).findIndex(
        (likedUser) => likedUser.id === user?.id,
      ) > -1,
    [(insight as InsightRfiType | PmRfiType)?.engagementDetails?.likedUsers],
  );

  const handleShowMoreDetails = async () => {
    try {
      setLoading(true);
      let url: any;
      if (insightType === 'FilteredRFI' || insightType === 'Meeting Minutes') {
        url = await ProcoreAPI.getRfiPDF(
          (insight as FilteredRFI | MeetingMinutes).procore_project_id,
          (insight as FilteredRFI | MeetingMinutes).id,
        );
      } else if (insightType === 'RFI') {
        url = await ProcoreAPI.getRfiPDF(
          (insight as InsightRfiType | PmRfiType | PmSubmittalType | PmSpecType)?.pmProjectId,
          Number((insight as InsightRfiType | PmRfiType)?.pmRFIId!),
        );
      } else {
        url = await ProcoreAPI.getSubmittalPDF(
          (insight as PmSubmittalType | PmSpecType)?.pmProjectId,
          Number((insight as PmSubmittalType)?.pmSubmittalId!),
        );
      }
      window.open(url.data);
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const [insightData, content] = useMemo(() => {
    if (insightType === 'Meeting Minutes') {
      const meetingMinutes = insight as MeetingMinutes;
      return [
        meetingMinutes,
        {
          title: meetingMinutes.title,
          description: meetingMinutes?.description || '',
        },
      ];
    }

    if (insightType === 'FilteredRFI') {
      const filteredRFI = insight as FilteredRFI;
      return [
        filteredRFI,
        {
          title: filteredRFI.subject,
          description: filteredRFI?.insightSnippets?.join(' ') || '',
        },
      ];
    }

    if (insightType === 'RFI' && searchKeyword) {
      const pmRFI = insight as PmRfiType;
      return [
        pmRFI,
        {
          title: pmRFI.subject,
          description: pmRFI.question_body,
        },
      ];
    }

    if (insightType === 'RFI') {
      const insightRFI = insight as InsightRfiType;
      return [
        insightRFI,
        {
          title: insightRFI.subject,
          description: insightRFI.insight_snippet,
        },
      ];
    }

    if (insightType === 'Submittal') {
      const pmSubmittal = insight as PmSubmittalType;
      return [
        pmSubmittal,
        {
          title: pmSubmittal.title,
          description: '<b>Submittal Type</b> ' + (pmSubmittal?.submittalType || '-'),
        },
      ];
    }

    const pmSpec = insight as PmSpecType | null;
    return [pmSpec, { title: pmSpec?.label || '', description: '' }];
  }, [insightType, searchKeyword, insight]);

  return (
    <InsightContainer
      className={`${insightType ? insightType.toLowerCase() : ''} ${variant} ${
        isDefaultView ? 'default-variant' : ''
      }`}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {searchKeyword ? (
          <Box
            display="flex"
            sx={{
              fontSize: '10px',
              lineHeight: '12px',
              color: '#35374F',
              mb: '10px',
              backgroundColor: getInsightColor(insightType),
              padding: '4px 10px 4px 10px',
              borderRadius: '22px',
            }}
            alignItems="center"
          >
            {insightType === 'FilteredRFI' ? 'RFI' : insightType}
          </Box>
        ) : (
          isDefaultView && (
            <Box
              display="flex"
              sx={{
                fontSize: 12,
                color: '#A0A3BD',
                textTransform: 'capitalize',
                mb: '10px',
              }}
              alignItems="center"
            >
              {insightType === 'FilteredRFI' ? 'RFI' : insightType}
            </Box>
          )
        )}
        {isDefaultView && insightType !== 'Spec' && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <InsightDate
              variant="caption"
              mr={insightType === 'FilteredRFI' || insightType === 'Meeting Minutes' ? 0.5 : 0}
            >
              {formatDate(
                insightType === 'Meeting Minutes'
                  ? (insight as MeetingMinutes).starts_at
                  : (insight as InsightRfiType | PmRfiType)?.created_at,
              )}
            </InsightDate>
            {(insightType === 'FilteredRFI' || insightType === 'Meeting Minutes') && (
              <SelectMenu menu={insightMenu}>
                <Box>
                  <IconButton size="small">
                    <MoreFillIcon />
                  </IconButton>
                </Box>
              </SelectMenu>
            )}
          </Box>
        )}
      </Box>

      {isDefaultView && (
        <>
          <RenderInsightContent
            content={content}
            insight={insightData}
            insightType={insightType}
            isLikedByMe={isLikedByMe}
            project={project}
            likeHandler={likeHandler}
            handleInsightOpen={setInsightModalOpen}
          />
        </>
      )}

      {variant === 'list' && insightType === 'RFI' && (
        <>
          <Box>
            <Typography
              variant="h5"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                fontWeight: 700,
                color: '#504D64',
                lineHeight: '29px',
                fontSize: '1.2em',
                '& .highlight': {
                  backgroundColor: 'yellow !important',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: highlightSearchkeyWord(rfi.subject, searchKeyword),
              }}
            />
          </Box>
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Created On</Typography>
            <Typography>{formatDate(rfi.created_at)}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Created By</Typography>
            <Typography>
              {rfi.createdByDisplayName} ({rfi.created_by})
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>
              Responsible Contractor
            </Typography>
            <Typography>{(rfi as InsightRfiType).responsible_contractor || '-'}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Reference</Typography>
            <Typography>{(rfi as InsightRfiType).reference || '-'}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Question(s)</Typography>
            <$InsightQuestionBody
              dangerouslySetInnerHTML={{
                __html: highlightSearchkeyWord(rfi.question_body, searchKeyword),
              }}
            />
          </Box>
          <Button
            onClick={handleShowMoreDetails}
            variant="outlined"
            sx={{ backgroundColor: '#ffffff', marginTop: '20px' }}
            endIcon={
              loading ? <CircularProgress color="inherit" size={16} /> : <PopOut height={16} />
            }
            disabled={loading}
          >
            Show More Details
          </Button>
        </>
      )}

      {variant === 'list' && insightType === 'Submittal' && (
        <>
          <Box>
            <Typography
              variant="h5"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                fontWeight: 700,
                color: '#504D64',
                lineHeight: '29px',
                fontSize: '1.2em',
                '& .highlight': {
                  backgroundColor: 'yellow !important',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: highlightSearchkeyWord(submittal.title, searchKeyword),
              }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Created On</Typography>
            <Typography>{formatDate(submittal.created_at)}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Created By</Typography>
            <Typography>
              {submittal.createdByDisplayName} ({submittal.created_by})
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Spec Section</Typography>
            <Typography>
              {submittal.specSectionCode} - {submittal.specSectionDescription}
            </Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Submittal Type</Typography>
            <Typography>{submittal.submittalType || '-'}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>
              Submittal Manager
            </Typography>
            <Typography>{submittal.submittalManager || '-'}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>Status</Typography>
            <Typography>{submittal.status || '-'}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }}>
              Responsible Contractor
            </Typography>
            <Typography>{submittal.responsible_contractor || '-'}</Typography>
            <Button
              onClick={handleShowMoreDetails}
              variant="outlined"
              sx={(theme) => ({ backgroundColor: theme.palette.custom.white, marginTop: '20px' })}
              endIcon={
                loading ? <CircularProgress color="inherit" size={16} /> : <PopOut height={16} />
              }
              disabled={loading}
            >
              Show More Details
            </Button>
          </Box>
        </>
      )}

      {variant === 'list' &&
        insightType === 'Spec' &&
        pmSpecs?.map((spec) => (
          <$SpecBox
            key={spec.id}
            dangerouslySetInnerHTML={{
              __html: highlightSearchkeyWord(spec.label, searchKeyword),
            }}
          />
        ))}

      {openInsightModal && insight && (
        <InsightModal
          open={openInsightModal}
          loading={loading}
          setOpen={setInsightModalOpen}
          insight={insight}
          insightMenu={insightMenu}
          insightType={insightType}
          isLikedByMe={isLikedByMe}
          project={project}
          likeHandler={likeHandler}
          searchKeyword={searchKeyword}
          handleShowMoreDetails={handleShowMoreDetails}
          objectType={insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES'}
        />
      )}
      {openParkInsightModal && (
        <ParkInsightModal
          open={openParkInsightModal}
          setOpen={setOpenParkInsightModal}
          filteredRFI={insight as FilteredRFI | MeetingMinutes}
          objectType={insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES'}
        />
      )}
      {openRejectInsightModal && (
        <RejectInsightModal
          open={openRejectInsightModal}
          setOpen={setOpenRejectInsightModal}
          filteredRFI={insight as FilteredRFI | MeetingMinutes}
          objectType={insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES'}
        />
      )}
      {openLinkInsightModal && (
        <LinkInsightModal
          open={openLinkInsightModal}
          setOpen={setOpenLinkInsightModal}
          filteredRFI={insight as FilteredRFI | MeetingMinutes}
          objectType={insightType === 'FilteredRFI' ? 'RFI' : 'MEETING_MINUTES'}
        />
      )}
      {openApproveModal && (
        <AlertModal
          title="Confirm"
          open={openApproveModal}
          setOpen={setOpenApproveModal}
          onSubmit={approveRFI}
          loading={approvingRFI}
          submitButtonName="Approve"
          submitButtonColor="primary"
          description="Approve this item to create AI draft."
        />
      )}
    </InsightContainer>
  );
};

export default InsightBox;

// TODO Implementation
// const likeHandler = async () => {
//   if (!user) return;
//   try {
//     let likedUsers = [...(insight?.engagementDetails?.likedUsers || [])];
//     const userInfo = {
//       displayName: user.displayName,
//       id: user.id,
//       pmuserId: user.pmuserId,
//       title: user.title,
//       userName: user.userName,
//     };

//     if (isLikedByMe) {
//       likedUsers.splice(
//         likedUsers.findIndex((likedUser: InsightLikedUserType) => likedUser.id === user?.id),
//         1,
//       );
//     } else {
//       likedUsers = [...likedUsers, userInfo];
//     }

//     const updatedInsight = {
//       ...insight,
//       engagementDetails: {
//         ...insight?.engagementDetails,
//         likedUsers,
//         comments: insight?.engagementDetails?.comments || [],
//       },
//     };
//     const pmRfiIndex = project.pmRFIs.findIndex((rfi) => rfi.id === updatedInsight.id);
//     const updatedPmRfis = [...project.pmRFIs];
//     updatedPmRfis[pmRfiIndex] = updatedInsight;
//     const updatedProject = {
//       ...project,
//       pmRFIs: updatedPmRfis,
//     };
//     updateSearchedProjectsCache<ProjectType>(
//       companyId,
//       searchKeyword,
//       'projects',
//       updatedProject,
//     );
//     updateInsightsCache(companyId, updatedInsight);

//     if (isLikedByMe) {
//       await InsightAPI.removeLike(companyId, insight.projectId!, insight.id!);
//     } else {
//       await InsightAPI.like(companyId, insight.projectId!, insight.id!);
//     }
//   } catch (error) {
//     toast.error(catchErrorMessage(error));
//   }
// };
