import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import StarSmileLineIcon from 'remixicon-react/StarSmileLineIcon';
import { ArticleType } from '../hooks/useArticlesQuery';
import { ArticleVariantType } from './ArticleBox';
import Collaborators from 'pages/drafts/Collaborators';
import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';
import ArticleInteractionActions from './ArticleInteractionActions';

type ArticleInteractionsProps = {
  article: ArticleType;
  isDetailedView?: boolean;
  isDraftView?: boolean;
  isRelatedPostView?: boolean;
  searchKeyword?: string;
  variant: ArticleVariantType;
  scrollInfoKey?: string;
  userMap?: ArticleUserMapType;
  dispatch: any;
  likeHandler: (article?: ArticleType) => void;
  viewInteractionsHandler: () => void;
};

function ArticleInteractions({
  article,
  isDetailedView,
  isDraftView,
  isRelatedPostView = false,
  searchKeyword,
  variant,
  scrollInfoKey,
  userMap,
  dispatch,
  likeHandler,
  viewInteractionsHandler,
}: ArticleInteractionsProps) {
  return (
    <$Container className={`article-interactions ${variant}`}>
      <Box>
        {article.isDraft ? (
          <Collaborators article={article} userMap={userMap} />
        ) : (
          article?.endorsements?.length > 0 && (
            <Button
              startIcon={
                <$EndorseIconButton className={variant}>
                  <StarSmileLineIcon size={isRelatedPostView ? 14 : 16} />
                </$EndorseIconButton>
              }
              variant="text"
              onClick={() => {
                setTimeout(() => {
                  dispatch({
                    type: 'SET_ARTICLE',
                    payload: {
                      selectedArticle: article,
                      selectedArticleIndex: -1,
                    },
                  });
                }, 0);
                dispatch({
                  type: 'TOGGLE_ENDORSE_INTERACTIONS_MODAL',
                  payload: true,
                  key: 'openEndorseInteractionsModal',
                });
              }}
              sx={(theme) => ({
                color: theme.palette.custom.jetGrey,
                minWidth: 'fit-content',
                p: isRelatedPostView ? 1.5 : 2,
                fontSize: isRelatedPostView ? 14 : 16,
              })}
            >
              {isRelatedPostView || variant === 'playlist'
                ? article.endorsements.length
                : `Endorsed (${article.endorsements.length})`}
            </Button>
          )
        )}
      </Box>

      <ArticleInteractionActions
        article={article}
        isDetailedView={isDetailedView}
        isDraftView={isDraftView}
        isRelatedPostView={isRelatedPostView}
        scrollInfoKey={scrollInfoKey}
        searchKeyword={searchKeyword}
        variant={variant}
        likeHandler={likeHandler}
        viewInteractionsHandler={viewInteractionsHandler}
      />
    </$Container>
  );
}

const $Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 12,
  paddingBottom: 12,
  '&.related-post': {
    marginTop: 0,
    padding: '4px 0',
    borderBottom: `1px solid ${theme.palette.custom.divider} !important`,
  },
}));

const $EndorseIconButton = styled(Box, { label: 'EndorseIconButton' })(({ theme }) => ({
  padding: 4,
  width: 28,
  height: 28,
  borderRadius: '50%',
  backgroundColor: theme.palette.custom.superNova,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.related-post': {
    width: 24,
    height: 24,
  },
}));

export default ArticleInteractions;
