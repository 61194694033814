import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dot from 'components/common/dot/Dot';
import SelectMenu from 'components/common/menu/SelectMenu';
import { UserLink } from 'pages/knowledge/components/DraftStatusMessage';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import MoreFillIcon from 'remixicon-react/MoreFillIcon';
import { formatDate, getAvatarBgColor } from 'utility/helpers';
import { PromptTemplate } from './hooks/usePromptTemplatesQuery';
import { MenuItemType } from 'components/drawer/RenderMenu';
import { useMemo, useState } from 'react';
import useUserData from 'hooks/useUserData';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import AlertModal from 'components/utils/AlertModal';
import AddPromptTemplateModal from './AddPromptTemplateModal';
import { useNavigate } from 'react-router-dom';
import { BUILDWISE_GPT_CHAT } from 'constants/routes';
import UserLineIcon from 'remixicon-react/UserLineIcon';

type RenderPromptTemplateProps = {
  deletingTemplate: boolean;
  template: PromptTemplate;
  deletePromptTemplate: (template: PromptTemplate) => Promise<void>;
};

function RenderPromptTemplate({
  deletePromptTemplate,
  deletingTemplate,
  template,
}: RenderPromptTemplateProps) {
  const { user } = useUserData();
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showMore, setShowMore] = useState((template?.prompt?.length || 0) < 200);
  const templateMenu = useMemo(() => {
    const isTemplateOwner = template.createdBy === user?.username;
    const MENU: MenuItemType[] = [
      {
        title: 'Edit',
        icon: <EditLineIcon size={17} />,
        onClick: () => setOpenEditModal(true),
        isHidden: !isTemplateOwner,
      },
      {
        title: 'Delete',
        icon: <DeleteBinLineIcon size={17} />,
        onClick: () => setOpenDeleteModal(true),
        isHidden: !isTemplateOwner,
      },
    ];
    return MENU.filter((item) => !item.isHidden);
  }, [template, user?.username]);

  const handleUseTemplate = () => {
    navigate(BUILDWISE_GPT_CHAT, { state: { prompt: template.prompt } });
  };

  return (
    <PromptTemplateContainer>
      <Box display="flex" justifyContent="space-between">
        <Title>{template.promptName}</Title>
        {templateMenu.length > 0 && (
          <SelectMenu menu={templateMenu}>
            <Box>
              <IconButton size="small">
                <MoreFillIcon />
              </IconButton>
            </Box>
          </SelectMenu>
        )}
      </Box>
      <Description>
        {showMore ? template.prompt : template.prompt?.substring(0, 200)}
        {(template.prompt?.length || 0) > 200 &&
          (showMore ? (
            <DescriptionActionButton
              onClick={() => setShowMore(false)}
              sx={{ display: 'block', mt: 1 }}
            >
              Show Less
            </DescriptionActionButton>
          ) : (
            <DescriptionActionButton onClick={() => setShowMore(true)}>
              ...Show More
            </DescriptionActionButton>
          ))}
      </Description>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Avatar
            variant="circular"
            src={template.createdByProfileImageUrl}
            sx={{
              backgroundColor: getAvatarBgColor(template?.createdByDisplayName || '', 30, 50),
              width: 25,
              height: 25,
              mr: 1,
              fontSize: 14,
            }}
          >
            {template?.createdByDisplayName?.slice(0, 1)?.toUpperCase() || (
              <UserLineIcon size={16} />
            )}
          </Avatar>
          {template?.createdByDisplayName && (
            <>
              <UserLink
                mail={template.createdBy}
                name={template.createdByDisplayName}
                sx={{ fontSize: 14 }}
              />
              <Dot />
            </>
          )}
          <LastModified title="Last Modified">
            {formatDate(template.updatedAt || template.createdAt)}
          </LastModified>
        </Box>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CheckLineIcon size={20} />}
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.white,
            color: theme.palette.custom.tuna,
            fontWeight: 700,
          })}
          id="use-template"
          onClick={handleUseTemplate}
        >
          Use This Template
        </Button>
      </Box>

      {openDeleteModal && (
        <AlertModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onSubmit={() => deletePromptTemplate(template)}
          loading={deletingTemplate}
          description={
            <>
              Are you sure you want to delete this prompt template?
              <br />
              This prompt template will be deleted permanently.
            </>
          }
        />
      )}

      {openEditModal && (
        <AddPromptTemplateModal
          template={template}
          isEdit
          open={openEditModal}
          setOpen={setOpenEditModal}
        />
      )}
    </PromptTemplateContainer>
  );
}

export const PromptTemplateContainer = styled(Box, { label: 'PromptTemplateContainer' })(
  ({ theme }) => ({
    padding: 16,
    backgroundColor: theme.palette.custom.white,
    boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
    borderRadius: 4,
    marginBottom: 12,
    transition: '0.5s',
    '& #use-template': {
      visibility: 'hidden',
      opacity: 0,
    },
    '&:hover': {
      '& #use-template': {
        opacity: 1,
        transition: 'opacity 0.8s',
        visibility: 'visible',
      },
    },
  }),
);

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.custom.tuna,
}));

const Description = styled(Typography, { label: 'Description' })(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.custom.mistBlue,
  whiteSpace: 'pre-wrap',
  margin: '8px 0 16px',
}));

const DescriptionActionButton = styled(Typography, { label: 'DescriptionActionButton' })(
  ({ theme }) => ({
    display: 'inline',
    fontSize: 'inherit',
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.custom.tuna,
    '&:hover': {
      fontWeight: 700,
    },
  }),
);

const LastModified = styled(Typography, { label: 'LastModified' })(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.custom.mistBlue,
}));

export default RenderPromptTemplate;
