import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, LinearProgress, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const $Loader = styled(Box)({
  width: '100%',
  height: '100%',
  minHeight: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.progress': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  '&.fixed': {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    zIndex: 999,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

type PropTypes = {
  timeout?: number;
  isProgress?: boolean;
  progress?: number;
  sx?: SxProps;
  isPagination?: boolean;
  variant?: 'fixed' | 'contained';
};

const Loader: React.FC<PropTypes> = ({
  timeout = 0,
  isProgress,
  progress,
  sx,
  isPagination,
  variant = 'contained',
}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, timeout);
    return () => clearTimeout(timeoutId);
  }, []);

  if (!visible) return null;

  return (
    <$Loader
      className={`${isProgress ? 'progress' : ''} ${isPagination ? 'pagination' : ''} ${variant}`}
      sx={sx}
    >
      {isProgress ? (
        <LinearProgress
          sx={{ height: 12, width: 'calc(100% - 52px)', borderRadius: 16 }}
          variant="determinate"
          value={progress}
          color="warning"
        />
      ) : (
        <Box>
          <CircularProgress size={60} thickness={6} color="warning" />
        </Box>
      )}
    </$Loader>
  );
};

export default Loader;
