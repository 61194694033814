import {
  Grid,
  Box,
  FormControl,
  Checkbox,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import MailLineIcon from 'remixicon-react/MailLineIcon';
import SmartphoneLineIcon from 'remixicon-react/SmartphoneLineIcon';
import { styled } from '@mui/material/styles';
import { NotificationsForm } from './NotificationSettingsModal';
import { ChangeEvent, useMemo } from 'react';

const INTERACTION_NOTIFICATIONS = {
  notifyWhenOthersLikeMyContent: 'When someone likes my post',
  notifyWhenOthersCommentOnMyArticlesFlag: 'When someone comments on my posts',
  notifyWhenMentionedMyNameFlag: 'When others mention me',
  notifyWhenOthersReplyToMyCommentsFlag: 'When others reply to my comments',
};

function InteractionsForm() {
  const {
    control,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<NotificationsForm>();
  const emailNotificationSettings = watch('emailNotificationSettings');
  const pushNotificationSettings = watch('pushNotificationSettings');
  const selectedNotification = useMemo(() => {
    if (
      emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag ||
      pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag
    ) {
      return 'realtime';
    }

    if (emailNotificationSettings.notifyDailyOnArticleCreate) {
      return 'notifyDailyOnArticleCreate';
    }

    if (emailNotificationSettings.notifyWeeklyOnArticleCreate) {
      return 'notifyWeeklyOnArticleCreate';
    }

    if (!emailNotificationSettings.sendEmailNotifications) {
      return 'sendEmailNotifications';
    }

    return 'realtime';
  }, [
    emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag,
    pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag,
    emailNotificationSettings.notifyDailyOnArticleCreate,
    emailNotificationSettings.notifyWeeklyOnArticleCreate,
    emailNotificationSettings.sendEmailNotifications,
  ]);

  const handleNotificationsChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'realtime') {
      setValue('emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyDailyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    if (value === 'notifyDailyOnArticleCreate') {
      setValue('emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyDailyOnArticleCreate', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    if (value === 'notifyWeeklyOnArticleCreate') {
      setValue('emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyDailyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    if (value === 'sendEmailNotifications') {
      setValue('emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyDailyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleRelatimeNotificationSettingsChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (event.target.value === 'mobile') {
      setValue('pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', checked, {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (!checked && !emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag) {
        setValue('emailNotificationSettings.notifyDailyOnArticleCreate', true, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue('emailNotificationSettings.notifyDailyOnArticleCreate', false, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    if (event.target.value === 'email') {
      setValue('emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag', checked, {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (!checked && !pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag) {
        setValue('emailNotificationSettings.notifyDailyOnArticleCreate', true, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue('emailNotificationSettings.notifyDailyOnArticleCreate', false, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      setValue('emailNotificationSettings.notifyWeeklyOnArticleCreate', false, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('emailNotificationSettings.sendEmailNotifications', true, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <br />
      <Note>For new posts, notify me with:</Note>
      <RadioGroup
        aria-labelledby="notifications-group-label"
        name="notifications"
        onChange={handleNotificationsChange}
        value={selectedNotification}
      >
        <FormControlLabel
          value="realtime"
          control={<Radio />}
          label="Real-time notifications"
          sx={{ width: 'fit-content' }}
          disabled={isSubmitting}
        />
        <Box mt={-1} ml={4}>
          <FormControlLabel
            control={
              <Checkbox value="mobile" onChange={handleRelatimeNotificationSettingsChange} />
            }
            label="Mobile Push"
            checked={pushNotificationSettings.notifyWhenOthersCreateNewArticlesFlag}
            disabled={isSubmitting}
          />
          <FormControlLabel
            control={<Checkbox value="email" onChange={handleRelatimeNotificationSettingsChange} />}
            label="Email"
            checked={emailNotificationSettings.notifyWhenOthersCreateNewArticlesFlag}
            disabled={isSubmitting}
          />
        </Box>
        <FormControlLabel
          value="notifyDailyOnArticleCreate"
          control={<Radio />}
          label="Daily email digests"
          sx={{ width: 'fit-content' }}
          disabled={isSubmitting}
        />
        <Description>Daily summary of new posts as an email</Description>
        <FormControlLabel
          value="notifyWeeklyOnArticleCreate"
          control={<Radio />}
          label="Weekly email digests"
          sx={{ width: 'fit-content' }}
          disabled={isSubmitting}
        />
        <Description>Weekly summary of new posts as an email</Description>
        <FormControlLabel
          value="sendEmailNotifications"
          control={<Radio />}
          label="No email notifications"
          sx={{ width: 'fit-content' }}
          disabled={isSubmitting}
        />
        <Description>Do not email me for any new posts</Description>
      </RadioGroup>

      <Note sx={{ mt: 2 }}>For post interactions, notify me:</Note>
      <Grid container>
        <Grid item xs={1}>
          <Box title="Email Notifications">
            <MailLineIcon />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box title="Push Notifications">
            <SmartphoneLineIcon />
          </Box>
        </Grid>
      </Grid>
      {Object.keys(INTERACTION_NOTIFICATIONS).map((key) => (
        <Grid container alignItems="center" key={key}>
          <Grid item xs={1}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControl sx={{ ml: '-9px' }}>
                  <Checkbox
                    {...field}
                    disabled={
                      isSubmitting ||
                      field.name === 'emailNotificationSettings.notifyWhenOthersLikeMyContent'
                    }
                    checked={
                      field.value &&
                      field.name !== 'emailNotificationSettings.notifyWhenOthersLikeMyContent'
                    }
                  />
                </FormControl>
              )}
              name={`emailNotificationSettings.${
                key as keyof NotificationsForm['emailNotificationSettings']
              }`}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControl sx={{ ml: '-9px' }}>
                  <Checkbox {...field} disabled={isSubmitting} checked={field.value} />
                </FormControl>
              )}
              name={`pushNotificationSettings.${
                key as keyof NotificationsForm['pushNotificationSettings']
              }`}
            />
          </Grid>
          <Grid item xs={10}>
            {INTERACTION_NOTIFICATIONS[key]}
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const Note = styled(Typography, { label: 'Note' })(({ theme }) => ({
  marginTop: 8,
  marginBottom: 16,
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
}));

const Description = styled(Typography, {
  label: 'Description',
})(({ theme }) => ({
  margin: '-8px 0 16px 32px',
  color: theme.palette.custom.mistBlue,
  fontSize: 14,
}));

export default InteractionsForm;
