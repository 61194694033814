import { Box, Popover, Typography } from '@mui/material';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import { styled } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';
import { ProcoreSyncStats } from '@buildwise/libs/home';
import useUserData from 'hooks/useUserData';

function ProcoreSyncMenuItem() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { company } = useUserData();

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <RefreshLineIcon size={20} />
        <SyncText>
          Data Synced{' '}
          {company?.pmmetrics?.pmsystemName?.toLowerCase() === 'procore' && 'From Procore'}
        </SyncText>
      </Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={() => ({
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0px 0px 24px 0px #0000001F',
          },
        })}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ProcoreSyncStats />
      </Popover>
    </>
  );
}

const SyncText = styled(Typography, { label: 'SyncText' })(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: 8,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.custom.mistBlue,
  textDecoration: 'underline',
  textTransform: 'capitalize',
}));

export default ProcoreSyncMenuItem;
