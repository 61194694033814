import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'assets/icons/login_logo.svg';
import { ReactComponent as LogoTitle } from 'assets/icons/logo_title.svg';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';

const ProcoreSignIn = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const queryCode = searchParams.get('code');
    const queryError = searchParams.get('error');
    if (queryCode) {
      localStorage.setItem('procoreCode', queryCode);
      setTimeout(() => {
        window.close();
      }, 10);
    } else {
      setError(queryError || true);
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  }, []);

  return (
    <Box className="layout">
      <Box
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '998',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          padding: '0 30px',
          background: '#fde175',
        }}
      >
        <Logo cursor="pointer" width={50} height={50} />
        <LogoTitle cursor="pointer" width={100} height={50} style={{ marginLeft: '10px' }} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
        {error ? 'Authorization Failed' : 'We are authenticating please wait......'}
        <br />
        {error}
      </Box>
    </Box>
  );
};

export default ProcoreSignIn;
