import { useTheme } from '@mui/material/styles';
import { Box, FormControl, FormHelperText, FormLabel, Grid, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfigEntryType } from 'config/config';
import { Controller, useFormContext } from 'react-hook-form';
import CalendarFillIcon from 'remixicon-react/CalendarFillIcon';
import ArrowDownSFillIcon from 'remixicon-react/ArrowDownSFillIcon';

type DateFieldProps = {
  field: ConfigEntryType;
};

function DateField({ field }: DateFieldProps) {
  const theme = useTheme();
  const {
    control,
    formState: { isSubmitting },
    watch,
  } = useFormContext<Record<string, any>>();
  const startDate = watch(`MIN_${field.entryCode}`);

  return (
    <Box>
      <Controller
        control={control}
        render={({ field: _field, fieldState }) => (
          <FormControl fullWidth sx={{ my: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {..._field}
                value={!!_field.value ? _field.value : null}
                InputAdornmentProps={{ position: 'end' }}
                components={{
                  OpenPickerIcon: () => <CalendarFillIcon color={theme.palette.custom.black} />,
                }}
                inputFormat=""
                disableMaskedInput
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Date"
                    sx={{
                      '& .MuiInput-input': {
                        padding: '8px 0px',
                        textTransform: 'uppercase',
                      },
                    }}
                  />
                )}
                disabled={isSubmitting}
              />
            </LocalizationProvider>
            <FormHelperText sx={{ ml: 0 }} error>
              {fieldState.error?.message}
            </FormHelperText>
          </FormControl>
        )}
        name={field.entryCode}
      />
      <FormLabel sx={{ color: theme.palette.custom.black, fontSize: 14 }}>Between</FormLabel>
      <Grid container spacing={1.5}>
        <Grid item sm={12} md={6}>
          <Controller
            control={control}
            render={({ field: _field, fieldState }) => (
              <FormControl fullWidth sx={{ my: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {..._field}
                    value={!!_field.value ? _field.value : null}
                    InputAdornmentProps={{ position: 'end' }}
                    components={{
                      OpenPickerIcon: () => <CalendarFillIcon color={theme.palette.custom.black} />,
                    }}
                    inputFormat=""
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Date"
                        sx={{
                          '& .MuiInput-input': {
                            padding: '8px 0px',
                            textTransform: 'uppercase',
                          },
                        }}
                      />
                    )}
                    disabled={isSubmitting}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
            name={`MIN_${field.entryCode}`}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            control={control}
            render={({ field: _field, fieldState }) => (
              <FormControl fullWidth sx={{ my: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {..._field}
                    value={!!_field.value ? _field.value : null}
                    minDate={startDate || null}
                    InputAdornmentProps={{ position: 'end' }}
                    components={{
                      OpenPickerIcon: () => <CalendarFillIcon color={theme.palette.custom.black} />,
                    }}
                    inputFormat=""
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Date"
                        sx={{
                          '& .MuiInput-input': {
                            padding: '8px 0px',
                            textTransform: 'uppercase',
                          },
                        }}
                      />
                    )}
                    disabled={isSubmitting}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
            name={`MAX_${field.entryCode}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DateField;
