type OtherAttributesType = Record<string, any>;

export enum ConfigEntryFieldType {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  REGEX = 'REGEX',
  RATING = 'RATING',
}

export type ConfigEntryType = {
  configEntryRefMap?: Record<string, any>;
  deleteFlag?: boolean;
  entryCode: string;
  entryName?: string;
  entryDescription: string;
  entryType?: 'STANDARD' | 'Standard' | 'APPLICATION' | 'CUSTOM';
  entryValues: string[];
  id?: string;
  isActive: boolean;
  modifiedAt?: Date | string;
  otherAttributes?: OtherAttributesType;
  referenceObject?: {
    configRefName?: string;
    label: string;
    placeHolder: string;
    type: ConfigEntryFieldType;
    maxRating?: number;
  };
  targetMapper?: string;
  targetMapperLabelName?: string;
  targetMapperType?: 'API' | 'CONFIG';
  targetMapperValueKey?: string;
  answers?: (string | number | boolean)[];
};

export enum FORMS_CONFIG_CATEGORY_TYPES {
  PROJECT_PROGRESS_CARD = 'PROJECT_PROGRESS_CARD',
  SCORECARD = 'SCORECARD',
  BUILDWISE_SCORECARD = 'BUILDWISE_SCORECARD',
  REQUEST_A_POST_CARD = 'REQUEST_A_POST_CARD',
}

export type ConfigType = {
  isActive?: boolean;
  companyId: string;
  configCategory?: FORMS_CONFIG_CATEGORY_TYPES;
  configCategoryDescription?: string;
  configName: string;
  configType?: string;
  createdAt?: string | Date;
  createdBy?: string;
  createdByDisplayName?: string;
  createdByExpYears?: string;
  createdByTitle?: string;
  description: string;
  entries: ConfigEntryType[];
  id: string;
  sortFlag?: boolean;
  title?: string;
  updatedAt?: string | Date;
  updatedBy?: string;
  updatedByDisplayName?: string;
  updatedByExpYears?: string;
  updatedByTitle?: string;
};

export type ConfigResponse = {
  config: ConfigType;
};
