import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

type PaginationLoaderProps = {
  isLoading: boolean;
};

function PaginationLoader({ isLoading }: PaginationLoaderProps) {
  return isLoading ? (
    <$PaginationLoaderBox>
      <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
      &nbsp;&nbsp;Loading...
    </$PaginationLoaderBox>
  ) : null;
}

const $PaginationLoaderBox = styled(Box, { label: 'PaginationLoaderBox' })(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0',
  zIndex: 1,
}));

export default PaginationLoader;
