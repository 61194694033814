import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ReactComponent as WordDocumentIcon } from 'assets/icons/doc.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as PptDocumentIcon } from 'assets/icons/ppt.svg';
import { ReactComponent as ExcelDocumentIcon } from 'assets/icons/xls.svg';
import { ReactComponent as TextDocumentIcon } from 'assets/icons/txt.svg';
import FileTextLineIcon from 'remixicon-react/FileTextLineIcon';
import VolumeUpFillIcon from 'remixicon-react/VolumeUpFillIcon';
import { getFileDetails } from 'utility/helpers';
import RenderVideo from 'pages/knowledge/components/RenderVideo';
import { useMemo } from 'react';
import RenderImage from 'pages/knowledge/components/RenderImage';

type RenderAttachmentProps = {
  attachment: AttachmentType;
};

const ThumbnailIcon = ({ attachment }: RenderAttachmentProps) => {
  const { fileType, isImage, isVideo, isAudio, isDocument } = useMemo(
    () =>
      getFileDetails({
        file: attachment,
        useDefaultStrToSliceFromFileName: !attachment.fileUploaded,
      }),
    [attachment],
  );

  if (isImage) {
    return <RenderImage attachment={attachment} />;
  }

  if (isVideo) {
    return (
      <RenderVideo
        forceStopPlaying
        doNotAutoplayVideos
        attachment={attachment}
        variant="company-files"
      />
    );
  }

  if (isAudio) {
    return (
      <$DocumentContainer>
        <VolumeUpFillIcon size={50} />
      </$DocumentContainer>
    );
  }

  if (isDocument) {
    switch (fileType) {
      case 'doc':
      case 'docx':
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'msword': {
        return (
          <$DocumentContainer>
            <WordDocumentIcon width={50} height={50} />
          </$DocumentContainer>
        );
      }

      case 'pdf': {
        return (
          <$DocumentContainer>
            <PdfIcon width={50} height={50} />
          </$DocumentContainer>
        );
      }

      case 'plain':
      case 'txt': {
        return (
          <$DocumentContainer>
            <TextDocumentIcon width={50} height={50} />
          </$DocumentContainer>
        );
      }

      case 'ppt':
      case 'pptx':
      case 'vnd.openxmlformats-officedocument.presentationml.presentation': {
        return (
          <$DocumentContainer>
            <PptDocumentIcon width={50} height={50} />
          </$DocumentContainer>
        );
      }

      case 'xls':
      case 'csv':
      case 'xlsx':
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'vnd.ms-excel': {
        return (
          <$DocumentContainer>
            <ExcelDocumentIcon width={50} height={50} />
          </$DocumentContainer>
        );
      }

      default: {
        return (
          <$DocumentContainer>
            <FileTextLineIcon size={40} />
          </$DocumentContainer>
        );
      }
    }
  }

  return null;
};

const $DocumentContainer = styled(Box)({
  textAlign: 'center',
  width: '80px',
  maxHeight: '100px',
  overflow: 'hidden',
});

export default ThumbnailIcon;
