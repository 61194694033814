import { CommentType, CommentReplyType } from 'pages/article/hooks/useArticleDetailsQuery';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { create } from 'zustand';

type ReportObjectType = 'Article' | 'Question' | 'User' | 'Comment' | null;

interface ReportModalStore {
  reportObject?: ReportObjectType;
  id?: string | null;
  text?: string | null;
  article?: ArticleType;
  comment?: CommentType | CommentReplyType;
  commentPath?: string;
  openReport: (params: {
    reportObject?: ReportObjectType;
    id?: string;
    text?: string;
    article?: ArticleType;
    comment?: CommentType | CommentReplyType;
    commentPath?: string;
  }) => void;
  close: () => void;
}

const useReportModalStore = create<ReportModalStore>((set) => ({
  openReport: (params) => {
    set({ ...params });
  },
  close: () =>
    set({
      reportObject: null,
      id: null,
      text: null,
      article: undefined,
      commentPath: '',
      comment: undefined,
    }),
}));

export default useReportModalStore;
