import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

type CaptureSearchResultPayload = {
  username: string;
  searchType: string;
  searchText: string;
  objectId: string;
  objectDesc: string;
};

export type SearchItem = {
  searchType: 'users' | 'projects' | 'stakeholders';
  operator?: 'or' | 'and';
  filterBy: {
    filters: {
      filterType?: string;
      filterValue: string;
    }[];
  };
};

export type NovaSearchType =
  | 'articles'
  | 'users'
  | 'projects'
  | 'stakeholders'
  | 'companyFiles'
  | 'projectFileSearch'
  | 'chatWithArticle';

export type GlobalSearchPayload = {
  companyId: string;
  fileSearch?: boolean;
  files?: string[];
  filesToSearch?: string[];
  novaSearchKeyword?: string;
  novaSearchType: NovaSearchType;
  pageContent?: boolean;
  searchScope?: string;
  searchType?: string;
  summarization?: boolean;
  chatId?: string;
  llmModel?: 'cohere' | 'titan';
};

class SearchAPI extends JavaAPI {
  public search({ endpoint, payload }: { endpoint?: string; payload: GlobalSearchPayload }) {
    return this.post(endpoint || JAVA_SERVICE_ENDPOINTS.SEARCH, payload);
  }

  public searchItem(payload: SearchItem) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SEARCH_ITEM, payload);
  }

  public captureSearchResultClick(payload: CaptureSearchResultPayload) {
    return this.post(JAVA_SERVICE_ENDPOINTS.CAPTURE_SEARCH_RESULT_CLICK, payload);
  }
}

const searchAPI = new SearchAPI();
export default searchAPI;
