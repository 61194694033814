import * as Yup from 'yup';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, FormControl, InputAdornment, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import PostDescription from '../PostDescription';
import { TabNameType } from './GeneratePostWithAiModal';
import GlobalLineIcon from 'remixicon-react/GlobalLineIcon';
import FileUpload from './FileUpload';
import { AttachmentType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';

type ContentFormSchema = {
  description: string;
};

export type FileFormSchema = {
  attachments: AttachmentType[];
  coverPhotoFileName: string;
};

type URLFormSchema = {
  url: string;
};

const ContentFormSchemaValidations = Yup.object().shape({
  description: Yup.string().required('Please enter text'),
});

const URLFormSchemaValidation = Yup.object().shape({
  url: Yup.string().required('Please enter the url'),
});

const FileFormSchemaValidation = Yup.object().shape({
  attachments: Yup.array(Yup.object()),
  coverPhotoFileName: Yup.string(),
});
export const schemas = {
  content: ContentFormSchemaValidations,
  url: URLFormSchemaValidation,
  file: FileFormSchemaValidation,
};
export const _defaultValues = {
  content: {
    description: '',
  },
  file: {
    attachments: [],
    coverPhotoFileName: '',
  },
  url: {
    url: '',
  },
};
export type FormValues = ContentFormSchema | FileFormSchema | URLFormSchema;

type GeneratePostWithAiFormProps = {
  tab: TabNameType;
  isExpanded: boolean;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

function GeneratePostWithAiForm({
  tab,
  showModal,
  setShowModal,
  isExpanded,
}: GeneratePostWithAiFormProps) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<FormValues>();
  return (
    <Box sx={{ marginTop: 2 }}>
      {tab === 'content' && (
        <PostDescription
          showModal={showModal}
          setShowModal={setShowModal}
          isEditing={false}
          isExpanded={isExpanded}
          customPlaceHolder="Enter text here"
          customStyles={{ border: '1px solid #B5B6C5', borderRadius: 2, padding: 1 }}
          source="convert_to_post"
        />
      )}
      {tab === 'url' && (
        <Controller
          control={control}
          name="url"
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message || ''}
                fullWidth
                placeholder="Enter your URL here"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GlobalLineIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    '& .MuiInputBase-input': {
                      padding: '14px 0px',
                    },
                  },
                }}
                disabled={isSubmitting}
              />
            </FormControl>
          )}
        />
      )}
      {tab === 'file' && <FileUpload />}
    </Box>
  );
}

export default GeneratePostWithAiForm;
