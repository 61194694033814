import axios, { AxiosRequestConfig } from 'axios';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { JAVA_SERVICE_URL, JAVA_SERVICE_ENDPOINTS } from '../endpoints';
import useAuthStore from '../../../store/useAuthStore';

const apiClient = (config?: AxiosRequestConfig) =>
  axios.create({
    baseURL: JAVA_SERVICE_URL,
    headers: {
      'Client-id': 'nova-web-app',
    },
    ...config,
  });

let isRefreshing = false;

apiClient().interceptors.response.use(
  (response) => response,
  async (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { config, response } = error;
    const originalRequest = config;

    if (response) {
      if ((response.status === 401 && isTokenExpired(response)) || response.status === 423) {
        let newToken;
        if (!isRefreshing && response.status !== 423) {
          isRefreshing = true;
          newToken = await refreshAccessToken();
          isRefreshing = false;
        }

        if (response.status === 423) {
          const errorMsg = response?.data?.errorPresent ? catchErrorMessage(error) : response.data;
          toast.error(errorMsg);
          useAuthStore.getState().resetState();
          return <Navigate to="/login" replace />;
        } else {
          const retryOrigReq = new Promise((resolve) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return resolve(axios(originalRequest));
          });
          await retryOrigReq;
        }
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

const isTokenExpired = (response) => {
  return JSON.stringify(response).toLocaleLowerCase().includes('token expired');
};

const refreshAccessToken = async () => {
  const currentRefreshToken = useAuthStore.getState().refreshToken;
  if (!currentRefreshToken) {
    return '';
  }

  try {
    const response = await apiClient().post(
      JAVA_SERVICE_ENDPOINTS.REFRESH_TOKEN,
      { refresh_token: currentRefreshToken },
      { headers: { 'Client-id': 'nova-web-app' } },
    );

    const { auth_token: accessToken = '', refresh_token: refreshToken = '' } = response?.data || {};
    if (accessToken) {
      useAuthStore.setState({
        refreshToken,
        accessToken,
      });
      return accessToken;
    }
    useAuthStore.getState().resetState();
    window.location.href = '/';
    return accessToken;
  } catch (error) {
    useAuthStore.getState().resetState();
    window.location.href = '/';
    console.warn(error);
    return error;
  }
};

export default apiClient;
