import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { UserType } from 'hooks/useUserData';
import UserAPI from 'api/UserAPI';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import { updateUsersQueryCache } from 'pages/people/hooks/usePeopleQuery';
import { UserData } from './CompanyUsers';
import EditUserForm from './EditUserForm';

export type ModifyUserFormData = {
  firstname: string;
  lastname: string;
  email: string;
  title: string;
  primaryCompanyName: string;
  role: string;
  active: string;
  canEndorseFlag: boolean;
  canSuggestRevisiontToPostsFlag: boolean;
  canMovePostsToTopFlag: boolean;
  canCreatePlaylistFlag: boolean;
  canInviteOthersFlag: boolean;
};

const EditUserSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Required field')
    .min(2, 'The first name must contain at least 2 characters')
    .nullable(),
  lastname: Yup.string()
    .required('Required field')
    .min(1, 'The last name must be at least one character')
    .nullable(),
  email: Yup.string().required('Required field'),
  title: Yup.string().required('Required field'),
  primaryCompanyName: Yup.string()
    .required('Required field')
    .min(2, 'Company name must contain at least 2 characters'),
  role: Yup.string().required('Required field'),
  active: Yup.string().required('Required field'),
  canEndorseFlag: Yup.boolean(),
  canSuggestRevisiontToPostsFlag: Yup.boolean(),
  canMovePostsToTopFlag: Yup.boolean(),
  canCreatePlaylistFlag: Yup.boolean(),
  canInviteOthersFlag: Yup.boolean(),
  addVoicePost: Yup.boolean(),
  articlesLLMSearch: Yup.boolean(),
  companyFilesLLMSearch: Yup.boolean(),
});

type EditUserModalTypes = {
  open: boolean;
  people: UserType[];
  user: UserData;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const EditUserModal: FC<EditUserModalTypes> = ({ open, people, user, setOpen }) => {
  const editUserForm = useForm<ModifyUserFormData>({
    mode: 'all',
    resolver: yupResolver(EditUserSchema),
    defaultValues: {
      firstname: user.firstName || '',
      lastname: user.lastName || '',
      email: user.userName || '',
      title: user.jobTitle || '',
      primaryCompanyName: user.primaryCompanyName || '',
      role: user.userRole || '',
      active: user.status || '',
      canEndorseFlag: user?.canEndorseFlag || false,
      canSuggestRevisiontToPostsFlag: user?.canSuggestRevisiontToPostsFlag || false,
      canMovePostsToTopFlag: user?.canMovePostsToTopFlag || false,
      canCreatePlaylistFlag: user?.canCreatePlaylistFlag || false,
      canInviteOthersFlag: user?.canInviteOthersFlag || false,
    },
  });
  const {
    formState: { isDirty, isValid, isSubmitting },
    handleSubmit,
    reset,
    trigger: validateForm,
  } = editUserForm;
  const { entries: jobTitles } = useConfigQuery(user.companyId, 'JOB_TITLES');
  const jobTitlesOptions = useMemo(
    () => (jobTitles ? jobTitles?.map((title) => title.entryCode) : []),
    [jobTitles],
  );

  useEffect(() => {
    validateForm();
  }, []);

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (values: ModifyUserFormData) => {
    try {
      const editUser = people.find(
        (userData) => userData.username === user.userName && userData.companyId === user.companyId,
      );
      if (editUser) {
        const payload = {
          ...editUser,
          ...values,
          firstname: values.firstname.trim(),
          lastname: values.lastname.trim(),
          email: values.email.trim(),
          active: values.active === 'Active',
          roles: [
            {
              roleName: values.role,
              roleDescription:
                values.role === 'ROLE_COMPANY_ADMIN' ? 'Role Company Admin' : 'Role Company User',
            },
          ],
        };
        const { data } = await UserAPI.editUser(payload);
        updateUsersQueryCache(data.companyId, data, 'update');
        toast.success('User details updated');
        closeModal();
      }
    } catch (error) {
      console.error('UPDATE_USER_DETAILS_ERROR', error);
      toast.error(catchErrorMessage(error));
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: 'black' }}>
          Modify User
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description">
        <FormProvider {...editUserForm}>
          <EditUserForm jobTitlesOptions={jobTitlesOptions} user={user} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={triggerSubmit}
          variant="contained"
        >
          {isSubmitting ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
