import { FC } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { FileActivityType } from '../apis/HomeAPI';
import RenderFileActivity from './RenderFileActivity';

type ViewDocumentsModalProps = {
  documents: FileActivityType[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewDocumentsModal: FC<ViewDocumentsModalProps> = ({ documents, open, setOpen }) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="md"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>New Files</Title>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ pt: 0 }}>
        <Table stickyHeader aria-label="table" sx={{ position: 'relative' }}>
          <TableHead
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.white,
            })}
          >
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  fontWeight: 700,
                  width: '20%',
                  backgroundColor: theme.palette.custom.white,
                })}
              >
                Name
              </TableCell>
              {/* <TableCell
                sx={(theme) => ({
                  fontWeight: 700,
                  backgroundColor: theme.palette.custom.white,
                })}
              >
                Owner
              </TableCell> */}
              {/* <TableCell
                sx={(theme) => ({
                  fontWeight: 700,
                  backgroundColor: theme.palette.custom.white,
                  width: '15%',
                })}
              >
                Location
              </TableCell> */}
              <TableCell
                sx={(theme) => ({
                  fontWeight: 700,
                  backgroundColor: theme.palette.custom.white,
                })}
              >
                Created On
              </TableCell>
              <TableCell
                sx={(theme) => ({
                  fontWeight: 700,
                  backgroundColor: theme.palette.custom.white,
                })}
              >
                Size
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((file) => (
              <RenderFileActivity key={file.documentName} file={file} />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

export default ViewDocumentsModal;
