import useUserData from 'hooks/useUserData';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import QuestionsAPI from 'api/QuestionsAPI';
import { useMemo } from 'react';
import { CommentType } from 'pages/article/hooks/useArticleDetailsQuery';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';

export type AssignedUserType = {
  displayName: string;
  id: string;
  pmuserId: string | null;
  profileImageURL?: string;
  title: string;
  userName: string;
  years: string;
};

export type QuestionType = {
  articleId?: string;
  assignedTo: string[];
  assignedToUserDetails: AssignedUserType[];
  attachments: AttachmentType[];
  comments: CommentType[];
  companyId: string;
  createdAt: string;
  createdBy: string;
  division?: string;
  hasAttachments: boolean;
  id: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  mentions: string[];
  questionDescription: string;
  questionTitle: string;
  specCategory?: string;
  specSection?: string;
  status: string;
  suitableRoles: string[];
  topicsAutoGenerated: any;
  userNameExpYearsToDisplay: string;
  userNameTitleToDisplay: string;
  userNameToDisplay: string;
  profileImageURL?: string;
};

export const useMyQuestions = () => {
  const { companyId } = useUserData();
  const { data: questionsData, isLoading } = useQuery<AxiosResponse<QuestionType[]>, Error>(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY_QUESTIONS.replace(':companyId', companyId) +
      '/?createdByMe=true',
    () => QuestionsAPI.getMyQuestions(companyId),
    {
      staleTime: 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId,
    },
  );

  const questions = useMemo(() => {
    return questionsData?.data || [];
  }, [questionsData]);

  return {
    questions,
    isLoading,
  };
};

export const useAssignedQuestions = ({ status, answeredBy = '', shouldFetch = true }) => {
  const { companyId } = useUserData();
  const {
    data: questionsData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery<AxiosResponse<QuestionType[]>, Error>(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY_QUESTIONS.replace(':companyId', companyId) +
      `/?assignedToMe=TRUE&questionStatus=${status}${answeredBy}`,
    () => QuestionsAPI.getAssignedToMe(companyId, status, answeredBy),
    {
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const questions = useMemo(() => {
    return questionsData?.data || [];
  }, [questionsData]);

  return {
    questions,
    isLoading,
    refetch,
    isFetching,
  };
};
