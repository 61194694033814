import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import {
  BASE,
  FORGOT_PASSWORD,
  LOGIN,
  PROCORE_SIGN_IN,
  SIGNUP,
  SSO_SIGN_IN,
  HOME,
} from 'constants/routes';
import useAuthStore from 'store/useAuthStore';
import { shallow } from 'zustand/shallow';
import PageNotFound from './PageNotFound';
import SSOSignIn from 'SSOSignIn';
import ProcoreSignIn from '@buildwise/pages/ProcoreSignIn';
import BuildWiseLoader from './BuildWiseLoader';
import Loader from 'components/common/loader/Loader';
import { handlePageScrollPosition } from 'utility/helpers';

const Login = lazy(() => import('@buildwise/pages/Login'));
const Signup = lazy(() => import('@buildwise/pages/Signup'));
const ForgotPassword = lazy(() => import('@buildwise/pages/ForgotPassword'));
const SelectCompanyModal = lazy(() => import('components/main/SelectCompanyModal'));

const AuthRoutes = () => {
  const navigate = useNavigate();
  const [userAuthDetails, setUser] = useAuthStore((state) => [state.user, state.setUser], shallow);
  const isNovaAdminOrAssociatedUser = useMemo(
    () => !!(userAuthDetails?.isNovaAdmin || userAuthDetails?.isAssociatedWithOtherCompanies),
    [userAuthDetails?.isNovaAdmin, userAuthDetails?.isAssociatedWithOtherCompanies],
  );
  const [selectCompanyModal, setSelectCompanyModal] = useState(
    !userAuthDetails?.selectedCompanyId && isNovaAdminOrAssociatedUser,
  );
  const [fetchUserDetails, setFetchUserDetails] = useState(false);
  const [fetchCompaniesDetails, setFetchCompanyDetails] = useState(false);
  const { user, userInfo, company, companyQueryInfo, logout } = useUserData({
    shouldFetchCompanyDetails:
      !isNovaAdminOrAssociatedUser || !!userAuthDetails.selectedCompanyId || fetchCompaniesDetails,
    shouldFetchUserDetails: !isNovaAdminOrAssociatedUser || fetchUserDetails,
  });

  useEffect(() => {
    if (!userAuthDetails?.selectedCompanyId) {
      setSelectCompanyModal(isNovaAdminOrAssociatedUser);
    }
  }, [userAuthDetails?.selectedCompanyId, isNovaAdminOrAssociatedUser]);

  useEffect(() => {
    if (company) {
      setFetchUserDetails(true);
    }
  }, [company]);

  const handleSelectCompany = useCallback(
    (companyId: string) => {
      if (companyId) {
        setUser({ ...userAuthDetails, companyId, selectedCompanyId: companyId });
        setFetchCompanyDetails(true);
        if (userAuthDetails?.isNovaAdmin) {
          setFetchUserDetails(true);
        }
        setSelectCompanyModal(false);
      }
    },
    [userAuthDetails],
  );

  const signOut = useCallback(() => {
    handlePageScrollPosition('all', 0, 0, 'reset');
    logout();
    setSelectCompanyModal(false);
    navigate('/login');
  }, []);

  if (userInfo.isLoading || companyQueryInfo?.isLoading) {
    return <BuildWiseLoader />;
  }

  if (user && company) {
    return <Navigate to={HOME} state={{ justLoggedIn: true }} />;
  }

  return (
    <Suspense fallback={<Loader variant="fixed" />}>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGNUP} element={<Signup />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={PROCORE_SIGN_IN} element={<ProcoreSignIn />} />
        <Route path={SSO_SIGN_IN} element={<SSOSignIn />} />
        <Route path={BASE} element={<Login />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      {selectCompanyModal && (
        <SelectCompanyModal
          selectCompanyModal={selectCompanyModal}
          handleSelectCompany={handleSelectCompany}
          onClose={signOut}
        />
      )}
    </Suspense>
  );
};

export default AuthRoutes;
