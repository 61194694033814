import React, { useRef, useState } from 'react';
import { Box, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Popper from 'components/common/popper/Popper';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { MenuItemType } from 'components/drawer/RenderMenu';

const $MenuList = styled(Box, { label: 'MenuList' })(({ theme }) => ({
  minWidth: 160,
  maxWidth: 250,
  width: 'max-content',
  backgroundColor: theme.palette.custom.offWhite,
  borderRadius: 8,
  overflow: 'hidden',
  '& .MuiMenuItem-root': {
    paddingLeft: 21,
    paddingRight: 21,
    height: 40,
    lineHeight: '16px',
    transition: 'background 0.3s',
    '&:hover': {
      background: theme.palette.custom.inputBackground,
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.custom.body,
    },
  },
}));

const $ListItemIcon = styled(ListItemIcon, { label: 'ListItemIcon' })({
  minWidth: '28px !important',
  color: 'inherit',
});

const $SubTitle = styled(Typography, { label: 'SubTitle' })({
  color: '#6A6C89',
  fontSize: '12px',
  whiteSpace: 'pre-wrap',
});

type SelectMenuProps = {
  menu: MenuItemType[];
  children: any;
  menuName?: string;
  onClose?: () => void;
};

const SelectMenu: React.FC<SelectMenuProps> = ({ menu, children, menuName, onClose }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<any>();

  const closeHandle = () => {
    setOpen(false);
    onClose?.();
  };
  const openHandle = () => setOpen(true);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ref: anchorEl,
          onClick: openHandle,
        }),
      )}
      <Popper open={open} onClose={closeHandle} anchor={anchorEl.current} color="offWhite">
        <$MenuList className={menuName || ''}>
          {menu.map(
            (item, index) =>
              !item.isHidden && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    item.onClick?.();
                    setOpen(false);
                  }}
                  sx={{ height: 'fit-content !important' }}
                >
                  {item.icon && <$ListItemIcon>{item.icon}</$ListItemIcon>}
                  <ListItemText sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ color: `${item.titleColor} !important` }}>
                      {item.title}
                      <$SubTitle>{item.subTitle}</$SubTitle>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ),
          )}
        </$MenuList>
      </Popper>
    </>
  );
};

export default SelectMenu;
