import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { useQuery } from 'react-query';
import TemplatesAPI from '../apis/TemplatesAPI';
import { TemplateType } from '../types';

const useTemplatesQuery = (companyId: string) => {
  const {
    data: templatesRes,
    isLoading,
    isFetching,
  } = useQuery(
    [JAVA_SERVICE_ENDPOINTS.GET_TEMPLATES.replace(':companyId', companyId)],
    () => TemplatesAPI.getTemplates(companyId),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId,
    },
  );

  return {
    templates: templatesRes?.data || [],
    isLoading,
    isFetching,
  };
};

export const useUserTemplatesQuery = (userName: string) => {
  const {
    data: templatesRes,
    isLoading,
    isFetching,
  } = useQuery(
    [JAVA_SERVICE_ENDPOINTS.GET_USER_TEMPLATES.replace(':userName', userName)],
    () => TemplatesAPI.getUserTemplates(userName),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!userName,
    },
  );

  return {
    userTemplates: templatesRes?.data?.templates || [],
    isLoading,
    isFetching,
  };
};

export const useTemplateDetailsQuery = (companyId: string, templateId: string) => {
  const {
    data: templateDetailsRes,
    isLoading,
    isFetching,
  } = useQuery(
    [
      JAVA_SERVICE_ENDPOINTS.GET_TEMPLATE_DETAILS.replace(':companyId', companyId).replace(
        ':templateId',
        templateId,
      ),
    ],
    () => TemplatesAPI.getTemplateDetails(companyId, templateId),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
      enabled: !!companyId && !!templateId,
    },
  );

  return {
    template: templateDetailsRes?.data?.template,
    isLoading,
    isFetching,
  };
};

export const updateTemplatesCache = (
  companyId: string,
  template: TemplateType,
  action: 'add-template' | 'update-template' | 'delete-template',
) => {
  queryClient.setQueriesData<AxiosResponse<TemplateType[]> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_TEMPLATES.replace(':companyId', companyId)],
    (oldData: AxiosResponse<TemplateType[]> | undefined) => {
      if (oldData) {
        let templates = [...oldData.data];

        if (action === 'add-template') {
          templates.unshift(template);
        }

        if (action === 'update-template') {
          const templateIndex = templates.findIndex((temp) => temp.id === template.id);
          if (templateIndex > -1) {
            templates[templateIndex] = {
              ...templates[templateIndex],
              ...template,
            };
          }
        }

        if (action === 'delete-template') {
          const templateIndex = templates.findIndex((temp) => temp.id === template.id);
          if (templateIndex > -1) {
            templates.splice(templateIndex, 1);
          }
        }

        return {
          ...oldData,
          data: templates,
        };
      }

      return oldData;
    },
  );
};

export default useTemplatesQuery;
