import { FC, useMemo, useState } from 'react';
import {
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import Settings2FillIcon from 'remixicon-react/Settings2FillIcon';
import UserSettingsFillIcon from 'remixicon-react/UserSettingsFillIcon';
import ListSettingsFillIcon from 'remixicon-react/ListSettingsFillIcon';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CompanyAPI from 'api/CompanyAPI';
import { CompanyType, updateCompaniesQueryCache } from 'hooks/useCompanyQueries';
import { catchErrorMessage } from 'utility/helpers';

type UpdateCompanySettingsFormValuesType = {
  isActive: string;
  externalFlag: string;
  sendNotificationFlag: boolean;
  emailWeeklySummaryFlag: boolean;
  pmSyncFlag: boolean;
};

type ModifyCompanyModalProps = {
  company: CompanyType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseCallback?: () => void;
};

const ModifyCompanyModal: FC<ModifyCompanyModalProps> = ({
  company,
  open,
  setOpen,
  onCloseCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('general');
  const { control, handleSubmit, reset, watch } = useForm({
    mode: 'onTouched',
    defaultValues: {
      isActive: company.isActive ? 'Active' : 'Inactive',
      externalFlag: company.settings?.externalFlag ? 'External' : 'Internal',
      sendNotificationFlag: company.settings?.sendNotificationFlag || false,
      emailWeeklySummaryFlag: company.settings?.emailWeeklySummaryFlag || false,
      pmSyncFlag: company.settings?.pmSyncFlag || false,
    },
  });
  const isActive = watch('isActive');
  const externalFlag = watch('externalFlag');
  const sendNotificationFlag = watch('sendNotificationFlag');
  const emailWeeklySummaryFlag = watch('emailWeeklySummaryFlag');
  const pmSyncFlag = watch('pmSyncFlag');

  const closeModal = () => {
    reset();
    setOpen(false);
    onCloseCallback?.();
  };

  const onSubmit = async (values: UpdateCompanySettingsFormValuesType) => {
    try {
      setLoading(true);
      const { data } = await CompanyAPI.updateCompanySettings(company.id, {
        id: company.id,
        isActive: values.isActive === 'Active',
        settings: {
          ...company.settings,
          externalFlag: values.externalFlag === 'External',
          sendNotificationFlag: values.sendNotificationFlag,
          emailWeeklySummaryFlag: values.emailWeeklySummaryFlag,
          pmSyncFlag: values.pmSyncFlag,
        },
      });
      updateCompaniesQueryCache(data, 'update');
      toast.success('Company settings updated');
      closeModal();
    } catch (error) {
      console.error('UPDATE_COMPANY_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSave = handleSubmit(onSubmit);

  const hasUpdatedAnySettings = useMemo(() => {
    const isActiveValue = isActive === 'Active';
    const externalFlagValue = externalFlag === 'External';

    return (
      company.isActive !== isActiveValue ||
      company.settings.externalFlag !== externalFlagValue ||
      company.settings.sendNotificationFlag !== sendNotificationFlag ||
      company.settings.emailWeeklySummaryFlag !== emailWeeklySummaryFlag ||
      company.settings.pmSyncFlag !== pmSyncFlag
    );
  }, [isActive, externalFlag, sendNotificationFlag, emailWeeklySummaryFlag, pmSyncFlag]);

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <$Title>Modify Company</$Title>
      </CustomDialogTitle>
      <DialogContent sx={{ minHeight: '400px' }} dividers>
        <Typography mb={2}>
          Company Name : <strong>{company.name}</strong>
        </Typography>
        <Tabs value={tab} onChange={(_: unknown, newValue: string) => setTab(newValue)}>
          <Tab
            icon={<Settings2FillIcon size={20} />}
            iconPosition="start"
            key="general"
            value="general"
            label="General"
          />
          <Tab
            icon={<UserSettingsFillIcon size={20} />}
            iconPosition="start"
            key="notifications"
            value="notifications"
            label="Notifications"
          />
          <Tab
            icon={<ListSettingsFillIcon size={20} />}
            iconPosition="start"
            key="integrations"
            value="integrations"
            label="Integrations"
          />
        </Tabs>

        {tab === 'general' && (
          <Box mt={1}>
            <$SettingRow>
              <Typography>Subscription Status</Typography>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      control={<Radio size="small" />}
                      value="Active"
                      label="Active"
                      disabled={loading}
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      value="Inactive"
                      label="Inactive"
                      disabled={loading}
                    />
                  </RadioGroup>
                )}
                name="isActive"
              />
            </$SettingRow>
            <$SettingRow>
              <Typography>Company Type</Typography>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      control={<Radio size="small" />}
                      value="External"
                      label="External"
                      disabled={loading}
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      value="Internal"
                      label="Internal"
                      disabled={loading}
                    />
                  </RadioGroup>
                )}
                name="externalFlag"
              />
            </$SettingRow>
          </Box>
        )}

        {tab === 'notifications' && (
          <Box mt={1}>
            <$SettingRow>
              <Typography>Enable Notifications</Typography>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch disableRipple {...field} disabled={loading} checked={field.value} />
                )}
                name="sendNotificationFlag"
              />
            </$SettingRow>
            <$SettingRow>
              <Typography>Send Weekly Activity Summary Emails To Users</Typography>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch disableRipple {...field} disabled={loading} checked={field.value} />
                )}
                name="emailWeeklySummaryFlag"
              />
            </$SettingRow>
          </Box>
        )}

        {tab === 'integrations' && (
          <Box mt={1}>
            <$SettingRow>
              <Typography>Sync Project Data From PM Systems</Typography>
              <Controller
                control={control}
                render={({ field }) => (
                  <Switch disableRipple {...field} disabled={loading} checked={field.value} />
                )}
                name="pmSyncFlag"
              />
            </$SettingRow>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={loading || !hasUpdatedAnySettings}
          onClick={handleSave}
        >
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const $Title = styled(Typography, { label: 'DialogTitle' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

const $SettingRow = styled(Box, { label: 'SettingRow' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '4px 0',
});

export default ModifyCompanyModal;
