import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import {
  ProjectProgressCardFormType,
  ScoreCardFormType,
} from '@buildwise/pages/project/hooks/useProjectDetailsQuery';

type FormProps = ProjectProgressCardFormType | ScoreCardFormType;

export type RenderPendingFormCardType = {
  context: string;
  form: FormProps;
};

const RenderPendingFormCard = ({ form }: RenderPendingFormCardType) => {
  const theme = useTheme();

  return (
    <$Container>
      <$ProgressCardContent>
        <Box sx={{ cursor: 'pointer' }}>
          <$ProgressCardTitle variant="h5">
            {form?.formCategory?.toLowerCase().includes('progress')
              ? form?.title
              : form?.contact?.name}
          </$ProgressCardTitle>
        </Box>
        {form?.project?.name && (
          <ProjectDetailsContainer>
            <BuildingLineIcon color={theme.palette.custom.tuna} size={20} />
            <ProjectName>{form?.project?.name}</ProjectName>
          </ProjectDetailsContainer>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          {form.status === 'NEW' && <Chip label="New" variant="outlined" />}
          {form.status === 'INPROGRESS' && <Chip label="In Progress" />}
          {form?.dueDate && (
            <$ProgressCardPublishedAt variant="caption">
              Due {moment(form.dueDate).format('ll')}
            </$ProgressCardPublishedAt>
          )}
        </Box>
      </$ProgressCardContent>
    </$Container>
  );
};

const $Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  marginBottom: '16px',
  borderRadius: '8px',
  border: '1px solid #EBEDF0',
  cursor: 'pointer',
  padding: '16px',
  '&:hover': {
    border: '1.5px solid #FFC107',
  },
}));

const $ProgressCardContent = styled(Box, { label: 'ProgressCardContent' })(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
}));

const $ProgressCardTitle = styled(Typography, { label: 'ProgressCardTitle' })(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.custom.tuna,
  lineHeight: '29px',
}));

const $ProgressCardPublishedAt = styled(Typography, { label: 'ProgressCardPublishedAt' })(
  ({ theme }) => ({
    fontSize: '13px',
    fontWeight: 400,
    marginRight: '8px',
    color: theme.palette.custom.mistBlue,
  }),
);

const ProjectDetailsContainer = styled(Box, { label: 'ProjectDetailsContainer' })({
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
});

const ProjectName = styled(Typography, { label: 'ProjectName' })(({ theme }) => ({
  marginLeft: 6,
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.custom.tuna,
}));

export default RenderPendingFormCard;
