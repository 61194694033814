import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HandleOperation from 'components/utils/HandleOperation';
import { ReactNode, ChangeEvent, useMemo, Fragment } from 'react';
import ArrowDownSFillIcon from 'remixicon-react/ArrowDownSFillIcon';
import Filter2LineIcon from 'remixicon-react/Filter2LineIcon';

export type FilterOptionType = {
  label: ReactNode;
  badgeValue?: ReactNode;
  value: string;
  disabled?: boolean | undefined;
};

export type FilterType = {
  category?: string;
  displayName?: string;
  options: FilterOptionType[];
  inputType?: string;
  allOptions?: string[];
  defaultOpen?: boolean;
  isGrouped?: boolean;
  // checkbox type input options
  showSelectAll?: boolean;
  selectedOptions?: string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  // radio type input options
  selectedValue?: string;
  onRadioChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

type FilterProps = {
  filters: FilterType[];
  isLoading?: boolean;
  skeltonCount?: number;
  clearFilters?: () => void;
};

const Filter: React.FC<FilterProps> = ({ filters, clearFilters, isLoading, skeltonCount }) => {
  const [individualFilters, groupedFilters] = useMemo(() => {
    const directFilters: FilterType[] = [];
    const groupedFilters: FilterType[] = [];

    filters.forEach((filter) => {
      if (filter.isGrouped !== undefined && !filter.isGrouped) {
        directFilters.push(filter);
      } else if (filter.options?.length) {
        groupedFilters.push(filter);
      }
    });

    return [directFilters, groupedFilters];
  }, [filters]);
  const filtersSelected = useMemo(
    () => !!filters.find((filter) => filter?.selectedOptions && filter.selectedOptions?.length > 0),
    [filters],
  );

  const getOptions = (options: FilterOptionType[]) => {
    const blankOptionIndex = options.findIndex((option) => option.value === '(blank)');
    if (blankOptionIndex > -1) {
      const updatedOptions = [...options];
      updatedOptions.splice(blankOptionIndex, 1);
      return [...updatedOptions, options[blankOptionIndex]];
    }
    return options;
  };

  if (filters.length === 0) {
    return null;
  }

  return (
    <$StickyBox>
      <$Filter>
        <$FilterHeader>
          <Box display="flex">
            <Filter2LineIcon style={{ marginRight: '8px' }} />
            Filter
          </Box>
          {clearFilters && filtersSelected && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button onClick={clearFilters}>Clear Filters</Button>
            </Box>
          )}
        </$FilterHeader>
        <Divider sx={{ mx: 2 }} />
        <$Container>
          <HandleOperation
            isLoading={isLoading}
            skelton={
              <Box pt={1} my={1} display="flex" alignItems="center">
                <Skeleton variant="rounded" width="100%" height={15} />
              </Box>
            }
            skeltonCount={skeltonCount || 5}
          >
            {individualFilters.map((filter, index) => (
              <Box sx={{ pt: 2 }} key={index}>
                {filter.options.map((option, index) => (
                  <Box key={`${index}${option.label}`} width="100%" sx={{ px: 1 }}>
                    <$FormControlLabel
                      control={
                        <Checkbox
                          onChange={filter.onChange}
                          checked={filter?.selectedOptions?.includes(option.value)}
                          value={option.value}
                        />
                      }
                      label={
                        <Box component="span" width="100%" display="flex" alignItems="center">
                          {option.label} &nbsp;
                          {option.badgeValue ? `(${option.badgeValue})` : ''}
                        </Box>
                      }
                      value={option.value}
                      disabled={option.disabled}
                    />
                  </Box>
                ))}
              </Box>
            ))}
            {groupedFilters.map((filter, index) => (
              <Fragment key={filter.category}>
                <$Accordion defaultExpanded={filter.defaultOpen}>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ArrowDownSFillIcon />}
                    sx={{ px: 1 }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography fontWeight="bold">
                        {filter?.displayName || filter.category}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <$AccordionDetails sx={{ maxHeight: 500 }}>
                    <$FormWrapper>
                      <Box>
                        {filter.inputType !== 'radio' && filter.showSelectAll && (
                          <Box>
                            <$FormControlLabel
                              control={
                                <Checkbox
                                  onChange={filter.onChange}
                                  checked={
                                    filter?.allOptions?.length === filter?.selectedOptions?.length
                                  }
                                />
                              }
                              label="Select All"
                              value="Select All"
                              className="select-all"
                            />
                          </Box>
                        )}
                        {filter.inputType !== 'radio' &&
                          getOptions(filter.options)?.map((option, index) => (
                            <Box key={`${index}${option.label}`} width="100%">
                              <$FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={filter.onChange}
                                    checked={!!filter?.selectedOptions?.includes(option.value)}
                                    value={option.value}
                                  />
                                }
                                label={
                                  <Box
                                    component="span"
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    {option.label}&nbsp;
                                    {option.badgeValue ? `(${option.badgeValue})` : ''}
                                  </Box>
                                }
                                value={option.value}
                                disabled={option.disabled}
                              />
                            </Box>
                          ))}
                        {filter.inputType === 'radio' && (
                          <RadioGroup
                            value={filter.selectedValue}
                            onChange={filter.onRadioChange}
                            defaultValue={filter.selectedValue}
                            name="selectedValue"
                          >
                            {getOptions(filter.options).map((option, index) => (
                              <Box key={`${index}${option.label}`}>
                                <$FormControlLabel
                                  value={option.value}
                                  control={<Radio size="small" />}
                                  label={
                                    <Box
                                      component="span"
                                      width="100%"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      {option.label}&nbsp;
                                      {option.badgeValue ? `(${option.badgeValue})` : ''}
                                    </Box>
                                  }
                                />
                              </Box>
                            ))}
                          </RadioGroup>
                        )}
                      </Box>
                    </$FormWrapper>
                  </$AccordionDetails>
                </$Accordion>
                {index < groupedFilters.length - 1 && <Divider />}
              </Fragment>
            ))}
          </HandleOperation>
        </$Container>
      </$Filter>
    </$StickyBox>
  );
};

const $StickyBox = styled(Box, { label: 'StickyBox' })(({ theme }) => ({
  position: 'sticky',
  top: 150,
  zIndex: 9,
}));

const $Filter = styled(Paper, { label: 'Filter' })(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  borderRadius: 8,
  boxShadow: 'none',
}));

const $FilterHeader = styled(Box, { label: 'FilterHeader' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  padding: '8px 20px',
}));

const $Container = styled(Box, { label: 'Container' })({
  position: 'relative',
  padding: '0 16px 8px',
});

const $Accordion = styled(Accordion, { label: 'Accordion' })({
  margin: '8px 0',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  '&::before': {
    display: 'none',
  },
});

const $AccordionDetails = styled(AccordionDetails, { label: 'AccordionDetails' })({
  height: 'fit-content',
  maxHeight: '600px',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '0 8px',
});

const $FormWrapper = styled(Box, { label: 'FormWrapper' })({
  width: '100%',
  height: 'fit-content',
});

const $FormControlLabel = styled(FormControlLabel, { label: 'FormControlLabel' })({
  width: '100%',
  alignItems: 'flex-start',
  marginTop: '2px',
  marginBottom: '2px',
  '& .MuiFormControlLabel-label': {
    width: '100%',
    fontSize: 15,
  },
  '&.select-all .MuiFormControlLabel-label': {
    fontWeight: 'bold',
    userSelect: 'none',
  },
  '& .MuiCheckbox-root, & .MuiRadio-root': {
    marginTop: -8,
  },
});

export default Filter;
