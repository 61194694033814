import { Box, Skeleton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import HandleOperation from 'components/utils/HandleOperation';
import { useMemo } from 'react';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import useRecentActivityQuery from '../queries/useRecentActivityQuery';
import FormatPartnersActivity from './FormatPartnersActivity';
import FormatPopularSearchKeywords from './FormatPopularSearchKeywords';
import FormatProjectsActivity from './FormatProjectsActivity';
import FormatUsersActivity from './FormatUsersActivity';
import RenderList, { RenderListProps } from './RenderList';
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon';
import FormatCommentsActivity from './FormatCommentsActivity';
import FileLineIcon from 'remixicon-react/FileLineIcon';
import FormatDocumentsActivity from './FormatDocumentsActivity';

function RecentActivity() {
  const theme = useTheme();
  const { recentActivity, isLoading } = useRecentActivityQuery();
  const listData: RenderListProps['list'] = useMemo(
    () => [
      {
        icon: <GroupLineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: <FormatUsersActivity users={recentActivity?.users || []} />,
        hide: !recentActivity?.users || recentActivity.users.length === 0,
      },
      {
        icon: <BuildingLineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: <FormatProjectsActivity projects={recentActivity?.projects || []} />,
        hide: !recentActivity?.projects || recentActivity.projects.length === 0,
      },
      {
        icon: <HandCoinLineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: <FormatPartnersActivity partners={recentActivity?.partners || []} />,
        hide: !recentActivity?.partners || recentActivity.partners.length === 0,
      },
      {
        icon: <FileLineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: <FormatDocumentsActivity documents={recentActivity?.documents || []} />,
        hide: !recentActivity?.documents || recentActivity.documents.length === 0,
      },
      {
        icon: <Chat3LineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: <FormatCommentsActivity comments={recentActivity?.comments || []} />,
        hide: !recentActivity?.comments || recentActivity.comments.length === 0,
      },
      {
        icon: <SearchLineIcon size={22} color={theme.palette.custom.yellowishBrown} />,
        message: (
          <FormatPopularSearchKeywords searchKeywords={recentActivity?.searchKeywords || []} />
        ),
        hide: !recentActivity?.searchKeywords || recentActivity.searchKeywords.length === 0,
      },
    ],
    [recentActivity],
  );

  return (
    <Box pb={1}>
      <TitleContainer>
        <Title as="h3">Recent Highlights</Title>
      </TitleContainer>

      <HandleOperation
        isLoading={isLoading}
        data={listData}
        skeltonCount={8}
        skelton={<Skeleton variant="text" width="80%" height={20} sx={{ my: 1 }} />}
      >
        <RenderList list={listData} />
      </HandleOperation>
    </Box>
  );
}

const TitleContainer = styled(Box, { label: 'TitleContainer' })({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12,
});

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  color: theme.palette.custom.yellowishBrown,
  fontSize: 16,
  margin: 0,
  marginRight: 2,
}));

export default RecentActivity;
