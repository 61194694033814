import { styled, useTheme } from '@mui/material/styles';
import { ArticleVariantType } from './ArticleBox';
import { Box, Button as MuiButton, Divider, CircularProgress } from '@mui/material';
import { Dispatch, useMemo } from 'react';
import { RecommendedArticleStatusType } from 'api/KnowledgeAPI';
import { ProjectRecommendationsType } from 'pages/projects/hooks/useProjectsQuery';
import { ArticleType } from '../hooks/useArticlesQuery';
import { useProjectRecommendedArticleStatusState } from 'pages/project/hooks/useProjectRecommendedArticleState';
import { UseArticleReducerActions } from '../hooks/useArticle';

type ArticleHeaderProps = {
  article: ArticleType;
  project: ProjectRecommendationsType;
  variant: ArticleVariantType;
  dispatch: Dispatch<UseArticleReducerActions>;
};

function ArticleHeader({ article, project, variant, dispatch }: ArticleHeaderProps) {
  const { loading, projectRecommendedArticleStatusTypes, updatingStatus, updatePostInProject } =
    useProjectRecommendedArticleStatusState({
      project,
    });
  const theme = useTheme();
  const activeStatus = useMemo(
    () =>
      project.recommendations.articleIdToStatusObjMap?.[article.id]
        ? project.recommendations.articleIdToStatusObjMap[article.id].status
        : RecommendedArticleStatusType.ACTIVE,
    [project?.recommendations?.articleIdToStatusObjMap],
  );

  return (
    <>
      <$Container className={variant}>
        {projectRecommendedArticleStatusTypes.map((item) => (
          <Button
            key={item.type}
            variant="outlined"
            size="small"
            sx={
              activeStatus === item.status
                ? {
                    backgroundColor: item.backgroundColor,
                    borderColor: item.backgroundColor,
                    color: theme.palette.custom.dark,
                    '&:hover': {
                      backgroundColor: item.backgroundColor,
                    },
                  }
                : {}
            }
            onClick={() => {
              if (activeStatus !== item.status) {
                if (item.status === RecommendedArticleStatusType.NOT_RELEVANT) {
                  dispatch({
                    type: 'TOGGLE_NOT_RELEVANT_ARTICLE_MODAL',
                    payload: true,
                    key: 'openNotRelevantArticleModal',
                  });
                } else {
                  updatePostInProject({ status: item.status }, article.id);
                }
              }
            }}
          >
            {loading && updatingStatus === item.status ? (
              <CircularProgress size={22} />
            ) : (
              <>
                <>
                  {activeStatus === item.status ? item.fillIcon : item.icon}
                  {(item.fillIcon || item.icon) && <>&nbsp;&nbsp;</>}
                </>
                {item.type}
              </>
            )}
          </Button>
        ))}
      </$Container>
      <$Divider sx={{ mx: 2 }} />
    </>
  );
}

const $Container = styled(Box, { label: 'Container' })({
  padding: '8px 16px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '80px',
  '&.detailed': {
    padding: '8px 24px',
  },
});

const Button = styled(MuiButton, { label: 'Button' })(({ theme }) => ({
  border: `1px solid ${theme.palette.custom.platinum}`,
  color: theme.palette.custom.mistBlue,
  '&:hover': {
    border: `1px solid ${theme.palette.custom.platinum}`,
  },
}));

const $Divider = styled(Divider, { label: 'Divider' })(({ theme }) => ({
  borderColor: theme.palette.custom.blueChalk,
}));

export default ArticleHeader;
