import { Box, Typography } from '@mui/material';
import { SEARCH } from 'constants/routes';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

type FormatPopularSearchKeywordsProps = {
  searchKeywords: string[];
};

function FormatPopularSearchKeywords({ searchKeywords }: FormatPopularSearchKeywordsProps) {
  const keywords = searchKeywords
    .slice(0, 7)
    .map((keyword) => keyword.substring(keyword.indexOf('-') + 1));

  return (
    <Box>
      <Typography fontSize="inherit" mb={1} display="inline" fontWeight={700}>
        Popular searches:{' '}
      </Typography>
      <br />
      {keywords.map((keyword, index) => (
        <Fragment key={keyword}>
          <Link to={`${SEARCH}?q=${keyword}`}>
            <Typography
              component="strong"
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.custom.link,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              })}
            >
              {keyword}
            </Typography>
          </Link>
          {keywords.length - 1 !== index && ', '}
        </Fragment>
      ))}
    </Box>
  );
}

export default FormatPopularSearchKeywords;
