import { Button, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Filter2LineIcon from 'remixicon-react/Filter2LineIcon';
import useUserData from 'hooks/useUserData';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import BookletLineIcon from 'remixicon-react/BookletLineIcon';

type NoFeedProps = {
  hasActiveFilters?: boolean;
  tab: string;
  clearFilters: () => void;
};

function NoFeed({ hasActiveFilters, tab, clearFilters }: NoFeedProps) {
  const theme = useTheme();
  useUserData();

  return (
    <NoDataContainer>
      <>
        {hasActiveFilters ? (
          <>
            <Filter2LineIcon size={120} color={theme.palette.custom.steel} />
            <NoDataTitle mt={1}>Looks like your search is too specific.</NoDataTitle>
            <NoDataDescription my={1}>
              Try to expand your filters to see more content.
            </NoDataDescription>
            <Button
              variant="outlined"
              sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mt: 1 })}
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          </>
        ) : tab === 'posts' ? (
          <>
            <FileListLineIcon size={120} color={theme.palette.custom.steel} />
            <NoDataDescription mb={1}>No posts to display.</NoDataDescription>
          </>
        ) : (
          <>
            <BookletLineIcon size={120} color={theme.palette.custom.steel} />
            <NoDataDescription mb={1}>No collections to display.</NoDataDescription>
          </>
        )}
      </>
    </NoDataContainer>
  );
}

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '80px 24px 0',
});

const NoDataTitle = styled(Box, { label: 'NoDataTitle' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
}));

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default NoFeed;
