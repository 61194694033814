import { Box, Chip as MuiChip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, Dispatch } from 'react';
import Filter3LineIcon from 'remixicon-react/Filter3LineIcon';
import MoreFiltersModal from './MoreFiltersModal';
import { FeedFilter } from 'api/KnowledgeAPI';

type AllPostsMoreFiltersProps = {
  selectedFilters: FeedFilter[];
  selectedMoreFiltersCount: number;
  applyMoreFilters: (moreFilters: FeedFilter[]) => void;
};

function AllPostsMoreFilters({
  selectedFilters,
  selectedMoreFiltersCount,
  applyMoreFilters,
}: AllPostsMoreFiltersProps) {
  const [openMoreFiltersModal, setOpenMoreFiltersModal] = useState(false);

  return (
    <AllPostsMoreFiltersContainer>
      <Chip
        label={
          <Box display="flex" alignItems="center">
            <Filter3LineIcon size={20} />
            &nbsp; All Filters {selectedMoreFiltersCount > 0 && `(${selectedMoreFiltersCount})`}
          </Box>
        }
        variant="outlined"
        size="small"
        onClick={() => setOpenMoreFiltersModal(true)}
        {...(selectedMoreFiltersCount > 0 && {
          sx: (theme) => ({
            backgroundColor: theme.palette.custom.earlyDawn,
            borderColor: theme.palette.custom.paleGold,
            color: theme.palette.custom.brown,
          }),
        })}
      />
      {openMoreFiltersModal && (
        <MoreFiltersModal
          open={openMoreFiltersModal}
          setOpen={setOpenMoreFiltersModal}
          selectedFilters={selectedFilters}
          applyMoreFilters={applyMoreFilters}
        />
      )}
    </AllPostsMoreFiltersContainer>
  );
}

const Chip = styled(MuiChip, { label: 'Chip' })(({ theme }) => ({
  cursor: 'pointer',
  padding: '16px 8px',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.custom.white,
}));

const AllPostsMoreFiltersContainer = styled(Box, { label: 'AllPostsMoreFiltersContainer' })(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export default AllPostsMoreFilters;
