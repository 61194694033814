import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '../config/apiClient';
import useAuthStore from '../../../store/useAuthStore';

class API {
  private config: AxiosRequestConfig | undefined = undefined;

  constructor(config?: AxiosRequestConfig) {
    this.config = config;
  }

  protected getAuthCredentials() {
    return { headers: { Authorization: `Bearer ${this.getToken()}` } };
  }

  public get<T = any>(
    url: string,
    params: ((config: AxiosRequestConfig) => AxiosRequestConfig) | AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T, Error>> {
    return apiClient(this.config).get(`${url}`, {
      ...this.getAuthCredentials(),
      ...(typeof params === 'function' ? params?.(this.getAuthCredentials()) : params),
    });
  }

  public post<T = any>(url: string, data?: unknown, params = {}): Promise<AxiosResponse<T, Error>> {
    return apiClient(this.config).post(`${url}`, data, { ...this.getAuthCredentials(), ...params });
  }

  public put<T = any>(url: string, data?: unknown, params = {}): Promise<AxiosResponse<T, Error>> {
    return apiClient(this.config).put(`${url}`, data, { ...this.getAuthCredentials(), ...params });
  }

  public delete<T = any>(url: string, params = {}): Promise<AxiosResponse<T, Error>> {
    return apiClient(this.config).delete(`${url}`, { ...this.getAuthCredentials(), ...params });
  }

  protected getToken() {
    return useAuthStore.getState().accessToken;
  }
}

export default API;
