import { FormControl, FormHelperText, IconButton, InputBase } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { preventEnterPressed } from 'utility/helpers';
import { styled, useTheme } from '@mui/material/styles';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';

type PostTitleProps = {
  selectedTemplateId: string | undefined;
  showModal: boolean;
  setPostTitle: Dispatch<SetStateAction<string>>;
};

function PostTitle({ selectedTemplateId, showModal, setPostTitle }: PostTitleProps) {
  const theme = useTheme();
  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<PostFormData>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(-1, -1);
      inputRef.current.focus();
    }
  }, [inputRef, selectedTemplateId, showModal]);

  return (
    <Controller
      control={control}
      render={({ field, fieldState }) => (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TitleInput
            {...field}
            inputRef={inputRef}
            autoFocus
            multiline
            maxRows={10}
            inputProps={{ minLength: 3 }}
            error={!!fieldState.error && (fieldState.isDirty || fieldState.isTouched)}
            onKeyDown={preventEnterPressed}
            fullWidth
            placeholder="Add a title"
            value={!!field.value.trim() ? field.value : ''}
            disabled={isSubmitting}
            onBlur={() => {
              field.onBlur();
              setValue('title', field.value.trim(), {
                shouldDirty: true,
                shouldValidate: false,
              });

              setPostTitle(field.value.trim());
            }}
            endAdornment={
              !!fieldState.error &&
              (fieldState.isDirty || fieldState.isTouched) && (
                <IconButton>
                  <ErrorWarningLineIcon color={theme.palette.custom.redWine} />
                </IconButton>
              )
            }
            sx={
              !!fieldState.error && (fieldState.isDirty || fieldState.isTouched)
                ? (theme) => ({
                    border: `1px solid ${theme.palette.custom.redWine}`,
                    paddingLeft: '8px !important',
                    borderRadius: '5px',
                  })
                : {}
            }
          />
          {!!fieldState.error && (fieldState.isDirty || fieldState.isTouched) && (
            <FormHelperText sx={{ ml: 0 }} error>
              {fieldState.error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      name="title"
    />
  );
}

const TitleInput = styled(InputBase, { label: 'TitleInput' })({
  fontSize: 20,
  fontWeight: 700,
});

export default PostTitle;
