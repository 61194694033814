import { Box, IconButton as MuiIconButton, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ThumbUpFillIcon from 'remixicon-react/ThumbUpFillIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import {
  InsightRfiType,
  PmRfiType,
  PmSpecType,
  PmSubmittalType,
  ProjectType,
} from '../hooks/useProjectsQuery';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import FlagFillIcon from 'remixicon-react/FlagFillIcon';
import { PROJECT } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import { highlightSearchkeyWord } from 'utility/helpers';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import RenderFilteredInsightStatus from './RenderFilteredInsightStatus';
import {
  FilteredRFI,
  MeetingMinutes,
} from 'pages/buildwiseAdmin/AIDraftsWorkbench/hooks/useFilteredInsightsQuery';

type RenderInsightContentProps = {
  content: {
    title: string;
    description: string;
  };
  insight:
    | InsightRfiType
    | PmRfiType
    | PmSubmittalType
    | PmSpecType
    | FilteredRFI
    | MeetingMinutes
    | null;
  insightType: 'RFI' | 'Submittal' | 'Spec' | 'FilteredRFI' | 'Meeting Minutes';
  isLikedByMe?: boolean;
  project?: ProjectType;
  handleInsightOpen: Dispatch<SetStateAction<boolean>>;
  likeHandler?: () => void;
};

function RenderInsightContent({
  content,
  insight,
  insightType,
  isLikedByMe,
  project,
  handleInsightOpen,
  likeHandler,
}: RenderInsightContentProps) {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const searchKeyword = useMemo(
    () => decodeURIComponent(searchParams?.get('q') || ''),
    [searchParams],
  );
  const { isNovaAdmin } = useUserData();

  const RenderedTitle = useMemo(() => {
    return (
      <Typography
        variant="h5"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          fontWeight: 700,
          color: '#504D64',
          lineHeight: '29px',
          fontSize: '1.2em',
          '& .highlight': {
            backgroundColor: 'yellow !important',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: highlightSearchkeyWord(content.title, searchKeyword),
        }}
      />
    );
  }, [content.title]);

  const RenderedDescription = useMemo(() => {
    if (!content.description) return null;
    let _text = content.description;
    if (searchKeyword) {
      _text = highlightSearchkeyWord(_text, searchKeyword);
    }
    return <InsightQuestionBody className="ellipsis" dangerouslySetInnerHTML={{ __html: _text }} />;
  }, [content.description]);

  const getProjectUrlInsightHash = () => {
    if (insightType === 'FilteredRFI' || insightType === 'RFI') {
      return 'rfis';
    }

    if (insightType === 'Submittal') {
      return 'submittals';
    }

    return 'specs';
  };

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Box onClick={() => handleInsightOpen(true)}>
        {RenderedTitle}
        {/* {project && (
          <Typography>
            {project.name} <Dot />
            {project.city}
            {project.city && (project.state_code || project.country_code) ? ',' : ''}{' '}
            {project.state_code}
            {project.state_code && project.country_code ? ',' : ''} {project.country_code}
          </Typography>
        )} */}
        {RenderedDescription}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {insightType === 'FilteredRFI' || insightType === 'Meeting Minutes' ? (
            <Box display="flex" alignItems="center" sx={{ cursor: 'text' }}>
              <BuildingLineIcon size={20} style={{ marginRight: 4 }} />
              <Typography>
                {(insight as FilteredRFI | MeetingMinutes)?.procore_project_name || (
                  <>&mdash;&mdash;</>
                )}
              </Typography>
            </Box>
          ) : (
            (project?.id || (insight as InsightRfiType)?.projectId) && (
              <Link
                to={`${PROJECT.replace(
                  ':projectId',
                  project?.id || (insight as InsightRfiType | PmRfiType)?.projectId || '',
                )}${searchKeyword ? `?q=${searchKeyword}` : ''}#${getProjectUrlInsightHash()}`}
                state={searchKeyword && { ...state, project }}
              >
                <BuildingLineIcon size={20} /> &nbsp;
                {project?.name || (insight as InsightRfiType)?.projectName}
              </Link>
            )
          )}
        </Box>
        {(insightType === 'FilteredRFI' || insightType === 'Meeting Minutes') && (
          <RenderFilteredInsightStatus
            status={(insight as FilteredRFI | MeetingMinutes).businessObjectStatus}
          />
        )}
      </Box>
      {insightType === 'RFI' && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
          <Box>
            {(insight as InsightRfiType | PmRfiType)?.insight_snippet && searchKeyword && (
              <Tooltip
                title={<Typography>Contains potentially important information</Typography>}
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 'fit-content',
                    },
                  },
                }}
              >
                <MuiIconButton>
                  <FlagFillIcon color={theme.palette.custom.lavaRed} />
                </MuiIconButton>
              </Tooltip>
            )}
          </Box>
          {insightType === 'RFI' && !searchKeyword && (
            <LikeIconButton onClick={likeHandler} disabled={!!isNovaAdmin}>
              {isLikedByMe ? <ThumbUpFillIcon size={18} /> : <ThumbUpLineIcon size={18} />}
              <Typography ml={0.5}>
                {
                  ((insight as InsightRfiType | PmRfiType)?.engagementDetails?.likedUsers || [])
                    .length
                }
              </Typography>
            </LikeIconButton>
          )}
        </Box>
      )}
    </Box>
  );
}

const InsightQuestionBody = styled(Box, { label: 'InsightQuestionBody' })({
  margin: '16px 0',
  lineHeight: '21px',
  whiteSpace: 'pre-wrap',
  '&.ellipsis': {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: '250px',
  },
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
});

const LikeIconButton = styled(MuiIconButton, { label: 'LikeIconButton' })(({ theme }) => ({
  color: theme.palette.custom.mediumBlack,
  borderRadius: 20,
  padding: '2px 12px',
}));

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  fontSize: 14,
  lineHeight: '15.6px',
  color: theme.palette.custom.link,
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export default RenderInsightContent;
