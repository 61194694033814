import { HomeAPI } from '@buildwise/libs/home';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';
import { AxiosResponse } from 'axios';
import useUserData from 'hooks/useUserData';
import { useQuery } from 'react-query';

function useArticleFieldsCommonQuery({
  endpoint,
  shouldFetch,
}: {
  endpoint: string;
  shouldFetch: boolean;
}) {
  const { companyId } = useUserData();

  const { data, ...rest } = useQuery<AxiosResponse<Record<string, any>[]>, Error>(
    [
      endpoint
        .replace(JAVA_SERVICE_ENDPOINTS.API_VERSION_PREFIX, '')
        .replace(':companyId', companyId),
    ],
    () =>
      HomeAPI.get(
        endpoint
          .replace(JAVA_SERVICE_ENDPOINTS.API_VERSION_PREFIX, '')
          .replace(':companyId', companyId),
      ),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  return {
    data: data?.data || [],
    ...rest,
  };
}

export default useArticleFieldsCommonQuery;
