import { DialogTitle, DialogTitleProps, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export interface CustomDialogTitleProps extends DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  closeIcon?: ReactNode;
  onClose?: () => void;
}

const CustomDialogTitle = (props: CustomDialogTitleProps) => {
  const { children, onClose, closeIcon, ...other } = props;

  return (
    <DialogTitle {...other} position="relative">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {closeIcon || <CloseLineIcon size={32} color="black" />}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
