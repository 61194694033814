import { useEffect, useMemo, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationFillIcon from 'remixicon-react/NotificationFillIcon';
import NotificationLineIcon from 'remixicon-react/NotificationLineIcon';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NOTIFICATION } from '../../constants/routes';
import { NotificationType, useNotificationsQuery } from '../../hooks/useNotificationsQuery';

type NotificationBoxProps = {
  shouldFetchNotifications: boolean;
};

function NotificationBox({ shouldFetchNotifications }: NotificationBoxProps) {
  const { notifications } = useNotificationsQuery({ shouldFetch: shouldFetchNotifications });
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const notificationCountRef = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const unReadNotifications: NotificationType[] = useMemo(
    () => notifications.filter((notification) => !notification.read),
    [notifications],
  );

  useEffect(() => {
    if (unReadNotifications.length && notificationCountRef.current !== unReadNotifications.length) {
      if (containerRef.current) {
        containerRef.current.className = `${containerRef.current.className} ring-bell`;

        setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.className = containerRef.current.className.replace(
              'ring-bell',
              '',
            );
          }
        }, 4000);
      }

      notificationCountRef.current = unReadNotifications.length;
    }
  }, [unReadNotifications]);

  const handleOpenNotifications = () => {
    navigate(NOTIFICATION);
  };

  return (
    <Badge
      overlap="circular"
      badgeContent={unReadNotifications.length}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={(theme) => ({
        '& .MuiBadge-badge': {
          top: 8,
          right: 12,
          backgroundColor: theme.palette.custom.pinkLemonade,
          color: theme.palette.custom.white,
        },
      })}
    >
      <IconButton
        title="Notifications"
        ref={anchorEl}
        onClick={handleOpenNotifications}
        color="primary"
        sx={{
          backgroundColor:
            window.location.pathname === NOTIFICATION ? 'rgba(80, 77, 100, 0.12)' : 'unset',
        }}
      >
        <$NotificationsConatiner ref={containerRef}>
          {window.location.pathname === NOTIFICATION ? (
            <NotificationFillIcon color="#091E42" />
          ) : (
            <NotificationLineIcon color="#091E42" />
          )}
        </$NotificationsConatiner>
      </IconButton>
    </Badge>
  );
}

const $NotificationsConatiner = styled(Box, { label: 'NotificationsConatiner' })({
  width: 25,
  height: 25,
  '&.ring-bell': {
    animation: 'ring-bell linear 1s',
    animationIterationCount: 4,
    transformOrigin: 'top center',
    '@keyframes ring-bell': {
      '0%': {
        transform: 'rotate(30deg)',
      },
      '50%': {
        transform: 'rotate(-30deg)',
      },
      '100%': {
        transform: 'rotate(30deg)',
      },
    },
  },
});

export default NotificationBox;
