import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
  Skeleton,
  Radio,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { catchErrorMessage, formatDate } from 'utility/helpers';
import { toast } from 'react-toastify';
import useUserData from 'hooks/useUserData';
import { PROJECT, USER_PROFILE, VIEW_ARTICLE } from 'constants/routes';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import HandleOperation from 'components/utils/HandleOperation';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import Dot from 'components/common/dot/Dot';
import { debounce } from 'lodash';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import Search from 'pages/knowledge/components/SearchComponent';
import useInsightsTypeDraftsQuery from './hooks/useInsightsTypeDraftsQuery';
import ProjectsAPI from 'api/ProjectsAPI';
import useFilteredInsightsQuery, {
  BusinessObjectStatus,
  FilteredRFI,
  MeetingMinutes,
} from './hooks/useFilteredInsightsQuery';
import DraftLineIcon from 'remixicon-react/DraftLineIcon';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';

type LinkInsightModalProps = {
  filteredRFI: FilteredRFI | MeetingMinutes;
  objectType: 'RFI' | 'MEETING_MINUTES';
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const LinkInsightModal: React.FC<LinkInsightModalProps> = ({
  filteredRFI,
  objectType,
  open,
  setOpen,
}) => {
  const theme = useTheme();
  const { companyId } = useUserData();
  const { state } = useLocation();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDraftId, setSelectedDraftId] = useState<string>();
  const { drafts, isLoading } = useInsightsTypeDraftsQuery();
  const { updateFilteredInsightsCache } = useFilteredInsightsQuery({
    objectType,
    shouldFetch: false,
  });

  const linkInsightToDraft = async () => {
    try {
      setLoading(true);
      await ProjectsAPI.updateInsight(companyId, {
        businessObjects: [
          {
            objectId: filteredRFI.id,
            objectType,
            referenceArticleId: selectedDraftId,
            reviewStatus: {
              status: BusinessObjectStatus.AI_DRAFT_CREATED,
            },
          },
        ],
      });
      toast.success('Status updated');
      updateFilteredInsightsCache({
        action: 'update',
        insight: {
          ...filteredRFI,
          businessObjectStatus: BusinessObjectStatus.AI_DRAFT_CREATED,
          updated_at: new Date().toISOString(),
        },
      });
      closeModal();
    } catch (err) {
      toast.error(catchErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSelectedDraftId('');
    setOpen(false);
  };

  const updateSearch = (value: string) => {
    setSearchKeyword(value.trim());
  };
  const debounceFn = useCallback(debounce(updateSearch, 300), []);

  const insightsData = useMemo(() => {
    if (searchKeyword) {
      return drafts.filter(
        (draft) =>
          draft.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          draft.createdBy.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          draft.userNameToDisplay.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }

    return drafts;
  }, [drafts, searchKeyword]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      TransitionComponent={GrowDialogTransition}
      sx={{ '& .MuiPaper-root': { height: '100%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Link AI Draft</Title>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description" sx={{ p: 0 }}>
        <StickySearchContainer>
          <Search
            value={searchKeyword}
            placeholder="Search for AI Drafts..."
            setValue={debounceFn}
            filled
            triggerOnEnter
          />
        </StickySearchContainer>

        <HandleOperation
          isLoading={isLoading}
          data={insightsData}
          noDataComponent={
            <NoDataContainer>
              {searchKeyword && insightsData.length === 0 ? (
                <>
                  <SearchLineIcon size={120} color={theme.palette.custom.steel} />
                  <Typography component="p" mt={1.5}>
                    No drafts found with <b>"{searchKeyword}"</b>
                  </Typography>
                </>
              ) : (
                <>
                  <DraftLineIcon size={120} color={theme.palette.custom.steel} />{' '}
                  <Typography component="p" mt={1.5}>
                    No drafts found
                  </Typography>
                </>
              )}
            </NoDataContainer>
          }
          skelton={
            <DraftHoverContainer>
              <DraftContainer sx={{ px: 1.5, py: 2 }}>
                <Skeleton animation="wave" variant="circular" width={25} height={25} />
                <Box ml={2}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={200}
                    height={25}
                    sx={{ mb: 0.5 }}
                  />
                  <Box display="flex" alignItems="center">
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={15}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={15}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton animation="wave" variant="text" width={100} height={15} />
                  </Box>
                  <Box display="flex" alignItems="center" mt={0.5}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={15}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={15}
                      sx={{ mr: 1 }}
                    />
                  </Box>
                </Box>
              </DraftContainer>
              <Divider />
            </DraftHoverContainer>
          }
        >
          {insightsData.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <DraftHoverContainer onClick={() => setSelectedDraftId(item.id)}>
                  <DraftContainer>
                    <Radio checked={item.id === selectedDraftId} disabled={loading} />
                    <Box ml={0.5}>
                      <DraftTitle>
                        <Link to={VIEW_ARTICLE.replace(':articleId', item.id)} target="_blank">
                          {item.title}
                        </Link>
                      </DraftTitle>
                      <DraftInfo display="flex" alignItems="center">
                        <Link
                          to={USER_PROFILE.replace(':userName', item.createdBy)}
                          target="_blank"
                          title="Created By / Assigned To"
                        >
                          {item.userNameToDisplay}
                        </Link>
                        <Dot />
                        <Box title="User Title">{item.userNameTitleToDisplay}</Box>
                        <Dot />
                        <Box title="Last Updated">
                          {formatDate(item.lastUpdatedAt || item.publishedAt)}
                        </Box>
                      </DraftInfo>
                      {item.project && (
                        <DraftInfo mt="2px">
                          <Box display="inline-flex" alignItems="center" title="Project">
                            <BuildingLineIcon size={16} style={{ marginRight: 4 }} />
                            <Link
                              to={PROJECT.replace(':projectId', item.project?.id || '')}
                              state={searchKeyword && { ...state, project: item.project }}
                              target="_blank"
                            >
                              {item.project.name}
                            </Link>
                          </Box>
                        </DraftInfo>
                      )}
                    </Box>
                  </DraftContainer>
                  {insightsData.length - 1 !== index && <Divider />}
                </DraftHoverContainer>
              </Fragment>
            );
          })}
        </HandleOperation>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading || !selectedDraftId}
          onClick={linkInsightToDraft}
          variant="contained"
        >
          {loading ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Link'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const StickySearchContainer = styled(Box, { label: 'StickySearchContainer' })(({ theme }) => ({
  position: 'sticky',
  top: '0',
  backgroundColor: theme.palette.custom.white,
  zIndex: 1,
  padding: '16px 24px',
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '40px 24px 0',
});

const DraftHoverContainer = styled(Box, { label: 'DraftHoverContainer' })(({ theme }) => ({
  padding: '0 24px',
  '&:hover': {
    backgroundColor: theme.palette.custom.whiteSmoke,
    transition: '0.5s',
  },
}));

const DraftContainer = styled(Box, { label: 'DraftContainer' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 0',
  cursor: 'pointer',
}));

const DraftTitle = styled(Typography, { label: 'DraftTitle' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.custom.tuna,
  textTransform: 'capitalize',
}));

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

const DraftInfo = styled(Box, { label: 'DraftInfo' })(({ theme }) => ({
  fontWeight: 400,
  fontSize: 13,
  color: theme.palette.custom.mistBlue,
}));

export default LinkInsightModal;
