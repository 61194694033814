import { JavaAPI, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';
import { ConfigType } from 'config/config';

export type ConfigResponseType = { config?: ConfigType; configs?: ConfigType[] };

class ConfigAPI extends JavaAPI {
  public getConfig({
    companyId,
    configName,
    queryParams,
  }: {
    companyId?: string;
    configName?: string;
    queryParams?: {
      configType?: string;
      configCategory?: string;
    };
  }) {
    const params = new URLSearchParams(queryParams);

    return this.get<ConfigResponseType>(
      `${(companyId ? JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', companyId) : JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG).replace(':configName', configName || '')}${queryParams ? `?${params.toString()}` : ''}`,
    );
  }

  public deleteConfigByName({ companyId, configName }: { companyId?: string; configName: string }) {
    return this.delete<ConfigResponseType>(
      `${(companyId ? JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', companyId) : JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG).replace(':configName', configName)}`,
    );
  }

  public deleteConfigById({ id }: { id: string }) {
    return this.delete<ConfigResponseType>(
      `${JAVA_SERVICE_ENDPOINTS.DELETE_CONFIG_BY_ID.replace(':id', id)}`,
    );
  }

  public updateConfig(config: ConfigType) {
    return this.put<ConfigResponseType>(JAVA_SERVICE_ENDPOINTS.UPDATE_CONFIG, config);
  }
}

const configAPI = new ConfigAPI();
export default configAPI;
