import { ReactComponent as BuildWiseLogo } from 'assets/icons/login_logo_large.svg';
import { ReactComponent as BuildWiseTitle } from 'assets/icons/logo_title.svg';
import { useMemo, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Box, useMediaQuery, Drawer, BadgeProps, Typography } from '@mui/material';
import {
  ACCOUNT_SETTINGS,
  BOOKMARKS,
  DRAFTS,
  FILES,
  HOME,
  INSIGHTS,
  PEOPLE,
  COLLECTIONS,
  PROJECTS,
  QUESTIONS_BY_ME,
  QUESTIONS_FOR_ME,
  STAKEHOLDERS,
  REQUESTS,
} from 'constants/routes';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFillIcon } from 'assets/icons/bookmark-fill.svg';
import DashboardLineIcon from 'remixicon-react/DashboardLineIcon';
import ChatSmile3LineIcon from 'remixicon-react/ChatSmile3LineIcon';
import { useSearchParams } from 'react-router-dom';
import DraftLineIcon from 'remixicon-react/DraftLineIcon';
import DashboardFillIcon from 'remixicon-react/DashboardFillIcon';
import ChatSmile3FillIcon from 'remixicon-react/ChatSmile3FillIcon';
import DraftFillIcon from 'remixicon-react/DraftFillIcon';
import QuestionFillIcon from 'remixicon-react/QuestionFillIcon';
import { useAssignedQuestions } from 'pages/audience/hooks/useQuestionsQueries';
import Settings4FillIcon from 'remixicon-react/Settings4FillIcon';
import useUserData from 'hooks/useUserData';
import useBookmarksQuery from 'pages/bookmarks/hooks/useBookmarksQuery';
import useDraftsQuery from 'pages/drafts/hooks/useDraftsQuery';
import QuestionLineIcon from 'remixicon-react/QuestionLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import HandCoinFillIcon from 'remixicon-react/HandCoinFillIcon';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import BuildingFillIcon from 'remixicon-react/BuildingFillIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import GroupFillIcon from 'remixicon-react/GroupFillIcon';
import LightbulbLineIcon from 'remixicon-react/LightbulbLineIcon';
import LightbulbFillIcon from 'remixicon-react/LightbulbFillIcon';
import FeedbackLineIcon from 'remixicon-react/FeedbackLineIcon';
import FoldersLineIcon from 'remixicon-react/FoldersLineIcon';
import FoldersFillIcon from 'remixicon-react/FoldersFillIcon';
import FeedbackModal from 'pages/projects/components/FeedbackModal';
import RenderMenu, { MenuItemType } from './RenderMenu';
import { useBuildWiseModulesQuery } from 'pages/knowledge/hooks/usePersonalizeQueries';
import { BUILDWISE_MODULES } from './constants';
import BookletLineIcon from 'remixicon-react/BookletLineIcon';
import BookletFillIcon from 'remixicon-react/BookletFillIcon';
import Home2LineIcon from 'remixicon-react/Home2LineIcon';
import Home2FillIcon from 'remixicon-react/Home2FillIcon';
import UserReceivedLineIcon from 'remixicon-react/UserReceivedLineIcon';
import UserReceivedFillIcon from 'remixicon-react/UserReceivedFillIcon';
import ProcoreSyncMenuItem from './ProcoreSyncMenuItem';
import { Helmet } from 'react-helmet';
import { useAssignedFormsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';

const MENU: MenuItemType[] = [
  {
    title: 'Home',
    url: HOME,
    icon: <Home2LineIcon />,
    iconActive: <Home2FillIcon />,
  },
  {
    title: 'Collections',
    url: COLLECTIONS,
    matchingUrls: [COLLECTIONS, '/admin/collection'],
    icon: <BookletLineIcon />,
    iconActive: <BookletFillIcon />,
  },
  {
    title: 'People',
    url: PEOPLE,
    matchingUrls: [PEOPLE, '/admin/profile'],
    icon: <GroupLineIcon />,
    iconActive: <GroupFillIcon />,
  },
  {
    title: 'Projects',
    url: PROJECTS,
    matchingUrls: [PROJECTS, '/admin/project'],
    icon: <BuildingLineIcon />,
    iconActive: <BuildingFillIcon />,
  },
  {
    title: BUILDWISE_MODULES.PARTNERS,
    url: STAKEHOLDERS,
    matchingUrls: [STAKEHOLDERS, '/admin/stakeholder'],
    icon: <HandCoinLineIcon />,
    iconActive: <HandCoinFillIcon />,
  },
  {
    title: 'Insights',
    url: INSIGHTS,
    icon: <LightbulbLineIcon />,
    iconActive: <LightbulbFillIcon />,
  },
  {
    title: BUILDWISE_MODULES.PROCEDURES,
    url: FILES,
    icon: <FoldersLineIcon />,
    iconActive: <FoldersFillIcon />,
  },
];

const MY_MENU: MenuItemType[] = [
  {
    title: 'Drafts',
    url: DRAFTS,
    matchingUrls: [DRAFTS, '/admin/draft'],
    icon: <DraftLineIcon />,
    iconActive: <DraftFillIcon />,
  },
  {
    title: 'Requests',
    url: REQUESTS,
    icon: <UserReceivedLineIcon />,
    iconActive: <UserReceivedFillIcon />,
  },
  {
    title: 'Bookmarks',
    url: BOOKMARKS,
    icon: <BookmarkIcon />,
    iconActive: <BookmarkFillIcon />,
  },
  {
    title: 'Questions for Me',
    url: QUESTIONS_FOR_ME,
    icon: <QuestionLineIcon />,
    iconActive: <QuestionFillIcon />,
  },
  {
    title: 'Questions I’ve Asked',
    url: QUESTIONS_BY_ME,
    icon: <ChatSmile3LineIcon />,
    iconActive: <ChatSmile3FillIcon />,
  },
];

const MainDrawer = () => {
  const { buildwiseModules } = useBuildWiseModulesQuery();
  const [searchParams] = useSearchParams();
  const { company, isNovaOrCompanyAdmin, user, companyId } = useUserData();
  const searchKeyword = useMemo(
    () => decodeURIComponent(searchParams?.get('q') || ''),
    [searchParams],
  );
  const [openFeedback, setFeedbackOpen] = useState(false);
  const { questions } = useAssignedQuestions({
    status: 'ASSIGNED',
    shouldFetch: !!company && company?.settings?.askQuestionFlag,
  });
  const { requestedPosts } = useAssignedFormsQuery({
    companyId: companyId || '',
    assignedTo: user?.username || '',
    createdBy: user?.username || '',
    status: 'NEW-INPROGRESS',
    formCategory: FORMS_CONFIG_CATEGORY_TYPES.REQUEST_A_POST_CARD,
  });
  const postsAssignedToCurrentUser = useMemo(
    () => requestedPosts.filter((form) => form?.assignedTo === user?.username),
    [requestedPosts, user?.username],
  );
  const { bookmarks: bookmarkedArticles } = useBookmarksQuery();
  const { drafts } = useDraftsQuery();
  const draftsAssignedToMe = useMemo(
    () => drafts.filter((draft) => draft.createdBy.toLowerCase() === user?.username?.toLowerCase()),
    [user, drafts],
  );
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const menu = useMemo(() => {
    const menu = [...MENU];

    return menu.map((item) => {
      if (item.title === BUILDWISE_MODULES.PARTNERS) {
        return {
          ...item,
          title:
            buildwiseModules.find((module) => module.entryCode === item.title)?.entryDescription ||
            item.title,
          isHidden: !company?.settings?.partnerFlag,
          showSelected: !searchKeyword,
        };
      }

      if (item.title === 'Projects') {
        return {
          ...item,
          isHidden: !company?.settings?.projectFlag,
          showSelected: !searchKeyword,
        };
      }

      if (item.title === 'Insights') {
        return { ...item, isHidden: true, showSelected: !searchKeyword };
      }

      if (item.title === BUILDWISE_MODULES.PROCEDURES) {
        return {
          ...item,
          title:
            buildwiseModules.find((module) => module.entryCode === item.title)?.entryDescription ||
            item.title,
          isHidden: !company?.settings?.companyFilesLLMSearch,
          showSelected: !searchKeyword,
        };
      }

      return { ...item, showSelected: !searchKeyword };
    });
  }, [company?.settings, searchKeyword, buildwiseModules]);

  const myMenu = useMemo(() => {
    const myMenu = [...MY_MENU];

    return myMenu.map((item) => {
      if (item.url === QUESTIONS_FOR_ME || item.url === QUESTIONS_BY_ME) {
        return {
          ...item,
          isHidden: !company?.settings?.askQuestionFlag,
          showSelected: !searchKeyword,
          badgeContent: item.url === QUESTIONS_FOR_ME ? questions.length : 0,
        };
      }

      if (item.url === BOOKMARKS) {
        return {
          ...item,
          badgeContent: bookmarkedArticles?.length,
          badgeColor: 'secondary' as BadgeProps['color'],
        };
      }

      if (item.url === DRAFTS) {
        return {
          ...item,
          badgeContent: draftsAssignedToMe?.length,
          badgeColor: 'secondary' as BadgeProps['color'],
        };
      }

      if (item.url === REQUESTS) {
        return {
          ...item,
          badgeContent: postsAssignedToCurrentUser?.length,
        };
      }

      return { ...item, showSelected: !searchKeyword };
    });
  }, [
    company?.settings?.askQuestionFlag,
    searchKeyword,
    questions.length,
    bookmarkedArticles?.length,
    draftsAssignedToMe?.length,
    postsAssignedToCurrentUser?.length,
  ]);

  const STICKY_MENU: MenuItemType[] = [
    {
      title: 'Feedback to BuildWise',
      titleColor: '#B38705',
      icon: <FeedbackLineIcon color="#B38705" />,
      onClick: () => setFeedbackOpen(true),
    },
    {
      title: 'Admin Console',
      url: ACCOUNT_SETTINGS,
      isHidden: !isNovaOrCompanyAdmin,
      icon: <Settings4FillIcon />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
      {isSm && (
        <Sidebar component="aside">
          <AbsoluteContainer>
            <FixedContainer>
              <StickyContainer>
                <Drawer
                  variant="persistent"
                  open
                  sx={(theme) => ({
                    '& .MuiPaper-root': {
                      position: 'initial',
                      height: `calc(100vh - 100px)`,
                      width: '100px',
                      transition: '0.4s',
                      [theme.breakpoints.up('lg')]: {
                        width: theme.drawerWidth,
                      },
                    },
                  })}
                >
                  <RenderMenu menu={menu} />
                  <$LineBreak sx={{ margin: '8px 24px' }} />
                  <RenderMenu menu={myMenu} />
                  {/* <RenderStickyMenu menu={STICKY_MENU} /> */}
                  <$LineBreak sx={{ margin: '8px 24px' }} />
                  <StickyMenuContainer>
                    <RenderMenu menu={STICKY_MENU} />
                    <Box>
                      <StickyMenuItem>
                        <Typography sx={{ color: 'rgb(0,0,0,0.5)', fontSize: 14 }}>
                          Powered by
                        </Typography>
                        <BuildWiseLogo
                          style={{ maxHeight: 25, maxWidth: 25, marginLeft: 4, marginRight: 4 }}
                        />
                        <BuildWiseTitle style={{ height: 25, width: 70 }} />
                      </StickyMenuItem>
                      {isNovaOrCompanyAdmin && company?.pmmetrics && (
                        <StickyMenuItem>
                          <ProcoreSyncMenuItem />
                        </StickyMenuItem>
                      )}
                    </Box>
                  </StickyMenuContainer>
                </Drawer>
              </StickyContainer>
            </FixedContainer>
          </AbsoluteContainer>
        </Sidebar>
      )}

      {openFeedback && (
        <FeedbackModal openFeedback={openFeedback} setFeedbackOpen={setFeedbackOpen} />
      )}
    </>
  );
};

export const Sidebar = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  width: '100px',
  transition: '0.4s',
  flex: 'none',
  [theme.breakpoints.up('lg')]: {
    width: theme.drawerWidth,
    flexShrink: 0,
  },
}));

export const $LineBreak = styled(Box)(({ theme }) => ({
  height: 1,
  backgroundColor: theme.palette.custom.line,
  marginTop: 16,
  marginBottom: 16,
}));

export const AbsoluteContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: -32,
  top: 0,
  width: 'inherit',
  [theme.breakpoints.down('lg')]: {
    left: 0,
  },
}));

export const FixedContainer = styled(Box)({
  position: 'fixed',
});

export const StickyContainer = styled(Box)({
  position: 'sticky',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  width: 'inherit',
});

export const StickyMenuContainer = styled(Box, { label: 'StickyMenuContainer' })(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.custom.background,
}));

export const StickyMenuItem = styled(Box, { label: 'StickyMenuItem' })(({ theme }) => ({
  marginBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export default MainDrawer;
