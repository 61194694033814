import { useQuery } from 'react-query';
import useUserData from 'hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import KnowledgeAPI from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { queryClient } from 'config/queryClient';
import { useMemo } from 'react';

export const useDraftsQuery = ({ shouldFetch = true }: { shouldFetch?: boolean } = {}) => {
  const { companyId } = useUserData();

  const { data: draftsData, ...rest } = useQuery<AxiosResponse<ArticleType[]>, Error>(
    JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_DRAFTS.replace(':companyId', companyId),
    () => KnowledgeAPI.getDraftArticles(companyId),
    {
      staleTime: 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const drafts = useMemo(() => draftsData?.data || [], [draftsData]);

  return {
    drafts,
    ...rest,
  };
};

export const invalidateDraftQueries = (companyId) => {
  queryClient.refetchQueries(
    JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_DRAFTS.replace(':companyId', companyId),
  );
};

export const updateDraftsCache = (
  companyId: string,
  article: ArticleType,
  action: 'add' | 'update' | 'delete',
) => {
  queryClient.setQueriesData<AxiosResponse<ArticleType[]> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_DRAFTS.replace(':companyId', companyId),
    (oldData: AxiosResponse<ArticleType[]> | undefined) => {
      if (oldData) {
        const drafts = [...(oldData?.data || [])];

        if (action === 'add') {
          drafts.unshift(article);
        }

        if (action === 'update') {
          const index = drafts.findIndex((draft) => draft.id === article.id);
          if (index > -1) {
            drafts[index] = { ...oldData[index], ...article };
          } else {
            drafts.unshift(article);
          }
        }

        if (action === 'delete') {
          const index = drafts.findIndex((draft) => draft.id === article.id);
          if (index > -1) {
            drafts.splice(index, 1);
          }
        }

        return {
          ...oldData,
          data: drafts,
        };
      }

      return oldData;
    },
  );
};

export default useDraftsQuery;
