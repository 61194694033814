import { styled } from '@mui/material/styles';
import {
  Chip as MuiChip,
  Box,
  Popover,
  FormControlLabel as MuiFormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { FilterOptionType, FilterType } from 'components/common/filter/Filter';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

type RenderChipFilterCategoryProps = {
  filter: FilterType;
  filterIndex: number;
  onFiltersChanged?: (filters: FilterType[]) => void;
  setFilters: Dispatch<SetStateAction<FilterType[]>>;
};

function RenderChipFilterCategory({
  filter,
  filterIndex,
  onFiltersChanged,
  setFilters,
}: RenderChipFilterCategoryProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getOptions = (options: FilterOptionType[]) => {
    const blankOptionIndex = options.findIndex((option) => option.value === '(blank)');
    if (blankOptionIndex > -1) {
      const updatedOptions = [...options];
      updatedOptions.splice(blankOptionIndex, 1);
      return [...updatedOptions, options[blankOptionIndex]];
    }
    return options;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    clearFilters(filter.category);
    setAnchorEl(null);
  };

  const clearFilters = (category?: string) => {
    setFilters((prevState) => {
      const updatedFilters =
        prevState?.map((filter) => ({
          ...filter,
          selectedOptions:
            !(category && typeof category === 'string') || filter.category === category
              ? []
              : filter.selectedOptions,
        })) || [];
      onFiltersChanged?.(updatedFilters);

      return updatedFilters;
    });
  };

  const handleCheckboxFilterChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilters((prevState) => {
      const updatedState = [...prevState];

      if (event.target.value === 'Select All') {
        if (checked) {
          updatedState[filterIndex].selectedOptions = updatedState[filterIndex].allOptions;
        } else {
          updatedState[filterIndex].selectedOptions = [];
        }

        return updatedState;
      }

      if (checked) {
        updatedState[filterIndex].selectedOptions = [
          ...(updatedState[filterIndex]?.selectedOptions || []),
          event.target.value,
        ];
      } else {
        updatedState[filterIndex].selectedOptions = updatedState[
          filterIndex
        ]?.selectedOptions?.filter((option) => option !== event.target.value);
      }

      onFiltersChanged?.(updatedState);

      return updatedState;
    });
  };

  return (
    <>
      <Chip
        key={filter.displayName}
        label={
          <Box display="flex" alignItems="center">
            {filter.displayName}{' '}
            {filter.selectedOptions &&
              filter.selectedOptions?.length > 0 &&
              `(${filter.selectedOptions?.length})`}{' '}
            &nbsp;
            <ArrowDownSLineIcon size={20} />
          </Box>
        }
        variant="outlined"
        size="small"
        aria-describedby={id}
        onClick={handleClick}
        {...(filter.selectedOptions &&
          filter.selectedOptions?.length > 0 && {
            sx: (theme) => ({
              backgroundColor: theme.palette.custom.earlyDawn,
              borderColor: theme.palette.custom.paleGold,
              color: theme.palette.custom.brown,
            }),
          })}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={() => ({
          '& .MuiPaper-root': {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0px 0px 24px 0px #0000001F',
          },
        })}
      >
        <Box width="fit-content" display="flex" flexDirection="column" p={2} maxWidth={350}>
          {getOptions(filter.options).map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  onChange={handleCheckboxFilterChange}
                  checked={!!filter?.selectedOptions?.includes(option.value)}
                  value={option.value}
                />
              }
              label={
                <Box component="span" width="100%" display="flex" alignItems="center">
                  {option.label}&nbsp;
                  {option.badgeValue ? `(${option.badgeValue})` : ''}
                </Box>
              }
              value={option.value}
              disabled={option.disabled}
            />
          ))}

          {filter.options.length > 1 && <ClearButton onClick={handleClear}>Clear All</ClearButton>}
        </Box>
      </Popover>
    </>
  );
}

const Chip = styled(MuiChip, { label: 'Chip' })(({ theme }) => ({
  cursor: 'pointer',
  padding: '16px 8px',
  marginRight: 8,
  textTransform: 'capitalize',
  backgroundColor: theme.palette.custom.white,
}));

const FormControlLabel = styled(MuiFormControlLabel, { label: 'FormControlLabel' })({
  alignItems: 'flex-start',
  marginTop: '2px',
  marginBottom: '2px',
  '& .MuiFormControlLabel-label': {
    width: '100%',
    fontSize: 15,
  },
  '&.select-all .MuiFormControlLabel-label': {
    fontWeight: 'bold',
    userSelect: 'none',
  },
  '& .MuiCheckbox-root, & .MuiRadio-root': {
    marginTop: -8,
  },
});

const ClearButton = styled(Typography, { label: 'ClearButton' })(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 16,
  color: theme.palette.custom.link,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default RenderChipFilterCategory;
