import { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { catchErrorMessage } from 'utility/helpers';
import { toast } from 'react-toastify';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ProjectsAPI from 'api/ProjectsAPI';
import useUserData from 'hooks/useUserData';
import useFilteredInsightsQuery, {
  BusinessObjectStatus,
  FilteredRFI,
  MeetingMinutes,
} from './hooks/useFilteredInsightsQuery';

const NOT_PROJECT_RELATED_ARTICLE_FEEDBACK_LIST = [
  {
    name: 'Project specific',
  },
  {
    name: 'Not enough context',
  },
  {
    name: 'Other',
  },
];

type NotRelevantArticleForm = {
  type: string;
  description: string;
};

type RejectInsightModalProps = {
  filteredRFI: FilteredRFI | MeetingMinutes;
  objectType: 'RFI' | 'MEETING_MINUTES';
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const RejectInsightModal = ({
  filteredRFI,
  objectType,
  open,
  setOpen,
}: RejectInsightModalProps) => {
  const { companyId } = useUserData();
  const { updateFilteredInsightsCache } = useFilteredInsightsQuery({
    objectType,
    shouldFetch: false,
  });
  const {
    formState: { isDirty, isSubmitting, isValid },
    control,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
  } = useForm<NotRelevantArticleForm>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        type: Yup.string().required(),
        description: Yup.string().when('type', {
          is: (type: string) => type === 'Other',
          then: (schema) => schema.required('Please provide feedback'),
        }),
      }),
    ),
    defaultValues: {
      type: '',
      description: '',
    },
  });
  const type = watch('type');

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (values: NotRelevantArticleForm) => {
    try {
      await ProjectsAPI.updateInsight(companyId, {
        businessObjects: [
          {
            objectId: filteredRFI.id,
            objectType,
            reviewStatus: {
              status: BusinessObjectStatus.REJECTED,
              notes: values.type === 'Other' ? `Other - ${values.description.trim()}` : values.type,
            },
          },
        ],
      });
      toast.success('Status updated');
      updateFilteredInsightsCache({
        action: 'update',
        insight: {
          ...filteredRFI,
          businessObjectStatus: BusinessObjectStatus.REJECTED,
          updated_at: new Date().toISOString(),
          businessObjectNotes: values.description.trim(),
        },
      });
      closeModal();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  return (
    <Dialog
      onClose={closeModal}
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Reject</Title>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ borderBottom: 0 }} id="dialog-description">
        <Box>
          <Note>
            Reject this content and add feedback to improve recommendations provided by BuildWise
            AI.
          </Note>
          <Controller
            control={control}
            render={({ field: radioField }) => (
              <RadioGroup aria-labelledby="not-relevant-article-radios-group" name="type">
                {NOT_PROJECT_RELATED_ARTICLE_FEEDBACK_LIST.map((item) => (
                  <Box key={item.name}>
                    <FormControlLabel
                      {...radioField}
                      control={
                        <Radio
                          value={item.name}
                          size="small"
                          onChange={() => {
                            setValue('type', item.name, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            resetField('description');
                          }}
                        />
                      }
                      label={item.name}
                      checked={type === item.name}
                      disabled={isSubmitting}
                    />
                    <Box sx={{ ml: 3.5 }}>
                      <Collapse in={item.name === type && type === 'Other'} unmountOnExit>
                        <Controller
                          control={control}
                          render={({ field: textField, fieldState }) => (
                            <TextField
                              {...textField}
                              autoFocus
                              fullWidth
                              multiline
                              rows={3}
                              placeholder="Provide feedback to improve recommendations..."
                              variant="outlined"
                              error={!!fieldState.error}
                              helperText={fieldState?.error?.message || ''}
                              sx={{ mt: 1 }}
                            />
                          )}
                          name="description"
                        />
                      </Collapse>
                    </Box>
                  </Box>
                ))}
              </RadioGroup>
            )}
            name="type"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={triggerSubmit}
          variant="contained"
          color="error"
        >
          {isSubmitting ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Reject'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const Note = styled(Typography, { label: 'Note' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
  marginBottom: 8,
}));

export default RejectInsightModal;
