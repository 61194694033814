import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';

const formatDate = (date: string) => {
  if (moment(date).isSame(moment(), 'day')) {
    return 'Today';
  }

  if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  }

  return moment(date).format('MMM Do, YYYY');
};

type RenderChatDateProps = {
  collapsedDates: string[];
  date: string;
  setCollapsedDates: Dispatch<SetStateAction<string[]>>;
};

const RenderChatDate = ({ collapsedDates, date, setCollapsedDates }: RenderChatDateProps) => {
  return (
    <Container>
      <Date>{formatDate(date)}</Date>
      {/* <Button
        endIcon={collapsedDates.includes(date) ? <ArrowUpSLineIcon /> : <ArrowDownSLineIcon />}
        variant="outlined"
        size="small"
        onClick={() =>
          setCollapsedDates((prevState) => {
            const updatedState = [...prevState];
            if (updatedState.includes(date)) {
              const index = updatedState.indexOf(date);
              updatedState.splice(index, 1);

              return updatedState;
            }

            return [...updatedState, date];
          })
        }
        sx={(theme) => ({
          mt: -4,
          backgroundColor: theme.palette.custom.white,
          '&:hover': {
            backgroundColor: theme.palette.custom.white,
          },
        })}
      >
        {formatDate(date)}
      </Button> */}
    </Container>
  );
};

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  textAlign: 'center',
  // position: 'sticky',
  top: 110,
  zIndex: 1,
}));

const Date = styled(Typography, { label: 'Date' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.background,
  padding: '8px 16px',
  margin: '-24px auto 0',
  width: 'fit-content',
}));

export default RenderChatDate;
