import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Match, RenderSearchProps } from '@react-pdf-viewer/search';
import { Dispatch, SetStateAction, useEffect } from 'react';

type RenderSearchMatchesProps = RenderSearchProps & {
  documentLoaded: boolean;
  matches: Match[];
  setMatches: Dispatch<SetStateAction<Match[]>>;
};

function RenderSearchMatches({
  currentMatch,
  documentLoaded,
  matches,
  jumpToMatch,
  search,
  setMatches,
}: RenderSearchMatchesProps) {
  useEffect(() => {
    if (documentLoaded) {
      search()
        .then((allMatches) => setMatches(allMatches))
        .catch((error) => console.log('PDF_SEARCH_ERROR', error));
    }
  }, [documentLoaded]);

  return (
    <>
      {matches.map((match, index) => {
        const wordsBefore = match.pageText.substr(match.startIndex - 20, 20);
        let words = wordsBefore.split(' ');
        words.shift();
        const begin = words.length === 0 ? wordsBefore : words.join(' ');

        const wordsAfter = match.pageText.substr(match.endIndex, 60);
        words = wordsAfter.split(' ');
        words.pop();
        const end = words.length === 0 ? wordsAfter : words.join(' ');
        const isCurrentMatch = currentMatch === index + 1;

        return (
          <Box key={index}>
            {(index === 0 || match.pageIndex !== matches[index - 1]?.pageIndex) && (
              <PageNo>Page {match.pageIndex + 1}</PageNo>
            )}
            <SearchMatchContainer
              onClick={() => {
                jumpToMatch(index + 1);
              }}
              sx={{
                fontWeight: isCurrentMatch ? 700 : 400,
                opacity: isCurrentMatch ? 1 : 0.9,
              }}
            >
              {begin}

              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.custom.mangoTango,
                })}
              >
                {match.pageText.substring(match.startIndex, match.endIndex)}
              </Box>

              {end}
            </SearchMatchContainer>
          </Box>
        );
      })}
    </>
  );
}

const PageNo = styled(Typography, { label: 'PageNo' })(({ theme }) => ({
  color: theme.palette.custom.white,
  fontWeight: 700,
  margin: '16px 0 8px',
}));

const SearchMatchContainer = styled(Typography, { label: 'SearchMatchContainer' })(({ theme }) => ({
  cursor: 'pointer',
  padding: 8,
  color: theme.palette.custom.white,
  borderBottom: `1px solid ${theme.palette.custom.smokeGrey}`,
}));

export default RenderSearchMatches;
