import PdfViewer from '@buildwise/pages/search/PdfViewer';
import { TableRow, TableCell, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { formatDate, formatFileSize, getExtraFileNameAttachedByService } from 'utility/helpers';
import { FileActivityType } from '../apis/HomeAPI';
import useUserData from 'hooks/useUserData';
import FileIcon from '@buildwise/pages/search/components/FileIcon';
import { styled } from '@mui/material/styles';
import RenderFileLocation from './RenderFileLocation';

type RenderFileActivityProps = {
  file: FileActivityType;
};

function RenderFileActivity({ file }: RenderFileActivityProps) {
  const { companyId } = useUserData();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    name: string;
    size: number;
    url: string;
  } | null>(null);
  const fileName =
    file.objectType === 'ARTICLE' || file.objectType === 'COMMENT'
      ? file.documentName?.replace(
          getExtraFileNameAttachedByService({ id: file.objectId, companyId }),
          '',
        ) || ''
      : file.documentName;

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell scope="row">
        <Box
          display="flex"
          width="fit-content"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (
              file?.documentName &&
              (file?.documentName?.toLowerCase()?.endsWith('.pdf') || file?.documentPDF)
            ) {
              setOpenPdfViewer(true);
              setSelectedFile({
                name: file.documentName,
                size: file?.documentSize || 0,
                url: file?.documentPDF || file?.documentURL || '',
              });
            } else {
              window.open(file.documentURL);
            }
          }}
        >
          <FileIcon fileType={file.documentType} />
          <FileName title={fileName}>{fileName}</FileName>
        </Box>
      </TableCell>
      {/* <TableCell>
        {file?.createdBy ? (
          <Box display="flex" alignItems="center">
            <Avatar
              variant="circular"
              sx={{
                backgroundColor: getAvatarBgColor(file?.createdBy || '', 30, 50),
                width: 30,
                height: 30,
                mr: 1,
                fontSize: 16,
              }}
            >
              {file?.createdBy?.slice(0, 1)?.toUpperCase() || <UserLineIcon size={16} />}
            </Avatar>
            <UserLink
              mail={file?.createdBy || ''}
              name={file?.createdBy || ''}
              target="_blank"
              sx={{ fontSize: 'inherit', color: 'inherit !important' }}
            />
          </Box>
        ) : (
          <>&mdash;&mdash;</>
        )}
      </TableCell> */}
      {/* <TableCell>
        <RenderFileLocation file={file} />
      </TableCell> */}
      <TableCell>{file.createdAt ? formatDate(file.createdAt) : <>&mdash;&mdash;</>}</TableCell>
      <TableCell>
        {file.documentSize ? formatFileSize(file.documentSize) : <>&mdash;&mdash;</>}
      </TableCell>

      {openPdfViewer && selectedFile && (
        <PdfViewer open={openPdfViewer} setOpen={setOpenPdfViewer} file={selectedFile} />
      )}
    </TableRow>
  );
}

const FileName = styled(Typography, { label: 'FileName' })(({}) => ({
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 300,
  marginLeft: 8,
}));

export default RenderFileActivity;
