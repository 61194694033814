import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';
import { useCallback, useMemo } from 'react';
import useUserData from 'hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import ProjectsAPI from 'api/ProjectsAPI';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { PartnerType } from 'pages/stakeholders/hooks/useStakeholdersQuery';
import { RecommendedArticleStatusType } from 'api/KnowledgeAPI';

export type ProjectContactType = {
  id: string;
  active: boolean;
  contactTypes: string[];
  name: string;
  description: string;
  members: any[];
  completion_date: string;
  projectIds?: string[];
  scoreCards?: Array<{
    contractorId: string;
    id: string;
  }>;
  city: string;
  state: string;
  country: string;
};

export type InsightLikedUserType = {
  displayName: string;
  id: string;
  pmuserId: string | null;
  title: string;
  userName: string;
  years: string;
};

export type InsightRfiType = {
  bookMarked: boolean;
  comments: any[];
  createdByDisplayName: string;
  created_at: Date | string;
  created_by: string;
  engagementDetails?: {
    likedUsers?: Partial<InsightLikedUserType>[];
    comments: any[];
  };
  full_number: string;
  id: string;
  link: string;
  pmCompanyId: string;
  pmProjectId: string;
  pmRFIId: string;
  projectId: string;
  projectName: string;
  question_body: string;
  insight_snippet: string;
  reference: string;
  responsible_contractor: string;
  specification_section: string;
  status: string;
  subject: string;
};

export type PmRfiType = {
  bookMarked: boolean;
  comments: any[];
  createdByDisplayName: string;
  created_at: Date | string;
  created_by: string;
  engagementDetails: {
    likedUsers: any[];
    comments: any[];
  };
  full_number: string;
  id: string;
  likedUsers: any[];
  link: string;
  pmCompanyId: string;
  pmProjectId: string;
  pmRFIId: string;
  question_body: string;
  subject: string;
  insight_snippet: string;
  projectId: string;
};

export type PmSubmittalType = {
  bookMarked: boolean;
  comments: any[];
  createdByDisplayName: string;
  created_at: Date | string;
  created_by: string;
  description: string;
  engagementDetails: {
    likedUsers: any[];
    comments: any[];
  };
  id: string;
  likedUsers: any[];
  pmCompanyId: string;
  pmProjectId: string;
  pmSubmittalId: string;
  responsible_contractor: string;
  specSectionCode: string;
  specSectionDescription: string;
  status: string;
  submittalManager: string;
  submittalNumber: string;
  submittalType: string;
  title: string;
};

export type PmSpecType = {
  current_revision_id: string;
  description: string;
  id: string;
  label: string;
  number: string;
  pmCompanyId: string;
  pmProjectId: string;
  pmSpecsId: string;
};

export type ProjectType = {
  address: string;
  articles: ArticleType[];
  city: string;
  completion_date: string;
  contacts: PartnerType[];
  costCenter: string;
  country: string;
  country_code: string;
  county: string;
  createdAt: Date | string;
  deliveryMethod: string;
  department: string;
  description: string;
  display_name: string;
  feePercentage: string;
  formalRFP: string | null;
  id: string;
  insightRFIs: InsightRfiType[];
  interviewRequired: string | null;
  latitude: string;
  longitude: string;
  marketSector: string;
  maxContractPrice: string;
  minorityParticipation: string;
  name: string;
  phone: string;
  pmRFIs: PmRfiType[];
  pmSpecs: PmSpecType[];
  pmSubmittals: PmSubmittalType[];
  projectAssociatedArticleCount: number;
  projectAssociatedUserCount: number;
  projectAwardedDate: string | null;
  projectBidDate: string | null;
  projectBidStatus: string | null;
  projectBidType: string | null;
  projectContractType: string | null;
  projectDurationMonths: string;
  projectFilesCount?: number;
  projectOwnerType: string | null;
  projectRegion: string;
  projectScopes: string[];
  project_number: string;
  project_type: string;
  region: string | null;
  squareFeet: string;
  stage: string;
  start_date: string;
  state_code: string;
  totalValue: string;
  tz_name: string;
  userIds: string[];
  users: {
    displayName: string;
    id: string;
    profileImageURL: string;
    title: string;
    userName: string;
    years: string;
  }[];
  workstream: string;
  zip: string;
};

type ProjectSpecSectionDetailsMapType = Record<
  string,
  {
    articleIds: string[];
    division?: string;
    divisionName?: string;
    specSection?: string;
    specSectionName?: string;
  }
>;

export type ProjectRecommendationsType = ProjectType & {
  recommendations: {
    articleMap: Record<string, ArticleType>;
    articleIdToStatusObjMap?: Record<
      string,
      {
        status: RecommendedArticleStatusType;
        notes?: string;
        activityLog?: {
          date: Date | string | null;
          userName: string;
          description: string;
          status: RecommendedArticleStatusType;
        }[];
      }
    >;
    id: string | null;
    specSectionDetailsMap: ProjectSpecSectionDetailsMapType;
  };
};

export type ProjectsPaginationResponseType = {
  projects: ProjectType[];
  totalElements: number;
  totalNumber: number;
  totalPages: number;
};

export type StarredProjectsResponse = {
  projects: ProjectType[];
  totalElements: number;
  totalNumber: number;
  totalPages: number;
};

export const useProjectsQuery = ({ searchKeyword = '' }: { searchKeyword?: string } = {}) => {
  const { companyId } = useUserData();

  const { data: projectsData, isLoading } = useQuery<
    AxiosResponse<ProjectsPaginationResponseType>,
    Error
  >(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECTS.replace(':companyId', companyId)],
    () => ProjectsAPI.getProjects(companyId),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId,
      refetchOnMount: 'always',
    },
  );

  const { data: searchedData, ...searchInfo } = useQuery<AxiosResponse<ProjectType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH, companyId, searchKeyword, 'all'],
    () => ProjectsAPI.searchProjects(companyId, searchKeyword),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!searchKeyword,
    },
  );

  const projects = useMemo(() => {
    if (!!searchKeyword) {
      return searchedData?.data || [];
    }
    return projectsData?.data?.projects || [];
  }, [projectsData, searchedData, searchKeyword]);

  return {
    projects,
    isLoading,
    searchInfo,
  };
};

export const useProjectsPaginationQuery = ({
  pageNo,
  pageSize,
  shouldFetch = true,
}: {
  pageNo: number;
  pageSize: number;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();

  const fetchProjects = ({ pageParam = pageNo }) => {
    return ProjectsAPI.getProjectsPagination(companyId, pageParam, pageSize);
  };

  const {
    data: projectsData,
    isLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery<AxiosResponse<ProjectsPaginationResponseType, Error>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECTS_PAGINATION, companyId],
    fetchProjects,
    {
      staleTime: 2 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
      getNextPageParam: (lastPage) => {
        return lastPage.data.totalPages > lastPage.data.totalNumber + 1
          ? lastPage.data.totalNumber + 1
          : undefined;
      },
    },
  );

  const projects = useMemo(() => {
    const allProjects: ProjectType[] = [];
    projectsData?.pages?.forEach((page) => allProjects.push(...page?.data?.projects));

    return allProjects;
  }, [projectsData]);

  return {
    fetchNextPage,
    totalProjects: projectsData?.pages?.[0].data?.totalElements,
    projects,
    isLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  };
};

export const useStarredProjectsQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { user, companyId } = useUserData();

  const {
    data: starredProjectsData,
    isIdle,
    ...rest
  } = useQuery<AxiosResponse<StarredProjectsResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_STARRED_PROJECTS, companyId],
    () => ProjectsAPI.getStarredProjects(companyId),
    {
      enabled: !!companyId && shouldFetch,
      keepPreviousData: true,
      staleTime: 2 * 60 * 1000,
      initialData: () => {
        const projectsData = queryClient.getQueryData<
          InfiniteData<AxiosResponse<ProjectsPaginationResponseType, Error>> | undefined
        >([JAVA_SERVICE_ENDPOINTS.GET_PROJECTS_PAGINATION, companyId]);

        if (projectsData) {
          const newStarredProjects: ProjectType[] = [];
          projectsData?.pages?.forEach((page) =>
            newStarredProjects.push(
              ...page?.data?.projects.filter((project) =>
                user?.starredProjects?.includes(project.id),
              ),
            ),
          );

          return {
            data: { projects: newStarredProjects, totalElements: 0, totalNumber: 0, totalPages: 0 },
          } as AxiosResponse<StarredProjectsResponse, Error>;
        }

        return undefined;
      },
    },
  );

  const starredProjects = useMemo(
    () => starredProjectsData?.data?.projects || [],
    [starredProjectsData],
  );

  const updateStarredProjectsCache = useCallback(
    ({ action, project }: { action: 'add' | 'remove'; project: ProjectType }) => {
      if (!isIdle) {
        queryClient.setQueryData<AxiosResponse<StarredProjectsResponse> | undefined>(
          [JAVA_SERVICE_ENDPOINTS.GET_STARRED_PROJECTS, companyId],
          (oldData: AxiosResponse<StarredProjectsResponse> | undefined) => {
            if (oldData) {
              const newStarredProjects = [...(oldData?.data?.projects || [])];

              if (action === 'add') {
                newStarredProjects.unshift(project);
              }

              if (action === 'remove') {
                const index = newStarredProjects.findIndex(
                  (starredProject) => starredProject.id === project.id,
                );
                if (index > -1) {
                  newStarredProjects.splice(index, 1);
                }
              }

              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  projects: newStarredProjects,
                },
              };
            }

            return oldData;
          },
        );
      }
    },
    [companyId, isIdle],
  );

  return {
    starredProjects,
    isIdle,
    ...rest,
    updateStarredProjectsCache,
  };
};

export const useProjectsInsightsQuery = () => {
  const { companyId } = useUserData();

  const { data: insightsData, isLoading } = useQuery<AxiosResponse<InsightRfiType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS.replace(':companyId', companyId)],
    () => ProjectsAPI.getProjectsInsights(companyId),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId,
      refetchOnMount: 'always',
    },
  );
  const insights = useMemo(() => insightsData?.data || [], [insightsData]);

  return {
    insights,
    isLoading,
  };
};

export const updateProjectsCache = (
  companyId: string,
  newProject: ProjectType | null,
  newProjects?: ProjectType[],
) => {
  queryClient.setQueriesData<AxiosResponse<ProjectsPaginationResponseType> | undefined>(
    [JAVA_SERVICE_ENDPOINTS.GET_PROJECTS_PAGINATION, companyId],
    (oldData: any) => {
      if (oldData) {
        if (!newProject && newProjects && newProjects?.length > 0) {
          oldData.pages = oldData?.pages.map((page, i) => {
            const oldProjects = [...(page?.data?.projects || [])];
            for (const project of newProjects) {
              const index = oldProjects.findIndex((oldProject) => oldProject.id === project.id);
              if (index > -1) {
                oldProjects[index] = { ...oldProjects[index], ...project };
              }
            }
            page.data = { ...page?.data, projects: oldProjects };
            return page;
          });
        } else {
          oldData.pages = oldData?.pages.map((page, i) => {
            const oldProjects = [...(page?.data?.projects || [])];
            if (i === 0) {
              const index = oldProjects.findIndex((project) => project.id === newProject?.id);
              if (index > -1) {
                oldProjects[index] = { ...oldProjects[index], ...newProject };
              } else {
                oldProjects.unshift(newProject);
              }
            }
            page.data = { ...page?.data, projects: oldProjects };
            return page;
          });
        }
      }

      return oldData;
    },
  );
};

export const updateInsightsCache = (
  companyId: string,
  updatedInsight: Partial<InsightRfiType>,
  index?: number,
) => {
  queryClient.setQueriesData<AxiosResponse<InsightRfiType[]> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_INSIGHTS.replace(':companyId', companyId),
    (oldData: AxiosResponse<InsightRfiType[]> | undefined) => {
      if (oldData) {
        const newData = [...oldData.data];
        if (index && index > -1 && newData[index].id === updatedInsight.id) {
          newData[index] = updatedInsight as InsightRfiType;
        } else {
          const insightIndex = newData.findIndex((insight) => insight.id === updatedInsight.id);
          if (insightIndex > -1) {
            newData[insightIndex] = updatedInsight as InsightRfiType;
          }
        }

        return { ...oldData, data: newData };
      }

      return oldData;
    },
  );
};

export const invalidateProjectQueries = (companyId) => {
  queryClient.refetchQueries(JAVA_SERVICE_ENDPOINTS.GET_PROJECTS.replace(':companyId', companyId));
};

export default useProjectsQuery;
