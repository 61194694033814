import React, { Dispatch, SetStateAction } from 'react';
import {
  // Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  // Tab,
  // Tabs,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { catchErrorMessage } from 'utility/helpers';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import useUserData from 'hooks/useUserData';
import UserAPI from 'api/UserAPI';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { updateUserDetailsCache } from 'pages/profile/hooks/useUserProfileData';
// import PostPreferencesForm from './PostPreferencesForm';
import InteractionsForm from './InteractionsForm';

export type NotificationsForm = {
  topicsForNotifications: string[];
  emailNotificationSettings: {
    notifyWhenOthersLikeMyContent: boolean;
    notifyWhenOthersCommentOnMyArticlesFlag: boolean;
    notifyWhenMentionedMyNameFlag: boolean;
    notifyWhenOthersReplyToMyCommentsFlag: boolean;
    notifyWhenOthersCreateNewArticlesFlag: boolean;
    notifyDailyOnArticleCreate: boolean;
    notifyWeeklyOnArticleCreate: boolean;
    sendEmailNotifications: boolean;
  };
  pushNotificationSettings: {
    notifyWhenOthersLikeMyContent: boolean;
    notifyWhenOthersCommentOnMyArticlesFlag: boolean;
    notifyWhenMentionedMyNameFlag: boolean;
    notifyWhenOthersReplyToMyCommentsFlag: boolean;
    notifyWhenOthersCreateNewArticlesFlag: boolean;
  };
};

type NotificationSettingsModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const NotificationSettingsModal: React.FC<NotificationSettingsModalProps> = ({ open, setOpen }) => {
  // const { topics } = useTopicsQuery();
  // const [tab, setTab] = useState('postPreferences');
  const { user } = useUserData();
  const notificationsForm = useForm<NotificationsForm>({
    mode: 'onTouched',
    defaultValues: {
      // topicsForNotifications:
      //   user?.topicsForNotifications === undefined || user?.topicsForNotifications === null
      //     ? topics
      //     : user.topicsForNotifications,
      emailNotificationSettings: {
        notifyWhenOthersLikeMyContent:
          user?.notificationSettings?.emailNotificationSettings?.notifyWhenOthersLikeMyContent ||
          false,
        notifyWhenOthersCommentOnMyArticlesFlag:
          user?.notificationSettings?.emailNotificationSettings
            ?.notifyWhenOthersCommentOnMyArticlesFlag || false,
        notifyWhenMentionedMyNameFlag:
          user?.notificationSettings?.emailNotificationSettings?.notifyWhenMentionedMyNameFlag ||
          false,
        notifyWhenOthersReplyToMyCommentsFlag:
          user?.notificationSettings?.emailNotificationSettings
            ?.notifyWhenOthersReplyToMyCommentsFlag || false,
        notifyWhenOthersCreateNewArticlesFlag:
          user?.notificationSettings?.emailNotificationSettings
            ?.notifyWhenOthersCreateNewArticlesFlag,
        notifyDailyOnArticleCreate:
          user?.notificationSettings?.emailNotificationSettings?.notifyDailyOnArticleCreate,
        notifyWeeklyOnArticleCreate:
          user?.notificationSettings?.emailNotificationSettings?.notifyWeeklyOnArticleCreate,
        sendEmailNotifications:
          user?.notificationSettings?.emailNotificationSettings?.sendEmailNotifications,
      },
      pushNotificationSettings: {
        notifyWhenOthersLikeMyContent:
          user?.notificationSettings?.pushNotificationSettings?.notifyWhenOthersLikeMyContent ||
          false,
        notifyWhenOthersCommentOnMyArticlesFlag:
          user?.notificationSettings?.pushNotificationSettings
            ?.notifyWhenOthersCommentOnMyArticlesFlag || false,
        notifyWhenMentionedMyNameFlag:
          user?.notificationSettings?.pushNotificationSettings?.notifyWhenMentionedMyNameFlag ||
          false,
        notifyWhenOthersReplyToMyCommentsFlag:
          user?.notificationSettings?.pushNotificationSettings
            ?.notifyWhenOthersReplyToMyCommentsFlag || false,
        notifyWhenOthersCreateNewArticlesFlag:
          user?.notificationSettings?.pushNotificationSettings
            ?.notifyWhenOthersCreateNewArticlesFlag,
      },
    },
  });
  const {
    formState: { isValid, isDirty, isSubmitting },
    handleSubmit,
    reset,
    // setValue,
  } = notificationsForm;

  // useEffect(() => {
  //   if (user?.topicsForNotifications === undefined || user?.topicsForNotifications === null) {
  //     setValue('topicsForNotifications', topics);
  //   }
  // }, [topics]);

  const closeModal = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (values: NotificationsForm) => {
    try {
      const payload = {
        ...user,
        topicsForNotifications: values.topicsForNotifications,
        notificationSettings: {
          ...user?.notificationSettings,
          emailNotificationSettings: {
            ...user?.notificationSettings?.emailNotificationSettings,
            ...values.emailNotificationSettings,
          },
          pushNotificationSettings: {
            ...user?.notificationSettings?.pushNotificationSettings,
            ...values.pushNotificationSettings,
          },
        },
      };
      const { data } = await UserAPI.editUser(payload);
      toast.success('Notification Settings Updated');
      updateUserDetailsCache({ ...user, ...data });
      reset();
      closeModal();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };
  const triggerSubmit = handleSubmit(onSubmit);

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
      // sx={{ '& .MuiPaper-root': { height: '75%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <$Title>Notification Settings</$Title>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ pt: 0 }}>
        {/* <$StickyTabsContainer>
          <Tabs value={tab} onChange={(_: unknown, newValue: string) => setTab(newValue)}>
            <Tab key="postPreferences" value="postPreferences" label="Post Preferences" />
            <Tab key="interactions" value="interactions" label="Interactions" />
          </Tabs>
        </$StickyTabsContainer> */}

        <FormProvider {...notificationsForm}>
          {/* {tab === 'postPreferences' && <PostPreferencesForm />} */}
          <InteractionsForm />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={triggerSubmit}
          variant="contained"
        >
          {isSubmitting ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const $StickyTabsContainer = styled(Box, { label: 'StickyTabsContainer' })(({ theme }) => ({
//   position: 'sticky',
//   top: 0,
//   backgroundColor: theme.palette.custom.white,
//   zIndex: 1,
//   paddingTop: 16,
//   paddingBottom: 16,
// }));

const $Title = styled(Typography, { label: 'InteractionsDialogTitle' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

export default NotificationSettingsModal;
