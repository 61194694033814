import { FC } from 'react';
import { Typography, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { CommentType } from '@buildwise/pages/article/hooks/useArticleDetailsQuery';
import RenderCommentActivity from './RenderCommentActivity';

type ViewCommentsModalProps = {
  open: boolean;
  comments: CommentType[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewCommentsModal: FC<ViewCommentsModalProps> = ({ open, comments, setOpen }) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>New Comments</Title>
      </CustomDialogTitle>
      <DialogContent dividers>
        {comments?.map((comment) => <RenderCommentActivity key={comment.id} comment={comment} />)}
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

export default ViewCommentsModal;
