import {
  Box,
  CircularProgress,
  Grid,
  Grow,
  IconButton,
  Button as MuiButton,
  Typography,
} from '@mui/material';
import { ListRange, Virtuoso } from 'react-virtuoso';
import { getPageScrollInfo, handlePageScrollPosition } from 'utility/helpers';
import HandleOperation from 'components/utils/HandleOperation';
import PaginationLoader from 'components/utils/PaginationLoader';
import { styled } from '@mui/material/styles';
import ArticleSkelton from 'pages/project/components/ArticleSkelton';
import ArticleBox, { AvatarContainer } from '@buildwise/pages/knowledge/components/ArticleBox';
import NoFeed from '@buildwise/pages/knowledge/components/NoFeed';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import useAllPosts from '../hooks/useAllPosts';
import useFilters from '../hooks/useFilters';
import Filter from 'components/common/filter/Filter';
import ChipsFilters from './ChipsFilters';
import AllPostsMoreFilters from './AllPostsMoreFilters';
import useUserData from 'hooks/useUserData';
import { useState, useRef, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import ArticlePrintPreview from '@buildwise/pages/knowledge/components/ArticlePrintPreview';
import Dot from 'components/common/dot/Dot';
import moment from 'moment';
import Upload2LineIcon from 'remixicon-react/Upload2LineIcon';
import LightbulbFlashFillIcon from 'remixicon-react/LightbulbFlashFillIcon';
import Avatar from 'components/common/avatar/Avatar';

type AllPostsProps = {
  inlineFilters?: boolean;
  scrollInfoKey?: string;
};

const AllPosts = ({ inlineFilters, scrollInfoKey }: AllPostsProps) => {
  const { isNovaAdmin } = useUserData();
  const {
    activeCompanyFilters,
    filters,
    filtersLoading,
    selectedFilters,
    selectedMoreFiltersCount,
    applyMoreFilters,
    clearAllFilters,
    onQuickFiltersChanged,
    setFilters,
  } = useFilters();
  const {
    articles,
    containerRef,
    enableRefresh,
    isFetching,
    isFetchingNextPage,
    isIdle,
    isLoading,
    isRefetching,
    virtuosoRef,
    fetchPosts,
    resetFeed,
    refetchQuery,
    setEnableRefresh,
  } = useAllPosts({
    filters: selectedFilters,
  });
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
  const [articlePreviewInfo, setArticlePreviewInfo] = useState<ArticleType[]>([]);
  const articleInfoRef = useRef<HTMLDivElement>(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const { company, user } = useUserData();

  const handlePrint = useReactToPrint({
    content: () => articleInfoRef.current,
    onBeforeGetContent: () => {
      setIsPreviewLoading(true); // Set isPreviewLoading to true before printing
    },
    onAfterPrint: () => {
      setIsPreviewLoading(false); // Reset isPreviewLoading after printing
    },
    documentTitle: `BuildWise Export`,
    pageStyle: `
    body {
      -webkit-print-color-adjust: exact; /* Chrome, Safari */
      print-color-adjust: exact;         /* Firefox */
    }
    .page-break {
      page-break-before: always;
    }
    .header {
      position: fixed;
      height : 80px;
      top : 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer {
      position: fixed;
      height : 80px;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-top: 20px;
    } 
  `,
  });

  const RenderPdfHeader = useMemo(() => {
    return (
      <Box className="header">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '85%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {company?.companyLogoDisplay &&
            company?.companyLogoDisplay !== 'NAME' &&
            company?.companyLogoURL ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {(!company?.companyLogoDisplay ||
                  ['BOTH', 'LOGO'].includes(company?.companyLogoDisplay)) && (
                  <Box
                    component="img"
                    src={company?.companyLogoURL}
                    maxWidth={200}
                    maxHeight={40}
                  />
                )}

                {company?.companyLogoDisplay === 'BOTH' && (
                  <Box sx={{ marginLeft: '5px' }}>
                    <$BoldText>{company?.name}</$BoldText>
                  </Box>
                )}
              </Box>
            ) : (
              <$BoldText>{company?.name}</$BoldText>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                mr: 1.5,
                mt: -2,
              }}
            >
              <AvatarContainer>
                <Avatar
                  src={user?.profileImageURL}
                  size={38}
                  icon={<LightbulbFlashFillIcon color="#FFC107" />}
                />
              </AvatarContainer>
            </Box>
            <Box display="flex" flexDirection="column" mb={'-4px'}>
              <$User title={user?.userNameToDisplay} sx={{ lineHeight: 1.2, fontWeight: 'blod' }}>
                {user?.userNameToDisplay}
              </$User>
              {user?.title && (
                <Typography variant="caption" title="Job Title">
                  {user.title}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }, [company, user]);

  return (
    <Grid container spacing={2} minHeight="100vh">
      <Grid item xs={12} md={inlineFilters ? 12 : 8}>
        {inlineFilters && (
          <StickyContainer>
            <ChipsFilters
              filters={filters}
              onFiltersChanged={onQuickFiltersChanged}
              setFilters={setFilters}
              clearAllFilters={clearAllFilters}
              showClearAllFiltersIcon={!!selectedMoreFiltersCount}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {isNovaAdmin && (
                <IconButton
                  onClick={() => {
                    setShowCheckbox((prevState) => !prevState);
                    setArticlePreviewInfo([]);
                    setIsPreviewLoading(false);
                  }}
                  sx={{
                    mr: 1,
                    backgroundColor: showCheckbox ? 'rgba(80, 77, 100, 0.12)' : 'unset',
                  }}
                  size="small"
                  title="Export"
                  disabled={isPreviewLoading}
                >
                  <Upload2LineIcon size={22} color={showCheckbox ? '#091E42' : '#525659'} />
                </IconButton>
              )}
              {activeCompanyFilters.length > filters.length && (
                <AllPostsMoreFilters
                  selectedFilters={selectedFilters}
                  applyMoreFilters={applyMoreFilters}
                  selectedMoreFiltersCount={selectedMoreFiltersCount}
                />
              )}
            </Box>
          </StickyContainer>
        )}
        <Box ref={containerRef} visibility="hidden">
          <Grow
            in={isFetching && !isFetchingNextPage && enableRefresh}
            style={{ transformOrigin: 'top center' }}
            timeout={500}
          >
            <Box position="relative">
              <AbsoluteContainer>
                <MuiButton
                  variant="contained"
                  size="small"
                  sx={{ margin: '0 auto' }}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  Refreshing...
                </MuiButton>
              </AbsoluteContainer>
            </Box>
          </Grow>

          <HandleOperation
            isLoading={isLoading || (isFetching && !isFetchingNextPage && !enableRefresh) || isIdle}
            data={articles}
            skelton={<ArticleSkelton variant="short" />}
            skeltonCount={5}
            noDataComponent={
              <NoFeed
                tab="posts"
                clearFilters={clearAllFilters}
                hasActiveFilters={selectedFilters.length > 0}
              />
            }
          >
            <Box pb={5}>
              <Virtuoso
                rangeChanged={(range: ListRange) => {
                  if (virtuosoRef.current && containerRef.current?.style.visibility !== 'hidden') {
                    handlePageScrollPosition(
                      scrollInfoKey || 'posts',
                      0,
                      window.scrollY === 0
                        ? 0
                        : Math.floor((range.startIndex + range.endIndex) / 2),
                    );
                  }
                }}
                ref={virtuosoRef}
                useWindowScroll
                data={articles}
                endReached={fetchPosts}
                atTopStateChange={(atTop) => {
                  const scrollToIndex = getPageScrollInfo<ArticleType>(
                    scrollInfoKey || 'posts',
                    articles,
                    'id',
                  );
                  if (!atTop) {
                    setEnableRefresh(true);
                  }

                  if (
                    scrollToIndex <= 2 &&
                    atTop &&
                    !isLoading &&
                    !isFetching &&
                    !isFetchingNextPage &&
                    !isRefetching &&
                    articles.length > 0 &&
                    enableRefresh
                  ) {
                    refetchQuery({ type: 'posts' });
                  }
                }}
                itemContent={(index, item) => (
                  <ArticleBox
                    key={item.id}
                    article={item}
                    articleIndex={index}
                    variant="short"
                    doNotAutoplayVideos
                    scrollInfoKey={scrollInfoKey || 'posts'}
                    resetFeed={resetFeed}
                    selectedFeedFilters={selectedFilters}
                    showCheckbox={showCheckbox}
                    articlePreviewInfo={articlePreviewInfo}
                    setArticlePreviewInfo={setArticlePreviewInfo}
                    isPreviewLoading={isPreviewLoading}
                  />
                )}
                components={{
                  Footer: () => <PaginationLoader isLoading={isFetchingNextPage} />,
                }}
              />
            </Box>
          </HandleOperation>
        </Box>
      </Grid>

      {!inlineFilters && (
        <Grid item md={4} display={{ xs: 'none', sm: 'none', md: 'block' }}>
          <Filter filters={filters} isLoading={filtersLoading} skeltonCount={10} />
        </Grid>
      )}

      {articlePreviewInfo?.length ? (
        <Box sx={{ display: 'none' }}>
          <Box
            ref={articleInfoRef}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: '85%' }}>
              {RenderPdfHeader}
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <td style={{ height: '80px' }}></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {articlePreviewInfo.map((item, index) => (
                        <ArticlePrintPreview
                          key={item.id}
                          article={item}
                          articleIndex={index}
                          variant="detailed"
                          doNotAutoplayVideos
                          scrollInfoKey={scrollInfoKey || 'posts'}
                          resetFeed={resetFeed}
                          selectedFeedFilters={selectedFilters}
                          showCheckbox={showCheckbox}
                          exportedArticles={articlePreviewInfo}
                          setArticlePreviewInfo={setArticlePreviewInfo}
                        />
                      ))}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ height: '70px' }}></td>
                  </tr>
                </tfoot>
              </table>
              <Box className="footer">
                <PdfFooter>
                  <$CompanyLogo src="https://images.buildwise.ai/BuildWise_logo_without_name.png" />
                  <Typography component="strong" fontWeight={700} variant="subtitle2">
                    Powered by BuildWise.AI
                  </Typography>
                  <Dot />
                  <Typography variant="subtitle2">
                    Exported on {moment().format('MMM DD, YYYY')}
                  </Typography>
                </PdfFooter>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}

      {articlePreviewInfo?.length ? (
        <StickyFooter>
          <StickyFooterContentContainer>
            <Box sx={{ ml: 4, display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1">
                {articlePreviewInfo?.length > 1
                  ? `${articlePreviewInfo?.length} Posts Selected`
                  : '1 Post Selected'}
              </Typography>
              <MuiButton
                variant="text"
                size="medium"
                sx={(theme) => ({
                  ml: 2,
                  color: theme.palette.custom.link,
                  fontWeight: 700,
                  cursor: 'pointer',
                })}
                onClick={() => {
                  if (articles.length === articlePreviewInfo.length) {
                    setArticlePreviewInfo([]);
                  } else {
                    setArticlePreviewInfo([...articles]);
                  }
                }}
                disabled={isPreviewLoading}
              >
                {articles.length === articlePreviewInfo.length ? 'Deselect All' : 'Select All'}
              </MuiButton>
            </Box>
            <Box>
              <MuiButton
                variant="text"
                size="medium"
                sx={{ mr: 2 }}
                onClick={() => {
                  setArticlePreviewInfo([]);
                  setShowCheckbox(false);
                  setIsPreviewLoading(false);
                }}
              >
                Cancel
              </MuiButton>
              <MuiButton
                variant="contained"
                size="medium"
                sx={{ mr: 2 }}
                onClick={() => {
                  handlePrint();
                }}
                disabled={isPreviewLoading}
              >
                {isPreviewLoading ? (
                  <CircularProgress
                    sx={(theme) => ({ color: theme.palette.custom.black })}
                    size={20}
                  />
                ) : (
                  'Preview'
                )}
              </MuiButton>
            </Box>
          </StickyFooterContentContainer>
        </StickyFooter>
      ) : null}
    </Grid>
  );
};

const AbsoluteContainer = styled(Box)({
  position: 'absolute',
  top: 20,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
});

const StickyContainer = styled(Box, { label: 'StickyContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 135,
  paddingTop: 8,
  paddingBottom: 16,
  backgroundColor: theme.palette.custom.background,
  zIndex: 2,
  width: '100%',
}));

const StickyFooter = styled(Box, { label: 'StickyFooter' })(({ theme }) => ({
  margin: 'auto',
  position: 'fixed',
  bottom: 0,
  left: 0,
  Padding: '0px 8px',
  backgroundColor: theme.palette.custom.white,
  zIndex: 2,
  width: '100%',
  height: '60px',
}));

const StickyFooterContentContainer = styled(Box, { label: 'StickyFooterContentContainer' })(
  ({ theme }) => ({
    maxWidth: theme.maxWidth,
    border: '1ps solid red',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 48px',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    positon: 'relative',
  }),
);

const $CompanyLogo = styled('img', { label: 'Image' })({
  height: '35px',
  marginRight: '8px',
});

const PdfFooter = styled(Box, { label: 'PdfFooter' })({
  display: 'flex',
  borderTop: '1px solid #ededf1',
  width: '85%',
  alignItems: 'center',
  padding: '10px 0',
});

const $User = styled(Typography, { label: 'User' })({
  fontWeight: 'bold',
  fontSize: '16px',
});

const $BoldText = styled(Typography, { label: 'Typography' })<{ fontSize?: number }>(
  ({ theme, fontSize = 24 }) => ({
    fontSize,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.custom.body,
    lineHeight: '27px',
  }),
);

export default AllPosts;
