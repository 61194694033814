import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VIEW_DRAFT } from 'constants/routes';
import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';
import { UseArticleReducerActions } from 'pages/knowledge/hooks/useArticle';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { Dispatch, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon';
import Edit2LineIcon from 'remixicon-react/Edit2LineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import UserAddLineIcon from 'remixicon-react/UserAddLineIcon';
import UserShared2LineIcon from 'remixicon-react/UserShared2LineIcon';
import Collaborators from './Collaborators';
import useUserData from 'hooks/useUserData';

type DraftFooterActionsProps = {
  article: ArticleType;
  userMap: ArticleUserMapType;
  dispatch: Dispatch<UseArticleReducerActions>;
};

function DraftFooterActions({ article, userMap, dispatch }: DraftFooterActionsProps) {
  const { hash, state } = useLocation();
  const { company, isNovaAdmin, isBuildWiseInsightsUser } = useUserData();
  const tab = useMemo(() => decodeURIComponent(hash).replace('#', '') || '', [hash]);
  const canPublishPost = useMemo(
    () => !((isNovaAdmin || isBuildWiseInsightsUser) && company?.settings?.externalFlag),
    [company?.settings.externalFlag, isNovaAdmin, isBuildWiseInsightsUser],
  );

  return (
    <Container>
      <Box>
        <Collaborators article={article} userMap={userMap} />
      </Box>

      <Box display="flex">
        <Button
          sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mx: 0.5 })}
          startIcon={<Edit2LineIcon size={18} />}
          variant="outlined"
          size="small"
          onClick={() =>
            dispatch({ type: 'TOGGLE_EDIT_MODAL', payload: true, key: 'openEditPost' })
          }
        >
          Edit
        </Button>
        {tab !== 'collaborations' && tab !== 'assigned-to-others' && (
          <Button
            sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mx: 0.5 })}
            startIcon={<UserShared2LineIcon size={18} />}
            variant="outlined"
            size="small"
            onClick={() =>
              dispatch({
                type: 'TOGGLE_TRANSFER_DRAFT_MODAL',
                payload: true,
                key: 'openTransferDraftModal',
              })
            }
          >
            Assign
          </Button>
        )}
        <Button
          sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mx: 0.5 })}
          startIcon={<UserAddLineIcon size={18} />}
          variant="outlined"
          size="small"
          onClick={() =>
            dispatch({ type: 'TOGGLE_INVITE_MODAL', payload: true, key: 'openInviteModal' })
          }
        >
          Invite
        </Button>
        {tab === 'collaborations' && (
          <Link
            to={`${VIEW_DRAFT.replace(':articleId', article.id)}#comments`}
            state={{ ...state, isDraft: true }}
          >
            <Button
              sx={(theme) => ({ backgroundColor: theme.palette.custom.white, mx: 0.5 })}
              startIcon={<Chat3LineIcon size={18} />}
              variant="outlined"
              size="small"
            >
              Comments ({article.totalComments || 0})
            </Button>
          </Link>
        )}
        {canPublishPost && (
          <Button
            startIcon={<FileListLineIcon size={18} />}
            variant="contained"
            size="small"
            sx={{ mx: 0.5 }}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_POST_CONFIRMATION_MODAL',
                payload: true,
                key: 'openPostConfirmationModal',
              })
            }
          >
            Post
          </Button>
        )}
      </Box>
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 12,
  paddingBottom: 12,
}));

export default DraftFooterActions;
