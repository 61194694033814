import useUserData from 'hooks/useUserData';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import FoldersLineIcon from 'remixicon-react/FoldersLineIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import FileLineIcon from 'remixicon-react/FileLineIcon';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import {
  JAVA_SERVICE_ENDPOINTS,
  PYTHON_SERVICE_ENDPOINTS,
} from '@buildwise/libs/services/endpoints';
import { useBuildWiseModulesQuery } from 'pages/knowledge/hooks/usePersonalizeQueries';
import { BUILDWISE_MODULES } from 'components/drawer/constants';
import SearchAPI, { GlobalSearchPayload } from 'api/SearchAPI';
import PythonSearchAPI from 'api/PythonSearchAPI';

export type SearchTab = {
  disabled: boolean;
  emptyDataIcon: JSX.Element;
  endpoint: string;
  service: typeof SearchAPI | typeof PythonSearchAPI;
  icon: JSX.Element;
  name: string;
  payload: GlobalSearchPayload;
  type: string;
};

function useSearchTabs() {
  const theme = useTheme();
  const { buildwiseModules } = useBuildWiseModulesQuery();
  const { company, companyId } = useUserData();
  const SEARCH_TABS = useMemo(
    () =>
      (
        [
          {
            type: 'articles',
            name: 'Posts',
            icon: <FileListLineIcon size={18} />,
            disabled: false,
            emptyDataIcon: <FileListLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: PYTHON_SERVICE_ENDPOINTS.ARTICLES_SEARCH,
            service: PythonSearchAPI,
            payload: {
              companyId,
              novaSearchType: 'articles',
              fileSearch: !!company?.settings?.articlesLLMSearch,
              pageContent: false,
            },
          },
          {
            type: 'users',
            name: 'People',
            icon: <GroupLineIcon size={18} />,
            disabled: false,
            emptyDataIcon: <GroupLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: JAVA_SERVICE_ENDPOINTS.SEARCH,
            service: SearchAPI,
            payload: {
              companyId,
              novaSearchType: 'users',
            },
          },
          {
            type: 'projects',
            name: 'Projects',
            icon: <BuildingLineIcon size={18} />,
            disabled: !company?.settings?.projectFlag,
            emptyDataIcon: <BuildingLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: JAVA_SERVICE_ENDPOINTS.SEARCH,
            service: SearchAPI,
            payload: {
              companyId,
              novaSearchType: 'projects',
            },
          },
          {
            type: 'stakeholders',
            name:
              buildwiseModules.find((module) => module.entryCode === BUILDWISE_MODULES.PARTNERS)
                ?.entryDescription || BUILDWISE_MODULES.PARTNERS,
            icon: <HandCoinLineIcon size={18} />,
            disabled: !company?.settings?.partnerFlag,
            emptyDataIcon: <HandCoinLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: JAVA_SERVICE_ENDPOINTS.SEARCH,
            service: SearchAPI,
            payload: {
              companyId,
              novaSearchType: 'stakeholders',
            },
          },
          {
            type: 'companyFiles',
            name:
              buildwiseModules.find((module) => module.entryCode === BUILDWISE_MODULES.PROCEDURES)
                ?.entryDescription || BUILDWISE_MODULES.PROCEDURES,
            icon: <FoldersLineIcon size={18} />,
            disabled: !company?.settings?.companyFilesLLMSearch,
            emptyDataIcon: <FoldersLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: PYTHON_SERVICE_ENDPOINTS.COMPANY_FILES_SEARCH,
            service: PythonSearchAPI,
            payload: {
              companyId,
              novaSearchType: 'companyFiles',
              summarization: false,
              pageContent: false,
            },
          },
          {
            type: 'projectFileSearch',
            name: 'Project Files',
            icon: <FileLineIcon size={18} />,
            disabled: !company?.settings?.projectFilesLLMSearch,
            emptyDataIcon: <FileLineIcon size={140} color={theme.palette.custom.steel} />,
            endpoint: PYTHON_SERVICE_ENDPOINTS.PROJECT_FILES_SEARCH,
            service: PythonSearchAPI,
            payload: {
              companyId,
              novaSearchType: 'projectFileSearch',
              summarization: false,
              pageContent: false,
            },
          },
        ] as SearchTab[]
      ).filter((tab) => !tab.disabled),
    [company, buildwiseModules, companyId],
  );

  return {
    SEARCH_TABS,
  };
}

export default useSearchTabs;
