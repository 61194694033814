import { create } from 'zustand';

interface ShareModalStore {
  shareData?: any;
  openModal: (params: { shareData?: any }) => void;
  close: () => void;
}

const useShareModalStore = create<ShareModalStore>((set) => ({
  openModal: (params) => {
    set({ ...params });
  },
  close: () => set({ shareData: null }),
}));

export default useShareModalStore;
