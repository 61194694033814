import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
  Skeleton,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import {
  usePlaylistsQuery,
  ArticleType,
  useArticlesAndPlaylistsPaginationQuery,
} from '../hooks/useArticlesQuery';
import { catchErrorMessage, formatDate } from 'utility/helpers';
import KnowledgeAPI, { FeedFilter } from 'api/KnowledgeAPI';
import { toast } from 'react-toastify';
import useUserData, { useUserArticlesQuery } from 'hooks/useUserData';
import { USER_PROFILE, VIEW_ARTICLE } from 'constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import HandleOperation from 'components/utils/HandleOperation';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import Search from './SearchComponent';
import Dot from 'components/common/dot/Dot';
import AddPostModal from 'components/modals/addPostModal/AddPostModal';
import { debounce } from 'lodash';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import BookletLineIcon from 'remixicon-react/BookletLineIcon';
import { AxiosResponse } from 'axios';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import useProjectDetailsQuery, {
  useProjectRecommendationsQuery,
} from 'pages/project/hooks/useProjectDetailsQuery';
import { useStakeholderArticles } from 'pages/stakeholder/hooks/useStakeholderDetailsQuery';
import useBookmarksQuery from 'pages/bookmarks/hooks/useBookmarksQuery';

type AddToPlayListModalPropTypes = {
  article: ArticleType;
  open: boolean;
  projectId?: string;
  stakeholderId?: string;
  selectedFeedFilters?: FeedFilter[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddPostToPlaylistsModal: React.FC<AddToPlayListModalPropTypes> = ({
  article,
  open,
  projectId = '',
  stakeholderId = '',
  selectedFeedFilters,
  setOpen,
}) => {
  const theme = useTheme();
  const { companyId } = useUserData();
  const { updatePostInPaginatedPlaylistsCache } = useArticlesAndPlaylistsPaginationQuery({
    shouldFetch: false,
  });
  const { updatePostInUserPlaylistsCache } = useUserArticlesQuery({ shouldFetch: false });
  const { updatePostInProjectRecommendedPlaylistsCache } = useProjectRecommendationsQuery({
    projectId,
    shouldFetch: false,
  });
  const { updatePostInProjectPlaylistsCache } = useProjectDetailsQuery({
    projectId,
    shouldFetch: false,
  });
  const { updatePostInStakeholderPlaylistsCache } = useStakeholderArticles({
    stakeholderId,
    shouldFetch: false,
  });
  const { updatePostInBookmarkedPlaylistsCache } = useBookmarksQuery({
    shouldFetch: false,
  });
  const { playlists, isLoading, updatePlaylistsCache, updatePostInPlaylistsCache } =
    usePlaylistsQuery();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPlaylistIds, setSelectedPlaylistIds] = useState<string[]>([]);
  const [removedPlaylistIds, setRemovedPlaylistIds] = useState<string[]>([]);
  const [openAddPlaylistModal, setOpenAddPlaylistModal] = useState(false);

  const handleChange = (playlist: ArticleType) => {
    if (!loading) {
      if (playlist.playListArticleIds.includes(article.id)) {
        setRemovedPlaylistIds((prevState) => {
          const newRemovedPlaylistIds = [...prevState];

          if (removedPlaylistIds.includes(playlist.id)) {
            newRemovedPlaylistIds.splice(newRemovedPlaylistIds.indexOf(playlist.id), 1);
          } else {
            newRemovedPlaylistIds.push(playlist.id);
          }

          return newRemovedPlaylistIds;
        });
      } else {
        setSelectedPlaylistIds((prevState) => {
          const newSelectedPlaylistIds = [...prevState];

          if (newSelectedPlaylistIds.includes(playlist.id)) {
            newSelectedPlaylistIds.splice(newSelectedPlaylistIds.indexOf(playlist.id), 1);
          } else {
            newSelectedPlaylistIds.push(playlist.id);
          }

          return newSelectedPlaylistIds;
        });
      }
    }
  };

  const updatePostInPlaylists = async () => {
    const updatePostInPlaylistsPromises: Promise<AxiosResponse<string, Error>>[] = [];

    if (selectedPlaylistIds.length) {
      updatePostInPlaylistsPromises.push(
        KnowledgeAPI.addPostToManyPlayLists(companyId, article.id, selectedPlaylistIds),
      );
    }
    if (removedPlaylistIds.length) {
      updatePostInPlaylistsPromises.push(
        KnowledgeAPI.removePostFromManyPlayLists(companyId, article.id, removedPlaylistIds),
      );
    }

    try {
      setLoading(true);
      await Promise.all(updatePostInPlaylistsPromises);
      toast.success('Post has been updated in playlist(s).');
      const updateCachePayload = {
        article,
        addToPlaylistIds: selectedPlaylistIds,
        removeFromPlaylistIds: removedPlaylistIds,
      };

      updatePostInPlaylistsCache(updateCachePayload);
      updatePostInPaginatedPlaylistsCache(updateCachePayload);
      updatePostInUserPlaylistsCache(updateCachePayload);
      if (projectId) {
        updatePostInProjectRecommendedPlaylistsCache(updateCachePayload);
        updatePostInProjectPlaylistsCache(updateCachePayload);
      }
      if (stakeholderId) {
        updatePostInStakeholderPlaylistsCache(updateCachePayload);
      }
      updatePostInBookmarkedPlaylistsCache(updateCachePayload);
      closeModal();
    } catch (err) {
      toast.error(catchErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSelectedPlaylistIds([]);
    setRemovedPlaylistIds([]);
    setOpen(false);
  };

  const updateSearch = (value: string) => {
    setSearchKeyword(value.trim());
  };
  const debounceFn = useCallback(debounce(updateSearch, 300), []);

  const playlistsData = useMemo(() => {
    if (searchKeyword) {
      return playlists.filter(
        (playlist) =>
          playlist.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          playlist.createdBy.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          playlist.userNameToDisplay.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }

    return playlists;
  }, [playlists, searchKeyword]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      TransitionComponent={GrowDialogTransition}
      sx={{ '& .MuiPaper-root': { height: '100%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Select Collection</Title>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description" sx={{ p: 0 }}>
        <StickySearchContainer>
          <Search
            value={searchKeyword}
            placeholder="Search Collections"
            setValue={debounceFn}
            filled
            triggerOnEnter
          />
        </StickySearchContainer>

        <HandleOperation
          isLoading={isLoading}
          data={playlistsData}
          noDataComponent={
            <NoDataContainer>
              {searchKeyword && playlistsData.length === 0 ? (
                <>
                  <SearchLineIcon size={120} color={theme.palette.custom.steel} />
                  <Typography component="p" mt={1.5}>
                    No collections found with <b>"{searchKeyword}"</b>
                  </Typography>
                </>
              ) : (
                <>
                  <BookletLineIcon size={120} color={theme.palette.custom.steel} />{' '}
                  <Typography component="p" mt={1.5}>
                    No collections found
                  </Typography>
                </>
              )}
            </NoDataContainer>
          }
          skelton={
            <PlaylistHoverContainer>
              <PlaylistContainer sx={{ px: 1.5, py: 2 }}>
                <Skeleton animation="wave" variant="rounded" width={25} height={25} />
                <Box ml={2}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={200}
                    height={15}
                    sx={{ mb: 0.5 }}
                  />
                  <Box display="flex" alignItems="center">
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={10}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      height={10}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton animation="wave" variant="text" width={100} height={10} />
                  </Box>
                </Box>
              </PlaylistContainer>
              <Divider />
            </PlaylistHoverContainer>
          }
        >
          {playlistsData.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <PlaylistHoverContainer onClick={() => handleChange(item)}>
                  <PlaylistContainer>
                    <Checkbox
                      checked={
                        (item.playListArticleIds.includes(article.id) ||
                          selectedPlaylistIds.includes(item.id)) &&
                        !removedPlaylistIds.includes(item.id)
                      }
                      disabled={loading}
                    />
                    <Box ml={0.5}>
                      <PlaylistTitle>
                        <Link to={VIEW_ARTICLE.replace(':articleId', item.id)} target="_blank">
                          {item.title}
                        </Link>
                      </PlaylistTitle>
                      <PlaylistInfo>
                        {item.playListArticleIds.length} Posts
                        <Dot />
                        <Link
                          to={USER_PROFILE.replace(':userName', item.createdBy)}
                          target="_blank"
                          title="Created By"
                        >
                          {item.userNameToDisplay}
                        </Link>
                        <Dot />
                        <span title="Last Updated">
                          {formatDate(item.lastUpdatedAt || item.publishedAt)}
                        </span>
                      </PlaylistInfo>
                    </Box>
                  </PlaylistContainer>
                  {playlistsData.length - 1 !== index && <Divider />}
                </PlaylistHoverContainer>
              </Fragment>
            );
          })}
        </HandleOperation>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => setOpenAddPlaylistModal(true)}
          startIcon={<AddLineIcon />}
          sx={(theme) => ({ color: theme.palette.custom.link })}
        >
          Add New Collection
        </Button>
        <Button
          disabled={loading || (!selectedPlaylistIds.length && !removedPlaylistIds.length)}
          onClick={updatePostInPlaylists}
          variant="contained"
        >
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save'}
        </Button>
      </DialogActions>

      {openAddPlaylistModal && (
        <AddPostModal
          open={openAddPlaylistModal}
          setOpen={setOpenAddPlaylistModal}
          selectedFeedFilters={selectedFeedFilters}
          createPlaylist
          onSaveCallback={(article: ArticleType) => updatePlaylistsCache({ playlist: article })}
        />
      )}
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const StickySearchContainer = styled(Box, { label: 'StickySearchContainer' })(({ theme }) => ({
  position: 'sticky',
  top: '0',
  backgroundColor: theme.palette.custom.white,
  zIndex: 1,
  padding: '16px 24px',
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '40px 24px 0',
});

const PlaylistHoverContainer = styled(Box, { label: 'PlaylistHoverContainer' })(({ theme }) => ({
  padding: '0 24px',
  '&:hover': {
    backgroundColor: theme.palette.custom.whiteSmoke,
    transition: '0.5s',
  },
}));

const PlaylistContainer = styled(Box, { label: 'PlaylistContainer' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 0',
  cursor: 'pointer',
}));

const PlaylistTitle = styled(Typography, { label: 'PlaylistTitle' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.custom.tuna,
  textTransform: 'capitalize',
}));

const Link = styled(RouterLink, { label: 'Link' })(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

const PlaylistInfo = styled(Typography, { label: 'PlaylistInfo' })(({ theme }) => ({
  fontWeight: 400,
  fontSize: 13,
  color: theme.palette.custom.mistBlue,
}));

export default AddPostToPlaylistsModal;
