import { styled } from '@mui/material/styles';
import usePromptBookmarksQuery from './hooks/usePromptBookmarksQuery';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import HandleOperation from 'components/utils/HandleOperation';
import PromptHistory from './PromptHistory';
import { ReactComponent as BookmarkLineIcon } from 'assets/icons/bookmarks.svg';
import RenderChatDate from './RenderChatDate';
import { useMemo, useState } from 'react';
import { filterHistoryByDate } from './hooks/useBuildWiseGPT';
import UserAPI from 'api/UserAPI';
import useUserData, { UserType } from 'hooks/useUserData';
import { updateUserDetailsCache } from 'pages/profile/hooks/useUserProfileData';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { BuildWiseGPTHistoryItem } from './hooks/useBuildWiseGPTHistoryQuery';
import { useNavigate } from 'react-router-dom';
import { BUILDWISE_GPT_CHAT } from 'constants/routes';

function PromptBookmarks() {
  const { user } = useUserData();
  const navigate = useNavigate();
  const {
    bookmarks: bookmarksData,
    isLoading,
    updatePromptBookmarksCache,
  } = usePromptBookmarksQuery();
  const [collpasedDates, setCollapsedDates] = useState<string[]>([]);
  const bookmarks = useMemo(() => filterHistoryByDate(bookmarksData), [bookmarksData]);

  const bookmarkPrompt = async (prompt: BuildWiseGPTHistoryItem) => {
    const oldUser = { ...user };
    try {
      const updatedPromptIds = [...(user?.gptPromptIds || [])];
      if (prompt.isBookMarked) {
        updatedPromptIds.splice(updatedPromptIds.indexOf(prompt._id), 1);
      } else {
        updatedPromptIds.push(prompt._id);
      }
      updateUserDetailsCache({ ...user, gptPromptIds: updatedPromptIds } as UserType);
      updatePromptBookmarksCache({
        action: 'delete',
        historyItem: prompt,
      });

      await UserAPI.editUser({
        ...user,
        gptPromptHistoryIds: updatedPromptIds,
      });
    } catch (error) {
      updateUserDetailsCache(oldUser as UserType);
      toast.error(catchErrorMessage(error));
    }
  };

  const regeneratePrompt = (prompt?: string) => {
    navigate(BUILDWISE_GPT_CHAT, { state: { prompt: prompt } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Title>Bookmarks</Title>
        <Description>Your personal collection of bookmarked prompts.</Description>
      </Grid>
      <Grid item xs={12}>
        <HandleOperation
          isLoading={isLoading}
          data={bookmarks}
          noDataComponent={
            <NoDataContainer>
              <BookmarkLineIcon />
              <NoDataDescription mt={1.5}>You currently have no bookmarks.</NoDataDescription>
            </NoDataContainer>
          }
        >
          {bookmarks.map((item) => {
            return (
              <Box
                sx={(theme) => ({ borderTop: `1px solid ${theme.palette.custom.divider}`, mt: 4 })}
              >
                <RenderChatDate
                  date={item.date}
                  collapsedDates={collpasedDates}
                  setCollapsedDates={setCollapsedDates}
                />
                <Collapse in={!collpasedDates.includes(item.date)}>
                  {item.data.map((chat) => (
                    <PromptHistory
                      item={chat}
                      bookmarkPrompt={bookmarkPrompt}
                      regeneratePrompt={regeneratePrompt}
                    />
                  ))}
                </Collapse>
              </Box>
            );
          })}
        </HandleOperation>
      </Grid>
    </Grid>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  color: theme.palette.custom.blueZodiac,
  fontWeight: 700,
  fontSize: 25,
}));

const Description = styled(Typography, { label: 'Description' })(({ theme }) => ({
  color: theme.palette.custom.blueZodiac,
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '40px 24px 0',
});

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default PromptBookmarks;
