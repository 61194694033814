import { useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '../libs/services/endpoints';
import NotificationsAPI from '../api/NotificationsAPI';

export enum NOTIFICATION_TYPE {
  ARTICLE = 'ARTICLE',
  REVISE_ARTICLE = 'REVISE_ARTICLE',
  SHARE_ARTICLE = 'SHARE_ARTICLE',
  LIKE_ARTICLE = 'LIKE_ARTICLE',
  COMMENT = 'COMMENT',
  LIKE_COMMENT = 'LIKE_COMMENT',
  ARTICLE_COMMENT = 'ARTICLE_COMMENT',
  ENDORSE_ARTICLE = 'ENDORSE_ARTICLE',
  ANSWER = 'ANSWER',
  INSIGHT = 'INSIGHT',
  QUESTION = 'QUESTION',
  QUESTION_COMMENT = 'QUESTION_COMMENT',
  ENDORSE_SKILLS = 'ENDORSE_SKILLS',
  FOLLOW_USER = 'FOLLOW_USER',
}

export type NotificationType = {
  articleId: string;
  articlePreview: string;
  articleSubTitle: string;
  articleTitle: string;
  category: string;
  commentId: string;
  companyId: string;
  draft: boolean;
  id: string;
  notificationType: NOTIFICATION_TYPE;
  notifiedBy: string;
  notifiedDate: string;
  notifiedTo: string;
  playerIds: string[];
  questionId: string;
  read: boolean;
  profileImageURL?: string;
};

export const useNotificationsQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const queryClient = useQueryClient();

  const { data: notificationsData, isLoading } = useQuery<AxiosResponse<NotificationType[]>, Error>(
    JAVA_SERVICE_ENDPOINTS.GET_NOTIFICATIONS,
    () => NotificationsAPI.getNotifications(),
    {
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      enabled: shouldFetch,
    },
  );
  const notifications = useMemo(() => notificationsData?.data || [], [notificationsData]);

  const updateNotificationsCache = (markAllRead: boolean, notificationId?: string) => {
    queryClient.setQueryData<AxiosResponse<NotificationType[]> | undefined>(
      JAVA_SERVICE_ENDPOINTS.GET_NOTIFICATIONS,
      (oldData: AxiosResponse<NotificationType[]> | undefined) => {
        if (oldData) {
          let newNotifications = [...(oldData.data || [])];

          if (markAllRead) {
            newNotifications = newNotifications.map((notification) => ({
              ...notification,
              read: true,
            }));
          }

          if (!markAllRead && notificationId) {
            const index = newNotifications.findIndex(
              (notification) => notification.id === notificationId,
            );

            if (index > -1) {
              newNotifications[index] = {
                ...newNotifications[index],
                read: true,
              };
            }
          }

          return {
            ...oldData,
            data: newNotifications,
          };
        }

        return oldData;
      },
    );
  };

  return {
    notifications,
    isLoading,
    updateNotificationsCache,
  };
};

export default useNotificationsQuery;
