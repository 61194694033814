import React, { ReactNode, useMemo } from 'react';
import { Box, BoxProps, Button, Link as MuiLink, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from 'components/common/avatar/Avatar';
import { catchErrorMessage, linkToUser } from '../../../utility/helpers';
import Dot from '../dot/Dot';
import UserAPI from 'api/UserAPI';
import { toast } from 'react-toastify';
import { UserType } from 'hooks/useUserData';
import { UserLink } from 'pages/knowledge/components/DraftStatusMessage';

const $UserAvatar = styled(Box, { label: 'UserAvatar' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-body1': {
    lineHeight: '19px',
    '& .MuiLink-root': {
      textDecoration: 'none',
      color: '#1976D2',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  '& .MuiTypography-caption': {
    lineHeight: '18px',
    letterSpacing: '0.0025em',
  },
  '&.small': {
    '& .MuiTypography-body1': {
      lineHeight: '18px',
      fontSize: 15,
      '& strong': {
        fontWeight: 400,
      },
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '17px',
      color: theme.palette.custom.label,
    },
  },
}));

interface UserAvatarPropTypes extends BoxProps {
  actionProps?: {
    name: string;
    onClick: () => void;
  };
  avatarName: string;
  avatarSize?: number;
  disableUserLink?: boolean;
  isSmall?: boolean;
  myUser?: UserType | null;
  profileImageURL?: string;
  routeState?: Record<string, any>;
  showFollowButton?: boolean;
  subTitle2?: string;
  subTitle?: string | ReactNode;
  target?: string;
  title: string;
  user?: UserType | null;
  userName?: string;
  onClick?: () => void;
  updateUserFollowers?: () => void;
}

const UserAvatar: React.FC<UserAvatarPropTypes> = ({
  actionProps,
  avatarName,
  avatarSize,
  disableUserLink = false,
  isSmall = false,
  myUser,
  profileImageURL,
  routeState,
  showFollowButton,
  subTitle,
  subTitle2,
  target,
  title,
  user,
  userName,
  onClick,
  updateUserFollowers,
  ...props
}) => {
  const handleFollow = async () => {
    try {
      if (user?.followers.includes(myUser?.id || '')) {
        await UserAPI.unfollowUser(user?.id);
      } else {
        await UserAPI.followUser(user?.id);
      }
      updateUserFollowers?.();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const FollowButton = useMemo(() => {
    if (user && myUser) {
      if (user?.followers.includes(myUser?.id)) {
        return (
          <Button
            sx={(theme) => ({
              color: theme.palette.custom.link,
              '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
              fontWeight: 'bold',
              px: 0,
              minWidth: 'fit-content',
            })}
            onClick={handleFollow}
          >
            Following
          </Button>
        );
      }
    }
    return (
      <Button
        sx={(theme) => ({
          color: theme.palette.custom.link,
          '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
          fontWeight: 'bold',
          px: 0,
          minWidth: 'fit-content',
        })}
        onClick={handleFollow}
      >
        Follow
      </Button>
    );
  }, [user, myUser]);

  return (
    <$UserAvatar className={`${isSmall ? 'small' : ''} user-avatar`} {...props}>
      <Avatar
        src={profileImageURL}
        size={avatarSize ? avatarSize : isSmall ? 35 : 40}
        fontSize={isSmall ? 18 : 20}
        name={avatarName}
        to={linkToUser(userName)}
      />
      <Box
        paddingLeft="12px"
        display="flex"
        flexDirection="column"
        sx={showFollowButton ? { marginTop: '-12px' } : {}}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          {userName ? (
            <>
              <UserLink
                name={title}
                mail={userName}
                target={target}
                disableUserLink={disableUserLink}
                routeState={routeState}
              />
              {showFollowButton && (
                <>
                  <Dot /> {FollowButton}
                </>
              )}
            </>
          ) : (
            <strong>{title}</strong>
          )}
          {actionProps && (
            <>
              {' '}
              <Dot /> <MuiLink onClick={actionProps.onClick}>{actionProps.name}</MuiLink>
            </>
          )}
        </Typography>
        {subTitle && (
          <Typography variant="caption" sx={showFollowButton ? { marginTop: '-8px' } : {}}>
            {subTitle}
          </Typography>
        )}
        {subTitle2 && <Typography variant="caption">{subTitle2}</Typography>}
      </Box>
    </$UserAvatar>
  );
};

export default UserAvatar;
