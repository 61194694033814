import { FormControlLabel, Checkbox } from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { useFormContext } from 'react-hook-form';

type CheckboxFieldProps = {
  field: ConfigEntryType;
};

function CheckboxField({ field }: CheckboxFieldProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<Record<string, any>>();
  const isChecked = !!watch(field.entryCode);

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(_, checked) =>
            setValue(field.entryCode, checked, { shouldDirty: true, shouldValidate: true })
          }
          checked={isChecked}
          value={field.entryCode}
        />
      }
      label={field.referenceObject?.label || field.entryCode}
      value={field.entryCode}
      disabled={isSubmitting}
    />
  );
}

export default CheckboxField;
