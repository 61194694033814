import {
  FormLabel,
  CircularProgress,
  Stack,
  Chip,
  Box,
  FormControl,
  Autocomplete,
  TextField,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Collapse,
} from '@mui/material';
import { useDivisionSpecSectionsQuery, usePredictDivisionsQuery } from 'hooks/useCompanyQueries';
import _ from 'lodash';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

type PostDivisionsAndSpecSectionsProps = {
  applicationBasedFields: string[];
  isExpanded: boolean;
  isFullScreen: boolean;
  postTitle: string;
};

function PostDivisionsAndSpecSections({
  applicationBasedFields,
  isExpanded,
  isFullScreen,
  postTitle,
}: PostDivisionsAndSpecSectionsProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const selectedDivisionChips = watch('divisionChips');
  const selectedDivisions = watch('divisions');
  const selectedSpecSectionChips = watch('specSectionChips');
  const selectedSpecSections = watch('specSections');

  // divisions state
  const { divisions: divisionsOptions, isLoading: divisionsLoading } = usePredictDivisionsQuery({
    query: postTitle,
    shouldFetch: isExpanded && !!postTitle,
  });
  const { divisionChipsOptions, divisionsSelectOptions } = useMemo(() => {
    const allDivisions = _.uniq([
      ...(selectedDivisionChips || []),
      ...divisionsOptions,
      ...(selectedDivisions || []),
    ]);
    const divisionChipsOptions: string[] = allDivisions.slice(0, 5);
    const divisionsSelectOptions: string[] = [
      ...divisionChipsOptions.filter((division) => selectedDivisions?.includes(division)),
      ...allDivisions.slice(5),
    ];

    return {
      divisionChipsOptions,
      divisionsSelectOptions,
    };
  }, [divisionsOptions]);
  useEffect(() => {
    if (divisionsOptions) {
      const divisionChipValues: string[] = [];
      divisionsOptions.slice(0, 5).forEach((division) => {
        if (selectedDivisions?.includes(division)) {
          divisionChipValues.push(division);
        }
      });
      if (divisionChipValues.length) {
        setValue('divisionChips', divisionChipValues);
        setValue(
          'divisions',
          selectedDivisions?.filter((division) => !divisionChipValues.includes(division)),
        );
      }
    }
  }, [selectedDivisions, divisionsOptions]);
  const prevDivisionCodeRef = useRef<string>('');
  const divisionCode = useMemo(() => {
    const prevDivCodes = prevDivisionCodeRef.current ? prevDivisionCodeRef.current.split('-') : [];
    const divisionChipsCodes =
      selectedDivisionChips?.map((division) => division?.split?.('-')?.[0]?.trim() || '') || [];
    const divisionCodes =
      selectedDivisions?.map((division) => division?.split?.('-')?.[0]?.trim() || '') || [];

    return _.uniq([...prevDivCodes, ...divisionChipsCodes, ...divisionCodes]).join('-');
  }, [selectedDivisionChips, selectedDivisions]);
  const isSpecSectionsQueryHashChanged = useMemo(
    () =>
      divisionCode?.length >= prevDivisionCodeRef.current?.length &&
      divisionCode !== prevDivisionCodeRef.current,
    [divisionCode],
  );

  // spec sections state
  const {
    specSections,
    isLoading: specSectionsLoading,
    isFetching: specSectionsFetching,
  } = useDivisionSpecSectionsQuery(
    divisionCode,
    isSpecSectionsQueryHashChanged && applicationBasedFields.includes('specSections'),
  );
  const [specSectionsOptions, setSpecSectionsOptions] = useState(specSections);
  const { specSectionChipsOptions, specSectionsSelectOptions } = useMemo(() => {
    const allSpecSections = _.uniq([
      ...(selectedSpecSectionChips || []),
      ...specSectionsOptions,
      ...(selectedSpecSections || []),
    ]);
    const specSectionChipsOptions: string[] = allSpecSections.slice(0, 5);
    const specSectionsSelectOptions: string[] = [
      ...specSectionChipsOptions.filter((specSection) =>
        selectedSpecSections?.includes(specSection),
      ),
      ...allSpecSections.slice(5),
    ];

    return {
      specSectionChipsOptions,
      specSectionsSelectOptions,
    };
  }, [specSectionsOptions]);
  useEffect(() => {
    if (specSectionsOptions) {
      const specSectionChipValues: string[] = [];
      specSectionsOptions.slice(0, 5).forEach((specSection) => {
        if (selectedSpecSections?.includes(specSection)) {
          specSectionChipValues.push(specSection);
        }
      });

      if (specSectionChipValues.length) {
        setValue('specSectionChips', specSectionChipValues);
        setValue(
          'specSections',
          selectedSpecSections?.filter(
            (specSection) => !specSectionChipValues.includes(specSection),
          ),
        );
      }
    }
  }, [selectedSpecSections, specSectionsOptions]);

  useEffect(() => {
    if (isSpecSectionsQueryHashChanged) {
      setSpecSectionsOptions(specSections);
    }
  }, [specSections, isSpecSectionsQueryHashChanged]);

  useEffect(() => {
    prevDivisionCodeRef.current = divisionCode || '';
  }, [divisionCode]);

  const handleSelectDivisions = (
    _: unknown,
    divisions: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>,
  ) => {
    if (reason === 'removeOption' && details?.option) {
      setSpecSectionsOptions((prevState) => {
        const newSpecSections = [...prevState];

        const removedDivisionCode = details.option.split('-')[0].trim();

        const specSectionChipIndex = selectedSpecSectionChips?.find((specSection) =>
          specSection?.startsWith(removedDivisionCode),
        );
        if (specSectionChipIndex) {
          return newSpecSections;
        }

        const specSectionSelectIndex = selectedSpecSections?.find((specSection) =>
          specSection?.startsWith(removedDivisionCode),
        );
        if (specSectionSelectIndex) {
          return newSpecSections;
        }

        if (divisions.length === 0 && selectedDivisionChips?.length === 0) {
          return [];
        }

        return newSpecSections.filter(
          (specSection) => !specSection.startsWith(removedDivisionCode),
        );
      });
    }

    setValue('divisions', divisions, { shouldDirty: true, shouldValidate: true });

    if (divisions.length === 0 && selectedDivisionChips?.length === 0) {
      setValue('specSectionChips', [], { shouldDirty: true, shouldValidate: true });
      setValue('specSections', [], { shouldDirty: true, shouldValidate: true });
      setSpecSectionsOptions([]);
    }
  };

  const handleSelectSpecSections = (_: unknown, specSections: string[]) => {
    setValue('specSections', specSections, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <>
      <Collapse
        in={
          !!postTitle ||
          (selectedDivisionChips && selectedDivisionChips?.length > 0) ||
          (selectedDivisions && selectedDivisions?.length > 0)
        }
        aria-disabled={isSubmitting}
      >
        {(postTitle ||
          (selectedDivisionChips && selectedDivisionChips?.length > 0) ||
          (selectedDivisions && selectedDivisions?.length > 0)) && (
          <>
            <FormLabel sx={{ display: 'flex', fontWeight: 700, mb: 1 }}>
              Select Divisions &nbsp;
              {divisionsLoading && (
                <CircularProgress
                  sx={(theme) => ({ color: theme.palette.custom.black })}
                  size={20}
                />
              )}
            </FormLabel>

            <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
              {divisionChipsOptions.map((division) => (
                <Chip
                  key={division}
                  disabled={isSubmitting}
                  onClick={() => {
                    if (selectedDivisionChips?.includes(division)) {
                      const newDivisionCips =
                        selectedDivisionChips?.filter(
                          (selectedDivision) => selectedDivision !== division,
                        ) || [];
                      setValue('divisionChips', newDivisionCips, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });

                      if (
                        (!selectedDivisions || selectedDivisions?.length === 0) &&
                        newDivisionCips &&
                        newDivisionCips?.length === 0
                      ) {
                        setValue('specSectionChips', [], {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue('specSections', [], { shouldDirty: true, shouldValidate: true });
                        setSpecSectionsOptions([]);
                      } else {
                        setSpecSectionsOptions((prevState) => {
                          const newSpecSections = [...prevState];

                          const removedDivisionCode = division?.split('-')[0].trim();

                          const specSectionChipIndex = selectedSpecSectionChips?.find(
                            (specSection) => specSection?.startsWith(removedDivisionCode),
                          );
                          if (specSectionChipIndex) {
                            return newSpecSections;
                          }

                          const specSectionSelectIndex = selectedSpecSections?.find((specSection) =>
                            specSection?.startsWith(removedDivisionCode),
                          );
                          if (specSectionSelectIndex) {
                            return newSpecSections;
                          }

                          return newSpecSections.filter(
                            (specSection) => !specSection.startsWith(removedDivisionCode),
                          );
                        });
                      }
                    } else {
                      setValue('divisionChips', [...(selectedDivisionChips || []), division], {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  label={division}
                  variant={selectedDivisionChips?.includes(division) ? 'filled' : 'outlined'}
                  size="small"
                  sx={(theme) => ({
                    mr: 1,
                    mb: 1,
                    cursor: 'pointer',
                    '&.MuiChip-filled': {
                      backgroundColor: theme.palette.custom.mulledWine,
                      color: theme.palette.custom.white,
                    },
                  })}
                />
              ))}
            </Stack>
          </>
        )}
      </Collapse>
      <Collapse in={divisionsOptions.length > 0 && applicationBasedFields.includes('specSections')}>
        <Box width={isFullScreen ? '25%' : '50%'}>
          {divisionsOptions.length > 0 && (
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <Autocomplete
                multiple
                limitTags={3}
                disabled={isSubmitting}
                onChange={handleSelectDivisions}
                options={divisionsSelectOptions}
                autoHighlight
                value={selectedDivisions || []}
                disableCloseOnSelect
                noOptionsText={divisionsLoading ? 'Loading...' : 'No Divisions Found'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select More Divisions"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {divisionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option}
                      deleteIcon={<CloseLineIcon size={20} />}
                    />
                  ))
                }
              />
            </FormControl>
          )}
        </Box>
      </Collapse>
      <Collapse
        in={
          selectedDivisionChips?.length > 0 ||
          selectedDivisions?.length > 0 ||
          selectedSpecSections?.length > 0
        }
        aria-disabled={isSubmitting}
      >
        {(selectedDivisionChips?.length > 0 ||
          selectedDivisions?.length > 0 ||
          selectedSpecSections?.length > 0) && (
          <>
            <FormLabel sx={{ display: 'flex', fontWeight: 700, mb: 1 }}>
              Select Spec Sections &nbsp;
              {(specSectionsLoading || specSectionsFetching) && (
                <CircularProgress
                  sx={(theme) => ({ color: theme.palette.custom.black })}
                  size={20}
                />
              )}
            </FormLabel>

            <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
              {specSectionChipsOptions.map((specSection) => (
                <Chip
                  key={specSection}
                  disabled={isSubmitting}
                  onClick={() => {
                    if (selectedSpecSectionChips?.includes(specSection)) {
                      setValue(
                        'specSectionChips',
                        selectedSpecSectionChips?.filter(
                          (selectedDivision) => selectedDivision !== specSection,
                        ),
                        { shouldDirty: true, shouldValidate: true },
                      );
                    } else {
                      setValue(
                        'specSectionChips',
                        [...(selectedSpecSectionChips || []), specSection],
                        { shouldDirty: true, shouldValidate: true },
                      );
                    }
                  }}
                  label={specSection}
                  variant={selectedSpecSectionChips?.includes(specSection) ? 'filled' : 'outlined'}
                  size="small"
                  sx={(theme) => ({
                    mr: 1,
                    mb: 1,
                    cursor: 'pointer',
                    '&.MuiChip-filled': {
                      backgroundColor: theme.palette.custom.mulledWine,
                      color: theme.palette.custom.white,
                    },
                  })}
                />
              ))}
            </Stack>
            <Box width={isFullScreen ? '25%' : '50%'}>
              <FormControl fullWidth sx={{ marginBottom: 3 }}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  disabled={isSubmitting}
                  disableCloseOnSelect
                  onChange={handleSelectSpecSections}
                  options={specSectionsSelectOptions}
                  autoHighlight
                  value={selectedSpecSections || []}
                  noOptionsText={specSectionsLoading ? 'Loading...' : 'No Spec Sections Found'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select More Spec Sections"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {specSectionsLoading || specSectionsFetching ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option}
                        deleteIcon={<CloseLineIcon size={20} />}
                      />
                    ))
                  }
                />
              </FormControl>
            </Box>
          </>
        )}
      </Collapse>
    </>
  );
}

export default PostDivisionsAndSpecSections;
