import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridColDef, GridRowId, DataGridProps } from '@mui/x-data-grid';
import { useState } from 'react';

const $DataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiSelect-select': {
    fontSize: '0.79rem !important',
  },
}));

type DataTableProps<T> = {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  height?: number;
  pageSize?: number;
  rows: T[];
  selections?: any;
  setRowId?: React.Dispatch<React.SetStateAction<any>>;
  setSelections?: React.Dispatch<React.SetStateAction<GridRowId[]>>;
} & DataGridProps;

function DataTable<T extends object>({
  checkboxSelection = true,
  columns,
  height,
  pageSize,
  rows,
  selections,
  setRowId,
  setSelections,
  ...rest
}: DataTableProps<T>) {
  const [rowsSize, setRowsSize] = useState(pageSize || 10);

  const handlePageSizeChange = (pageSize: number) => {
    setRowsSize(pageSize);
  };

  return (
    <Box
      sx={{
        height: height ? height : 420,
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: '#1976D2',
        },
      }}
    >
      <$DataGrid
        {...rest}
        rows={rows}
        columns={columns}
        pageSize={rowsSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 20]}
        components={{ Toolbar: GridToolbar }}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            quickFilterProps: { debounceMs: 500 },
            ...rest.componentsProps?.toolbar,
          },
        }}
        onCellEditCommit={(params) => {
          return setRowId?.(params.id);
        }}
        onSelectionModelChange={(selections) => {
          setSelections?.(selections);
        }}
        selectionModel={selections}
      />
    </Box>
  );
}

export default DataTable;
