import { useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import useUserData from '../../../hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '../../../libs/services/endpoints';
import { AxiosResponse } from 'axios';
import CompanyAPI from '../../../api/CompanyAPI';

type ConfigType = {
  configEntryRefMap?: Record<string, any>;
  entryCode: string;
  entryDescription: string;
  isActive: boolean;
};

type ConfigResponse = {
  config: {
    configName: string;
    createdAt?: string | Date;
    createdBy?: string;
    description: string;
    entries: ConfigType[];
    id: string;
  };
};

export const useTopicsQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: entriesData, isLoading } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_TOPICS, companyId],
    () => CompanyAPI.getTopics(companyId),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const topics = useMemo(
    () => entriesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [],
    [entriesData],
  );

  return {
    topics,
    isLoading,
  };
};

export const useDivisionsQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: entriesData, isLoading } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_DIVISIONS.replace(':companyId', companyId)],
    () => CompanyAPI.getDivisions(companyId),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const entries = useMemo(() => {
    return (
      entriesData?.data?.config?.entries?.map(
        (entry) => entry.entryCode + ' - ' + entry.entryDescription,
      ) || []
    );
  }, [entriesData]);

  return {
    entries,
    isLoading,
  };
};

export const useDepartmentsQuery = () => {
  const { companyId } = useUserData();

  const { data: entriesData, isLoading } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_DEPARTMENTS, companyId],
    () => CompanyAPI.getDepartments(companyId),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId,
    },
  );

  const departments = useMemo(() => {
    const newDepartments =
      entriesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [];

    const index = newDepartments.indexOf('Other');
    if (index > -1) {
      newDepartments.splice(index, 1);
      newDepartments.push('Other');
    }

    return newDepartments;
  }, [entriesData]);

  return {
    departments,
    isLoading,
  };
};

export const useProjectPhasesQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_PROJECT_PHASES.replace(':companyId', companyId)],
    () => CompanyAPI.getProjectPhases(companyId),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const projectPhases = useMemo(() => {
    return entriesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [];
  }, [entriesData]);

  return {
    projectPhases,
    ...rest,
  };
};

export const useSuggestedSkills = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_SUGGESTED_EXPERT_SKILLS, companyId],
    () => CompanyAPI.getSuggestedExpertSkills(companyId),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const suggestedSkills = useMemo(() => {
    return entriesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [];
  }, [entriesData]);

  return {
    suggestedSkills,
    ...rest,
  };
};

export const useSuggestedHobbies = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_SUGGESTED_HOBBIES, companyId],
    () => CompanyAPI.getSuggestedHobbies(companyId),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const suggestedHobbies = useMemo(() => {
    return entriesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [];
  }, [entriesData]);

  return {
    suggestedHobbies,
    ...rest,
  };
};

export const useBuildWiseModulesQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const { companyId } = useUserData();
  const queryClient = useQueryClient();

  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_MODULES, companyId],
    () => CompanyAPI.getBuildWiseModules(companyId),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const { buildwiseModules, config } = useMemo(
    () => ({
      buildwiseModules: entriesData?.data?.config?.entries || [],
      config: entriesData?.data?.config,
    }),
    [entriesData],
  );

  const updateConfigCache = ({ config }: { config: ConfigResponse }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_MODULES,
      companyId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigResponse, Error> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_MODULES, companyId],
        (old) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                ...config,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    buildwiseModules,
    config,
    ...rest,
    updateConfigCache,
  };
};
