import { RecommendedPosts } from '@buildwise/libs/home';
import PersonalPostsNote from './PersonalPostsNote';

function PersonalisedPosts() {
  return (
    <>
      <PersonalPostsNote />
      <RecommendedPosts />
    </>
  );
}

export default PersonalisedPosts;
