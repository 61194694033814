import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import {
  ADMIN,
  BUILDWISE_ADMIN,
  BUILDWISE_GPT_BASE,
  COMPANY_ADMIN,
  USER_PROFILE,
} from '../constants/routes';
import moment from 'moment';
import { RoleType, UserType } from 'hooks/useUserData';
import {
  ATTACHMENT_TYPES,
  AUDIO_TYPES,
  DOCUMENT_TYPES,
  IMAGE_TYPES,
  VIDEO_TYPES,
} from 'components/utils/constants';
import { toast } from 'react-toastify';
import { ArticleUserMapType } from 'pages/article/hooks/useArticleDetailsQuery';
import { LLMResponseEngagementType } from 'api/KnowledgeAPI';
import _ from 'lodash';
import { BuildWiseGPTHistoryItem } from '@buildwise/pages/buildwiseAdmin/BuildWiseGPT/hooks/useBuildWiseGPTHistoryQuery';
import { Parser } from 'htmlparser2';

export const catchErrorMessage = (e: any) =>
  e?.response
    ? e.response?.data?.errorPresent && e.response.data.errorMessages?.length > 0
      ? e?.response?.data?.errorMessages?.[0].errorMessage
      : e?.response?.data?.errorResponse?.errorMessages?.length > 0
        ? e?.response?.data?.errorResponse?.errorMessages?.[0]?.errorMessage
        : e?.message
    : e?.message;

export const getAdminRoutePath = (path: string) => {
  return path.replace(ADMIN, '');
};
export const getCompanyAdminRoutePath = (path: string) => {
  return path.replace(COMPANY_ADMIN, '');
};
export const getBuildWiseAdminRoutePath = (path: string) => {
  return path.replace(BUILDWISE_ADMIN, '');
};
export const getBuildWiseGPTRoutePath = (path: string) => {
  return path.replace(BUILDWISE_GPT_BASE, '');
};

export const getValueHelper = (value, options) => {
  if (!!options?.length && typeof value === 'string') {
    const index = options.findIndex((o) => o.value === value);
    return index > -1 ? options[index] : value;
  }
  return value;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
export const isValidImage = (type) => {
  return validImageTypes.includes(type);
};

const validVideoTypes = ['video/x-flv', 'video/mp4', 'video/quicktime', 'video/x-msvideo'];
export const isValidVideo = (type) => {
  return validVideoTypes.includes(type);
};

export function getVideoCover(file: File, seekTo = 0.5): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      reject('Error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('Video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */,
        );
      });
    });
  });
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const isValidEmail = (email: string) =>
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const formatDate = (date: Date | string | null | undefined) => {
  if (!date) return '';
  const _date = moment(date);
  const diffMinutes = moment().diff(_date, 'minutes');
  if (diffMinutes < 60) {
    return `${diffMinutes || 1} ${diffMinutes <= 1 ? 'minute' : 'minutes'} ago`;
  }
  const diffHours = moment().diff(_date, 'hours');
  if (diffHours < 24) {
    return `${diffHours} ${diffHours <= 1 ? 'hour' : 'hours'} ago`;
  }
  return _date.format('MMM D, YYYY');
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const checkIfImage = (type) => {
  let _type = type.toLowerCase();
  return _type === 'jpg' || _type === 'jpeg' || _type === 'png';
};

export const linkToUser = (userName) =>
  !userName ? '' : USER_PROFILE.replace(':userName', userName);

export const preventEnterPressed = (e) => {
  if (e?.keyCode === 13) {
    e.preventDefault();
  }
};

export const getMentionedPattern = (displayName, id) => {
  return `@[${displayName}](${id})`;
};

export const mentionParser = (markup) => {
  return [...markup.matchAll(/@\[(.*?)]\((.*?)\)/gm)].reduce((a, v) => {
    a.push({ displayName: v[1], userName: v[2] });
    return a;
  }, []);
};

export const replaceMentions = (text, mentions) => {
  let _text = text;
  if (!!mentions?.length) {
    mentions.forEach((mention) => {
      const mentionedText = getMentionedPattern(mention.displayName, mention.userName);
      _text = _text.replace(mentionedText, `<span>${mention.displayName}</span>`);
    });
  }
  const _mentions = mentionParser(_text);
  _mentions.forEach((mention) => {
    const mentionedText = getMentionedPattern(mention.displayName, mention.userName);
    _text = _text.replace(mentionedText, `<span>${mention.displayName}</span>`);
  });
  return _text;
};

export const preventComma = (e) => {
  if (e.key === ',') {
    e.preventDefault();
  }
};

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater than seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

type PageScrollInfo = {
  lastViewedIndex: number | string;
  scrolledToIndex: number;
};

export const getInsightColor = (
  type: 'RFI' | 'Spec' | 'Submittal' | 'FilteredRFI' | 'Meeting Minutes',
) => {
  if (type === 'RFI' || type === 'FilteredRFI' || type === 'Meeting Minutes') {
    return '#B6E8FC';
  }

  if (type === 'Submittal') {
    return '#F3D9FF';
  }

  return '#FFF2D9';
};

export const handlePageScrollPosition = (
  tab: string,
  lastViewedIndex: string | number,
  scrolledToIndex: number,
  action?: 'reset',
) => {
  if (tab === 'all' && action === 'reset') {
    sessionStorage.setItem('pagescrollinfo', JSON.stringify({}));
  } else {
    let pageScrollInfo: Record<string, PageScrollInfo> = {
      ...(JSON.parse(String(sessionStorage.getItem('pagescrollinfo'))) || {}),
    };

    if (tab === 'search-tabs' && action === 'reset') {
      pageScrollInfo['searched-articles'] =
        pageScrollInfo['searched-users'] =
        pageScrollInfo['searched-projects'] =
        pageScrollInfo['searched-insights'] =
        pageScrollInfo['searched-stakeholders'] =
          {
            lastViewedIndex: 0,
            scrolledToIndex: 0,
          };
    } else {
      pageScrollInfo[tab] = {
        lastViewedIndex: action === 'reset' ? 0 : lastViewedIndex,
        scrolledToIndex: action === 'reset' ? 0 : scrolledToIndex,
      };
    }

    sessionStorage.setItem('pagescrollinfo', JSON.stringify(pageScrollInfo));
  }
};

export const getPageScrollInfo = <T>(tab: string, data: T[], findByKey: string) => {
  const pageScrollInfo: Record<string, PageScrollInfo> = {
    ...(JSON.parse(String(sessionStorage.getItem('pagescrollinfo'))) || {}),
  };

  let lastViewedIndex = -1;
  if (pageScrollInfo[tab]?.lastViewedIndex) {
    lastViewedIndex = data?.findIndex(
      (item) => item[findByKey] === pageScrollInfo[tab]?.lastViewedIndex,
    );
  }

  return lastViewedIndex !== -1 ? lastViewedIndex : pageScrollInfo[tab]?.scrolledToIndex || 0;
};

export const markAsViewed = (id: string) => {
  const itemsViewed = JSON.parse(String(sessionStorage.getItem('itemsviewed'))) || [];
  if (!itemsViewed.includes(id)) {
    itemsViewed.push(id);
  }
  sessionStorage.setItem('itemsviewed', JSON.stringify(itemsViewed));
};

export const removeAsViewed = (id: string) => {
  const itemsViewed = JSON.parse(String(sessionStorage.getItem('itemsviewed'))) || [];
  const index = itemsViewed.indexOf(id);
  if (index > -1) {
    itemsViewed.splice(index, 1);
  }

  sessionStorage.setItem('itemsviewed', JSON.stringify(itemsViewed));
};

export const shouldUpdateViewCount = (id: string) => {
  const itemsViewed = JSON.parse(String(sessionStorage.getItem('itemsviewed'))) || [];

  return !itemsViewed.includes(id);
};

export const getAvatarBgColor = (name: string, saturation = 30, lightness = 50) => {
  let hash = 0;
  for (var i = 0; i < name?.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};

export const numberExponentToLarge = (n) => {
  var sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
};

export const exponentialToDecimal = (exponential) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes('e+')) {
    const exponentialSplitted = decimal.split('e+');
    let postfix = '';
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0);
      i++
    ) {
      postfix += '0';
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
  }
  if (decimal.toLowerCase().includes('e-')) {
    const exponentialSplitted = decimal.split('e-');
    let prefix = '0.';
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += '0';
    }
    decimal = prefix + exponentialSplitted[0].replace('.', '');
  }
  return decimal;
};

export const groupBy = (array, keyFunction) => {
  const groups = {};
  array.forEach(function (el) {
    const key = keyFunction(el);
    if (key in groups === false) {
      groups[key] = [];
    }
    groups[key].push(el);
  });
  return groups;
};

export const formatFullNumber = (num: number) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num);

export const formatNumber = function (num) {
  // Nine Zeroes for Billions
  return Math.abs(Number(num)) >= 1.0e9
    ? (Math.abs(Number(num)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
      Math.abs(Number(num)) >= 1.0e6
      ? (Math.abs(Number(num)) / 1.0e6).toFixed(2) + 'M'
      : // Three Zeroes for Thousands
        Math.abs(Number(num)) >= 1.0e3
        ? (Math.abs(Number(num)) / 1.0e3).toFixed(2) + 'K'
        : Math.abs(Number(num));
};

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isNovaOrCompanyAdminRole = (roles?: RoleType[]) => {
  return Boolean(
    roles?.find(
      (role) => role.roleName === 'ROLE_NOVA_ADMIN' || role.roleName === 'ROLE_COMPANY_ADMIN',
    ),
  );
};

export const isNovaAdminRole = (roles?: RoleType[]) => {
  return Boolean(roles?.find((role) => role.roleName === 'ROLE_NOVA_ADMIN'));
};

export const getExactFileName = (file: AttachmentType | undefined) => {
  if (!file) {
    return '';
  }
  if (file?.fileUploaded) {
    return (
      file?.fileName.substring(file?.fileName.indexOf('_', file?.fileName.indexOf('_') + 1) + 1) ||
      ''
    );
  }

  return file?.fileName || '';
};

export const attachFiles = async (
  newAttachments: File[],
  attachments: AttachmentType[],
  setAttachments: (attachments: AttachmentType[]) => void,
  replace: boolean = false,
) => {
  let files = [...newAttachments];
  const invalidFiles: string[] = [];

  // filter valid files
  files = files.filter((file) => {
    if (ATTACHMENT_TYPES.includes(file.type)) {
      return true;
    } else {
      invalidFiles.push(file.type);
      return false;
    }
  });

  if (invalidFiles.length > 0) {
    setAttachments(attachments);
    return toast.error(`File types ${invalidFiles.join(', ')} are not allowed`);
  }

  files = renameDuplicateFiles(files, attachments);
  let updatedAttachments = [...attachments];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    try {
      const attachment = {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        articleOpenUrl: '',
        thumbnailOpenUrl: '',
        thumbnail: file,
        file,
      } as AttachmentType;

      if (AUDIO_TYPES.includes(file.type) || DOCUMENT_TYPES.includes(file.type)) {
        attachment.thumbnail = null;
      }

      if (DOCUMENT_TYPES.includes(file.type)) {
        attachment.articleOpenUrl = URL.createObjectURL(file);
        attachment.thumbnailOpenUrl = URL.createObjectURL(file);
      }

      if (IMAGE_TYPES.includes(file.type)) {
        const fileContent = await getFileContent(file);
        attachment.articleOpenUrl = fileContent as string;
        attachment.thumbnailOpenUrl = fileContent;
      }

      if (VIDEO_TYPES.includes(file.type)) {
        try {
          const videoCover = await getVideoCover(file);
          if (videoCover) {
            attachment.thumbnail = new File([videoCover], `${file.name}.jpeg`, {
              type: 'image/jpeg',
              lastModified: new Date().getTime(),
            });

            attachment.articleOpenUrl = URL.createObjectURL(file);
            attachment.thumbnailOpenUrl = URL.createObjectURL(videoCover);
          }
        } catch (error) {
          console.warn('GENERATE_VIDEO_THUMBNAIL_ERROR', error);
        }
      }

      if (replace) {
        updatedAttachments = [attachment];
      } else {
        updatedAttachments = [...updatedAttachments, attachment];
      }
    } catch (error) {
      console.log('LOCAL_FILE_NOT_LOADED_ERROR', error);
    }
  }

  setAttachments(updatedAttachments);
};

const getFileContent = async (file: File) => {
  const reader = new FileReader();

  return new Promise<string | ArrayBuffer | null | undefined>((resolve, reject) => {
    reader.onload = (progressEvent) => {
      resolve(progressEvent.target?.result);
    };

    reader.onerror = () => {
      reject(`${file.name} couldn't loaded`);
    };

    reader.readAsDataURL(file);
  });
};

const renameDuplicateFiles = (newFiles: File[], oldFiles: AttachmentType[]) => {
  let newFilesData = [...newFiles];
  newFilesData = newFiles.map((newFile) => {
    // check if the file already exists
    const existedFile = oldFiles.find(
      (oldFile) => getExactFileName(oldFile).toLowerCase() === newFile.name.toLowerCase(),
    );

    if (existedFile) {
      return renameFile(newFile, oldFiles, newFile.name);
    }

    return newFile;
  });

  return newFilesData;
};

const renameFile = (
  file: File,
  oldFiles: AttachmentType[],
  baseFileName: string,
  fileCount = 1,
) => {
  const fileTypeIndex = baseFileName.lastIndexOf('.');
  const fileType = baseFileName.substring(fileTypeIndex);
  const newFileName = `${baseFileName.substring(0, fileTypeIndex)}_${fileCount}${fileType}`;
  const fileData = new File([file], newFileName, {
    lastModified: file.lastModified,
    type: file.type,
  });

  const existedFile = oldFiles.find(
    (oldFile) => getExactFileName(oldFile).toLowerCase() === fileData.name.toLowerCase(),
  );

  if (existedFile) {
    return renameFile(fileData, oldFiles, baseFileName, fileCount + 1);
  }

  return fileData;
};

export const highlightSearchkeyWord = (
  result: string,
  searchKeyword?: string,
  addBreak?: boolean,
) => {
  if (searchKeyword) {
    const searchResults = result?.match(new RegExp(searchKeyword || '', 'ig'));
    const replaceAllSearchResults = () => {
      if (searchResults && searchResults?.length > 0) {
        for (const keyword of searchResults) {
          return "<span class='highlight'>" + keyword + '</span>';
        }
      }
      return '';
    };
    return (
      result?.replace(new RegExp(searchKeyword || '', 'ig'), replaceAllSearchResults()) ||
      (addBreak ? '<br/><br/>' : '')
    );
  }

  return result || (addBreak ? '<br/><br/>' : '');
};

export type ExtraFileNameAttachedByServiceExtendsType = { id: string; companyId: string };
export const getExtraFileNameAttachedByService = <
  T extends ExtraFileNameAttachedByServiceExtendsType,
>(
  item: T | undefined,
) => {
  if (item?.id && item?.companyId) {
    return `${item.companyId}_${item.id}_`;
  }

  return '';
};

export const getFileDetails = ({
  file,
  strToSliceFromFileName = '',
  useDefaultStrToSliceFromFileName = false,
}: {
  file: AttachmentType;
  strToSliceFromFileName?: string;
  useDefaultStrToSliceFromFileName?: boolean;
}) => {
  let fileType = file.fileType?.split('/')?.[1] || '';
  let fileName = file.fileName;
  let fileContentType: 'document' | 'img' | 'video' | 'audio' | '' = '';
  let isMediaFile = false;
  let isImage = false;
  let isVideo = false;
  let isAudio = false;
  let isDocument = false;

  // determine file details when it's uploaded
  if (file.fileUploaded) {
    fileType = file.fileType;

    if (useDefaultStrToSliceFromFileName) {
      fileName = file.fileName?.substring(
        file.fileName?.indexOf('_', file.fileName?.indexOf('_') + 1) + 1,
      );
    } else if (strToSliceFromFileName) {
      fileName = file.fileName?.replace(strToSliceFromFileName, '');
    }
  }

  // determine file content type
  if (
    DOCUMENT_TYPES.includes(`application/${fileType?.toLowerCase()}`) ||
    DOCUMENT_TYPES.includes(`text/${fileType === 'txt' ? 'plain' : fileType?.toLowerCase()}`)
  ) {
    fileContentType = 'document';
    isDocument = true;
  }

  if (IMAGE_TYPES.includes(`image/${fileType?.toLowerCase()}`)) {
    fileContentType = 'img';
    isMediaFile = true;
    isImage = true;
  }

  if (AUDIO_TYPES.includes(`audio/${fileType?.toLowerCase()}`)) {
    fileContentType = 'audio';
    isMediaFile = true;
    isAudio = true;
  } else if (VIDEO_TYPES.includes(`video/${fileType?.toLowerCase()}`)) {
    fileContentType = 'video';
    isMediaFile = true;
    isVideo = true;
  }

  return {
    fileType,
    fileName,
    fileContentType,
    isMediaFile,
    isDocument,
    isImage,
    isVideo,
    isAudio,
  };
};

export const splitFilesByType = ({
  files = [],
  extraFileNameAttachedByService = '',
  coverPhotoFileName = '',
}: {
  files: AttachmentType[];
  extraFileNameAttachedByService?: string;
  coverPhotoFileName?: string;
}) => {
  const documents: AttachmentType[] = [];
  const images: AttachmentType[] = [];
  const videos: AttachmentType[] = [];
  const audios: AttachmentType[] = [];
  const mediaFiles: AttachmentType[] = [];

  files.forEach((attachment) => {
    const { fileContentType } = getFileDetails({
      file: attachment,
      strToSliceFromFileName: extraFileNameAttachedByService,
    });

    if (fileContentType === 'document') {
      documents.push(attachment);
    }

    if (fileContentType === 'img') {
      images.push(attachment);
      mediaFiles.push(attachment);
    }

    if (fileContentType === 'video') {
      videos.push(attachment);
      mediaFiles.push(attachment);
    }

    if (fileContentType === 'audio') {
      audios.push(attachment);
      mediaFiles.push(attachment);
    }
  });

  // reorder media files based on coverPhotoFileName
  if (coverPhotoFileName) {
    const mediaFileIndex = mediaFiles.findIndex(
      (attachment) =>
        getFileDetails({
          file: attachment,
          strToSliceFromFileName: extraFileNameAttachedByService,
        }).fileName.toLowerCase() === coverPhotoFileName?.toLowerCase(),
    );

    if (mediaFileIndex > -1) {
      const file = mediaFiles.splice(mediaFileIndex, 1)[0];
      mediaFiles.unshift(file);
    }
  }

  return {
    documents,
    images,
    videos,
    audios,
    mediaFiles,
  };
};

export const getDraftDetails = (
  draft: ArticleType,
  user: UserType | null | undefined,
  userMap?: ArticleUserMapType,
) => {
  const isAssignedToMe = draft.createdBy?.toLowerCase() === user?.username.toLowerCase();

  let assignedBy = '';
  let assignedByMail = '';
  if (isAssignedToMe) {
    assignedBy =
      draft.activityLog?.reverse()?.find((activity) => activity.type === 'Article Transfer')
        ?.userName || '';

    if (assignedBy === 'buildwise.insights@buildwise.ai') {
      assignedBy = 'BuildWise Insights';
      assignedByMail = 'buildwise.insights@buildwise.ai';
    } else {
      assignedByMail = assignedBy;
      assignedBy = userMap?.[assignedBy]?.[0]?.displayName || assignedBy;
    }
  }

  const isAssignedToOther =
    draft.previousOwners?.includes(user?.username || '') && draft.createdBy !== user?.username;
  let assignedTo = '';
  let assignedToMail = '';
  if (isAssignedToOther) {
    assignedTo = draft?.createdBy;
    assignedToMail = draft?.createdBy;
    assignedTo = userMap?.[assignedTo]?.[0]?.displayName || assignedTo;
  }

  return {
    isAssignedToMe,
    assignedBy,
    assignedByMail,
    isAssignedToOther,
    assignedTo,
    assignedToMail,
    isCollaborator: draft?.collaborators?.includes(user?.username || ''),
  };
};

export const getLLMResponseActionStateFromSessionStorage = () => {
  const state: Record<LLMResponseEngagementType, string[]> =
    JSON.parse(String(sessionStorage.getItem('llmResponseActionState'))) || {};

  return state;
};

export const updateLLMResponseActionInSessionStorage = ({
  id,
  engagementType,
  undoAction,
}: {
  id: string;
  engagementType: LLMResponseEngagementType;
  undoAction?: boolean;
}) => {
  const state: Record<LLMResponseEngagementType, string[]> =
    JSON.parse(String(sessionStorage.getItem('llmResponseActionState'))) || {};

  if (state[engagementType]) {
    if (undoAction) {
      const index = state[engagementType].indexOf(id);
      if (index > -1) {
        state[engagementType].splice(index, 1);
      }
    } else {
      state[engagementType].push(id);
    }
  } else {
    state[engagementType] = [id];
  }

  sessionStorage.setItem('llmResponseActionState', JSON.stringify(state));

  return state;
};

export const shortenString = (string: string) => {
  if (string?.length > 60) {
    return string.substring(0, 50) + '...' + string.substring(string.length - 10);
  }

  return string;
};

export const popFirstThreeWords = (paragraph: string) =>
  paragraph.match(/^(\S+\s+){0,2}\S+/)?.[0] || '';

export const generateUUID = () => {
  // Combine random values and timestamps for uniqueness
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16); // convert to hexadecimal string
  const randomString = _.sampleSize(_.range(0, 16), 10)
    .map((v) => v.toString(16))
    .join(''); // generate 10 random hex digits

  // Create a new ObjectId-like string
  return `${timestamp}${randomString}`;
};

export const addChatToSessionStorage = ({
  chatItem,
  companyId,
}: {
  chatItem: BuildWiseGPTHistoryItem;
  companyId: string;
}) => {
  const chat: BuildWiseGPTHistoryItem[] = [
    ...(JSON.parse(String(sessionStorage.getItem(`chat:${companyId}`))) || []),
  ];
  const index = chat.findIndex((item) => item.prompt === chatItem.prompt);

  if (index > -1) {
    chat[index] = {
      ...chat[index],
      ...chatItem,
    };
  } else {
    try {
      chat.push(chatItem);
    } catch (error) {
      deleteChatFromSessionStorage({ chatItem, companyId });
      addChatToSessionStorage({ chatItem, companyId });
    }
  }

  sessionStorage.setItem(`chat:${companyId}`, JSON.stringify(chat));
};

export const getChatFromSessionStorage = ({ companyId }: { companyId: string }) => {
  const chat: BuildWiseGPTHistoryItem[] = [
    ...(JSON.parse(String(sessionStorage.getItem(`chat:${companyId}`))) || []),
  ];

  return chat;
};

export const deleteChatFromSessionStorage = ({
  chatItem,
  companyId,
}: {
  chatItem?: BuildWiseGPTHistoryItem;
  companyId: string;
}) => {
  if (chatItem) {
    const chat: BuildWiseGPTHistoryItem[] = [
      ...(JSON.parse(String(sessionStorage.getItem(`chat:${companyId}`))) || []),
    ];
    chat.splice(0, 1);
    sessionStorage.setItem(`chat:${companyId}`, JSON.stringify(chat));
  } else {
    sessionStorage.removeItem(`chat:${companyId}`);
  }
};

export const checkURLAccessibility = async (url: string) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
};

const base64ToFile = (base64: string, filename: string) => {
  const [metadata, data] = base64.split(',');
  const mimeString = metadata.split(':')[1].split(';')[0];
  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], filename, { type: mimeString });
};

export const extractImagesFromHtml = (content: string) => {
  const imageUrls: string[] = [];
  const parser = new Parser(
    {
      onopentag(name, attributes) {
        if (name === 'img' && attributes.src) {
          imageUrls.push(attributes.src);
        }
      },
    },
    { decodeEntities: true },
  );

  parser.write(content);
  parser.end();

  const updatedAttachments: AttachmentType[] = [];

  for (let i = 0; i < imageUrls.length; i++) {
    const file = base64ToFile(imageUrls[i], `${i}.png`);
    try {
      const attachment = {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        thumbnail: file,
        file,
      } as AttachmentType;

      attachment.articleOpenUrl = imageUrls[i] as string;
      attachment.thumbnailOpenUrl = imageUrls[i];

      updatedAttachments.push(attachment);
    } catch (error) {
      console.log('LOCAL_FILE_NOT_LOADED_ERROR', error);
    }
  }

  return updatedAttachments;
};

