import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { servicesKeys } from './query-keys';
import { ConfigAPI } from '..';
import { ConfigResponseType } from '../apis/ConfigAPI';

function useConfigQuery({
  shouldFetch,
  ...params
}: {
  companyId?: string;
  configName?: string;
  queryParams?: { configType?: string; configCategory?: string };
  shouldFetch?: boolean;
}) {
  const { data: configData, ...rest } = useQuery({
    queryKey: servicesKeys.getConfig(params),
    queryFn: () => ConfigAPI.getConfig(params),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
    enabled: shouldFetch,
  });

  const { config, configs, entries } = useMemo(
    () => ({
      config: configData?.data.config,
      configs: configData?.data?.configs || [],
      entries: configData?.data?.config?.entries || [],
    }),
    [configData],
  );

  const updateConfigCache = ({
    updatedConfig,
    ...params
  }: {
    companyId?: string;
    configName?: string;
    queryParams?: { configType?: string; configCategory?: string };
    updatedConfig: ConfigResponseType;
  }) => {
    const queryStatus = queryClient.getQueryState(servicesKeys.getConfig(params));

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigResponseType, Error> | undefined>(
        servicesKeys.getConfig(params),
        (old) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                ...updatedConfig,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    config,
    configs,
    entries,
    ...rest,
    updateConfigCache,
  };
}

export default useConfigQuery;
