import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { HOME_TABS } from '../constants';
import EndorsedPosts from './EndorsedPosts';
import PopularPosts from './PopularPosts';
import PostsTabs from './PostsTabs';
import RecommendedPosts from './RecommendedPosts';
import LongAddPostButton from './LongAddPostButton';
import { AllPosts, PersonalPostsNote } from '@buildwise/libs/feed';

function PostsCenter() {
  const { hash } = useLocation();
  const tab = decodeURIComponent(hash).replace('#', '') || HOME_TABS[0].value;
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const prevScrollPos = useRef<number>(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, buttonRef]);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos.current) {
      // Scrolling down
      if (buttonRef.current) {
        buttonRef.current.style.position = 'static';
      }
    } else {
      // Scrolling up
      if (buttonRef.current) {
        buttonRef.current.style.position = 'sticky';
      }
    }

    if (currentScrollPos === 0) {
      if (buttonRef.current) {
        buttonRef.current.style.position = 'static';
      }
    }

    prevScrollPos.current = currentScrollPos;
  }, [prevScrollPos, buttonRef]);

  return (
    <>
      <StickyContainer ref={buttonRef} sx={{ top: tab === 'endorsed-posts' ? 140 : 190 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LongAddPostButton />
          </Grid>
        </Grid>
      </StickyContainer>
      <PostsTabs />
      {tab === 'all-posts' && <AllPosts inlineFilters scrollInfoKey="all-posts" />}
      {tab === 'popular-posts' && <PopularPosts />}
      {tab === 'endorsed-posts' && <EndorsedPosts />}
      {tab === 'for-you' && (
        <>
          <PersonalPostsNote />
          <RecommendedPosts />
        </>
      )}
    </>
  );
}

const StickyContainer = styled(Box, { label: 'StickyContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.custom.background,
  zIndex: 1,
  width: '100%',
  padding: '0 0 4px',
  top: 190,
  position: 'static',
}));

export default PostsCenter;
