import { AvatarGroup, Tooltip, Typography, Avatar } from '@mui/material';
import Loader from 'components/common/loader/Loader';
import _ from 'lodash';
import {
  ArticleUserMapDetails,
  ArticleUserMapType,
} from 'pages/article/hooks/useArticleDetailsQuery';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { Suspense, lazy, useMemo, useState } from 'react';
import { getAvatarBgColor } from 'utility/helpers';

const DraftCollaborators = lazy(() => import('pages/knowledge/components/DraftsCollaborators'));

type CollaboratorsProps = {
  article: ArticleType;
  userMap?: ArticleUserMapType;
};

function Collaborators({ article, userMap }: CollaboratorsProps) {
  const [openCollaborators, setOpenCollaborators] = useState(false);
  const collaboratingUsers = useMemo(() => {
    const collaboratorsData = _.uniq([article.createdBy, ...(article?.collaborators || [])]);

    return collaboratorsData.map((username) => {
      const user = userMap?.[username]?.[0] || ({ userName: username } as ArticleUserMapDetails);
      const userType = username === article.createdBy ? 'Owner' : 'Collaborating';
      return { ...user, userType };
    });
  }, []);

  return (
    <>
      {article?.collaborators && article?.collaborators?.length > 0 && (
        <Tooltip
          placement="top"
          componentsProps={{
            tooltip: {
              sx: { padding: '4px 8px', maxWidth: 'fit-content', mb: '4px !important' },
            },
          }}
          title={<Typography fontSize={14}>Collaborators</Typography>}
        >
          <AvatarGroup
            max={3}
            sx={{
              '& .MuiAvatar-root': { width: 30, height: 30, fontSize: 16, cursor: 'pointer' },
            }}
            onClick={() => setOpenCollaborators(true)}
          >
            {collaboratingUsers.map((person) => (
              <Avatar
                key={person.userName}
                src={person?.profileImageURL}
                sx={(theme) => ({
                  width: 30,
                  height: 30,
                  fontSize: 16,
                  backgroundColor: getAvatarBgColor(
                    person?.displayName || person?.userName || '',
                    30,
                    50,
                  ),
                  '&:hover': {
                    borderColor: theme.palette.custom.superNova,
                    transition: '0.4s',
                  },
                })}
              >
                {(person?.displayName || person?.userName).slice(0, 1).toUpperCase()}
              </Avatar>
            ))}
          </AvatarGroup>
        </Tooltip>
      )}

      <Suspense fallback={<Loader variant="fixed" />}>
        {openCollaborators && (
          <DraftCollaborators
            open={openCollaborators}
            setOpen={setOpenCollaborators}
            userMap={userMap}
            collaborators={article?.collaborators || []}
            article={article}
          />
        )}
      </Suspense>
    </>
  );
}

export default Collaborators;
