import { useState, useRef, Dispatch, SetStateAction, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import useUserData from '../../hooks/useUserData';
import Popper from 'components/common/popper/Popper';
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon';
import Avatar from 'components/common/avatar/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { CHAT, MY_PROFILE } from '../../constants/routes';
import { handlePageScrollPosition } from 'utility/helpers';
import NotificationLineIcon from 'remixicon-react/NotificationLineIcon';
import WindowFillIcon from 'remixicon-react/WindowFillIcon';
import NotificationSettingsModal from 'components/modals/notificationSettingsModal/NotificationSettingsModal';
import useCompanyQueryData from 'hooks/useCompanyQueryData';
import Message2LineIcon from 'remixicon-react/Message2LineIcon';

type UserMenuProps = {
  selectedCompanyId: string;
  setOpenSwitchWorkspaceModal: Dispatch<SetStateAction<boolean>>;
};

function UserMenu({ selectedCompanyId, setOpenSwitchWorkspaceModal }: UserMenuProps) {
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openNotificationSettingsModal, setOpenNotificationSettingsModal] = useState(false);
  const {
    companyId: loggedInCompanyId,
    user,
    isNovaAdmin,
    isAssociatedWithOtherCompanies,
    logout,
  } = useUserData({
    shouldFetchCompanyDetails: false,
    shouldFetchUserDetails: false,
    selectedCompanyId,
  });
  const anchorElUser = useRef<HTMLButtonElement | null>(null);
  const hasAccessToChat = useMemo(
    () =>
      isNovaAdmin ||
      ((process.env?.REACT_APP_ENVIRONMENT === 'TEST' ||
        process.env?.REACT_APP_ENVIRONMENT === 'LOCAL') &&
        loggedInCompanyId === '21403'),
    [isNovaAdmin, process.env, loggedInCompanyId],
  );

  const handleOpenUserMenu = () => {
    setOpen(true);
  };

  const handleCloseUserMenu = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    handlePageScrollPosition('all', 0, 0, 'reset');
    logout();
    navigate('/login');
  };

  const handleProfile = () => {
    handleCloseUserMenu();
    navigate(MY_PROFILE);
  };

  return (
    <>
      <Avatar<HTMLButtonElement>
        src={user?.profileImageURL}
        name={user?.userNameToDisplay || 'U'}
        buttonRef={anchorElUser}
        onClick={handleOpenUserMenu}
        size={44}
      />
      <Popper
        open={open}
        onClose={handleCloseUserMenu}
        anchor={anchorElUser.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        <Container>
          <Box
            display="flex"
            sx={{
              paddingBottom: 2,
              px: 2,
            }}
          >
            <Avatar
              size={56}
              src={user?.profileImageURL}
              name={user?.userNameToDisplay}
              to={MY_PROFILE}
            />
            <Box>
              <Box paddingLeft="12px" display="flex" flexDirection="column">
                <Link to={MY_PROFILE}>
                  <Typography component="strong" fontWeight={700}>
                    {user?.userNameToDisplay}
                  </Typography>
                </Link>
                {user?.title && <Typography variant="caption">{user.title}</Typography>}
                {shouldDisplayCompanyOnUserCards &&
                  (user?.primaryCompanyName || user?.userPrimaryCompanyName) && (
                    <Typography variant="caption">
                      {user?.primaryCompanyName || user?.userPrimaryCompanyName}
                    </Typography>
                  )}
                <Button onClick={handleProfile} variant="outlined" sx={{ mt: 1 }}>
                  View Profile
                </Button>
              </Box>
            </Box>
          </Box>

          <Divider sx={(theme) => ({ borderColor: theme.palette.custom.blueChalk, m: 0, mx: 2 })} />
          <MenuList sx={{ padding: 0 }}>
            <MenuItem
              sx={{ mt: 1, background: 'none !important' }}
              onClick={() => setOpenNotificationSettingsModal(true)}
            >
              <ListItemIcon sx={{ minWidth: 'fit-content !important', mr: 1 }}>
                <NotificationLineIcon size={18} />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: 14 }}>Notification Settings</Typography>
              </ListItemText>
            </MenuItem>

            <Divider sx={(theme) => ({ borderColor: theme.palette.custom.blueChalk, mx: 2 })} />
            {hasAccessToChat && (
              <Link to={CHAT}>
                <MenuItem
                  sx={{ pt: 1, background: 'none !important' }}
                  onClick={() => handleCloseUserMenu()}
                >
                  <ListItemIcon sx={{ minWidth: 'fit-content !important', mr: 1 }}>
                    <Message2LineIcon size={18} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: 14 }}>Chat</Typography>
                  </ListItemText>
                </MenuItem>
              </Link>
            )}
            {(isNovaAdmin || isAssociatedWithOtherCompanies) && (
              <MenuItem
                sx={{ pt: 1, background: 'none !important' }}
                onClick={() => setOpenSwitchWorkspaceModal(true)}
              >
                <ListItemIcon sx={{ minWidth: 'fit-content !important', mr: 1 }}>
                  <WindowFillIcon size={18} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: 14 }}>Switch Workspace</Typography>
                </ListItemText>
              </MenuItem>
            )}
            <MenuItem sx={{ mt: 0.5, background: 'none !important' }} onClick={handleLogout}>
              <ListItemIcon
                sx={(theme) => ({
                  minWidth: 'fit-content !important',
                  mr: 1,
                  color: theme.palette.custom.paleCarmine,
                })}
              >
                <LogoutBoxLineIcon size={18} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="caption"
                  sx={(theme) => ({ fontSize: 14, color: theme.palette.custom.paleCarmine })}
                >
                  Sign Out
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Container>
      </Popper>

      {openNotificationSettingsModal && (
        <NotificationSettingsModal
          open={openNotificationSettingsModal}
          setOpen={setOpenNotificationSettingsModal}
        />
      )}
    </>
  );
}

const Container = styled(Box, { label: 'Container' })({
  minWidth: 380,
  padding: '16px 0',
});

export default UserMenu;
