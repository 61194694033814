import { ConfigEntryFieldType, ConfigEntryType } from 'config/config';
import TextInputField from './TextInputField';
import NumberField from './NumberField';
import DateField from './DateField';
import CheckboxField from './CheckboxField';
import RadioField from './RadioField';
import MultiKeywordsField from './MultiKeywordsField';
import PostSelectCustomField from 'components/modals/addPostModal/PostSelectCustomField';
import PostMultiSelectCustomField from 'components/modals/addPostModal/PostMultiSelectCustomField';
import DivisionsField from './DivisionsField';
import SpecSectionsField from './SpecSectionsField';

type RenderFilterFieldByTypeProps = {
  field: ConfigEntryType;
};

function RenderFilterFieldByType({ field }: RenderFilterFieldByTypeProps) {
  if (field.entryCode.toLowerCase() === 'divisions') {
    return (
      <DivisionsField field={field} TextFieldProps={{ variant: 'standard', placeholder: '' }} />
    );
  }

  if (field.entryCode.toLowerCase() === 'specsections') {
    return (
      <SpecSectionsField field={field} TextFieldProps={{ variant: 'standard', placeholder: '' }} />
    );
  }

  switch (field.referenceObject?.type || ConfigEntryFieldType.MULTI_SELECT) {
    case ConfigEntryFieldType.TEXT: {
      return <TextInputField field={field} />;
    }

    case ConfigEntryFieldType.NUMBER: {
      return <NumberField field={field} />;
    }

    case ConfigEntryFieldType.DATE: {
      return <DateField field={field} />;
    }

    case ConfigEntryFieldType.CHECKBOX: {
      return <CheckboxField field={field} />;
    }

    case ConfigEntryFieldType.RADIO: {
      return <RadioField field={field} />;
    }

    case ConfigEntryFieldType.SELECT: {
      return (
        <PostSelectCustomField
          field={field}
          TextFieldProps={{ variant: 'standard', placeholder: '' }}
        />
      );
    }

    case ConfigEntryFieldType.MULTI_SELECT: {
      return (
        <PostMultiSelectCustomField
          field={field}
          TextFieldProps={{ variant: 'standard', placeholder: '' }}
        />
      );
    }

    case ConfigEntryFieldType.REGEX: {
      return (
        <MultiKeywordsField
          field={field}
          TextFieldProps={{ variant: 'standard', placeholder: '' }}
        />
      );
    }

    default:
      return (
        <PostMultiSelectCustomField
          field={field}
          TextFieldProps={{ variant: 'standard', placeholder: '' }}
        />
      );
  }
}

export default RenderFilterFieldByType;
