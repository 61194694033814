import {
  FormControl,
  Autocomplete,
  TextField,
  CircularProgress,
  Chip,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { useDivisionSpecSectionsQuery } from 'hooks/useCompanyQueries';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

type SpecSectionsFieldProps = {
  field: ConfigEntryType;
  TextFieldProps?: TextFieldProps;
};

function SpecSectionsField({ field, TextFieldProps }: SpecSectionsFieldProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext();
  const value = (watch(field.entryCode) || []) as string[];
  const selectedDivisions = (watch('divisions') || []) as string[];
  const prevDivisionCodeRef = useRef<string>('');
  const divisionCode = useMemo(() => {
    const prevDivCodes = prevDivisionCodeRef.current ? prevDivisionCodeRef.current.split('-') : [];
    const divisionCodes =
      selectedDivisions?.map((division) => division?.split?.('-')?.[0]?.trim() || '') || [];

    return _.uniq([...prevDivCodes, ...divisionCodes]).join('-');
  }, [selectedDivisions]);
  const isSpecSectionsQueryHashChanged = useMemo(
    () =>
      divisionCode?.length >= prevDivisionCodeRef.current?.length &&
      divisionCode !== prevDivisionCodeRef.current,
    [divisionCode],
  );
  const { specSections: specSectionsOptions, isLoading } = useDivisionSpecSectionsQuery(
    divisionCode,
    isSpecSectionsQueryHashChanged,
  );

  const options = useMemo(
    () =>
      _.orderBy(
        specSectionsOptions.map((division) => ({ label: division, value: division })),
        'label',
      ),
    [specSectionsOptions],
  );

  useEffect(() => {
    prevDivisionCodeRef.current = divisionCode || '';
  }, [divisionCode]);

  const handleSelect = (_: unknown, values: { label: string; value: string }[]) => {
    setValue(
      field.entryCode,
      values.map((item) => item.value),
      { shouldDirty: true, shouldValidate: true },
    );
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          multiple
          limitTags={3}
          disableCloseOnSelect
          onChange={handleSelect}
          options={options}
          autoHighlight
          value={value ? options.filter((item) => value.includes(item.value)) : []}
          noOptionsText={isLoading ? 'Loading...' : 'No Data Found'}
          renderInput={(params) => (
            <TextField
              placeholder={field.referenceObject?.placeHolder || 'Select'}
              variant="outlined"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              {...TextFieldProps}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.label}
                deleteIcon={<CloseLineIcon size={20} />}
              />
            ))
          }
          disabled={isSubmitting}
        />
        {selectedDivisions.length === 0 && (
          <Typography fontSize={13} variant="caption" textAlign="right" component="p">
            Please select divisions first
          </Typography>
        )}
      </FormControl>
    </>
  );
}

export default SpecSectionsField;
