import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';

type DeletePostModalProps = {
  cancelButtonName?: string;
  cancelLoading?: boolean;
  description?: string | ReactNode;
  loading?: boolean;
  open: boolean;
  submitButtonColor?: 'primary' | 'error' | 'success';
  submitButtonName?: string;
  title?: ReactNode;
  handleClose?: () => void;
  handleCancel?: () => void;
  onSubmit: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AlertModal = ({
  cancelButtonName,
  cancelLoading,
  description,
  loading = false,
  open,
  submitButtonColor = 'error',
  submitButtonName,
  title,
  handleClose,
  handleCancel,
  onSubmit,
  setOpen,
}: DeletePostModalProps) => {
  const onClose = () => {
    handleClose?.();
    setOpen(false);
  };

  const onCancel = () => {
    handleCancel?.();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CustomDialogTitle onClose={onClose} id="dialog-title">
        <$Title component={typeof title === 'string' ? 'p' : 'div'}>{title || 'Delete'}</$Title>
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description || (
            <>
              Are you sure you want to delete this post?
              <br />
              This post will be deleted permanently
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel} disabled={cancelLoading}>
          {cancelLoading ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            cancelButtonName || 'Cancel'
          )}
        </Button>
        <Button
          variant="contained"
          color={submitButtonColor}
          onClick={onSubmit}
          disabled={loading || cancelLoading}
        >
          {loading ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            submitButtonName || 'Delete'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const $Title = styled(Box, { label: 'ConfirmationDialogTitle' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
}));

export default AlertModal;
