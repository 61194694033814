import React, { Suspense, lazy, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, GlobalStyles } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { globalStyles, theme } from 'config/theme';
import { queryClient } from 'config/queryClient';
import AuthRoutes from 'routes/AuthRoutes';
import DashboardRoutes from 'routes/DashboardRoutes';
import CompanyAdminRoutes from 'routes/CompanyAdminRoutes';
import { GET_STARTED, PRIVACY, TERMS_CONDITIONS } from 'constants/routes';
import Privacy from '@buildwise/pages/Privacy';
import TermsConditions from '@buildwise/pages/TermsConditions';
import { handlePageScrollPosition } from 'utility/helpers';
import BuildWiseAdminRoutes from 'routes/BuildWiseAdminRoutes';
// import TawkMessenger from '@tawk.to/tawk-messenger-react';
import Loader from 'components/common/loader/Loader';
import BuildWiseGPTRoutes from 'routes/BuildWiseGPTRoutes';
import { Worker } from '@react-pdf-viewer/core';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const GetStarted = lazy(() => import('@buildwise/pages/GetStarted'));

const withProviders = (WrappedComponent: React.ElementType) => () => (
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.js">
    <ThemeProvider theme={theme}>
      <Box height="100vh" width="100%">
        <GlobalStyles styles={globalStyles} />
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <WrappedComponent />
          {/* <TawkMessenger
            propertyId={process.env?.REACT_APP_TAWK_PROPERTY_ID}
            widgetId={process.env?.REACT_APP_TAWK_WIDGET_ID}
          /> */}
        </QueryClientProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          closeButton={true}
          newestOnTop={true}
          rtl={false}
          limit={5}
          enableMultiContainer={true}
          draggable={false}
          theme="colored"
          pauseOnFocusLoss
          pauseOnHover
        />
      </Box>
    </ThemeProvider>
  </Worker>
);

function App() {
  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', onResize);
    window.addEventListener('unload', handlePageScrollPosition.bind(null, 'all', 0, 0, 'reset'));
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener(
        'unload',
        handlePageScrollPosition.bind(null, 'all', 0, 0, 'reset'),
      );
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: '/admin/*',
      element: <DashboardRoutes />,
    },
    {
      path: '/company-admin/*',
      element: <CompanyAdminRoutes />,
    },
    {
      path: '/buildwise-admin/*',
      element: <CompanyAdminRoutes />,
    },
    {
      path: '/buildwise-gpt/*',
      element: <CompanyAdminRoutes />,
    },
    {
      path: GET_STARTED,
      element: <GetStarted />,
    },
    {
      path: TERMS_CONDITIONS,
      element: <TermsConditions />,
    },
    {
      path: PRIVACY,
      element: <Privacy />,
    },
    {
      path: '/*',
      element: <AuthRoutes />,
    },
  ]);

  return (
    <Suspense fallback={<Loader variant="fixed" />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default withProviders(App);
