import { useDropzone } from 'react-dropzone';
import UploadCloud2FillIcon from 'remixicon-react/UploadCloud2FillIcon';
import { Box, CircularProgress, Link as MuiLink, SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { useFormContext } from 'react-hook-form';

type DropzoneProps = {
  dropzonePlaceholder?: string;
  loadingFiles?: boolean;
  maxFiles?: number;
  sx?: SxProps<Theme>;
  onDrop: (files: File[]) => void;
  multiple?: boolean;
};

function Dropzone({
  dropzonePlaceholder,
  loadingFiles,
  maxFiles,
  sx,
  onDrop,
  multiple = true,
}: DropzoneProps) {
  const {
    formState: { isSubmitting },
  } = useFormContext<PostFormData>();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...(maxFiles && { maxFiles: 1 }),
    disabled: isSubmitting,
    multiple,
  });

  return (
    <DropzoneContainer
      {...getRootProps({ className: 'dropzone' })}
      sx={{ ...sx, opacity: isSubmitting ? 0.6 : 1 }}
    >
      <input className="input-zone" {...getInputProps()} disabled={isSubmitting} />
      <Box display="flex" alignItems="center" justifyContent="center">
        {loadingFiles ? (
          <>
            <CircularProgress
              sx={(theme) => ({ color: theme.palette.custom.black, mx: 1 })}
              size={20}
            />{' '}
            Attaching files...
          </>
        ) : (
          <>
            <UploadCloud2FillIcon size={20} />
            <Typography ml={0.5} sx={{ opacity: 1 }}>
              {dropzonePlaceholder || 'Drop files here to attach'} or <Link>browse</Link>
            </Typography>
          </>
        )}
      </Box>
    </DropzoneContainer>
  );
}

const DropzoneContainer = styled(Box, { label: 'DropzoneContainer' })(({ theme }) => ({
  textAlign: 'center',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.custom.divider}`,
  padding: '16px 0',
  borderRadius: 8,
  backgroundColor: theme.palette.custom.alabaster,
}));

const Link = styled(MuiLink, { label: 'Link' })(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.custom.link,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default Dropzone;
