import { TabProps } from '@mui/material';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import LineChartFillIcon from 'remixicon-react/LineChartFillIcon';
import StarSmileLineIcon from 'remixicon-react/StarSmileLineIcon';
import UserStarFillIcon from 'remixicon-react/UserStarFillIcon';

export const HOME_TABS: TabProps[] = [
  {
    value: 'all-posts',
    label: 'All Posts',
    icon: <FileListLineIcon size={18} />,
    iconPosition: 'start',
  },
  {
    value: 'popular-posts',
    label: 'Popular Posts',
    icon: <LineChartFillIcon size={18} />,
    iconPosition: 'start',
  },
  {
    value: 'endorsed-posts',
    label: 'Endorsed Posts',
    icon: <StarSmileLineIcon size={18} />,
    iconPosition: 'start',
  },
  // {
  //   value: 'for-you',
  //   label: 'For You',
  //   icon: <UserStarFillIcon size={18} />,
  //   iconPosition: 'start',
  // },
];

export const POPULAR_POSTS_FILTERS = [
  { label: 'This Week', value: '1W' },
  { label: 'One Month', value: '1M' },
  { label: 'Six Months', value: '6M' },
  { label: 'One Year', value: '1Y' },
  { label: 'All', value: 'All' },
];
