import { Box, IconButton, InputBase } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, forwardRef } from 'react';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { styled } from '@mui/material/styles';

type PromptInputProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const PromptTemplatesSearch = forwardRef<HTMLTextAreaElement, PromptInputProps>(
  ({ value, setValue }, inputRef) => {
    return (
      <Container>
        <InputContainer>
          <SearchInput
            fullWidth
            inputRef={inputRef}
            placeholder="Search templates by title..."
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue?.(event.target.value)}
            startAdornment={
              <IconButton>
                <SearchLineIcon size={22} />
              </IconButton>
            }
          />
        </InputContainer>
      </Container>
    );
  },
);

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  width: '100%',
  position: 'sticky',
  bottom: 0,
  padding: '16px 0 0',
  backgroundColor: theme.palette.custom.background,
}));

const InputContainer = styled(Box, { label: 'InputContainer' })({
  margin: '0 auto',
  position: 'relative',
});

const SearchInput = styled(InputBase, { label: 'SearchInput' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  borderRadius: 8,
  padding: '6px !important',
  maxHeight: '150px !important',
  overflow: 'hidden !important',
  border: `2px solid ${theme.palette.custom.mistBlue}`,
  '&:focus-within': {
    borderColor: `${theme.palette.custom.mistBlue} !important`,
  },
  '&:has(.MuiInputBase-input:placeholder-shown)': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-input': {
    maxHeight: '150px !important',
    overflow: 'auto !important',
  },
}));

export default PromptTemplatesSearch;
