import { styled } from '@mui/material/styles';
import { Box, Button, Link, Paper, Tooltip, TooltipProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const SmallButton = styled(Button, { label: 'SmallButton' })({
  paddingLeft: 24,
  paddingRight: 24,
  height: 34,
  marginRight: 16,
  '& .MuiButton-startIcon': {
    marginRight: 10,
  },
});

export const $FormPaper = styled(Paper, { label: 'FormPaper' })(({ theme }) => ({
  borderRadius: 16,
  boxShadow: 'none',
  padding: '16px 0',
  marginBottom: 24,
  overflow: 'hidden',
  width: 690,
}));

export const $FormLabel = styled('label', { label: 'FormLabel' })(({ theme }) => ({
  display: 'inline-flex',
  color: theme.palette.custom.body,
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 6,
  lineHeight: '28px',
}));

export const $Break = styled(Box, { label: 'Break' })(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 18,
  marginBottom: 18,
  '& > span': {
    display: 'inline-flex',
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    color: theme.palette.custom.body,
    background: theme.palette.custom.white,
    position: 'relative',
    zIndex: 1,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 1,
    background: theme.palette.custom.line,
    top: '50%',
    left: 0,
  },
}));

export const $BackButton = styled(IconButton, { label: 'BackButton' })(({ theme }) => ({
  background: theme.palette.custom.white,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
  position: 'absolute',
  left: -60,
  top: -2,
  zIndex: 1,
}));

export const $LightTooltip = styled(Tooltip, { label: 'LightTooltip' })<TooltipProps>(
  ({ theme }) => ({}),
);

export const $PageTitle = styled(Typography, { label: 'PageTitle' })(() => ({
  color: '#15294B',
  fontWeight: 'bold',
  fontSize: '25px',
}));

export const $PageSubTitle = styled(Typography, { label: 'PageSubTitle' })(() => ({
  color: '#15294B',
}));

export const $LeftBox = styled(Box, { label: 'LeftBox' })(() => ({
  width: '65%',
  marginRight: 32,
  flexShrink: 0,
}));

export const $RightBox = styled(Box, { label: 'RightBox' })(() => ({
  flex: 1,
  width: '35%',
  marginTop: 132,
}));

export const $PageWrapper = styled(Box, { label: 'PageWrapper' })(() => ({
  width: '100%',
  minHeight: '400px',
}));

export const $PageList = styled(Box, { label: 'PageList' })(() => ({
  minHeight: '400px',
  marginBottom: 24,
  paddingBottom: 200,
  overflow: 'hidden',
}));

export const $BoxWrapper = styled(Box, { label: 'BoxWrapper' })(() => ({
  width: '100%',
  padding: '16px',
  display: 'flex',
  background: '#FFFFFF',
  marginTop: 1,
}));

export const $BoxTitle = styled(Typography, { label: 'BoxTitle' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '19.2px',
  marginBottom: 2,
  cursor: 'pointer',
  display: 'block',
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export const $BoxSubTitle = styled(Typography, { label: 'BoxSubTitle' })(({ theme }) => ({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '0.25px',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 450,
  color: theme.palette.custom.mistBlue,
}));

export const $BoxInfo = styled(Typography, { label: 'BoxInfo' })(() => ({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '0.25px',
  color: '#6E7191',
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: 5,
  },
}));

export const $ImageBox = styled(Link, { label: 'ImageBox' })(() => ({
  display: 'flex',
  flexShrink: 0,
  marginRight: 12,
  borderRadius: 14,
  alignItems: 'flex-start',
  justifyContent: 'center',
  textDecoration: 'none',
  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
}));
