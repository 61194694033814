import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import useUserData from 'hooks/useUserData';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FeedFilter } from 'api/KnowledgeAPI';
import RenderFilterFieldByType from './RenderFilterFieldByType';
import { getDefaultFilterValueByType } from '../helpers';
import { ConfigEntryFieldType } from 'config/config';
import _ from 'lodash';

type MoreFiltersModalProps = {
  open: boolean;
  selectedFilters: FeedFilter[];
  applyMoreFilters: (moreFilters: FeedFilter[]) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function MoreFiltersModal({
  open,
  selectedFilters,
  applyMoreFilters,
  setOpen,
}: MoreFiltersModalProps) {
  const { companyId } = useUserData();
  const { entries: companyFilters } = useConfigQuery(companyId, 'COMPANY_FILTERS');
  const activeCompanyFilters = useMemo(
    () => companyFilters.filter((filter) => filter.isActive),
    [companyFilters],
  );
  const orderedFilters = useMemo(
    () =>
      _.uniqBy(
        [
          ...activeCompanyFilters
            .filter(
              (filter) =>
                filter.referenceObject?.type === ConfigEntryFieldType.MULTI_SELECT ||
                !filter.referenceObject?.type,
            )
            .slice(0, 2),
          ...activeCompanyFilters,
        ],
        'entryCode',
      ),
    [activeCompanyFilters],
  );
  const filtersFormState = useForm<Record<string, any>>({
    mode: 'onChange',
    defaultValues: getDefaultValues(selectedFilters),
  });
  const {
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = filtersFormState;

  function getDefaultValues(_selectedFilters: FeedFilter[]) {
    const defaultValues: Record<string, any> = {};

    orderedFilters.forEach((filter) => {
      const value = _selectedFilters.find((_filter) => _filter.filterName === filter.entryCode);
      defaultValues[filter.entryCode] =
        value?.filterValues || getDefaultFilterValueByType(filter.referenceObject?.type);

      if (filter.referenceObject?.type === 'DATE') {
        defaultValues[`MIN_${filter.entryCode}`] =
          value?.startValue || getDefaultFilterValueByType(filter.referenceObject?.type);
        defaultValues[`MAX_${filter.entryCode}`] =
          value?.endValue || getDefaultFilterValueByType(filter.referenceObject?.type);
      }
    });

    return defaultValues;
  }

  const closeModal = () => {
    setOpen(false);
  };

  const handleApply = (values: Record<string, any>) => {
    let formattedValues: FeedFilter[] = [];

    formattedValues = orderedFilters.map((filter) => ({
      filterName: filter.entryCode,
      filterValues:
        values[filter.entryCode] || getDefaultFilterValueByType(filter.referenceObject?.type),
      ...(filter.referenceObject?.type === ConfigEntryFieldType.CHECKBOX && {
        isChecked: values[filter.entryCode],
      }),
      ...(filter.referenceObject?.type === ConfigEntryFieldType.DATE && {
        startValue: values[`MIN_${filter.entryCode}`],
        endValue: values[`MAX_${filter.entryCode}`],
      }),
    }));

    applyMoreFilters(formattedValues);
    setOpen(false);
  };

  const onApply = handleSubmit(handleApply);

  const resetFilters = () => {
    reset(getDefaultValues([]));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      onClose={closeModal}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>All Filters</Title>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description" sx={{ py: 4 }}>
        <FormProvider {...filtersFormState}>
          {orderedFilters.map((filter) => {
            return (
              <Grid
                key={filter.entryCode}
                container
                mb={
                  filter.referenceObject?.type === ConfigEntryFieldType.SELECT ||
                  filter.referenceObject?.type === ConfigEntryFieldType.MULTI_SELECT
                    ? 1
                    : 2
                }
                alignItems="center"
              >
                <Grid item md={3}>
                  <FieldName>{filter.referenceObject?.label || filter.entryCode}</FieldName>
                </Grid>
                <Grid item md={9}>
                  <RenderFilterFieldByType field={filter} />
                </Grid>
              </Grid>
            );
          })}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={resetFilters}>
          Reset
        </Button>
        <Button variant="contained" disabled={isSubmitting || !isValid} onClick={onApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const FieldName = styled(Typography, { label: 'FieldName' })(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.custom.black,
}));

export default MoreFiltersModal;
