import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import UserAvatar from 'components/common/userAvatar/UserAvatar';
import { UserType } from 'hooks/useUserData';
import { Dispatch, SetStateAction, useRef, useEffect, ChangeEvent } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import Avatar from 'components/common/avatar/Avatar';
import Upload from 'components/common/upload/Upload';
import { AUDIO_TYPES, DOCUMENT_TYPES, IMAGE_TYPES, VIDEO_TYPES } from 'components/utils/constants';
import Image2LineIcon from 'remixicon-react/Image2LineIcon';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { MentionType } from './CommentsBox';
import { useLocation } from 'react-router-dom';
import useCompanyQueryData from 'hooks/useCompanyQueryData';
import Dot from 'components/common/dot/Dot';

type CommentInputProps = {
  attachments: AttachmentType[];
  doNotSelectInputValue?: boolean;
  hideAvatar?: boolean;
  loading: boolean;
  placeholder?: string;
  type: string | undefined;
  user: UserType | null | undefined;
  userData: MentionType[];
  value: string;
  escapeCallback?: () => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFileInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  setMentions: Dispatch<SetStateAction<MentionType[]>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const CommentInput: React.FC<CommentInputProps> = ({
  attachments,
  doNotSelectInputValue = false,
  hideAvatar,
  loading,
  placeholder,
  type,
  user,
  userData,
  value,
  escapeCallback,
  onBlur,
  onFileInputChange,
  onSubmit,
  setMentions,
  setValue,
}) => {
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();
  const inputRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLDivElement | null>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (inputRef.current && (value || attachments.length)) {
      if (!doNotSelectInputValue && !attachments.length) {
        inputRef.current.select();
      } else {
        inputRef.current.setSelectionRange(value.length, value.length);
        inputRef.current.focus();
      }
    }
  }, [inputRef, attachments, hash]);

  useEffect(() => {
    if (commentsRef.current && decodeURIComponent(hash).replace('#', '') === 'comments') {
      commentsRef.current.scrollIntoView({ behavior: 'smooth' });

      // delay the focus to persist the smooth scrolling behavior
      setTimeout(() => inputRef.current?.focus(), 1000);
    }
  }, [commentsRef, hash]);

  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    _: unknown,
    _value: unknown,
    mentions: MentionType[],
  ) => {
    setMentions(userData.filter((user) => mentions.find((m) => m.id === user.id)));
    return setValue(event.target.value);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      escapeCallback?.();
      inputRef.current?.blur();
    }

    if (!event.shiftKey && event.key === 'Enter') {
      if (event.altKey) {
        setValue((prevState) => `${prevState}\r\n`);
      } else {
        event.preventDefault();
        onSubmit();
      }
    }
  };

  const renderUserSuggestion = (
    entry: MentionType,
    _: unknown,
    highlightedDisplay: string,
    index: unknown,
    focused: boolean,
  ) => {
    return (
      <$UserAvatarMentions
        title={highlightedDisplay}
        avatarName={entry.userNameToDisplay}
        subTitle={
          <>
            {entry.title}
            {shouldDisplayCompanyOnUserCards &&
              (entry?.primaryCompanyName || entry?.userPrimaryCompanyName) && (
                <>
                  <Dot />
                  {entry?.primaryCompanyName || entry?.userPrimaryCompanyName}
                </>
              )}
          </>
        }
        className={`${focused ? 'focused' : ''}`}
        isSmall
      />
    );
  };

  return (
    <$CommentInputWrapper
      className={value.trim() || attachments.length > 0 ? 'active' : ''}
      ref={commentsRef}
    >
      {!hideAvatar && (
        <Box>
          <Avatar
            src={user?.profileImageURL}
            size={35}
            fontSize={18}
            name={user?.userNameToDisplay || ''}
          />
        </Box>
      )}
      <$CommentInputField
        inputRef={inputRef}
        disabled={loading}
        allowSpaceInQuery
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder || 'Add a Comment'}
        className={`input-field ${hideAvatar ? 'hide-avatar' : ''}`}
        forceSuggestionsAboveCursor
        onKeyDown={onKeyDown}
      >
        <Mention
          trigger="@"
          style={{ backgroundColor: '#E8F5FA' }}
          data={userData}
          appendSpaceOnAdd
          renderSuggestion={renderUserSuggestion}
        />
      </$CommentInputField>
      <Box>
        {type !== 'question' && (
          <Upload
            multiple
            acceptedFiles={[...IMAGE_TYPES, ...AUDIO_TYPES, ...VIDEO_TYPES, ...DOCUMENT_TYPES]}
            onChange={onFileInputChange}
          >
            <$AttachmentsButton>
              <Image2LineIcon size={26} />
            </$AttachmentsButton>
          </Upload>
        )}
      </Box>
    </$CommentInputWrapper>
  );
};

const $CommentInputWrapper = styled(Box, { label: 'CommentInputWrapper' })(({ theme }) => ({
  width: '100%',
  position: 'relative',
  border: `1px solid ${theme.palette.custom.line}`,
  borderRadius: 8,
  background: `${theme.palette.custom.white} !important`,
  padding: '4px 12px',
  '&.active': {
    borderColor: theme.palette.custom.black,
  },
  display: 'flex',
  alignItems: 'center',
}));

const $CommentInputField = styled(MentionsInput, { label: 'CommentInputField' })(({ theme }) => ({
  width: '100%',
  margin: '0 12px',
  '&.hide-avatar': {
    margin: '0 8px',
  },
  '& .input-field__highlighter': {
    paddingTop: 8,
    paddingBottom: 7,
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent !important',
  },
  '& .input-field__suggestions': {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    border: `1px solid ${theme.palette.custom.line}`,
    boxShadow: '0px 8px 16px rgba(17, 17, 17, 0.12)',
    borderRadius: 8,
    width: 'max-content',
    maxHeight: 300,
    overflow: 'auto',
  },
  '& .input-field__input': {
    paddingTop: 8,
    border: 0,
    outline: 'none',
    color: theme.palette.custom.body,
    '&::placeholder': {
      color: '#B3B9C4',
      opacity: 1,
    },
  },
}));

const $UserAvatarMentions = styled(UserAvatar, { label: 'UserAvatarMentions' })(({ theme }) => ({
  padding: '8px 4px',
  '&.focused': {
    background: theme.palette.custom.inputBackground,
    borderRadius: 8,
  },
}));

const $AttachmentsButton = styled(IconButton, { label: 'AttachmentsButton' })(({ theme }) => ({
  padding: '6px',
  color: theme.palette.custom.body,
  '&.Mui-disabled': {
    color: theme.palette.custom.placeholder,
  },
}));

export default CommentInput;
