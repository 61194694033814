import { useBuildWiseModulesQuery } from '@buildwise/pages/knowledge/hooks/usePersonalizeQueries';
import { PartnerType } from '@buildwise/pages/stakeholders/hooks/useStakeholdersQuery';
import { Avatar, Box, Dialog, DialogContent, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BUILDWISE_MODULES } from 'components/drawer/constants';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { STAKEHOLDER } from 'constants/routes';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { Link } from 'react-router-dom';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';

type ViewPartnersModalProps = {
  open: boolean;
  partners: PartnerType[];
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ViewPartnersModal: FC<ViewPartnersModalProps> = ({ open, partners, setOpen }) => {
  const theme = useTheme();
  const closeModal = () => {
    setOpen(false);
  };
  const { buildwiseModules } = useBuildWiseModulesQuery();
  const { partnersModuleName } = useMemo(
    () => ({
      partnersModuleName:
        buildwiseModules.find((module) => module.entryCode === BUILDWISE_MODULES.PARTNERS)
          ?.entryDescription || BUILDWISE_MODULES.PARTNERS,
    }),
    [buildwiseModules],
  );

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="xs"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>New {partnersModuleName}</Title>
      </CustomDialogTitle>
      <DialogContent dividers>
        {partners?.map((partner, i) => (
          <PartnerContainer key={partner?.id || i}>
            <Box marginRight={1.5}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.custom.softPeach,
                  width: 44,
                  height: 44,
                }}
              >
                <HandCoinLineIcon size={25} color={theme.palette.custom.mistBlue} />
              </Avatar>
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography>
                <PartnerLink to={STAKEHOLDER.replace(':stakeholderId', partner?.id)}>
                  {partner.name}
                </PartnerLink>
              </Typography>
              {partner?.contactTypes?.length > 0 && (
                <Typography variant="caption">{partner.contactTypes.join(', ')}</Typography>
              )}
            </Box>
          </PartnerContainer>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

const PartnerContainer = styled(Box, { label: 'PartnerContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.custom.platinum}`,
  padding: '12px 0',
  '&:last-child': {
    border: 'none',
  },
}));

const PartnerLink = styled(Link, { label: 'PartnerLink' })(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.custom.activeTitle,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default ViewPartnersModal;
