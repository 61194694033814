/* eslint-disable no-useless-escape */
import { Box, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import KnowledgeAPI, { LLMResponseEngagementType } from 'api/KnowledgeAPI';
import React, { useCallback, useState } from 'react';
import CheckFillIcon from 'remixicon-react/CheckFillIcon';
import ClipboardLineIcon from 'remixicon-react/ClipboardLineIcon';
import ThumbDownFillIcon from 'remixicon-react/ThumbDownFillIcon';
import ThumbDownLineIcon from 'remixicon-react/ThumbDownLineIcon';
import ThumbUpFillIcon from 'remixicon-react/ThumbUpFillIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import {
  catchErrorMessage,
  getLLMResponseActionStateFromSessionStorage,
  updateLLMResponseActionInSessionStorage,
} from 'utility/helpers';
import LLMResponseFeedbackModal from './LLMResponseFeedbackModal';
import { toast } from 'react-toastify';

type LLMSummaryInteractionsProps = {
  llmResponse: string;
  promptId: string;
  sx?: SxProps;
};

function LLMSummaryInteractions({ llmResponse, promptId, sx }: LLMSummaryInteractionsProps) {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [llmResponseActionState, setLLMResponseActionState] = useState(
    getLLMResponseActionStateFromSessionStorage(),
  );

  const copyResponse = useCallback(async () => {
    try {
      setOpenTooltip(true);
      await navigator.clipboard.writeText(
        llmResponse?.replace(/\\\"/g, '"').replace(/\"/g, '').replaceAll('\\n', '\n').trim() || '',
      );

      setTimeout(() => {
        setOpenTooltip(false);
      }, 1200);
    } catch (error) {
      console.warn('COPY_LLM_SUMMARY_ERROR', error);
    }
  }, [llmResponse]);

  const likeResponse = useCallback(async () => {
    try {
      if (promptId) {
        await KnowledgeAPI.updateLLMSearchPrompt({
          id: promptId,
          engagementType: LLMResponseEngagementType.LIKED,
        });
        setLLMResponseActionState(
          updateLLMResponseActionInSessionStorage({
            id: promptId,
            engagementType: LLMResponseEngagementType.LIKED,
            undoAction: llmResponseActionState[LLMResponseEngagementType.LIKED]?.includes(promptId),
          }),
        );
      }
    } catch (error) {
      toast.error(catchErrorMessage(error));
    }
  }, [promptId]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end" sx={sx}>
        <IconButton onClick={likeResponse} title="Like">
          {llmResponseActionState[LLMResponseEngagementType.LIKED]?.includes(promptId || '') ? (
            <ThumbUpFillIcon size={18} />
          ) : (
            <ThumbUpLineIcon size={18} />
          )}
        </IconButton>
        <IconButton onClick={() => setOpenFeedbackModal(true)} title="Bad response">
          {llmResponseActionState[LLMResponseEngagementType.COMMENTED]?.includes(promptId || '') ? (
            <ThumbDownFillIcon size={18} />
          ) : (
            <ThumbDownLineIcon size={18} />
          )}
        </IconButton>
        <Tooltip open={openTooltip} placement="top" title={<Typography>Copied</Typography>}>
          <IconButton onClick={copyResponse}>
            {openTooltip ? <CheckFillIcon size={18} /> : <ClipboardLineIcon size={18} />}
          </IconButton>
        </Tooltip>
      </Box>

      {openFeedbackModal && (
        <LLMResponseFeedbackModal
          open={openFeedbackModal}
          setOpen={setOpenFeedbackModal}
          promptId={promptId}
          onActionUpdateCallback={() => {
            if (promptId) {
              setLLMResponseActionState(
                updateLLMResponseActionInSessionStorage({
                  id: promptId,
                  engagementType: LLMResponseEngagementType.COMMENTED,
                  undoAction:
                    llmResponseActionState[LLMResponseEngagementType.COMMENTED]?.includes(promptId),
                }),
              );
            }
          }}
        />
      )}
    </>
  );
}

export default LLMSummaryInteractions;
