import { styled } from '@mui/material/styles';
import { Box, Collapse } from '@mui/material';
import PromptInputModal from './PromptInputModal';
import useBuildWiseGPT from './hooks/useBuildWiseGPT';
import PromptInput from './PromptInput';
import PromptHistory from './PromptHistory';
import RenderChatDate from './RenderChatDate';

function BuildWiseGPT() {
  const {
    collpasedDates,
    containerRef,
    history,
    inputRef,
    loading,
    openFullViewModal,
    value,
    bookmarkPrompt,
    handleKeyDown,
    onClose,
    onFullModalMinimized,
    onSend,
    regeneratePrompt,
    setCollapsedDates,
    setFullViewModal,
    setValue,
  } = useBuildWiseGPT();

  return (
    <Container>
      <ChatHistoryContainer>
        {history.map((item) => {
          return (
            <Box
              sx={(theme) => ({ borderTop: `1px solid ${theme.palette.custom.divider}`, mt: 4 })}
              key={item.date}
            >
              <RenderChatDate
                date={item.date}
                collapsedDates={collpasedDates}
                setCollapsedDates={setCollapsedDates}
              />
              <Collapse in={!collpasedDates.includes(item.date)}>
                {item.data.map((chat) => (
                  <PromptHistory
                    key={chat._id}
                    item={chat}
                    loading={loading}
                    bookmarkPrompt={bookmarkPrompt}
                    regeneratePrompt={regeneratePrompt}
                  />
                ))}
              </Collapse>
            </Box>
          );
        })}
        <Box ref={containerRef} />
      </ChatHistoryContainer>
      <PromptInput
        ref={inputRef}
        value={value}
        handleKeyDown={handleKeyDown}
        onSend={onSend}
        setFullViewModal={setFullViewModal}
        setValue={setValue}
        regeneratePrompt={regeneratePrompt}
        hideRegenerate={!history.length}
      />

      {openFullViewModal && (
        <PromptInputModal
          open={openFullViewModal}
          enteredValue={value}
          setOpen={setFullViewModal}
          onFullModalMinimized={onFullModalMinimized}
          onSend={onSend}
          onClose={onClose}
        />
      )}
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })({
  minHeight: '100vh',
});

const ChatHistoryContainer = styled(Box, { label: 'ChatHistoryContainer' })({
  minHeight: '100vh',
  maxWidth: '80%',
  margin: '0 auto 48px',
});

export default BuildWiseGPT;
