import { FC } from 'react';
import { Typography, Dialog, DialogContent, Box, Avatar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Link } from 'react-router-dom';
import { ProjectType } from '@buildwise/pages/projects/hooks/useProjectsQuery';
import { PROJECT } from 'constants/routes';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';

type ViewProjectsModalProps = {
  open: boolean;
  projects: ProjectType[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewProjectsModal: FC<ViewProjectsModalProps> = ({ open, projects, setOpen }) => {
  const theme = useTheme();
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="xs"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '50%' } }}
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>New Projects</Title>
      </CustomDialogTitle>
      <DialogContent dividers>
        {projects?.map((project, i) => (
          <ProjectContainer key={project?.id || i}>
            <Box marginRight={1.5}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.custom.lightBlue,
                  width: 44,
                  height: 44,
                }}
                variant="rounded"
              >
                <BuildingLineIcon color={theme.palette.custom.black} />
              </Avatar>
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography>
                <ProjectLink to={PROJECT.replace(':projectId', project?.id)}>
                  {project.name}
                </ProjectLink>
              </Typography>
              {(project.city || project.state_code || project.country_code) && (
                <Typography variant="caption">
                  {project.city}
                  {(project.state_code || project.country_code) && project.city ? ',' : ''}{' '}
                  {project.state_code}
                  {project.country_code && project.state_code ? ',' : ''} {project.country_code}
                </Typography>
              )}
            </Box>
          </ProjectContainer>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
});

const ProjectContainer = styled(Box, { label: 'ProjectContainer' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.custom.platinum}`,
  padding: '12px 0',
  '&:last-child': {
    border: 'none',
  },
}));

const ProjectLink = styled(Link, { label: 'ProjectLink' })(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.custom.activeTitle,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default ViewProjectsModal;
