import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';

class FormsAPI extends JavaAPI {
  public createForm(payload: any) {
    return this.post(JAVA_SERVICE_ENDPOINTS.CREATE_FORM, payload);
  }

  public updateForm(payload: any) {
    return this.put(JAVA_SERVICE_ENDPOINTS.UPDATE_FORM, payload);
  }

  public removeForm(formId: string) {
    return this.delete<string>(JAVA_SERVICE_ENDPOINTS.DELETE_FORM.replace(':formId', formId));
  }

  public getAllFormsByCategory(configCategory: string, companyId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_ALL_FORMS_BY_CATEGORY.replace(':configCategory', configCategory).replace(':companyId', companyId)}`,
    );
  }

  public getFormDetails(formId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_FORM_DETAILS.replace(':formId', formId));
  }

  public getAssignedForms({
    assignedTo,
    companyId,
    createdBy,
    formCategory,
    status,
  }: {
    assignedTo?: string;
    companyId: string;
    createdBy?: string;
    formCategory?: FORMS_CONFIG_CATEGORY_TYPES;
    status: string;
  }) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_ASSIGNED_FORMS.replace(':companyId', companyId)}?status=${status}${assignedTo ? `&assignedTo=${encodeURIComponent(assignedTo)}` : ''}${createdBy ? `&createdBy=${encodeURIComponent(createdBy)}` : ''}${formCategory ? `&formCategory=${formCategory}` : ''}`,
    );
  }
}

export default new FormsAPI();
