import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { catchErrorMessage } from 'utility/helpers';
import { toast } from 'react-toastify';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { styled, useTheme } from '@mui/material/styles';
import ThumbDownFillIcon from 'remixicon-react/ThumbDownFillIcon';
import KnowledgeAPI, { LLMResponseEngagementType } from 'api/KnowledgeAPI';

type LLMResponseFeedbackModalProps = {
  promptId: string;
  open: boolean;
  onActionUpdateCallback: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function LLMResponseFeedbackModal({
  promptId,
  open,
  onActionUpdateCallback,
  setOpen,
}: LLMResponseFeedbackModalProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (promptId) {
        await KnowledgeAPI.updateLLMSearchPrompt({
          id: promptId,
          engagementType: LLMResponseEngagementType.DISLIKED,
          comment: reason.trim(),
        });
        onActionUpdateCallback();
        closeModal();
      }
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const closeModal = () => {
    setReason('');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Box display="flex" alignItems="center">
          <IconButton sx={(theme) => ({ backgroundColor: theme.palette.custom.blueChalk })}>
            <ThumbDownFillIcon size={25} color={theme.palette.custom.mistBlue} />
          </IconButton>
          &nbsp;&nbsp;
          <Title>Feedback</Title>
        </Box>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ borderBottom: 0 }}>
        <TextField
          multiline
          minRows={5}
          maxRows={10}
          autoFocus
          fullWidth
          value={reason}
          onChange={handleChange}
          placeholder="Provide feedback to improve BuildWise AI"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSubmit} disabled={loading || !reason.trim()}>
          {loading ? (
            <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
          ) : (
            'Submit'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
}));

export default LLMResponseFeedbackModal;
