import { IconButton, Stack } from '@mui/material';
import { FilterType } from 'components/common/filter/Filter';
import RenderChipFilterCategory from './RenderChipFilterCategory';
import CloseCircleFillIcon from 'remixicon-react/CloseCircleFillIcon';
import { Dispatch, SetStateAction } from 'react';

type ChipsFiltersProps = {
  filters: FilterType[];
  showClearAllFiltersIcon: Boolean;
  clearAllFilters: () => void;
  onFiltersChanged?: (filters: FilterType[]) => void;
  setFilters: Dispatch<SetStateAction<FilterType[]>>;
};

function ChipsFilters({
  filters,
  showClearAllFiltersIcon,
  clearAllFilters,
  onFiltersChanged,
  setFilters,
}: ChipsFiltersProps) {
  return (
    <>
      <Stack direction="row" flexWrap="wrap">
        {filters.map((filter, index) => (
          <RenderChipFilterCategory
            key={filter.category}
            filter={filter}
            filterIndex={index}
            onFiltersChanged={onFiltersChanged}
            setFilters={setFilters}
          />
        ))}
        {showClearAllFiltersIcon && filters.length > 0 && (
          <IconButton
            sx={(theme) => ({
              color: theme.palette.custom.pinkSwan,
              marginTop: '-4px',
              '&:hover': { color: 'inherit' },
            })}
            onClick={clearAllFilters}
            title="Clear Filters"
          >
            <CloseCircleFillIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
}

export default ChipsFilters;
