import { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { catchErrorMessage } from 'utility/helpers';
import { toast } from 'react-toastify';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProjectRecommendedArticleStatusState } from '../hooks/useProjectRecommendedArticleState';
import { ProjectRecommendationsType } from 'pages/projects/hooks/useProjectsQuery';
import { RecommendedArticleStatusType } from 'api/KnowledgeAPI';

const NOT_PROJECT_RELATED_ARTICLE_FEEDBACK_LIST = [
  {
    name: 'This post is for a different scope of work',
  },
  {
    name: 'This post is not applicable to this project',
  },
  {
    name: 'We already completed this work',
  },
  {
    name: 'This post is outdated',
  },
  {
    name: 'Other',
  },
];

type NotRelevantArticleForm = {
  type: string;
  description: string;
};

type NotProjectRelevantArticleProps = {
  articleId: string;
  open: boolean;
  project: ProjectRecommendationsType;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const NotProjectRelevantArticle = ({
  articleId,
  open,
  project,
  setOpen,
}: NotProjectRelevantArticleProps) => {
  const { updatePostInProject } = useProjectRecommendedArticleStatusState({
    project,
  });
  const {
    formState: { isDirty, isSubmitting, isValid },
    control,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
  } = useForm<NotRelevantArticleForm>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        type: Yup.string().required(),
        description: Yup.string().when('type', {
          is: (type: string) => type === 'Other',
          then: (schema) => schema.required('Please provide feedback'),
        }),
      }),
    ),
    defaultValues: {
      type: '',
      description: '',
    },
  });
  const type = watch('type');

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (values: NotRelevantArticleForm) => {
    try {
      await updatePostInProject(
        {
          status: RecommendedArticleStatusType.NOT_RELEVANT,
          message: values.type === 'Other' ? values.description : values.type,
        },
        articleId,
      );
      toast.success(
        'Our team will review your feedback and improve AI recommendations. Thanks for your feedback!',
      );
      closeModal();
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const triggerSubmit = handleSubmit(onSubmit);

  return (
    <Dialog
      onClose={closeModal}
      open={open}
      fullWidth
      maxWidth="xs"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Title>Not Relevant</Title>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ borderBottom: 0 }} id="dialog-description">
        <Box>
          <Note>
            Please let us know why this post is not relevant so we can improve our AI
            recommendations.
          </Note>
          <Controller
            control={control}
            render={({ field: radioField }) => (
              <RadioGroup aria-labelledby="not-relevant-article-radios-group" name="type">
                {NOT_PROJECT_RELATED_ARTICLE_FEEDBACK_LIST.map((item) => (
                  <Box key={item.name}>
                    <FormControlLabel
                      {...radioField}
                      control={
                        <Radio
                          value={item.name}
                          size="small"
                          onChange={() => {
                            setValue('type', item.name, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            resetField('description');
                          }}
                        />
                      }
                      label={item.name}
                      checked={type === item.name}
                      disabled={isSubmitting}
                    />
                    <Box sx={{ ml: 3.5 }}>
                      <Collapse in={item.name === type && type === 'Other'} unmountOnExit>
                        <Controller
                          control={control}
                          render={({ field: textField, fieldState }) => (
                            <TextField
                              {...textField}
                              autoFocus
                              fullWidth
                              multiline
                              rows={3}
                              placeholder={
                                type === 'Other'
                                  ? 'Please provide feedback'
                                  : 'Please provide feedback (optional)'
                              }
                              variant="outlined"
                              error={!!fieldState.error}
                              helperText={fieldState?.error?.message || ''}
                              sx={{ mt: 1 }}
                            />
                          )}
                          name="description"
                        />
                      </Collapse>
                    </Box>
                  </Box>
                ))}
              </RadioGroup>
            )}
            name="type"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting || !isDirty || !isValid}
          onClick={triggerSubmit}
          variant="contained"
        >
          {isSubmitting ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const Note = styled(Typography, { label: 'Note' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
  marginBottom: 8,
}));

export default NotProjectRelevantArticle;
