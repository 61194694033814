import { SxProps, Typography } from '@mui/material';
import UserShared2LineIcon from 'remixicon-react/UserShared2LineIcon';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { USER_PROFILE } from 'constants/routes';
import { UseArticleReducerActions } from '../hooks/useArticle';
import { Dispatch } from 'react';

type DraftStatusMessageProps = {
  assignedBy: string;
  assignedByMail: string;
  isAssignedToMe: boolean;
  dispatch: Dispatch<UseArticleReducerActions>;
};

function DraftStatusMessage({
  assignedBy,
  assignedByMail,
  isAssignedToMe,
  dispatch,
}: DraftStatusMessageProps) {
  if (isAssignedToMe && assignedBy) {
    return (
      <$Message variant="caption">
        <UserShared2LineIcon size={20} /> &nbsp;&nbsp;Assigned to you by&nbsp;
        <UserLink
          onInsightsUserClick={() =>
            dispatch({
              type: 'TOGGLE_INSIGHTS_MODAL',
              payload: true,
              key: 'openInsightsModal',
            })
          }
          mail={assignedByMail}
          name={assignedBy}
          sx={{ textTransform: 'none' }}
        />
      </$Message>
    );
  }

  return null;
}

export const UserLink = ({
  disableUserLink,
  mail,
  name,
  routeState,
  sx,
  target,
  onInsightsUserClick,
}: {
  disableUserLink?: boolean;
  mail: string;
  name: string;
  routeState?: Record<string, any>;
  sx?: SxProps;
  target?: string;
  onInsightsUserClick?: () => void;
}) => {
  return (
    <$User
      {...(mail === 'buildwise.insights@buildwise.ai'
        ? {
            onClick: onInsightsUserClick,
          }
        : !disableUserLink && {
            component: Link,
            to: USER_PROFILE.replace(':userName', encodeURIComponent(mail)),
            state: routeState,
            title: name,
            target,
          })}
      className={disableUserLink ? 'disable-link' : ''}
      sx={sx}
    >
      {name}
    </$User>
  );
};

const $Message = styled(Typography, { label: 'Message' })({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginLeft: 16,
  overflow: 'hidden',
});

const $User = styled(Typography, { label: 'User' })(({ theme }) => ({
  fontWeight: 'bold',
  // textTransform: 'capitalize',
  cursor: 'pointer',
  '&:hover:not(.disable-link)': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default DraftStatusMessage;
