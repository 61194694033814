import { JavaAPI, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';

class ProcoreAPI extends JavaAPI {
  public createProcoreUrl() {
    return this.get(JAVA_SERVICE_ENDPOINTS.PROCORE_CREATE_URL);
  }

  public loginProcore(code: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.PROCORE_LOGIN_URL + '?code=' + code);
  }

  public getCompanies() {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_PROCORE_COMPANIES);
  }

  public getCompanyProjects(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_PROCORE_COMPANY_PROJECTS.replace(':companyId', companyId),
    );
  }

  public importProcoreProjects(companyId: string, procoreCompanyId: string, projectIds: string[]) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.IMPORT_PROCORE_PROJECTS.replace(':companyId', companyId),
      {
        procoreCompanyId: procoreCompanyId,
        procoreProjectIds: projectIds,
      },
    );
  }

  public getRfiPDF(pmProjectID: string, pmRFIId: number) {
    return this.get(JAVA_SERVICE_ENDPOINTS.PROCORE_RFI_PDF(pmProjectID, pmRFIId));
  }

  public getSubmittalPDF(pmProjectID: string, pmSubmittalID: number) {
    return this.get(JAVA_SERVICE_ENDPOINTS.PROCORE_SUBMITTAL_PDF(pmProjectID, pmSubmittalID));
  }
}

export default new ProcoreAPI();
