import { CloseFullscreenRounded, CloseSharp } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputBase,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import GrowDialogTransition from 'components/modal/GrowDialogTransition';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

type PromptInputModalProps = {
  open: boolean;
  enteredValue: string;
  onClose: () => void;
  onFullModalMinimized: (value: string) => void;
  onSend: (value: string) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function PromptInputModal({
  open,
  enteredValue,
  onClose,
  onFullModalMinimized,
  onSend,
  setOpen,
}: PromptInputModalProps) {
  const theme = useTheme();
  const [value, setValue] = useState(enteredValue);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  }, [inputRef, enteredValue]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ '& .MuiPaper-root': { minHeight: '85%' } }}
      TransitionComponent={GrowDialogTransition}
    >
      <CustomDialogTitle id="dialog-title" sx={{ height: 70 }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              onFullModalMinimized(value);
              setOpen(false);
            }}
          >
            <CloseFullscreenRounded sx={(theme) => ({ color: theme.palette.custom.black })} />
          </IconButton>
          <IconButton
            onClick={() => {
              onClose();
              setOpen(false);
            }}
          >
            <CloseSharp sx={(theme) => ({ color: theme.palette.custom.black })} />
          </IconButton>
        </Box>
      </CustomDialogTitle>
      <DialogContent
        sx={{ py: 0, cursor: 'text' }}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <InputBase
          multiline
          fullWidth
          inputRef={inputRef}
          placeholder="Send a message"
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!value}
          onClick={() => {
            onSend(value);
            setOpen(false);
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PromptInputModal;
