import { ConfigEntryFieldType } from 'config/config';

export const getDefaultFilterValueByType = (type: ConfigEntryFieldType | undefined) => {
  switch (type || ConfigEntryFieldType.MULTI_SELECT) {
    case ConfigEntryFieldType.TEXT:
    case ConfigEntryFieldType.NUMBER:
    case ConfigEntryFieldType.DATE:
    case ConfigEntryFieldType.RADIO:
    case ConfigEntryFieldType.SELECT: {
      return '';
    }

    case ConfigEntryFieldType.CHECKBOX: {
      return false;
    }

    case ConfigEntryFieldType.MULTI_SELECT:
    case ConfigEntryFieldType.REGEX: {
      return [];
    }

    default:
      return [];
  }
};
