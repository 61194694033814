/* eslint-disable no-useless-escape */
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Skeleton as MuiSkeleton,
  Grid,
  Grow,
  Tooltip,
} from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { getAvatarBgColor, shortenString } from 'utility/helpers';
import { BuildWiseGPTHistoryItem } from './hooks/useBuildWiseGPTHistoryQuery';
import useUserData from 'hooks/useUserData';
import ClipboardLineIcon from 'remixicon-react/ClipboardLineIcon';
import BuildWiseLogo from 'assets/icons/logo.svg';
import { ReactComponent as BookmarkLineIcon } from 'assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFillIcon } from 'assets/icons/bookmark-fill.svg';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import CheckFillIcon from 'remixicon-react/CheckFillIcon';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import RenderLLMMarkdown from './RenderLLMMarkdown';

type PromptHistoryProps = {
  item: BuildWiseGPTHistoryItem;
  loading?: boolean;
  bookmarkPrompt?: (prompt: any) => Promise<void>;
  regeneratePrompt?: (prompt?: string) => void;
};

function PromptHistory({
  item,
  loading = false,
  bookmarkPrompt,
  regeneratePrompt,
}: PromptHistoryProps) {
  const { user } = useUserData();
  const [openTooltip, setOpenTooltip] = useState(false);

  const copyResponse = async () => {
    try {
      setOpenTooltip(true);
      await navigator.clipboard.writeText(
        item.genAIResponse.replace(/\\\"/g, '"').replace(/\"/g, '').replaceAll('\\n', '\n').trim(),
      );

      setTimeout(() => {
        setOpenTooltip(false);
      }, 1200);
    } catch (error) {
      console.warn('COPY_PROMPT_RESPONSE_ERROR', error);
    }
  };

  const question: ReactNode = useMemo(
    () =>
      item.prompt ? (
        <>
          <Grid item sm={1} my={1} />
          <Grid item sm={10} my={1}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <QuestionContainer>
                <Question>{item.prompt}</Question>
              </QuestionContainer>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            my={1}
            sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', ml: -1 }}
          >
            <Avatar
              variant="circular"
              src={user?.profileImageURL}
              sx={{
                backgroundColor: getAvatarBgColor(user?.userNameToDisplay || '', 30, 50),
              }}
            >
              {user?.userNameToDisplay.slice(0, 1).toUpperCase()}
            </Avatar>
          </Grid>
        </>
      ) : (
        ''
      ),
    [item, user],
  );
  const answer = useMemo(() => {
    let answer = item.genAIResponse ? (
      <>
        <Grid
          item
          sm={1}
          my={1}
          sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}
        >
          <BuildWiseAvatar variant="circular" src={BuildWiseLogo}>
            {item?.createdBy.slice(0, 1).toUpperCase()}
          </BuildWiseAvatar>
        </Grid>
        <Grid item sm={10} my={1}>
          <Box width="fit-content" maxWidth="100%" overflow="hidden">
            <AnswerContainer>
              <Box px={2}>
                <RenderLLMMarkdown
                  llmResponse={item.genAIResponse
                    .replace(/\\\"/g, '"')
                    .replace(/\"/g, '')
                    .replaceAll('\\n', '\n')
                    .trim()}
                />
                {/* {item.isStreaming && (
                  <Box
                    component="span"
                    sx={(theme) => ({
                      display: 'inline',
                      backgroundColor: theme.palette.custom.tuna,
                      borderRadius: 8,
                      padding: '1px',
                    })}
                  />
                )} */}
              </Box>
              {item?.referenceFiles && item.referenceFiles?.length > 0 && (
                <>
                  <br />
                  <Box
                    sx={(theme) => ({
                      borderTop: `1px solid ${theme.palette.custom.divider}`,
                      pt: 1,
                      px: 2,
                    })}
                  >
                    <Typography
                      sx={(theme) => ({ fontWeight: 700, color: theme.palette.custom.black })}
                    >
                      References:{' '}
                    </Typography>
                    <Box display="flex" flexWrap="wrap" mt={0.5}>
                      {item.referenceFiles?.map((file) => (
                        <Box
                          sx={(theme) => ({
                            padding: '4px 8px',
                            backgroundColor: '#1976d221',
                            mr: 0.5,
                            mb: 0.5,
                            borderRadius: 2,
                          })}
                          key={file.documentName}
                        >
                          <FileName to={file.documentURL} target="_blank" title={file.documentName}>
                            {shortenString(file.documentName)}
                          </FileName>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
              {/* <Typography
                sx={{ fontWeight: 400, fontSize: 16, whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: linkifyHtml(
                    decode(
                      item.genAIResponse.replace(/\"/g, '').replaceAll('\\n', '<br />').trim(),
                    ),
                    {
                      target: '_blank',
                    },
                  ),
                }}
              /> */}
            </AnswerContainer>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={0.5}>
              {bookmarkPrompt && (
                <IconButton
                  onClick={() =>
                    bookmarkPrompt?.({
                      ...item,
                      isBookMarked: user?.gptPromptIds?.includes(item._id),
                    })
                  }
                  title={user?.gptPromptIds?.includes(item._id) ? 'Remove Bookmark' : 'Bookmark'}
                >
                  {user?.gptPromptIds?.includes(item._id) ? (
                    <BookmarkFillIcon style={{ width: 18, height: 18 }} />
                  ) : (
                    <BookmarkLineIcon style={{ width: 18, height: 18 }} />
                  )}
                </IconButton>
              )}
              <Tooltip open={openTooltip} placement="top" title={<Typography>Copied</Typography>}>
                <IconButton onClick={copyResponse}>
                  {openTooltip ? <CheckFillIcon size={18} /> : <ClipboardLineIcon size={18} />}
                </IconButton>
              </Tooltip>
              {regeneratePrompt && (
                <IconButton onClick={() => regeneratePrompt?.(item.prompt)} title="Regenerate">
                  <RefreshLineIcon size={18} />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      </>
    ) : (
      ''
    );

    if (loading && !item.genAIResponse) {
      answer = (
        <>
          <Grid
            item
            sm={1}
            my={1}
            sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}
          >
            <BuildWiseAvatar variant="circular" src={BuildWiseLogo} />
          </Grid>
          <Grid item sm={10} my={1}>
            <Box display="flex">
              <AnswerContainer width="100% !important" padding="16px !important">
                <Grow in timeout={2000}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={10}
                    sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                  />
                </Grow>
                <Grow in timeout={3000}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={10}
                    sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                  />
                </Grow>
                <Grow in timeout={3500}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={10}
                    sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                  />
                </Grow>
                <Grow in timeout={4000}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="80%"
                    height={10}
                    sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                  />
                </Grow>
              </AnswerContainer>
            </Box>
          </Grid>
        </>
      );
    }

    return answer;
  }, [item, user, openTooltip]);

  return (
    <Grid container key={item._id} spacing={1}>
      {question}
      {answer}
    </Grid>
  );
}

const QuestionContainer = styled(Box, { label: 'QuestionContainer' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.tuna,
  color: theme.palette.custom.white,
  padding: 16,
  borderRadius: 8,
  borderTopRightRadius: 0,
  marginRight: 8,
  width: 'fit-content',
  maxWidth: '100%',
}));

const Question = styled(Typography, { label: 'Question' })(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  whiteSpace: 'pre-wrap',
}));

const AnswerContainer = styled(Box, { label: 'AnswerContainer' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.tropicalBlue,
  borderRadius: 8,
  borderTopLeftRadius: 0,
  padding: '16px 0',
  width: 'fit-content',
  maxWidth: '100%',
  overflow: 'hidden',
  color: theme.palette.custom.black,
  fontWeight: 400,
  fontSize: 16,
  marginRight: 8,
  '& .line-break': {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& pre,code': {
      whiteSpace: 'pre-wrap',
    },
    '& h1,h2,h3,h4,h5,h6': {
      margin: 0,
    },
    '& ul,ol': {
      margin: 0,
      whiteSpace: 'normal !important',
    },
    '& li p': {
      whiteSpace: 'pre-wrap !important',
    },
    pre: {
      width: '100%',
      backgroundColor: theme.palette.custom.navy,
      padding: 8,
      color: theme.palette.custom.white,
      borderRadius: 10,
    },
  },
}));

const BuildWiseAvatar = styled(Avatar, { label: 'BuildWiseAvatar' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.mistBlue,
  padding: 4,
  '& .MuiAvatar-img': {
    objectFit: 'contain !important',
  },
}));

const FileName = styled(Link, { label: 'FileName' })(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 400,
  fontSize: 14,
  color: theme.palette.custom.link,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Skeleton = styled(MuiSkeleton, { label: 'Skeleton' })({
  '&::after': {
    background: `linear-gradient(
      90deg,
      transparent,
      rgb(143 143 143 / 80%),
      transparent
    )`,
  },
});

export default PromptHistory;
