import { styled } from '@mui/material/styles';
import { Dialog, Typography, DialogContent, Box, Button } from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Dispatch, SetStateAction, useState } from 'react';
import useUserData from 'hooks/useUserData';
import { useAssignedFormsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import { Link } from 'react-router-dom';
import { USER_PROFILE } from 'constants/routes';
import CancelPostRequestModal from '../../../requests/components/CancelPostRequestModal';
import AddPostModal from 'components/modals/addPostModal/AddPostModal';
import moment from 'moment';

type RequestedPostsModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function RequestedPostsModal({ open, setOpen }: RequestedPostsModalProps) {
  const { user, companyId } = useUserData();
  const [openCancelPostRequestModal, setOpenCancelPostRequestModal] = useState(false);
  const [postRequest, setPostRequest] = useState<any>(null);
  const [openAddPostModal, setOpenAddPostModal] = useState(false);
  const { data, requestedPosts, updateAssignedFormsCache } = useAssignedFormsQuery({
    companyId: companyId || '',
    assignedTo: user?.username || '',
    status: 'NEW-INPROGRESS',
  });

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        onClose={closeModal}
        sx={{ '& .MuiPaper-root': { height: '55%' } }}
      >
        <CustomDialogTitle onClose={closeModal} id="dialog-title">
          <Title>Requests for Your Posts</Title>
        </CustomDialogTitle>
        <DialogContent id="dialog-description">
          {requestedPosts.map((request) => (
            <Container key={request?.id}>
              <Typography
                mb={2}
                sx={(theme) => ({ color: theme.palette.custom.tuna, fontSize: 16 })}
              >
                {request?.requestMessage}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography
                  sx={(theme) => ({ color: theme.palette.custom.mistBlue, fontSize: 14 })}
                >
                  Requested by{' '}
                  <UserLink to={USER_PROFILE.replace(':userName', request.createdBy)}>
                    {request?.createdByDisplayName ||
                      data?.userMap?.[request.createdBy]?.[0]?.displayName}
                  </UserLink>
                </Typography>
                {request?.dueDate && (
                  <Typography
                    sx={(theme) => ({ color: theme.palette.custom.mistBlue, fontSize: 14 })}
                  >
                    Suggested Due Date: {moment(request?.dueDate || '').format('MMM DD, YYYY')}
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setPostRequest(request);
                    setOpenAddPostModal(true);
                  }}
                >
                  Create a Post
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setPostRequest(request);
                    setOpenCancelPostRequestModal(true);
                  }}
                >
                  Close
                </Button>
              </Box>
            </Container>
          ))}
        </DialogContent>
      </Dialog>

      {openCancelPostRequestModal && (
        <CancelPostRequestModal
          open={openCancelPostRequestModal}
          setOpen={setOpenCancelPostRequestModal}
          postRequest={postRequest}
          onCloseRequest={() => {
            updateAssignedFormsCache({ form: postRequest, action: 'delete' });
            setPostRequest(null);
            closeModal();
          }}
        />
      )}
      {openAddPostModal && (
        <AddPostModal
          open={openAddPostModal}
          setOpen={setOpenAddPostModal}
          formIds={[postRequest.id]}
          onAddArticleCallback={() => {
            updateAssignedFormsCache({ form: postRequest, action: 'delete' });
            setPostRequest(null);
            closeModal();
          }}
        />
      )}
    </>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  padding: 16,
  marginBottom: 16,
  borderRadius: 8,
  border: `1px solid ${theme.palette.custom.blueChalk}`,
}));

const UserLink = styled(Link, { label: 'UserLink' })(({ theme }) => ({
  fontSize: 'inherit',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

export default RequestedPostsModal;
