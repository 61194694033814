import { styled } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import FormsAPI from 'api/FormsAPI';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';
import useUserData from 'hooks/useUserData';
import { useAssignedFormsQuery } from '@buildwise/pages/forms/hooks/useFormsQuery';
import _ from 'lodash';

type CancelPostRequestModalProps = {
  open: boolean;
  postRequest: any;
  onCloseRequest: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isAssignedToMe?: boolean;
};

function CancelPostRequestModal({
  open,
  postRequest,
  onCloseRequest,
  setOpen,
  isAssignedToMe = true,
}: CancelPostRequestModalProps) {
  const { companyId, user } = useUserData();
  const [responseMessage, setResponseMessage] = useState('');
  const [closingRequest, setClosingRequest] = useState(false);
  const { data } = useAssignedFormsQuery({
    companyId: companyId || '',
    assignedTo: user?.username || '',
    status: 'NEW-INPROGRESS',
  });

  const closeModal = () => {
    setOpen(false);
  };

  const onClose = async () => {
    try {
      setClosingRequest(true);
      let result = await FormsAPI.updateForm({
        companyId,
        status: 'CLOSED',
        formCategory: FORMS_CONFIG_CATEGORY_TYPES.REQUEST_A_POST_CARD,
        id: postRequest?.id,
        responseMessage: responseMessage.trim(),
      });
      if (result?.status === 200 && !_.isEmpty(result?.data)) {
        toast.success('Post request closed successfully!');
        onCloseRequest();
        closeModal();
      } else {
        toast.error('Unable to close the request, please try again later');
      }
    } catch (error) {
      console.warn('CLOSE_REQUEST_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setClosingRequest(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        onClose={closeModal}
        sx={{ '& .MuiPaper-root': { minHeight: '40%' } }}
      >
        <CustomDialogTitle onClose={closeModal} id="dialog-title">
          <Title>Close Request</Title>
        </CustomDialogTitle>
        <DialogContent id="dialog-description" dividers sx={{ borderBottom: 0 }}>
          <TextField
            autoFocus
            multiline
            minRows={5}
            maxRows={8}
            fullWidth
            label={
              isAssignedToMe
                ? `Add a note for ${postRequest?.createdByDisplayName || data?.userMap?.[postRequest.createdBy]?.[0]?.displayName} explaining the reason for closing the request without adding a post:`
                : 'Add a note explaining the reason for closing the request'
            }
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={responseMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setResponseMessage(event.target.value);
            }}
            disabled={closingRequest}
            sx={{ mt: 1, '& .MuiFormLabel-root': { whiteSpace: 'pre-wrap' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            disabled={!responseMessage.trim() || closingRequest}
            onClick={onClose}
            variant="contained"
          >
            {closingRequest ? (
              <CircularProgress sx={(theme) => ({ color: theme.palette.custom.black })} size={20} />
            ) : (
              'Close Request'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: theme.palette.custom.black,
}));

export default CancelPostRequestModal;
