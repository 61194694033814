import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement, Ref, forwardRef } from 'react';

const GrowDialogTransition = forwardRef(function GrowDialogTransition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Grow ref={ref} {...props} style={{ transformOrigin: 'top center' }} timeout={400} />;
});

export default GrowDialogTransition;
