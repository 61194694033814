import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FeedbackForm from './FeedbackForm';
import useFeedbackForm from '../hooks/useFeedbackForm';
import MailLineIcon from 'remixicon-react/MailLineIcon';
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon';
import { Box } from '@mui/system';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';

export const $Dialog = styled(Dialog, { label: 'ActionModal' })(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: '0px 1px 0px #D9DBE9',
    transition: 'width 0.3s',
    maxWidth: 1280,
    width: 620,
  },
  '& .MuiPaper-root': {
    marginBottom: 'unset',
    padding: '16px 0 0 0',
  },
}));

export const $DialogTitle = styled(MuiDialogTitle, { label: 'DialogTitle' })(({ theme }) => ({
  margin: 0,
  padding: '0 24px',
  height: 72,
  lineHeight: '76px',
  color: '#000000',
  fontWeight: 700,
  fontSize: 32,
  '& .MuiIconButton-root:not(.resize)': {
    top: 16,
    right: 12,
    color: theme.palette.custom.lightBlack,
  },
}));

export const $DialogContent = styled(DialogContent, { label: 'DialogContent' })<{ width?: number }>(
  ({ theme, width }) => ({
    transition: 'width 0.3s, padding 0.3s',
    borderTop: `1px solid ${theme.palette.custom.line}`,
    borderBottom: `1px solid ${theme.palette.custom.line}`,
    width: width || '100%',
    overflow: 'auto',
    backgroundColor: 'white !important',
    zIndex: 1,
    padding: '16px 16px 0px 24px',
    maxHeight: '520px',
  }),
);

type FeedbackModalProps = {
  openFeedback: boolean;
  setFeedbackOpen: any;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({ openFeedback, setFeedbackOpen }) => {
  const { control, register, watch, setValue, getValues, triggerSubmit, loading } =
    useFeedbackForm(closeHandle);

  function closeHandle() {
    setFeedbackOpen(false);
  }

  const feedback = watch('feedback');

  const removeFile = (file: string) => {
    console.log(file);
  };

  return (
    <Dialog open={openFeedback} fullWidth>
      <CustomDialogTitle onClose={closeHandle} id="dialog-title">
        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: 'black' }}>
          Feedback to BuildWise
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description">
        <Box
          sx={{
            backgroundColor: '#F5F6F7',
            borderRadius: '8px',
            padding: '16px',
          }}
        >
          <Typography align="center" paddingBottom={1.5} fontWeight={700}>
            BuildWise Support
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography>Monday - Friday</Typography>
              <Typography>8:00 AM - 6:00 PM (EST)</Typography>
            </Box>
            <Box>
              <Box component="a" href="tel:+1 (301) 859-0330" display="flex" alignItems="center">
                <PhoneFillIcon size={20} />
                &nbsp;&nbsp;
                <$LinkText>+1 (301) 859-0330</$LinkText>
              </Box>
              <Box
                component="a"
                href="mailto:support@buildwise.ai"
                display="flex"
                alignItems="center"
              >
                <MailLineIcon size={20} />
                &nbsp;&nbsp;
                <$LinkText>support@buildwise.ai</$LinkText>
              </Box>
            </Box>
          </Box>
        </Box>
        <FeedbackForm
          getValues={getValues}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          removeFile={removeFile}
        />
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button disabled={loading || !feedback} onClick={triggerSubmit} variant="contained">
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const $LinkText = styled(Typography, { label: 'LinkText' })(({ theme }) => ({
  '&:hover': {
    color: theme.palette.custom.link,
    textDecoration: 'underline',
  },
}));

export default FeedbackModal;
