import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import useTemplatesForm from '../../../hooks/useTemplatesForm';
import TemplateForm from './TemplateForm';
import { TemplateType } from '@buildwise/libs/company-admin';

type TemplateModalProps = {
  open: boolean;
  template?: TemplateType;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function TemplateModal({ open, setOpen, template }: TemplateModalProps) {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const { templateForm, loading, handleSave, handleLabelKeyDown, handleRemoveLabel } =
    useTemplatesForm({
      template,
      closeModal,
    });
  const { watch } = templateForm;
  const title = watch('title');
  const labels = watch('labels');
  const description = watch('description');

  const disableSave = useMemo(() => {
    const hasNewChanges =
      title.trim() !== template?.title.trim() ||
      description.trim() !== template?.description.trim() ||
      labels.join().toLowerCase() !== template?.labels.join().toLowerCase();
    return (
      loading || !title.trim() || title.trim().length < 3 || !description.trim() || !hasNewChanges
    );
  }, [title, labels, description, loading, template]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="template-dialog-title"
      aria-describedby="template-dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="template-dialog-title">
        <$Title>{template ? 'Edit' : 'Add'} Template</$Title>
      </CustomDialogTitle>
      <DialogContent dividers id="template-dialog-description" sx={{ pb: 5 }}>
        <FormProvider {...templateForm}>
          <TemplateForm
            loading={loading}
            handleLabelKeyDown={handleLabelKeyDown}
            handleRemoveLabel={handleRemoveLabel}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button disabled={disableSave} onClick={handleSave} variant="contained">
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const $Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: 'black',
}));

export default TemplateModal;
