import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { useMemo, useState } from 'react';
import { getExactFileName } from 'utility/helpers';
import { styled } from '@mui/material/styles';
import { Typography, Box, Grow, Skeleton as MuiSkeleton } from '@mui/material';
import PdfViewer from 'pages/search/PdfViewer';
import { ReactComponent as BlackStarsIcon } from 'assets/icons/BlackStars.svg';
import { ArticleAttachmentsSummary } from '../hooks/useArticleAttachmentsSummary';
import RenderLLMMarkdown from 'pages/buildwiseAdmin/BuildWiseGPT/RenderLLMMarkdown';
import LLMSummaryInteractions from 'pages/search/components/LLMSummaryInteractions';
import FileIcon from 'pages/search/components/FileIcon';

type RenderDocumentsProps = {
  articleAttachmentsSummary?: ArticleAttachmentsSummary;
  documents: AttachmentType[];
  showTitle?: boolean;
  source?: string;
};

function RenderDocuments({
  articleAttachmentsSummary,
  documents,
  showTitle = true,
  source,
}: RenderDocumentsProps) {
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    name: string;
    size: number;
    url: string;
    initialPage?: number;
  } | null>(null);

  if (!documents.length) {
    return null;
  }

  const handleDocumentClick = (options: {
    name: string;
    size: number;
    url: string;
    initialPage?: number;
    shouldUsePDFViewer: boolean;
  }) => {
    if (options.shouldUsePDFViewer) {
      setSelectedFile(options);
      setOpenPdfViewer(true);
    } else {
      window.open(options.url);
    }
  };

  return (
    <Box mt={2}>
      {showTitle && <$Title>Attachments</$Title>}
      {documents.map((attachment) => (
        <RenderDocument
          key={attachment.fileName}
          attachment={attachment}
          handleDocumentClick={handleDocumentClick}
          articleAttachmentsSummary={articleAttachmentsSummary}
          source={source}
        />
      ))}
      {openPdfViewer && selectedFile && (
        <PdfViewer open={openPdfViewer} setOpen={setOpenPdfViewer} file={selectedFile} />
      )}
    </Box>
  );
}

type RenderDocumentProps = {
  articleAttachmentsSummary?: ArticleAttachmentsSummary;
  attachment: AttachmentType;
  handleDocumentClick: (options: {
    name: string;
    size: number;
    url: string;
    initialPage?: number;
    shouldUsePDFViewer: boolean;
    pageContent?: string[];
  }) => void;
  source?: string;
};

const RenderDocument = ({
  articleAttachmentsSummary,
  attachment,
  handleDocumentClick,
  source,
}: RenderDocumentProps) => {
  const fileExtension = useMemo(() => {
    let extension = attachment.fileType?.split('/')?.[1] || '';

    if (attachment.fileUploaded) {
      extension = attachment.fileType;
    }

    return extension;
  }, [attachment.fileType, attachment.fileUploaded]);
  const attachmentSummary = useMemo(
    () => articleAttachmentsSummary?.[attachment.fileName],
    [articleAttachmentsSummary, attachment.fileName],
  );
  const summary = useMemo(() => attachmentSummary?.data?.data, [attachmentSummary]);

  return (
    <>
      {source === 'preview' ? (
        <Box
          component="a"
          href={
            !!attachmentSummary?.data?.data
              ? attachmentSummary?.data?.data?.documentData?.articleOpenUrl || ''
              : attachment?.articleOpenUrl || ''
          }
          target="_blank"
          rel="noopener noreferrer"
          sx={{ width: '100%' }}
        >
          <DocumentName>
            <Box component="span" mr={0.5}>
              <FileIcon fileType={fileExtension} />
            </Box>
            {getExactFileName(attachment)}
          </DocumentName>
        </Box>
      ) : (
        <DocumentName
          onClick={() =>
            handleDocumentClick({
              name: getExactFileName(attachment),
              size: attachment.fileSize,
              url: !!attachmentSummary?.data?.data
                ? attachmentSummary?.data?.data?.documentData?.documentPDF ||
                  attachmentSummary?.data?.data?.documentData?.articleOpenUrl ||
                  ''
                : attachment?.documentPDF || attachment?.articleOpenUrl || '',
              initialPage:
                Number(attachmentSummary?.data?.data?.documentData.pageInfo?.[0].pageNo) || 0,
              shouldUsePDFViewer:
                !!attachment?.documentPDF ||
                !!attachmentSummary?.data?.data?.documentData?.documentPDF,
              pageContent: attachmentSummary?.data?.data?.documentData?.pageContent || [],
            })
          }
        >
          <Box component="span" mr={0.5}>
            <FileIcon fileType={fileExtension} />
          </Box>{' '}
          {getExactFileName(attachment)}
        </DocumentName>
      )}

      {!!attachmentSummary && (
        <>
          <SummaryContainer>
            <Arrow />
            <Box mr={1}>
              <BlackStarsIcon
                title={attachmentSummary.isLoading ? 'Summarizing...' : 'Summary'}
                width={20}
                height={20}
              />
            </Box>
            <Box flex={1}>
              {attachmentSummary.isLoading ? (
                <Box>
                  <Grow in timeout={2000}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="100%"
                      height={10}
                      sx={(theme) => ({
                        my: 1,
                        backgroundColor: theme.palette.custom.skelton1,
                        mt: 0,
                      })}
                    />
                  </Grow>
                  <Grow in timeout={3000}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="100%"
                      height={10}
                      sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                    />
                  </Grow>
                  <Grow in timeout={3500}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="100%"
                      height={10}
                      sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                    />
                  </Grow>
                  <Grow in timeout={4000}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="80%"
                      height={10}
                      sx={(theme) => ({ my: 1, backgroundColor: theme.palette.custom.skelton1 })}
                    />
                  </Grow>
                </Box>
              ) : (
                <RenderLLMMarkdown llmResponse={attachmentSummary.data?.data?.llmResponse || ''} />
              )}
            </Box>
          </SummaryContainer>
          {summary?.llmResponse && summary.gptPromptId.id && (
            <LLMSummaryInteractions
              promptId={summary?.gptPromptId?.id}
              llmResponse={summary?.llmResponse}
            />
          )}
        </>
      )}
    </>
  );
};

const $Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.secondary.dark,
  lineHeight: '19px',
}));

const DocumentName = styled(Typography, { label: 'DocumentName' })(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.custom.link,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '17px',
  marginTop: 8,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const SummaryContainer = styled(Box, { label: 'SummaryContainer' })(({ theme }) => ({
  position: 'relative',
  padding: '12px 16px',
  width: '100%',
  color: theme.palette.custom.black,
  fontWeight: 400,
  fontSize: 16,
  whiteSpace: 'pre-wrap',
  backgroundColor: theme.palette.custom.tropicalBlue,
  margin: '12px 0 8px',
  borderRadius: '0 4px 4px 4px',
  display: 'flex',
  '& .line-break': {
    whiteSpace: 'pre-wrap',
    '& pre,code': {
      whiteSpace: 'pre-wrap',
    },
    '& h1,h2,h3,h4,h5,h6': {
      margin: 0,
    },
    '& ul,ol': {
      margin: 0,
      whiteSpace: 'normal !important',
    },
    '& li p': {
      whiteSpace: 'pre-wrap !important',
    },
    pre: {
      width: '100%',
      backgroundColor: theme.palette.custom.black,
      padding: 8,
      color: theme.palette.custom.white,
      borderRadius: 10,
    },
  },
}));

const Arrow = styled(Box, { label: 'Arrow' })(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translateY(-100%)',
  borderWidth: 6,
  borderStyle: 'solid',
  borderColor: `transparent transparent ${theme.palette.custom.tropicalBlue} ${theme.palette.custom.tropicalBlue}`,
  borderRadius: '4px 4px 0 0',
}));

const Skeleton = styled(MuiSkeleton, { label: 'Skeleton' })({
  '&::after': {
    background: `linear-gradient(
      90deg,
      transparent,
      rgb(143 143 143 / 80%),
      transparent
    )`,
  },
});

export default RenderDocuments;
