import { AxiosResponse } from 'axios';
import { ArticleType, LikedUsersResponse } from 'pages/knowledge/hooks/useArticlesQuery';
import { BuildWiseGPTHistory } from 'pages/buildwiseAdmin/BuildWiseGPT/hooks/useBuildWiseGPTHistoryQuery';
import { PromptTemplate } from 'pages/buildwiseAdmin/BuildWiseGPT/hooks/usePromptTemplatesQuery';
import { JAVA_SERVICE_ENDPOINTS, JavaAPI } from '@buildwise/libs/services';

export type ReplyCommentPayloadType = {
  articleId: string;
  comment: {
    parentId: string;
    commentText: string;
    commentAuthor: string;
  };
};

export enum RecommendedArticleStatusType {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  REVIEW_LATER = 'REVIEW_LATER',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

export type AddPostsToProjectPayload = {
  articleIdToStatusObjMap: Record<string, { status: RecommendedArticleStatusType; notes?: string }>;
};

export type RevisePostPayload = {
  users: string[];
  message: string;
};

export type RevisePostResponse = {
  article: ArticleType;
  successResponse: string;
  totalElements: number;
  totalNumber: number;
  totalPages: number;
};

export type ArticlesResponse = {
  articles: ArticleType[];
  isPersonal: null | boolean;
  pageNumber: number;
  totalElements: number;
  totalPages: number;
};

export type PersonalizeFeedFilterType = ('PROJECTS' | 'TOPICS' | 'USERS' | 'DEPARTMENTS')[];

type AddPromptTemplateResponse = {
  gptPrompt: PromptTemplate;
  successResponse: string;
};

export enum LLMResponseEngagementType {
  LIKED = 'liked',
  DISLIKED = 'disliked',
  VIEWED = 'viewed',
  COMMENTED = 'commented',
}

export type FeedFilter = {
  filterName: string;
  filterValues: string | number | boolean | (string | number | boolean)[];
  startValue?: string | number;
  endValue?: string | number;
  isChecked?: boolean;
};

class KnowledgeAPI extends JavaAPI {
  public getArticles(companyId: string, isPersonalised = false) {
    const params = new URLSearchParams({
      isPersonalised: isPersonalised ? 'true' : 'false',
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_ARTICLES.replace(':companyId', companyId)}?${params.toString()}`,
    );
  }

  public getArticlesPagination(
    companyId: string,
    pageNo: number,
    pageSize: number,
    filter?: PersonalizeFeedFilterType,
  ) {
    const params = new URLSearchParams({
      isPersonalised: 'false',
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
      ...(filter?.length && { filter: filter.join('-') }),
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_ARTICLES.replace(':companyId', companyId)}?${params.toString()}`,
    );
  }

  public getPostArticlesPagination(
    companyId: string,
    pageNo: number,
    pageSize: number,
    filters?: FeedFilter[],
  ) {
    const params = new URLSearchParams({
      isPersonalised: 'false',
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_POST_ARTICLES.replace(
        ':companyId',
        companyId,
      )}?${params.toString()}`,
      (config) => ({
        ...config,
        headers: {
          ...config.headers,
          filters: JSON.stringify(filters),
        },
      }),
    );
  }

  public getPlaylistArticlesPagination(
    companyId: string,
    pageNo: number,
    pageSize: number,
    filters?: FeedFilter[],
  ) {
    const params = new URLSearchParams({
      isPersonalised: 'false',
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_PLAYLIST_ARTICLES.replace(
        ':companyId',
        companyId,
      )}?${params.toString()}`,
      (config) => ({
        ...config,
        headers: {
          ...config.headers,
          filters: JSON.stringify(filters),
        },
      }),
    );
  }

  public getInsightArticlesPagination(
    companyId: string,
    pageNo: number,
    pageSize: number,
    filter?: PersonalizeFeedFilterType,
  ) {
    const params = new URLSearchParams({
      isPersonalised: 'false',
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
      ...(filter?.length && { filter: filter.join('-') }),
    });
    return this.get<ArticlesResponse>(
      `${JAVA_SERVICE_ENDPOINTS.KNOWLEDGE_GET_INSIGHT_ARTICLES.replace(
        ':companyId',
        companyId,
      )}?${params.toString()}`,
    );
  }

  public getBookmarkedArticles(companyId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_BOOKMARKED.replace(':companyId', companyId),
    );
  }

  public getDraftArticles(companyId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_DRAFTS.replace(':companyId', companyId));
  }

  public getArticle(companyId: string, articleId: string, internal = '') {
    const params = new URLSearchParams({
      internal,
    });
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      )}?${params.toString()}`,
    );
  }

  public getPaginatedRelatedArticles(
    companyId: string,
    articleId: string,
    pageNo: string,
    pageSize: number,
  ) {
    const params = new URLSearchParams({
      pageNo: pageNo.toString(),
      pageSize: pageSize.toString(),
    });

    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_RELATED_ARTICLES.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      )}?${params.toString()}`,
    );
  }

  public getArticleComments(companyId: string, articleId: string) {
    return this.get(
      `${JAVA_SERVICE_ENDPOINTS.GET_ARTICLE_COMMENTS.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      )}`,
    );
  }

  public addComment(
    companyId: string,
    articleId: string,
    comment: { commentAuthor: string; commentText: string; mentions?: any[] },
  ) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_COMMENT, {
      companyId,
      articleId,
      comment,
    });
  }

  public replyComment(payload: ReplyCommentPayloadType) {
    return this.post(JAVA_SERVICE_ENDPOINTS.REPLY_COMMENT, payload);
  }

  public updateComment(payload: any) {
    return this.put(JAVA_SERVICE_ENDPOINTS.UPDATE_COMMENT, payload);
  }

  public likeComment(companyId: string, commentId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.LIKE_COMMENT.replace(':companyId', companyId).replace(
        ':commentId',
        commentId,
      ),
    );
  }

  public removeCommentLike(companyId: string, commentId: string) {
    return this.get(
      JAVA_SERVICE_ENDPOINTS.REMOVE_COMMENT_LIKE.replace(':companyId', companyId).replace(
        ':commentId',
        commentId,
      ),
    );
  }

  public searchArticles(companyId: string, keyword: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.SEARCH, {
      companyId,
      novaSearchKeyword: keyword,
      novaSearchType: 'all',
    });
  }

  public addArticle(companyId: string, payload: any) {
    const formBody = new FormData();

    if (payload.attachment) {
      formBody.append('file', payload.attachment.file);
      if (payload.attachment?.thumbnail) {
        formBody.append('thumbnail', payload.attachment.thumbnail);
      }

      delete payload.attachment;
    }
    formBody.append('article', JSON.stringify(payload));

    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_ARTICLE.replace(':companyId', companyId), formBody);
  }

  public forwardArticle(companyId: string, payload: FormData) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.FORWARD_ARTICLE.replace(':companyId', companyId),
      payload,
    );
  }

  public addArticleMedia(
    companyId: string,
    articleId: string,
    payload: FormData,
    onUploadProgress?: any,
  ): Promise<AxiosResponse<ArticleType, Error>> {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_ARTICLE_MEDIA.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      payload,
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }

  public updateArticleMedia(
    companyId: string,
    articleId: string,
    payload: FormData,
    onUploadProgress?: any,
  ): Promise<AxiosResponse<ArticleType, Error>> {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.UPDATE_ARTICLE_MEDIA.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      payload,
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }

  public addCommentMedia(
    companyId: string,
    commentId: string,
    payload: FormData,
    onUploadProgress?: any,
  ): Promise<AxiosResponse<any, Error>> {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_COMMENT_MEDIA.replace(':companyId', companyId).replace(
        ':commentId',
        commentId,
      ),
      payload,
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }

  public updateCommentMedia(
    companyId: string,
    commentId: string,
    payload: FormData,
    onUploadProgress?: any,
  ): Promise<AxiosResponse<any, Error>> {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.UPDATE_COMMENT_MEDIA.replace(':companyId', companyId).replace(
        ':commentId',
        commentId,
      ),
      payload,
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }

  public removeCommentMedia(
    companyId: string,
    commentId: string,
    fileName: string,
    onUploadProgress?: any,
  ): Promise<AxiosResponse<any, Error>> {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.REMOVE_COMMENT_ATTCHMENT.replace(':companyId', companyId)
        .replace(':commentId', commentId)
        .replace(':fileName', fileName),
      onUploadProgress
        ? {
            onUploadProgress,
          }
        : undefined,
    );
  }

  public editArticle(companyId: string, articleId: string, payload: any) {
    const formBody = new FormData();
    formBody.append('article', JSON.stringify(payload));
    return this.put<ArticleType>(
      JAVA_SERVICE_ENDPOINTS.EDIT_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      formBody,
    );
  }

  public addBookmark(companyId: string, articleId: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_BOOKMARK, {
      companyId,
      articleId,
    });
  }

  public removeBookmark(companyId: string, articleId: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.REMOVE_BOOKMARK, {
      companyId,
      articleId,
    });
  }

  public like(companyId: string, articleId: string, userName: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.ADD_LIKE, {
      companyId,
      articleId,
      userName,
    });
  }

  public removeLike(companyId: string, articleId: string, userName: string) {
    return this.post(JAVA_SERVICE_ENDPOINTS.REMOVE_LIKE, {
      companyId,
      articleId,
      userName,
    });
  }

  public removePost(companyId: string, articleId: string) {
    return this.delete(
      JAVA_SERVICE_ENDPOINTS.GET_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
    );
  }

  public removeAttachment(companyId: string, articleId: string, attachmentId: string) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.REMOVE_ARTICLE_ATTACHMENT.replace(':companyId', companyId)
        .replace(':articleId', articleId)
        .replace(':id', attachmentId),
    );
  }

  public getInteractions(companyId: string, articleId: string) {
    return this.get<LikedUsersResponse>(
      JAVA_SERVICE_ENDPOINTS.LIKED_USERS.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
    );
  }

  public addPostsToPlayList(companyId: string, articleId: string, playListArticles: string[]) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_POSTS_TO_PLAYLIST.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      playListArticles,
    );
  }

  public getAllPlayLists(companyId: string) {
    return this.get(JAVA_SERVICE_ENDPOINTS.GET_PLAYLISTS.replace(':companyId', companyId));
  }

  public addPostToManyPlayLists(
    companyId: string,
    articleId: string,
    playListArticleIds: string[],
  ) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.ADD_POST_TO_MANY_PLAYLISTS.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      playListArticleIds,
    );
  }

  public removePostFromManyPlayLists(
    companyId: string,
    articleId: string,
    playListArticleIds: string[],
  ) {
    return this.post(
      JAVA_SERVICE_ENDPOINTS.REMOVE_POST_FROM_MANY_PLAYLISTS.replace(
        ':companyId',
        companyId,
      ).replace(':articleId', articleId),
      playListArticleIds,
    );
  }

  public updateProjectRecommendedPosts(
    companyId: string,
    projectId: string,
    payload: AddPostsToProjectPayload,
  ) {
    return this.put(
      JAVA_SERVICE_ENDPOINTS.ADD_POSTS_TO_PROJECT.replace(':companyId', companyId).replace(
        ':projectId',
        projectId,
      ),
      payload,
    );
  }

  public revisePost(companyId: string, articleId: string, payload: RevisePostPayload) {
    return this.post<RevisePostResponse>(
      JAVA_SERVICE_ENDPOINTS.REVISE_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      payload,
    );
  }

  public moveArticle(companyId: string, articleId: string, payload: ArticleType) {
    const formBody = new FormData();
    formBody.append('article', JSON.stringify(payload));

    return this.post(
      JAVA_SERVICE_ENDPOINTS.MOVE_ARTICLE.replace(':companyId', companyId).replace(
        ':articleId',
        articleId,
      ),
      formBody,
    );
  }

  public postPrompt(payload: { promptText: string; regenerateFlag?: boolean }) {
    return this.post(JAVA_SERVICE_ENDPOINTS.POST_PROMPT, {
      ...payload,
      promptType: 'Generate',
    });
  }

  public getBuildWiseGPTHistory(username: string) {
    return this.get<BuildWiseGPTHistory>(
      JAVA_SERVICE_ENDPOINTS.GET_BUILDWISE_GPT_HISTORY.replace(
        ':username',
        encodeURIComponent(username),
      ),
    );
  }

  public getPromptBookmarks(username: string) {
    return this.get<BuildWiseGPTHistory>(
      JAVA_SERVICE_ENDPOINTS.PROMPT_BOOKAMRKS.replace(':username', encodeURIComponent(username)),
    );
  }

  public getPromptTemplates(companyId: string) {
    return this.get<any>(JAVA_SERVICE_ENDPOINTS.PROMPT_TEMPLATES.replace(':companyId', companyId));
  }

  public savePromptTemplate(payload: Partial<PromptTemplate>) {
    return this.post<AddPromptTemplateResponse>(
      JAVA_SERVICE_ENDPOINTS.ADD_PROMPT_TEMPLATE,
      payload,
    );
  }

  public updateLLMSearchPrompt(payload: {
    comment?: string;
    id: string;
    engagementType: LLMResponseEngagementType;
  }) {
    return this.post<AddPromptTemplateResponse>(
      JAVA_SERVICE_ENDPOINTS.UPDATE_LLM_SEARCH_PROMPT,
      payload,
    );
  }

  public deletePromptTemplate(promptId: string) {
    return this.delete<any>(
      JAVA_SERVICE_ENDPOINTS.DELETE_PROMPT_TEMPLATE.replace(':promptId', promptId),
    );
  }
}

const knowledgeAPI = new KnowledgeAPI();
export default knowledgeAPI;
