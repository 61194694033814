import { Theme } from '@mui/material/styles';
import { Drawer, useMediaQuery } from '@mui/material';
import {
  ACCOUNT_SETTINGS,
  AI_DRAFT_WORKBENCH,
  BUILDWISE_COMPANIES,
  BUILDWISE_CONFIGURATIONS,
  ADMIN_FIELDS,
  ADMIN_FILTERS,
  BUILDWISE_GPT_BOOKMARKS,
  BUILDWISE_GPT_CHAT,
  BUILDWISE_GPT_TEMPLATES,
  BUILDWISE_USERS,
  // COMAPNY_DASHBOARDS,
  COMPANY_CONFIGURATIONS,
  COMPANY_FIELDS,
  COMPANY_FILTERS,
  INTEGRATIONS,
  SUBSCRIPTION_SETTINGS,
  TEMPLATES,
  USERS,
  USER_ACTIVITY_DASHBOARDS,
  USER_GROUPS,
  COMPANY_PROGRESS_CARDS,
  ADMIN_PROGRESS_CARDS,
  COMPANY_SCORECARDS,
  ADMIN_SCORECARDS,
  COMPANY_BUILDWISE_SCORECARDS,
  ADMIN_BUILDWISE_SCORECARDS,
} from 'constants/routes';
import Settings2LineIcon from 'remixicon-react/Settings2LineIcon';
import { AbsoluteContainer, FixedContainer, Sidebar, StickyContainer } from './MainDrawer';
import RenderMenu, { MenuItemType } from './RenderMenu';
import useUserData from 'hooks/useUserData';
import { Helmet } from 'react-helmet';
import { useMemo } from 'react';
import usePromptTemplatesQuery from 'pages/buildwiseAdmin/BuildWiseGPT/hooks/usePromptTemplatesQuery';
import { useCompanyConfigsByTypeQuery } from '@buildwise/libs/company-admin';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAdminConfigsByTypeQuery } from '@buildwise/libs/admin';

const CompanyAdminDrawer = () => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const { user, isNovaAdmin, companyId } = useUserData();
  const { templates } = usePromptTemplatesQuery({
    shouldFetch: process.env.REACT_APP_ENVIRONMENT !== 'APP' || isNovaAdmin,
  });
  const { configs: companyFieldConfigs } = useCompanyConfigsByTypeQuery({
    companyId,
    configType: 'INPUT_FIELDS',
  });
  const { configs: companyFilterConfigs } = useCompanyConfigsByTypeQuery({
    companyId,
    configType: 'FILTERS',
  });
  const { configs: adminFieldConfigs } = useAdminConfigsByTypeQuery({
    configType: 'INPUT_FIELDS',
  });
  const { configs: adminFilterConfigs } = useAdminConfigsByTypeQuery({
    configType: 'FILTERS',
  });

  const companyConfigs = useMemo(() => {
    return [...companyFieldConfigs, ...companyFilterConfigs];
  }, [companyFieldConfigs, companyFilterConfigs]);

  const adminConfigs = useMemo(() => {
    return [...adminFieldConfigs, ...adminFilterConfigs];
  }, [adminFieldConfigs, adminFilterConfigs]);

  const menu = useMemo(() => {
    const MENU: MenuItemType[] = [
      {
        title: 'Company Admin',
        icon: <Settings2LineIcon />,
        children: _.compact([
          {
            title: 'Company Settings',
            url: ACCOUNT_SETTINGS,
          },
          {
            title: 'Subscription Settings',
            url: SUBSCRIPTION_SETTINGS,
          },
          {
            title: 'Integrations',
            url: INTEGRATIONS,
          },
          {
            title: 'Templates',
            defaultExpanded: false,
            children: [
              {
                title: 'Articles',
                url: TEMPLATES,
              },
              {
                title: 'Progress Cards',
                url: COMPANY_PROGRESS_CARDS,
              },
              {
                title: 'Scorecards',
                url: COMPANY_SCORECARDS,
              },
              {
                title: 'BuildWise Scorecards',
                url: COMPANY_BUILDWISE_SCORECARDS,
              },
            ],
          },
          {
            title: 'User Administration',
            defaultExpanded: false,
            children: [
              {
                title: 'Users',
                url: USERS,
              },
              {
                title: 'User Groups',
                url: USER_GROUPS,
              },
            ],
          },
          {
            title: 'Configurations',
            url: COMPANY_CONFIGURATIONS,
            defaultExpanded: false,
            children: [
              {
                title: 'Areas',
                url: `${COMPANY_CONFIGURATIONS}#areas`,
              },
              {
                title: 'Departments',
                url: `${COMPANY_CONFIGURATIONS}#departments`,
              },
              {
                title: 'Job Titles',
                url: `${COMPANY_CONFIGURATIONS}#job-titles`,
              },
              {
                title: 'Market Sectors',
                url: `${COMPANY_CONFIGURATIONS}#market-sectors`,
              },
              {
                title: 'Partner Roles',
                url: `${COMPANY_CONFIGURATIONS}#partner-roles`,
              },
              {
                title: 'Project Phases',
                url: `${COMPANY_CONFIGURATIONS}#project-phases`,
              },
              {
                title: 'Project Types',
                url: `${COMPANY_CONFIGURATIONS}#project-types`,
              },
              {
                title: 'Regions',
                url: `${COMPANY_CONFIGURATIONS}#regions`,
              },
              {
                title: 'Scorecard Categories',
                url: `${COMPANY_CONFIGURATIONS}#scorecard-categories`,
              },
              {
                title: 'Specification Master Format',
                url: `${COMPANY_CONFIGURATIONS}#master-format`,
              },
              {
                title: 'Topics',
                url: `${COMPANY_CONFIGURATIONS}#topics`,
              },
              {
                title: 'User Interests',
                url: `${COMPANY_CONFIGURATIONS}#user-interests`,
              },
              {
                title: 'Workstreams',
                url: `${COMPANY_CONFIGURATIONS}#workstreams`,
              },
            ],
          },
          (companyFieldConfigs.length > 0
            ? {
                title: 'Input Fields',
                url: COMPANY_FIELDS,
                defaultExpanded: false,
                children: companyFieldConfigs.map((config) => ({
                  title: config.description,
                  url: `${COMPANY_FIELDS}#${config.configName}`,
                })),
              }
            : undefined) as MenuItemType,
          (companyFilterConfigs.length > 0
            ? {
                title: 'Filters',
                url: COMPANY_FILTERS,
                defaultExpanded: false,
                children: companyFilterConfigs.map((config) => ({
                  title: config.description,
                  url: `${COMPANY_FILTERS}#${config.configName}`,
                })),
              }
            : undefined) as MenuItemType,
          {
            title: 'Reports',
            defaultExpanded: false,
            isHidden: !isNovaAdmin,
            children: [
              {
                title: 'User Activity',
                url: USER_ACTIVITY_DASHBOARDS,
              },
            ],
          },
          {
            title: 'BuildWise GPT',
            defaultExpanded: false,
            isHidden:
              (process.env.REACT_APP_ENVIRONMENT === 'APP' || isNovaAdmin) &&
              process.env.REACT_APP_ENVIRONMENT !== 'LOCAL',
            children: [
              {
                title: 'Chat',
                url: BUILDWISE_GPT_CHAT,
              },
              {
                title: 'Bookmarks',
                url: BUILDWISE_GPT_BOOKMARKS,
                badgeContent: user?.gptPromptIds?.length || 0,
              },
              {
                title: 'Templates',
                url: BUILDWISE_GPT_TEMPLATES,
                badgeContent: templates.length,
              },
            ],
          },
        ]),
      },
      {
        title: 'BuildWise Admin',
        icon: <Settings2LineIcon />,
        isHidden: !isNovaAdmin,
        defaultExpanded: pathname.startsWith('/buildwise-admin'),
        children: _.compact([
          {
            title: 'Companies',
            url: BUILDWISE_COMPANIES,
          },
          {
            title: 'Users',
            url: BUILDWISE_USERS,
          },
          {
            title: 'AI-Drafts Workbench',
            url: AI_DRAFT_WORKBENCH,
          },
          {
            title: 'Configurations',
            url: BUILDWISE_CONFIGURATIONS,
            defaultExpanded: false,
            children: [
              {
                title: 'Areas',
                url: `${BUILDWISE_CONFIGURATIONS}#areas`,
              },
              {
                title: 'Departments',
                url: `${BUILDWISE_CONFIGURATIONS}#departments`,
              },
              {
                title: 'Job Titles',
                url: `${BUILDWISE_CONFIGURATIONS}#job-titles`,
              },
              {
                title: 'Market Sectors',
                url: `${BUILDWISE_CONFIGURATIONS}#market-sectors`,
              },
              {
                title: 'Meeting Minutes Criteria',
                url: `${BUILDWISE_CONFIGURATIONS}#meeting-minutes`,
              },
              {
                title: 'Partner Roles',
                url: `${BUILDWISE_CONFIGURATIONS}#partner-roles`,
              },
              {
                title: 'Project Phases',
                url: `${BUILDWISE_CONFIGURATIONS}#project-phases`,
              },
              {
                title: 'Project Types',
                url: `${BUILDWISE_CONFIGURATIONS}#project-types`,
              },
              {
                title: 'Regions',
                url: `${BUILDWISE_CONFIGURATIONS}#regions`,
              },
              {
                title: 'RFI Filter Criteria',
                url: `${BUILDWISE_CONFIGURATIONS}#rfi-criteria`,
              },
              {
                title: 'Scorecard Categories',
                url: `${BUILDWISE_CONFIGURATIONS}#scorecard-categories`,
              },
              {
                title: 'Topics',
                url: `${BUILDWISE_CONFIGURATIONS}#topics`,
              },
              {
                title: 'User Interests',
                url: `${BUILDWISE_CONFIGURATIONS}#user-interests`,
              },
              {
                title: 'Workstreams',
                url: `${BUILDWISE_CONFIGURATIONS}#workstreams`,
              },
            ],
          },
          {
            title: 'Templates',
            defaultExpanded: false,
            children: [
              {
                title: 'Progress Cards',
                url: ADMIN_PROGRESS_CARDS,
              },
              {
                title: 'Scorecards',
                url: ADMIN_SCORECARDS,
              },
              {
                title: 'BuildWise Scorecards',
                url: ADMIN_BUILDWISE_SCORECARDS,
              },
            ],
          },
          (adminFieldConfigs.length > 0
            ? {
                title: 'Input Fields',
                url: ADMIN_FIELDS,
                defaultExpanded: false,
                children: adminFieldConfigs.map((config) => ({
                  title: config.description,
                  url: `${ADMIN_FIELDS}#${config.configName}`,
                })),
              }
            : undefined) as MenuItemType,
          (adminFilterConfigs.length > 0
            ? {
                title: 'Filters',
                url: ADMIN_FILTERS,
                defaultExpanded: false,
                children: adminFilterConfigs.map((config) => ({
                  title: config.description,
                  url: `${ADMIN_FILTERS}#${config.configName}`,
                })),
              }
            : undefined) as MenuItemType,
          {
            title: 'BuildWise GPT',
            defaultExpanded: false,
            isHidden: process.env.REACT_APP_ENVIRONMENT === 'LOCAL',
            children: [
              {
                title: 'Chat',
                url: BUILDWISE_GPT_CHAT,
              },
              {
                title: 'Bookmarks',
                url: BUILDWISE_GPT_BOOKMARKS,
                badgeContent: user?.gptPromptIds?.length || 0,
              },
              {
                title: 'Templates',
                url: BUILDWISE_GPT_TEMPLATES,
                badgeContent: templates.length,
              },
            ],
          },
        ]),
      },
    ];

    return MENU;
  }, [user, isNovaAdmin, process.env, companyConfigs, adminConfigs, pathname]);

  return (
    <>
      <Helmet>
        <title>{`${process.env.REACT_APP_TITLE} - Admin Console`}</title>
      </Helmet>
      {isMd && (
        <Sidebar
          component="aside"
          sx={(theme) => ({
            width: theme.drawerWidth,
          })}
        >
          <AbsoluteContainer>
            <FixedContainer>
              <StickyContainer>
                <Drawer
                  variant="persistent"
                  open
                  sx={{
                    '& .MuiPaper-root': {
                      position: 'initial',
                      height: `calc(100vh - 100px)`,
                    },
                  }}
                >
                  <RenderMenu menu={menu} />
                </Drawer>
              </StickyContainer>
            </FixedContainer>
          </AbsoluteContainer>
        </Sidebar>
      )}
    </>
  );
};

export default CompanyAdminDrawer;
