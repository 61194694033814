import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import useUserData from 'hooks/useUserData';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

type RadioFieldProps = {
  field: ConfigEntryType;
};

function RadioField({ field }: RadioFieldProps) {
  const { companyId } = useUserData();
  const {
    control,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<Record<string, any>>();
  const { entries } = useConfigQuery(
    companyId,
    field.referenceObject?.configRefName || field.entryCode,
  );
  const value = (watch(field.entryCode) || '') as string;
  const options = _.orderBy(_.uniq(_.compact([value, ...entries.map((entry) => entry.entryCode)])));

  return (
    <>
      <Controller
        control={control}
        render={({ field: radioField }) => (
          <RadioGroup aria-labelledby="report-radios-group" name={field.entryCode} row>
            {options.map((item) => (
              <Box key={item}>
                <FormControlLabel
                  {...radioField}
                  control={
                    <Radio
                      value={item}
                      size="small"
                      onChange={() => {
                        setValue(field.entryCode, item, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                    />
                  }
                  label={item}
                  disabled={isSubmitting}
                />
              </Box>
            ))}
          </RadioGroup>
        )}
        name={field.entryCode}
      />
    </>
  );
}

export default RadioField;
