import { useQueries, useQuery } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import SearchAPI, { SearchItem } from '../api/SearchAPI';
import useUserData, { UserType } from './useUserData';
import { JAVA_SERVICE_ENDPOINTS, PYTHON_SERVICE_ENDPOINTS } from '../libs/services/endpoints';
import { queryClient } from '../config/queryClient';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import { ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import { PartnerType } from 'pages/stakeholders/hooks/useStakeholdersQuery';
import useSearchTabs from 'pages/search/hooks/useSearchTabs';

export const useSearchQuery = <T = any>({
  searchKeyword = '',
  shouldFetch = true,
  type = 'articles',
}: {
  searchKeyword?: string;
  shouldFetch?: boolean;
  type: 'articles' | 'users' | 'projects' | 'stakeholders';
}) => {
  const { companyId } = useUserData();

  const { data: searchedData, ...rest } = useQuery<AxiosResponse<T>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH, companyId, searchKeyword, type],
    () =>
      SearchAPI.search({
        payload: {
          companyId,
          novaSearchKeyword: searchKeyword,
          novaSearchType: type,
        },
      }),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!type && shouldFetch,
    },
  );

  const data = useMemo(() => {
    return searchedData?.data || [];
  }, [searchedData]);

  return {
    data,
    ...rest,
  };
};

export const useSearchItemQuery = <T = any>(query: SearchItem, shouldSearch = true) => {
  const { data: searchedData, ...rest } = useQuery<AxiosResponse<T>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH_ITEM, query.searchType],
    () => SearchAPI.searchItem(query),
    {
      refetchOnMount: 'always',
      staleTime: 5 * 60 * 1000,
      enabled: shouldSearch,
    },
  );

  const data = useMemo(() => {
    return searchedData?.data;
  }, [searchedData]);

  return {
    data,
    ...rest,
  };
};

export type FileSearchType = {
  chunkContent?: string[];
  documentName: string;
  documentPDF?: string;
  documentPath: string;
  documentURL: string;
  pageNo?: string[];
  projectId?: string;
  projectName?: string;
};

export type QueriesType = ArticleType | UserType | ProjectType | PartnerType | FileSearchType;
export type SearchQueriesType =
  | ({
      items: QueriesType[];
      gptPrompt?: {
        id: string;
      };
      llmResponse?: string;
    } & Record<string, any>)
  | null;

/*
    Note: Where ever your using this queries result, Please be cautious when adding this returned queries result in your useEffect dependency array.
    
    React-query has itself has an issue with this hook. It's forcing the consumed component to only update the same and consistent response from this queries result. And it will cause infinite component re-renders if we update state with any reference type other than queries result response type in useEffect where this queries result is added as a dependency.
    
    References: https://github.com/TanStack/query/issues/2991#issuecomment-1469918496, https://github.com/TanStack/query/issues/5137
  */
export const useSearchQueries = ({ searchKeyword = '' }) => {
  const { SEARCH_TABS } = useSearchTabs();
  const { companyId } = useUserData();
  const queries = useQueries<any>(
    SEARCH_TABS.map((tab) => {
      return {
        queryKey: [
          tab.endpoint || JAVA_SERVICE_ENDPOINTS.SEARCH,
          companyId,
          searchKeyword,
          tab.type,
        ],
        queryFn: () =>
          tab.service
            .search({
              payload: {
                ...tab.payload,
                novaSearchKeyword: searchKeyword,
              },
              endpoint: tab.endpoint,
            })
            .then((res) => {
              // This is to keep the response type consistent for queries. Currently, Articles response isn't consistent when there is not data, getting object instead if []. This can be removed once BE team fixes this issue.
              if (tab.type === 'articles') {
                return {
                  ...res,
                  data: {
                    items: res.data?.articles || [],
                    ...res.data,
                  },
                };
              }

              if (tab.type === 'companyFiles' || tab.type === 'projectFileSearch') {
                return {
                  ...res,
                  data: {
                    items: res.data?.documents || [],
                    ...res.data,
                  },
                };
              }

              if (!Array.isArray(res?.data)) {
                return {
                  ...res,
                  data: {
                    items: [],
                  },
                };
              }

              return {
                ...res,
                data: {
                  items: res?.data || [],
                },
              };
            }),
        staleTime: 60 * 1000,
        enabled: !!companyId && !!searchKeyword && !!tab.type,
      };
    }),
  );

  return {
    queries,
  };
};

export const updateSearchQueriesCache = <T extends { id: string }>(
  companyId: string,
  searchKeyword: string,
  tab: 'articles' | 'users' | 'projects' | 'stakeholders' | 'companyFiles' | 'projectFileSearch',
  updatedItem: T,
  index?: number,
) => {
  queryClient.setQueriesData<AxiosResponse<any> | undefined>(
    [PYTHON_SERVICE_ENDPOINTS.ARTICLES_SEARCH, companyId, searchKeyword, tab],
    (oldData: AxiosResponse<any> | undefined) => {
      if (oldData) {
        const newData = [...(oldData?.data?.items || [])];
        if (index && index > -1 && newData[index].id === updatedItem.id) {
          newData[index] = updatedItem as T;
        } else {
          const itemIndex = newData.findIndex((insight) => insight.id === updatedItem.id);
          if (itemIndex > -1) {
            newData[itemIndex] = updatedItem as T;
          }
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            items: newData,
          },
        };
      }

      return oldData;
    },
  );
};

export default useSearchQuery;
