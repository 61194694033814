import React, { Dispatch, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import Avatar from 'components/common/avatar/Avatar';
import { styled } from '@mui/material/styles';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { ArticleAttachmentsSummary } from '@buildwise/pages/article/hooks/useArticleAttachmentsSummary';
import {
  ArticleUserMapType,
  CommentType,
} from '@buildwise/pages/article/hooks/useArticleDetailsQuery';
import { FeedFilter } from 'api/KnowledgeAPI';
import {
  ProjectRecommendationsType,
  ProjectType,
} from '@buildwise/pages/projects/hooks/useProjectsQuery';
import LightbulbFlashFillIcon from 'remixicon-react/LightbulbFlashFillIcon';
import useArticle from '@buildwise/pages/knowledge/hooks/useArticle';
import RenderArticleUser from '@buildwise/pages/knowledge/components/RenderArticleUser';
import { formatDate, highlightSearchkeyWord, replaceMentions } from 'utility/helpers';
import Typography from '@mui/material/Typography';
import ArticleProjectDetails from '@buildwise/pages/knowledge/components/ArticleProjectDetails';
import ArticleCustomFields from '@buildwise/pages/knowledge/components/ArticleCustomFields';
import {
  ArticleTitle,
  ArticleUserContainer,
} from '@buildwise/pages/knowledge/components/ArticleBox';
import { Stack, Chip } from '@mui/material';
import Attachments from '@buildwise/pages/knowledge/components/Attachments';
import { ArticleDescription } from '@buildwise/pages/knowledge/components/ArticleBox';
import linkifyHtml from 'linkify-html';
import { decode } from 'html-entities';
import useUserData from 'hooks/useUserData';

export type ArticleVariantType =
  | 'detailed'
  | 'short'
  | 'draft'
  | 'list'
  | 'related-post'
  | 'playlist'
  | 'playlist-article';

type ArticlePropTypes = {
  article: ArticleType;
  articleAttachmentsSummary?: ArticleAttachmentsSummary;
  articleIndex?: number;
  comments?: CommentType[];
  doNotAutoplayVideos?: boolean;
  isRecommendedType?: boolean;
  project?: ProjectType | ProjectRecommendationsType;
  scrollInfoKey?: string;
  showAttachmentSummaryIndicatorSkeleton?: boolean;
  stakeholderId?: string;
  selectedFeedFilters?: FeedFilter[];
  userMap?: ArticleUserMapType;
  variant?: ArticleVariantType;
  bookmarkUpdateCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  deleteArticleCallback?: (options: { article: ArticleType }) => void;
  endorseArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  likeUpdateCallback?: (options: { oldArticle: ArticleType; updatedArticle: ArticleType }) => void;
  resetFeed?: (options?: {
    resetQueries?: boolean | undefined;
    smoothScrollBehavior?: boolean | undefined;
  }) => void;
  updateArticleCallback?: (options: {
    oldArticle: ArticleType;
    updatedArticle: ArticleType;
  }) => void;
  showCheckbox?: boolean;
  exportedArticles?: ArticleType[];
  setArticlePreviewInfo?: Dispatch<React.SetStateAction<ArticleType[]>>;
};

const ArticlePrintPreview: React.FC<ArticlePropTypes> = ({
  article,
  articleAttachmentsSummary,
  articleIndex,
  project,
  stakeholderId,
  selectedFeedFilters,
  variant = 'detailed',
  bookmarkUpdateCallback,
  deleteArticleCallback,
  endorseArticleCallback,
  likeUpdateCallback,
  resetFeed,
  updateArticleCallback,
  exportedArticles,
}) => {
  const {
    documents,
    isPublishedByBuildWiseInsights,
    isRelatedPostView,
    mediaFiles,
    searchKeyword,
    dispatch,
  } = useArticle({
    article,
    articleIndex,
    project,
    selectedFeedFilters,
    stakeholderId,
    variant,
    bookmarkUpdateCallback,
    deleteArticleCallback,
    endorseArticleCallback,
    likeUpdateCallback,
    resetFeed,
    updateArticleCallback,
  });
  const { company, user } = useUserData();
  const articleDescriptionRef = useRef<HTMLParagraphElement | null>(null);

  const RenderedTitle = useMemo(() => {
    if (!searchKeyword) {
      return (
        <ArticleTitle variant="h5" className={variant}>
          {article.title}
        </ArticleTitle>
      );
    }
    return (
      <ArticleTitle
        variant="h5"
        className={variant}
        dangerouslySetInnerHTML={{
          __html: highlightSearchkeyWord(article?.title, searchKeyword),
        }}
      />
    );
  }, [article]);

  const RenderedDescription = useMemo(() => {
    if (!article.description) return null;
    let _text = replaceMentions(article.description, article.mentions);
    if (searchKeyword) {
      _text = highlightSearchkeyWord(_text, searchKeyword);
    }
    return (
      <ArticleDescription
        ref={isRelatedPostView ? null : articleDescriptionRef}
        className={variant}
        dangerouslySetInnerHTML={{ __html: linkifyHtml(decode(_text), { target: '_blank' }) }}
      />
    );
  }, [article]);

  const RenderPdfContentPage = useMemo(() => {
    return (
      <>
        <ArticleLegend>
          <$BoldText fontSize={20}>Exported Posts ({exportedArticles?.length})</$BoldText>
          {exportedArticles?.map((item, index) => (
            <Typography key={item.id} marginTop={2}>
              {`${index + 1}. ${item.title}`}
            </Typography>
          ))}
        </ArticleLegend>
      </>
    );
  }, [company, user, exportedArticles]);

  return (
    <>
      {articleIndex === 0 && RenderPdfContentPage}
      <Box className="page-break" style={{ marginLeft: '-4px' }}>
        <Box display="flex">
          <AvatarContainer>
            <Avatar
              src={article?.profileImageURL}
              size={38}
              name={isPublishedByBuildWiseInsights ? '' : article.userNameToDisplay}
              icon={<LightbulbFlashFillIcon color="#FFC107" />}
              bgcolor={isPublishedByBuildWiseInsights ? '#FFF8E5' : ''}
            />
          </AvatarContainer>
          <ArticleContent className={variant}>
            <ArticleUserContainer className={variant}>
              <RenderArticleUser
                source="preview"
                article={article}
                dispatch={dispatch}
                isPublishedByBuildWiseInsights={isPublishedByBuildWiseInsights}
              />
              <Box display="flex" alignItems="center" mt={-2.5}>
                <$ArticleDate variant="caption" mr={0.5}>
                  {formatDate(article.isDraft ? article.createdAt : article.publishedAt)}
                </$ArticleDate>
              </Box>
            </ArticleUserContainer>
            <ArticleInfo>
              {RenderedTitle}

              {article?.labels?.length > 0 && (
                <Stack direction="row" flexWrap="wrap" my={1}>
                  {article.labels.map((label) => (
                    <Chip
                      key={`${article.id}-${label}`}
                      label={label}
                      variant="outlined"
                      sx={{ mr: 0.5, mb: 0.5, cursor: 'pointer' }}
                    />
                  ))}
                </Stack>
              )}
              {mediaFiles.length > 0 && (
                <Attachments
                  attachments={mediaFiles}
                  article={article}
                  variant={variant}
                  source="preview"
                />
              )}
              {RenderedDescription}
            </ArticleInfo>
            <$Title>Additional Details</$Title>
            <ArticleProjectDetails
              article={article}
              documents={documents}
              searchKeyword={searchKeyword}
              articleAttachmentsSummary={articleAttachmentsSummary}
              source="preview"
            />
            {article.customFields && <ArticleCustomFields article={article} />}
          </ArticleContent>
        </Box>
      </Box>
    </>
  );
};

export const $ArticleDate = styled(Typography, { label: 'ArticleDate' })({
  fontSize: 12,
  fontWeight: 400,
});

const $Title = styled(Typography, { label: 'Title' })({
  fontSize: 14,
  fontWeight: 700,
  marginTop: 16,
});

const ArticleInfo = styled(Box, { label: 'ArticleInfo' })({
  marginTop: 2,
});

const $User = styled(Typography, { label: 'User' })({
  fontWeight: 'bold',
  fontSize: '16px',
});

const $BoldText = styled(Typography, { label: 'Typography' })<{ fontSize?: number }>(
  ({ theme, fontSize = 24 }) => ({
    fontSize,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.custom.body,
    lineHeight: '27px',
  }),
);

const ArticleLegend = styled(Box, { label: 'ArticleLegend' })({
  borderTop: '1px solid #ededf1',
  paddingTop: 16,
});

export const AvatarContainer = styled(Box, { label: 'AvatarContainer' })({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '38px',
});

export const ArticleContent = styled(Box, { label: 'ArticleContent' })({
  flex: 1,
  overflow: 'hidden',
  padding: '0 0 8px 12px',
  '&.list': {
    paddingLeft: 0,
    paddingTop: 24,
  },
  '& img': {
    width: '100% !important',
    maxWidth: '100% !important',
    height: '100% !important',
    maxHeight: '100% !important',
    overflow: 'hidden',
  },
});

export default ArticlePrintPreview;
