import { useQuery } from 'react-query';
import { useMemo } from 'react';
import useUserData from 'hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { AxiosResponse } from 'axios';
import QuestionsAPI from 'api/QuestionsAPI';
import { queryClient } from 'config/queryClient';
import { CommentType, CommentReplyType } from 'pages/article/hooks/useArticleDetailsQuery';
import { QuestionType } from 'pages/audience/hooks/useQuestionsQueries';

type QuestionQueryPropTypes = {
  questionId: string;
};

export const useQuestionDetailsQuery = ({ questionId }: QuestionQueryPropTypes) => {
  const { companyId } = useUserData();

  const {
    data: questionData,
    refetch,
    isLoading,
  } = useQuery<AxiosResponse<QuestionType>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_QUESTION.replace(':companyId', companyId).replace(
        ':questionId',
        questionId,
      ),
    ],
    () => QuestionsAPI.getQuestion(companyId, questionId),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      refetchOnMount: 'always',
      enabled: !!companyId && !!questionId,
    },
  );

  const question = useMemo(() => {
    return questionData?.data;
  }, [questionData]);

  return {
    question,
    isLoading,
    refetch,
  };
};

type CommentsResponse = {
  questionId: string;
  companyId: string;
  comments: CommentType[];
};

export const useQuestionCommentsQuery = ({ questionId }: QuestionQueryPropTypes) => {
  const { companyId } = useUserData();
  const {
    data: commentsData,
    refetch,
    isLoading,
  } = useQuery<AxiosResponse<CommentsResponse>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_QUESTION_COMMENTS.replace(':companyId', companyId).replace(
        ':questionId',
        questionId,
      ),
    ],
    () => QuestionsAPI.getQuestionComments(companyId, questionId),
    {
      staleTime: 60 * 1000,
      refetchOnMount: 'always',
      enabled: !!companyId && !!questionId,
    },
  );

  const comments = useMemo(() => {
    return commentsData?.data?.comments;
  }, [commentsData]);

  return {
    companyId,
    comments,
    isLoading,
    refetch,
  };
};

export const updateQuestionCommentsCache = (
  companyId: string,
  questionId: string,
  comment: CommentType | CommentReplyType | undefined,
  action: 'add' | 'update' | 'like' | 'reply',
  commentPath: string = '',
) => {
  queryClient.setQueriesData<AxiosResponse<CommentsResponse> | undefined>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_QUESTION_COMMENTS.replace(':companyId', companyId).replace(
        ':questionId',
        questionId,
      ),
    ],
    (oldData: AxiosResponse<CommentsResponse> | undefined) => {
      if (oldData && comment) {
        const oldComments = [...(oldData?.data?.comments || [])];

        if (action === 'add') {
          oldComments.unshift(comment);
        }

        if (commentPath) {
          let comments = oldComments;
          const commentIds = commentPath.split('/');

          for (let i = 0; i < commentIds.length; i++) {
            const commentIndex = comments.findIndex((comment) => comment.id === commentIds[i]);

            if (commentIndex === -1) {
              break;
            }

            if (commentIndex > -1) {
              if (i !== commentIds.length - 1) {
                comments = comments[commentIndex]?.replies || [];
              } else {
                if (action === 'update' || action === 'like') {
                  comments[commentIndex] = {
                    ...comment,
                    replies: comments[commentIndex].replies,
                  };
                }

                if (action === 'reply') {
                  if (comments[commentIndex].replies) {
                    comments[commentIndex].replies?.unshift(comment as CommentReplyType);
                  } else {
                    comments[commentIndex] = {
                      ...comments[commentIndex],
                      replies: [comment as CommentReplyType],
                    };
                  }
                }
              }
            }
          }
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            comments: [...oldComments],
          },
        };
      }

      return oldData;
    },
  );
};

export default useQuestionDetailsQuery;
