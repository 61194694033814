import { Dispatch, FC, KeyboardEvent, SetStateAction, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import RenderPostsInEditPlayListModal from 'pages/knowledge/components/RenderPostsInEditPlaylistModal';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { useFormContext } from 'react-hook-form';
import PostTitle from './PostTitle';
import PostLabels from './PostLabels';
import PostDescription from './PostDescription';
import PostAttachments from './PostAttachments';
import PostTopics from './PostTopics';
import PostProject from './PostProject';
import PostPartner from './PostPartner';
import PostPlaylists from './PostPlaylists';
import PostDivisionsAndSpecSections from './PostDivisionsAndSpecSections';
import PostProjectPhases from './PostProjectPhases';
import useUserData from 'hooks/useUserData';
import PostCustomFields from './PostCustomFields';
import { ConfigEntryType } from 'config/config';
import { useConfigQuery } from 'hooks/useCompanyQueries';

type PostDetailsFormPropTypes = {
  applicationBasedFields: string[];
  article: ArticleType | undefined;
  forward: boolean;
  isEditing: boolean;
  isExpanded: boolean;
  isFullScreen: boolean;
  loadingFiles: boolean;
  selectedTemplateId: string | undefined;
  showModal: boolean;
  standardAndCustomFields: ConfigEntryType[];
  handleLabelKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onDrop: (acceptedFiles: File[]) => void;
  removeLabel: (label: string) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const PostDetailsForm: FC<PostDetailsFormPropTypes> = ({
  applicationBasedFields,
  article,
  forward,
  isEditing,
  isExpanded,
  isFullScreen,
  loadingFiles,
  selectedTemplateId,
  showModal,
  standardAndCustomFields,
  handleLabelKeyDown,
  onDrop,
  removeLabel,
  setShowModal,
}) => {
  const { company, companyId } = useUserData();
  const { watch, getValues, setValue } = useFormContext<PostFormData>();
  const title = watch('title');
  const [postTitle, setPostTitle] = useState(title);
  const { isLoading: articleFieldsLoading } = useConfigQuery(companyId, 'ARTICLE_FIELDS');

  return (
    <>
      <PostTitle
        selectedTemplateId={selectedTemplateId}
        showModal={showModal}
        setPostTitle={setPostTitle}
      />
      <PostLabels
        isExpanded={isExpanded}
        handleLabelKeyDown={handleLabelKeyDown}
        removeLabel={removeLabel}
      />
      <PostDescription
        isEditing={isEditing}
        isExpanded={isExpanded}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <PostAttachments
        article={article}
        isExpanded={isExpanded}
        onDrop={onDrop}
        loadingFiles={loadingFiles}
      />

      {isExpanded && !articleFieldsLoading && (
        <>
          <Box width={isFullScreen ? '25%' : '50%'} mt={3}>
            {!forward && applicationBasedFields.includes('topics') && (
              <PostTopics isExpanded={isExpanded} />
            )}
            {company?.settings?.projectFlag &&
              !forward &&
              applicationBasedFields.includes('projectId') && (
                <PostProject isExpanded={isExpanded} />
              )}
            {!forward && applicationBasedFields.includes('projectPhases') && (
              <PostProjectPhases isExpanded={isExpanded} />
            )}
            {company?.settings.partnerFlag &&
              !forward &&
              applicationBasedFields.includes('partnerIds') && (
                <PostPartner isExpanded={isExpanded} />
              )}
            {!isEditing &&
              article?.originIndicator !== 'PLAYLIST' &&
              !forward &&
              applicationBasedFields.includes('playListArticleIds') && (
                <PostPlaylists isEditing={isEditing} isExpanded={isExpanded} />
              )}
            <PostCustomFields fields={standardAndCustomFields} />
          </Box>
          {applicationBasedFields.includes('divisions') && (
            <PostDivisionsAndSpecSections
              isExpanded={isExpanded}
              isFullScreen={isFullScreen}
              postTitle={postTitle}
              applicationBasedFields={applicationBasedFields}
            />
          )}
        </>
      )}
      {article?.originIndicator && article?.originIndicator === 'PLAYLIST' && (
        <RenderPostsInEditPlayListModal
          playListArticles={article?.playListArticles}
          article={article}
          getValues={getValues}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default PostDetailsForm;
