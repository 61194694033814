import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import useAuthStore from 'store/useAuthStore';
import { shallow } from 'zustand/shallow';
import { AxiosResponse } from 'axios';
import { CompanyType } from './useCompanyQueries';

function useCompanyQueryData() {
  const queryClient = useQueryClient();
  const [userDetails] = useAuthStore((state) => [state.user], shallow);
  const company = useMemo(
    () =>
      queryClient.getQueryData<AxiosResponse<CompanyType, Error>>([
        JAVA_SERVICE_ENDPOINTS.GET_COMPANY_DETAILS,
        userDetails?.companyId,
      ])?.data,
    [],
  );
  const { shouldDisplayCompanyOnUserCards } = useMemo(() => {
    return {
      shouldDisplayCompanyOnUserCards: !!company?.settings?.displayCompanyOnUserProfile,
    };
  }, [company]);

  return {
    company,
    shouldDisplayCompanyOnUserCards,
  };
}

export default useCompanyQueryData;
