import { PythonAPI } from '@buildwise/libs/services';
import { GlobalSearchPayload } from './SearchAPI';

class PythonSearchAPI extends PythonAPI {
  public search({ endpoint, payload }: { endpoint: string; payload: GlobalSearchPayload }) {
    return this.post(endpoint, payload);
  }
}

const pythonSearchAPI = new PythonSearchAPI();
export default pythonSearchAPI;
