import { useMemo } from 'react';
import { AttachmentType } from '../hooks/useArticlesQuery';
import { getFileDetails } from 'utility/helpers';
import RenderAudio from './RenderAudio';
import RenderImage from './RenderImage';
import RenderVideo from './RenderVideo';
import { Box } from '@mui/material';

type RenderPlaylistArticleThumbnailProps = {
  attachment: AttachmentType;
};

function RenderPlaylistArticleThumbnail({ attachment }: RenderPlaylistArticleThumbnailProps) {
  const { isImage, isVideo, isAudio } = useMemo(
    () => getFileDetails({ file: attachment, useDefaultStrToSliceFromFileName: true }),
    [attachment],
  );

  if (isImage) {
    return <RenderImage attachment={attachment} variant="playlist-article" />;
  }

  if (isVideo) {
    return <RenderVideo doNotAutoplayVideos attachment={attachment} variant="playlist-article" />;
  }

  if (isAudio) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <RenderAudio attachment={attachment} variant="playlist-article" />
      </Box>
    );
  }

  return null;
}

export default RenderPlaylistArticleThumbnail;
