import {
  FormControl,
  Autocomplete,
  TextField,
  CircularProgress,
  TextFieldProps,
} from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { useFormContext } from 'react-hook-form';
import useArticleFieldsOptions from './useArticleFieldsOptions';

type PostSelectCustomFieldProps = {
  field: ConfigEntryType;
  TextFieldProps?: TextFieldProps;
};

function PostSelectCustomField({ field, TextFieldProps }: PostSelectCustomFieldProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext();
  const value = (watch(field.entryCode) || '') as string;
  const { options, isLoading } = useArticleFieldsOptions({
    field,
    value: [{ label: value, value }],
  });

  const handleSelect = (_: unknown, values: { label: string; value: string } | null) => {
    setValue(field.entryCode, values?.value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          onChange={handleSelect}
          options={options}
          autoHighlight
          value={value ? options.find((option) => option.value === value) : null}
          noOptionsText={isLoading ? 'Loading...' : 'No Data Found'}
          renderInput={(params) => (
            <TextField
              placeholder={field.referenceObject?.placeHolder || 'Select'}
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              {...TextFieldProps}
            />
          )}
          disabled={isSubmitting}
        />
      </FormControl>
    </>
  );
}

export default PostSelectCustomField;
