import {
  FormControl,
  Autocomplete,
  TextField,
  CircularProgress,
  Chip,
  TextFieldProps,
} from '@mui/material';
import { ConfigEntryType } from 'config/config';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import useArticleFieldsOptions from './useArticleFieldsOptions';

type PostMultiSelectCustomFieldProps = {
  field: ConfigEntryType;
  TextFieldProps?: TextFieldProps;
};

function PostMultiSelectCustomField({ field, TextFieldProps }: PostMultiSelectCustomFieldProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext();
  const value = (watch(field.entryCode) || []) as string[];
  const { options, isLoading } = useArticleFieldsOptions({
    field,
    value: value.map((item) => ({ label: item, value: item })),
  });
  const handleSelect = (_: unknown, values: { label: string; value: string }[]) => {
    setValue(
      field.entryCode,
      values.map((item) => item.value),
      { shouldDirty: true, shouldValidate: true },
    );
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          multiple
          limitTags={3}
          disableCloseOnSelect
          onChange={handleSelect}
          options={options}
          autoHighlight
          value={value ? options.filter((item) => value.includes(item.value)) : []}
          noOptionsText={isLoading ? 'Loading...' : 'No Data Found'}
          renderInput={(params) => (
            <TextField
              placeholder={field.referenceObject?.placeHolder || 'Select'}
              variant="outlined"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              {...TextFieldProps}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.label}
                deleteIcon={<CloseLineIcon size={20} />}
              />
            ))
          }
          disabled={isSubmitting}
        />
      </FormControl>
    </>
  );
}

export default PostMultiSelectCustomField;
