import { ConfigEntryType } from 'config/config';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import useUserData from 'hooks/useUserData';
import _ from 'lodash';
import useArticleFieldsCommonQuery from './useArticleFieldsCommonQuery';
import { useMemo } from 'react';

function useArticleFieldsOptions({
  field,
  value,
}: {
  field: ConfigEntryType;
  value: { label: string; value: string }[];
}) {
  const { companyId } = useUserData();
  const { data, isLoading: dataLoading } = useArticleFieldsCommonQuery({
    endpoint: field?.targetMapper || '',
    shouldFetch: field.targetMapperType === 'API' || !!field.targetMapper,
  });
  const { entries, isLoading: configLoading } = useConfigQuery(
    companyId,
    field.referenceObject?.configRefName || field?.entryCode || '',
    field.targetMapperType === 'CONFIG' || !!field.referenceObject?.configRefName,
  );
  const options = useMemo(
    () =>
      _.orderBy(
        _.uniqBy(
          _.filter(
            [
              ...(field.targetMapperType === 'API'
                ? data.map((item) => ({
                    label: item?.[field?.targetMapperLabelName || ''],
                    value: item?.[field?.targetMapperValueKey || ''],
                  }))
                : entries.map((entry) => ({ label: entry.entryCode, value: entry.entryCode }))),
              ...value,
            ],
            (entry) => !!entry.value,
          ),
          'value',
        ),
      ),
    [data, entries, field, value],
  );

  return {
    options,
    isLoading: dataLoading || configLoading,
  };
}

export default useArticleFieldsOptions;
