import { Button, Box, Typography, Skeleton, Avatar } from '@mui/material';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import usePromptTemplates from './hooks/usePromptTemplates';
import { styled, useTheme } from '@mui/material/styles';
import RenderPromptTemplate, { PromptTemplateContainer } from './RenderPromptTemplate';
import HandleOperation from 'components/utils/HandleOperation';
import { Virtuoso } from 'react-virtuoso';
import AddPromptTemplateModal from './AddPromptTemplateModal';
import ArticleLineIcon from 'remixicon-react/ArticleLineIcon';
import PromptTemplatesSearch from './PromptTemplatesSearch';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';

function PromptTemplates() {
  const {
    deletingTemplate,
    openTemplateModal,
    searchKeyword,
    templates,
    isLoading,
    deletePromptTemplate,
    setOpenTemplateModal,
    setSearchKeyword,
  } = usePromptTemplates();
  const theme = useTheme();

  return (
    <Container>
      <StickyContainer>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Title>Templates for Prompts</Title>
          <Button
            startIcon={<AddLineIcon />}
            variant="outlined"
            sx={(theme) => ({ bgcolor: theme.palette.custom.white })}
            onClick={() => setOpenTemplateModal(true)}
          >
            Create New Template
          </Button>
        </Box>
        <PromptTemplatesSearch value={searchKeyword} setValue={setSearchKeyword} />
      </StickyContainer>
      <Box mb={15}>
        <HandleOperation
          isLoading={isLoading}
          data={templates}
          skelton={
            <PromptTemplateContainer>
              <Skeleton animation="wave" variant="text" width="70%" height={25} />
              <Box my={1}>
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="60%" height={20} />
              </Box>
              <Box my={1}>
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="100%" height={20} />
                <Skeleton animation="wave" variant="text" width="60%" height={20} />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Avatar variant="circular" sx={{ mr: 1, width: 25, height: 25 }} />
                  <Skeleton animation="wave" variant="text" width="150px" height={25} />
                </Box>
                <Skeleton animation="wave" variant="text" width="150px" height={25} />
              </Box>
            </PromptTemplateContainer>
          }
          noDataComponent={
            <NoDataContainer>
              {searchKeyword ? (
                <>
                  <SearchLineIcon size={120} color={theme.palette.custom.steel} />
                  <NoDataDescription mt={1.5}>
                    No templates found with <b>"{searchKeyword}"</b>
                  </NoDataDescription>
                </>
              ) : (
                <>
                  <ArticleLineIcon size={120} color={theme.palette.custom.steel} />
                  <NoDataDescription mt={1.5}>No templates found</NoDataDescription>
                </>
              )}
            </NoDataContainer>
          }
        >
          <Virtuoso
            useWindowScroll
            data={templates}
            itemContent={(_, template) => (
              <RenderPromptTemplate
                key={template.id}
                template={template}
                deletingTemplate={deletingTemplate}
                deletePromptTemplate={deletePromptTemplate}
              />
            )}
          />
        </HandleOperation>
      </Box>

      {openTemplateModal && (
        <AddPromptTemplateModal open={openTemplateModal} setOpen={setOpenTemplateModal} />
      )}
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  maxWidth: '80%',
  margin: '0 auto',
}));

const Title = styled(Typography, { label: 'Title' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 24,
  color: theme.palette.custom.tuna,
}));

const StickyContainer = styled(Box, { label: 'StickyContainer' })(({ theme }) => ({
  position: 'sticky',
  top: 79,
  padding: '8px 0 16px',
  backgroundColor: theme.palette.custom.background,
  zIndex: 1,
  width: '100%',
}));

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '40px 24px 0',
});

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
}));

export default PromptTemplates;
