import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { RequestPostFormSchema, UsersSearchResponseType } from './RequestPostModal';
import {
  FormControl,
  Autocomplete,
  Box,
  TextField,
  CircularProgress,
  Typography,
  Collapse,
} from '@mui/material';
import Dot from 'components/common/dot/Dot';
import UserAvatar from 'components/common/userAvatar/UserAvatar';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchItemQuery } from 'hooks/useSearchQuery';
import useUserData from 'hooks/useUserData';
import { UserOptionType } from 'components/modals/shareModal/useSharePostModalState';
import useCompanyQueryData from 'hooks/useCompanyQueryData';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from 'config/theme';
import CalendarFillIcon from 'remixicon-react/CalendarFillIcon';
import _ from 'lodash';

function RequestPostForm() {
  const { user, company } = useUserData();
  const { shouldDisplayCompanyOnUserCards } = useCompanyQueryData();
  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
  } = useFormContext<RequestPostFormSchema>();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showPermittedDomainsBanner, setShowPermittedDomainsBanner] = useState(false);
  const validEmailDomains = useMemo(
    () => _.filter([company?.emailDomain || '', ...(company?.otherEmailDomains || [])], _.length),
    [company?.emailDomain, company?.otherEmailDomains],
  );
  const { data: peopleData, isLoading: peopleLoading } =
    useSearchItemQuery<UsersSearchResponseType>(
      {
        searchType: 'users',
        operator: 'or',
        filterBy: {
          filters: [
            {
              filterType: 'name',
              filterValue: 'ALL',
            },
          ],
        },
      },
      true,
    );
  const peopleOptions = useMemo(
    () =>
      _.uniqBy(
        (peopleData?.userResponse || [])
          .filter((_user) => _user.username !== user?.username)
          .map((userData) => ({
            ...userData,
            label: `${userData.userNameToDisplay} - ${userData.email}`,
            value: userData.username,
          })) as UserOptionType[],
        (x) => x.value.toLowerCase(),
      ),
    [peopleData],
  );

  useEffect(() => {
    if (errors?.assignedTo?.type === 'INVALID_EMAIL_DOMAIN') {
      setShowPermittedDomainsBanner(true);
    }
  }, [errors?.assignedTo?.type]);

  const handleSelectUser = useCallback(
    (_: SyntheticEvent<Element, Event>, _user: UserOptionType | string | null) => {
      setValue('assignedTo', typeof _user === 'string' ? _user : _user?.username || '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [],
  );

  const getFilteredUsers = (users: UserOptionType[]) =>
    users.filter(
      (_user) =>
        _user.userNameToDisplay.toLowerCase().includes(inputValue.toLowerCase()) ||
        _user.username.toLowerCase().includes(inputValue.toLowerCase()),
    );

  const isWeekend = (date: Dayjs) => {
    const day = date.day();

    return day === 0 || day === 6;
  };

  return (
    <>
      <Collapse in={showPermittedDomainsBanner && validEmailDomains.length > 0}>
        <ValidDomainsContainer>
          <Typography fontWeight={700}>Domains permitted:</Typography>
          {validEmailDomains.join(', ')}
        </ValidDomainsContainer>
      </Collapse>

      <Controller
        control={control}
        name="assignedTo"
        render={({ field: { value, ...rest }, fieldState }) => (
          <FormControl fullWidth>
            <Autocomplete
              {...rest}
              freeSolo
              onChange={handleSelectUser}
              value={peopleOptions.find((_user) => _user.username === value) || value || null}
              inputValue={inputValue}
              onInputChange={(_, _value) => {
                setValue('assignedTo', _value.trim(), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setInputValue(_value);
              }}
              options={peopleOptions}
              filterOptions={(options) => getFilteredUsers(options)}
              fullWidth
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <UserAvatar
                    disableUserLink
                    avatarSize={40}
                    profileImageURL={option.profileImageURL}
                    title={`${option.userNameToDisplay} (${option.username})`}
                    avatarName={option.userNameToDisplay}
                    subTitle={
                      <>
                        {option.title}
                        {shouldDisplayCompanyOnUserCards &&
                          (option?.primaryCompanyName || option?.userPrimaryCompanyName) && (
                            <>
                              <Dot />
                              {option?.primaryCompanyName || option?.userPrimaryCompanyName}
                            </>
                          )}
                      </>
                    }
                    userName={option.username}
                  />
                </Box>
              )}
              autoHighlight
              noOptionsText={peopleLoading ? 'Loading...' : 'No Users Found'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a member"
                  placeholder="i.e. John Doe or john.doe@email.com"
                  autoFocus
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {peopleLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
              disabled={isSubmitting}
              sx={{ mb: 2 }}
              classes={{
                // DevNote: Explicitly hide options container styles when there are no options available for freeSolo input. See https://github.com/mui/material-ui/issues/40843
                paper: getFilteredUsers(peopleOptions).length === 0 ? 'hide-options-container' : '',
              }}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="requestMessage"
        render={({ field, fieldState }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              multiline
              minRows={8}
              maxRows={10}
              fullWidth
              label="Message"
              placeholder="Write a Message"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              disabled={isSubmitting}
            />
          </FormControl>
        )}
      />
      <Controller
        name="dueDate"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl sx={{ mt: 2, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                inputFormat="MMMM D, YYYY"
                disableMaskedInput
                InputAdornmentProps={{ position: 'start' }}
                components={{
                  OpenPickerIcon: () => <CalendarFillIcon color={theme.palette.custom.black} />,
                }}
                minDate={dayjs()}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography fontWeight={700} mr={0.5}>
                          Suggested Due Date
                        </Typography>
                        <Typography component="span">(optional)</Typography>
                      </Box>
                    }
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select Due Date',
                      style: { padding: '14px 0px' },
                    }}
                    variant="outlined"
                    onClick={() => setOpenDatePicker(true)}
                    autoComplete="off"
                  />
                )}
                onChange={(date) => {
                  setOpenDatePicker(false);
                  field.onChange(date);
                }}
                disabled={isSubmitting}
                shouldDisableDate={isWeekend}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
    </>
  );
}

const ValidDomainsContainer = styled(Box, { label: 'ValidDomainsContainer' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.inputBackground,
  borderRadius: 4,
  padding: 12,
  marginBottom: 16,
}));

export default RequestPostForm;
