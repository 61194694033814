import { ReactComponent as EqualLineIcon } from 'assets/icons/EqualLineIcon.svg';
import { Grid, Box, Typography, Tooltip, Switch, Divider, IconButton } from '@mui/material';
import { ConfigEntryType, ConfigType } from 'config/config';
import useUserData from 'hooks/useUserData';
import AdminAPI from '../apis/AdminAPI';
import useAdminConfigsByTypeQuery from '../queries/useAdminConfigsByTypeQuery';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTheme } from '@mui/material/styles';

type RenderEntryItemProps = {
  config: ConfigType;
  entry: ConfigEntryType;
  index: number;
};

function RenderEntryItem({ config, entry, index }: RenderEntryItemProps) {
  const theme = useTheme();
  const { isNovaAdmin } = useUserData();
  const [openTooltip, setOpenTooltip] = useState('');
  const { updateConfigsCache } = useAdminConfigsByTypeQuery({
    configType: 'INPUT_FIELDS',
  });
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: entry?.id || '',
    disabled: !isNovaAdmin,
  });

  const updateConfig = async ({
    config,
    updatedConfigEntry,
  }: {
    config: ConfigType;
    updatedConfigEntry: ConfigEntryType;
  }) => {
    const updatedConfigEntries = [...config.entries];
    const index = updatedConfigEntries.findIndex((item) => item.id === updatedConfigEntry.id);

    if (index > -1) {
      updatedConfigEntries[index] = {
        ...updatedConfigEntries[index],
        ...updatedConfigEntry,
      };
    }

    try {
      const { data } = await AdminAPI.updateConfig({
        ...config,
        entries: updatedConfigEntries,
      });
      setOpenTooltip(updatedConfigEntry.entryCode);

      setTimeout(() => {
        setOpenTooltip('');
      }, 2000);

      updateConfigsCache({ updatedConfig: data.config });
      if (hash) {
        navigate(pathname);
      }
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  return (
    <Grid
      item
      md={12}
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={isNovaAdmin ? 0.5 : 1}
        sx={{
          cursor: 'pointer',
          ...(isDragging && {
            boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
          }),
          '&:hover': {
            '& .sort-icon': {
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          ml={isNovaAdmin ? -4 : 0}
          {...attributes}
          {...listeners}
        >
          {isNovaAdmin && (
            <IconButton size="small" className="sort-icon" sx={{ visibility: 'hidden' }}>
              <EqualLineIcon width={24} color={theme.palette.custom.coolGrey} />
            </IconButton>
          )}
          <Typography ml={0.5}>
            {entry?.entryCode} {entry.entryDescription && `- ${entry.entryDescription}`}
          </Typography>
        </Box>
        {isNovaAdmin && (
          <Box>
            <Tooltip
              open={openTooltip === entry.entryCode}
              placement="top"
              title={
                <Typography>
                  {entry?.entryCode} is {entry.isActive ? 'activated' : 'deactivated'}
                </Typography>
              }
            >
              <Switch
                disableRipple
                sx={{ m: 1 }}
                checked={entry.isActive}
                onChange={(_, checked) =>
                  updateConfig({
                    config,
                    updatedConfigEntry: {
                      ...entry,
                      isActive: checked,
                    },
                  })
                }
              />
            </Tooltip>
            {/* <IconButton
          sx={{ ml: 1 }}
          onClick={() =>
            deleteConfig({
              config,
              deleteConfigEntry: entry,
            })
          }
        >
          <DeleteBinLineIcon size={20} color={theme.palette.custom.redWine} />
        </IconButton> */}
          </Box>
        )}
      </Box>
      {config.entries.length - 1 !== index && !isDragging && <Divider />}
    </Grid>
  );
}

export default RenderEntryItem;
