import { useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import useUserData from '../../../hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '../../../libs/services/endpoints';
import KnowledgeAPI from '../../../api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { ArticleType } from '../../knowledge/hooks/useArticlesQuery';

export const useBookmarksQuery = (options: { shouldFetch?: boolean } = {}) => {
  const { shouldFetch = true } = options;
  const queryClient = useQueryClient();
  const { companyId } = useUserData();
  const bookmarksQueryKey = [JAVA_SERVICE_ENDPOINTS.GET_ARTICLES_BOOKMARKED, companyId];

  const { data: bookmarksData, ...rest } = useQuery<AxiosResponse<ArticleType[]>, Error>(
    bookmarksQueryKey,
    () => KnowledgeAPI.getBookmarkedArticles(companyId),
    {
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const bookmarks = useMemo(
    () => (Array.isArray(bookmarksData?.data) ? bookmarksData?.data || [] : []),
    [bookmarksData],
  );

  const updatePostInBookmarkedPlaylistsCache = ({
    article,
    addToPlaylistIds = [],
    removeFromPlaylistIds = [],
  }: {
    article: ArticleType;
    addToPlaylistIds?: string[];
    removeFromPlaylistIds?: string[];
  }) => {
    queryClient.setQueryData<AxiosResponse<ArticleType[], Error> | undefined>(
      bookmarksQueryKey,
      (old) => {
        if (old) {
          let newPlaylists = [...(Array.isArray(old?.data) ? old?.data || [] : [])];
          let addToPlaylistIdsUpdatedCount = 0;
          let removeFromPlaylistIdsUpdatedCount = 0;

          if (addToPlaylistIds.length || removeFromPlaylistIds.length) {
            for (let i = 0; i < newPlaylists.length; i++) {
              const playlist = newPlaylists[i];
              const newPlaylistArticleIds = [...playlist?.playListArticleIds];
              const newPlaylistArticles = [...playlist?.playListArticles];

              // add article to playlist
              if (addToPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.push(article.id);
                newPlaylistArticles.push(article);
              }

              // remove article from playlist
              if (removeFromPlaylistIds.includes(playlist.id)) {
                newPlaylistArticleIds.splice(newPlaylistArticleIds.indexOf(article.id), 1);
                const index = newPlaylistArticles.findIndex(
                  (playListArticle) => playListArticle.id === article.id,
                );
                if (index > -1) {
                  newPlaylistArticles.splice(index, 1);
                }
              }

              newPlaylists[i] = {
                ...playlist,
                playListArticleIds: newPlaylistArticleIds,
                playListArticles: newPlaylistArticles,
              };

              // stop updating cache when all collections updated
              if (
                addToPlaylistIdsUpdatedCount === addToPlaylistIds.length &&
                removeFromPlaylistIdsUpdatedCount === removeFromPlaylistIds.length
              ) {
                break;
              }
            }
          }

          return {
            ...old,
            data: newPlaylists,
          };
        }

        return old;
      },
    );
  };

  const updateBookmarksCache = ({
    action,
    article,
  }: {
    action: 'add' | 'update' | 'remove';
    article: ArticleType;
  }) => {
    queryClient.setQueryData<AxiosResponse<ArticleType[]> | undefined>(bookmarksQueryKey, (old) => {
      if (old) {
        const newBookmarks = [...(old?.data || [])];

        if (action === 'add') {
          newBookmarks.push(article);
        }

        if (action === 'update') {
          const index = newBookmarks.findIndex((bookmark) => bookmark.id === article.id);
          if (index > -1) {
            newBookmarks[index] = {
              ...newBookmarks[index],
              ...article,
            };
          }
        }

        if (action === 'remove') {
          const index = bookmarks.findIndex((bookmark) => bookmark.id === article.id);
          if (index > -1) {
            newBookmarks.splice(index, 1);
          }
        }

        return {
          ...old,
          data: newBookmarks,
        };
      }
      return old;
    });
  };

  return {
    bookmarks,
    ...rest,
    updateBookmarksCache,
    updatePostInBookmarkedPlaylistsCache,
  };
};

export default useBookmarksQuery;
