import { OpenInFullRounded } from '@mui/icons-material';
import { Box, Button, IconButton, InputBase, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, forwardRef } from 'react';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import ChatNewFillIcon from 'remixicon-react/ChatNewFillIcon';
import SendPlane2FillIcon from 'remixicon-react/SendPlane2FillIcon';
import { styled, useTheme } from '@mui/material/styles';

type PromptInputProps = {
  hideRegenerate?: boolean;
  placeholder?: string;
  value: string;
  handleKeyDown: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  onNewSession?: () => void;
  onSend: (value: string) => void;
  regeneratePrompt?: (prompt?: string) => void;
  setFullViewModal: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const PromptInput = forwardRef<HTMLTextAreaElement, PromptInputProps>(
  (
    {
      hideRegenerate = false,
      placeholder,
      value,
      handleKeyDown,
      onNewSession,
      onSend,
      regeneratePrompt,
      setFullViewModal,
      setValue,
    },
    inputRef,
  ) => {
    const theme = useTheme();

    return (
      <Container>
        <InputContainer>
          {!hideRegenerate && (
            <RegenerateContainer onClick={() => regeneratePrompt?.()}>
              <RegenerateButton startIcon={<RefreshLineIcon />}>Regenerate</RegenerateButton>
            </RegenerateContainer>
          )}
          {onNewSession && (
            <Tooltip
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: { padding: '4px 8px', maxWidth: 'fit-content', mb: '8px !important' },
                },
              }}
              title={<Typography fontSize={14}>New Session</Typography>}
            >
              <IconButton
                sx={(theme) => ({
                  background: `linear-gradient(to bottom right, #091e427a 10%, ${theme.palette.custom.navy} 60%)`,
                  borderRadius: '50%',
                  width: 45,
                  height: 45,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1,
                  '&:hover': {
                    background: `linear-gradient(to bottom right, #091e427a 10%, ${theme.palette.custom.navy} 60%)`,
                  },
                })}
                onClick={onNewSession}
              >
                <ChatNewFillIcon size={26} color={theme.palette.custom.white} />
              </IconButton>
            </Tooltip>
          )}
          <SearchInput
            autoFocus
            multiline
            fullWidth
            inputRef={inputRef}
            placeholder={placeholder || 'Send a message'}
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
            startAdornment={
              <IconButton>
                <SearchLineIcon size={22} />
              </IconButton>
            }
            endAdornment={
              <Box display="flex" alignItems="center">
                <IconButton sx={{ mr: 0.5 }} onClick={() => setFullViewModal(true)}>
                  <OpenInFullRounded fontSize="small" />
                </IconButton>
                <IconButton onClick={() => onSend(value)}>
                  <SendPlane2FillIcon size={22} />
                </IconButton>
              </Box>
            }
            onKeyDown={handleKeyDown}
          />
        </InputContainer>
      </Container>
    );
  },
);

const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  width: '100%',
  position: 'sticky',
  bottom: 0,
  padding: '16px 0 24px',
  backgroundColor: theme.palette.custom.background,
  boxShadow: `0 -20px 30px ${theme.palette.custom.background}`,
}));

const InputContainer = styled(Box, { label: 'InputContainer' })({
  maxWidth: '80%',
  margin: '0 auto',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const RegenerateContainer = styled(Box, { label: 'RegenerateContainer' })({
  position: 'absolute',
  right: 0,
  bottom: '150%',
});

const RegenerateButton = styled(Button, { label: 'RegenerateButton' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  '&:hover': {
    backgroundColor: theme.palette.custom.white,
  },
}));

const SearchInput = styled(InputBase, { label: 'SearchInput' })(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  borderRadius: 8,
  padding: '6px !important',
  maxHeight: '150px !important',
  overflow: 'hidden !important',
  border: `2px solid ${theme.palette.custom.mistBlue}`,
  '&:focus-within': {
    borderColor: `${theme.palette.custom.mistBlue} !important`,
  },
  '&:has(.MuiInputBase-input:placeholder-shown)': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-input': {
    maxHeight: '150px !important',
    overflow: 'auto !important',
  },
}));

export default PromptInput;
