import { UseQueryResult, useQuery } from 'react-query';
import { useMemo } from 'react';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { QuestionType } from 'pages/audience/hooks/useQuestionsQueries';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import { ArticleType } from 'pages/knowledge/hooks/useArticlesQuery';
import CompanyAPI from 'api/CompanyAPI';
import { ProjectType } from 'pages/projects/hooks/useProjectsQuery';
import { ConfigResponse } from 'config/config';
import { queryClient } from 'config/queryClient';
import useUserData from './useUserData';

type CompanySettingsType = {
  askQuestionFlag: boolean;
  addScoreCardFlag: boolean;
  addVoicePost: boolean;
  articleTemplatesFlag: boolean;
  articlesLLMSearch: boolean;
  clientCredentialsFlag: boolean;
  cloneArticlesFromBuildWiseFlag: boolean;
  companyFilesLLMSearch: boolean;
  companyType?: string;
  displayCompanyOnUserProfile?: boolean;
  emailWeeklySummaryFlag: boolean;
  externalFlag: boolean;
  insightsFlag: boolean;
  integrationSystems: string[];
  integrations: {
    addRFIsToCompanyProject: boolean;
    addSpecsToCompanyProject: boolean;
    addSubmittalsToCompanyProject: boolean;
    addUsersToCompanyProject: boolean;
    autodeskFlag: boolean;
    createDraftPostsFromIncidentsFlag: boolean;
    dailyLogsFlag: boolean;
    incidentsFlag: boolean;
    inspectionsFlag: boolean;
    loginWithPMSystemOnly: boolean;
    observationsFlag: boolean;
    photosFlag: boolean;
    procoreDefaultPartnerRole: string;
    procoreFlag: boolean;
    projectDirectoryFlag: boolean;
    projectsFlag: boolean;
    punchListFlag: boolean;
    rfisFlag: boolean;
    specificationsFlag: boolean;
    submittalsFlag: boolean;
    usersFlag: boolean;
    vendorsFlag: boolean;
  };
  invitePermittedDomainsFlag?: string;
  partnerFlag: boolean;
  projectFlag?: boolean;
  pmSyncFlag: boolean;
  projectFilesLLMSearch: boolean;
  scoreCardFlag?: boolean;
  progressCardFlag: boolean;
  sendNotificationFlag: boolean;
  ssoFlag: boolean;
  subscriptionSettings: {
    allowInviteUsersFlag: boolean;
    allowUserSignUps: boolean;
    subscriptionPlan: string;
    subscriptionPlanList: string[];
    subscriptionsRemaining: number;
    subscriptionsUsed: number;
    totalSubscriptions: number;
  };
};

export type CompanyType = {
  address: string;
  companyLogoDisplay: string;
  companyVault?: {
    articles: ArticleType[];
    isPersonal: boolean | null;
    pageNumber: number;
    totalElements: number;
    totalPages: number;
  };
  companyVaultQuestions: QuestionType[];
  emailDomain: string;
  id: string;
  isActive: boolean;
  isArticleReviewNeeded: boolean;
  logoURL: string;
  masterFormat: string;
  name: string;
  otherEmailDomains: string[];
  pmmetrics?: {
    id: string | null;
    lastImportedDate: string | Date;
    pmcompanyId: string;
    pmsystemName: string;
    totalProjects: number;
    totalRFIs: number;
    totalSpecifications: number;
    totalSubmittals: number;
    totalUsers: number;
    totalVendors: number;
  };
  companyLogoURL: string;
  projectPhases: string[];
  projectTypes: string[];
  projects: ProjectType[];
  versionId: number;
  settings: CompanySettingsType;
  starred?: boolean;
};

export type SubscriptionSettingsType = {
  allowUserSignUps: boolean;
  subscriptionPlan: string;
  subscriptionPlanList: string[];
  subscriptionsRemaining: number;
  subscriptionsUsed: number;
  totalSubscriptions: number;
  subscriptionStartDate: string | undefined | null;
  subscriptionEndDate: string | undefined | null;
  allowInviteUsersFlag: boolean;
};

type PredictDivisionType = {
  config: {
    entries: DivisionType[];
  };
};

export type DivisionType = {
  entryCode: string;
  entryDescription: string;
  isActive: boolean;
  targetMapper: string;
};

export const useCompanyQuery = ({ companyId }) => {
  const {
    data: companyData,
    isLoading,
    refetch: refetchCompany,
  } = useQuery<AxiosResponse<CompanyType>, Error>(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId),
    () => CompanyAPI.getCompany(companyId),
    {
      onError: () => {},
      staleTime: 60 * 1000,
      enabled: !!companyId,
    },
  );
  const company = useMemo(() => companyData?.data, [companyData]);

  return {
    company,
    isLoading,
    refetchCompany,
  };
};

export const useCompaniesQuery = ({
  isAssociatedWithOtherCompanies,
}: {
  isAssociatedWithOtherCompanies: boolean;
}) => {
  const {
    data: companiesData,
    refetch: refetchCompanies,
    ...rest
  } = useQuery<AxiosResponse<[CompanyType[]]>, Error>(
    [
      isAssociatedWithOtherCompanies
        ? JAVA_SERVICE_ENDPOINTS.GET_USER_COMPANIES
        : JAVA_SERVICE_ENDPOINTS.GET_COMPANIES,
    ],
    () => CompanyAPI.getCompanies({ isAssociatedWithOtherCompanies }),
    {
      refetchOnMount: false,
      staleTime: 1 * 60 * 1000,
      keepPreviousData: true,
    },
  );
  const companies = useMemo(() => companiesData?.data[0] || [], [companiesData]);

  return {
    companies,
    ...rest,
    refetchCompanies,
  };
};

export const usePredictDivisionsQuery = ({
  query,
  shouldFetch = true,
}: {
  query: string;
  shouldFetch?: boolean;
}) => {
  const { companyId } = useUserData();
  const { data: divisionsData, ...rest } = useQuery<AxiosResponse<PredictDivisionType>, Error>(
    [JAVA_SERVICE_ENDPOINTS.PREDICT_COMPANY_DIVISIONS, companyId, query],
    () => CompanyAPI.predictDivisions(companyId, query),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const divisions = useMemo(
    () =>
      divisionsData?.data?.config?.entries?.map(
        (entry) => `${entry.entryCode} - ${entry.entryDescription}`,
      ) || [],
    [divisionsData],
  );

  return {
    divisions,
    ...rest,
    query: {
      data: divisionsData,
      ...rest,
    },
  };
};

export const useDivisionSpecSectionsQuery = (divisionCode: string, shouldSearch = true) => {
  const { companyId } = useUserData();

  const { data: specSectionsData, ...rest } = useQuery<AxiosResponse<PredictDivisionType>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_DIVISION_SEPC_SECTIONS, companyId, divisionCode],
    () => CompanyAPI.getDivisionSpecSections(companyId, divisionCode),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      enabled: !!companyId && !!divisionCode && shouldSearch,
    },
  );

  const specSections = useMemo(() => {
    const specs =
      specSectionsData?.data?.config?.entries?.map(
        (entry) => `${entry.entryCode} - ${entry.entryDescription}`,
      ) || [];

    const specsByDivisionCode: Record<string, string[]> = {};

    specs.forEach((spec) => {
      const divCode = spec?.trim()?.split?.(' ')?.[0] || '';

      if (divCode) {
        if (specsByDivisionCode[divCode]) {
          specsByDivisionCode[divCode].push(spec);
        } else {
          specsByDivisionCode[divCode] = [spec];
        }
      }
    });

    const divisionCodes = divisionCode.split('-');

    const sortedSpecSections: string[] = [];

    divisionCodes.forEach((code) => sortedSpecSections.push(...(specsByDivisionCode[code] || [])));

    return sortedSpecSections;
  }, [specSectionsData, divisionCode]);

  return {
    specSections,
    ...rest,
    query: {
      data: specSectionsData,
      ...rest,
    },
  };
};

export const useConfigQuery = (companyId: string, configName: string, shouldFetch = true) => {
  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', companyId).replace(
        ':configName',
        configName,
      ),
    ],
    () => CompanyAPI.getCompanyConfig(companyId, configName),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const [config, entries] = useMemo(
    () => [entriesData?.data?.config, entriesData?.data?.config?.entries || []],
    [entriesData],
  );

  const updateConfigCache = ({
    _companyId = companyId,
    _configName = configName,
    updatedData,
  }: {
    _companyId?: string;
    _configName?: string;
    updatedData: ConfigResponse;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', _companyId).replace(
        ':configName',
        _configName,
      ),
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigResponse, Error> | undefined>(
        [
          JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG.replace(':companyId', _companyId).replace(
            ':configName',
            _configName,
          ),
        ],
        (old) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                ...updatedData,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    config,
    entries,
    ...rest,
    updateConfigCache,
  };
};

export const useBuildWiseConfigQuery = (configName: string) => {
  const { data: entriesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG, configName],
    () => CompanyAPI.getBuildWiseConfig(configName),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!configName,
    },
  );

  const [config, entries] = useMemo(
    () => [entriesData?.data?.config, entriesData?.data?.config?.entries || []],
    [entriesData],
  );

  const updateBuildWiseConfigCache = ({
    _configName = configName,
    updatedData,
  }: {
    _configName?: string;
    updatedData: ConfigResponse;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG,
      _configName,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ConfigResponse, Error> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.BUILDWISE_CONFIG, _configName],
        (old) => {
          if (old) {
            return {
              ...old,
              data: {
                ...old.data,
                ...updatedData,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    config,
    entries,
    ...rest,
    updateBuildWiseConfigCache,
  };
};

export const usePartnerRolesQuery = (
  options: {
    companyId?: string;
    shouldFetch?: boolean;
  } = {},
) => {
  const { companyId: _companyId } = useUserData();
  const { companyId = _companyId, shouldFetch = true } = options;

  const { data: partnerRolesData, ...rest } = useQuery<AxiosResponse<ConfigResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.COMPANY_CONFIG_PARTNER_ROLES, companyId],
    () => CompanyAPI.getPartnerRoles(companyId),
    {
      refetchOnMount: 'always',
      staleTime: 2 * 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const partnerRoles = useMemo(() => {
    const newPartnerRoles = _.orderBy(
      partnerRolesData?.data?.config?.entries?.map((entry) => entry.entryCode) || [],
    );

    const index = newPartnerRoles.indexOf('Other');
    if (index > -1) {
      newPartnerRoles.splice(index, 1);
      newPartnerRoles.push('Other');
    }

    return newPartnerRoles;
  }, [partnerRolesData]);

  return {
    partnerRoles,
    ...rest,
  };
};

export const updateCompanyQueryCache = (companyId: string, updatedCompany: CompanyType) => {
  queryClient.setQueriesData<AxiosResponse<CompanyType> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY.replace(':companyId', companyId),
    (old: AxiosResponse<CompanyType> | undefined) => {
      if (old) {
        return {
          ...old,
          data: {
            ...old.data,
            ...updatedCompany,
          },
        };
      }

      return old;
    },
  );
};

export const updateCompaniesQueryCache = (
  company: CompanyType,
  action: 'add' | 'update',
  isAssociatedWithOtherCompanies?: boolean,
) => {
  queryClient.setQueriesData<AxiosResponse<[CompanyType[]]> | undefined>(
    isAssociatedWithOtherCompanies
      ? JAVA_SERVICE_ENDPOINTS.GET_USER_COMPANIES
      : JAVA_SERVICE_ENDPOINTS.GET_COMPANIES,
    (old: AxiosResponse<[CompanyType[]]> | undefined) => {
      if (old) {
        const companies = [...(old?.data?.[0] || [])];
        if (action === 'add' && company) {
          companies.unshift(company);
        }

        if (action === 'update' && company) {
          const index = companies.findIndex((item) => item.id === company.id);
          if (index > -1) {
            companies[index] = company;
          }
        }

        return {
          ...old,
          data: [companies],
        };
      }
      return old;
    },
  );
};

export default useCompanyQuery;
