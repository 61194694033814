import { ConfigType, JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services';
import FormsAPI from 'api/FormsAPI';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { FORMS_CONFIG_CATEGORY_TYPES } from 'config/config';
import _ from 'lodash';

export const useFormsQuery = ({ configCategory }: { configCategory: string }) => {
  const { companyId } = useUserData();
  const { data, isLoading } = useQuery<AxiosResponse<any>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_ALL_FORMS_BY_CATEGORY.replace(
        ':configCategory',
        configCategory,
      ).replace(':companyId', companyId),
    ],
    () => FormsAPI.getAllFormsByCategory(configCategory, companyId),
    {
      staleTime: 60 * 1000,
      enabled: !!configCategory,
    },
  );
  const activeForms: ConfigType[] | [] = useMemo(
    () =>
      data?.data?.configs
        ?.filter((item: ConfigType) => item.isActive)
        .map((item: ConfigType) => ({
          id: item.id,
          configName: item.configName,
          configType: item.configType,
          configCategory: item.configCategory,
          description: item.description,
          entries: item.entries,
          sortFlag: item.sortFlag,
          title: item.title,
          companyId,
          updatedAt: item.updatedAt,
          updatedBy: item.updatedBy,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          createdByDisplayName: item.createdByDisplayName,
        })) || [],
    [data],
  );

  return { allForms: activeForms, isLoading };
};

export const useFormDetailsQuery = ({
  formId = '',
  shouldFetch = true,
}: {
  formId?: string;
  shouldFetch?: boolean;
}) => {
  const { data, isLoading } = useQuery<AxiosResponse<any>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_FORM_DETAILS, formId],
    () => FormsAPI.getFormDetails(formId),
    {
      cacheTime: 0,
      enabled: !!formId && shouldFetch,
    },
  );

  let formData;
  let userMapData;
  if (data?.data?.form) {
    formData = data?.data?.form;
    userMapData = data?.data?.userMap;
  }
  const formDetails = useMemo(
    () =>
      formData
        ? {
            id: formData?.id,
            articles: formData?.articles,
            articleIds: formData?.articleIds,
            configName: formData?.formName,
            configType: formData?.formType,
            configCategory: formData?.formCategory,
            description: formData?.formCategoryDescription,
            entries: formData?.questions,
            sortFlag: formData?.sortFlag,
            title: formData?.title,
            companyId: formData?.companyId,
            projectId: formData?.projectId,
            projectName: formData?.project?.name,
            configId: formData?.configId,
            updatedAt: formData?.updatedAt,
            updatedBy: formData?.updatedBy,
            createdAt: formData?.createdAt,
            createdBy: formData?.createdBy,
            updatedByDisplayName: userMapData[formData?.updatedBy]?.[0]['displayName'],
            status: formData?.status,
            assignedTo: formData?.assignedTo,
            assignedToUserDisplayName: userMapData[formData?.assignedTo]?.[0]['displayName'],
            partnerId: formData?.contactId,
          }
        : {},
    [data],
  );

  const articles =
    (formDetails?.articles &&
      _.orderBy(
        formDetails?.articles.filter((article) => !article?.isDraft),
        ['createdAt'],
        ['desc'],
      )) ||
    [];

  const updateFormDetailsCache = ({
    action,
    article = '',
    formDetails = '',
  }: {
    action: 'add' | 'remove' | 'update';
    article?: any;
    formDetails?: any;
  }) => {
    const formId = formDetails ? formDetails?.form?.id : article['formIds'][0];
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_FORM_DETAILS,
      formId,
    ]);
    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueriesData<AxiosResponse<any> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.GET_FORM_DETAILS, formId],
        (old) => {
          if (old) {
            let updatedFormDetails: any = { ...(old?.data?.form || {}) };
            let updatedUserMap = { ...(old?.data?.userMap || {}) };

            if (action === 'remove' && article) {
              let articleId = article?.id;
              updatedFormDetails['articles'] = updatedFormDetails?.articles.filter(
                (article) => article.id !== articleId,
              );
              updatedFormDetails['articleIds'] = updatedFormDetails?.articleIds.filter(
                (id: string) => id !== articleId,
              );
            }

            // It will handle both add & update of articles
            if (action === 'update') {
              if (formDetails) {
                updatedFormDetails = {
                  ...updatedFormDetails,
                  ...formDetails?.form,
                  articles: [...(updatedFormDetails?.articles || [])],
                  articleIds: [...(updatedFormDetails?.articleIds || [])],
                };
                updatedUserMap = {
                  ...updatedUserMap,
                  ...(formDetails?.userMap && formDetails.userMap),
                };
              }

              if (article) {
                let index = updatedFormDetails?.articles?.findIndex(
                  (item) => item.id === article.id,
                );
                if (index > -1) {
                  updatedFormDetails['articles'][index] = { ...article };
                } else {
                  updatedFormDetails['articles'] = [
                    ...(updatedFormDetails?.articles || []),
                    article,
                  ];
                  updatedFormDetails['articleIds'] = [
                    ...(updatedFormDetails?.articleIds || []),
                    article?.id,
                  ];
                }
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                form: { ...updatedFormDetails },
                userMap: { ...updatedUserMap },
              },
            };
          }
          return old;
        },
      );
    }
  };
  return {
    formDetails,
    isLoading,
    articles,
    updateFormDetailsCache,
  };
};

export const useAssignedFormsQuery = ({
  assignedTo,
  companyId,
  createdBy,
  formCategory,
  shouldFetch = true,
  status,
}: {
  assignedTo?: string;
  companyId: string;
  createdBy?: string;
  formCategory?: FORMS_CONFIG_CATEGORY_TYPES;
  shouldFetch?: boolean;
  status: string;
}) => {
  const { data, ...rest } = useQuery<AxiosResponse<any>, Error>(
    [
      JAVA_SERVICE_ENDPOINTS.GET_ASSIGNED_FORMS,
      companyId,
      createdBy,
      assignedTo,
      status,
      formCategory,
    ],
    () => FormsAPI.getAssignedForms({ companyId, createdBy, assignedTo, status, formCategory }),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!(createdBy || assignedTo) && !!status && shouldFetch,
    },
  );

  const formsData = useMemo(() => data?.data, [data]);

  const assignedForms = useMemo(() => _.groupBy(data?.data?.forms || [], 'formCategory'), [data]);

  const updateAssignedFormsCache = ({
    form,
    action,
  }: {
    form: any;
    action: 'add' | 'delete' | 'update';
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.GET_ASSIGNED_FORMS,
      companyId,
      createdBy,
      assignedTo,
      status,
      formCategory,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<any> | undefined>(
        [
          JAVA_SERVICE_ENDPOINTS.GET_ASSIGNED_FORMS,
          companyId,
          createdBy,
          assignedTo,
          status,
          formCategory,
        ],
        (old) => {
          if (old) {
            let updatedForms = [...(old?.data?.forms || [])];

            if (action === 'add') {
              updatedForms = [form, ...updatedForms];
            }

            if (action === 'delete') {
              updatedForms = updatedForms.filter((_form) => _form?.id !== form?.id);
            }

            if (action === 'update') {
              let index = updatedForms?.findIndex((_form) => _form.id === form.id);
              if (index > -1) {
                updatedForms[index] = { ...updatedForms[index], ...form };
              } else {
                updatedForms = [form, ...updatedForms];
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                forms: [...updatedForms],
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    assignedProgresscards: assignedForms?.[FORMS_CONFIG_CATEGORY_TYPES.PROJECT_PROGRESS_CARD] || [],
    assignedScorecards: [
      ...(assignedForms?.[FORMS_CONFIG_CATEGORY_TYPES.SCORECARD] || []),
      ...(assignedForms?.[FORMS_CONFIG_CATEGORY_TYPES.BUILDWISE_SCORECARD] || []),
    ],
    requestedPosts: assignedForms?.[FORMS_CONFIG_CATEGORY_TYPES.REQUEST_A_POST_CARD] || [],
    data: formsData,
    ...rest,
    updateAssignedFormsCache,
  };
};
