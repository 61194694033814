import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import useAuthStore from '../store/useAuthStore';
import UserAPI, { LoginPayload, SSOSignInQueryParams } from '../api/UserAPI';
import { shallow } from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { GET_STARTED, LOGIN } from '../constants/routes';
import { toast } from 'react-toastify';
import { catchErrorMessage } from '../utility/helpers';

export type VerifyUserDomainResponse = {
  loginType: string[];
  redirectUrl?: string;
  messageCode?: string;
  companyName?: string;
  message?: string;
};

export const useAuth = () => {
  const navigate = useNavigate();
  const [setAccessToken, setRefreshToken, accessToken, setUser] = useAuthStore(
    (state) => [state.setAccessToken, state.setRefreshToken, state.accessToken, state.setUser],
    shallow,
  );

  const { mutate: login, ...loginInfo } = useMutation<AxiosResponse<any>, Error, LoginPayload>(
    (loginPayload) => UserAPI.login(loginPayload),
    {
      onSuccess: ({ data: response }) => {
        setTimeout(() => {
          const isNovaAdmin = !!response?.roles?.find(
            (role) => role.roleName === 'ROLE_NOVA_ADMIN',
          );
          setAccessToken(response.auth_token);
          setRefreshToken(response.refresh_token);
          setUser({
            email: response.email,
            companyId: response.companyId,
            isNovaAdmin,
            isAssociatedWithOtherCompanies:
              !isNovaAdmin && response?.associatedCompanies?.includes(response.companyId)
                ? response?.associatedCompanies?.length > 1
                : response?.associatedCompanies?.length > 0,
          });
        }, 1);
      },
    },
  );

  const { mutate: ssoSignIn, isLoading: loadingSSOSignIn } = useMutation<
    AxiosResponse<any>,
    Error,
    SSOSignInQueryParams
  >((queryParams) => UserAPI.ssoSignIn(queryParams), {
    retry: false,
    onError: (e) => {
      toast.error(catchErrorMessage(e));
      navigate(LOGIN);
    },
    onSuccess: ({ data: response }) => {
      setTimeout(() => {
        setAccessToken(response.auth_token);
        setRefreshToken(response.refresh_token);

        if (!response.firstName || !response.lastName) {
          navigate(GET_STARTED, { state: { procore: response } });
        } else {
          const isNovaAdmin = !!response?.roles?.find(
            (role) => role.roleName === 'ROLE_NOVA_ADMIN',
          );
          setUser({
            email: response.email,
            companyId: response.companyId,
            isNovaAdmin,
            isAssociatedWithOtherCompanies:
              !isNovaAdmin &&
              !!response?.associatedCompanies?.find(
                (companyId) => companyId !== response.companyId,
              ),
          });
        }
      }, 1);
    },
  });

  const { mutate: verifyDomain, ...verifyDomainInfo } = useMutation<
    AxiosResponse<VerifyUserDomainResponse>,
    Error,
    string
  >((email) => UserAPI.verifyDomain(email));

  // const { mutate: register, ...registerInfo } = useMutation<AxiosResponse, Error>(
  //   (payload) => apiClient.post(JAVA_SERVICE_ENDPOINTS.REGISTER, payload),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );
  //
  // const { mutate: restaurantRegister, ...restaurantRegisterInfo } = useMutation<
  //   AxiosResponse,
  //   Error
  //   >((payload) => apiClient.post(JAVA_SERVICE_ENDPOINTS.RESTAURANT_REGISTER, payload), {
  //   onError: (e) => notification.error(e),
  // });
  //
  // const { mutate: resetPassword, ...resetPasswordInfo } = useMutation<
  //   AxiosResponse<ResetPasswordApiResponse>,
  //   Error,
  //   ResetPasswordPayload
  //   >(
  //   (payload) =>
  //     apiClient.post(JAVA_SERVICE_ENDPOINTS.AUTH_RESET_PASSWORD, {
  //       ...payload,
  //       source: constants.USER_ACCOUNT_TYPE.Business,
  //     }),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );
  //
  // const { mutate: changePassword, ...changePasswordInfo } = useMutation<
  //   AxiosResponse<ChangePasswordApiResponse>,
  //   Error,
  //   ChangePasswordPayload
  //   >(
  //   (payload) =>
  //     apiClient.post(JAVA_SERVICE_ENDPOINTS.AUTH_CHANGE_PASSWORD, {
  //       ...payload,
  //       source: constants.USER_ACCOUNT_TYPE.Business,
  //     }),
  //   {
  //     onError: (e) => notification.error(e),
  //   },
  // );

  // const { mutate: invitationToken, ...invitationTokenInfo } = useMutation<
  //   AxiosResponse,
  //   Error,
  //   string
  //   >((token) => apiClient.get(`${JAVA_SERVICE_ENDPOINTS.INVITATION_TOKEN}/${token}`));

  return {
    accessToken,
    login,
    loginInfo,
    loadingSSOSignIn,
    ssoSignIn,
    verifyDomain,
    verifyDomainInfo,
    // register,
    // registerInfo,
    // restaurantRegister,
    // restaurantRegisterInfo,
    // resetPassword,
    // resetPasswordInfo,
    // changePassword,
    // changePasswordInfo,
  };
};
