import { Collapse } from '@mui/material';
import Dropzone from 'components/common/dropzone/Dropzone';
import LightboxModal from 'components/common/modal/LightboxModal';
import { AUDIO_TYPES, IMAGE_TYPES, VIDEO_TYPES } from 'components/utils/constants';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import RenderThumbnails from 'pages/companyFiles/components/RenderThumbnails';
import { ArticleType, AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getExactFileName, getFileDetails } from 'utility/helpers';

type PostAttachmentsProps = {
  article: ArticleType | undefined;
  isExpanded: boolean;
  loadingFiles: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  hideDropZone?: boolean;
  multiple?: boolean;
};

function PostAttachments({
  article,
  isExpanded,
  loadingFiles,
  onDrop,
  hideDropZone = false,
  multiple = true,
}: PostAttachmentsProps) {
  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<PostFormData>();
  const [lightboxOpen, setLightboxOpen] = useState<{
    open: boolean;
    fileName: string;
  }>({
    open: false,
    fileName: '',
  });
  const attachments = watch('attachments');
  const coverPhotoFileName = watch('coverPhotoFileName');
  const [mediaFiles, thumbnails] = useMemo(() => {
    const mediaFiles: AttachmentType[] = [];
    const thumbnails: AttachmentType[] = [];

    attachments?.forEach((attachment) => {
      let fileType = `${attachment.fileType.toLowerCase().split('/')?.[1]}`;
      if (attachment.fileUploaded) {
        fileType = attachment.fileType.toLowerCase();
      }

      const isMediaFile =
        IMAGE_TYPES.includes(`image/${fileType}`) ||
        VIDEO_TYPES.includes(`video/${fileType}`) ||
        AUDIO_TYPES.includes(`audio/${fileType}`);

      if (isMediaFile && !attachment?.isRemoved) {
        mediaFiles.push(attachment);
      }

      if (!attachment?.isRemoved) {
        thumbnails.push(attachment);
      }
    });

    return [mediaFiles, thumbnails];
  }, [attachments]);

  const onRemoveFile = (file: AttachmentType) => {
    const fileName = getExactFileName(file);

    const updatedAttachments = [...attachments];
    const index = updatedAttachments.findIndex(
      (attachment) => attachment.fileName.toLowerCase() === file.fileName.toLowerCase(),
    );
    if (file.fileUploaded) {
      updatedAttachments[index] = {
        ...updatedAttachments[index],
        isRemoved: true,
      };
    } else {
      updatedAttachments.splice(index, 1);
    }

    setValue('attachments', updatedAttachments, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (fileName.toLowerCase() === coverPhotoFileName?.toLowerCase()) {
      const nextCoverPhotoIndex =
        file.fileUploaded && index !== attachments.length - 1 ? index + 1 : 0;
      if (
        updatedAttachments.length > 0 &&
        getFileDetails({ file: updatedAttachments[nextCoverPhotoIndex] }).isMediaFile
      ) {
        setValue('coverPhotoFileName', getExactFileName(updatedAttachments[nextCoverPhotoIndex]), {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }
  };

  const updateCoverPhoto = (fileName: string) => {
    setValue('coverPhotoFileName', fileName, { shouldDirty: true, shouldValidate: true });
    const updatedAttachments = [...attachments];
    const fileIndex = updatedAttachments.findIndex((attachment) => {
      const exactFileName = getExactFileName(attachment);
      return exactFileName?.toLowerCase() === fileName.toLowerCase();
    });

    if (fileIndex > -1) {
      const file = updatedAttachments.splice(fileIndex, 1)[0];
      updatedAttachments.unshift(file);
    }

    setValue('attachments', updatedAttachments, { shouldDirty: true, shouldValidate: true });
  };

  const updateAttachment = (fileName: string, updatedAttachment: AttachmentType) => {
    const updatedAttachments = [...attachments];
    const index = updatedAttachments.findIndex(
      (file) => file.fileName.toLowerCase() === fileName.toLowerCase(),
    );

    if (updatedAttachment.fileUploaded) {
      updatedAttachments[index] = {
        ...updatedAttachment,
        isUpdated: true,
      };
    } else {
      updatedAttachments[index] = updatedAttachment;
    }

    setValue('attachments', updatedAttachments, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <>
      {isExpanded && !hideDropZone && (
        <Dropzone onDrop={onDrop} loadingFiles={loadingFiles} multiple={multiple} />
      )}
      <Collapse in={thumbnails.length > 0} aria-disabled={isSubmitting} sx={{ mt: 2 }}>
        <RenderThumbnails
          files={thumbnails}
          removeFile={onRemoveFile}
          setLightboxOpen={setLightboxOpen}
          disabled={isSubmitting}
          showEdit
          isImage={(file: AttachmentType) => {
            let fileType = `${file.fileType.toLowerCase().split('/')?.[1]}`;
            if (file.fileUploaded) {
              fileType = file.fileType.toLowerCase();
            }
            return IMAGE_TYPES.includes(
              file.fileUploaded ? `image/${fileType}` : file.fileType.toLowerCase(),
            );
          }}
        />
      </Collapse>

      {lightboxOpen.open && (
        <LightboxModal<ArticleType>
          onClose={() => setLightboxOpen({ open: false, fileName: '' })}
          open={lightboxOpen.open}
          mode="edit"
          mediaFiles={mediaFiles}
          defaultOpenFileName={lightboxOpen.fileName}
          coverPhotoFileName={coverPhotoFileName}
          setCoverPhotoFileName={updateCoverPhoto}
          updateFile={updateAttachment}
          item={article}
        />
      )}
    </>
  );
}

export default PostAttachments;
