import { Box, Button, DialogActions, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import ClockwiseFillIcon from 'remixicon-react/ClockwiseFillIcon';
import GalleryFillIcon from 'remixicon-react/GalleryFillIcon';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  ElementType,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { styled } from '@mui/material/styles';
import { AttachmentType } from 'pages/knowledge/hooks/useArticlesQuery';
import {
  ExtraFileNameAttachedByServiceExtendsType,
  formatFileSize,
  getExtraFileNameAttachedByService,
  getFileDetails,
} from 'utility/helpers';
import Dot from '../dot/Dot';

type ModalPropTypes<T> = {
  coverPhotoFileName?: string;
  defaultOpenFileName?: string;
  item?: T;
  hideCoverPhotoOption?: boolean;
  mediaFiles: AttachmentType[];
  mode?: 'view' | 'edit';
  open: boolean;
  onClose: () => void;
  setCoverPhotoFileName?: (fileName: string) => void;
  updateFile?: (fileName: string, updatedFile: AttachmentType) => void;
};

const LightboxModal = <T extends ExtraFileNameAttachedByServiceExtendsType>({
  coverPhotoFileName,
  defaultOpenFileName = '',
  item,
  hideCoverPhotoOption = false,
  mediaFiles,
  mode = 'view',
  open,
  onClose,
  setCoverPhotoFileName,
  updateFile,
}: ModalPropTypes<T>) => {
  const [imgIndex, setImgIndex] = useState(
    defaultOpenFileName ? mediaFiles.findIndex((file) => file.fileName === defaultOpenFileName) : 0,
  );
  const enableEditing = useMemo(
    () => mediaFiles.length > 0 && mode === 'edit' && !mediaFiles[imgIndex]?.imageHashValue,
    [mediaFiles, imgIndex],
  );
  const [rotateDeg, setRotateDeg] = useState(90);
  const [isIdle, setIsIdle] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sourceRef = useRef<HTMLImageElement | HTMLVideoElement>(null);

  const { fileContentType, file, fileName, fileType } = useMemo(() => {
    const file = mediaFiles[imgIndex];
    const { fileContentType, fileName, fileType } = getFileDetails({
      file,
      useDefaultStrToSliceFromFileName: !file.fileUploaded,
      strToSliceFromFileName: getExtraFileNameAttachedByService<T>(item),
    });

    return {
      fileContentType,
      file,
      fileType,
      fileName,
      isCoverPhoto: coverPhotoFileName ? coverPhotoFileName === fileName : imgIndex === 0,
    };
  }, [imgIndex, mediaFiles, coverPhotoFileName, item]);

  const onKeyPressed = (event: KeyboardEvent) => {
    handleMouseOver();
    if (event.key === 'ArrowRight') {
      if (imgIndex < mediaFiles.length - 1) {
        resetState();
        setImgIndex(imgIndex + 1);
      }
    } else if (event.key === 'ArrowLeft') {
      if (imgIndex > 0) {
        resetState();
        setImgIndex(imgIndex - 1);
      }
    }
  };

  const onModalClose = () => {
    resetState();
    onClose();
    setImgIndex(0);
  };

  const removeControlsOnIdle = useCallback(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsIdle(true);
      }, 2500);
    };
  }, []);

  const handleIdleState = useCallback(removeControlsOnIdle(), [removeControlsOnIdle]);

  const handleMouseOver = () => {
    setIsIdle(false);
    handleIdleState();
  };

  const resetState = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    setRotateDeg(90);
  };

  const handleSave = async () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      let editedFile = await new Promise<Blob | null>((resolve) => {
        ctx?.canvas.toBlob((blob) => {
          resolve(blob);
        }, `image/${fileType.toLowerCase()}`);
      });

      if (editedFile) {
        const generatedFile = new File([editedFile], fileName, {
          type: `image/${fileType.toLowerCase()}`,
          lastModified: new Date().getTime(),
        });

        const fileReader = new FileReader();
        fileReader.onload = async (progressEvent) => {
          const updatedFile: AttachmentType = {
            ...file,
            fileSize: generatedFile?.size || file.fileSize,
            articleOpenUrl: progressEvent?.target?.result as string,
            thumbnailOpenUrl: progressEvent?.target?.result,
            thumbnail: generatedFile,
            file: generatedFile,
          };
          setRotateDeg(90);
          updateFile?.(file.fileName, updatedFile);
        };

        fileReader.readAsDataURL(generatedFile);
      }
    }
  };

  if (mediaFiles.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onModalClose}
      onKeyDown={onKeyPressed}
      onMouseOver={handleMouseOver}
      onMouseMove={handleMouseOver}
      onClick={handleMouseOver}
    >
      <$CustomDialogTitle
        onClose={onModalClose}
        id="dialog-title"
        closeIcon={<CloseLineIcon size={32} color="white" />}
        sx={{
          opacity: isIdle ? 0 : 1,
          zIndex: 1,
        }}
      >
        <Box display="flex" alignItems="center">
          <GalleryFillIcon color="#f1c40f" size={30} />
          <Box marginLeft={1.5}>
            <Typography component="h3" sx={{ fontSize: '18px', fontWeight: 700 }}>
              {fileName}
            </Typography>
            <Typography component="p" fontSize="14px">
              {fileContentType === 'img' ? 'image' : fileContentType}
              {file?.fileSize && (
                <>
                  <Dot />
                  {formatFileSize(file?.fileSize)}
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </$CustomDialogTitle>

      <$DialogContent>
        {mediaFiles.length > 1 && (
          <>
            {imgIndex !== 0 && (
              <$IconButton
                onClick={(event: MouseEvent) => {
                  resetState();
                  setImgIndex(imgIndex - 1);
                  event.stopPropagation();
                }}
                sx={{
                  opacity: isIdle ? 0 : 1,
                }}
              >
                <ArrowLeftSLineIcon size={32} />
              </$IconButton>
            )}
            {imgIndex < mediaFiles.length - 1 && (
              <$IconButton
                className="right"
                onClick={(event: MouseEvent) => {
                  resetState();
                  setImgIndex(imgIndex + 1);
                  event.stopPropagation();
                }}
                sx={{
                  opacity: isIdle ? 0 : 1,
                }}
              >
                <ArrowRightSLineIcon size={32} />
              </$IconButton>
            )}
          </>
        )}

        <$CanvasContainer
          onClick={onModalClose}
          sx={{ display: fileContentType === 'img' ? 'flex' : 'none' }}
        >
          <canvas
            ref={canvasRef}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            onClick={(event: MouseEvent) => event.stopPropagation()}
          />
        </$CanvasContainer>

        <$MediaContainer
          display={fileContentType === 'img' ? 'none' : 'flex'}
          onClick={onModalClose}
        >
          <Box
            ref={sourceRef}
            id="source"
            component={fileContentType as ElementType}
            controls={fileContentType !== 'img'}
            src={file.articleOpenUrl}
            autoPlay
            onClick={(event: MouseEvent) => event.stopPropagation()}
            poster={fileContentType === 'video' ? file.thumbnailOpenUrl : ''}
            sx={{ maxHeight: '100%', maxWidth: '100%', width: '100%', height: '100%' }}
            {...{ ...(fileContentType === 'img' ? { crossOrigin: 'anonymous' } : {}) }}
            onLoad={() => {
              if (canvasRef.current && fileContentType === 'img') {
                const ctx = canvasRef.current.getContext('2d');
                if (sourceRef.current) {
                  canvasRef.current.width = sourceRef.current.width;
                  canvasRef.current.height = sourceRef.current.height;
                  ctx?.drawImage(
                    sourceRef.current,
                    0,
                    0,
                    sourceRef.current.width,
                    sourceRef.current.height,
                  );
                }
              }
            }}
          />
        </$MediaContainer>
      </$DialogContent>

      {enableEditing && fileContentType === 'img' && (
        <$DialogActions
          sx={{
            opacity: isIdle ? 0 : 1,
          }}
        >
          {/* {mediaFiles.length > 1 && !hideCoverPhotoOption && (
            <Box>
              <$FormControlLabel
                checked={isCoverPhoto}
                onClick={() => {
                  setCoverPhotoFileName?.(fileName);
                  setImgIndex(0);
                }}
                control={<Radio size="small" />}
                label="Use as cover photo"
              />
            </Box>
          )} */}

          {enableEditing && fileContentType === 'img' && (
            <$RotateBtn
              variant="contained"
              onClick={() => {
                if (canvasRef.current) {
                  const ctx = canvasRef.current.getContext('2d');
                  if (sourceRef.current) {
                    const canvasWidth = canvasRef.current.width;
                    canvasRef.current.width = canvasRef.current.height;
                    canvasRef.current.height = canvasWidth;

                    ctx?.translate(canvasRef.current.width / 2, canvasRef.current.height / 2);
                    ctx?.rotate((rotateDeg * Math.PI) / 180);
                    setRotateDeg((prevState) => prevState + 90);
                    ctx?.drawImage(
                      sourceRef.current,
                      -sourceRef.current.width / 2,
                      -sourceRef.current.height / 2,
                    );

                    handleSave();
                  }
                }
              }}
            >
              <ClockwiseFillIcon />
              &nbsp;&nbsp;Rotate
            </$RotateBtn>
          )}
        </$DialogActions>
      )}
    </Dialog>
  );
};

const $CustomDialogTitle = styled(CustomDialogTitle, { label: 'CustomDialogTitle' })(() => ({
  background: 'linear-gradient(rgb(16, 18, 20), rgba(14, 22, 36, 0)) 0px center no-repeat',
  padding: '12px 24px',
  position: 'absolute',
  top: '0',
  width: '100%',
  transition: 'opacity 0.7s',
  color: 'white',
}));

const $DialogContent = styled(DialogContent, { label: 'DialogContent' })(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#000',
  overflow: 'auto',
  padding: '20px 0px !important',
  width: '100%',
  height: '100%',
}));

const $DialogActions = styled(DialogActions, { label: 'DialogActions' })(() => ({
  position: 'absolute',
  width: 'fit-content',
  left: '50%',
  top: '80%',
  borderRadius: '20px',
  padding: '8px',
  transform: 'translateX(-50%)',
  margin: '0 auto',
  transition: 'opacity 0.7s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  whiteSpace: 'nowrap',
}));

const $IconButton = styled(IconButton, { label: 'IconButton' })(({ theme }) => ({
  position: 'fixed',
  left: 50,
  top: '45%',
  zIndex: 1,
  color: theme.palette.custom.white,
  backgroundColor: '#232323',
  transition: 'opacity 0.7s',
  '&:hover': {
    backgroundColor: '#464646',
    transition: 'background-color 0.5s',
  },
  '&.right': {
    left: 'unset',
    right: 50,
  },
}));

const $CanvasContainer = styled(Box, { label: 'CanvasContainer' })(() => ({
  maxWidth: '100%',
  width: '100%',
  maxHeight: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const $MediaContainer = styled(Box, { label: 'MediaContainer' })(() => ({
  width: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

const $RotateBtn = styled(Button, { label: 'RotateBtn' })(() => ({
  backgroundColor: 'white',
  border: '1px solid #4B4E71',
  '&:hover': { backgroundColor: 'white' },
}));

// const $FormControlLabel = styled(FormControlLabel, { label: 'FormControlLabel' })(() => ({
//   marginLeft: 0,
//   backgroundColor: 'white',
//   padding: '0 12px',
//   borderRadius: '20px',
//   '& .MuiFormControlLabel-label': {
//     fontSize: 15,
//   },
// }));

export default LightboxModal;
