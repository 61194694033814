import { styled } from '@mui/material/styles';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { ArticleType, AttachmentType } from '../hooks/useArticlesQuery';
import RenderImage from './RenderImage';
import RenderVideo from './RenderVideo';
import RenderAudio from './RenderAudio';
import { Box } from '@mui/material';
import { getExtraFileNameAttachedByService, getFileDetails } from 'utility/helpers';
import { ArticleVariantType } from './ArticleBox';

type RenderAttachmentsProps = {
  article: ArticleType;
  attachment: AttachmentType;
  doNotAutoplayVideos?: boolean;
  variant: ArticleVariantType;
  layout: 'single' | 'double' | 'triple' | 'multiple';
  setLightboxOpen?: (payload: { open: boolean; fileName: string }) => void;
};

function RenderAttachment({
  article,
  attachment,
  doNotAutoplayVideos = true,
  layout,
  variant,
  setLightboxOpen,
}: RenderAttachmentsProps) {
  const { fileContentType, isImage, isVideo, isAudio } = useMemo(
    () =>
      getFileDetails({
        file: attachment,
        useDefaultStrToSliceFromFileName: !attachment.fileUploaded,
        strToSliceFromFileName: getExtraFileNameAttachedByService<ArticleType>(article),
      }),
    [attachment],
  );
  let content: ReactNode = '';

  const handleAttachmentsOpen = (event: MouseEvent) => {
    if (setLightboxOpen) {
      setLightboxOpen({ open: true, fileName: attachment?.fileName || '' });
      event.stopPropagation();
    }
  };

  if (isImage) {
    content = (
      <RenderImage attachment={attachment} variant={variant} isShortView={variant === 'short'} />
    );
  }

  if (isVideo) {
    content = (
      <RenderVideo
        doNotAutoplayVideos={doNotAutoplayVideos}
        attachment={attachment}
        variant={variant}
        forceStopPlaying={!!setLightboxOpen}
      />
    );
  }

  if (isAudio) {
    content = <RenderAudio attachment={attachment} variant={variant} />;
  }

  if (content) {
    return (
      <Container
        className={`${fileContentType} ${variant} ${layout}`}
        onClick={handleAttachmentsOpen}
      >
        {content}
      </Container>
    );
  }

  return null;
}

export const Container = styled(Box, { label: 'Container' })(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: theme.palette.custom.background,
  '&.video': {
    backgroundColor: `${theme.palette.custom.black} !important`,
  },
  '&.audio': {
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&.single.audio': {
    height: 'fit-content',
  },
  '&.single.img > img': {
    objectFit: 'contain !important',
  },
}));

export default RenderAttachment;
