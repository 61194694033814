import KnowledgeAPI, { RecommendedArticleStatusType } from 'api/KnowledgeAPI';
import useUserData from 'hooks/useUserData';
import { ProjectRecommendationsType } from 'pages/projects/hooks/useProjectsQuery';
import { useState } from 'react';
import ThumbDownFillIcon from 'remixicon-react/ThumbDownFillIcon';
import ThumbDownLineIcon from 'remixicon-react/ThumbDownLineIcon';
import { updateProjectRecommendationsQueryCache } from './useProjectDetailsQuery';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';

const projectRecommendedArticleStatusTypes = [
  {
    type: 'Not Relevant',
    icon: <ThumbDownLineIcon size={18} />,
    fillIcon: <ThumbDownFillIcon size={18} />,
    backgroundColor: '#E4E5E7',
    status: RecommendedArticleStatusType.NOT_RELEVANT,
  },
  {
    type: 'New',
    backgroundColor: '#FFF9E6',
    status: RecommendedArticleStatusType.ACTIVE,
  },
  // {
  //   type: 'In Progress',
  //   backgroundColor: '#FFE699',
  //   status: RecommendedArticleStatusType.INPROGRESS,
  // },
  {
    type: 'Keep',
    backgroundColor: '#B6E8FC',
    status: RecommendedArticleStatusType.REVIEW_LATER,
  },
  {
    type: 'Complete',
    backgroundColor: '#96E3CE',
    status: RecommendedArticleStatusType.COMPLETED,
  },
];

export function useProjectRecommendedArticleStatusState({
  project,
}: {
  project: ProjectRecommendationsType;
}) {
  const { companyId, user } = useUserData();
  const [loading, setLoading] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState<RecommendedArticleStatusType | ''>('');

  const updatePostInProject = async (
    payload: { status: RecommendedArticleStatusType; message?: string },
    articleId: string,
  ) => {
    try {
      setUpdatingStatus(payload.status);
      setLoading(true);
      await KnowledgeAPI.updateProjectRecommendedPosts(companyId, project.id, {
        articleIdToStatusObjMap: {
          ...project?.recommendations?.articleIdToStatusObjMap,
          [articleId]: payload,
        },
      });
      updateProjectRecommendationsQueryCache({
        companyId,
        projectId: project.id,
        project: {
          ...project,
          recommendations: {
            ...project?.recommendations,
            articleIdToStatusObjMap: {
              ...project?.recommendations?.articleIdToStatusObjMap,
              [articleId]: {
                ...payload,
                activityLog: [
                  ...(project?.recommendations?.articleIdToStatusObjMap?.[articleId]?.activityLog ||
                    []),
                  {
                    date: new Date(),
                    description: `${user?.userNameToDisplay} changed the status to ${payload.status}.`,
                    status: payload.status,
                    userName: user?.username || '',
                  },
                ],
              },
            },
          },
        },
        action: 'update-project',
      });
    } catch (error) {
      console.warn('UPDATE_POST_IN_PROJECT', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setUpdatingStatus('');
      setLoading(false);
    }
  };

  return {
    loading,
    projectRecommendedArticleStatusTypes,
    updatingStatus,
    updatePostInProject,
  };
}
