import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const $Wrapper = styled(Box, { label: 'AuthWrapper' })(({ theme }) => ({
  background: '#F7F7FC',
  width: '100%',
  padding: 24,
  '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
    margin: '8px 0',
  },
}));

const Privacy = () => {
  return (
    <$Wrapper>
      <Typography variant="h3">
        <strong>BuildWise Privacy Policy</strong>
      </Typography>
      <p>Last Modified: August 10, 2022</p>
      <p>
        Thank you for using BuildWise. At BuildWise, we know that you care how your information is
        treated, so we’ve created this Privacy Policy (sometimes referred to as the “Policy”), which
        focuses on how we collect personally identifiable (or “personal”) information. Personal
        information identifies or describes a particular individual, such as a person’s first and
        last name, email address, physical address or telephone number.
      </p>
      <p>
        This Policy applies to our websites, services, products, and applications (each a “Service”)
        and explains the following:
      </p>
      <ul>
        <li>What information we gather from you when you use the Service</li>
        <li>How we may use and share this information</li>
        <li>The security approaches we use to protect your information</li>
      </ul>
      <p>
        We will not use or share your personal information with anyone except as described in this
        Privacy Policy.
      </p>
      <p>
        By using the Service, you consent to the collection and use of your personal information by
        BuildWise in accordance with this Privacy Policy. If we decide to change our Privacy Policy,
        we will post those changes on this page and revise the “last modified” date on the Policy.
        Your access to and use of this Service is also subject to your acceptance of{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_PUBLIC_URL}/terms-conditions`}
        >
          BuildWise’s Terms of Service.
        </a>
      </p>
      <Typography variant="h6" sx={{ marginBottom: -2, fontWeight: 700 }}>
        Information We Collect and Use
      </Typography>
      <p>
        We collect personal information necessary to operate, maintain and provide you the features
        and functionality of the Service, to analyze how the Service is used, diagnose service or
        technical problems, maintain security, personalize content, remember information to help you
        efficiently access your account, monitor aggregate metrics such as total number of visitors,
        traffic, and demographic patterns, and track Content and users as necessary to comply with
        our legal obligations. “Content” refers to information, messages, text and other data which
        is collected, stored, surfaced, and shared through the use of our Service.
      </p>
      <p>
        <span className="span-text">Information You Provide:</span> You provide us information about
        yourself, such as your name and e-mail address, if you register for an account with the
        Service or by connecting with the Service via a third-party service. We receive and store
        the information you input when registering for our Service, or which you otherwise provide
        to us in any other manner.
      </p>
      <p>
        We may use your email address to send you Service-related notices (including any notices
        required by law, in lieu of communication by postal mail). We may also use your contact
        information to send you marketing messages. If you do not want to receive these messages,
        you can opt out by following the instructions in the message.
      </p>
      <p>
        If you choose to use our invitation service to invite a friend to the Service, we will ask
        you for that person’s email address and automatically send an email invitation. We store
        this information to send this email, to register your friend if your invitation is accepted,
        and to track the success of our invitation service.
      </p>
      <p>
        <span className="span-text">Content:</span> You also provide us Content you post to the
        Service, or is otherwise collected through your use of the Service. Your Content and
        metadata about your Content may be viewed by other users in accordance with your settings on
        the Service. Unless Content is made viewable in accordance with your settings on the
        Service, then neither BuildWise, BuildWise employees, BuildWise contractors nor agents will
        view your Content except: (i) to maintain, provide or improve the Service; (ii) to help you
        and respond to your request related to your Content, including support requests; (iii) to
        review and monitor (if we elect to do so) compliance with our Terms of Service (including
        our Acceptable Use Policy); (iv) to comply with our legal obligations or to cooperate with
        law enforcement; or (v) as otherwise described in this Privacy Policy. We have the right to
        remove any information you post, if deemed to be in violation of our Acceptable Use Policy
        as set forth in our Terms of Service.
      </p>
      <p>
        <span className="span-text">Cookies:</span> When you use the Service, we may send one or
        more “cookies” – a small data file – to your computer to uniquely identify your browser and
        let us help you log in faster and enhance your navigation through the BuildWise site. A
        cookie may convey anonymous information about how you browse the Service to us. A persistent
        cookie remains on your hard drive after you close your browser so that it can be used by
        your browser on subsequent visits to the Service. Persistent cookies can be removed by
        following your web browser’s directions. A session cookie is temporary and disappears after
        you close your browser. You can reset your web browser to refuse all cookies or to indicate
        when a cookie is being sent; however, some features of the Service may not function properly
        if the ability to accept cookies is disabled.
      </p>
      <p>
        Note that certain third parties engaged by your employer or e-mail provider (or your e-mail
        provider itself) may use cookies. The use of such third party cookies is not covered by this
        Policy, as we do not have access or control over these cookies. Please check with the
        relevant third parties’ privacy policies to understand how they may use cookies.
      </p>
      <p>
        <span className="span-text">Log Files:</span> When you use the Service, our servers
        automatically record certain information sent by your web browser. These server logs may
        include information such as your web request, Internet Protocol (“IP”) address, browser
        type, referring / exit pages and URLs, number of clicks and how you interact with links on
        the Service, domain names, landing pages, pages viewed, mobile carrier, and other such
        information.
      </p>
      <p>
        <span className="span-text">Clear Gifs Information:</span> When you use the Service, we may
        employ clear gifs (also known as web beacons or tracking pixels) which are used to track the
        online usage patterns. In addition, we may also use clear gifs in HTML-based emails we send
        to our users to track which emails are opened by recipients. The information is used to
        enable more accurate reporting and make our Service better.
      </p>
      <p>
        <span className="span-text">Third Party Tools:</span> We use third party tools for ad
        targeting/tailoring, remarketing, conversion tracking, user assessment and analytics tools
        to help understand use of the Service. Many of these tools collect the information as sent
        by your browser as part of a web page request, including cookies and your IP address. These
        tools also receive this information and their use of it is governed by their privacy policy
        and opt out provisions, where applicable.{' '}
      </p>
      <p>
        <span className="span-text">Do Not Track:</span> If you enable or send a “do not track”
        signal or similar mechanism relating to the tracking of browsing activities, we do not have
        practices or protocols to honor or respond to such a request. However, we only collect the
        types of information otherwise described in this Policy.
      </p>
      <Typography variant="h6" sx={{ marginBottom: -2, fontWeight: 700 }}>
        How We Share Your Information
      </Typography>
      <p>
        <span className="span-text">Your Use:</span> We will display certain personal information
        about you in your profile page and elsewhere on the Service according to the preferences you
        set in your e-mail account(s). Any information you choose to provide should reflect how much
        you want others to know about you. Please consider carefully what information you disclose
        in your profile page and your desired level of anonymity. You can review and revise your
        profile information at any time. We may also share or disclose your personal information
        with your consent, and also as otherwise described below.
      </p>
      <p>
        <span className="span-text">Service Providers, Business Partners, and Others:</span> We may
        share your personal information with other third parties (such as trusted contractors or
        service providers who host our Service or otherwise support the operation of the Service).
        If we do this, such third parties’ use of your information will be bound to terms which are
        substantially similar to this Privacy Policy as applicable to the services which they are
        providing for us.
      </p>
      <p>
        <span className="span-text">Business Transfers:</span> As we develop our business, we may
        buy or sell assets or business offerings. Customers, email, and visitor information is
        generally one of the transferred business assets in these types of transactions and we may
        transfer your personal information in these instances. We may also transfer or assign such
        information in the course of corporate divestitures, mergers, or dissolution. We will use
        commercially reasonable efforts to notify you of any change of ownership or control in which
        your personal information is transferred.
      </p>
      <p>
        <span className="span-text">Third Party Services:</span> In the event you export Content to
        a third party application or otherwise give a third party access to your Content,
        information and/or account (for example when you choose to access our Services through such
        an application), we are not responsible for what those parties do with your information, so
        you should make sure you trust the application and that it has a privacy policy acceptable
        to you.
      </p>
      <p>
        <span className="span-text">
          Compliance with Laws and Law Enforcement Requests; Protection of BuildWise’s Rights:
        </span>{' '}
        We may disclose your personal information if required to do so by law or subpoena or if we
        believe that it is reasonably necessary to comply with a law, regulation or legal request;
        to protect the safety of any person; to address fraud, security or technical issues; or to
        protect our rights or property.
      </p>
      <p>
        <span className="span-text">Non-Personally Identifiable Information:</span> We may disclose
        aggregated, anonymized or otherwise non-personally identifiable information, such as
        anonymous usage data, platform types, etc., with interested third parties to help them
        understand the usage patterns for certain BuildWise Services.
      </p>
      <Typography variant="h6" sx={{ marginBottom: -2, fontWeight: 700 }}>
        How We Protect Your Information
      </Typography>
      <p>
        The security of your information is important to us. We’ve adopted commercially reasonable
        physical, managerial, and technical safeguards to preserve the integrity and security of
        your personal information. No method of electronic transmission or storage is 100% secure,
        however, and we cannot ensure or warrant the absolute security of any information you
        transmit or store in the Service.
      </p>
      <Typography variant="h6" sx={{ marginBottom: -2, fontWeight: 700 }}>
        Legal Bases for Processing Your Personal Information
      </Typography>
      <p>
        If you are in a country in the European Union or the UK, you are entitled to an explanation
        of the legal bases we rely on to process your personal information. The legal bases for
        collecting and using the personal information described above will depend on the personal
        information concerned and the specific context in which we collect it, which is discussed
        below.
      </p>
      <p>
        <span className="span-text">Consent</span>We may process your personal information based on
        your consent such as when you purchase a service or ask us to send you certain kinds of
        marketing communications. You have the right to withdraw your consent at any time without
        affecting the lawfulness of the processing based on consent before its withdrawal.
      </p>
      <p>
        <span className="span-text">Our Legitimate Interests</span>We may process your personal
        information if doing so is necessary for our legitimate interests and your rights as an
        individual do not override those legitimate interests. For example, when we process your
        personal information to carry out fraud prevention activities and activities to increase
        network and information security, identify usage trends, determine the effectiveness of
        promotional campaigns, expand our business activities and improve our services and the
        content and functionality of our Website.
      </p>
      <p>
        <span className="span-text">To Perform a Contract</span>We may process your personal
        information to administer and fulfill contractual obligations to you.
      </p>
      <p>
        <span className="span-text">To Enable Us to Comply With a Legal Obligation</span>We may
        process your personal information to comply with legal obligations to which we are subject.
        This may include any requirement to produce audited accounts, any legal obligation to share
        information with law enforcement agencies, public or governmental authorities, and to comply
        with legal process.
      </p>
      <p>
        We may process your personal information to comply with legal obligations to which we are
        subject. This may include any requirement to produce audited accounts, any legal obligation
        to share information with law enforcement agencies, public or governmental authorities, and
        to comply with legal process.
      </p>
      <p>
        <span className="span-text">Necessary for the Exercise or Defense of Legal Claims</span>
      </p>
      <p>
        If you bring a claim against us or we bring a claim against you, we may process your
        personal information in relation to that claim.
      </p>
      <p>
        We are the Data Controller of all personal information collected from residents of the
        European Union. If you have any questions about or need further information concerning the
        legal basis on which we collect and use your personal information for any specific
        processing activity, please contact us at support@buildwise.ai.
      </p>
      <Typography variant="h6" sx={{ marginBottom: -2, fontWeight: 700 }}>
        Your Choices About Your Information – Excluding Europe
      </Typography>
      <p>
        You may, of course, decline to submit personal information through the Service, in which
        case we may not be able to provide certain services to you. You may update or correct your
        account information at any time by logging in to your account. You may also choose to stop
        receiving our newsletter or marketing emails by following the “Unsubscribe” instructions
        included in our emails. If you have any other requests or questions related to our handling,
        use or sharing of your personal information, please contact us at support@BuildWise.ai.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700 }}>
        Your Choices About Your Information – Europe
      </Typography>
      <Typography sx={{ fontWeight: 700, marginBottom: -2 }}>
        Cross-Border Transfers of Personal Data
      </Typography>
      <p>
        Personal Data that you provide while in the EU or an EAA member state will be transferred to
        the United States. The General Data Protection Regulation permits such transfer when
        necessary for the performance of a contract between you and BuildWise, if BuildWise obtains
        your explicit consent to such transfer, or if it is in BuildWise’s legitimate interest to
        transfer the Personal Data. The laws in the United States may not be as protective of your
        privacy as those in your location. If we transfer Personal Data from the EU, we will provide
        an appropriate safeguard such as binding corporate rules, standard data protection clauses,
        or your consent.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Data Subject Rights</Typography>
      <p>
        If you are an individual in a country in the European Union or the UK, you have the right to
        make requests to:
      </p>
      <ul>
        <li>Access the personal information we keep about you;</li>
        <li>Rectify inaccurate personal information;</li>
        <li>Place restrictions on our use of your personal information;</li>
        <li>Object to our use of your personal information;</li>
        <li>Ask us to delete the personal information about you we hold;</li>
        <li>
          Have your personal information transferred to you or another party of your choosing;{' '}
        </li>
        <li>Make a complaint to the relevant supervising authority</li>
      </ul>
      <p>We describe these rights more fully below.</p>
      <Typography sx={{ fontWeight: 700 }}>
        Asking us to access your personal information
      </Typography>
      <p>
        You have the right to obtain from us confirmation as to whether or not we are processing
        personal information about you and, if so, the right to be provided with the information
        contained in this Notice. You also have the right to receive a copy of the personal
        information undergoing processing.
      </p>
      <Typography sx={{ fontWeight: 700 }}>
        Asking us to rectify your personal information
      </Typography>
      <p>
        You have the right to ask us to rectify any inaccurate personal information about you and to
        have incomplete personal data completed.
      </p>
      <p>Asking us to restrict our use of your personal information</p>
      <p>
        You have the right to ask us to place a restriction on our use of your personal information
        if one of the following applies to you:
      </p>
      <ul>
        <li>
          You contest the accuracy of the information that we hold about you, while we verify its
          accuracy;
        </li>
        <li>
          We have used your information unlawfully, but you request us to restrict its use instead
          of erasing it;
        </li>
        <li>
          We no longer need the information for the purpose for which we collected it, but you need
          it to deal with a legal claim; or
        </li>
        <li>
          You have objected to us using your information, while we check whether our legitimate
          grounds override your right to object.
        </li>
      </ul>
      <Typography sx={{ fontWeight: 700 }}>
        Objecting to our use of your personal information
      </Typography>
      <p>
        You have the right to object to our use of your personal information where our reason for
        using it is based on our legitimate interests or your consent (rather than when the reason
        for using it is to perform an obligation due to you under a contract with us).
      </p>
      <Typography sx={{ fontWeight: 700 }}>
        Asking us to delete your personal information
      </Typography>
      <p>You can ask us to delete your personal information if:</p>
      <ul>
        <li>We no longer need it for the purposes for which we collected it;</li>
        <li>We have been using it with no valid legal basis;</li>
        <li>
          We are obligated to erase it to comply with a legal obligation to which we are subject;
        </li>
        <li>We need your consent to use the information and you withdraw consent;</li>
        <li>
          You object to us processing your personal information where our legal basis for doing so
          is our legitimate interests and there are no overriding legitimate grounds for the
          processing
        </li>
      </ul>
      <p>
        However, this right is not absolute. Even if you make a request for deletion, we may need to
        retain certain information for legal or administrative purposes, such as record keeping,
        maintenance of opt-out requirements, defending or making legal claims, or detecting
        fraudulent activities. We will retain information in accordance with the{' '}
        <span className="span-text">“How Long Is Your Personal Information Kept”</span> section
        below.
      </p>
      <p>
        If you do exercise a valid right to have your personal information deleted, please keep in
        mind that deletion by third parties to whom the information has been provided might not be
        immediate and that the deleted information may persist in backup copies for a reasonable
        period (but will not be available to others).
      </p>
      <p>
        Requests for deleting your personal information must be made in writing to
        support@buildwise.ai
      </p>
      <Typography sx={{ fontWeight: 700 }}>
        The right to transfer your personal information to another service provider
      </Typography>
      <p>
        You may request that we transfer some of the personal information you have provided to you
        or another service provider in electronic copy. This applies to personal information we are
        processing to service a contract with you and to personal information we are processing
        based on your consent.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Making a complaint</Typography>
      <p>
        If you have any concerns or complaints regarding our processing of your personal
        information, please contact us at support@buildwise.ai and we will do our best to answer any
        question and resolve any complaint to your satisfaction.
      </p>
      <p>
        If, for whatever reason, you feel we do not meet the standards you expect of us, you are
        also entitled to make a complaint to your local supervisory authority. The EU Commission has
        a list of supervisory authorities here.
      </p>
      <Typography sx={{ fontWeight: 700 }}>BuildWise Blog & Community</Typography>
      <p>
        Our Service offers publicly accessible community services including blogs, and forums. You
        should be aware that any information you provide in these areas may be read, collected, and
        used by others who access them. Your posts may remain even after you cancel your account.
      </p>
      <Typography sx={{ fontWeight: 700 }}>How Long Is Your Personal Information Kept</Typography>
      <p>
        We retain your personal information to the extent it is necessary for the purpose for which
        it was collected. We will delete your personal information when it is no longer necessary
        for the purpose for which it was collected, or upon your request, subject to exceptions as
        discussed in this policy or under applicable law, contract, or regulation.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Children’s Privacy</Typography>
      <p>
        Our Service is not directed to persons under 13, and we do not knowingly collect or solicit
        personal information from anyone under the age of 13 or knowingly allow such persons to
        register with the Service. If we become aware that we have collected personal information
        from a child under age 13 without verification of parental consent, we take steps to remove
        that information. If you believe that we may have any information from or about a child
        under 13, please contact us at support@BuildWise.ai.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Links to Other Websites</Typography>
      <p>
        We are not responsible for the practices employed by websites linked to or from the Service,
        nor the information or content contained therein. Please remember that when you use a link
        to go from the Service to another website, our Privacy Policy is no longer in effect. Your
        browsing and interaction on any other website, including those that have a link on our
        website, is subject to that website’s own rules and policies.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Changes to Our Privacy Policy</Typography>
      <p>
        If we change our Privacy Policy, we will post those changes on this page to keep you aware
        of what information we collect, how we use it and under what circumstances we may disclose
        it. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>
      <Typography sx={{ fontWeight: 700 }}>Contact Us</Typography>
      <p>
        If you have any questions about this Privacy Policy, please contact us at
        support@BuildWise.ai.
      </p>
    </$Wrapper>
  );
};

export default Privacy;
