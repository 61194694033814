import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BuildWiseLoader from 'routes/BuildWiseLoader';

function SSOSignIn() {
  const { hash } = useLocation();
  const { ssoSignIn } = useAuth();

  useEffect(() => {
    const hashParts = decodeURIComponent(hash).replace('#', '').split('&');
    let access_token = '';
    let id_token = '';

    hashParts.forEach((part) => {
      const keyValue = part.split('=');
      const key = keyValue[0];
      const value = keyValue[1];

      if (key === 'access_token') {
        access_token = value;
      } else if (key === 'id_token') {
        id_token = value;
      }
    });

    if (access_token && id_token) {
      ssoSignIn({ access_token, id_token });
    }
  }, [hash]);

  return <BuildWiseLoader />;
}

export default SSOSignIn;
