import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Loader from 'components/common/loader/Loader';
import useUserData from '../hooks/useUserData';
import {
  ACCOUNT_SETTINGS,
  INTEGRATIONS,
  COMPANY_CONFIGURATIONS,
  USERS,
  USER_GROUPS,
  LOGIN,
  TOPIC_EXPERTS,
  SUBSCRIPTION_SETTINGS,
  TEMPLATES,
  TEMPLATE_DETAILS,
  // COMAPNY_DASHBOARDS,
  USER_ACTIVITY_DASHBOARDS,
  AI_DRAFT_WORKBENCH,
  BUILDWISE_COMPANIES,
  BUILDWISE_CONFIGURATIONS,
  BUILDWISE_USERS,
  BUILDWISE_GPT_BOOKMARKS,
  BUILDWISE_GPT_CHAT,
  BUILDWISE_GPT_TEMPLATES,
  HOME,
  COMPANY_FIELDS,
  COMPANY_FILTERS,
  ADMIN_FIELDS,
  ADMIN_FILTERS,
  COMPANY_PROGRESS_CARDS,
  ADMIN_PROGRESS_CARDS,
  CREATE_COMPANY_PROGRESS_CARD,
  EDIT_COMPANY_PROGRESS_CARD,
  CREATE_ADMIN_PROGRESS_CARD,
  EDIT_ADMIN_PROGRESS_CARD,
  COMPANY_SCORECARDS,
  CREATE_COMPANY_SCORECARD,
  EDIT_COMPANY_SCORECARD,
  COMPANY_BUILDWISE_SCORECARDS,
  ADMIN_SCORECARDS,
  CREATE_ADMIN_SCORECARD,
  EDIT_ADMIN_SCORECARD,
  ADMIN_BUILDWISE_SCORECARDS,
  CREATE_COMPANY_BUILDWISE_SCORECARD,
  EDIT_COMPANY_BUILDWISE_SCORECARD,
  CREATE_ADMIN_BUILDWISE_SCORECARD,
  EDIT_ADMIN_BUILDWISE_SCORECARD,
} from '../constants/routes';
import {
  getBuildWiseAdminRoutePath,
  getBuildWiseGPTRoutePath,
  getCompanyAdminRoutePath,
} from '../utility/helpers';
import SubscriptionSettings from 'pages/admin/subscriptionSettings/SubscriptionSettings';
import MainWrapper from 'components/main/MainWrapper';
import CompanyAdminDrawer from 'components/drawer/CompanyAdminDrawer';
import PageNotFound from './PageNotFound';
import { styled } from '@mui/material/styles';
// import Header from 'components/header/Header';
import { Box } from '@mui/material';
import BuildWiseLoader from './BuildWiseLoader';
import AIDraftsWorkbench from 'pages/buildwiseAdmin/AIDraftsWorkbench/AIDraftsWorkbench';
import BuildWiseCompanies from 'pages/buildwiseAdmin/companiesManagement/BuildWiseCompanies';
import BuildWiseConfigurations from 'pages/buildwiseAdmin/configurations/BuildWiseConfigurations';
import BuildWiseUsers from 'pages/buildwiseAdmin/usersManagement/BuildWiseUsers';
import BuildWiseGPT from 'pages/buildwiseAdmin/BuildWiseGPT/BuildWiseGPT';
import PromptBookmarks from 'pages/buildwiseAdmin/BuildWiseGPT/PromptBookmarks';
import PromptTemplates from 'pages/buildwiseAdmin/BuildWiseGPT/PromptTemplates';

const AccountSettings = lazy(() => import('../pages/admin/accountSettings/AccountSettings'));
const Integrations = lazy(() => import('../pages/admin/integrations/Integrations'));
const CompanyConfigurations = lazy(() => import('@buildwise/pages/CompanyConfigurations'));
const CompanyCustomFields = lazy(() => import('@buildwise/pages/CompanyFields'));
const CompanyCustomFilters = lazy(() => import('@buildwise/pages/CompanyFilters'));
const AdminFields = lazy(() => import('@buildwise/pages/AdminFields'));
const AdminFilters = lazy(() => import('@buildwise/pages/AdminFilters'));
const Templates = lazy(() => import('@buildwise/pages/Templates'));
const TemplateDetails = lazy(() => import('@buildwise/pages/TemplateDetails'));
const CompanyProgressCards = lazy(() => import('@buildwise/pages/CompanyProgressCards'));
const AdminProgressCards = lazy(() => import('@buildwise/pages/AdminProgressCards'));
const ProgressCardDetails = lazy(() => import('@buildwise/pages/ProgressCardDetails'));
const CompanyScoreCards = lazy(() => import('@buildwise/pages/CompanyScoreCards'));
const CompanyBuildWiseScoreCards = lazy(
  () => import('@buildwise/pages/CompanyBuildWiseScoreCards'),
);
const AdminScoreCards = lazy(() => import('@buildwise/pages/AdminScoreCards'));
const AdminBuildWiseScoreCards = lazy(() => import('@buildwise/pages/AdminBuildWiseScoreCards'));
const ScoreCardDetails = lazy(() => import('@buildwise/pages/ScoreCardDetails'));
const TopicExperts = lazy(() => import('../pages/admin/userAdministration/TopicExperts'));
const Users = lazy(() => import('../pages/admin/userAdministration/CompanyUsers'));
const UserGroups = lazy(() => import('../pages/admin/userAdministration/UserGroups'));
const CompanyDashboards = lazy(() => import('pages/CompanyDashboards/CompanyDashboards'));

const CompanyAdminRoutes = () => {
  const navigate = useNavigate();
  const { user, userInfo, isNovaOrCompanyAdmin } = useUserData();
  useEffect(() => {
    if (!user && !userInfo.isLoading) {
      navigate(LOGIN);
    } else if (user) {
      const isAdmin = isNovaOrCompanyAdmin;
      if (!isAdmin) {
        navigate(HOME);
      }
    }
  }, [user, userInfo.isLoading]);

  if (!user && userInfo.isLoading) return <BuildWiseLoader />;

  return (
    <Routes>
      {/* <Route
        path={getCompanyAdminRoutePath(COMAPNY_DASHBOARDS)}
        element={
          <$Root>
            <Header />
            <$Container>
              <CompanyDashboards />
            </$Container>
          </$Root>
        }
      /> */}
      <Route
        path="/*"
        element={
          <MainWrapper sidebar={CompanyAdminDrawer}>
            <Suspense fallback={<Loader variant="fixed" />}>
              <Routes>
                {/* Company Admin Routes */}
                <Route
                  path={getCompanyAdminRoutePath(ACCOUNT_SETTINGS)}
                  element={<AccountSettings />}
                />
                <Route
                  path={getCompanyAdminRoutePath(SUBSCRIPTION_SETTINGS)}
                  element={<SubscriptionSettings />}
                />
                <Route path={getCompanyAdminRoutePath(INTEGRATIONS)} element={<Integrations />} />
                <Route path={getCompanyAdminRoutePath(TEMPLATES)} element={<Templates />} />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_PROGRESS_CARDS)}
                  element={<CompanyProgressCards />}
                />
                <Route
                  path={getCompanyAdminRoutePath(CREATE_COMPANY_PROGRESS_CARD)}
                  element={<ProgressCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(EDIT_COMPANY_PROGRESS_CARD)}
                  element={<ProgressCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_SCORECARDS)}
                  element={<CompanyScoreCards />}
                />
                <Route
                  path={getCompanyAdminRoutePath(CREATE_COMPANY_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(EDIT_COMPANY_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_BUILDWISE_SCORECARDS)}
                  element={<CompanyBuildWiseScoreCards />}
                />
                <Route
                  path={getCompanyAdminRoutePath(CREATE_COMPANY_BUILDWISE_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(EDIT_COMPANY_BUILDWISE_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getCompanyAdminRoutePath(TEMPLATE_DETAILS)}
                  element={<TemplateDetails />}
                />
                <Route path={getCompanyAdminRoutePath(USERS)} element={<Users />} />
                <Route path={getCompanyAdminRoutePath(USER_GROUPS)} element={<UserGroups />} />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_CONFIGURATIONS)}
                  element={<CompanyConfigurations />}
                />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_FIELDS)}
                  element={<CompanyCustomFields />}
                />
                <Route
                  path={getCompanyAdminRoutePath(COMPANY_FILTERS)}
                  element={<CompanyCustomFilters />}
                />
                <Route
                  path={getCompanyAdminRoutePath(USER_ACTIVITY_DASHBOARDS)}
                  element={<CompanyDashboards />}
                />
                <Route path={getCompanyAdminRoutePath(TOPIC_EXPERTS)} element={<TopicExperts />} />

                {/* BuildWise Admin Routes */}
                <Route
                  path={getBuildWiseAdminRoutePath(BUILDWISE_COMPANIES)}
                  element={<BuildWiseCompanies />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(BUILDWISE_USERS)}
                  element={<BuildWiseUsers />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(ADMIN_PROGRESS_CARDS)}
                  element={<AdminProgressCards />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(CREATE_ADMIN_PROGRESS_CARD)}
                  element={<ProgressCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(EDIT_ADMIN_PROGRESS_CARD)}
                  element={<ProgressCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(ADMIN_SCORECARDS)}
                  element={<AdminScoreCards />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(CREATE_ADMIN_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(EDIT_ADMIN_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(ADMIN_BUILDWISE_SCORECARDS)}
                  element={<AdminBuildWiseScoreCards />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(CREATE_ADMIN_BUILDWISE_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(EDIT_ADMIN_BUILDWISE_SCORECARD)}
                  element={<ScoreCardDetails />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(BUILDWISE_CONFIGURATIONS)}
                  element={<BuildWiseConfigurations />}
                />
                <Route path={getBuildWiseAdminRoutePath(ADMIN_FIELDS)} element={<AdminFields />} />
                <Route
                  path={getBuildWiseAdminRoutePath(ADMIN_FILTERS)}
                  element={<AdminFilters />}
                />
                <Route
                  path={getBuildWiseAdminRoutePath(AI_DRAFT_WORKBENCH)}
                  element={<AIDraftsWorkbench />}
                />

                {/* BuildWise GPT Routes */}
                <Route
                  path={getBuildWiseGPTRoutePath(BUILDWISE_GPT_CHAT)}
                  element={<BuildWiseGPT />}
                />
                <Route
                  path={getBuildWiseGPTRoutePath(BUILDWISE_GPT_BOOKMARKS)}
                  element={<PromptBookmarks />}
                />
                <Route
                  path={getBuildWiseGPTRoutePath(BUILDWISE_GPT_TEMPLATES)}
                  element={<PromptTemplates />}
                />

                <Route path="/*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </MainWrapper>
        }
      />
    </Routes>
  );
};

export const $Root = styled(Box, { label: 'Root' })(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  minHeight: theme.vh100,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const $Container = styled(Box, { label: 'ContentBox' })(({ theme }) => ({
  maxWidth: '1440px',
  margin: '0 auto',
  padding: '16px 48px',
  width: '100%',
  display: 'flex',
  position: 'relative',
}));

export default CompanyAdminRoutes;
