import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import IconButton from '@mui/material/IconButton';
import DragMove2FillIcon from 'remixicon-react/DragMove2FillIcon';
import { Link } from 'react-router-dom';
import { VIEW_ARTICLE } from 'constants/routes';
import { ArticleType } from '../hooks/useArticlesQuery';
import Dot from 'components/common/dot/Dot';
import { formatDate } from 'utility/helpers';
import PlaylistArticleThumbnail from './PlaylistArticleThumbnail';
import { PostFormData } from 'pages/addPost/hooks/useAddPostForm';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

type RenderPlayistArticlesProps = {
  draggable?: boolean;
  posts: ArticleType[];
  getValues?: UseFormGetValues<PostFormData>;
  setValue?: UseFormSetValue<PostFormData>;
};

function RenderPlayistArticles({
  draggable = false,
  posts,
  getValues,
  setValue,
}: RenderPlayistArticlesProps) {
  const theme = useTheme();
  const [articles, setArticles] = useState(posts);

  useEffect(() => {
    setArticles(posts);
  }, [posts]);

  const deletePostFromPlaylist = async (playListArticleId: string) => {
    const copyArticles = [...articles];
    const removePlayListArticles = copyArticles.filter(
      (article) => article.id !== playListArticleId,
    );
    setArticles(removePlayListArticles);
    const playListArticleIds = (getValues?.()?.playListArticleIds || []).filter(
      (playlistId) => playlistId !== playListArticleId,
    );
    setValue?.('playListArticleIds', playListArticleIds, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue?.('playListArticles', removePlayListArticles, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let _articles = [...articles];

    //remove and save the dragged item content
    const draggedItemContent = _articles.splice(dragItem.current, 1)[0];

    //switch the position
    _articles.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setArticles(_articles);
    const playListArticleIds = _articles.map((article) => article.id);
    setValue?.('playListArticleIds', playListArticleIds, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Container>
      {articles.map((post, index) => {
        return (
          <PlaylistArticleHoverContainer
            key={post.id}
            draggable={draggable}
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
            className={draggable ? '' : 'detailed'}
            {...(!draggable && {
              component: Link,
              to: VIEW_ARTICLE.replace(':articleId', post.id),
            })}
          >
            <PlaylistContainer>
              {draggable && (
                <ActionButtonContainer aria-label="close">
                  <DragMove2FillIcon />
                </ActionButtonContainer>
              )}
              <PlaylistArticleContent>
                <PlaylistArticleThumbnail
                  attachments={post?.attachments || []}
                  coverPhotoFileName={post.coverPhotoFileName}
                />
                <Box ml={2} flex={1}>
                  <PlaylistArticleTitle>{post.title}</PlaylistArticleTitle>
                  <PlaylistArticleInfo>
                    <span title="Created By">{post.userNameToDisplay}</span>
                    <Dot />
                    <span title="Created On">{formatDate(post.createdAt)}</span>
                  </PlaylistArticleInfo>

                  <Box display="flex" draggable={draggable}>
                    <InteractionContainer title="Views">
                      <EyeLineIcon size={16} color={theme.palette.custom.mistBlue} />
                      &nbsp;<InteractionItemCount>{post.totalViews}</InteractionItemCount>
                    </InteractionContainer>
                    <InteractionContainer title="Likes">
                      <ThumbUpLineIcon size={16} color={theme.palette.custom.mistBlue} />
                      &nbsp;<InteractionItemCount>{post.totalLikes}</InteractionItemCount>
                    </InteractionContainer>
                    <InteractionContainer title="Comments">
                      <Chat3LineIcon size={16} color={theme.palette.custom.mistBlue} />
                      &nbsp;<InteractionItemCount>{post.totalComments}</InteractionItemCount>
                    </InteractionContainer>
                  </Box>
                </Box>
              </PlaylistArticleContent>
            </PlaylistContainer>
            {draggable && (
              <ActionButtonContainer
                aria-label="close"
                onClick={() => deletePostFromPlaylist(post.id)}
              >
                <CloseLineIcon size={24} />
              </ActionButtonContainer>
            )}
          </PlaylistArticleHoverContainer>
        );
      })}
    </Container>
  );
}

const Container = styled(Box, { label: 'Container' })({
  marginTop: 16,
  maxHeight: 1000,
  overflow: 'auto',
  '&::-webkit-scrollbar-thumb': {
    visibility: 'hidden',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    visibility: 'visible',
  },
});

const PlaylistArticleHoverContainer = styled(Box, { label: 'PlaylistArticleHoverContainer' })(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: '0.5s',
    '&.detailed': {
      padding: '0 24px',
    },
    '&:hover': {
      backgroundColor: theme.palette.custom.whiteSmoke,
    },
  }),
);

const PlaylistContainer = styled(Box, { label: 'PlaylistContainer' })({
  display: 'flex',
  alignItems: 'center',
});

const PlaylistArticleContent = styled(Box, { label: 'PlaylistArticleContent' })(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 0',
}));

const PlaylistArticleTitle = styled(Box, { label: 'PlaylistArticleTitle' })(({ theme }) => ({
  color: theme.palette.custom.tuna,
  fontSize: 16,
  fontWeight: 700,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

const PlaylistArticleInfo = styled(Typography, { label: 'PlaylistArticleInfo' })(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.custom.mistBlue,
  marginTop: 4,
  marginBottom: 4,
}));

const InteractionContainer = styled(Box, { label: 'InteractionContainer' })(({ theme }) => ({
  color: theme.palette.custom.mediumBlack,
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  marginRight: 24,
}));

const InteractionItemCount = styled(Typography, { label: 'InteractionItemCount' })(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.custom.mistBlue,
}));

const ActionButtonContainer = styled(IconButton, { label: 'IconButton' })(({ theme }) => ({
  color: theme.palette.custom.black,
  marginRight: 8,
}));

export default RenderPlayistArticles;
