import { Avatar, Box, Typography } from '@mui/material';
import RenderDocuments from 'pages/article/components/RenderDocuments';
import { styled, useTheme } from '@mui/material/styles';
import UserAvatar from 'components/common/userAvatar/UserAvatar';
import { ArticleType, AttachmentType } from '../hooks/useArticlesQuery';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { PROJECT } from 'constants/routes';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import { formatNumber } from 'utility/helpers';
import moment from 'moment';
import Dot from 'components/common/dot/Dot';
import { ArticleAttachmentsSummary } from 'pages/article/hooks/useArticleAttachmentsSummary';

type ArticleProjectDetailsProps = {
  article: ArticleType;
  articleAttachmentsSummary?: ArticleAttachmentsSummary;
  documents: AttachmentType[];
  searchKeyword: string;
  source?: string;
};

function ArticleProjectDetails({
  article,
  articleAttachmentsSummary,
  documents,
  searchKeyword,
  source,
}: ArticleProjectDetailsProps) {
  const theme = useTheme();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { project } = article;

  const highlightSearchkeyWord = (result: string, searchKeyword?: string) => {
    const searchResults = result?.match(new RegExp(searchKeyword || '', 'ig'));
    const replaceAllSearchResults = () => {
      if (searchResults && searchResults?.length > 0) {
        for (const keyword of searchResults) {
          return "<span class='highlight'>" + keyword + '</span>';
        }
      }
      return '';
    };
    return result?.replace(new RegExp(searchKeyword || '', 'ig'), replaceAllSearchResults());
  };

  return (
    <Box>
      <RenderDocuments
        documents={documents}
        articleAttachmentsSummary={articleAttachmentsSummary}
        source={source}
      />
      {project && (
        <$Detail>
          <$Title>Project Name</$Title>
          <Box ml={-1.5}>
            <$ProjectContainer>
              <$Link
                to={`${PROJECT?.replace(':projectId', project.id)}${
                  searchParams.get('q') ? `?q=${searchParams.get('q')}` : ''
                }`}
                state={searchKeyword && { ...state }}
              >
                <Avatar
                  sx={{
                    bgcolor: theme.palette.custom.lightBlue,
                    mr: 2,
                    width: '50px',
                    height: '50px',
                  }}
                  variant="rounded"
                >
                  <BuildingLineIcon color={theme.palette.custom.black} />
                </Avatar>
              </$Link>

              <Box flexGrow={1}>
                <$Link
                  to={`${PROJECT?.replace(':projectId', project.id)}${
                    searchParams.get('q') ? `?q=${searchParams.get('q')}` : ''
                  }`}
                  state={searchKeyword && { ...state }}
                >
                  <$ProjectName
                    dangerouslySetInnerHTML={{
                      __html: highlightSearchkeyWord(project?.name, searchKeyword),
                    }}
                  />
                </$Link>
                {(project.city || project.state_code || project.country_code) && (
                  <$ProjectInfo>
                    {project.city}
                    {(project.state_code || project.country_code) && project.city ? ',' : ''}{' '}
                    {project.state_code}
                    {project.country_code && project.state_code ? ',' : ''} {project.country_code}
                  </$ProjectInfo>
                )}
                {project.totalValue && (
                  <$ProjectInfo>
                    <b>Total Value:&nbsp;$</b> {formatNumber(project.totalValue)}
                  </$ProjectInfo>
                )}
                <Box display="flex">
                  <>
                    {project.start_date && (
                      <$ProjectInfo>
                        <b>Start Date:&nbsp;</b> {moment(project.start_date).format('MMM DD, YYYY')}
                      </$ProjectInfo>
                    )}
                    {project.start_date && project.completion_date && <Dot />}
                    {project.completion_date && (
                      <$ProjectInfo>
                        <b>Completion Date:&nbsp;</b>{' '}
                        {moment(project.completion_date).format('MMM DD, YYYY')}
                      </$ProjectInfo>
                    )}
                  </>
                </Box>
              </Box>
            </$ProjectContainer>
          </Box>
        </$Detail>
      )}
      {article.projectPhases && article.projectPhases?.length > 0 && (
        <$Detail>
          <$Title>Project Phase</$Title>
          {article.projectPhases.join(', ')}
          {/* <RenderLinks
            links={article.projectPhases}
            getLinkConfig={(item: string) => ({
              to: `${SEARCH}?q=${encodeURIComponent(item)}`,
              state,
            })}
          /> */}
        </$Detail>
      )}
      {article.divisions && article.divisions?.length > 0 && (
        <$Detail>
          <$Title>Divisions</$Title>
          {article.divisions.join(', ')}
          {/* <RenderLinks
            links={article.divisions}
            getLinkConfig={(item: string) => ({
              to: `${SEARCH}?q=${encodeURIComponent(item)}`,
              state,
            })}
          /> */}
        </$Detail>
      )}
      {article.specSections && article.specSections.length > 0 && (
        <$Detail>
          <$Title>Spec Sections</$Title>
          {article.specSections.join(', ')}
          {/* <RenderLinks
            links={article.specSections}
            getLinkConfig={(item: string) => ({
              to: `${SEARCH}?q=${encodeURIComponent(item)}`,
              state,
            })}
          /> */}
        </$Detail>
      )}
      {article.topics && article.topics.length > 0 && (
        <$Detail>
          <$Title>Topics</$Title>
          {article.topics.join(', ')}
          {/* <RenderLinks
            links={article.topics}
            getLinkConfig={(item: string) => ({
              to: `${SEARCH}?q=${encodeURIComponent(item)}`,
              state,
            })}
          /> */}
        </$Detail>
      )}
      {article.originIndicator === 'QUESTION_AND_ANSWER' && (
        <$Detail>
          <$Title>Asked By</$Title>
          <Box mt={-1}>
            {article.questionCreatedBy ? (
              <UserAvatar
                isSmall
                title={article.questionCreatedByDisplayName}
                avatarName={article.questionCreatedByDisplayName}
                userName={article.questionCreatedBy}
                subTitle={article.questionCreatedByTitleToDisplay}
                profileImageURL={article?.questionCreatedByProfileImageURL}
              />
            ) : (
              <Typography>Anonymous</Typography>
            )}
          </Box>
        </$Detail>
      )}
    </Box>
  );
}

// type RenderLinksProps = {
//   links: string[];
//   getLinkConfig: (item: string) => LinkProps;
// };

// const RenderLinks = ({ links, getLinkConfig }: RenderLinksProps) => {
//   return (
//     <>
//       {links.map((link, index) => (
//         <Fragment key={link}>
//           <$Link {...getLinkConfig(link)}>{link}</$Link>
//           {index !== links.length - 1 && ', '}
//         </Fragment>
//       ))}
//     </>
//   );
// };

const $Detail = styled(Box, { label: 'Detail' })({
  marginTop: 12,
});

const $Title = styled(Typography, { label: 'Title' })({
  fontSize: 14,
  fontWeight: 700,
});

const $Link = styled(Link, { label: 'Link' })(({ theme }) => ({
  display: 'inline-block',
  height: 'fit-content',
  width: 'fit-content',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

const $ProjectContainer = styled(Box, { label: 'ProjectContainer' })({
  width: '100%',
  padding: 16,
  display: 'flex',
  marginTop: 1,
});

const $ProjectName = styled(Typography, { label: 'ProjectName' })(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '19.2px',
  marginBottom: 2,
  cursor: 'pointer',
  width: 'fit-content',
  display: 'block',
  '& .highlight': {
    backgroundColor: 'yellow !important',
  },
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.custom.link,
  },
}));

const $ProjectInfo = styled(Typography, { label: 'ProjectInfo' })(({ theme }) => ({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '0.25px',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 450,
  color: theme.palette.custom.mistBlue,
}));

export default ArticleProjectDetails;
