import useUserData, { UserType } from 'hooks/useUserData';
import { useRef, useState, useEffect, KeyboardEvent, useMemo } from 'react';
import useBuildWiseGPTHistoryQuery, {
  BuildWiseGPTHistoryItem,
  PromptSource,
} from './useBuildWiseGPTHistoryQuery';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import moment from 'moment';
import UserAPI from 'api/UserAPI';
import { updateUserDetailsCache } from 'pages/profile/hooks/useUserProfileData';
import { useLocation, useNavigate } from 'react-router-dom';
import { BUILDWISE_GPT_CHAT } from 'constants/routes';
import BuildWiseGPTAPI from 'api/BuildWiseGPTAPI';

export type PromptHistoryItem = {
  date: string;
  data: BuildWiseGPTHistoryItem[];
};

export const filterHistoryByDate = (
  historyData: BuildWiseGPTHistoryItem[],
): PromptHistoryItem[] => {
  const chatByDate: Record<string, BuildWiseGPTHistoryItem[]> = {};

  historyData
    .filter((item) => !item.promptSource || item.promptSource === PromptSource.BuildWiseGPT)
    .forEach((item) => {
      const dateWithoutTime = moment(item.createdAt).format('YYYY-MM-DD');
      chatByDate[dateWithoutTime] = chatByDate[dateWithoutTime]
        ? [...chatByDate[dateWithoutTime], item]
        : [item];
    });

  const allDates = Object.keys(chatByDate);

  return _.orderBy(
    allDates.map((item) => ({
      date: item,
      data: _.orderBy(chatByDate[item], 'createdAt'),
    })),
    'date',
  );
};

function useBuildWiseGPT() {
  const { user } = useUserData();
  const { state } = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { buildwiseGPTHistory, updatePromptHistoryCache } = useBuildWiseGPTHistoryQuery();
  const [value, setValue] = useState(state?.prompt || '');
  const [collpasedDates, setCollapsedDates] = useState<string[]>([]);
  const [openFullViewModal, setFullViewModal] = useState(!!state?.prompt || false);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const history = useMemo(() => filterHistoryByDate(buildwiseGPTHistory), [buildwiseGPTHistory]);

  useEffect(() => {
    if (containerRef.current && history.length > 1) {
      containerRef.current.scrollIntoView();
    }
  }, [containerRef, history]);

  const postPrompt = async (value: string, isRegenerating?: boolean) => {
    if (value.trim()) {
      setValue('');
      setCollapsedDates((prevState) => {
        const updatedState = [...prevState];
        const dateWithoutTime = moment().format('YYYY-MM-DD');
        const index = updatedState.indexOf(dateWithoutTime);
        if (index > -1) {
          updatedState.splice(index, 1);
        }

        return updatedState;
      });
      const lastPrompt = _.orderBy(buildwiseGPTHistory, 'createdAt', 'desc')[0];
      const chat = {
        _id: _.uniqueId(),
        createdAt: new Date().toISOString(),
        createdBy: user?.username || '',
        genAIResponse: '',
        prompt: value.trim(),
        promptSource: PromptSource.BuildWiseGPT,
      };

      if (isRegenerating) {
        updatePromptHistoryCache({
          action: 'update',
          chat: {
            ...lastPrompt,
            genAIResponse: '',
          },
        });
      } else {
        updatePromptHistoryCache({
          action: 'add',
          chat,
        });
      }
      setLoading(true);
      try {
        const { data } = await BuildWiseGPTAPI.postPrompt({
          promptText: value.trim(),
          ...(isRegenerating && { regenerateFlag: true }),
        });

        updatePromptHistoryCache({
          action: 'update',
          chat: {
            ...(isRegenerating ? lastPrompt : chat),
            genAIResponse: data.successResponse,
          },
        });

        if (state?.prompt) {
          navigate(BUILDWISE_GPT_CHAT);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSend = (_value: string) => {
    postPrompt(_value);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter' && value) {
      event.preventDefault();
      postPrompt(value);
    }
  };

  const onFullModalMinimized = (_value: string) => {
    setValue(_value);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  };

  const bookmarkPrompt = async (prompt: BuildWiseGPTHistoryItem) => {
    const oldUser = { ...user };
    try {
      const updatedPromptIds = [...(user?.gptPromptIds || [])];
      if (prompt.isBookMarked) {
        updatedPromptIds.splice(updatedPromptIds.indexOf(prompt._id), 1);
      } else {
        updatedPromptIds.push(prompt._id);
      }
      updateUserDetailsCache({ ...user, gptPromptIds: updatedPromptIds } as UserType);

      await UserAPI.editUser({
        ...user,
        gptPromptHistoryIds: updatedPromptIds,
      });
    } catch (error) {
      updateUserDetailsCache(oldUser as UserType);
      toast.error(catchErrorMessage(error));
    }
  };

  const regeneratePrompt = (prompt?: string) => {
    if (prompt) {
      setValue(prompt);
    } else {
      const lastPrompt = _.orderBy(
        buildwiseGPTHistory.filter(
          (item) => !item.promptSource || item.promptSource === PromptSource.BuildWiseGPT,
        ),
        'createdAt',
        'desc',
      )[0];
      setValue(lastPrompt.prompt);
    }
    setFullViewModal(true);
  };

  const onClose = () => {
    setValue('');
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(-1, -1);
        inputRef.current.focus();
      }
    }, 0);
  };

  return {
    collpasedDates,
    containerRef,
    history,
    inputRef,
    loading,
    openFullViewModal,
    value,
    bookmarkPrompt,
    handleKeyDown,
    onClose,
    onFullModalMinimized,
    onSend,
    regeneratePrompt,
    setCollapsedDates,
    setFullViewModal,
    setValue,
  };
}

export default useBuildWiseGPT;
