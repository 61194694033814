import {
  Dialog,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
} from '@mui/material';
import CompanyAPI from 'api/CompanyAPI';
import CustomDialogTitle from 'components/modal/CustomDialogTitle';
import { updateCompaniesQueryCache } from 'hooks/useCompanyQueries';
import { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';

export type AddCompanyFormValuesType = {
  name: string;
  emailDomain: string;
};

type AddCompanyModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function AddCompanyModal({ open, setOpen }: AddCompanyModalProps) {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      emailDomain: '',
    },
  });
  const name = watch('name');

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (values: AddCompanyFormValuesType) => {
    try {
      setLoading(true);
      const { data } = await CompanyAPI.addCompany({
        name: values.name.trim(),
        emailDomain: values.emailDomain.trim(),
      });
      if (data.company) {
        updateCompaniesQueryCache(data.company, 'add');
        toast.success('Company added successfully');
      }
      closeModal();
    } catch (error) {
      console.error('ADD_COMPANY_ERROR', error);
      toast.error(catchErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = handleSubmit(onSubmit);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CustomDialogTitle onClose={closeModal} id="dialog-title">
        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: 'black' }}>
          Add Company
        </Typography>
      </CustomDialogTitle>
      <DialogContent dividers id="dialog-description">
        <Controller
          control={control}
          rules={{
            required: 'Please enter company name',
            minLength: { value: 3, message: 'Company name should be atleast 3 characters' },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ' '}
              {...field}
              variant="outlined"
              label="Company Name"
              autoFocus
              autoComplete="off"
              value={!!field.value.trim() ? field.value : ''}
              placeholder="Enter company name e.g BuildWise"
              disabled={loading}
            />
          )}
          name="name"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ' '}
              {...field}
              variant="outlined"
              label="Email Domain"
              autoComplete="off"
              value={!!field.value.trim() ? field.value : ''}
              placeholder="Enter email domain e.g buildwise.ai"
              disabled={loading}
            />
          )}
          name="emailDomain"
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading || name.trim().length < 3}
          onClick={handleAdd}
          variant="contained"
        >
          {loading ? <CircularProgress sx={{ color: '#000' }} size={20} /> : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCompanyModal;
