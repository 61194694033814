import KnowledgeAPI from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { JAVA_SERVICE_ENDPOINTS } from '@buildwise/libs/services/endpoints';
import useUserData from 'hooks/useUserData';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export type PromptTemplate = {
  bookMarked: boolean;
  companyId: string;
  createdAt: string | Date;
  createdBy: string;
  createdByDisplayName?: string;
  createdByProfileImageUrl: string;
  createdByTitle: string;
  displayName?: string;
  id: string;
  prompt?: string;
  promptName: string;
  regenerateFlag: boolean;
  promptType?: string;
  updatedAt?: string | Date;
  updatedBy: string;
};

type PromptTemplatesResponse = {
  gptPrompts: PromptTemplate[];
};

function usePromptTemplatesQuery(options: { shouldFetch?: boolean } = {}) {
  const { shouldFetch = true } = options;
  const queryClient = useQueryClient();
  const { companyId } = useUserData();
  const { data: templatesData, ...rest } = useQuery<AxiosResponse<PromptTemplatesResponse>, Error>(
    [JAVA_SERVICE_ENDPOINTS.PROMPT_TEMPLATES, companyId],
    () => KnowledgeAPI.getPromptTemplates(companyId),
    {
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
      keepPreviousData: true,
      enabled: !!companyId && shouldFetch,
    },
  );

  const templates = useMemo(
    () =>
      Array.isArray(templatesData?.data?.gptPrompts) ? templatesData?.data?.gptPrompts || [] : [],
    [templatesData?.data],
  );

  const updatePromptTemplatesCache = ({
    action,
    template,
  }: {
    action: 'add' | 'update' | 'delete';
    template: PromptTemplate;
  }) => {
    const queryStatus = queryClient.getQueryState([
      JAVA_SERVICE_ENDPOINTS.PROMPT_TEMPLATES,
      companyId,
    ]);

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<PromptTemplatesResponse> | undefined>(
        [JAVA_SERVICE_ENDPOINTS.PROMPT_TEMPLATES, companyId],
        (old) => {
          if (old) {
            const updatedTemplates = [...(old?.data.gptPrompts || [])];

            if (action === 'add') {
              updatedTemplates.unshift(template);
            }

            if (action === 'update') {
              const index = updatedTemplates.findIndex((item) => item.id === template.id);

              if (index > -1) {
                updatedTemplates[index] = {
                  ...updatedTemplates[index],
                  ...template,
                };
              }
            }

            if (action === 'delete') {
              const index = updatedTemplates.findIndex((item) => item.id === template.id);

              if (index > -1) {
                updatedTemplates.splice(index, 1);
              }
            }

            return {
              ...old,
              data: {
                ...old.data,
                gptPrompts: updatedTemplates,
              },
            };
          }

          return old;
        },
      );
    }
  };

  return {
    templates,
    ...rest,
    updatePromptTemplatesCache,
  };
}

export default usePromptTemplatesQuery;
