import { useQuery } from 'react-query';
import { useMemo } from 'react';
import useUserData, { UserType } from '../../../hooks/useUserData';
import { JAVA_SERVICE_ENDPOINTS } from '../../../libs/services/endpoints';
import { AxiosResponse } from 'axios';
import { queryClient } from '../../../config/queryClient';
import UserAPI from '../../../api/UserAPI';

export const usePeopleQuery = ({ searchKeyword = '' }: { searchKeyword?: string } = {}) => {
  const { companyId } = useUserData();

  const {
    data: peopleData,
    isLoading,
    refetch,
  } = useQuery<AxiosResponse<UserType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.GET_COMPANY_USERS.replace(':companyId', companyId)],
    () => UserAPI.getPeople(companyId),
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      enabled: !!companyId,
      refetchOnMount: 'always',
    },
  );

  const { data: searchedData, ...searchInfo } = useQuery<AxiosResponse<UserType[]>, Error>(
    [JAVA_SERVICE_ENDPOINTS.SEARCH, companyId, 'all', searchKeyword],
    () => UserAPI.searchPeople(companyId, searchKeyword),
    {
      staleTime: 60 * 1000,
      enabled: !!companyId && !!searchKeyword,
    },
  );

  const people = useMemo(() => {
    if (!!searchKeyword) {
      return searchedData?.data || [];
    }
    return peopleData?.data || [];
  }, [peopleData, searchedData, searchKeyword]);

  return {
    people,
    isLoading,
    searchInfo,
    refetch,
  };
};

export const invalidatePeopleQueries = (companyId) => {
  queryClient.refetchQueries(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY_USERS.replace(':companyId', companyId),
  );
};

export const useUsersQuery = () => {
  const { data: users, isLoading } = useQuery(
    JAVA_SERVICE_ENDPOINTS.GET_USERS,
    () => UserAPI.getUsers(),
    {
      refetchOnMount: 'always',
      staleTime: 60 * 1000,
    },
  );
  const usersData = useMemo(() => users?.data || [], [users]);

  return {
    users: usersData,
    isLoading,
  };
};

export const updateUsersQueryCache = (
  companyId: string,
  user: UserType,
  action: 'add' | 'update',
) => {
  // update Company Admin users screen
  queryClient.setQueriesData<AxiosResponse<UserType[]> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_COMPANY_USERS.replace(':companyId', companyId),
    (old: AxiosResponse<UserType[]> | undefined) => {
      if (old) {
        const users = [...old.data];
        if (action === 'add') {
          users.unshift(user);
        }

        if (action === 'update') {
          const index = users.findIndex((userData) => userData.id === user.id);
          if (index > -1) {
            users[index] = { ...users[index], ...user };
          }
        }
        return {
          ...old,
          data: users,
        };
      }

      return old;
    },
  );

  // update BuildWise Admin users screen
  queryClient.setQueriesData<AxiosResponse<UserType[]> | undefined>(
    JAVA_SERVICE_ENDPOINTS.GET_USERS,
    (old: AxiosResponse<UserType[]> | undefined) => {
      if (old) {
        const users = [...old.data];
        if (action === 'add') {
          users.unshift(user);
        }

        if (action === 'update') {
          const index = users.findIndex((userData) => userData.id === user.id);
          if (index > -1) {
            users[index] = { ...users[index], ...user };
          }
        }
        return {
          ...old,
          data: users,
        };
      }

      return old;
    },
  );
};

export default usePeopleQuery;
