import { PYTHON_SERVICE_ENDPOINTS, PythonAPI } from '@buildwise/libs/services';
import { AttachmentType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';

export type ArticleAttachmentSummary = {
  documentData: AttachmentType & {
    pageInfo: {
      pageNo: string;
      pageContent: string[];
    }[];
  };
  llmResponse: string;
  gptPromptId: { id: string; regenerateFlag: string; bookMarked: boolean };
};

export type GenerateArticleSummaryPayloadType = {
  companyId: string;
  summary_text: string;
  web_url: string;
};

class PythonKnowledgeAPI extends PythonAPI {
  public articleAttachmentSummary(payload: {
    articleId: string;
    attachmentFileName: string;
    companyId: string;
    exactMatch?: boolean;
    novaSearchKeyword: string;
    prompt?: string;
    semanticSearch?: boolean;
    pageContent?: boolean;
  }) {
    return this.post<ArticleAttachmentSummary>(
      PYTHON_SERVICE_ENDPOINTS.ARTICLE_ATTACHMENT_SUMMARY,
      {
        novaSearchType: 'articleSummarization',
        ...payload,
      },
    );
  }
}

const pythonKnowledgeAPI = new PythonKnowledgeAPI();
export default pythonKnowledgeAPI;
