import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  BadgeProps,
  Box,
  useMediaQuery,
  Tooltip,
  Typography,
} from '@mui/material';
import { CSSProperties, MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { $LineBreak } from './MainDrawer';

export type MenuItemType = {
  badgeColor?: BadgeProps['color'];
  badgeContent?: number;
  children?: MenuItemType[];
  defaultExpanded?: boolean;
  exact?: boolean;
  icon?: ReactNode;
  iconActive?: ReactNode;
  isDiabled?: boolean;
  isHidden?: boolean;
  matchingUrls?: string[];
  shouldNavigateWhenParent?: boolean;
  showSelected?: boolean;
  target?: string;
  title: string;
  subTitle?: string;
  titleColor?: CSSProperties['color'];
  url?: string;
  onClick?: (event?: MouseEvent) => void;
} & Record<string, any>;

type MenuProps = {
  menu: MenuItemType[];
};

type MenuItemProps = {
  item: MenuItemType;
  isStickyItem?: boolean;
};

const MenuItem = ({ item, isStickyItem }: MenuItemProps) => {
  const {
    badgeContent = 0,
    children,
    defaultExpanded = true,
    icon,
    iconActive,
    matchingUrls,
    shouldNavigateWhenParent = false,
    showSelected = true,
    target = '',
    title,
    titleColor = '',
    url,
    onClick,
  } = item;
  const theme = useTheme();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { pathname, state, hash } = useLocation();
  const pathArray = useMemo(() => children?.map((m) => m.url), [children]);
  const isOpen = useMemo(() => pathArray?.includes(pathname), [pathArray, pathname]);
  const [open, setOpen] = useState(defaultExpanded);
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (pathname === url) {
      setOpen(pathname === url);
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      if (itemRef.current && itemRef.current.classList.contains('sidebar-active-item')) {
        itemRef.current.scrollIntoView();
      }
    }, 500);
  }, [itemRef]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleBadgeMargin = () => {
    if (badgeContent >= 100000) return '2.25rem';
    else if (badgeContent >= 10000) return '2rem';
    else if (badgeContent >= 1000) return '1.5rem';
    return '1.25rem';
  };

  return (
    <>
      <ListItemButton
        ref={itemRef}
        {...((children?.length && !shouldNavigateWhenParent) ||
        (pathname === url && hash && children?.length)
          ? {
              className: `multi ${url === `${pathname}${hash}` ? 'sidebar-active-item' : ''}`,
              selected: isOpen,
              onClick: handleClick,
            }
          : {
              className: `${url === `${pathname}${hash}` ? 'sidebar-active-item' : ''}`,
              ...(url
                ? {
                    component: Link,
                    to: url,
                    target,
                    onClick: () => {
                      if (pathname === url) {
                        window.scrollTo({
                          top: 0,
                        });
                      }
                    },
                  }
                : { onClick }),
              selected:
                (!shouldNavigateWhenParent || (shouldNavigateWhenParent && !Boolean(hash))) &&
                showSelected &&
                Boolean(url) &&
                (state?.activeSidebarTab
                  ? state?.activeSidebarTab === title
                  : matchingUrls?.length
                    ? !!matchingUrls?.find(
                        (item) =>
                          pathname.startsWith(item) ||
                          item === pathname ||
                          item === `${pathname}${hash}`,
                      )
                    : pathname.startsWith(url || '') ||
                      pathname === url ||
                      url === `${pathname}${hash}`),
            })}
        sx={
          pathname.startsWith('/company-admin') || pathname.startsWith('/buildwise-admin')
            ? {}
            : {
                [theme.breakpoints.down('lg')]: {
                  borderRadius: '50%',
                  py: 1.5,
                  width: 50,
                  height: 50,
                },
              }
        }
      >
        <Tooltip
          placement="right"
          disableHoverListener={
            isLg || pathname.startsWith('/company-admin') || pathname.startsWith('/buildwise-admin')
          }
          componentsProps={{
            tooltip: { sx: { maxWidth: 'fit-content', px: 2 } },
          }}
          title={<Typography>{title}</Typography>}
        >
          <ListItemIcon>{pathname === url && iconActive ? iconActive : icon}</ListItemIcon>
        </Tooltip>
        {(isMd ||
          pathname.startsWith('/company-admin') ||
          pathname.startsWith('/buildwise-admin')) && (
          <ListItemText
            className={children?.length ? 'header' : ''}
            sx={{
              '& .MuiListItemText-primary': {
                fontWeight: isStickyItem ? 'bold' : 'normal',
                color: titleColor,
                padding: '2px 0',
              },
              '& .MuiBadge-root': {
                marginLeft: handleBadgeMargin(),
              },
            }}
          >
            {title} {badgeContent ? `(${badgeContent})` : ''}
          </ListItemText>
        )}
        {!!children?.length && (open ? <ArrowUpSLineIcon /> : <ArrowDownSLineIcon />)}
      </ListItemButton>
      {!!children?.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className="children">
            {children?.map(
              (child, index) => !child.isHidden && <MenuItem key={index} item={child} />,
            )}
          </List>
        </Collapse>
      )}
    </>
  );
};

const RenderMenu = ({ menu }: MenuProps) => {
  return (
    <List className="sidebar-menu">
      {menu.map((item) => !item.isHidden && <MenuItem key={item.title} item={item} />)}
    </List>
  );
};

export const RenderStickyMenu = ({ menu }: MenuProps) => {
  const shouldRenderMenu = useMemo(() => menu.find((item) => !item.isHidden), [menu]);

  if (shouldRenderMenu) {
    return (
      <$Container>
        <List sx={{ pb: 1 }} className="sidebar-menu">
          <$LineBreak />
          {menu.map(
            (item) => !item.isHidden && <MenuItem key={item.title} item={item} isStickyItem />,
          )}
        </List>
      </$Container>
    );
  }

  return null;
};

export const $Container = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: '0',
  zIndex: 1,
  backgroundColor: theme.palette.custom.background,
}));

export default RenderMenu;
