import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import { getPageScrollInfo, handlePageScrollPosition } from 'utility/helpers';
import {
  useArticlesAndPlaylistsPaginationQuery,
  ArticleType,
} from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { FeedFilter } from 'api/KnowledgeAPI';

function useAllPosts({ filters }: { filters: FeedFilter[] }) {
  const [enableRefresh, setEnableRefresh] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const formattedFilters = useMemo(
    () =>
      filters.map((filter) => ({
        ...filter,
        filterValues: !Array.isArray(filter.filterValues)
          ? [filter.filterValues]
          : filter.filterValues,
      })),
    [filters],
  );
  const {
    articles = [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
    fetchNextPage,
    refetchQuery,
    resetFeedQueries,
    isIdle,
  } = useArticlesAndPlaylistsPaginationQuery({
    pageNo: 0,
    pageSize: 10,
    feedType: 'posts',
    filters: formattedFilters,
  });

  useEffect(() => {
    const homeTabsEle = document.querySelector('#home-page-tabs');
    const isHomeTabsStickyPositioned =
      homeTabsEle?.getBoundingClientRect().top && homeTabsEle?.getBoundingClientRect().top === 79;

    const scrollToIndex = getPageScrollInfo<ArticleType>('all-posts', articles, 'id');

    if (containerRef.current) {
      containerRef.current.style.visibility = 'hidden';
    }

    if (isHomeTabsStickyPositioned || scrollToIndex > 2) {
      if (containerRef.current && isLoading) {
        window.scrollTo({
          top:
            containerRef.current.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            175,
        });
      }

      setTimeout(() => {
        virtuosoRef.current?.scrollToIndex({
          index: scrollToIndex,
          offset: -185,
        });

        if (containerRef.current) {
          containerRef.current.style.visibility = 'visible';
        }
      }, 300);
    } else {
      if (containerRef.current) {
        containerRef.current.style.visibility = 'visible';
      }
    }
  }, [containerRef, virtuosoRef]);

  const fetchPosts = useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]);

  const resetFeed = (options: { resetQueries?: boolean; smoothScrollBehavior?: boolean } = {}) => {
    const { resetQueries = false, smoothScrollBehavior = false } = options;

    if (resetQueries) {
      resetFeedQueries();
    }

    handlePageScrollPosition('all-posts', 0, 0, 'reset');
    window.scrollTo({ top: 0, behavior: smoothScrollBehavior ? 'smooth' : 'auto' });
  };

  return {
    articles,
    containerRef,
    enableRefresh,
    isFetching,
    isFetchingNextPage,
    isIdle,
    isLoading,
    isRefetching,
    virtuosoRef,
    fetchPosts,
    resetFeed,
    refetchQuery,
    setEnableRefresh,
  };
}

export default useAllPosts;
