import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const $Wrapper = styled(Box, { label: 'AuthWrapper' })(({ theme }) => ({
  background: '#F7F7FC',
  width: '100%',
  padding: 24,
  '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
    margin: '8px 0',
  },
}));

const TermsConditions = () => {
  return (
    <$Wrapper>
      <Typography variant="h3">
        <strong>BuildWise Terms of Service</strong>
      </Typography>
      <p>Posted: August 10, 2022</p>
      <p>
        Welcome to BuildWise! BuildWise is a knowledge-sharing platform made exclusively for the
        construction industry. It is designed to make knowledge sharing a delightful and rewarding
        experience so construction professionals within an organization can capture and share
        information across projects and teams that can prevent repeat mistakes and rework on
        construction projects.
      </p>
      <p>{`These terms of service (“Terms”) govern your (in which "your" and “you” will refer to you, your company or other legal entity) access to and use of certain Software-as-a-Service (i.e., SaaS) solutions  (each a “Service”) of BuildWise Inc. (“BuildWise”, “we”, “our”, or “us”).  The particular Service which we will make available to you hereunder will be that which is specified in an online billing page or other ordering document  which is submitted by you, accepted by us, and which references these Terms (in each case, an “Order”). Each Order is subject to, and governed by, these Terms. These Terms also apply if you have signed up to use our Service during a trial period.  By using our Service, clicking a box indicating your acceptance of these Terms, or entering into an Order which references these Terms, you are agreeing to be bound by these Terms and acknowledge that you have reviewed and agree to BuildWise's Privacy Policy.  If you are entering into these Terms on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these Terms (in which case “you” refers to such company or entity).  If you do not agree to be bound by these Terms and our Privacy Policy, then you must not use the Service.  You may not enter into these Terms, or access the Service, for competitive purposes.   Should you have any questions surrounding these Terms, please contact support@BuildWise.com.`}</p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        1. Registration
      </Typography>
      <p>
        With exception to accessing the public areas of BuildWise’s website, when using our Service,
        you will be required to have your users register with their e-mail addresses. Your{' '}
        <span className="span-text">“users”</span> are those employees and contractors who you
        permit to use the Service on your behalf. If authenticating to the Service using a third
        party account, you and your users must adhere to the authentication and rights of use set
        forth by such third party service. You represent and warrant that your users have the right
        and authority to access the Service via their e-mail addresses. Please refer to your
        organization’s policies and practices, and those of your e-mail provider, for questions
        regarding their access rights and data handling practices. BuildWise cannot control, and is
        not responsible for, the practices or restrictions imposed by your organization and/or
        e-mail provider surrounding the use of the Service.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        2. Content Within BuildWise
      </Typography>
      <p>
        In accordance with their applicable roles and associated user permissions, your users may
        submit or view electronic data and information, including posts and files, to or in our
        Service (collectively, your <span className="span-text">“Content”</span>). This Content may
        then be shared by your users, through the use of our Service, with others.
      </p>
      <p>
        You agree and warrant that you, and your users, will only upload, copy, view, modify, share
        or otherwise use Content on or through the Service that you, and they, have the right and
        authority to use. You are responsible for securing any necessary rights from others in order
        for your users to use any Content belonging to them. You recognize and agree that, once your
        users upload or share Content through the use of our Service, such Content may be
        re-distributed to others who are permitted or enabled by you or your users to use the
        Service. Any of the individuals in this distribution stream may then view, re-distribute,
        copy, modify, delete, share and/or otherwise use (collectively,{' '}
        <span className="span-text">“Use”</span> ) the Content and, since all such Uses are outside
        of the control of BuildWise, BuildWise is not responsible or liable in connection with any
        such Use (including any misuse) of your Content.
      </p>
      <p>
        We are not responsible or liable for the Content you post or share via the Service. While we
        may access and/or review the Content and your use of the Service in order to support the
        operation of the Service, to determine compliance with these Terms, to comply with law, and
        for other purposes that we describe in these Terms and our Privacy Policy, we have no
        obligation to do so.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        3. Your Responsibilities
      </Typography>
      <p>
        You are responsible for: (a) ensuring the quality and accuracy of the Content; (b) ensuring
        that the Content, and the conduct of you and your users in connection with the use of the
        Service, complies with these Terms; (c) ensuring that you and your users do not upload,
        copy, re-distribute, modify, download, use or share Content unless you, and they, have the
        right to do so; (d) promptly handling and resolving any notices and claims relating to the
        Content, including any notices sent to you by any person claiming that any Content violates
        any person’s rights; and (d) maintaining appropriate security by protecting your account
        information (including your password and other login information) for the Service. You shall
        notify us immediately of any unauthorized use or loss of any of your account information or
        any other breach of security with respect to your use of the Service.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        4. Our Acceptable Use Policy
      </Typography>
      <p>
        In connection with your use of our Service, you and your users are prohibited from (1)
        accessing, tampering with, reverse engineering, disassembling or decompiling all or any part
        of the Service, or using non-public areas or parts of the Service (including source and
        object code), or shared areas of the Service for which you have not been granted express
        rights of use, or attempting to use or gain unauthorized access to our or to any
        third-party’s networks or equipment; (2) permitting other individuals or entities to copy
        all or any portion of the Service; or leasing, selling, distributing, sublicensing or
        reselling the Service, or using the Service in an outsourcing or service bureau arrangement
        or otherwise for the benefit of a third party; (3) providing unauthorized access to or use
        of any user IDs, login keys or passwords or other account information that we may provide
        you to enable you to activate or access the Service; in this regard, you are expressly
        prohibited from using shared e-mail addresses or group e-mail addresses; (4) attempting to
        probe, scan or test the vulnerability of the Service or any system or network of BuildWise
        or any of our customers or suppliers; (5) interfering or attempting to interfere with any
        service which we provide to any user, host or network; (6) engaging in fraudulent or
        offensive activity, any activity which violates any applicable law or regulation or any
        activity which poses a security or intellectual property risk to the Service or our systems;
        (7) uploading, copying, sharing or utilizing any Content, or engaging in any activity, that
        is pornographic, obscene, harassing, abusive, slanderous or defamatory or that encourages,
        promotes, foments or expresses racism, hatred, bigotry, violence or sedition; (8) uploading,
        copying, sharing or utilizing any Content, or engaging in any activity, that infringes the
        intellectual property rights or privacy rights of any individual or third-party; (9)
        transmitting unsolicited bulk or commercial messages; (10) distributing worms, Trojan
        horses, viruses, corrupted files or any similar items; (11) restricting, inhibiting,
        interfering with or otherwise disrupting or causing a performance degradation to any
        BuildWise (or BuildWise supplier) facilities or systems used to deliver the Service; or (12)
        modifying or create derivative works of the Service. BuildWise may in its sole discretion
        determine whether your use of the Service is a violation of this Acceptable Use Policy and,
        if so, we may suspend or terminate your ability to use the Service. You are responsible for
        ensuring that your users comply with our Acceptable Use Policy and the other terms and
        conditions of these Terms.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        5. Our Responsibilities
      </Typography>
      <p>
        BuildWise is responsible for providing the Service in accordance with these Terms. We will
        implement commercially reasonable measures which are designed to safeguard and protect the
        security, confidentiality and integrity of information that you provide to or through the
        Service (including your Content).
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        6. Marketing and Publicity
      </Typography>
      <p>
        Upon your prior written consent, which consent shall not be unreasonably withheld, BuildWise
        shall have the right to use your name and Service-related statements for marketing or
        promotional purposes on BuildWise’s website and/or in other communications with existing or
        potential BuildWise customers.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        7. Subscription Term
      </Typography>
      <p>
        Your right to use the Service is provided on a subscription basis for an initial term ( the{' '}
        <span className="span-text">“Initial Term”</span>) which is specified in the applicable
        Order. Thereafter, unless otherwise specified in an applicable Order, each Order will renew
        for successive renewal subscription periods equivalent in length to the then-expiring term
        (each a <span className="span-text">“Renewal Term”</span>) unless either party provides
        written notice of its intention to not renew the Order at least fifteen (15) days prior to
        the end of the then-current term (i.e., either the Initial Term or Renewal Term, as
        applicable). Non-renewal notices to BuildWise shall be sent to hello@BuildWise.com or in
        such other manner as is authorized under the “Notice” provision below. We can send notices
        of non-renewal to you at the e-mail address provided to us by a system administrator upon
        registration and such notification shall be deemed effective when sent. The Initial Term,
        and all Renewal Terms, shall constitute the <span className="span-text">“Term”</span> of the
        Order. The Term of an Order may be earlier terminated or suspended in accordance with the
        provisions of these Terms.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        8. Fees
      </Typography>
      <p>
        The subscription fees due to BuildWise for your Initial Term and each Renewal Term will be
        calculated based on our then-current pricing terms, unless otherwise agreed to by the
        parties in the applicable Order or otherwise in a writing which references these Terms. You
        will be required to provide your contact information and select a method of payment (for
        example, by credit card) to process such recurring payments and any applicable taxes. Once
        the in advance payment is processed by BuildWise, the purchased features for the specified
        number of users will be enabled within your BuildWise Service account.
      </p>
      <p>
        If not otherwise specified, all fees shall be invoiced in advance and shall be due and
        payable within thirty (30) days of the submission of the invoice. Invoiced amounts not paid
        by their due date shall be subject to a one percent (1%) per month interest fee, or the
        maximum amount permitted by applicable law, whichever is less. Prices do not include taxes,
        and You shall promptly pay, all applicable sales, use and other transactional taxes in
        connection with the Service, any other services or support provided, or any payments made
        under these Terms. All fees are committed amounts which are non-cancelable and
        non-refundable.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        9. Intellectual Property; Feedback
      </Typography>
      <p>
        All right, title, and interest in and to the Service (excluding Content provided by you,
        which is owned by you), and to all software, technology, documentation, methods, processes
        and work product provided, used or displayed in connection with the delivery or support of
        the Service or related professional services, as well as all modifications, enhancements and
        derivatives thereof, are and will remain the exclusive property of BuildWise and/or its
        licensors. The Service is protected by copyright, trademark, and other laws of both the
        United States and foreign countries. Nothing in the Terms gives you the right to use
        BuildWise’s trademarks, logos, domain names, and other distinctive brand features. Any
        rights not expressly granted to you are reserved by BuildWise.
      </p>
      <p>
        During the Term and subject to the terms and restrictions set forth herein, we grant you a
        non-exclusive, non-transferable, non-sublicensable, time-bounded, right to access and use
        the Service on a remote-access, subscription basis via the Internet and solely in support of
        your internal business operations. Due to the nature of the SaaS delivery model, the Service
        to which you are provided remote access hereunder shall be the version of such Service which
        is then generally hosted by BuildWise for its clients. You may only grant access to the
        Service to users who: (a) use the Service solely in support of your internal business
        operations; and (b) are bound by the confidentiality, limited use and other provisions set
        forth in these Terms (including their roles and associated user permissions and limitations)
        which are protective of the rights and interests of BuildWise. In addition, you shall be
        responsible and liable to ensure that each such user complies with these Terms.
      </p>
      <p>
        You grant BuildWise, and its affiliates, employees, agents and contractors, a worldwide,
        royalty-free right to use your Content: (i) to provide the Service to you; and (ii) as
        otherwise permitted under these Terms. Such rights of use include the right to host, backup,
        store, display, process, reproduce, analyze, transmit and otherwise make your Content
        available to you and others for the purposes for which the Service is designed. We will not
        sell your Content to a third party, nor will we share your Content with any third party
        except in support or facilitation of the provision of the Service or as otherwise permitted
        under these Terms. We may generate and collect analytic data and metrics regarding your use
        (and the use by your users) of the Service and your Content (
        <span className="span-text">“Analytic Data”</span>) and use such Analytic Data in order to:
        (I) support, improve, develop and optimize the Service, including to develop new features;
        (II) inform internal business processes and strategies, and (III) create guidance and
        reports regarding features and usage of our Service for distribution to our customers and
        prospects; such Analytic Data will be de-identified and aggregated. Subject to the above
        conditions, the Analytic Data is owned by us.
      </p>
      <p>
        You are responsible for obtaining all necessary rights and consents for BuildWise to use,
        process and store all Content for the purposes of providing the Service and meeting our
        obligations under these Terms. You are responsible for securing any necessary rights from
        others in order to Use any Content belonging to them.
      </p>
      <p>
        In the event that you submit any ideas, comments, suggestions, proposed modifications or
        enhancements, or other feedback relating to the Service (collectively,{' '}
        <span className="span-text">“Feedback”</span>), we shall automatically own such Feedback
        without compensation to you and you hereby assign all rights in such Feedback to BuildWise.
        For clarity, BuildWise may use your Feedback for any purpose, and shall own any and all work
        product or developments created based thereon or related thereto.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        10. Confidentiality
      </Typography>
      <p>
        <span className="span-text">“Confidential Information” </span>is non-public information of a
        party which is provided to the other party hereunder and which is either designated as
        confidential or of a type which should be recognized by a commercially reasonable party as
        confidential. Confidential Information of BuildWise includes the Service and its related
        documentation and materials, along with the terms and conditions of these Terms. The party
        receiving Confidential Information: (a) may use it only for purposes consistent with these
        Terms; and (b) may not disclose it to any third party unless such third party is accessing
        and using it for purposes consistent with these Terms and is bound in writing by
        confidentiality and limited use restrictions at least as protective in all material respect
        of the disclosing party as those required hereunder. The obligations to maintain the
        Confidential Information as confidential shall remain in place for so long as the applicable
        information is maintained as confidential by the owning party and shall survive termination
        or expiration of these Terms. The limitations on disclosure or use of Confidential
        information shall not apply to information which (i) is rightfully obtained by the recipient
        without breach of any confidentiality obligation; (ii) is or becomes known to the public
        through no act or omission of the recipient; (iii) the recipient develops independently
        without using Confidential Information of the other party; or (iv) is disclosed in response
        to a valid court or governmental order or as otherwise required by law, if the recipient has
        given the other party prior written notice (unless prohibited by law or governmental
        authority) and provides reasonable assistance.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        11. Changes to Terms
      </Typography>
      <p>
        BuildWise may revise these Terms from time to time and at our sole discretion. When such
        changes are effected, BuildWise will publish an updated version on our website:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_PUBLIC_URL}/terms-conditions`}
        >
          https://www.BuildWise.ai/terms-of-service
        </a>
        . The updated Terms will be deemed effective with your continued use of the Service. You
        have rights to dispute an updated term which materially alters your rights or obligations
        (with exception to changes required by law) within ten (10) business days from the date of
        the new Terms being published, upon written notification to: support@BuildWise.ai. Please
        note: Your rights and access to the Service may be temporarily disrupted until such dispute
        is resolved between you and BuildWise. If we are unable to resolve the dispute within thirty
        (30) days of your written notification of dispute, the Service will be terminated.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        12. Copyright Complaints
      </Typography>
      <p>
        BuildWise respects the intellectual property of others. It is our policy to respond to
        notices of alleged copyright infringement that comply with the Digital Millennium Copyright
        Act (<span className="span-text">“DMCA”</span>). If you believe that your work has been
        copied in a way that constitutes copyright infringement, please contact us at:
        support@BuildWise.ai. We reserve the right to delete or disable content alleged to be
        infringing and terminate accounts of repeat infringers.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        13. Cancellation and Termination
      </Typography>
      <p>These Terms remain effective until they are canceled or terminated as set forth below:</p>
      <p>
        <span className="span-text"> a)</span> If your Order expires in accordance with Section 7
        (“Subscription Term”) provision above;
      </p>
      <p>
        <span className="span-text"> b)</span> if the Service terminates as a result of a material
        change to these Terms as described in Section 11 (“Changes to Terms”) provision above;
      </p>
      <p>
        <span className="span-text"> c)</span>
        If either of us materially breaches the obligations to the other party under these Terms and
        fails to reasonably cure such breach within thirty (30) days of a party’s written
        notification to such other party of such breach. Termination under this provision shall be
        by written notice after the passage of such 30-day cure period without a cure having been
        effectuated.
      </p>
      <p>
        <span className="span-text"> d)</span> Notwithstanding anything to the contrary above,
        BuildWise may terminate these Terms and/or suspend your use of the Service at any time if:
      </p>
      <p>
        <span className="span-text"> 1)</span> you have misused the Service; or
      </p>
      <p>
        <span className="span-text"> 2)</span> you fail to pay the applicable subscription fees and
        any applicable taxes in full and in a timely manner and fail to pay us for a period of five
        (5) business days after our written notice to you of the delinquency; or
      </p>
      <p>
        <span className="span-text"> 3)</span> BuildWise is required to do so by law
      </p>
      <p>
        Termination of these Terms will terminate all Orders to which these Terms apply. Upon any
        termination or cancellation hereof, BuildWise shall then disable your access to the Service,
        and your rights of access and use of the Service shall cease. You must promptly (at
        BuildWise’s election) return or destroy all proprietary items and materials of BuildWise
        which are then in your possession or control. BuildWise will delete your Content from the
        Service promptly after ninety (90) days has elapsed from the date of expiration or
        termination. As promptly as reasonably practicable after your written request which you
        provide to us reasonably in advance of this 90-day cutoff, we will provide an export of your
        Content in an industry standard format. BuildWise cannot recover your data once deleted
        after the 90-day period. If you terminate for our uncured material breach pursuant to
        Section 13(c), we will refund any prepaid fees covering the unused remainder of the
        then-current subscription term. If we terminate pursuant to Section 13(c) or 13(d), you will
        pay any unpaid fees covering the remainder of the then-current subscription term.
      </p>
      <p>
        The provisions of these Terms which relate to confidentiality, intellectual property
        ownership, indemnity, limitations and disclaimers of liability and payment obligations,
        along with terms which expressly or by their nature should reasonably survive termination,
        shall survive expiration or termination hereof.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        14. Warranty; Disclaimer of Warranty
      </Typography>
      <p>
        We warrant, for the Term of the applicable Order, and conditioned on your use of the Service
        in compliance with these Terms, that the Service will perform materially in accordance with
        our applicable published documentation that we make available to you along with the Service.
        In the event that we breach this warranty and are unable to remedy the breach within a
        reasonable period of time after being made aware thereof, your exclusive remedies are to:
        (a) pursue termination of these Terms for material breach pursuant to the applicable
        provisions of the “Cancellation and Termination” section above; and (b) seek recovery of
        damages caused by the breach, subject to the “Limitation of Liability” section below.
      </p>
      <p>
        EXCEPT AS PROVIDED ABOVE, THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND
        WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        WITHOUT LIMITING THE FOREGOING, BuildWise AND ITS LICENSORS DO NOT WARRANT THAT THE SERVICE
        WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR
        LOCATION, OR WILL BE UNINTERRUPTED.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        15. Limitation of Liability
      </Typography>
      <p>
        IN NO EVENT SHALL BuildWise, ITS AFFILIATES, DIRECTORS, EMPLOYEES, SUBCONTRACTORS OR
        SUPPLIERS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
        EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS OR REVENUES,
        LOSS OF DATA OR CONTENT, DAMAGE TO GOODWILL, OR OTHER SIMILAR LOSSES, THAT RESULT FROM THE
        USE OF, OR INABILITY TO USE, THIS SERVICE. THE AGGREGATE AND CUMULATIVE LIABILITY OF
        BuildWise, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUBCONTRACTORS AND SUPPLIERS SHALL
        NOT EXCEED THE FEES WHICH YOU PAID TO BuildWise FOR THE SERVICE DURING THE TWELVE (12) MONTH
        PERIOD PRECEDING THE DATE OF THE INITIAL CLAIM BROUGHT BY YOU AGAINST BuildWise HEREUNDER.
        MULTIPLE CLAIMS SHALL NOT INCREASE THIS CAP. IF YOU RECEIVE THE SERVICE FOR NO FEE (FOR
        EXAMPLE, IF YOU ARE EVALUATING THE SERVICE FOR A LIMITED TRIAL AT NO FEE), THEN YOU
        ACKNOWLEDGE AND AGREE THAT, AS A RESULT, YOU SHALL HAVE NO RIGHT TO RECOVERY OF FEES IN
        CONNECTION WITH THE SERVICE.
      </p>
      <p>
        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF BuildWise HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGE AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL
        PURPOSE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL
        NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        16. Export Control; Compliance with Laws
      </Typography>
      <p>
        You are entirely responsible for ensuring that the Content, and your conduct in connection
        with your use of the Service, complies with all applicable laws and regulations, including
        but not limited to export and import regulations. You are prohibited from using the Service
        or exporting any Content or technology to any jurisdiction prohibited by the United States
        export control laws. You may not use the Service if you are a resident of a country
        embargoed by the United States, or are a foreign person or entity blocked or denied by the
        United States government, or if such use is otherwise in violation of U.S. or other
        applicable law.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        17. Indemnification
      </Typography>
      <p>
        We will defend you and your employees and affiliates (
        <span className="span-text">“Client Indemnitees”</span>) against any claim, demand, suit or
        proceeding which alleges that the use of the Service infringes a third party’s United States
        intellectual property rights, and we shall indemnify Client Indemnitees from and against any
        damages, losses, and costs finally awarded against them as a direct result of such third
        party claim. In addition, we will incur legal fees and associated legal costs which are
        necessary to defend such third party claim and, to the extent that we settle the claim prior
        to an award, we will pay such settlement amounts. As a condition of our indemnity
        obligation, you must promptly notify us of any such claim; grant us sole control of the
        defense and settlement of any such claim (which shall not include a specific or financial
        performance obligation on, or admission of liability by, you); and cooperate with us to
        facilitate our ability to settle or defend the claim. In the event of a covered infringement
        claim, we will exert commercially reasonable efforts to obtain for you the right to continue
        using the Service or to replace or modify the Service so that it is not infringing and
        materially similar. If these alternatives are not commercially reasonable, we may terminate
        the Service and, if you have prepaid for any unused Service covering periods following
        termination, refund that prepayment. We shall have no indemnity obligations to you if the
        infringement claim is caused by your modification of the Service, your use of the Service in
        breach of these Terms, or your use of the Service in combination with unauthorized products
        or services. THESE ARE OUR EXCLUSIVE OBLIGATIONS WITH RESPECT TO INFRINGEMENT OF
        INTELLECTUAL PROPERTY RIGHTS.
      </p>
      <p>
        You agree to defend BuildWise, and its affiliates, employees, subcontractors and suppliers
        (the<span className="span-text">“BuildWise Indemnitees”</span> ), against any claim, demand,
        suit or proceeding brought by third parties, regarding; (a) any data or Content which is
        transmitted, uploaded, deleted, modified, distributed, redistributed, or copied by you or
        any of your users (including a claim of violation of a third party’s proprietary or privacy
        rights with respect thereto); or (b) your violation, or a violation by you or any of your
        users of these Terms, applicable law, rule or regulation, and you shall indemnify BuildWise
        Indemnitees against all associated damages, losses, and costs finally awarded against a
        BuildWise Indemnitee. In addition, you shall incur legal fees which are necessary to defend
        such third party claim and, to the extent the claim is settled prior to an award, you shall
        pay such settlement amounts.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        18. Controlling Law
      </Typography>
      <p>
        These Terms will be governed by the State of Delaware law except for its conflicts of law
        principles. The State (and, to the extent jurisdiction exists) and Federal Courts located
        within Wilmington, Delaware shall have exclusive jurisdiction over any and all disputes
        arising out, or relating to, these Terms or the Service or any site or application through
        which the Service is delivered.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        19. Government
      </Typography>
      <p>
        The Service may not be used by or for any governmental agency or body absent the parties
        first entering into a separate written agreement in advance of any such use.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        20. Waiver, Severability, Third Party Beneficiaries & Assignment
      </Typography>
      <p>
        A party’s failure to enforce a provision is not a waiver of its right to do so at a later
        date. If a provision is found unenforceable, the remaining provisions of the Terms will
        remain in full effect and an enforceable term will be substituted reflecting our intent as
        closely as possible. There are no third party beneficiaries to these Terms, including your
        users. Except as provided below, neither party may assign these Terms or any rights or
        obligation hereunder, without the other party’s prior written consent to such assignment,
        which consent shall not be unreasonably withheld. However, either party may assign these
        Terms, without seeking or obtaining the other party’s consent, to: (a) an affiliate in the
        event of a corporate reorganization; or (b) in connection with a merger, acquisition, or
        consolidation or to a third party that is acquiring all or substantially all of its assets
        or a controlling interest in its stock; provided that you may not assign these Terms to a
        company that is reasonably viewed by us as a direct competitor of BuildWise.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        21. Notices
      </Typography>
      <p>
        All notices must be in writing and shall be sent by first class U.S. mail (return receipt),
        a nationally known express or overnight courier (such as FedEx, UPS or the U.S. Postal
        Service) or email. Notices to BuildWise shall be sent to its main office, for which the
        current address is 1494 Kimblewick Rd, Potomac MD 20854, or, if sent by email, to{' '}
        <a href="mailto:support@BuildWise.ai">support@BuildWise.ai </a>. Notices to you shall be
        sent to the address listed in the Order or in these Terms or, if sent by email, to the email
        address provided by a system administrator upon registration. Notices delivered by mail or
        courier shall be deemed received on the date shown on the mail carrier’s or courier’s
        confirmation of delivery. Notices delivered by email shall be deemed received the next
        business day after it was sent unless it was returned as undelivered to the sender.
        Notwithstanding the above, in lieu of the notice requirements above: (a) each party may
        notify the other party of non-renewal of a subscription in accordance with Section 7
        (“Subscription Term”); and (b) we may change these Terms as described in Section 11(“Changes
        to Terms”) above. Any notice of change in address shall also be given in the manner set
        forth above.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        22. Force Majeure
      </Typography>
      <p>
        Neither party will be liable to the other for any failure to perform any of its obligations
        under these Terms during any period in which performance is delayed by circumstances not
        within such party’s reasonable control, such as a natural disaster, act of war or terrorism,
        riots, fires acts or orders of government, labor disruption, internet or telecommunication
        outages or interruptions, hacking or similar incidents, or power outages.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        23. Third Party Links and Components
      </Typography>
      <p>
        Any third party components which are embedded as a part of the Service shall be considered a
        part of the “Service” for purposes of these Terms. However, our Service may facilitate
        links, access or integration to other products, services, technology or websites owned or
        operated by third parties (<span className="span-text">“Third Party Products”</span>). We
        have no responsibility for, or control over, these Third Party Products, all of which may
        have separate privacy and data collection practices, independent of BuildWise. As such, you
        access and use the Third Party Products at your own risk and we disclaim all liability
        related or connected to your access or use thereof.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        24. Independent Contractors; Subcontractors.
      </Typography>
      <p>
        The parties are independent contractors and are not legal partners or agents. We may provide
        or perform certain parts of the Service or our obligations to you hereunder through third
        party subcontractors and suppliers, including third party hosting providers. For clarity, we
        shall remain responsible for breaches of these Terms caused by any such third party.
      </p>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: -2 }}>
        25. Entire Agreement
      </Typography>
      <p>
        These Terms, together with any amendments and Orders, will constitute the entire agreement
        between you and BuildWise concerning the Service and supersedes and replaces any prior or
        contemporaneous understandings and agreements regarding the subject matter hereof.
      </p>
    </$Wrapper>
  );
};

export default TermsConditions;
