import * as React from 'react';
import { SxProps, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

const $Dialog = styled(Dialog, { label: 'ActionModal' })<{ minHeight?: string }>(
  ({ theme, minHeight }) => ({
    '& .MuiDialog-paper': {
      minHeight: minHeight ? minHeight : '500px',
      background: '#ffffff',
      borderRadius: 8,
      boxShadow: '0px 1px 0px #D9DBE9',
    },
    '&.standard-mode': {
      '& .MuiDialog-paper': {
        width: 620,
        maxWidth: 1280,
        transition: 'width 0.3s',
        '& .MuiDialogContent-root': {
          width: '100%',
        },
      },
    },
    '&.full-view': {
      '& .MuiDialog-paper': {
        transition: 'width 0.5s',
        width: '100%',
      },
    },
    '&.confirmation-modal': {
      '& .MuiDialog-paper': {
        minHeight: '250px',
        height: '250px',
        width: '450px',
      },
      '& .MuiDialogTitle-root.title': {
        fontSize: '20px',
      },
    },
  }),
);

const $DialogTitle = styled(MuiDialogTitle, { label: 'DialogTitle ' })(({ theme }) => ({
  margin: 0,
  padding: '0 24px',
  height: 72,
  lineHeight: '76px',
  color: '#000000',
  fontWeight: 700,
  fontSize: 32,
  '& .MuiIconButton-root': {
    top: 16,
    right: 12,
    color: theme.palette.custom.lightBlack,
  },
}));

const $DialogContent = styled(DialogContent, { label: 'DialogContent ' })<{
  width?: number;
  height?: string;
  maxHeight?: string;
}>(({ theme, width, height, maxHeight }) => ({
  borderTop: `1px solid ${theme.palette.custom.line}`,
  borderBottom: `1px solid ${theme.palette.custom.line}`,
  width: width || '100%',
  height: height || 'auto',
  maxHeight: maxHeight || 'auto',
}));

export type DialogTitleProps = {
  onClose: () => void;
} & React.PropsWithChildren;

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <$DialogTitle {...other} className="title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseLineIcon size={32} />
        </IconButton>
      ) : null}
    </$DialogTitle>
  );
};

type ModalPropTypes = {
  open: boolean;
  title: React.ReactNode;
  onClose?: () => void;
  width?: number;
  height?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  sticky?: React.ReactNode;
  className?: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  minHeight?: string;
  maxHeight?: string;
  contentSx?: SxProps;
};

const ActionModal: React.FC<ModalPropTypes> = ({
  open,
  title,
  onClose = () => {},
  width,
  footer,
  sticky,
  children,
  height,
  className,
  onKeyUp,
  minHeight,
  maxHeight,
  contentSx,
}) => {
  return (
    <$Dialog
      onClose={onClose}
      open={open}
      maxWidth={width ? false : undefined}
      className={className}
      onKeyUp={onKeyUp}
      minHeight={minHeight}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      {sticky}
      <$DialogContent width={width} height={height} maxHeight={maxHeight} dividers sx={contentSx}>
        {children}
      </$DialogContent>
      {footer && <DialogActions sx={{ padding: 2 }}>{footer}</DialogActions>}
    </$Dialog>
  );
};

export default ActionModal;
