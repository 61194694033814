import { useMemo, useState } from 'react';
import usePromptTemplatesQuery, { PromptTemplate } from './usePromptTemplatesQuery';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import KnowledgeAPI from 'api/KnowledgeAPI';

function usePromptTemplates() {
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    templates: templateData,
    updatePromptTemplatesCache,
    ...rest
  } = usePromptTemplatesQuery();
  const templates = useMemo(() => {
    if (searchKeyword) {
      return templateData.filter((template) =>
        template.promptName.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }

    return templateData;
  }, [templateData, searchKeyword]);

  const deletePromptTemplate = async (template: PromptTemplate) => {
    try {
      setDeletingTemplate(true);
      await KnowledgeAPI.deletePromptTemplate(template.id);
      toast.success('Template deleted successfully');
      updatePromptTemplatesCache({
        action: 'delete',
        template,
      });
    } catch (error) {
      toast.error(catchErrorMessage(error));
    } finally {
      setDeletingTemplate(false);
    }
  };

  return {
    deletingTemplate,
    openTemplateModal,
    searchKeyword,
    templates,
    ...rest,
    deletePromptTemplate,
    setOpenTemplateModal,
    setSearchKeyword,
  };
}

export default usePromptTemplates;
