import { useQuery } from 'react-query';
import { homeQueryKeys } from './query-keys';
import { HomeAPI } from '..';
import useUserData from 'hooks/useUserData';
import { ArticleType } from '@buildwise/pages/knowledge/hooks/useArticlesQuery';
import { ArticlesResponse } from 'api/KnowledgeAPI';
import { AxiosResponse } from 'axios';
import { queryClient } from 'config/queryClient';

function useEndorsedPostsQuery() {
  const { companyId } = useUserData();
  const { data: endorsedPostsData, ...rest } = useQuery({
    queryKey: homeQueryKeys.endorsedPosts(companyId),
    queryFn: () => HomeAPI.getEndorsedPosts(companyId),
    staleTime: 60 * 1000,
    refetchOnMount: 'always',
    enabled: !!companyId,
  });

  const updateCache = ({
    action = 'update',
    updatedArticle,
  }: {
    action?: 'update' | 'delete';
    updatedArticle: ArticleType;
  }) => {
    const queryStatus = queryClient.getQueryState(homeQueryKeys.endorsedPosts(companyId));

    if (queryStatus?.status && queryStatus.status !== 'idle') {
      queryClient.setQueryData<AxiosResponse<ArticlesResponse[], Error> | undefined>(
        homeQueryKeys.endorsedPosts(companyId),
        (old) => {
          if (old) {
            const updatedArticles = [...(old?.data?.[0]?.articles || [])];

            if (action === 'update') {
              const index = updatedArticles.findIndex(
                (articleData) => articleData.id === updatedArticle.id,
              );

              if (index > -1) {
                updatedArticles[index] = { ...updatedArticles[index], ...updatedArticle };
              } else {
                updatedArticles.unshift(updatedArticle);
              }
            }

            if (action === 'delete') {
              const index = updatedArticles.findIndex(
                (articleData) => articleData.id === updatedArticle.id,
              );

              updatedArticles.splice(index, 1);
            }

            return {
              ...old,
              data: [
                {
                  ...old.data[0],
                  articles: updatedArticles,
                },
              ],
            };
          }

          return old;
        },
      );
    }
  };

  return {
    endorsedPosts: endorsedPostsData?.data?.[0]?.articles || [],
    updateCache,
    ...rest,
  };
}

export default useEndorsedPostsQuery;
