import { Box, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { HOME } from 'constants/routes';
import { Link } from 'react-router-dom';
import QuestionMarkIcon from 'remixicon-react/QuestionMarkIcon';

function PageNotFound() {
  const theme = useTheme();

  return (
    <NoDataContainer>
      <QuestionMarkIcon
        size={120}
        color={theme.palette.custom.mikadoYellow}
        stroke={theme.palette.custom.mulledWine}
        strokeWidth={0.6}
      />
      <NoDataTitle>We’ve lost this page</NoDataTitle>
      <NoDataDescription>
        Sorry, the page you are looking for doesn’t exist or has been moved.
      </NoDataDescription>
      <Link to={HOME}>
        <Button variant="contained">Back to Home</Button>
      </Link>
    </NoDataContainer>
  );
}

const NoDataContainer = styled(Box, { label: 'NoDataContainer' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '80px 24px 0',
});

const NoDataTitle = styled(Box, { label: 'NoDataTitle' })(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.tuna,
  marginTop: 16,
}));

const NoDataDescription = styled(Box, { label: 'NoDataDescription' })(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.custom.mistBlue,
  marginTop: 8,
  marginBottom: 24,
}));

export default PageNotFound;
