import { FormControl, Autocomplete, TextField, Chip, TextFieldProps } from '@mui/material';
import { ConfigEntryType } from 'config/config';
import _ from 'lodash';
import { KeyboardEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { preventComma } from 'utility/helpers';

type MultiKeywordsFieldProps = {
  field: ConfigEntryType;
  TextFieldProps?: TextFieldProps;
};

function MultiKeywordsField({ field, TextFieldProps }: MultiKeywordsFieldProps) {
  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch,
  } = useFormContext<Record<string, any>>();
  const value = (watch(field.entryCode) || []) as string[];
  const [inputValue, setInputValue] = useState('');

  const handleSelect = (_: unknown, values: string[]) => {
    setValue(field.entryCode, values, { shouldDirty: true, shouldValidate: true });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const value = (event.target as HTMLInputElement)?.value?.trim() || '';

    if (event.key === 'Enter' || event.key === ',') {
      const values = (getValues()?.[field.entryCode] || []) as string[];
      const noDuplicate = !values?.find((label) => label.toLowerCase() === value?.toLowerCase());
      if (value && noDuplicate) {
        setValue(field.entryCode, [...(values || []), value], {
          shouldDirty: true,
          shouldValidate: true,
        });
        (event.target as HTMLInputElement).value = '';
        setInputValue('');
      }
    } else {
      setInputValue(value);
    }
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          size="small"
          freeSolo
          multiple
          limitTags={3}
          onChange={handleSelect}
          onInputChange={(_, _value) => setInputValue(_value)}
          onKeyUp={handleKeyDown}
          onKeyDown={preventComma}
          options={[]}
          inputValue={inputValue}
          value={value || []}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              {...TextFieldProps}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option}
                deleteIcon={<CloseLineIcon size={20} />}
              />
            ))
          }
          disabled={isSubmitting}
          classes={{
            // DevNote: Explicitly hide options container styles when there are no options available for freeSolo input. See https://github.com/mui/material-ui/issues/40843
            paper: 'hide-options-container',
          }}
        />
      </FormControl>
    </>
  );
}

export default MultiKeywordsField;
