import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  Chip,
  Switch,
  Tooltip,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useUserData from 'hooks/useUserData';
import { useConfigQuery } from 'hooks/useCompanyQueries';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { catchErrorMessage } from 'utility/helpers';
import CompanyAPI from 'api/CompanyAPI';
import { ConfigEntryType } from 'config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export const DEFAULT_COMPANY_FILTERS: Omit<ConfigEntryType, 'id'>[] = [
  {
    entryCode: 'MARKET_SECTORS',
    entryDescription: 'Market Sectors',
    entryValues: [],
    isActive: true,
  },
  {
    entryCode: 'DEPARTMENT_OR_REGIONS',
    entryDescription: 'Regions',
    entryValues: [],
    isActive: true,
  },
  {
    entryCode: 'TOPICS',
    entryDescription: 'Topics',
    entryValues: [],
    isActive: true,
  },
];

const CompanyConfigurations = () => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { companyId, isNovaAdmin, company } = useUserData();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [openTooltip, setOpenTooltip] = useState('');

  const [jobTitle, setJobTitle] = useState('');
  const {
    config: jobTitleConfig,
    entries: jobTitles,
    updateConfigCache,
  } = useConfigQuery(companyId, 'JOB_TITLES');

  const [selectedCompanyFilters, setSelectedCompanyFilters] = useState<
    Omit<ConfigEntryType, 'id'>[]
  >([]);
  const { config: companyFiltersConfig, entries: companyFilters } = useConfigQuery(
    companyId,
    'COMPANY_FILTERS',
  );
  const companyFiltersOptions = useMemo(
    () =>
      DEFAULT_COMPANY_FILTERS.filter(
        (filter) => !companyFilters.find((_filter) => _filter.entryCode === filter.entryCode),
      ),
    [companyFilters],
  );

  const [department, setDepartment] = useState('');
  const { config: departmentsConfig, entries: departments } = useConfigQuery(
    companyId,
    'DEPARTMENTS',
  );

  const [region, setRegion] = useState('');
  const { config: regionsConfig, entries: regions } = useConfigQuery(companyId, 'REGIONS');

  const [area, setArea] = useState('');
  const { config: areasConfig, entries: areas } = useConfigQuery(companyId, 'AREAS');

  const [workStream, setWorkStream] = useState('');
  const { config: workStreamsConfig, entries: workStreams } = useConfigQuery(
    companyId,
    'WORKSTREAMS',
  );

  const [topic, setTopic] = useState('');
  const { config: topicsConfig, entries: topics } = useConfigQuery(companyId, 'TOPICS');

  const [skill, setSkill] = useState('');
  const { config: skillsConfig, entries: skills } = useConfigQuery(
    companyId,
    'userProfileSuggestedExpertSkills',
  );

  const [marketSector, setMarketSector] = useState('');
  const { config: marketSectorsConfig, entries: marketSectors } = useConfigQuery(
    companyId,
    'MARKET_SECTORS',
  );

  const [partnerRole, setPartnerRole] = useState('');
  const { config: partnerRolesConfig, entries: partnerRoles } = useConfigQuery(
    companyId,
    'PARTNER_ROLES',
  );

  const [projectType, setProjectType] = useState('');
  const { config: projectTypesConfig, entries: projectTypes } = useConfigQuery(
    companyId,
    'PROJECT_TYPES',
  );

  const [scorecardCategory, setScorecardCategory] = useState('');
  const { config: scorecardCategoriesConfig, entries: scorecardCategories } = useConfigQuery(
    companyId,
    'SCORECARD_CATEGORIES',
  );

  const [projectPhase, setProjectPhase] = useState('');
  const { config: projectPhasesConfig, entries: projectPhases } = useConfigQuery(
    companyId,
    'PROJECT_PHASES',
  );

  const [masterFormat, setMasterFormat] = useState(company?.masterFormat || '50');

  useEffect(() => {
    if (containerRef.current && hash) {
      const elementRef = containerRef.current.querySelector(
        `#${decodeURIComponent(hash).replace('#', '')}`,
      );
      if (elementRef) {
        window.scrollTo({
          behavior: 'smooth',
          top:
            elementRef.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 90,
        });
      }
    }
  }, [
    hash,
    containerRef,
    jobTitleConfig,
    departmentsConfig,
    regionsConfig,
    areasConfig,
    workStreamsConfig,
    topicsConfig,
    marketSectorsConfig,
    projectTypesConfig,
    projectPhasesConfig,
  ]);

  const addConfig = async (
    configArray: ConfigEntryType[],
    configName = '',
    description = '',
    entryCode: string,
    callback: (value: string) => void,
  ) => {
    try {
      const { data } = await CompanyAPI.updateConfig(companyId, configName, description, [
        ...configArray,
        {
          entryCode: entryCode,
          entryDescription: entryCode,
          isActive: true,
          entryValues: [],
        },
      ]);
      toast.success(`${entryCode} added successfully`);
      updateConfigCache({ _companyId: companyId, _configName: configName, updatedData: data });
      if (hash) {
        navigate(pathname);
      }
      callback('');
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const addMultipleConfig = async (
    oldValues: ConfigEntryType[],
    configName = '',
    configDescription = '',
    values: Omit<ConfigEntryType, 'id'>[],
    callback: (values: Omit<ConfigEntryType, 'id'>[]) => void,
  ) => {
    try {
      const { data } = await CompanyAPI.updateConfig(companyId, configName, configDescription, [
        ...oldValues,
        ...values,
      ]);
      toast.success(`Config added successfully`);
      updateConfigCache({ _companyId: companyId, _configName: configName, updatedData: data });
      if (hash) {
        navigate(pathname);
      }
      callback([]);
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const updateConfig = async (
    configArray: ConfigEntryType[],
    configName = '',
    description = '',
    config: ConfigEntryType,
  ) => {
    const updatedConfig = [...configArray];
    const index = updatedConfig.findIndex((item) => item.id === config.id);

    if (index > -1) {
      updatedConfig[index] = {
        ...updatedConfig[index],
        ...config,
      };
    }

    try {
      const { data } = await CompanyAPI.updateConfig(
        companyId,
        configName,
        description,
        updatedConfig,
      );
      setOpenTooltip(config.entryCode);

      setTimeout(() => {
        setOpenTooltip('');
      }, 2000);

      updateConfigCache({ _companyId: companyId, _configName: configName, updatedData: data });
      if (hash) {
        navigate(pathname);
      }
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const deleteConfig = async (
    configArray: ConfigEntryType[],
    configName = '',
    description = '',
    index: number,
  ) => {
    try {
      configArray[index].deleteFlag = true;
      const { data } = await CompanyAPI.updateConfig(
        companyId,
        configName,
        description,
        configArray,
      );
      toast.success(`${configArray[index].entryCode} deleted successfully`);
      updateConfigCache({ _companyId: companyId, _configName: configName, updatedData: data });
      if (hash) {
        navigate(pathname);
      }
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const handleMasterFormat = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setMasterFormat(e.target.value);
      await CompanyAPI.updateCompanySettings(companyId, {
        id: companyId,
        masterFormat: e.target.value,
      });
    } catch (e) {
      toast.error(catchErrorMessage(e));
    }
  };

  const handleEnter = (
    e: KeyboardEvent<HTMLDivElement>,
    configArray: ConfigEntryType[],
    configName = '',
    description = '',
    entryCode = '',
    callback: (value: string) => void,
  ) => {
    if (e.key === 'Enter') {
      addConfig(configArray, configName, description, entryCode, callback);
    }
  };

  return (
    <Box ref={containerRef}>
      <Typography variant="h4" mb={1.5}>
        Configurations
      </Typography>
      <Container id="areas">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Areas</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Area"
                      value={area}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          areas,
                          areasConfig?.configName || 'AREAS',
                          areasConfig?.description || 'AREAS',
                          area,
                          setArea,
                        );
                      }}
                      onChange={(event) => setArea(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!area}
                      onClick={() => {
                        addConfig(
                          areas,
                          areasConfig?.configName || 'AREAS',
                          areasConfig?.description || 'AREAS',
                          area,
                          setArea,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {areas.map((item, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{item?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                areas,
                                areasConfig?.configName || 'AREAS',
                                areasConfig?.description || 'AREAS',
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {areas.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="company-filters">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Company Filters</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        limitTags={3}
                        disableCloseOnSelect
                        onChange={(_: unknown, values: Omit<ConfigEntryType, 'id'>[]) => {
                          setSelectedCompanyFilters(values);
                        }}
                        getOptionLabel={(option) => option.entryDescription}
                        options={companyFiltersOptions}
                        autoHighlight
                        value={selectedCompanyFilters || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Company Filters"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={option.entryDescription}
                              deleteIcon={<CloseLineIcon size={20} />}
                            />
                          ))
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!selectedCompanyFilters.length}
                      onClick={() => {
                        addMultipleConfig(
                          companyFilters,
                          companyFiltersConfig?.configName || 'COMPANY_FILTERS',
                          companyFiltersConfig?.description || 'Company Filters',
                          selectedCompanyFilters,
                          setSelectedCompanyFilters,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {companyFilters.map((filter, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{filter?.entryDescription}</Typography>
                      {isNovaAdmin && (
                        <Box>
                          <Tooltip
                            open={openTooltip === filter.entryCode}
                            placement="top"
                            title={
                              <Typography>
                                {filter?.entryDescription} is{' '}
                                {filter.isActive ? 'activated' : 'deactivated'}
                              </Typography>
                            }
                          >
                            <Switch
                              disableRipple
                              sx={{ m: 1 }}
                              checked={filter.isActive}
                              onChange={(_, checked) =>
                                updateConfig(
                                  companyFilters,
                                  companyFiltersConfig?.configName,
                                  companyFiltersConfig?.description,
                                  {
                                    ...filter,
                                    isActive: checked,
                                  },
                                )
                              }
                            />
                          </Tooltip>
                          <IconButton
                            sx={{ ml: 1 }}
                            onClick={() =>
                              deleteConfig(
                                companyFilters,
                                companyFiltersConfig?.configName || 'COMPANY_FILTERS',
                                companyFiltersConfig?.description || 'Company Filters',
                                index,
                              )
                            }
                          >
                            <DeleteBinLineIcon size={20} color={theme.palette.custom.redWine} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    {companyFilters.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="departments">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Departments</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Department"
                      value={department}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          departments,
                          departmentsConfig?.configName,
                          departmentsConfig?.description,
                          department,
                          setDepartment,
                        );
                      }}
                      onChange={(event) => setDepartment(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!department}
                      onClick={() => {
                        addConfig(
                          departments,
                          departmentsConfig?.configName,
                          departmentsConfig?.description,
                          department,
                          setDepartment,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {departments.map((item, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{item?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                departments,
                                departmentsConfig?.configName,
                                departmentsConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {departments.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="job-titles">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Job Titles</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Job Title"
                      value={jobTitle}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          jobTitles,
                          jobTitleConfig?.configName,
                          jobTitleConfig?.description,
                          jobTitle,
                          setJobTitle,
                        );
                      }}
                      onChange={(event) => setJobTitle(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!jobTitle}
                      onClick={() => {
                        addConfig(
                          jobTitles,
                          jobTitleConfig?.configName,
                          jobTitleConfig?.description,
                          jobTitle,
                          setJobTitle,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {jobTitles.map((jobTitle, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{jobTitle?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                jobTitles,
                                jobTitleConfig?.configName,
                                jobTitleConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {jobTitles.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="market-sectors">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Market Sectors</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Market Sector"
                      value={marketSector}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          marketSectors,
                          marketSectorsConfig?.configName,
                          marketSectorsConfig?.description,
                          marketSector,
                          setMarketSector,
                        );
                      }}
                      onChange={(event) => setMarketSector(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!marketSector}
                      onClick={() => {
                        addConfig(
                          marketSectors,
                          marketSectorsConfig?.configName,
                          marketSectorsConfig?.description,
                          marketSector,
                          setMarketSector,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {marketSectors.map((marketSector, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{marketSector?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                marketSectors,
                                marketSectorsConfig?.configName,
                                marketSectorsConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {marketSectors.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="partner-roles">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Partner Roles</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Partner Role"
                      value={partnerRole}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          partnerRoles,
                          partnerRolesConfig?.configName,
                          partnerRolesConfig?.description,
                          partnerRole,
                          setPartnerRole,
                        );
                      }}
                      onChange={(event) => setPartnerRole(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!partnerRole}
                      onClick={() => {
                        addConfig(
                          partnerRoles,
                          partnerRolesConfig?.configName,
                          partnerRolesConfig?.description,
                          partnerRole,
                          setPartnerRole,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {partnerRoles.map((role, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{role?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                partnerRoles,
                                partnerRolesConfig?.configName,
                                partnerRolesConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {partnerRoles.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="project-phases">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Project Phases</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Project Phase"
                      value={projectPhase}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          projectPhases,
                          projectPhasesConfig?.configName,
                          projectPhasesConfig?.description,
                          projectPhase,
                          setProjectPhase,
                        );
                      }}
                      onChange={(event) => setProjectPhase(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!projectPhase}
                      onClick={() => {
                        addConfig(
                          projectPhases,
                          projectPhasesConfig?.configName,
                          projectPhasesConfig?.description,
                          projectPhase,
                          setProjectPhase,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {projectPhases.map((phase, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{phase?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                projectPhases,
                                projectPhasesConfig?.configName,
                                projectPhasesConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {projectPhases.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="project-types">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Project Types</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Project Type"
                      value={projectType}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          projectTypes,
                          projectTypesConfig?.configName,
                          projectTypesConfig?.description,
                          projectType,
                          setProjectType,
                        );
                      }}
                      onChange={(event) => setProjectType(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!projectType}
                      onClick={() => {
                        addConfig(
                          projectTypes,
                          projectTypesConfig?.configName,
                          projectTypesConfig?.description,
                          projectType,
                          setProjectType,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {projectTypes.map((projectType, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{projectType?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                projectTypes,
                                projectTypesConfig?.configName,
                                projectTypesConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {projectTypes.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="regions">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Regions</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Region"
                      value={region}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          regions,
                          regionsConfig?.configName,
                          regionsConfig?.description,
                          region,
                          setRegion,
                        );
                      }}
                      onChange={(event) => setRegion(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!region}
                      onClick={() => {
                        addConfig(
                          regions,
                          regionsConfig?.configName,
                          regionsConfig?.description,
                          region,
                          setRegion,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {regions.map((item, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{item?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                regions,
                                regionsConfig?.configName,
                                regionsConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {regions.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="scorecard-categories">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Scorecard Categories</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Scorecard Category"
                      value={scorecardCategory}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          scorecardCategories,
                          scorecardCategoriesConfig?.configName || 'SCORECARD_CATEGORIES',
                          scorecardCategoriesConfig?.description || 'SCORECARD_CATEGORIES',
                          scorecardCategory,
                          setScorecardCategory,
                        );
                      }}
                      onChange={(event) => setScorecardCategory(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!scorecardCategory}
                      onClick={() => {
                        addConfig(
                          scorecardCategories,
                          scorecardCategoriesConfig?.configName || 'SCORECARD_CATEGORIES',
                          scorecardCategoriesConfig?.description || 'SCORECARD_CATEGORIES',
                          scorecardCategory,
                          setScorecardCategory,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {scorecardCategories.map((category, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{category?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                scorecardCategories,
                                scorecardCategoriesConfig?.configName || 'SCORECARD_CATEGORIES',
                                scorecardCategoriesConfig?.description || 'SCORECARD_CATEGORIES',
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {scorecardCategories.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="master-format">
        <Box sx={{ padding: 4 }}>
          <Grid
            container
            sx={{
              marginRight: 4,
            }}
          >
            <Grid item md={3} xs={12} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Specification Master Format</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Box>
                <Typography variant="h6">Choose between types:</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="16"
                    value={masterFormat}
                    onChange={handleMasterFormat}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="16"
                      control={<Radio disabled={!isNovaAdmin} />}
                      label="16 Divisions"
                    />
                    <FormControlLabel
                      value="50"
                      control={<Radio disabled={!isNovaAdmin} />}
                      label="50 Divisions"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="topics">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Topics</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Topic"
                      value={topic}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          topics,
                          topicsConfig?.configName,
                          topicsConfig?.description,
                          topic,
                          setTopic,
                        );
                      }}
                      onChange={(event) => setTopic(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!topic}
                      onClick={() => {
                        addConfig(
                          topics,
                          topicsConfig?.configName,
                          topicsConfig?.description,
                          topic,
                          setTopic,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {topics.map((topic, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{topic?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                topics,
                                topicsConfig?.configName,
                                topicsConfig?.description,
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {topics.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="user-interests">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">User Interests</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Skill"
                      value={skill}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          skills,
                          skillsConfig?.configName || 'userProfileSuggestedExpertSkills',
                          skillsConfig?.description || 'userProfileSuggestedExpertSkills',
                          skill,
                          setSkill,
                        );
                      }}
                      onChange={(event) => setSkill(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!skill}
                      onClick={() => {
                        addConfig(
                          skills,
                          skillsConfig?.configName || 'userProfileSuggestedExpertSkills',
                          skillsConfig?.description || 'userProfileSuggestedExpertSkills',
                          skill,
                          setSkill,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {skills.map((_skill, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{_skill?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                skills,
                                skillsConfig?.configName || 'userProfileSuggestedExpertSkills',
                                skillsConfig?.description || 'userProfileSuggestedExpertSkills',
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {skills.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container id="workstreams">
        <Box sx={{ padding: 4 }}>
          <Grid container>
            <Grid item md={3} xs={2} sx={{ paddingRight: 4 }}>
              <Typography variant="h5">Workstreams</Typography>
            </Grid>
            <Grid container item md={9} xs={12}>
              {isNovaAdmin && (
                <Grid container item md={12} sx={{ marginBottom: 2 }}>
                  <Grid item md={10}>
                    <TextField
                      placeholder="Workstream"
                      value={workStream}
                      onKeyUp={(e) => {
                        handleEnter(
                          e,
                          workStreams,
                          workStreamsConfig?.configName || 'WORKSTREAMS',
                          workStreamsConfig?.description || 'WORKSTREAMS',
                          workStream,
                          setWorkStream,
                        );
                      }}
                      onChange={(event) => setWorkStream(event?.target.value)}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      disabled={!workStream}
                      onClick={() => {
                        addConfig(
                          workStreams,
                          workStreamsConfig?.configName || 'WORKSTREAMS',
                          workStreamsConfig?.description || 'WORKSTREAMS',
                          workStream,
                          setWorkStream,
                        );
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container item md={12}>
                {workStreams.map((item, index) => (
                  <Grid item md={12} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" py={0.5}>
                      <Typography sx={{ paddingTop: 1 }}>{item?.entryCode}</Typography>
                      {isNovaAdmin && (
                        <IconButton>
                          <DeleteBinLineIcon
                            size={20}
                            color={theme.palette.custom.redWine}
                            onClick={() =>
                              deleteConfig(
                                workStreams,
                                workStreamsConfig?.configName || 'WORKSTREAMS',
                                workStreamsConfig?.description || 'WORKSTREAMS',
                                index,
                              )
                            }
                          />
                        </IconButton>
                      )}
                    </Box>
                    {workStreams.length - 1 !== index && <Divider />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled(Paper, { label: 'Container' })(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 16,
  boxShadow: `0 4px 24px ${theme.palette.custom.boxShadow}`,
  marginBottom: 24,
  overflow: 'hidden',
}));

export default CompanyConfigurations;
